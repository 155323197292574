import {textsAytm as appTexts } from '../../../AppData';
import { getContextFromUrl } from 'config/Config';

export const texts = {
    PAGE_TITLE: 'Desistimiento',
    TEXT_DESISTIMIENTO: 'Desistimiento',
    TEXT_INFO: 'Documento de desistimiento que aporta el usuario',
    TEXTO_TAMAÑO_MAX_ANEXO: 'Tamaño maximo por anexo: ',
    TEXTO_MB:'MB',
    TEXT_DOC: 'Documentación de desistimiento',
    TITULO_CONFIRMARPESOSOBREPASADO: "No es posible anexar el documento.",
    TEXTO_ERROR_DROP_REJECTED1: "Tenga en cuenta que el documento deber ser un PDF con un tamaño máximo de ",
    TEXTO_ERROR_DROP_REJECTED2: " MB. Por favor, seleccione otro documento que cumpla con las condiciones indicadas.",
    TEXT_BOTON: 'VOLVER A LA BANDEJA DE ENTRADA',
    TEXT_FIRMAR: 'FIRMAR',
    TEXT_PLANT_DES: 'Para la relación del trámite de desistimiento debe adjuntar el escrito de desistimiento y firmarlo posteriormente. Puede obtener un modelo genérico ',
    TEXT_PLANT_AQUI: 'aquí',
    TEXT_PLANT_DES_PDF: 'ModeloGenericoDesistimiento.odt',
    TEXT_CONFIRMAR_DESISTIMIENTO: '¿Está seguro de firmar el desistimiento?',
    TEXT_CONFIRMAR_DESCRIPCION: 'Una vez firmado el desistimiento estará presentada y no podrá modificarla. Le aconsejamos que revise que todo está correcto antes de firmar.',
    TEXT_BOTON_ACEPTAR_FIRMA: 'QUIERO FIRMAR EL DESISTIMIENTO',
    TITLE_ERROR_PRESENTACION: 'Ha ocurrido un error en la presentación del desistimiento',
    TITLE_DES_OK: 'Desistimiento presentado',
    TITLE_DES_INFO: 'El proceso de presentación desistimiento se ha realizado correctamente',
    PARTE_1_MSG_ERROR: "Se ha producido un error técnico.",
    PARTE_2_MSG_ERROR: "Puede volver a intentar realizar el proceso de firma y, si el error persiste, contacte con el servicio de Información y Sugerencias del Servicio de Información y Atención Ciudadana que puede consultar <a href="+appTexts.URL_CONTACTO+">aquí</a>.",
}

export const properties = {
    URL_BANDEJA: '/BandejaSolicitudes',
    TAM_MAX_ANEXO: '10',
    PATH_IMG_PDF: `${getContextFromUrl()}` + 'pdfGris.png',
    UN_MEGABYTE_EN_BYTE : 1000000,
    NUMERO_DECIMALES: 2,
    IMAGEN_CONFIRMAR: `${getContextFromUrl()}` + 'oval.png',
    IMAGEN_OK: `${getContextFromUrl()}` + 'archivoOk.png',
    IMAGEN_ERROR : `${getContextFromUrl()}` + 'exclamation-triangle-solid.svg',
    RESOURCE_PRESENTAR_DESISTIMIENTO: 'entregas',
    URL_PRESENTAR_DESISTIMIENTO: '/presentacionEntrega',
    PATH_PLANT_DEST_PDF: `${getContextFromUrl()}` + 'ModeloGenericoDesistimiento.odt',
    PATH_PLANT_DEST_ICON: `${getContextFromUrl()}` +  'circle-info-solid.svg',
    RESOURCE_DATO_EXPEDIENTE: 'dato-expediente',
    URL_ACCION_PRESENTAR_DESEST_OV3: '/presentarDesestimientoOV3/',
    RESOURCE_PRESENTAR_DESESTIMIENTO_PCT3: 'presentacion-entrega',
    PROCESO_ENTREGA_DESISTIMIENTO: 'DESISTIMIENTO',
}

export const formato = ['.pdf'];