import { createMuiTheme } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles'
import {backgroundRectangle, buttonPrimaryBig, buttonSecundaryBig, colores, tipografia} from '../../../AppStyle';

const useStyles = (tamMarginTop, isPopUpPdf) => {

    const customStyle = makeStyles ({
        classIcons:{
            height: '50px', 
            display: 'inlineBlock',
            width: '100%',
            marginTop: '30px',
        },
        colAC:{
            width: '90%'
        },
        colIconoResponsive:{
            width: '40%'
        },
        colACResponsive:{
            width: '60%'
        }, 
        gridAC:{
            width: '100%'
        },
        tituloAC:{
            fontFamily: tipografia.bold,
            fontSize: '15px',
            marginLeft: '15px',
            marginBottom: '5px'
        },
        gridAvisoAC:{
            width: '100%',
            fontSize: '15px',
            marginLeft: '15px'
        },
        gridSeccionTitulo:{
            width:'100%',
            fontSize: '20px',
            height: '55px',
            fontFamily: tipografia.bold,
            marginTop: '3%'
        },
        gridSeccionTituloResponsive:{
            width:'100%',
            fontSize: '20px',
            height: '55px',
            fontFamily: tipografia.bold,
            marginLeft: '5%'
        },
        lineaHorizontal:{
            width: '100%',
            marginBottom: '15px'     
        },
        gridSeccionTres:{
            marginTop: '30px',
            marginBottom: '30px',
            width: '100%',
            height: '55px',
            justifyContent: 'center'
        },
        gridACIcono:{
            marginTop: '10px',
            width: 'fit-content',
            height: 'fit-content'
        },
        gridLineaAC:{
            width: '100%'
        },
        classBackdrop: {
            zIndex: 0
        },
        icono: {
            marginTop: '3%'
        },
        botonAceptar:{
            ...buttonPrimaryBig    
        },
        botonCancelar:{
            ...buttonSecundaryBig    
        },
        classButton:{
            ...(isPopUpPdf) ? { ...buttonSecundaryBig } : { ...buttonPrimaryBig },
            
        },
        classButtonCancelar:{
            ...buttonSecundaryBig
        },
        classGridButtons: {
            width: '100%',
            marginLeft: 'auto',
            marginRight:'auto%',
            marginBottom: '2%',
            marginTop: '2%'
        },
        lineaHorizontalAvisos:{
            width: '95%',
            marginLeft: '15px',
            marginTop: '25px',
            marginBottom: '20px'   
        }
    
    });    

    const overAvisosCriticos = createMuiTheme({
        overrides: {
            MuiPopover: {
                root: {
                    zIndex:'0',
                    marginTop: tamMarginTop + '%',
                }
            },
            MuiPaper:{
                root:{
                    ...(isPopUpPdf) ? { width: '100%' } : { width: '45%' },
                    height: 'fit-content',
                   
                }
            }
        }
    });

    const overAvisosCriticosResponsive = createMuiTheme({
        overrides: {
            MuiPopover: {
                root: {
                    zIndex:'0',
                    marginTop: tamMarginTop + '%',
                },
                paper: {
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '342px',
                    position: 'initial'
                }
            },
            MuiPaper:{
                root:{
                    width: '56%',
                    height: 'fit-content',
                }
            }
        }
    });

    return { customStyle, overAvisosCriticos, overAvisosCriticosResponsive};
}

export default useStyles;
