import React from 'react';
//Componentes importados de material ui
import { InputLabel, TextField, Grid, ThemeProvider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Estilo importado
import Style from './SelectAutocompleteStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**
 * @function SelectAutocomplete
 * La función contiene los componentes MuiThemeProvider, Grid, InputLabel, Autocomplete y TextField
 * El componente ThemeProvider aplica el estilo a los componentes que los contiene
 * El componente InputLabel contiene el texto de la etiqueta del componente Input 
 * El componente Autocomplete contiene el autocompletado del componenteSelectAutocomplete
 * El componente TextField contiene el componenteSelectAutocomplete en si, dónde se mostrarán las opciones
 * @param {*} props 
 * A través de las props se recibe por parámetro los atributos y propiedades de los componentes
 */
export default function SelectAutocomplete(props) {
    //ordenarItems.sort(filtroOrdenacion); Filtro para ordenar los items alfabéticamente
    return (
        <ThemeProvider theme={Style(props.isPhoneVersion)}>

            <Grid className={props.classes.classGrid}>
            {          
                props.isRequired &&
                    <Grid container>
                        <InputLabel className={props.classes.classInputLabel} for={props.id} shrink>
                            {props.label}
                        </InputLabel>
                        <span className={props.classes.classSpanRequired}>{props.textRequired}</span>
                    </Grid>
            }
            {          
                !props.isRequired &&
                    <InputLabel className={props.classes.classInputLabel} for={props.id} shrink>
                        {props.label}
                    </InputLabel>
            }                
                <Autocomplete
                    id={props.id}
                    name={props.name}
                    disableClearable={props.disableClearable}
                    blurOnSelect //Al seleccionar la opción se selecciona automáticamente y no queda el campo editable para seguir insertando texto
                    clearOnEscape //Al pulsar ESC se borra el contenido
                    options={props.options}// Objeto que recibe con las opciones a mostrar
                    getOptionLabel={props.getOptionLabel} //Opciones a mostrar en el selector, name es el nodo del objeto que se mostrará (option) => option.name
                    popupIcon={<ExpandMoreIcon style={{color: "#333333"}}/>} //Icono a mostrar 
                    onChange={props.onChange}
                    value={props.value}
                    noOptionsText={props.noOptionsText}// Texto a mostrar cuando no hay opciones disponibles    
                    clearText={'Borrar'}
                    openText={'Abrir'}
                    closeText={'Cerrar'}
                    ref={props.refSelect}  
                    disabled={props.disabled}
                    defaultValue={props.defaultValue}
                    renderInput={(params) => (
                        <TextField {...params} title={props.title} error={props.error} inputRef={props.inputRef} name={props.nameInput} placeholder={props.placeholder} 
                        helperText={props.helperText} variant='outlined' required={props.isRequired} aria-required={props.isRequired} />
                    )}
                />
            </Grid>
        </ThemeProvider>
    );
}