import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { customStyle } from './breadcrumbsStyle';
import { withRouter } from 'react-router';
import { properties, rutas } from './BreadcrumbsData';
import useOperations from './breadcrumbFunction';
import { loadState } from 'providers/localStorage';
import { localStorageName } from 'providers/localStorageData';
import {properties as appProperties} from '../../../AppData';

const SimpleBreadcrumbs = props => {
  const { obtenerCamino, obtenerNombreMiga, crearTextoBreadcrumbs, camino, contexto, contextoUrl, 
          mostrarMiga } = useOperations();

  const style = customStyle();
  const [isPhoneVersion, setIsPhoneVersion] = useState(false);

  let urlProcedimiento = loadState(localStorageName.urlProcedenciaProcedimiento, false);

  //Paso 1. Obtenición de la ruta actual
  let rutaActual = window.location.hash;

  //Paso 2. Se realiza la llamada a la función obtenerCamino cada vez que se renderiza el componente
  useEffect(() => {
    obtenerCamino(rutaActual);
    removeAriaHiddenDeSeparador();
    updateFormat();
    return () => window.removeEventListener('resize', updateFormat);
  }, [rutaActual]);

  //Paso 3. Se convierte en array la cadena de String recibida con los caminos
  let migasObtenidas = camino === rutas.procedimientos.seleccionConvocatoriaPerfil + urlProcedimiento ? [camino] : camino.split('/');

  //Se transforma el texto de la pantalla principal, para que el primer caracter aparezca en mayúsculas y el resto en minúsculas
  const textoPantallaPrincipal = properties.pantallaPrincipal.substr(0, 1).toUpperCase() + properties.pantallaPrincipal.substr(1, properties.pantallaPrincipal.length);

  const removeAriaHiddenDeSeparador = () => {
    let separadores = document.getElementsByClassName("MuiBreadcrumbs-separator");

    if (separadores!== null) {
        for (let separador of separadores) {
            separador.setAttribute("aria-hidden", "false");
        }
    }
  }

  /**Método que capta el tamaño de la ventana y actualiza actualiza
  el hook que controla cuando se da el salto a versión movil*/
  const updateFormat = () => {
    if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
      setIsPhoneVersion(true);
    } else {
      setIsPhoneVersion(false);
    }
  }
  
  return (
    //Paso 4. Se inserta el componente miga de pan
    <Breadcrumbs separator={<span className={isPhoneVersion ? style.separadorPhoneVersion : style.separador}>&#8250;</span>} aria-label="breadcrumb">
      { //Se añade un elemento vacío para insertar el separador en versión móvil
        isPhoneVersion &&
          (<a title='' className={style.linkPrincipal} href=''/>)
      }  
      {/* Paso 4.1. Se inserta la primera miga de pan, siempre que no sea la página principal y no se esté visualizando la aplicación en versión móvil */}      
      {
        ((mostrarMiga(rutaActual) && !isPhoneVersion) || (mostrarMiga(rutaActual) && isPhoneVersion && migasObtenidas.length === 1)) && 
        (<a title={textoPantallaPrincipal} className={style.linkPrincipal} href={contextoUrl + '#/'}>{textoPantallaPrincipal}</a>)
      }
      {/*Paso 4.2. Se inserta el resto de migas de pan
      Se evalúa si el array que contiene las migas de pan, contiene la página principal*/}
      {!migasObtenidas.includes(properties.pantallaPrincipal) &&
        /*Paso 4.3. Se recorre el objeto que contiene las migas de pan*/        
        migasObtenidas.map((migaActual) => {
          
          //Se obtiene la última miga de pan del objeto migasObtenidas
          const ultimoElemento = migaActual === migasObtenidas[migasObtenidas.length - 1];
          const penultimoElemento = migaActual === migasObtenidas[migasObtenidas.length - 2];          
          /**
           * Estructura de control que verifica que migas se insertaran dependiendo de la versión que sea vista la aplicación
           * Versión escritorio: Se mostrarán las migas de pan al completo, exceptuando la miga de la página actual
           * Versión móvil: Se mostrará solo ala miga de la página anterior
           */
          if ((!isPhoneVersion && !ultimoElemento) || (isPhoneVersion && penultimoElemento)) {
            // En caso de que la migaActual sea seleccionConvocatoriaPerfil, se añade la url del procedimiento
            migaActual = migaActual === rutas.procedimientos.seleccionConvocatoriaPerfil ? migaActual + urlProcedimiento : migaActual;
            const routeTo = contexto + migaActual;
            // Se obtiene el nombre de la miga de pan
            let nombreMiga = obtenerNombreMiga(migaActual);
            // Se obtiene el texto que contendrá la miga de pan          
            let textoMiga = crearTextoBreadcrumbs(migaActual);                   
          
            /* Paso 4.4. Inserción de las migas de pan */
            return migasObtenidas && (
              <nav aria-label={properties.ariaLabel}>
                <a title={nombreMiga} key={nombreMiga} className={style.linkPrincipal} href={routeTo} dangerouslySetInnerHTML={{ __html: textoMiga}}/>
              </nav>
            )
          } else if (ultimoElemento) {
            // Renderizar la miga de pan actual como texto no clicable
            return (
              <span key={migaActual} className={style.paginaActual} dangerouslySetInnerHTML={{ __html: crearTextoBreadcrumbs(migaActual) }} />
            );
          }
        })
      }
    </Breadcrumbs>
  );
}

export default withRouter(SimpleBreadcrumbs);
