const es =  {
    'Submit': 'Enviar',
    'Save': 'Guardar',
    'Cancel': 'Cancelar',
    'Remove' : 'Eliminar',
    'Drag and Drop a form component': 'Arrastre y suelte un elemento del formulario',
    'Label': 'Etiqueta',
    'Label Position' : 'Posición de la etiqueta',
    'Basic': 'Básicos',
    'Advanced': 'Avanzados',
    'Layout': 'Disposición',
    'Data': 'Datos',
    'Premium': 'De pago',
    title : 'Título',
    title_desc : 'Introduce el título',
    title_tooltip: 'Título del formulario',
    name : 'Alias',
    name_desc : 'Alias del formulario',
    name_tooltip: 'Alias del formulario',
    display_as : 'Mostrar como',
    display_as_tooltip:'Mostrar como',
    type : 'Tipo',
    type_tooltip : 'Tipo',
    path : 'Ruta',
    path_desc : 'Ejemplo',
    path_tooltip : 'Ruta del formulario',
    'Form' : 'Formulario',
    'Wizard' : 'Asistente',
    'Resource': 'Recurso',
    'No data found' : 'No se han encontrado datos',
    'Path {{path}} is required': 'Es requerido el {{path}}',
    required : '{{field}} es requerido.',
    invalid_email: '{{field}} debe ser un correo electrónico válido.',
    invalid_date :"{{field}} no es una fecha válida.",
    invalid_regex : "{{field}} no coincide con el patrón {{regex}}.",
    max : "{{field}} no puede ser mayor que {{max}}.",
    maxLength : "{{field}} debe ser menor que {{length}} caracteres.",
    min : "{{field}} no puede ser menor que {{min}}.",
    minLength : "{{field}} debe ser mayor que {{length}} caracteres.",
    next : "Siguiente",
    pattern : "{{field}} no coincide con el patrón {{pattern}}",
    previous : "Anterior",
    error : 'Por favor, corrija los siguientes errores antes de enviar.',
    alertMessage: '{{label}}: {{message}}',
    complete: 'Envío completo',
    submitError: 'Por favor comprueba el formulario y corrige todos los errores antes de enviar.',
    unique: '{{field}} debe ser único.',
    array: '{{field}} debe ser un array.',
    array_nonempty: '{{field}} debe tener un array no vacío.', // eslint-disable-line camelcase
    nonarray: '{{field}} no debe ser un array.',
    select: '{{field}} contiene una selección invalida.',
    minWords: '{{field}} debe tener al menos {{length}} palabras.',
    maxWords: '{{field}} no debe tener más de {{length}} palabras.',
    maxDate: '{{field}} no debería contener una fecha posterior a {{- maxDate}}.',
    minDate: '{{field}} no debería contener una fecha anterior a {{- minDate}}.',
    maxYear: '{{field}} no debería contener un año mayor que {{maxYear}}.',
    minYear: '{{field}} no debería contener un año menor que {{minYear}}.',
    invalid_url: '{{field}} debe ser una URL válida.', // eslint-disable-line camelcase
    invalid_day: '{{field}} no es un día válido.', // eslint-disable-line camelcase
    mask: '{{field}} does not match the mask.',
    stripe: '{{stripe}}',
    month: 'Mes',
    day: 'Día',
    year: 'Año',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    cancel: 'Cancelar',
    submit: 'Enviar el formulario',
    confirmCancel: '¿Esta seguro de que desea cancelar?',
    searchPlaceholderValue: "Escriba para buscar",
    placeholder: "Escriba para buscar",
    'Type to search': 'Escriba para buscar',
    saveDraftInstanceError: 'No se puede guardar como borrador por que no hay instancia de formio.',
    saveDraftAuthError: 'No puede guardar un borrador sin estar loggeado.',
    restoreDraftInstanceError: 'No se puede restaurar el borrador porque no hay una instancia de formio.',
    time: 'Hora no válida'
}

export default es;
