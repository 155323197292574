export const texts = {
    PAGE_TITLE: 'Alta de usuario',
    BTN_ALTA_ENTIDAD: 'ALTA EDICIÓN DE USUARIO',
    BTN_ATRAS: 'ATRÁS'
}

export const cruds = {
    interesado: {
        resource: 'interesado',
        url: '/insertarActualizarInteresadoOV3'
    }
}

export const properties = {
    RESOURCE_INTERESADO_ENTIDAD:'operacionesInteresado',
    URL_GET_INTERESADO_ENTIDAD: 'cargaPantallaInteresado',
    URL_POST_INTERESADO_ENTIDAD: 'guardarActualizarPantallaUsuario',
    RESOURCE_MANAGER :'manager',
    RESOURCE_MOTOR_TRAMITACION: 'api',
    RESOURCE_SALTO_BANDEJA:'logInBandeja',
    URL_GET_PAISES: 'lista-paises',
    URL_GET_PROVINCIAS: 'lista-provincias',
    URL_GET_MUNICIPIOS: 'lista-municipio/',
    URL_GET_TIPO_VIAS: 'lista-tipos-via',
    DATE_LENGTH: 10,
    URL_ORGANISMOS: '/find-by-ids-convocatoria',
};

export const objComponentFormio = {
    aliasFormulario: 'altaEdicionUsuarioAyunCor',
    sp: {
        complete: 'Formulario cumplimentado correctamente',
        error: 'Hubo un problema',
        next: 'Siguiente',
        previous: 'Atrás',
        cancel: 'Cancelar',
        submit: 'Guardar',
        required: 'Es obligatorio rellenar este campo',
        confirmCancel: '¿Estás seguro que quieres cancelar?',
        submitError: 'Por favor revise el formulario y corrija todos los errores.',
        pattern: 'El valor no cumple con el patrón requerido',
        email: 'Debe ser una dirección de correo electrónico válida',
        pattern: 'El valor no cumple con el patrón requerido',
        'Type to search': 'Escriba para buscar',
        invalid_regex: 'El valor insertado no es válido'
    }    
};

const pageable = '?page=0&size=1000';
export const propertiesPCT3 = {
    pageable: {
        municipios: `${pageable}&sort=descripcion,ASC`,
    },
    resource: {
        interesdado: 'interesado',
        provincias: 'provincia',
        municipios: 'municipio',        
    },
    url: {
        provincia: '/provincia/',
        interesadoDatosContactoPorDefecto: '/obtenerDatosInteresadoPorDefecto',
        guardarActualizarInteresado: '/insertarActualizarInteresadoOV3'
    },
};

export const valoresExtranjero = {
    COD_SPAIN: "108",
    COD_EXTRANJERO: "108",
    CADENA_EXTRANJERO: "EXTRANJERO",
    COD_PROVINCIA_EXTRANJERO: "99",
    COD_MUNI_EXTRANJERO: "900",
    CP: "00000",
}
