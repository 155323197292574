import React, { useEffect, useRef} from 'react';
import { Container, Grid, ThemeProvider, Backdrop, CircularProgress, Snackbar, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import SeleccionConvocatoriaPerfilStyle, {overrideStyle} from './SeleccionConvocatoriaPerfilStyle';
import Select from '../../commons/selectAutocomplete/SelectAutocomplete';
import { Alert } from '@material-ui/lab';
import MetodoAcceso from '../../commons/MetodoAcceso/metodoAcceso';
import { objAttributesSeleccionConvocatoriaPerfil, objTextSeleccionConvocatoriaPerfil, setStateButtons, properties } from './SeleccionConvocatoriaPerfilData';
import useFunctions from './SeleccionConvocatoriaPerfilFunctions';
import RadioButtonCustom from 'components/commons/RadioButtonCustom/radioButtonCustom';
import { loadState } from './../../../providers/localStorage';
import AvisosCriticos from '../../commons/avisosCriticos/avisosCriticos';
import { localStorageName } from './../../../providers/localStorageData';
import VentanaUsuario from '../../commons/ventanaUsuarioSinAlta/ventanaUsuarioSinAlta';
import Avisos from '../../commons/avisos/Avisos';
import { properties as appProperties } from '../../../AppData';
import TextoEnriquecido from '../../commons/textoEnriquecido/TextoEnriquecido';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function SeleccionConvocatoriaPerfil() {
    /***********************************************************************************
     * Constantes
     ***********************************************************************************/

    /**
     * @constant useFormSeleccionConvocatoriaPerfil Funciones, constantes y variables del fichero de funciones useFormSeleccionConvocatoriaPerfil
     */
    const { values, convocatorias, isPhoneVersion, handleChange, updateFormat, mostrarPerfiles, mostrarAcceso,
        getIdsPerfilesConvocatoria, getEstadoBoton, alerts, message, setMessage, getLabelConvocatoria,
        mostrarMensajeBienvenida, expandedMensajeBienvenida, handleChangeMensajeBienvenida,
        procActual, useLoginIdp, onClickCertificado, compruebaUsuario, postDatosSalto, effectAux,
        isLoading, ventanaUsuarioSinAltaAbrirCerrar, mostrarVentana, pintarPantalla, context,
        irAccesoConContrasena} = useFunctions();

    // Obtención de los id de los métodos de acceso
    const attribCert = objAttributesSeleccionConvocatoriaPerfil.buttons.certificadoDigital;
    const attribSinCert = objAttributesSeleccionConvocatoriaPerfil.buttons.entradaSinCertificado;
    const attribClave = objAttributesSeleccionConvocatoriaPerfil.buttons.entradaClave;
    const attribRegistro = objAttributesSeleccionConvocatoriaPerfil.buttons.entradaRegistrado;
    const attribSaltoBandeja = objAttributesSeleccionConvocatoriaPerfil.buttons.saltoABandeja;
    // Obtención del id del selector para pasar por parametros
    const idSelect = objAttributesSeleccionConvocatoriaPerfil.select.id;
    const idRadioGroup = objAttributesSeleccionConvocatoriaPerfil.radioButton.idRadioGroup;
    // Obtención de los iconos para el método de acceso
    const accesoConCertificado = objAttributesSeleccionConvocatoriaPerfil.icon.entradaConCertificado;
    const accesoConCertificadoResponsive = objAttributesSeleccionConvocatoriaPerfil.icon.entradaConCertificadoRespon;
    const accesoSinCertificado = objAttributesSeleccionConvocatoriaPerfil.icon.entradaSinCertificado;
    const accesoSinCertificadoResponsive = objAttributesSeleccionConvocatoriaPerfil.icon.entradaSinCertificadoRespon;
    const accesoConClave = objAttributesSeleccionConvocatoriaPerfil.icon.entradaConClave;
    const accesoConClaveResponsive = objAttributesSeleccionConvocatoriaPerfil.icon.entradaConClaveRespon;
    const accesoRegistrado = objAttributesSeleccionConvocatoriaPerfil.icon.entradaConRegistro;
    const accesoRegistradoResponsive = objAttributesSeleccionConvocatoriaPerfil.icon.entradaConRegistroRespon;

    // Obtención de estilos para usarlos en las condiciones
    const style = SeleccionConvocatoriaPerfilStyle();
    const styleGridEncabezadoResponsive = SeleccionConvocatoriaPerfilStyle().classGridEncabezadoResponsive;
    const styleGridEncabezado = SeleccionConvocatoriaPerfilStyle().classGridEncabezado;
    const styleTitleEncabezado = SeleccionConvocatoriaPerfilStyle().classEncabezadoH2;
    const styleTitleEncabezadoResponsive = SeleccionConvocatoriaPerfilStyle().classEncabezadoH2Responsive;
    const styleTitleMetodoAcceso = SeleccionConvocatoriaPerfilStyle().classEncabezadoH3;
    const styleParrafo = SeleccionConvocatoriaPerfilStyle().classParrafo;
    const styleParrafoResponsive = SeleccionConvocatoriaPerfilStyle().classParrafoResponsive;
    const styleContaForm = SeleccionConvocatoriaPerfilStyle().classContaFormSeleccionConvocatoriaPerfilData;
    const styleContaFormResponsive = SeleccionConvocatoriaPerfilStyle().classContaFormSeleccionConvocatoriaPerfilDataResponsive;
    const styleContaCompon = SeleccionConvocatoriaPerfilStyle().classContaComponentes;
    const styleContaComponResponsive = SeleccionConvocatoriaPerfilStyle().classContaComponentesResponsive;
    const styleTitleMetodoAccesoResponsive = SeleccionConvocatoriaPerfilStyle().classEncabezadoH3Responsive;
    const styleMetodosAccesoResponsive = SeleccionConvocatoriaPerfilStyle().classGridSeccionesResponsive;
    const styleMetodosAcceso = SeleccionConvocatoriaPerfilStyle().classGridSecciones;
    const styleTextoEnriquecido = SeleccionConvocatoriaPerfilStyle().classMsgBienvenida;
    const styleSeparador = SeleccionConvocatoriaPerfilStyle().separador;
    const styleGridSeparador = SeleccionConvocatoriaPerfilStyle().classGridSecciones;
    const styleEncabezadoH3 = SeleccionConvocatoriaPerfilStyle().classEncabezadoH3;
    const styleSeparacionSecciones = SeleccionConvocatoriaPerfilStyle().classSeparacionSecciones;
    const styleGridSecciones = SeleccionConvocatoriaPerfilStyle().classGridSecciones;
    const styleSeparacionMetodoAcceso = SeleccionConvocatoriaPerfilStyle().classSeparacionMetodoAcceso;
    const styleBackdrop = SeleccionConvocatoriaPerfilStyle().classBackdrop;
    const styleClassContaURL = SeleccionConvocatoriaPerfilStyle().classContaURL;
    let refSelector = useRef(null);

    /***********************************************************************************
     * Efectos
     ***********************************************************************************/

    // Alimentación del selector con las convocatorias obtenidas
    useEffect(() => {
            //Por tema de sincronia usamos un metodo auxiliar para alimentar la pagina
            effectAux();
            let redirect = loadState(localStorageName.redirect, false);
            (redirect !== undefined && redirect === 'clave') && localStorage.removeItem(localStorageName.redirect);
    }, []);

    // Efecto aplicado para modificar el tamaño del selector
    useEffect(() => {
        refSelector.current !== null &&
        (refSelector.current.style.width = 'auto')
    }, [refSelector.current]);

    useEffect(() => {
        if (context.sesion && context.sesion === appProperties.SESION_INICIO) {
            const convocatoriaSeleccionada = loadState(localStorageName.convocatoriaSeleccionada, true);
            const perfilSeleccionado = loadState(localStorageName.perfilSeleccionado, false);
            let redirect = loadState(localStorageName.redirect, false);

            if (redirect === 'clave' && convocatoriaSeleccionada && perfilSeleccionado) {
                compruebaUsuario();
            }
        }    
    }, context.sesion);

    window.addEventListener('resize', updateFormat);
    
    /***********************************************************************************
     * Funciones
     ***********************************************************************************/

    /**
     * @function renderPantalla Función que renderiza la pantalla
     * @returns Deveuvel la estructura HTML con el contenido de esta
     */
    const renderPantalla = () => {
        return <Grid>
            <Avisos />
            <Container
                {...objAttributesSeleccionConvocatoriaPerfil.containers.formSeleccionConvocatoriaPerfil}
                className={isPhoneVersion ? styleContaFormResponsive : styleContaForm}
            >            
                <section >
                    <Grid ///////////////////////////CABECERA
                        className={isPhoneVersion ? styleGridEncabezadoResponsive : styleGridEncabezado}>
                     <h1 id="tituloPagina"
                            className={isPhoneVersion ? styleTitleEncabezadoResponsive : styleTitleEncabezado}                        
                            dangerouslySetInnerHTML={{ __html: objTextSeleccionConvocatoriaPerfil.titulos.tituloPagina + '' + ((procActual.descripcion === undefined) ? procActual.nombre : procActual.descripcion)}}
                        />
                        <p className={isPhoneVersion ? styleParrafoResponsive : styleParrafo}>
                            {objTextSeleccionConvocatoriaPerfil.parrafo}
                        </p>
                    </Grid>
                </section>
                    <Container ///////////////////////////CONTENEDOR DEL FORMULARIO
                        {...objAttributesSeleccionConvocatoriaPerfil.containers.componentes}
                        className={isPhoneVersion ? styleContaComponResponsive : styleContaCompon}
                    >
                        <section aria-label={properties.label_acces_convocatoria} className={styleSeparador}>
                            <Grid ///////////////////////////CONVOCATORIA
                                {...objAttributesSeleccionConvocatoriaPerfil.grids.seccion1}
                                className={styleGridSeparador}
                                item
                            >
                                <h3
                                    className={styleEncabezadoH3}
                                >
                                    {objTextSeleccionConvocatoriaPerfil.titulos.primeraSeccion}
                                </h3>
                                <Select /*SELECCION DE CONVOCATORIA*/
                                    {...objAttributesSeleccionConvocatoriaPerfil.select}
                                    classes={style}
                                    options={convocatorias}
                                    disableClearable={true}
                                    getOptionLabel={getLabelConvocatoria}
                                    onChange={(e, newValue) => handleChange(e, newValue, idSelect)}
                                    value={values.seleccionConvocatoria}
                                    idInput={values.seleccionConvocatoria ? values.seleccionConvocatoria.id.toString() : 'seleccionPerfilConvocatoria'}
                                    nameInput={values.seleccionConvocatoria ? values.seleccionConvocatoria.id.toString() : 'seleccionPerfilConvocatoria'}
                                    placeholder={objTextSeleccionConvocatoriaPerfil.placeholder.convocatoria}
                                    noOptionsText={objTextSeleccionConvocatoriaPerfil.noOptionsText.convocatoria}
                                    refSelect={refSelector}
                                    isPhoneVersion={isPhoneVersion}
                                    isRequired={false}
                                    title={values.seleccionConvocatoria.descripcion}
                                />
                            </Grid>
                        
                            {mostrarMensajeBienvenida && <ThemeProvider theme = {overrideStyle}>
                                <Grid>
                                    <Accordion expanded={expandedMensajeBienvenida === 'panel1'} onChange={handleChangeMensajeBienvenida('panel1')}>
                                        <AccordionSummary className = {style.acordeonSummary}>
                                            {expandedMensajeBienvenida ?
                                                (<KeyboardArrowDownIcon className={style.claseIconMensajeBienvenida}/>) 
                                            : 
                                                (<KeyboardArrowRightIcon className={style.claseIconMensajeBienvenida}/>) }
                                            <Typography className={style.typography}>Más información sobre el procedimiento</Typography>                                      
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TextoEnriquecido
                                                className={styleTextoEnriquecido}
                                                texto={ procActual.mensajeBienvenida}
                                            />
                                        </AccordionDetails>
                                    </Accordion> 
                                </Grid>
                            </ThemeProvider>}
                        
                        </section>
   
                        <section aria-label={properties.label_acces_perfil} className={styleSeparacionSecciones}>
                            <ThemeProvider>
                                <Grid ///////////////////////////PERFIL
                                    {...objAttributesSeleccionConvocatoriaPerfil.grids.seccion2}
                                    className={styleGridSecciones}
                                    item
                                >
                                    <h3
                                        className={styleEncabezadoH3}
                                    >
                                        {objTextSeleccionConvocatoriaPerfil.titulos.segundaSeccion}
                                    </h3>
                                    <RadioButtonCustom
                                        {...objAttributesSeleccionConvocatoriaPerfil.radioButton}
                                        classes={style}
                                        classFormLabel={''}
                                        options={mostrarPerfiles()}
                                        value={values.perfil}
                                        onChange={(e, newValue) => handleChange(e, newValue, idRadioGroup)}
                                        disabled={getIdsPerfilesConvocatoria(values.seleccionConvocatoria)}
                                        icon={objAttributesSeleccionConvocatoriaPerfil.icon.interesado}
                                        isPhoneVersion={isPhoneVersion}
                                    />
                                </Grid>
                            </ThemeProvider>
                        </section>
                        <section aria-label={properties.label_acces_metodoAcceso} className={styleSeparacionMetodoAcceso}>
                            <Grid ///////////////////////////METODOS DE ACCESO
                                {...objAttributesSeleccionConvocatoriaPerfil.grids.seccion3}
                                className={isPhoneVersion ? styleMetodosAccesoResponsive : styleMetodosAcceso}
                                item
                            >
                                <h3
                                    className={isPhoneVersion ? styleTitleMetodoAccesoResponsive : styleTitleMetodoAcceso}
                                >
                                    {objTextSeleccionConvocatoriaPerfil.titulos.terceraSeccion}
                                </h3>
                                {mostrarAcceso(attribCert.codigoPCT3) && 
                                <MetodoAcceso ///////////////////////////CERTIFICADO DIGITAL
                                    {...objAttributesSeleccionConvocatoriaPerfil.metodoAcceso.idGrid}
                                    icon={isPhoneVersion ? accesoConCertificadoResponsive : accesoConCertificado}
                                    titulo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.titulos.certificadoDigital}
                                    parrafo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.parrafo}
                                    buttonAtributtes={objAttributesSeleccionConvocatoriaPerfil.buttons.certificadoDigital}
                                    stateButton={setStateButtons(getEstadoBoton(attribCert))}
                                    opacity={getEstadoBoton(attribCert)}
                                    textButton={objTextSeleccionConvocatoriaPerfil.buttons}
                                    responsive={isPhoneVersion}
                                    metodoAcceso={onClickCertificado}
                                    metodo={properties.aria_acces_certificadoDigital}
                                />}
                                {mostrarAcceso(attribClave.codigoPCT3) && 
                                <MetodoAcceso ///////////////////////////ENTRADA CON CLAVE
                                    {...objAttributesSeleccionConvocatoriaPerfil.metodoAcceso.idGrid}
                                    icon={isPhoneVersion ? accesoSinCertificadoResponsive : accesoSinCertificado}
                                    titulo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.titulos.entradaConClave}
                                    parrafo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.parrafo}
                                    buttonAtributtes={objAttributesSeleccionConvocatoriaPerfil.buttons.entradaClave}
                                    stateButton={setStateButtons(getEstadoBoton(attribClave))}
                                    opacity={getEstadoBoton(attribClave)}
                                    textButton={objTextSeleccionConvocatoriaPerfil.buttons}
                                    responsive={isPhoneVersion}
                                    metodoAcceso={useLoginIdp}
                                    metodo={properties.aria_acces_clave}
                                />}
                                {mostrarAcceso(attribRegistro.codigoPCT3) && 
                                <MetodoAcceso ///////////////////////////ENTRADA DE USUARIO REGISTRADO
                                    {...objAttributesSeleccionConvocatoriaPerfil.metodoAcceso.idGrid}
                                    icon={isPhoneVersion ? accesoRegistradoResponsive : accesoRegistrado}
                                    titulo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.titulos.entradaRegistrado}
                                    parrafo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.parrafoRegistrado}
                                    buttonAtributtes={objAttributesSeleccionConvocatoriaPerfil.buttons.entradaRegistrado}
                                    stateButton={setStateButtons(getEstadoBoton(attribRegistro))}
                                    opacity={getEstadoBoton(attribRegistro)}
                                    textButton={objTextSeleccionConvocatoriaPerfil.buttons}
                                    responsive={isPhoneVersion}
                                    metodoAcceso={irAccesoConContrasena}
                                    metodo={properties.aria_acces_registrado}
                                />}
                                {mostrarAcceso(attribSinCert.codigoPCT3) && 
                                <MetodoAcceso ///////////////////////////ENTRADA SIN CERTIFICADO DIGITAL
                                    {...objAttributesSeleccionConvocatoriaPerfil.metodoAcceso.idGrid}
                                    icon={isPhoneVersion ? accesoConClaveResponsive : accesoConClave}
                                    titulo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.titulos.entradaSinCertificado}
                                    parrafo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.parrafoSinCertificado}
                                    buttonAtributtes={objAttributesSeleccionConvocatoriaPerfil.buttons.entradaSinCertificado}
                                    stateButton={setStateButtons(getEstadoBoton(attribSinCert))}
                                    opacity={getEstadoBoton(attribSinCert)}
                                    textButton={objTextSeleccionConvocatoriaPerfil.buttons}
                                    responsive={isPhoneVersion}
                                    metodoAcceso={(e) => postDatosSalto(e, 'an')}
                                    metodo={properties.aria_acces_sinCertificado}
                                />}
                                <MetodoAcceso ///////////////////////////Salto a la bandeja para usuarios logeados
                                    {...objAttributesSeleccionConvocatoriaPerfil.metodoAcceso.idGrid}
                                    icon={isPhoneVersion ? accesoConClaveResponsive : accesoConClave} //Cambiar
                                    titulo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.titulos.accesoABandeja}
                                    parrafo={objTextSeleccionConvocatoriaPerfil.metodoAcceso.parrafoUsuarioLogeado}//Cambiar
                                    buttonAtributtes={objAttributesSeleccionConvocatoriaPerfil.buttons.saltoABandeja}
                                    stateButton={setStateButtons(getEstadoBoton(attribSaltoBandeja))}
                                    opacity={getEstadoBoton(attribSaltoBandeja)}
                                    textButton={objTextSeleccionConvocatoriaPerfil.buttons}
                                    responsive={isPhoneVersion}
                                    metodoAcceso={compruebaUsuario}
                                    metodo={properties.aria_acces_entradaBandeja}
                                />
                            </Grid>
                        </section>
                    </Container>
                <Backdrop className={styleBackdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AvisosCriticos/>
                <VentanaUsuario handleChange={ventanaUsuarioSinAltaAbrirCerrar} mostrar={mostrarVentana} isPhoneVersion={isPhoneVersion}/>
                {alerts.map(m => (
                <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({severity: "", msg: ""})}>
                    <Alert
                        severity={message.severity}
                        variant="filled"
                        >
                        {message.msg}
                    </Alert>
                </Snackbar>
                ))}
            </Container >
        </Grid>
    }

    /***********************************************************************************
     * Return
     ***********************************************************************************/
    return (
        <main>
            {pintarPantalla ?
                (renderPantalla())
                : <Container  className={styleClassContaURL}>
                    <Backdrop className={styleBackdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                    </Backdrop>
                </Container>   
            }
        </main>
    )
}
