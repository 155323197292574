import React, { useEffect } from 'react'
import {customStyle} from './TabPanelStyle'
import {properties} from './TabPanelData';
import {Grid, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function TabPanel(props) {

  const style = customStyle();

  useEffect(() => {
    setFocusOnPanel();
  });

  const setFocusOnPanel = () => {
    let elemento = document.getElementById(properties.ID_TITULO_PANEL);
    if(elemento){
      elemento.focus();
    }
  }

  const renderRow = (fila, columna) =>{

    if(columna.tipoColumna === properties.SPECIAL_COLUMN_TYPE){
      return(
      <li className={style.elementosLista}>
        <Grid container alignItems='center'>
          <img src={ properties.MEDIA_PATH + fila.imagen} className={style.iconoFila}/>
          <a target="_blank" className={style.enlaces} href={fila.enlace}>{fila.texto}</a>
        </Grid>
      </li>
      )

    }else{
      return(
      <li className={style.elementosLista}>
        <a target="_blank" className={style.enlaces} href={fila.enlace}>{fila.texto}</a>
      </li>
      )
  }}
  

  const renderColumns = (columna) => {
    return(
      <Grid container className={columna.tipoColumna===properties.SPECIAL_COLUMN_TYPE ? style.gridColumnDirectoA : style.gridColumn} direction='column'>           
        <Grid item className={columna.tipoColumna===properties.SPECIAL_COLUMN_TYPE ? style.gridTituloDirectoA : null}>
          <a target="_blank" id={properties.ID_TITULO_PANEL} href={columna.enlace} className={style.tituloColumna}>{columna.tituloColumna}</a>
        </Grid>
        <Grid item className={columna.tipoColumna===properties.SPECIAL_COLUMN_TYPE ? style.gridListaDirectoA : null}>
          <ul className={style.lista}>
            {columna.filas.map((fila) =>{
              return renderRow(fila, columna)
            })}
          </ul>
        </Grid>
      </Grid>
    )
  }

  return ( 
    props.selectedSectionIndex === props.index && (
      <Grid container className={style.gridPanel} justify='center'>
        <div className={style.gridContenidoPanel}>
          {/*PARTE SUPERIOR*/}
          <Grid container className={style.gridSuperior} direction='row' justify='space-between' alignItems='center'>
            <Grid item>
              <a target="_blank" href={props.tabData.enlace} className={style.tituloPanel}>{props.tabData.tituloSeccion}</a>
            </Grid>
            <Grid item>
              <IconButton onClick={props.close} aria-label={properties.ARIA_LABEL_BTN_CERRAR}>
                <CloseIcon className={style.closeIcon}/>
              </IconButton>
            </Grid>
          </Grid>

          {/*PARTE INFERIOR*/}
          <Grid container className={style.gridInferior} direction='row' justify='center'>
            <ul className={style.lista}>
              {props.tabData.columnasPrincipales.map((columnaPrincipal) => {
                return (
                  <Grid container className={style.gridMainColumn} direction='column'>
                    {columnaPrincipal.columnas.map((columna) =>{
                      return ( <li>{renderColumns(columna)}</li>)
                    })}
                  </Grid>
                )
              })}
            </ul>
          </Grid>
        </div>
      </Grid >
    )
  )
}