import React, { useState, useEffect, useContext } from 'react'
import {customStyle, overrideStyle} from './HamburgerMenuStyle'
import {properties, texts} from './HamburgerMenuData';
import {Grid, Accordion, AccordionSummary, AccordionDetails, IconButton, ThemeProvider} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {properties as appProperties} from '../../../AppData';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuCuenta from '../../commons/menuCuenta/menuCuenta';
import MenuUsuario from '../../pages/menuUsuario/menuUsuarioVphone';
import { loadState } from './../../../providers/localStorage';
import {localStorageName} from './../../../providers/localStorageData';
import AppContext from 'AppContext';
import PopUp from '../../commons/popUp/popUp';
import PopUpHerramientas from '../popUpHerramientas/PopUpHerramientas';
import { menuCordoba } from '../header/HeaderData';

export default function HamburgerMenu(props) {

    const [isOpen, setIsOpen] = useState(false)
    const style = customStyle();
    const [ultConexion, setUltConexion] = useState(null);
    const contexto = useContext(AppContext);

    useEffect(() => {  
        if(contexto.sesion === appProperties.SESION_CIERRE){
            limpiarUltConexion();
        }  
    }, contexto.sesion) ;

    const handleChange = () =>{
        if(isOpen){
            setIsOpen(false);
        }else{
            setIsOpen(true);
        }
    }

    const limpiarUltConexion = () =>{
        setUltConexion(''); 
    }

    const cerrarMenu = () =>{
        setIsOpen(false); 
    }

    const [tlfProcedimiento, setTlfProcedimiento] = useState(null);
    const procedimientoActual = loadState(localStorageName.procedimientoActual, true);

    /**
     * Efecto creado para mostrar el número de teléfono asociado al procedimiento seleccionado
     */
    /**
     * 21-feb-2025 se comenta porque actualmente no se usa
      useEffect(() => {
        if (procedimientoActual !== null && procedimientoActual !== undefined) {
            getTlfPorProcedimiento(procedimientoActual.idTipoProcedimiento);
        } else {
            setTlfProcedimiento(null);
        }
    }, [procedimientoActual]); 
     * 
     */
      

    function transformarTexto(cadena){
        const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
        // Paso 1. Elimina los espacios en blanco y los sustituye por un _ y establece todas las letras en mayúsculas
        cadena = cadena.replace(/\s+/g, '_').toLocaleUpperCase();
        // Paso 2. Elimina los acentos de las letras que lo contengan
        return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
      }
    /**
     * @function getTlfPorProcedimiento Función que obtiene el teléfono de contacto del procedimiento seleccionado
     * @param {*} idProc Id del procedimiento seleccionado
     */
    const getTlfPorProcedimiento = (idProc) => {
        // Paso 1. Se obtienen los tipos de procedimientos obtenidos
        let tipoProcedimientosObtenidos = loadState(localStorageName.tipoProcedimientos, true);
        
        if (tipoProcedimientosObtenidos !== null && tipoProcedimientosObtenidos !== undefined) {
            // Paso 2. Se recorren los tipos de procedimientos obtenidos y se evalúa si su id conincide con el id del procedimiento seleccionado
            for (const procedimiento of tipoProcedimientosObtenidos) {
                if (procedimiento.id === idProc) {
                    // Paso 3. Se obtiene la descripción del procedimiento seleccionado y se obtiene el la propiedad del cloudconfig que contiene el número de tlf.
                    //let propertiObtenida = 'TELEFONO_SAC_' + transformarTexto(procedimiento.descripcion);
                    //setTlfProcedimiento(propertiesCloudConfig[propertiObtenida]);
                    break;
                }
            }
        }
    }
    
    const renderMenuFooter = () =>{
        return(
            <div className={style.gridContacto}>

                {!props.isLogeado ? (
                        <MenuUsuario handleChange={props.handleChange} cerrarMenu={cerrarMenu}/>
                    ) : (
                        <MenuCuenta isPhoneVersion={true} />
                    )
                    
                }
                <p className={style.ultimaConexion}>{ultConexion}</p>
                <div className={style.gridContacto}>
                    <div className={style.popUpBotones}>
                        <PopUp/>
                        <PopUpHerramientas/>
                    </div>
                    {
                        (tlfProcedimiento !== null && tlfProcedimiento !== undefined) && 
                            <Grid>
                                <p className={style.msgContacto}>{texts.MSG_CONTACTO}</p>
                                <Grid className={style.gridTelefono} container direction='row' alignItems='center' justify='center'>
                                    <img className={style.iconoTelefono} src={properties.TLFN_ICON_PATH} alt={texts.ALT_TLFNO}/>
                                    <p className={style.tlfn}>{tlfProcedimiento}</p>
                                </Grid>
                            </Grid>
                    }
                </div>
            </div>
        )
    }

    const renderAccordionsDetails = (seccion) => {
        return ( 
            <ul className={style.listaDesordenada}>{
                seccion.columnasPrincipales.map((mainColumn) =>{
                    return ( mainColumn.columnas.map((column) =>{
                        return(
                            column.tipoColumna !== properties.SPECIAL_COLUMN_TYPE ? 
                                <div>
                                    <li className={style.elementoLista}><a target="_blank" className={style.link} href={column.enlace}>{column.tituloColumna}</a></li>   
                                    <hr className={style.lineaMenu}></hr>
                                </div> 
                            : 
                                null
                        )          
                    }))
                })
            }</ul>
        )
    }

    const renderAccordions = () => {
        return ( 
            <div className={style.gridDesplegable}>
                {menuCordoba.map((seccion) =>{
                return (
                    <Accordion className={style.acordeon}>
                        <AccordionSummary expandIcon={<ArrowDropDownIcon className={style.iconoDesplegable}/>}>
                            <Grid container direction='row' alignItems='center'>
                                <Grid item><p className={style.tituloAcordeon}>{seccion.tituloSeccion}</p></Grid>
                            </Grid>      
                        </AccordionSummary>     
                        <AccordionDetails className={style.detallesAcordeon}>
                            {renderAccordionsDetails(seccion)}
                        </AccordionDetails>
                    </Accordion>
                )
                })}
                {renderMenuFooter()}
            </div>   
        )
    }

    // RENDER
    return(
        <ThemeProvider theme={overrideStyle}>
            <Grid container className={style.gridMenu} alignItems='center'>
                <IconButton onClick={handleChange}>
                    <MenuIcon alt={properties.ALT_ICONO_MENU} role='button'/>
                </IconButton>
                {isOpen && <Grid container className={style.gridAcordeon}>
                    {renderAccordions()}   
                </Grid>}    
            </Grid>
        </ThemeProvider>
    )
}