import React, {useState} from 'react';
import Popover from '@material-ui/core/Popover';
import { Slide, ThemeProvider } from '@material-ui/core';
import {style, customStyle} from './PopUpHerramientasStyle';
import {IconButton} from '@material-ui/core';
import MensajesAyuda from '../../pages/mensajesAyuda/mensajesAyuda';
import {properties, pages, textoTituloPopUp, listadoPreguntas} from './PopUpHerramientasData'

export default function PopUpHerramientas() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleChange = (event) => {
        let element = document.getElementById(properties.ID_BOTON);
        if(element){
            element.setAttribute(properties.ATRIBUTO_ARIA_EXPANDED, true)
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        let element = document.getElementById(properties.ID_BOTON);
        if(element){
            element.setAttribute(properties.ATRIBUTO_ARIA_EXPANDED, false)
        }
        setAnchorEl(null);
    };

    const paginaActual = () =>{
        const path = window.location.href.split('/').pop();
        switch(path) {
            case pages.HOME:
               return listadoPreguntas;
            default:
               return listadoPreguntas;
        }        
    } 

    return (
        <ThemeProvider theme={style}>
                <IconButton id={properties.ID_BOTON} onClick={handleChange} aria-expanded='false' aria-label={properties.ARIA_LABEL_BOTON} className={customStyle().iconButton}>
                    <img src={process.env.PUBLIC_URL + properties.SRC_IMG} alt={properties.ALT_IMG} className={customStyle().iconoAyuda}/>
                </IconButton>
                <Popover
                    open={open}
                    onClose={handleClose}
                    transitionDuration={'auto'}
                    anchorReference="anchorPosition"
                    disableScrollLock
                    anchorPosition={{ top: 0, left: 0 }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }
                    }
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                        }
                    }                   
                    >
                    <Slide className={customStyle().slide} direction="left" in={open} mountOnEnter unmountOnExit>
                        <div><MensajesAyuda handleChange={handleClose} preguntas={paginaActual()} textoTituloPopUp={textoTituloPopUp}/></div>
                    </Slide>
                </Popover>
        </ ThemeProvider>
    );
}