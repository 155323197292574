import React from 'react';
import { Grid, InputLabel, MenuItem, Select, MuiThemeProvider } from '@material-ui/core';
import Style, { overrideStyle } from './SelectStyle.js';

export default function SelectSimple(props) {
    /**
     * @constant
     * Contienen las props
     */
    const { label } = props; // Texto select
    const { options, onChange} = props; //Parametros
    let style = Style();

    return (
        <MuiThemeProvider theme={overrideStyle}>
        <Grid className={style.classGrid}>
            <InputLabel className={style.classInputLabel} shrink>{label}</InputLabel>
            <Select
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={onChange}
                displayEmpty
                className={style.classSelect}
                IconComponent={props.IconComponent}
                disableUnderline   
                disabled={props.disabled}      
            >
                {
                    options.map(val => (
                        <MenuItem
                        className={style.classMenuItem}
                        key={val.id}
                        value={val.descripcion}
                        >
                            {val.descripcion}
                        </MenuItem>
                    ))
                }
            </Select>
        </Grid>
        </MuiThemeProvider>
    );
}
