import { getContextFromUrl } from 'config/Config';

export const properties = {
    ID_BOTON: 'botonAyuda',
    ARIA_LABEL_BOTON : 'ayuda',
    ATRIBUTO_ARIA_EXPANDED: 'aria-expanded',
    SRC_IMG: '/popUpHerramientas/toolsSolid.svg',
    ALT_IMG: 'Herramientas y enlaces'
}

export const pages = {
    HOME: '',
}

export const textoTituloPopUp = {
    cabecera: {
        titulo: 'Herramientas y enlaces',
        parrafo: 'En esta sección puede encontrar diferentes enlaces o herramientas de utilidad',
    },
    button: 'Volver',
}

export const listadoPreguntas = [

    {
        id: '1001',
        descripcion: 'Herramientas',
        mensajes: [
            {
                idDescripcion: '001',
                descripcionMensaje: 'Descarga',
                idMensaje: '001',
                contenidoMensaje: '<a href="https://sedediatid.digital.gob.es/es-es/firmaelectronica/Paginas/AutoFirma.aspx" target="_blank">Autofirma</a>',
            },
            {
                idDescripcion: '002',
                descripcionMensaje: 'En linea',
                idMensaje: '002',
                contenidoMensaje: '<a href="https://www.cordoba.es/sede/verificacion-documentos/" target="_blank">Verificación de Documentos por CSV</a></br><a href="https://valide.redsara.es/valide/inicio.html" target="_blank">VALIDe</a></br><a href="/#/MatrizCompatibilidad" target="_blank">Matriz de Compatibilidad</a>',
            }
        ]
    },
    {
        id: '1002',
        descripcion: 'Identificación, firma y sello electrónico',
        mensajes: [
            {
                idDescripcion: '003',
                descripcionMensaje: 'Identificación y Firma',
                idMensaje: '003',
                contenidoMensaje: '<p><a href="https://www.cordoba.es/sede/sistemas-identificacion-firma-admitidos" target="_blank">Identificación</a> y <a href="https://firmaelectronica.gob.es/" target="_blank">Firma</a></p></br><a href="https://www.cordoba.es/sede/sellos-electr%C3%B3nicos" target="_blank">Sellos Electrónicos</a></br>',
            }
        ]
    },
    {
        id: '1003',
        descripcion: 'Administración Electrónica',
        mensajes: [
            {
                idDescripcion: '004',
                descripcionMensaje: '',
                idMensaje: '004',
                contenidoMensaje: '<a href="https://www.cordoba.es/sede/calendario-oficial" target="_blank">Calendario Oficial</a></br><a href="https://www.cordoba.es/tramites/catalogo-de-procedimientos-y-servicios" target="_blank">Catálogo de Procedimientos y Servicios</a></br><a href="https://www.cordoba.es/sede/codigos-de-identificacion-dir3" target="_blank">Órganos y unidades administrativas</a></br><a href="https://www.cordoba.es/tramites/general/reclamaciones-quejas-sugerencias-e-iniciativas" target="_blank">Presentación de Sugerencias y Reclamaciones</a></br><a href="https://dehu.redsara.es/" target="_blank">Notificaciones Electrónicas</a></br>',
            },
        ]
    }
]