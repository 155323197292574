import { makeStyles } from '@material-ui/core';
import { tipografia, colores, links } from '../../../AppStyle';

export const customStyle = makeStyles({

    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerGrid: {
        width: '100%',
        height: '100px',
        padding: '2rem 0.5rem 2rem 0.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colores.principales.hover
    },
    footerGridEmpty: {
        width: '100%',
        display: 'flex',
        paddingLeft: 'calc(30px * 0.5)',
        paddingRight: 'calc(30px * 0.5)',
        justifyContent: 'center',
        margin: 'auto',
        backgroundColor: colores.principales.hover
    },
    footerGridWrapper: {
        width: '70%',
        display: 'flex',
        paddingTop: '1.5%'
    },
    footerGridWrapperPhone: {
        flexDirection: 'column'
    },
    bottomSectionWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '60%',
        margin: '20px auto 20px auto'
    },
    bottomSectionWrapPhone: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        margin: '20px auto 20px auto',
        flexDirection: 'column'
    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    bottomSectionLogos: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
    },
    bottomSectionLogosPhone: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '90%'
    },
    bottomSectionIcons: {
        marginRight: '0',
        marginLeft: '-180px',
        maxWidth: '225px',
        '&:focus': {
            outline: 'none',
        },
    },
    websiteRightsWrapper: {
        margin: '0 0 0 0'
    },
    websiteRightsWrapperPhone: {
        alignSelf: 'center',
        marginTop: '5%'
    },
    acLogo: {
        width: '100%',
        alignSelf: 'center',
        '&:hover': {
            opacity: 0.8,
        },
    },
    jaLogoMock: {
        marginRight: '20px',
        width: '100%',
        height: '150px'
    },
    jaLogoPhone: {
        margin: '5px',
        height: '60px',
        display: 'inline-flex'
    },
    jaLogoPhoneMock: {
        marginRight: '25px',
        height: '100px'
    },
    infoList: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '10px',
    },
    websiteRights: {
        color: colores.principales.blanco,
        fontSize: '1.1rem',
        marginLeft: '10px',
        marginRight: '10px',
        fontFamily: tipografia.regular,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
            outline: 'none'
        }
    },
    footerMainColumn: {
        margin: '20px',
        maxWidth: '350px'
    },
    footerColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flexStart',
        margin: '16px',
        textAlign: 'left',
        boxSizing: 'border-box'
    },
    footerList: {
        listStyleType: 'none'
    },
    footerRrssItemsWrapper: {
        display: 'flex',
        width: '35%',
        justifyContent: 'flex-end',
        marginLeft: '130px',
        marginRight: '-130px',
    },
    footerRrssIconssWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
    },
    footerRrssNameWrapper: {
        display: 'flex',
        width: '55%',
        justifyContent: 'center',
        marginRight: '-40px',
    },
    footerMoreInfo: {
        padding: '10px 0px 0px -20px',
    },
    footerLeftWrapper: {
        display: 'flex',
        width: '60%',
        justifyContent: 'start',
    },
    colFooterTittle: {
        fontFamily: tipografia.regular,
        fontWeight: '700',
        fontStyle: 'normal',
        fontSize: '22px',
        lineHeight: '24px',
        color: colores.escalaGrises.cero,
        marginBottom: '20px',
        cursor: 'default'
    },
    colFooter: {
        fontFamily: tipografia.regular,
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '15px',
        lineHeight: '24px',
        color: colores.escalaGrises.cero + " !important",
        textDecoration: 'none',
        marginBottom: '15px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'underline',
            outline: 'none'
        },
    },
    prueba: {

    },
    colFooterRRSS: {
        fontFamily: tipografia.regular,
        marginLeft: '20px',
        fontWeight: '400',
        fontStyle: 'normal',
        lineHeight: '24px',
        color: colores.principales.blanco,
        '&:focus': {
            outline: 'none',
        },
    },
    colFooterIconInfo: {
        fontFamily: tipografia.regular,
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '15px',
        lineHeight: '24px',
        color: colores.principales.blanco,
        textDecoration: 'none',
        marginBottom: '15px',
        cursor: 'pointer',
        '&:focus': {
            border: '1px solid white',
            outline: 'none'
        },
    },
    footerColumnLi: {
        color: colores.escalaGrises.cero,
        textDecoration: 'none',
        marginBottom: '15px',
        cursor: 'pointer'
    },
    rrssIcons: {
        color: colores.principales.blanco,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fill: colores.escalaGrises.cero,
        width: '21px',
        paddingTop: '2px',
        '&:hover': {
            outline: 'none', //'white solid 1px'
            opacity: 0.8
        },
    },
    contactoIcons: {
        color: colores.principales.blanco,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fill: colores.escalaGrises.cero,
        width: '21px',
        paddingTop: '2px',
        '&:hover': {
            outline: 'none', //'white solid 1px'
        },
    },
    liStyle: {
        listStyleType: 'none'
    },
    gridFooter: {
        width: '100%',
        padding: '2rem 0.5rem 2rem 0.5rem',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: colores.escalaGrises.cien
    },
    bottomSection: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        background: colores.escalaGrises.cinco
    },
    classLinkFooter: {
        ...links
    },
    gridWrapper: {
        display: 'flex'
    },
    gridColFooter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '16px',
        textAlign: 'left',
        boxSizing: 'border-box'
    },
    bottomSecctionWrapp: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        margin: '40px auto 40px auto',
    },

    gridContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: '5px',
        alignItems: 'center',
        backgroundColor: colores.principales.hover,
    },
    gridItem: {
        marginRight: '5px'
    },
    listContainer: {
        listStyleType: 'none',
        margin: '20px 0px 10px 0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    customLink: {
        color: 'white',
        fontSize: '1rem',
        display: 'block',
        width: '100%',
        overflow: 'visible',
        whiteSpace: 'nowrap',
        marginRight: '40px',
        textAlign: 'center',

    }

});