
import { useState, useContext } from 'react';
import { objects, properties, texts, msgError } from './PageAltaAnexoData';
import { localStorageName } from '../../../providers/localStorageData';
import { loadState, saveState } from '../../../providers/localStorage';
import { properties as appProperties } from 'AppData';
import { useHistory } from 'react-router-dom';
import RestProvider from 'providers/RestProvider';
import AppContext from 'AppContext';
import useApp from 'AppFunctions';

const useFunctions = () => {

    // Objeto que contendrá los valores de los apartados dónde se anexarán los documentos
    const objValues = {
        'val0': '',
        'val1': '',
        'val2': '',
        'val3': '',
        'val4': '',
        'val5': '',
        'val6': '',
        'val7': '',
        'val8': '',
        'val9': '',
        'val10': '',
    };

    const [documentos, setDocumentos] = useState([]);
    const [disabledBotonFirmar, setDisabledBotonFirmar] = useState(true);
    const [disabledBtnPresentSubsa, setDisabledBtnPresentSubsa] = useState(true);
    const [values, setValues] = useState({ ...objValues });
    const [documentosAnexos, setDocumentosAnexos] = useState();
    const [textoError, setTextoError] = useState();
    const [mostrarError, setMostrarError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [documentosFirma, setDocumentosFirma] = useState([]);
    const [msgErrorPresentacion, setMsgErrorPresentacion] = useState();
    const [mostrarErrorPresentacion, setMostrarErrorPresentacion] = useState();
    const [docsAfirmar, setDocsAfirmar] = useState([]);
    const [entidades, setEntidades] = useState([]);
    const [mostrarConfirmacionFirma, setMostrarConfirmacionFirma] = useState(false);
    const [mostrarMsgSalir, setMostrarMsgSalir] = useState(false);

    const { crudOperation, crudOperationsType } = RestProvider();
    const { firmaGenericaMasiva, calcularHashBase64 } = useApp();
    const history = useHistory();
    const context = useContext(AppContext);

    // Hooks de gestión de mensajes de errores
    let idAlert = 0;
    const [message, setMessage] = useState({ msg: '', severity: '' });
    const addAlert = msg => setAlerts([...alerts, { id: idAlert++, msg }]);
    const [alerts, setAlerts] = useState([]);
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    /**
     * @vars Valores obtenidos de localStorage
     */
    let solActual = loadState(localStorageName.solicitudActual, true);
    let procedimiento = loadState(localStorageName.procedimientoActual, true);
    let convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);
    let userLogin = loadState(localStorageName.infoUsuario, true);
    let fase = loadState(localStorageName.fase, true);
    let estado = loadState(localStorageName.estado, true);
    let perfil = loadState(localStorageName.perfilSeleccionado, true);
    let entidadesMostradas = loadState(localStorageName.entidadesDeAlta, true);
    let faseEstadoExpediente = loadState(localStorageName.faseEstadoExpediente, true);

    const PREFIJO_OBJETO_FILE_PDF = 'data:application/pdf;base64,';

    /**
     * @function handleOnDrop Gestión de la subida de documentos
     * @param {*} files Fichero subido por el usuario
     * @param {*} id Del anexo
     */
    const handleOnDrop = (files, id) => {
        // Paso 1. Obtención del indice dónde se encuentra el anexo en el array documentosAnexos
        let index = documentosAnexos.findIndex(a => a.id === id);

        if (index !== -1) {
            
            // Obtención del anexo
            let anexoObtenido = documentosAnexos[index];

            // Paso 2. Se crea el documento a insertar
            let documentoA = {
                id: anexoObtenido.id === null ? id : anexoObtenido.id,
                fichero: files[0],
                anexo: null,
                descripcion: anexoObtenido.value,
                firmado: false,
                nombreFichero: files[0].file.path,
                nombre: files[0].file.path
            }

            anexoObtenido.pdf = files[0].data;
            documentoA.fichero.data = files[0].data.split('base64,')[1];

            // Paso 3. Se inserta el documento que se almacena en el array documentos, el cual se almacenará en BBDD
            documentos.push(documentoA);

            // Paso 4. Se añade el documento a los documentos que serán firmados posteriormente
            addDocsFirma(documentoA);

            // Paso 5. Se realiza el tratramiento del anexo
            tratamientoAnexo(files[0].file.size, index);

            // Paso 6. Se actualiza el array documentosAnexos con la nueva modificación
            setDocumentosAnexos([...documentosAnexos]);            
        }
    }

    /**
     * @function addDocsFirma Función que añade los documentos a firmar
     * @param {*} doc 
     */
    const addDocsFirma = (doc) => {
        // Paso 1. Se realiza una búsqueda en el array para comprobar si existe el documento
        let existeDoc = docsAfirmar.length !== 0 ? docsAfirmar.find(d => d.id === doc.id) : false;
        
        if (existeDoc) {
            // Paso 2. Si existe, se obtiene la posición en la que se encuentra en el array y se sustituye por el documento actual
            let index = docsAfirmar.findIndex(d => d.id === doc.id);
            docsAfirmar.splice(index, 1, doc);
        } else {
            // Paso 3. Si no existe, se añade al array
            docsAfirmar.push(doc);
        }
        // Paso 4. Comprueba si se debe mostrar el botón de firma y el botón de presentar subsanación
        mostrarBtnFirma();
        mostrarBtnPresentSubsa();
    }

    /**
     * @function tratamientoAnexo Método que actualiza el anexo modificado en el array de anexos
     * @param {*} pesoDoc tamaño del documento subido
     * @param {*} index posición del anexo a modificar en el array documentosAnexos
     */
    const tratamientoAnexo = (pesoDoc, index) => {
        // Actualización del anexo modificado
        documentosAnexos[index].mostrarDocAnexo = true;
        documentosAnexos[index].tamDoc = (pesoDoc / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES);
    };

    /**
     * @function handleChange Gestión de la subida de documentos
     * @param {*} e Fichero subido por el usuario
     */
    const handleChange = (e, anexo, campo) => {
        let valor = e.target.value;

        // Paso 1. Se actualiza el valor del anexo en el objeto objectsValues
        setValues(prevValores => ({
            ...prevValores,
            [campo]: valor
        }));

        // Paso 2. Se obtiene la posición del anexo en el array documentosAnexos
        let index = documentosAnexos.findIndex(a => a.id === anexo.id);
        
        if (index !== undefined && index !== null) {
            
            // Paso 3. Se actualizan los valores del anexo en el array documentosAnexos
            documentosAnexos[index].mostrarDropZone = (valor !== undefined && valor !== null && valor !== '');
            documentosAnexos[index].value = valor;
            setDocumentosAnexos([...documentosAnexos]);
        }
    };

    /**
    * @function eliminarAnexo Función que elimina el anexo seleccionado
    * @param {*} id Id del anexo a eliminar
    */
    const eliminarAnexo = async (e, id, campo) => {
        let valor = e.target.value;

        // Paso 1. Se obtiene la posición del array en la que se encuentra el anexo a eliminar.
        let index = documentosAnexos.findIndex(a => a.id === id);

        // Estructura de control que comprueba que se haya obtenido la posición.
        if (index !== -1) {
            /* Paso 2. Se comprueba si se trata de un anexo ya almacenado en BBDD. 
             * Se comprueba si no es de tipo string, ya que el id por defecto es anexo+numeroPosicion, si el anexo está almacenado en BBDD,
             * el id estará constituido por el id del documento de BBDD.
            */
            typeof id !== 'string' && await deleteAnexo(id);
            
            // Paso 3. Se reestablecen los valores del anexo que se muestra por pantalla.
            documentosAnexos[index].mostrarDropZone = false;
            documentosAnexos[index].value = '';
            documentosAnexos[index].mostrarDocAnexo = false;
            documentosAnexos[index].pdf = '';
            documentosAnexos[index].id = `anexo${index}`;
        }

        // Paso 4. Se actualiza la hook value.
        setValues(prevValores => ({
            ...prevValores,
            [campo]: valor
        }));

        // Paso 5. Se elimina el anexo del array de documentos que serán almacenados en BBDD
        let indexDoc = documentos.findIndex(d => d.id === id);
        indexDoc !== -1 && documentos.splice(indexDoc, 1);

        // Paso 6. Se elimina el anexo del array de documentos que se firmarán
        let indexDocFirma = docsAfirmar.findIndex(d => d.id === id);
        indexDocFirma !== -1 && docsAfirmar.splice(indexDocFirma, 1);

        // Paso 7. Comprueba si se debe mostrar el botón de firma y el botónt de presentar subsanación
        mostrarBtnFirma();
        mostrarBtnPresentSubsa();
    };

    /**
     * @function mostrarConfirmarError Método que hace saltar el componente mostrar cuando un anexo subido es rechazado
     * @param {*} rejectedFile Fichero rechazado
     * @param {*} tamMaxDoc tamaño maximo permitido para el documento
     */
    const mostrarConfirmarErrorFichero = (rejectedFile, tamMaxDoc) => {
        setTextoError(texts.TEXTO_ERROR_DROP_REJECTED1 + tamMaxDoc + texts.TEXTO_ERROR_DROP_REJECTED2);
        setMostrarError(true);
    };

    /**
     * @function obtenerEntidades Función que obtiene las entidades insertadas en el formulario de solicitud
     */
    const obtenerEntidades = async () => {
        setIsLoading(true);

        let entidadesObtenidas = [];
        let form = null;
        let idFaseInicio = null;

        // Paso 1. Obtención del id de la fase de inicio
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_FASE, {url: properties.URL_COD_FASE + properties.COD_FASE_INICIO, method: 'GET' }).then(response => {
            idFaseInicio = response.data.id;
        }).catch(() => {
            handleSetMessage(msgError.error, msgError.severity);
            setIsLoading(false);
        });

        let input = {
            idConvocatora: convocatoria.id,
            idExpediente: solActual.idExpediente,
            idFase: idFaseInicio,
            campoConcreto: "datosCooperacion"
        };
        
        // Paso 2. Obtención de los valores necesarios del formulario
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_FORMULARIO_EXPEDIENTE, {url: properties.URL_FORMULARIO, method: 'post', data: input }).then(response => {
            form = response.data;
        }).catch(() => {
            handleSetMessage(msgError.error, msgError.severity);
            setIsLoading(false);
        });
        // Paso 3. Obtención de las entidades participantes en el proyecto colaborativo
        if (form !== undefined && form !== null && form.length !== 0) {

            // Paso 3.1. Se realiza la búsqueda de los objetos que contienen las entidades y almacenamos los valores de nombre y tipo
            var nombres = {}
            var tipos = {}

            for (var i = 0; i < form.length; i++) {
                for (var x = 0; x < 6; x++) {
                    if (form[i].nombreCampo === "datosCooperacion_miembrosDeLaAgrupacion_" + x + "_nombreDeLaEntidadMiembros") {
                        nombres[x] = form[i].valorCampo
                    } else if (form[i].nombreCampo === "datosCooperacion_miembrosDeLaAgrupacion_" + x + "_tipoDeEntidadMiembros") {
                        tipos[x] = form[i].valorCampo
                    }
                }
            }
            
            /**
             * Paso 3.2. Se obtiene las entidades participantes, se genera un objeto y se insertan en el array de respuesta (entidadesObtenidas).
             * También se crea otro objeto (entidades) que se almacenará en el localStorage, que servirá a modo de bandera para mostrar u ocultar elementos
             * de las entidades.
             */

            let entidadesExistentes = [];

            for (var i = 0; i < Object.keys(nombres).length; i++) {
                let tpEntidad = tipos[i]
                let nombreEntidad = nombres[i]

                entidadesObtenidas.push({
                    tipoEntidad: tpEntidad,
                    nombre: nombreEntidad
                });
                entidadesExistentes.push({
                    idEntidad: null,
                    entidad: nombreEntidad,
                    entidadDeAlta: false,
                    idAnexo: null,
                    anexoAdjuntado: false
                });
            }

            // Paso 4. Se almacena en localStorage las entidades existentes
            if (entidadesMostradas === undefined) {
                saveState(localStorageName.entidadesDeAlta, entidadesExistentes, true);
            }

        }

        setEntidades([...entidadesObtenidas]);
        setIsLoading(false);

        return entidadesObtenidas;
    }

    /**
     * @function initAnexos Función que obtiene os anexos
     * @param {*} entidades 
     */
    const initAnexos = async () => {
        setIsLoading(true);
        let entidadesObtenidas = await obtenerEntidades();
        let anexosObtenidos = [];

        if (entidadesObtenidas !== undefined && entidadesObtenidas !== null && entidadesObtenidas.length !== 0) {

            // Paso 1. Se obtienen el nombre de los proyectos colaborativos para insertarlos en cada anexo
            for (let i = 0; i < entidadesObtenidas.length; i++) {
                anexosObtenidos.push({ ...objects.documentoAnexo, titulo: entidadesObtenidas[i].nombre, id: `anexo${i}` });
            }
        }

        setDocumentosAnexos(anexosObtenidos);
        mostrarBtnFirma();
        mostrarBtnPresentSubsa();
        setIsLoading(false);
    }    

    /**
     * @function mostrarBtnFirma Función que gestiona la visibilidad del botón Inciar Firma
     */
    const mostrarBtnFirma = () => {
        // Se realiza la llamada antes que el seteo de la hook entidades, por eso se comprueba previamente que el tamaño de la hook no sea 0
        let estado = (isSubsanacion() && docsAfirmar.length !== 0) ? false : ((entidades.length === 0) ? true : docsAfirmar.length !== entidades.length);
        setDisabledBotonFirmar(estado);
    }

    /**
     * @function mostrarBtnPresentSubsa Función que gestiona la visibilidad del botón Presentar Subsanación
     * Se mostrará en caso de que no se haya anexado ningún documento y en caso de que se trate de una subsanación
     */
    const mostrarBtnPresentSubsa = () => {
        let estado = (docsAfirmar.length > 0);
        setDisabledBtnPresentSubsa(estado);
    }

    /**
     * @function deleteAnexo Función que elimina un anexo a través de su id en bbdd
     * @param {*} idDoc 
     * @returns 
     */
    const deleteAnexo = async (idDoc) => {
        setIsLoading(true);
        let res = null;

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DOCUMENTO, { url: properties.URL_ELIMINAR_ANEXO + idDoc + '/expediente/' +solActual.id, method: 'get' }).then(response => {
            res = response.data;
        }).catch(() => {
            handleSetMessage(msgError.error, msgError.severity);
        });

        setIsLoading(false);
        return res;
    }

    /**
     * @function firmar Función que realiza la el proceso de firma de los documentos anexos adjuntados
     */
    const firmar = async () => {
        // Se extraen los ficheros de los documentos obtenidos
        let documentosObtenidos = [];
        
        if (docsAfirmar.length !== 0) {
            for (const doc of docsAfirmar) {
                documentosObtenidos.push(doc.fichero.data);
            }
            firmaGenericaMasiva(documentosObtenidos, realizarPresentacion, null);
        } else if (docsAfirmar.length === 0 && isSubsanacion()) {
            await realizarPresentacion();
        }
    }

    /**
     * @function inputFirma Función que genera el objeto input para el proceso de firma
     * @param {*} firma 
     * @returns 
     */
    const inputFirma = async (firma) => {
        let sinEntidades = (docsAfirmar === undefined || docsAfirmar.length === 0);
        let enumeradoSubsanacion = sinEntidades ? properties.ENUMERADO_FIR_SUBSA_SIN_ENTI : properties.ENUMERADO_FIR_SUBSA;
        return {
            enumProcesoEntrega: isSubsanacion() ? enumeradoSubsanacion : properties.ENUMERADO_FIR_ANEXOS,
            idExpediente: solActual.id === undefined ? solActual.idExpediente : solActual.id, 
            numeroExpediente: solActual.numeroExpediente,
            idConvocatoria: convocatoria.id,
            dniLogin: userLogin.interesadoNif,
            numeroSerieCertificadoLogin: userLogin.datosCertificado ? userLogin.datosCertificado.numeroSerie : null,
            isloginCertificadoDigital: userLogin.tipoAutenticacion === appProperties.TIPO_AUTENTICACION_CERTIFICADO ? true : false,
            descripcionConvocatoria: convocatoria.descripcion,
            fechaInicioPresentacion:new Date(convocatoria.fechaInicioPresentacion),
            fechaFinPresentacion: new Date(convocatoria.fechaFinPresentacion),
            idProcedimiento: procedimiento.id,
            descripcionProcedimiento: procedimiento.descripcion,
            usuarioOrve: convocatoria.codOrveUsuario,
            passwordOrve:convocatoria.codOrvePassword,
            asuntoOrve: convocatoria.codOrveAsunto,
            destinatarioOrve: convocatoria.codOrveDestinatario,
            listDocumentos: !sinEntidades ? await montarDocumentosFirma(firma) : [],
            fase: fase.codigo,
            estado: estado.codigo,
            perfil: perfil
        };
    }

    /**
     * @function montarDocumentosFirma Función que monta los documentos de firma
     * @param {*} signatureBase64 
     * @returns 
     */
    const montarDocumentosFirma = async (signatureBase64) => {
        let documentosObtenidos = [];
        let firmas = signatureBase64.split(':');
        setDocumentosFirma([]);

        for (let i = 0; i < docsAfirmar.length; i++) {
            documentosObtenidos.push({
                firmaBase64: firmas[i],
                hashDocumentoBase64: calcularHashBase64(docsAfirmar[i]),
                documento: docsAfirmar[i].fichero.data,
                nombreDocumento: docsAfirmar[i].nombre,
                tipoDocumento: properties.CODIGO_TIPO_DOCUMENTO,
                descripcionDocumento: docsAfirmar[i].descripcion,
                firmado: true
            }); 
        }
        setDocumentosFirma([...documentosObtenidos]);
        return documentosObtenidos;
    }

    /**
     * @function realizarPresentacion Función que realiza la presentación de los documentos, firmado y la transición del expediente.
     * También se utiliza para la subsanación de entidades y cuando no se dispongan de entidades a subsanar
     */
    const realizarPresentacion = async (signatureBase64) => {
        setIsLoading(true);
        let input = await inputFirma(signatureBase64);

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_PRESENTACION_ENTREGA, { url: '', method: 'post', data: input}).then(response => {
            saveState(localStorageName.numRegistroOrve, response.data.numRegistroOrve, false);
            saveState(localStorageName.recibi, response.data.recibi, false);
            saveState(localStorageName.numeroExpediente, response.data.numeroExpediente, false);
            saveState(localStorageName.idExpediente, response.data.idExpediente, false);
            localStorage.removeItem(localStorageName.entidadSeleccionada);
            localStorage.removeItem(localStorageName.entidadesDeAlta);
            setIsLoading(false);
            !isSubsanacion() ? setMostrarConfirmacionFirma(true) : irAsolicitudPresentada();
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) {
                let msg = error.response.data.messageDescription;     
                setMsgErrorPresentacion(msg + texts.PARTE_2_MSG_ERROR);
            } else {
                setMsgErrorPresentacion(texts.PARTE_1_MSG_ERROR + texts.PARTE_2_MSG_ERROR); 
            }
            setMostrarErrorPresentacion(true);
        });
    }

    /**
     * @function volverAbandeja Función que realiza el traslado a la bandeja
     */
    const volverAbandeja = async () => {
        await eliminarEntidadesColaboradoras();
        setMostrarMsgSalir(false);
        history.push('/BandejaSolicitudes');
    }

    const irAsolicitudPresentada = () => {
        history.push('/SolicitudPresentadaCorrectamente');
    }

    /**
     * @function updateFormat Segun el num de pixeles del ancho de la pantalla pone version movil o version ordenador
     */
    const updateFormat = () => {
        if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    /**
     * @function cerrarConfirmacionFirma Función que cierra el popup informativo y ejecuta la función ir a solicitudPresentada
     */
    const cerrarConfirmacionFirma = () =>  {
        setMostrarConfirmacionFirma(false);
        irAsolicitudPresentada();
    }

    /**
     * @function cerrarConfirmacionErrorFirma Función que cierra el popup informativo
     */
    const cerrarConfirmacionErrorFirma = () => {
        setMostrarErrorPresentacion(false);
    }

    const irFormularioAltaEntidadColaboradora = (e, entidad) => {
        saveState(localStorageName.entidadSeleccionada, entidad, false);
        history.push('/AltaEntidadColaboradora');
    }

    /**
     * @function comprobarEstadoEntidad Función que obtiene el estado de una entidad
     * @param {*} nombreEntidad 
     * @param {*} entidad Nombre de la entidad
     */
    const comprobarEstadoEntidad = (entidad) => {
        let res = false;
        
        if ((entidadesMostradas !== undefined && entidadesMostradas !== null) && (entidad !== undefined && entidad !== null)) {
            // Se obtiene la entidad que se recibe por parámetros
            let entidadObtenida = entidadesMostradas.filter(e => e.entidad === entidad)[0];
        
            // Se devuelve el valor booleano que entidadDeAlta que indica si ya se ha dado de alta la entidad
            
            res = isSubsanacion() ? true : entidadObtenida.entidadDeAlta;
        }
        return res;
    }

    /**
     * @function comprobarAllEstadosEntidades Función que comprueba si todas las entidades mostradas han sido dadas de alta
     * @returns Devolverá un valor booleano que indicará si han sido dadas de altas todas las entidades
     */
    const comprobarAllEstadosEntidades = () => {
        let res = false;
        
        // En caso de que el expediente esté en estado pendiente de subsanación de alta de anexo, las entidades ya han sido dadas de alta
        if (isSubsanacion() && values) {
            res = true;
        } else if (entidadesMostradas !== undefined && entidadesMostradas !== null && entidadesMostradas.length !== 0) {
            
            // Se comprueba si alguna de las entidades mostradas no ha sido de alta
            let entidadesObtenidas = entidadesMostradas.filter(e => e.entidadDeAlta === false);

            // Si no se ha obtenido ninguna entidad que no esté dada de alta devolverá true, de lo contrario false
            res = (entidadesObtenidas.length === 0);
        } 

        return res;
    }

    const mostrarMsg = () => {
        setMostrarMsgSalir(true);
    }

    const dejarMostrarMsgSalir = () => {
        setMostrarMsgSalir(false);
    }

    /**
     * @function eliminarEntidadesColaboradoras Función que elimina las entidades colaboradoras asociadas al expediente
     * @returns 
     */
    const eliminarEntidadesColaboradoras = async () => {
        let res = null;
        setIsLoading(true);
        if (entidadesMostradas === undefined) {
            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_INTERESADO, { url: properties.URL_DELETE_INTERESADO + solActual.idExpediente , method: 'delete'}).then(response => {
                res = response.data;
            }).catch(function (error) { 
                setIsLoading(false);
            });
        }
        setIsLoading(false);
        return res;
    }

    /**
     * @function isSubsanacion Función que indica si el expediente se encuentra en el estado pendiente de subsanación de alta de anexo
     * @returns 
     */
    const isSubsanacion = () => {
        return estado !== undefined && estado.codigo !== undefined && estado.codigo === 'E_PENDSUB_ALTA_ANEXO';
    }

    return {
        handleOnDrop, handleChange, eliminarAnexo, mostrarConfirmarErrorFichero, initAnexos,
        volverAbandeja, updateFormat, cerrarConfirmacionErrorFirma, comprobarEstadoEntidad,
        firmar, cerrarConfirmacionFirma, irFormularioAltaEntidadColaboradora,
        disabledBotonFirmar, documentosAnexos, values, isLoading, alerts, mostrarMsgSalir, dejarMostrarMsgSalir,
        message, setMessage, isPhoneVersion, msgErrorPresentacion, mostrarMsg, eliminarEntidadesColaboradoras,
        comprobarAllEstadosEntidades, mostrarErrorPresentacion, mostrarConfirmacionFirma, isSubsanacion, disabledBtnPresentSubsa
    };
};

export default useFunctions;