import React, { useEffect } from 'react';
import { Grid, CircularProgress, Backdrop } from '@material-ui/core';
import useStyles  from  './EntregasStyle';
import useFunctionsDesestimiento from './EntregasFunctions';
import {texts, properties, formato} from './EntregasData';
import SubirArchivoVoluntario from 'components/commons/subirArchivoVoluntario/SubirArchivoVoluntario';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import { compProperties, properties as appProperties } from '../../../AppData';
import { loadState } from 'providers/localStorage';
import { localStorageName } from 'providers/localStorageData';

export default function Entregas() {  

    const { isPhoneVersion, obtenerTituloPagina, updateFormat, documentosDesistir, handleOnDrop, eliminarAnexoVoluntario, mostrarConfirmarError,
        cerrarErrorDropRejected, errorDropRejected, tamDropRejected, volverABandeja, disabledBotonFirmar, pdf,
        mostrarConfirmacion, mostrarConfirmarFirma, accionFirmar, dejarDeMostrarConfirmacion, isLoading, mostrarErrorPresentacion,
        msgErrorPresentacion, dejarDeMostrarErrorPresentacion, mostrarPopUpDesOk, filePdf, obtenerAccionActual, accion} = useFunctionsDesestimiento();

    const { style } = useStyles(isPhoneVersion);
    const customStyle = style();
    useEffect(() => {
        obtenerTituloPagina();
        updateFormat();
        obtenerAccionActual();
    }, []);

    let solicitudActual = loadState(localStorageName.solicitudActual, true);

    window.addEventListener('resize', updateFormat);

    return(
        <main>
            <Grid className={customStyle.mLeft}>
                <Grid className={customStyle.gridTitulo} >
                    <h2 className={customStyle.hclassText}>{accion.nombreAccion}</h2>
                    {solicitudActual.numeroExpediente && <span className={customStyle.idExpediente}>{solicitudActual.numeroExpediente}</span>}
                    {solicitudActual.idExpediente && <span className={customStyle.idExpediente}>{solicitudActual.idExpediente}</span>}
                </Grid>
                <Grid className={customStyle.backgroundRectangle}>
                    <h3 className={customStyle.hclassText2}>
                        {texts.TEXT_INFO}
                    </h3>
                    <h3 className={customStyle.hclassText2}>
                        {texts.TEXTO_TAMAÑO_MAX_ANEXO}{properties.TAM_MAX_ANEXO}{texts.TEXTO_MB}
                    </h3>
                    <SubirArchivoVoluntario img={properties.PATH_IMG_PDF} tamMaximoArchivo={properties.TAM_MAX_ANEXO} formato={formato} handleOnDrop={(files, rejectedFiles) => handleOnDrop(files, rejectedFiles, null)}
                            value={documentosDesistir[0].value} mostrarDrop={documentosDesistir[0].mostrarDropZone} 
                            mostrarDocAnexo={documentosDesistir[0].mostrarDocAnexo} tamDoc={documentosDesistir[0].tamDoc}
                            bottonFunction={eliminarAnexoVoluntario} handleRejection={(rejectedFiles) => mostrarConfirmarError(rejectedFiles, properties.TAM_MAX_ANEXO)} 
                            isDisabled={true} pdf={filePdf} poderVisualizar={true}
                    />
                
                </Grid>
                {/*Confirmar mensaje error cuando sobrepasa el tamTotal de los docs*/}
                <Confirmacion 
                    accionConfirmacion={cerrarErrorDropRejected}
                    titulo={texts.TITULO_CONFIRMARPESOSOBREPASADO} 
                    mostrar={errorDropRejected} 
                    isPhoneVersion={isPhoneVersion}
                    mensaje={texts.TEXTO_ERROR_DROP_REJECTED1 + tamDropRejected + texts.TEXTO_ERROR_DROP_REJECTED2}
                    botonera={compProperties.SOLO_CERRAR}
                    quitarImg={false}
                    tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                    isPopUpPdf={false}
                    textBotonConfirmarPersonalizado={false}
                    isImagenPersonalizada={true}
                    centrarContenido={true}
                    imagen={properties.IMAGEN_ERROR}
                    isTextoEnriquecido={true}
                    />
                {/*Confirmar la firma*/}
                <Confirmacion
                    mostrar={mostrarConfirmarFirma} 
                    isPhoneVersion={isPhoneVersion}
                    titulo={texts.TEXT_CONFIRMAR_ENTREGA + accion.nombreAccion + "?"}
                    mensaje={texts.TEXT_CONFIRMAR_DESCRIPCION}
                    botonera={compProperties.CONFIR_CANCELAR_ACEPTAR}
                    accionConfirmacion={accionFirmar}
                    accionDenegacion={compProperties.CONFIR_SIN_ACCION}
                    handleChange={dejarDeMostrarConfirmacion}
                    tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                    isPopUpPdf={false}
                    textBotonConfirmarPersonalizado={true}
                    textoBotonConfirmar={texts.TEXT_BOTON_ACEPTAR_FIRMA}
                    isImagenPersonalizada={false}
                    centrarContenido={true}
                    isTextoEnriquecido={true}
                />
                <Confirmacion
                    mostrar={mostrarErrorPresentacion} 
                    isPhoneVersion={isPhoneVersion}
                    titulo={texts.TITLE_ERROR_PRESENTACION + accion.nombreAccion}
                    mensaje={msgErrorPresentacion}
                    botonera={compProperties.CONFIR_SOLO_ACEPTAR}
                    accionConfirmacion={dejarDeMostrarErrorPresentacion}
                    handleChange={dejarDeMostrarErrorPresentacion}
                    tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                    isPopUpPdf={false}
                    isImagenPersonalizada={true}
                    imagen={properties.IMAGEN_ERROR}
                    centrarContenido={true}
                    isTextoEnriquecido={true}
                />
                <Confirmacion
                    mostrar={mostrarPopUpDesOk} 
                    isPhoneVersion={isPhoneVersion}
                    titulo={texts.TITLE_DES_OK + accion.nombreAccion + " ha sido presentada"}
                    mensaje={texts.TITLE_DES_INFO}
                    botonera={compProperties.CONFIR_SOLO_ACEPTAR}
                    accionConfirmacion={volverABandeja}
                    handleChange={volverABandeja}
                    tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                    isPopUpPdf={false}
                    isImagenPersonalizada={true}
                    imagen={properties.IMAGEN_OK}
                    centrarContenido={true}
                    isTextoEnriquecido={true}
                />
                <Grid container className={customStyle.gridBoton} justify='flex-end' spacing={1}>
                    <button className={customStyle.botonVolver} onClick={volverABandeja}>{texts.TEXT_BOTON}</button>
                    <button className={customStyle.botonFirma} disabled={disabledBotonFirmar} onClick={mostrarConfirmacion}>{texts.TEXT_FIRMAR}</button>
                </Grid>
            </Grid>
            <Backdrop className={customStyle.classBackdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </main>    
    );
}
