import React, { useEffect, useState } from 'react';
import FormioProvider from '../../../providers/FormioProvider';
import { Form } from '@formio/react';
import { properties, msg, btnsGuardar, btnsCancelar } from './FormNuevaSolicitudData';
import RestProvider from 'providers/RestProvider';
import { loadState, saveState } from './../../../providers/localStorage';
import { localStorageName } from './../../../providers/localStorageData';
import { useHistory } from "react-router-dom";
import useOperations from '../generadorPdf/GeneradorPdfFunction';
import usePrecargas from '../Precargas/PrecargasFunction';
import formioFunctions from '../Precargas/FormIoFunctions';
import FormPruebaStyle from './FormNuevaSolicitudStyle.css';
import ictFunctions from '../Precargas/ICTFunctions';
import validacionesFormIoFunctions from '../validacionesFormIO/validacionesFormioFunctions';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import { getContextFromUrl } from 'config/Config';
import SICAFunctions from '../Precargas/SICAFunctions';


function FormNuevaSolicitud(props) {
    const { formioOperation } = FormioProvider();
    const { crudOperation, crudOperationsType } = RestProvider();
    const [formData, setFormData] = useState();
    const [submissionData, setSubmissionData] = useState();
    const history = useHistory();
    const [avanzarPagina, setAvanzarPagina] = useState(false);
    const [formularioCorrecto, setFormularioCorrecto] = useState(false);
    const [pdfAgenerar, setPdfAgenerar] = useState(false);
    const { functionValidacionID, functionValidadorRegex, functionValidacionIBAN, functionCamposEspecificos } = validacionesFormIoFunctions();
    const { getProvincias, getProvinciasDescripcion, getMunicipios, getTipoVias, getPaises, getOrganismos, calculaActuaEnCalidadDe, obtenerValoresUsuario, obtenerConvocatoria } = formioFunctions();
    const { getValorICT } = ictFunctions();
    const { getDatosRepresentanteSica } = SICAFunctions();
    const { generarPDF } = useOperations(props);
    const { obtenerDatosUsuarioFormulario } = usePrecargas();
    const procedimientoActual = loadState(localStorageName.procedimientoActual, true);
    const solAct = loadState(localStorageName.solicitudActual, true);
    const [mostrarConfirmacion, setMostrarConfirmacion] = useState(false);
    const [popUpTitle, setPopUpTitle] = useState("Título")
    const [popUpMesagge, setPopUpMesagge] = useState("Mensaje")
    const [popUpImage, setPopUpImage] = useState('exclamation-triangle-solid.svg')
    const showPopup = (title, mesagge, image) => {
        setPopUpTitle(title)
        setPopUpMesagge(mesagge)
        setPopUpImage(image)
        setMostrarConfirmacion(true)
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (props.handleLoading !== undefined) {
            props.handleLoading(true);
        }
        await obtenerPdfAgenerar();
        await getSubmissionData();
        await getForm();
    }

    //Efecto aplicado para obtener el formulario PDF cuando se realice el guardado del formulario
    useEffect(() => {
        if (formularioCorrecto) {
            obtenerFormularioPdf();
        }
    }, [formularioCorrecto]);

    //Efecto aplicado para avanzar al paso de Aporte de documentación cuando se realice la generación del formulario PDF
    useEffect(() => {
        if (avanzarPagina) {
            let perfilSeleccionado = loadState(localStorageName.perfilSeleccionado, true);
            let solAct = loadState(localStorageName.solicitudActual, true);
            if (solAct.revisarPresentacion && perfilSeleccionado.descripcion === 'REPRESENTANTE_SICA') {
                props.handleProcessing(false);
                document.querySelector('body').style.overflow = 'auto';
                history.push('/FirmaSolicitud');
            } else {
                props.handleProcessing(false);
                history.push('/AporteDocumentacion');
            }
        }
    }, [avanzarPagina]);

    const getForm = async () => {
        let resource = loadState(localStorageName.resourceFormNuevaSolicitud, false);
        let formulario;
        let camposNoSubsanables;
        await formioOperation(resource, {}, true).then(response => {
            formulario = response.data;
            if (props.handleLoading !== undefined) {
                props.handleLoading(false);
            }
        }).catch((error) => {
            if (props.handleLoading !== undefined) {
                props.handleLoading(false);
            }
        });

        if (loadState(localStorageName.fase) === "SUBSANA") {
            await crudOperation(crudOperationsType.CUSTOM, 'motivoSubsanacionConvocatoria', { url: `/findAllSubsanablesByIdExpediente/${solAct.id}`, method: 'get' }).then(response => {
                camposNoSubsanables = response.data;
            }).catch((error) => {
                if (props.handleLoading !== undefined) {
                    props.handleLoading(false);
                }
            });
            var arrayKeyNoSubsanables = camposNoSubsanables.map(msc => msc.idCampoFormulario)
            ocultarComponentesNoSubsanables(formulario, arrayKeyNoSubsanables);
            setFormData(formulario);
        } else {
            setFormData(formulario);
        }
    }

    const getSubmissionData = async () => {
        let respuesta;
        props.handleLoading(true);
        let convAct = loadState(localStorageName.convocatoriaSeleccionada, true);
        let rutaFormulario = loadState(localStorageName.resourceFormNuevaSolicitud, false);
        let infoUsuario = loadState(localStorageName.infoUsuario, true);

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_EXPEDIENTE, { url: properties.URL_EXPEDIENTE + solAct.numeroExpediente + '/' + infoUsuario.interesadoNif, method: 'get' }).then(response => {

            let idFaseIdEstado = {
                idFase: response.data.idFase,
                idEstado: response.data.idEstado,
            }

            if (solAct.edicion || solAct.subsanar || solAct.alegar || solAct.revisarPresentacion || solAct.corregirSolicitud
                || solAct.revisarSubsanacion || solAct.corregirSubsanacion) {
                let idExpediente = response.data.id;
                saveState(localStorageName.idExpediente, idExpediente, true);
            }
            saveState(localStorageName.faseEstadoExpediente, idFaseIdEstado, true);

        })

        let faseEstadoExpediente = loadState(localStorageName.faseEstadoExpediente, true);
        let perfilSeleccionado = loadState(localStorageName.perfilSeleccionado, true);
        let solicitudColaborativa = loadState(localStorageName.solicitudColaborativa, false);

        let datosRecuperar;

        if (solAct.edicion || solAct.subsanar || solAct.alegar || solAct.revisarPresentacion || solAct.corregirSolicitud
            || solAct.revisarSubsanacion || solAct.corregirSubsanacion) {
            let idExpediente = loadState(localStorageName.idExpediente, true);
            datosRecuperar = {
                idConvocatoria: convAct.id,
                idExpediente: idExpediente,
                idFase: faseEstadoExpediente.idFase,
                rutaFormulario: rutaFormulario,
                perfilSeleccionado: perfilSeleccionado.descripcion,
                solicitudColaborativa: solicitudColaborativa
            }
            Object.assign(solAct, { 'id': idExpediente });
            saveState(localStorageName.solicitudActual, solAct, true);
        } else {
            datosRecuperar = {
                idConvocatoria: convAct.id,
                idExpediente: solAct.id,
                idFase: faseEstadoExpediente.idFase,
            }
        }

        //PROCESO DE PRECARGA DE FORMULARIO DE NUEVA SOLICITUD

        //Paso 1. Encontrar los datos que son necesarios para recargar:

        let datosARellenar = await crudOperation(
            crudOperationsType.CUSTOM,
            properties.RESOURCE_FORMULARIO_EXPEDIENTE,
            { url: properties.URL_OBTENER_CAMPO_FORMULARIO, method: 'post', data: datosRecuperar })

        //Paso 2: Si el usuario no tiene un formulario de solicitud guardado con datos, precargamos
        // los datos personales y dirección del usuario logueado:

        const datoExpediente = {
            numeroExpediente: solAct.numeroExpediente
        };
        const hayFormularioGuardado = await crudOperation(
            crudOperationsType.CUSTOM,
            'dato-expediente',
            { url: '/findByNumeroExpediente', method: 'post', data: datoExpediente })
        if (!hayFormularioGuardado.data.datosSolicitudJson) {
            datosARellenar = await obtenerDatosUsuarioFormulario(datosARellenar)
        }
        let infoBoja = {};
        // Obtenemos la información relativa al BOJA del procedimiento actual y la añadimos a los datos cargados:
        await crudOperation(crudOperationsType.CUSTOM, 'procedimiento', { url: "/" + procedimientoActual.id, method: 'get' }).then(response => {

            const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
            const fechaOrdenDecretoLey = new Date(response.data.fechaOrdenDecretoLey)

            infoBoja.identificadorOrdenDecretoLey = response.data.identificadorOrdenDecretoLey
            infoBoja.diaOrdenDecretoLey = fechaOrdenDecretoLey.getDate()
            infoBoja.mesOrdenDecretoLey = meses[fechaOrdenDecretoLey.getMonth()]
            infoBoja.anyoOrdenDecretoLey = fechaOrdenDecretoLey.getFullYear()
            infoBoja.numeroBoja = response.data.numeroBoja
            infoBoja.fechaBoja = response.data.fechaBoja
            infoBoja.anyoConvocatoriaEjercicio = response.data.convocatoria
        })
        datosARellenar.data = { ...datosARellenar.data, informacionBoja: infoBoja }
        setSubmissionData(datosARellenar);
        props.handleLoading(false);

        return respuesta;
    }
    /**
     * @function handleSubmit
     * @param {*} data Recibe los datos del formulario
     * @param {*} tipoGuardado Recibe el tipo de guardado realizado (guardado del paso actual o guardado del formulario al completo)
     */
    const handleSubmit = async (data, tipoGuardado) => {
        props.handleLoading(true);
        let solAct = loadState(localStorageName.solicitudActual, true);
        let convAct = loadState(localStorageName.convocatoriaSeleccionada, true);
        let infoUsu = loadState(localStorageName.infoUsuario, true);
        let nombreFormulario = loadState(localStorageName.resourceFormNuevaSolicitud, false);
        let faseEstadoExpediente = loadState(localStorageName.faseEstadoExpediente, true);

        let datoExpediente = {
            id: solAct.id,
        }

        let input = {
            datoExpediente: datoExpediente,
            idConvocatoria: convAct.id,
            idFase: faseEstadoExpediente.idFase,
            formulario: JSON.stringify(data.data),
            rutaFormulario: nombreFormulario,
            nifFirmante: infoUsu.interesadoNif,
        }

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_FORMULARIO_EXPEDIENTE, { url: properties.URL_GUARDAR_FORMULARIO_PCT, method: 'post', data: input })
            .then(response => {
                props.handleError(null);
                //Inserta un mensaje u otro, dependiendo del botón de guardado que ha sido pulsado
                props.handleSetMessage(tipoGuardado === btnsGuardar.guardarForm ? msg.msg.formulario.exitoFormulario : msg.msg.pasoActual.exitoPasoActual, msg.severity.exito);
                props.handleLoading(false);
                //Se establece a true en caso de que se pulse el botón del guardado del formulario al completo
                setFormularioCorrecto(tipoGuardado === btnsGuardar.guardarForm ? true : false);
            }).catch(function (error) {
                props.handleLoading(false)
                props.handleError(error.input.datos);
                props.handleSetMessage(tipoGuardado === btnsGuardar.guardarForm ? msg.msg.formulario.errorFormulario : msg.msg.pasoActual.errorPasoActual, msg.severity.error);
                props.handleProcessing(false);
                setAvanzarPagina(false);
            });

    }

    const handleChange = (value) => {
        value.isValid = false;
        if (value.changed != undefined) {
            let formularioSeleccionado = loadState(localStorageName.resourceFormNuevaSolicitud, false);
            if (value.changed.component.key == "telefono") {
                pruebaValidationFunction();
            }
            /* Casuística específica para el formulario 0021569ictconectividadpoligonos, realiza el borrado
            de una serie de campos cuando en la primera página se ha seleccionado en el radio button la opción interesado */
            if ((formularioSeleccionado === '0021569ictconectividadpoligonos' || formularioSeleccionado === '0022142IctRedDeEmpresas')
                && value.changed.component.key === "actuaEnCalidadDe"
                && value.data.datos.actuaEnCalidadDe === 'representanteDeEntidadUOtraPersona') {
                document.querySelector("#nombreRazonSocialDenominacion").defaultValue = '';
                document.querySelector("#dNINIENIF").defaultValue = '';
                document.querySelector("#primerApellido").defaultValue = '';
                document.querySelector("#segundoApellido").defaultValue = '';
                document.querySelector("#nombreRazonSocialDenominacion").value = '';
                document.querySelector("#dNINIENIF").value = '';
                document.querySelector("#primerApellido").value = '';
                document.querySelector("#segundoApellido").value = '';
            }
        }
    }

    const handleSubmitFomr = async (data) => {
        //Paso 1. Se oculta el scroll vertical para evitar que se desplace el formulario durante la generacion del pdf
        document.querySelector('body').style.overflow = 'hidden';
        //Paso 2. Se establece el scroll vertical a 200px 
        window.scroll(0, 200);
        //Paso 3. Se realiza el guardado del formulario
        await handleSubmit(data, btnsGuardar.guardarForm);
    }

    const handleCustomEvents = (customEvent) => {
        //Evento para guardar el formulario con los valores que se encuentren insertados en ese preciso momento
        if (customEvent.type === btnsGuardar.guardarPaso) {
            handleSubmit(customEvent, btnsGuardar.guardarPaso);
        }
        if (customEvent.type === btnsCancelar.cancelarPaso) {
            history.push("/BandejaSolicitudes");
        }
    }

    const pruebaValidationFunction = (value) => {
        console.log("ENTRA!")
        return true;
    }

    /**
     * @function obtenerFormularioPdf Método que obtiene el formulario PDF una vez se ha rellenado la solicitud
     */
    const obtenerFormularioPdf = async () => {
        try {
            let form = formularioPdf();
            props.handleProcessing(form.formAgenerar === 'pdfConVolcadoHTML' ? true : false);
            await generarPDF(form.formAgenerar, form.solicitud, form.dataSource, procedimientoActual.id, form.fase);

            props.handleSetMessage(msg.msg.pdf.exitoPdf, msg.severity.exito);
            setAvanzarPagina(true);
        } catch (error) {
            setAvanzarPagina(false);
            props.handleSetMessage(msg.msg.pdf.errorPdf, msg.severity.error);
        }
    }

    /**
     * @function formularioPdf Método que obtiene los valores necesarios para la generación de PDF
     * @returns Devuelve un objeto con todos los valores necesarios
     */
    const formularioPdf = () => {
        const formularioPdfNuevo = {
            solicitud: loadState(localStorageName.solicitudActual, true).numeroExpediente,
            fase: loadState(localStorageName.fase, false),
            formAgenerar: pdfAgenerar
        }

        return formularioPdfNuevo;
    }

    /**
     * @function obtenerPdfAgenerar Método que obtiene el tipo de PDF a generar
     * @returns Devuelve un String con el tipo de PDF a generar
     */
    const obtenerPdfAgenerar = async () => {
        let convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);
        let idFase = loadState(localStorageName.idFase, true);
        let idEstadoFase = loadState(localStorageName.idEstadoFase, true);

        let input = {
            idConvocatoria: convocatoria.id,
            idFase: idFase,
            idEstado: idEstadoFase
        };

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_FORMULARIO_FASE_CONVOCATORIA, { url: properties.RESOURCE_TIPO_PDF, method: 'post', data: input }).then(response => {
            setPdfAgenerar(response.data);
        });
    }

    /**
     * @function ocultarComponentesNoSubsanables Función que esconde los componentes marcados como no subsanables en la ventana de convocatoria de
     * la PCT, de manera que el ciudadano no podrá editarlos al subsanar.
     * 
     * @param componente componente de formio
     * @param arrayKeyNoSubsanables array de keys de los campos no subsanables
     */
    const ocultarComponentesNoSubsanables = (componente, arrayKeyNoSubsanables) => {
        if (componente?.key && arrayKeyNoSubsanables.includes(componente?.key)) {
            componente.disabled = false;
        }
        var componentes = componente?.components;
        if (componentes && componentes.length > 0) {
            for (let i = 0; i < componentes.length; i++) {
                ocultarComponentesNoSubsanables(componentes[i], arrayKeyNoSubsanables);
            }
        } else {
            return false;
        }
    };

    /**
     * @function scrollUp Función que establece arriba el scroll de la página, se utiliza para los eventos producidos cuando se
     * pulsan los botones siguiente (onNextPage), atrás (onPrevPage) o cuando no se ha informado un campo obligatorio (onError).
     */
    const scrollUp = () => {
        window.scroll(0, 200);
    }

    return (
        <div>
            {/*A la etiqueta options hay que pasarle los parametros directamente, 
            NO creamos una constante. En caso de hacerlo se producirá un BUG y se harán multiples 
            llamadas al onSubmit y al onChange*/}
            <Form
                form={formData}
                onCustomEvent={handleCustomEvents}
                onSubmit={handleSubmitFomr}
                onChange={handleChange}
                onNextPage={scrollUp}
                onPrevPage={scrollUp}
                options={{
                    ov: true,
                    language: 'sp',
                    evalContext: {
                        // Funciones específicas para validación de datos
                        validarNIF: functionValidacionID,
                        validarCampo: functionValidadorRegex,
                        validarIBAN: functionValidacionIBAN,
                        camposEspecificos: functionCamposEspecificos,
                        // Funciones específicas para carga de selectores genéricos
                        getProvincias: getProvincias,
                        getProvinciasDescripcion: getProvinciasDescripcion,
                        getMunicipios: getMunicipios,
                        getTipoVias: getTipoVias,
                        getPaises: getPaises,
                        getOrganismos: getOrganismos,
                        //Funciones específicas para formulario ICT-Conectividad Polígonos
                        getValorICT: getValorICT,
                        //Funciones de precarga para el bloque de componentes personales
                        calculaActuaEnCalidadDe: calculaActuaEnCalidadDe,
                        obtenerValoresUsuario: obtenerValoresUsuario,
                        obtenerConvocatoria: obtenerConvocatoria,
                        //Funcion que permite generar un popup parametrizado desde el formulario
                        showPopup: showPopup,
                        // Funciones específicas para formulario Predoctorales2023_Presentacion_SICA
                        getDatosRepresentanteSica: getDatosRepresentanteSica,
                        // Funcion de precarga de los datos del interesado para el formulario
                        obtenerDatosUsuarioFormulario: obtenerDatosUsuarioFormulario
                    },

                    i18n: {
                        sp: {
                            complete: 'Formulario cumplimentado correctamente',
                            error: 'Hubo un problema',
                            next: 'Siguiente',
                            previous: 'Atrás',
                            cancel: 'Cancelar',
                            submit: 'FINALIZAR Y ADJUNTAR DOCUMENTACIÓN',
                            required: 'Es obligatorio rellenar este campo',
                            confirmCancel: '¿Estás seguro que quieres cancelar?',
                            submitError: 'Por favor revise el formulario y corrija todos los errores.',
                            mask: '',
                            searchPlaceholderValue: 'Buscar',
                            'Type to search': 'Escriba para buscar',
                            unsavedRowsError: 'Por favor, guarde todas las filas antes de continuar.',
                            invalidRowsError: 'Por favor, corrija las filas inválidas antes de continuar.',
                            invalidRowError: 'Fila inválida. Por favor corríjala o elimínela.',
                            invalidOption: '{{field}} es un valor inválido.',
                            invalidDay: '{{field}} no es un día válido.',
                            //required: '{{field}} es obligatorio',
                            unique: '{{field}} debe ser único',
                            array: '{{field}} debe ser un array',
                            array_nonempty: '{{field}} debe ser un array no vacío', // eslint-disable-line camelcase
                            nonarray: '{{field}} no debe ser un array',
                            select: '{{field}} contiene una selección inválida',
                            pattern: '{{field}} no coincide con el patrón {{pattern}}',
                            minLength: '{{field}} debe tener al menos {{length}} caracteres.',
                            maxLength: '{{field}} no debe tener más de {{length}} caracteres.',
                            minWords: '{{field}} debe tener al menos {{length}} palabras.',
                            maxWords: '{{field}} no debe tener más de {{length}} palabras.',
                            min: '{{field}} no puede ser menor que {{min}}.',
                            max: '{{field}} no puede ser mayor que {{max}}.',
                            maxDate: '{{field}} no debe contener una fecha posterior a {{- maxDate}}',
                            minDate: '{{field}} no debe contener una fecha anterior a {{- minDate}}',
                            maxYear: '{{field}} no debe contener un año mayor que {{maxYear}}',
                            minSelectedCount: 'Debe seleccionar al menos {{minCount}} elementos',
                            maxSelectedCount: 'Solo puede seleccionar hasta {{maxCount}} elementos',
                            minYear: '{{field}} no debe contener un año menor que {{minYear}}',
                            invalid_email: '{{field}} debe ser un correo electrónico válido.',
                            invalid_url: '{{field}} debe ser una URL válida.',
                            invalid_regex: '{{field}} no coincide con el patrón {{regex}}.',
                            invalid_date: '{{field}} no es una fecha válida.',
                            invalid_day: '{{field}} no es un día válido.',
                            invalidValueProperty: 'Propiedad de valor inválida',
                            mask: '{{field}} no coincide con la máscara.',
                            valueIsNotAvailable: '{{ field }} es un valor inválido.',
                            captchaTokenValidation: 'ReCAPTCHA: Error en la validación del token',
                            captchaTokenNotSpecified: 'ReCAPTCHA: No se especificó el token en la solicitud',
                            captchaFailure: 'ReCAPTCHA: No se encontró el token de respuesta',
                            time: '{{field}} no es una hora válida.',
                            invalidDate: '{{field}} no es una fecha válida',
                            number: '{{field}} no es un número válido.'
                        }
                    }
                }}
                submission={submissionData} />
            <Confirmacion
                accionConfirmacion={() => { setMostrarConfirmacion(false) }}
                titulo={popUpTitle}
                mostrar={mostrarConfirmacion}
                mensaje={popUpMesagge}
                botonera='confirSoloAceptar'
                quitarImg={false}
                tamMarginTop='10'
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={false}
                isImagenPersonalizada={true}
                centrarContenido={true}
                imagen={`${getContextFromUrl()}` + popUpImage}
                isTextoEnriquecido={true}
            />
        </div>

    );
}

export default FormNuevaSolicitud;