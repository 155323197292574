import RestProvider from 'providers/RestProvider';
import { cruds, datosInteresado } from './altaEntidadColaboradoraData';
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import { localStorageName } from 'providers/localStorageData';
import { loadState, saveState } from 'providers/localStorage';


const useOperations = () => {
    const { crudOperation, crudOperationsType } = RestProvider();
    const history = useHistory();
    const [message, setMessage] = useState({ msg: '', severity: '' });
    let _ID = 0;
    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const [alerts, setAlerts] = useState([]);
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }
    const [isLoading, setIsLoading] = useState(false);

    const solicitudActual = loadState(localStorageName.solicitudActual, true);

    /**
     * @function handleOnSubmit Función que se ejecutará cuando se produzca en el formulario el evento onSubmit
     */
    const handleOnSubmit = async (data) => {
        setIsLoading(true);
        let interesado = {};

        // Paso 1. Se construye el objeto que requiere el endpoint recibir por parámetros
        let obj = await buildObjInteresado(data);
        // Paso 2. Se realiza la creación o actualización (en caso de que ya exista) del interesado
        crudOperation(crudOperationsType.CUSTOM, cruds.interesado.resource, { url: cruds.interesado.url, method: 'post', data: obj }).then(response => {
            interesado = response.data;
            asignarAltaInteresado();
            setIsLoading(false);
            history.push('/AltaAnexo');
        }).catch(function (error) {
            setIsLoading(false);
            handleSetMessage("Ha ocurrido un error al intentar guardar los datos del interesado.", 'error');
        });
        
        return interesado;
    }

    /**
     * @constant buildObjInteresado Constante dónde se crea el objeto que necesitará la función handleOnSubmit para el endpoint de creación del interesado
     * @param {*} data 
     * @returns 
     */
    const buildObjInteresado = async (data) => {
        let datosObtenidos = {};
        let entidad = data.data.datosDeLaEntidad;
        let dcEntidad = data.data.direccion;

        datosInteresado.interesado.identificador = entidad.codigoEntidad;
        datosInteresado.interesado.nombre = entidad.nombreEntidad;
        // datosInteresado.interesado.tipoIdentificador PDT. DE DEFINIR
        datosInteresado.datosContacto.interesado = datosInteresado.interesado;
        datosInteresado.datosContacto.email = entidad.correoElectronico;
        datosInteresado.datosContacto.telefono = entidad.telefonoFijo;
        datosInteresado.datosContacto.telefonoMovil = entidad.telefonoMovil;
        datosInteresado.datosContacto.tipoVia = await getTipoVia(dcEntidad.tipoDeVia);
        datosInteresado.datosContacto.nombreVia = dcEntidad.nombreDeLaVia;
        datosInteresado.datosContacto.numero = dcEntidad.numero;
        datosInteresado.datosContacto.letra = dcEntidad.letra;
        datosInteresado.datosContacto.escalera = dcEntidad.escalera;
        datosInteresado.datosContacto.puerta = dcEntidad.puerta;
        datosInteresado.datosContacto.pais = {id: dcEntidad.pais};
        datosInteresado.datosContacto.provincia = {id: dcEntidad.provincia};
        datosInteresado.datosContacto.municipio = {id: dcEntidad.municipio};
        datosInteresado.datosContacto.idPais = dcEntidad.pais;
        datosInteresado.datosContacto.idProvincia = dcEntidad.provincia;
        datosInteresado.datosContacto.idMunicipio = dcEntidad.municipio;
        datosInteresado.datosContacto.postal = dcEntidad.codigoPostal;
        datosInteresado.datosContacto.idDatoExpediente = solicitudActual.idExpediente;
        datosInteresado.datosContacto.tipoNotificacion = 'Electrónica'
        datosInteresado.datosContacto.tipoInteresado = await getTipoInteresado('COLABORADOR');

        datosObtenidos = {
            interesado1: {...datosInteresado}
        }
        return datosObtenidos;
    }

    /**
     * @function handleCustomEvents Función que captura los eventos de los botones customizados
     * @param {*} customEvent 
     */
    const handleCustomEvents = (customEvent) => {        
        // Captura cuando se hace click sobre el botón Atrás
        if (customEvent.type === 'btnAtras') {
            history.push('/AltaAnexo');
        }
    }

    /**
     * @function getTipoVia Función que obtiene un tipo de vía a partir de su código
     * @param {*} codigo 
     * @returns 
     */
    const getTipoVia = async (codigo) => {
        let res = null;
        await crudOperation(crudOperationsType.CUSTOM, cruds.tipoVia.resource, { url: cruds.tipoVia.url + codigo, method: 'get' }).then(response => {
            res = response.data;
        }).catch(function (error) {
            handleSetMessage("Ha ocurrido un error al intentar obtener el tipo de vía.", 'error');
        });
        return res;
    }

    /**
     * @function getTipoInteresado Función que obtiene un tipo de interesado a partir de su código
     * @param {*} codigo 
     * @returns 
     */
    const getTipoInteresado = async (codigo) => {
        let res = null;
        await crudOperation(crudOperationsType.CUSTOM, cruds.tipoInteresado.resource, { url: cruds.tipoInteresado.url + codigo, method: 'get' }).then(response => {
            res = response.data;
        }).catch(function (error) {
            handleSetMessage("Ha ocurrido un error al intentar obtener el tipo de interesado.", 'error');
        });
        return res;
    }

    /**
     * @function asignarAltaInteresado Función que indica que el interesado ha sido dado de alta
     */
    const asignarAltaInteresado = () => {
        let entidadesExistentes = loadState(localStorageName.entidadesDeAlta, true);
        let entidadSeleccionada = loadState(localStorageName.entidadSeleccionada, false);

        // Se realiza la búsqueda de la entidad que se ha dado de alta y se establece la propiedad entidadDeAlta con valor true
        for (const ent of entidadesExistentes) {
            if (ent.entidad === entidadSeleccionada) {
                ent.entidadDeAlta = true;
                break;
            }
        }
        // Se actualiza el objeto
        saveState(localStorageName.entidadesDeAlta, entidadesExistentes, true);
    }    

    return {
        handleOnSubmit, handleCustomEvents, isLoading, alerts, message, setMessage
    };
}

export default useOperations;