import { createMuiTheme, makeStyles } from "@material-ui/core";
import { estilosTextosPrincipales, colores, tipografia, links } from '../../../AppStyle';

export default makeStyles({
    classContainer: {
        width: '100%',
        height: 'fit-content',
        boxShadow: `0 0 6px 0 ${colores.sombras.sombraGris1}, 0 0 30px 0 ${colores.sombras.sombraGris2}`,
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    classContainerMovil: {
        width: '100%',
        height: 'auto',
        boxShadow: `0 0 6px 0 ${colores.sombras.sombraGris1}, 0 0 30px 0 ${colores.sombras.sombraGris2}`,
        padding: '20px 16px 23px 16px',
    },
    ////////////////////////////////GRIDS
    classGridCabecera: {
        width: 'auto',
        height: 'auto',
    },
    classGridCabeceraTextoMovil: {
        width: 'auto',
        height: 'auto',
        marginTop: '1%',
        display: 'block',
        marginLeft: '2.5%',
    },
    classGridCabeceraTexto: {
        width: 'auto',
        height: 'auto',
        marginLeft: '2%',
        display: 'block',
        marginLeft: '5%',
    },
    classGridAcordeon: {
        width: 'auto',
        height: 'auto',
        marginTop: '1%',
        marginLeft: '4%',
        padding: '21.27px 23px 23px 32px',
        display: 'block',
        boxShadow: `0 0 6px 0 ${colores.sombras.sombraGris1}, 0 0 30px 0 ${colores.sombras.sombraGris2}`,
    },
    classGridAcordeonMovil: {
        width: 'auto',
        height: 'auto',
        marginTop: '1%',
        padding: '21.27px 23px 23px 32px',
        display: 'block',
        boxShadow: `0 0 6px 0 ${colores.sombras.sombraGris1}, 0 0 30px 0 ${colores.sombras.sombraGris2}`,
    },
    classButton: {
        position: 'fixed',
        width: '34px',
        height: 'auto',              
        color: colores.escalaGrises.cien,
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        fontFamily: tipografia.regular, 
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: '600',
    },
    ////////////////////////////////ACORDEON  
    classAcordeon: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderStyle: 'none',
    },
    classSummary: {
        marginTop: '23px',
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.cien,
        height: '24px'
    },
    classDetails: {
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.cien,
        fontWeight:'normal',
        fontSize: '14px',
        lineHeight: '20px',
    },
    ////////////////////////////////TITULOS    
    classTitleEncabezado: {
        fontFamily: tipografia.regular,
        ...estilosTextosPrincipales.encabezadoH2,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold'
    },
    classTitleAcordeon: {
        ...estilosTextosPrincipales.encabezadoH4,
        color: colores.principales.principal,
    },
    ////////////////////////////////ENCABEZADOS
    classParrafEncabezado: {
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.cien,
    }
});

export const overAcordeon = createMuiTheme({
    overrides: {
        MuiAccordion: {
            root: {
                '&::before': {
                    backgroundColor: 'transparent',
                    content: 'none',
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                minHeight: '24px',
                padding: '0px 16px 0px 0px',
                '&$expanded': {
                    minHeight: '24px',
                    marginBottom: '16px',
                },
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
            },
            content: {
                order: 1,//Se cambia el orden del DIV (el icono pasa a una primera posición y el el texto pasa a una segunda posición)
                '&$expanded': {
                    color: colores.principales.principal,
                    textDecorationLine: 'underline',
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                height: '57%',
                backgroundColor: colores.escalaGrises.cinco,
            },
        },
        MuiIconButton: {
            root: {
                padding: '0px',                
            },
            edgeEnd: {
                marginRight: '0px',
            },
        },
        MuiLink: {
            root: {
                '&.Mui-focusVisible': {
                    backgroundColor: 'yellow',
                  },
            }
        }
    },
});