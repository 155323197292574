import { getContextFromUrl } from 'config/Config';

export const properties  = {
  AC_ICON_PATH: `${getContextFromUrl()}app/iconoAC.png`,
  JA_ICON_PATH: `${getContextFromUrl()}app/jaLogoGenerico.png`,
  RESOURCE : 'configuraciones',
  URL : 'avisos',
  HOME : 'SeleccionConvocatoriaPerfil',
  V2: 'PCT2',
  V3: 'PCT3',
  URL_AVISOSOV3: 'avisosov3',
  URL_AVISOSOV3_CRITICOS: '/avisosCriticos',
  URL_AVISOSOV3_CRITICOS_PROC: '/avisosCriticosProcedimiento',
}

export const tipoAviso  = {
  CRITICO: 'CRIT',
  INFO: 'INFO'
}

export const texts  = {
  ALT_AC: 'Avisos de la Sede electrónica',
  TIT_GENERAL: 'Avisos de la Sede electrónica',
  TIT_PROCEDIMIENTO: 'Avisos del Procedimiento ',
  BOTON_ENTENDIDO: 'ENTENDIDO'
}

