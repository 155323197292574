import { makeStyles } from '@material-ui/core/styles';
import { backgroundRectangle , estilosTextosPrincipales, colores, tipografia, buttonSecundarySmall, buttonPrimarySmall } from '../../../AppStyle';

const useStyles = (isPhoneVersion) => {
    
    const style = makeStyles({
        gridPageTitle: {
            marginTop: '2%',
            marginLeft: '5%',
            width: '90%'
        },
        pageTitle: {
            ...estilosTextosPrincipales.titulosPagina,
        },
        backgroundRectangle: {
            ...backgroundRectangle,
            marginBottom: '0px'
        },
        hclassFirma: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            paddingTop: '2%',
            ...(isPhoneVersion) && { marginRight: '5px'},
        },
        classP: {
            color: colores.escalaGrises.noventa,
            fontFamily:  tipografia.regular,
            fontSize: '17px',
            lineHeight: '24px',
            marginBottom: '20px',
        },
        classDivRect: {
            marginLeft: '5%',
        },
        hclassFirmante: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            paddingTop: '1%',
            fontSize: '18px',
        },
        gridDatos: {
            display: 'inline-block',
            marginRight: '12%'
        },
        pDatosFirmante: {
            color: colores.escalaGrises.noventa,
            fontFamily:  tipografia.regular,
            lineHeight: '24px',
            marginBottom: '20px',
            fontSize: '18px',
            marginRight: '15%',
            fontWeight: '',
        },
        gridContenedorDatos: {
            marginLeft: '2%',
            marginTop: '1%',
        },
        gridListDoc: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            paddingTop: '2%',
            fontSize: '20px',
        },
        gridBotonesListoAtras: {
            width:'100%', 
            paddingRight: '5%', 
            margin: 0 ,
            ...(isPhoneVersion) && { marginTop: '3%'},
        },
        botonAtras:{
            ...buttonSecundarySmall,
            ...(!isPhoneVersion) ? { margin: '1% 0.5% 1% 1%'} : { margin: '1% 0.5% 1% 5%'},
            ...(isPhoneVersion) && { width: '100%'},
        },
        botonListo:{
            ...buttonPrimarySmall,
            ...(isPhoneVersion) && { width: '100%'},
            ...(!isPhoneVersion) ? { margin: '1% 0.5% 1% 1%'} : { margin: '1% 0.5% 1% 5%'},
        },
        marginButtonPdf:{
            marginBottom: '2px',
        },
        classBackdrop: {
            zIndex: 1,
            color: colores.principales.blanco,
        },
        gridTitulo:{
            display: 'flex', 
            flexDirection: 'row',
        },
        idExpediente:{
            marginLeft:'25px',
            color: colores.escalaGrises.sesenta,
            fontFamily: tipografia.bold,
            fontSize: '13px',
            height: 'inherit',
            display:'flex',
            alignItems: 'center'
        },
        classMarginTextInBlackDrop:{
            marginLeft:'10px',
        }
    });

    return {style}
}

export default useStyles;

