import React from 'react';
import { Grid, InputLabel, Select, MuiThemeProvider } from '@material-ui/core';
import Style, { overrideStyle } from './SelectStyle.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function SimpleSelect(props) {

    //////////////////////////////////Este es el metodo que recibe la props map
    /*const menuItem = (objeto, key, value, descripcion) => {
        return
        props.objeto.map((filtro) => (
            <MenuItem
                key={key}
                value={value}
                className={Style().classMenuItem}
            >
                {descripcion}
            </MenuItem>
        ))
    };*/
    //////////////////////////////////

    return (
        <MuiThemeProvider theme={overrideStyle}>
            <Grid className={Style().classGrid}>
                <InputLabel className={Style().classInputLabel} shrink>{props.inputLabel}</InputLabel>
                <Select
                    className={Style().classSelect}
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    displayEmpty
                    onChange={props.onChange}
                    disableUnderline
                    disabled={props.disabled}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        },
                        getContentAnchorEl: null
                    }}
                >
                    {props.map}
                </Select>
            </Grid>
        </MuiThemeProvider>
    );
}
