/**
 * @constant objAtributes contiene un objeto con todos los atributos del componente.
 */
export const objAtributes = {
    containers: {
        id: 'containerMenuUsuario',
    },
    grid: {
        id: 'gridMenuUsuario',
    },
    gridMenuInicial: {
        id: 'gridUsuario',
    },
    menu: {
        id: 'menuUsuario',
    },
    button: {
        id: 'cerrarSesion',
        name: 'cerrarSesion',
        type: 'submit',
    },
    menuItem: {
        id: 'nombreUsuario',
    },
    opcMenu: {
        opc1: {
            id: 'opc-misTramites'
        },
        opc2: {
            id: 'opc-misDatosPersonales',        },
        opc3: {
            id: 'opc-loginAdministrador'
        },
        opc4: {
            id: 'opc-descargarDocumentos'
        }
    },
    iconButton: {
        'aria-label': 'Menú',
        'aria-controls': 'menuUsuario',
        'aria-haspopup': 'true',
    },    
    //////////////////////////VERSIÓN MÓVIL
    h3: {
        id:'mensajeBienvenida',
    },
    h1: {
        id:'nombreUsuadio',
    },
    buttonOpcTramites: {
        id: 'tramites',
        name: 'reamites',
        type: 'submit',
    },
    buttonOpcDatosPersonales: {
        id: 'datosPersonales',
        name: 'datosPersonales',
        type: 'submit',
    },
    conexion: {
        id: 'datosConexion',
    },
};

/**
 * @constant objText contiene los textos necesarios para el componente.
 */
export const objText = {
    boton: 'CERRAR SESIÓN',
    conexion: 'Última conexión: ',
    sinUltConexion: 'sin información',
    /////////////////VERSIÓN MOVIL
    mensajeBienvenida: 'Bienvenido/a',
};

/**
 * @constant mock contiene los valores que recibirá al componente a la espera del endPoint.
 */
export const mock = {
    usuario: {
        nombre: 'Pedro',
        apellidos: 'Díaz Pérez',
    },
    opcMenu: ['Mis trámites', 'Mis datos personales', 'Descarga de documentos', 'Módulo Administración'],
    /////////////////VERSIÓN MÓVIL
    conexion: '10/02/2019    12:00:03',
};

export const properties  = {
    RESOURCE : 'configuraciones',
    URL : 'login',
    ID_BUTTON: 'idBotonUsuario',
    ARIA_EXPANDED: 'ariaExpanded',
    CLAVE: 'cl',
    CERTIFICADO: 'cd',
  }