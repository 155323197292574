import { Grid, Link } from '@material-ui/core';
import RestProvider from 'providers/RestProvider';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { properties as appProperties } from '../../../AppData';
import { mockFooterBottom } from './FooterData';
import { customStyle } from './FooterStyles';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';


const COLUMN_TYPE_RRSS = 'RRSS';
const WEB_RIGHTS_1 = 'accesibilidad';
const RRSS_TITLE_LINK = 'Síguenos en ';
const RRSS_ICON_PATH = 'images/RRSS/';
const RESOURCE = 'footer';
const COLUMN_LABEL_RRSS = 'Síguenos en:'

function Footer() {

  const {crudOperation, crudOperationsType} = RestProvider();
  const [mainColumnList, setMainColumnList] = useState([]);
  const [isPhoneVersion, setIsPhoneVersion] = useState(false);
  const [bloqueInferior, setBloqueInferior] = useState();
  const [isFromApi, setIsFromApi] = useState(true);
  const styles = customStyle()
  const history = useHistory();
  
  useEffect(() => {
      updateFormat();
  }, []) ;

  ////////////////////////////////////////////////////////////

  const renderColumnRows = (column) =>{
    let rowList = column.rows;

    if(column.label === COLUMN_LABEL_RRSS){
      const redesSociales = ['twitter', 'instagram', 'facebook', 'linkedin', 'youtube', 'flickr'];

      const getIconoFromLink = (row, redesSociales) => {
        let altText = null;
          for (const rs of redesSociales) {
            if (row.link.includes(rs)) {
              altText = rs;
              break;
            }
          }
        return altText;
      }

      return <div className={styles.footerRrssItemsWrapper}>{        

        rowList && rowList.map((row) =>{
          let altText = getIconoFromLink( row, redesSociales);
          return <a target="_blank" href={row.link} title={RRSS_TITLE_LINK + altText} className={styles.colFooterRRSS}><img className= {styles.rrssIcons} src={RRSS_ICON_PATH + altText.toLowerCase() + ".svg"} alt={altText}/></a>
        })
      }
      </div>

    }else{
      return <ul className={styles.footerList}> {rowList && rowList.map((row) =>{
            if((row.text ? row.text.toLowerCase() : '') === "contacto"){
              return <li className={styles.footerColumnLi}><Link onClick={()=>history.push("/PantallaContacto")} className={styles.colFooter}>{row.text}</Link></li>
            }else{
              return <li className={styles.footerColumnLi}><a target="_blank" href={row.link} className={styles.colFooter}>{row.text}</a></li>
            }           
          })}
      </ul>
         
    }
  }

  const renderRRSS = () => {
    let redes = mockFooterBottom.redes
    return <div className={styles.footerRrssItemsWrapper}>{        

      redes && redes.map((row) =>{
        return <a target="_blank" href={row.link} className={styles.colFooterRRSS}><img className= {styles.rrssIcons} src={row.src} alt={row.alt}/></a>
      })
    }
    </div>
  }

  const renderRightPart = () => {
    let iconos = mockFooterBottom.iconoPrincipal
    let informacion = mockFooterBottom.informacion
    // let informacion_corporativa = mockFooterBottom.informacion_corporativa
    
    return <div className={styles.footerLeftWrapper}>
      {renderMainIcons(iconos)}
      {renderMainInfo(informacion)}
      
      {/* {renderGridPrueba()} */}
      {/*renderCorporateInfo(informacion_corporativa)}  */}
      
    </div>
  }

  const renderMainIcons = (iconos) => {
    return <div className={styles.footerRrssNameWrapper}>{        
      iconos && iconos.map((row) =>{
        return <a target="_blank" href={row.link} className={styles.bottomSectionIcons}><img className= {styles.acLogo} src={row.src} alt={row.alt}/></a>
      })
    }
    </div>
  }
  const renderMainInfo = (informacion) => {
    return <div className={styles.footerRrssIconssWrapper}>      
              <ul className={styles.footerMoreInfo}>{
                informacion && informacion.map((row) =>{
                    return <div className={styles.infoList}>
                      <a target="_blank" className={styles.colFooterRRSS}><img className= {styles.contactoIcons} src={row.src} alt={row.alt}/></a>
                      <p className={styles.websiteRights}>{row.texto} <br/> {row.texto2} </p>
                    </div>
                  })}
              </ul>
      </div>
    }

  const informacion_corporativa = [
    {
      texto: "Mapa Web",
      link: "https://www.cordoba.es/mapa-web",
      alt: "Mapa Web"
    },
    {
      texto: "Aviso legal",
      link: "https://www.cordoba.es/aviso-legal",
      alt: "Aviso legal"
    },
    {
      texto: "Protección de datos",
      link: "https://www.cordoba.es/proteccion-de-datos",
      alt: "Protección de datos"
    },
    {
      texto: "Registros de Actividades de Tratamiento",
      link: "https://www.cordoba.es/proteccion-de-datos#toc-registro-de-actividades-de-tratamiento",
      alt: "Registros de Actividades de Tratamiento"
    },
    {
      texto: "Declaración de Accesibilidad",
      link: "https://www.cordoba.es/declaracion-de-accesibilidad",
      alt: "Declaración de Accesibilidad"
    }
  ];
  
  const renderGridPrueba = () => {
    return (
      <div className={styles.gridContainer}>
        <ul className={styles.listContainer}>
        {informacion_corporativa.map((item, index) => (
          <li>
            <Link target="_blank" href={item.link} alt={item.alt} className={styles.customLink}>
              {item.texto}
            </Link>
            </li>
        ))}
        </ul>


      </div>
    
    );
  }
  

   ////////////////////////////////////////////////////////////

  const renderGrid = () =>{
    
    return mainColumnList && mainColumnList.map((columnList) => {
      return <div className={styles.footerMainColumn}>{

        columnList.columns.map((column) =>{
      
          return <div className={styles.footerColumn}>
            <h2 className={styles.colFooterTittle}>{column.label}</h2>
            {renderColumnRows(column)}
          </div>
        })
      }
      </div>
    });
  }
  const renderLinks = () =>{
    
    {/*ENLACES*/}
    return <div className={!isPhoneVersion ? styles.websiteRightsWrapper : styles.websiteRightsWrapperPhone}>
    {bloqueInferior && bloqueInferior.enlaces && bloqueInferior.enlaces.map((enlace)=>{
      return (
        enlace.texto.toLowerCase() !== WEB_RIGHTS_1 ?
          <a target="_blank" href={enlace.link} className={styles.websiteRights} title={enlace.texto}>{enlace.texto}</a>
        :
          <a onClick={onClickAccesibilidad} className={styles.websiteRights} title={enlace.texto}>{enlace.texto}</a>
      );
    })}
  </div>
}

  ////////////////////////////////////////////////////////////

  const onClickAccesibilidad = () => {
    history.push('/Accesibilidad');  
}

  const renderBottomPart = () =>{

    return <div className={!isPhoneVersion ? styles.bottomSectionWrap : styles.bottomSectionWrapPhone}>

      {/*ICONOS*/}
      <div className={!isPhoneVersion ? styles.bottomSectionLogos : styles.bottomSectionLogosPhone}>
        {mockFooterBottom.iconosCabecera.map((icono)=>{
          let estilo;
          if(isPhoneVersion){
            estilo = isFromApi ? styles.jaLogoPhone : styles.jaLogoPhoneMock;
          }else{
            estilo = isFromApi ? styles.jaLogo : styles.jaLogoMock;
          }
          // Se añade el tamaño del icono según su alt
          let width, height;
          if (icono.alt === 'Logotipo Edusi') {
            width = '143';
            height = '53';
          } else if (icono.alt === 'Logotipo Europea') {
            width = '140';
            height = '35';
          } else {
            width = '245'
            height = '50';
          }

          return (
            <div className={styles.imgContainer}>
              <img className={estilo} src={icono.src} alt={icono.alt} width={width} height={height}/>
            </div>
          );
        })}
      </div>
    </div>
  }

  ////////////////////////////////////////////////////////////

  /**Salto a versión movil*/
  const updateFormat = () => {
    if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
      setIsPhoneVersion(true);
    } else {
      setIsPhoneVersion(false);
    }
  };

  window.addEventListener('resize', updateFormat);
  ////////////////////////////////////////////////////////////

  return (

    <footer className={styles.footerContainer}>

      <div className={styles.bottomSection}>
        {renderBottomPart()}
      </div>

      <section className={mainColumnList.length===0 ? styles.footerGridEmpty : styles.footerGrid} >
        
          <div className={!isPhoneVersion ? styles.footerGridWrapper : styles.footerGridWrapperPhone}>
            {renderRightPart()}
            
            {renderRRSS()}
          </div>
          {renderLinks()}
        </section>
        <div className={styles.gridContainer}>
          {renderGridPrueba()} 
        </div>
      
      
    </footer>
    
  );
}

export default Footer;
