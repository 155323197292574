import React from 'react';
import { Slide, Grid, Container, ThemeProvider, Backdrop, Popover } from '@material-ui/core';
import {properties, texts} from './ConfirmacionData';
import useStyles from './ConfirmacionStyle';
import { compProperties as componentsProperties} from '../../../AppData';
import { useHistory } from "react-router-dom";
import TextoEnriquecido from '../textoEnriquecido/TextoEnriquecido';

/**
 * @function Confiramción muestra un ventana con una acción de confirmación (Aceptar, Sí...) o denegación (Cancelar, No...)
 * @param {*} props recibe el componente a través de las props, la variable isVisible para hacer el componente visible o no y el resto de parametrización del componente
 * @returns devuelve el componente en si.
 */

export default function Confirmacion(props) {

    const {customStyle, overAvisosCriticos, overAvisosCriticosResponsive} = useStyles(props.tamMarginTop, props.isPopUpPdf);

    let estilos = customStyle();
    const history = useHistory();

    const botonConfirmacion = () =>{
        props.accionConfirmacion();        
    }

    const botonDenegacion = () =>{
        if(props.accionDenegacion===componentsProperties.CONFIR_SIN_ACCION){
            props.handleChange(false)            
        }else{ 
            history.push(props.accionDenegacion);
        }
        
    }

    const botonCancelar = () =>{
        if(props.botonera!=componentsProperties.CONFIR_SOLO_ACEPTAR &&
            props.botonera!=componentsProperties.SOLO_CERRAR ){
            return(
                <Grid item><button className={props.isPhoneVersion ? estilos.classButtonCancelar : estilos.botonCancelar} onClick={botonDenegacion}>{textoBoton(properties.btnDenegacion)}</button></Grid>
            );
        }
    }

    const textoBoton = boton =>{
        let txtBoton = '';
        if(props.botonera===componentsProperties.CONFIR_SI_NO){
            if(boton===properties.btnConfirmacion){
                txtBoton = texts.btnSiText;
            }else{
                txtBoton = texts.btnNoText;
            }
        }else if(props.botonera===componentsProperties.SOLO_CERRAR){
            txtBoton = texts.txtBtnCerrar;
        }else{
            if(boton===properties.btnConfirmacion){
                txtBoton = texts.btnAceptarText;
            }else{
                txtBoton = texts.btnCancelarText;
            }
        }
        return txtBoton;
    }

    return (
        <ThemeProvider theme={props.isPhoneVersion ? overAvisosCriticosResponsive : overAvisosCriticos }>
            <Backdrop className={estilos.classBackdrop} open={props.mostrar} style={{zIndex:'5'}}>
                <Popover //Inserta el popUp
                open={props.mostrar}            
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Slide direction="left" in={props.mostrar} mountOnEnter unmountOnExit>
                    <Container>
                        {!props.quitarImg &&
                            <Grid container justify='center'>
                                <img className={estilos.icono} src={props.isImagenPersonalizada ? props.imagen : properties.AC_ICON_PATH} alt={texts.ALT_AC}/>
                            </Grid> 
                        }
                        <Grid container className={props.isPhoneVersion ? estilos.gridSeccionTituloResponsive : estilos.gridSeccionTitulo } justify='center'>
                            {props.titulo}
                        </Grid>
                        {!props.isTextoEnriquecido ? <Grid>
                            {props.centrarContenido ? <Grid container justify='center'> 
                                                    {props.mensaje}
                                                </Grid> : <Grid > 
                                                    {props.mensaje}
                                                </Grid>
                            } 
                        </Grid> : <Grid>
                            {props.centrarContenido ? <Grid container justify='center'> 
                                <TextoEnriquecido
                                    texto={props.mensaje}
                                />
                            </Grid> : <Grid > 
                                    <TextoEnriquecido
                                        texto={props.mensaje}
                                    />
                                    </Grid>
                                } 
                        </Grid>
                        
                        }                           
                            {/*BOTON CONFIRMAR-DENEGAR*/}
                            <Grid container className={props.isPhoneVersion ? estilos.classGridButtons : estilos.gridSeccionTres} direction='row' spacing={2} justify={props.isPhoneVersion ? 'center' : 'flex-end'}>                                
                                {botonCancelar()}
                                <Grid item><button className={props.isPhoneVersion ? estilos.classButton : estilos.botonAceptar} onClick={botonConfirmacion}>{props.textBotonConfirmarPersonalizado ? props.textoBotonConfirmar : textoBoton(properties.btnConfirmacion)}</button></Grid>                                                             
                            </Grid>
                    </Container>
                </Slide>
                </Popover>
            </Backdrop>
        </ThemeProvider>
    );
}
