import React,  {useState, useEffect}  from 'react';
import { Slide, Grid, Container, ThemeProvider } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import {texts} from './ventanaUsuarioSinAltaData';
import { overAvisosCriticos, overAvisosCriticosResponsive, customStyle } from './ventanaUsuarioSinAltaStyle';
import { useHistory } from "react-router-dom";
import { saveState } from './../../../providers/localStorage';
import {localStorageName} from './../../../providers/localStorageData';
import { ReactComponent as EntradaClave } from '../../pages/seleccionConvocatoriaPerfil/imagen/nouser.svg';



/**
 * @function ventanaUsuarioSinAlta muestra un aviso critico en PopUp modal
 * @param {*} props recibe el componente a través de las props y la variable isVisible para hacer el componente visible o no.
 * @returns devuelve el componente en si.
 */

export default function VentanaUsuarioSinAlta(props) {

    let estilos = customStyle();
    const history = useHistory();   

    const bottonAceptar = () =>{
        saveState(localStorageName.redirect, 'altaUsuario', false);
        history.push("/AltaUsuario"); 
        
    }

    const bottonCerrar = () =>{
        props.handleChange(false)
    }

    return (
                <ThemeProvider theme={props.isPhoneVersion ? overAvisosCriticosResponsive : overAvisosCriticos }>
                        <Popover //Inserta el popUp
                        open={props.mostrar}            
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Slide direction="left" in={props.mostrar} mountOnEnter unmountOnExit>
                            <Container>
                                <EntradaClave className={estilos.classIcons}/>                      
                                <Grid  container className={props.isPhoneVersion ? estilos.gridSeccionTituloResponsive : estilos.gridSeccionTitulo } justify='center'>
                                    <h2 className={props.isPhoneVersion ? estilos.clssTituloH2Responsive : estilos.classTituloH2}>{texts.AVISO}</h2>
                                </Grid>       
                                <Grid  container>
                                    <p className={estilos.classParrafo}>{texts.EXPLICACION}</p>
                                </Grid>    
                                <Grid>
                                    {/*PASO 1*/}
                                    <Grid className={estilos.gridSeccionPasos}>
                                        <p className={estilos.classA}>{texts.PASO1}</p>  
                                        <p className={estilos.classParrafo}>{texts.PASO1EXP}</p> </Grid>
                                </Grid>
                                <Grid>
                                    {/*PASO 2*/}
                                    <Grid className={estilos.gridSeccionPasos}>
                                        <p className={estilos.classA}>{texts.PASO2}</p>  
                                        <p className={estilos.classParrafo}>{texts.PASO2EXP}</p>
                                    </Grid>
                                </Grid>                         
                                    {/*BOTON ENTENDIDO-CERRAR*/}
                                    <Grid container className={estilos.gridSeccionTres} direction='row' justify='flex-end'>                                
                                        <Grid item className={estilos.alinearBotones}>
                                            <button className={estilos.botonCancelar} onClick={bottonCerrar}>CANCELAR</button>
                                            <button className={estilos.botonAceptar} onClick={bottonAceptar}>ACEPTAR</button>
                                        </Grid>                                
                                    </Grid>
                            </Container>
                        </Slide>
                        </Popover>
                </ThemeProvider>
    );
}
