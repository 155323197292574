//Declaracion de objetos con atributos
export const objAtrib = {
    gridPrincipal: {
        id: 'gridPrincipal'
    },
    gridContenedor: {
        id: 'gridContenedor'
    },
    tituloEncabezado: {
        id: 'encabezado'
    },
    button: {
        id: 'btnNuevoFormulario',
        name: 'btnNuevoformulario'
    },
    buttonImportar: {
        id: "btnImportarFormulario",
        name:"btnImportarFormulario",
        variant: "contained",
        component: "span",
    },
    inputImportar: {
        accept: "application/json",
        id: "inputImportar",
        name: "inputImportar",
        type: "file"
    }

}

export const compProperties = {
    CONFIR_SI_NO: 'confirSiNo',
    CONFIR_CANCELAR_ACEPTAR: 'confirCancelarAceptar',
    CONFIR_SOLO_ACEPTAR: 'confirSoloAceptar',
    CONFIR_SIN_ACCION: 'sinAccion',
}

export const objText = {
    tituloEncabezado: 'Generación de Formularios',
    nombreButton: 'NUEVO FORMULARIO',
    nombreImport: "IMPORTAR FORMULARIO",
    popup: {
        titulo: 'AVISO',
        mensaje: 'Se va a proceder a la eliminación del formulario, ¿desea continuar?.',
        botonera: compProperties.CONFIR_CANCELAR_ACEPTAR,
        accionDenegacion: compProperties.CONFIR_SIN_ACCION
    },
    popupImportar: {
        titulo: 'AVISO',
        mensaje: 'Se va a proceder a la importación un formulario, ¿desea continuar?.',
        botonera: compProperties.CONFIR_CANCELAR_ACEPTAR,
        accionDenegacion: compProperties.CONFIR_SIN_ACCION
    },
    popupImportarActu: {
        titulo: 'AVISO',
        mensaje: 'Se va a proceder a actualizar el contenido de un formulario ya existente, ¿desea continuar y descargar un backup del formulario actual?.',
        botonera: compProperties.CONFIR_CANCELAR_ACEPTAR,
        accionDenegacion: compProperties.CONFIR_SIN_ACCION
    }
}

export const cabecerasTabla = [
    "NOMBRE",
    "TIPO",
    "ACCIONES"
]


export const properties = {
    INIT_PAGE: 1,
    RECORDS_PER_PAGE: 5,
}

export const msgError = {
    severity: 'Error',
    error: 'Ha ocurrido un error al intentar recuperar los datos.'
}