import React, { useEffect } from 'react';
import { Grid, ThemeProvider } from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { AttachFile } from '@material-ui/icons';
import useStyles from './SubirArchivoVoluntarioStyle'
import { InputDescripcion, texts } from './SubirArchivoVoluntarioData';
import Input from '../../commons/input/Input';
import useOperations from './SubirArchivoVoluntarioFunction';
import VisualizadorPdf from 'components/commons/visualizadorPdf/VisualizadorPdf';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import {pasosNuevaSolicitud, compProperties, properties as appProperties} from './../../../AppData';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function SubirArchivoVoluntario(props) {

    const { updateFormat, isPhoneVersion, mostrarPDF, cerrarPopUpPdf, abrirPopUpPdf, cortarTexto, mostrarTextoEntero, mostrarTexto } = useOperations();

    const {customStyle, overInputs } = useStyles(isPhoneVersion);
    const style = customStyle();
    const imageMaxSize = props.tamMaximoArchivo * 1000000 //Bytes
    let estiloContenedor = props.styleGridContenedor === null ? style.clasGridContenedor : props.styleGridContenedor;


    useEffect(() => {
        updateFormat();
        return () => window.removeEventListener('resize', updateFormat);
    }, []);

    window.addEventListener('resize', updateFormat);
    return (
        <Grid className={style.classGridContenedor} disabled={true}>
                {props.mostrarDocAnexo && <Grid>
                        {mostrarTexto ? (
                            <Grid className={style.classDivIcon} onClick={mostrarTextoEntero}>
                                <KeyboardArrowDownIcon className={style.classIcon}/>
                                <p title={props.value} className={style.classTextoEntero} >{props.value}</p>
                            </Grid>
                                    ) : (  
                            <Grid className={style.classDivIcon} onClick={mostrarTextoEntero}>
                                <KeyboardArrowRightIcon className={style.classIcon}/>   
                                <p title={props.value} className={style.classTextoCortado}>{cortarTexto(props.value)}</p>
                            </Grid>   
                        )}
                            <Grid className={style.classGridImg}>
                                <img src={props.img} className={style.classImg}/>
                                <p className={style.classParrafo}>{texts.TAM}{props.tamDoc}{texts.PESO}</p>
                            </Grid>
                            <button className={style.classButtonCancelar} name={props.value} onClick={props.bottonFunction}>{texts.ELIMINAR}</button>
                            {props.poderVisualizar &&
                                <button className={style.classButtonVer} name={props.value} onClick={abrirPopUpPdf}>{texts.VER}</button>
                            }
                        </Grid>
                }
                {!props.mostrarDocAnexo &&
                    <ThemeProvider theme={overInputs}>
                            <Input //Descripcion
                                classes={style}
                                {...InputDescripcion}
                                id={props.id !== undefined && props.id !== null ? props.id : InputDescripcion.id}
                                value={props.value}
                                onChange={props.onChange}
                                disabled = {props.isDisabled}
                            />
                        {props.mostrarDrop &&
                            <DropzoneAreaBase
                                Icon={AttachFile}
                                acceptedFiles={props.formato}
                                dropzoneText={texts.TEXTO_DROP}
                                filesLimit={1}
                                onAdd={props.handleOnDrop}
                                maxFileSize={imageMaxSize}
                                onDropRejected={props.handleRejection}
                                showAlerts={[]}
                            />
                        }
                </ThemeProvider>
                }
            {/*Confirmar para mostrar pdf*/}
            <Confirmacion 
                accionConfirmacion={cerrarPopUpPdf} 
                mostrar={mostrarPDF} 
                isPhoneVersion={isPhoneVersion}
                mensaje={<VisualizadorPdf pdf={props.pdf} isPhoneVersion={isPhoneVersion} pdfNombre={props.value}/>}
                botonera={compProperties.SOLO_CERRAR}
                quitarImg={true}
                tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_0}
                isPopUpPdf={true}
                textBotonConfirmarPersonalizado={false}
                isImagenPersonalizada={false}
                centrarContenido={true}
                isTextoEnriquecido={false}
            />
            </Grid>
        
    )
}