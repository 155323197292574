import React, { useEffect } from 'react';
import SelectAutocomplete from '../../selectAutocomplete/SelectAutocomplete';
import useFunctions from './selectAutocompleteFormioFunctions';
import Style from './selectAutocompleteFormioStyle';

export default function SelectAutocompleteFormio(props) {
    const { value, options, isPhoneVersion, updateFormat, handleChange, getOptionsFromEndPoint } = useFunctions();
    const style = Style();

    /***** Variables de acceso rápido *****/
    let controller = props.controladorEntpoint;
    let url = props.urlEndPoint;

    useEffect(() => {
        updateFormat();
        (controller !== undefined) && getOptionsFromEndPoint(controller, url);
    }, []);

    window.addEventListener('resize', updateFormat);

    return (
        <SelectAutocomplete
            idGrid={props.idGrid}
            id={props.id}
            name={props.name}
            label={props.label}
            maxlength={props.maxlength}
            classes={style}
            options={options}
            getOptionLabel={(option) => option[props.optionSelect]}
            onChange={handleChange}
            value={value}
            idInput={props.idInput}
            nameInput={props.nameInput}
            placeholder={props.placeholder}
            noOptionsText={props.noOptionsText}
            refSelect={props.refSelect}
            isPhoneVersion={isPhoneVersion}
            isRequired={props.isRequired}
            textRequired={'*'}
        />
    );
}