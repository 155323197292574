import {makeStyles} from '@material-ui/core/styles'
import {colores, tipografia} from '../../../AppStyle'

export const customStyle = makeStyles ({
 
    gridFecha:{
        width: 'fit-content',
        height: 'inherit',
    },
    diaTexto:{
        marginRight: '15px',
        fontFamily: tipografia.regular,
        color: colores.escalaGrises.cero,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'center',
    },
    horaTexto:{
        fontFamily: tipografia.regular,
        color: colores.escalaGrises.cero,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'center',
    }
});


