import React, { useState, useEffect, useContext } from 'react';
import { texts } from './serviciosAtencionCiudadaniaData';
import { properties as appProperties, texts as appTexts } from '../../../AppData';
import { Grid  } from '@material-ui/core';
import { customStyle } from './serviciosAtencionCiudadaniaStyle';
import PersonIcon from '@material-ui/icons/Person';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AppContext from 'AppContext';
import AuthProvider from './../../../providers/authProvider';
import RestProvider from 'providers/RestProvider';

function ServiciosAtencionCiudadania() {

    //HOOKS
    const {obtenerProperties} = RestProvider();
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const context = useContext(AppContext);
    const [mailProcCTDIIUE, setMailProcCTDIIUE] = useState();
    const [mailProcComercioOtros, setMailProcComercioOtros] = useState();
    const [mailProcIndustriaMinas, setMailProcIndustriaMinas] = useState();
    const [tlfProcCTDIIUE, setTlfProcCTDIIUE] = useState();
    const [tlfProcComercioOtros, setTlfProcComercioOtros] = useState();
    const [tlfProcIndustriaMinas, setTlfProcIndustriaMinas] = useState();


    const addProperties = async () => { 
        const propertiesList = [
            'procedimientos.ctdiiue.mail', 'procedimientos.comercio.otros.mail', 'procedimientos.industria.minas.mail',
            'telefono.sac.universidades', 'telefono.sac.comercio', 'telefono.sac.industria'
        ];
    
        try {
            let properties = await obtenerProperties(propertiesList);    
            loadCloudInfo(properties);
        } catch (error) {
            console.error('error obteniendo las properties', error);
        }
    };

    //LLAMADAS
    useEffect(() => {
        addProperties()
        updateFormat();
        context.updateTitlePage(texts.PAGE_TITLE);
    }, []);

    //FUNCIONES

    const loadCloudInfo = (properties) => {

        // MAIL
        setMailProcCTDIIUE(properties['procedimientos.ctdiiue.mail']);
        setMailProcComercioOtros(properties['procedimientos.comercio.otros.mail']);
        setMailProcIndustriaMinas(properties['procedimientos.industria.minas.mail']);

        // TLF
        setTlfProcCTDIIUE(properties['telefono.sac.universidades']);
        setTlfProcComercioOtros(properties['telefono.sac.comercio']);
        setTlfProcIndustriaMinas(properties['telefono.sac.industria']);
    }

    /**Método que capta el tamaño de la ventana y actualiza actualiza
    ***el hook que controla cuando se da el salto a versión movil*/
    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    };


    //RENDER

    return (
        <main>
            <Grid container direction='column' justify='space-around'>
                <Grid direction={isPhoneVersion ? 'column-reverse' : 'row'} justify='space-between'>
                    <Grid container className={customStyle().gridPageTitle}>
                        <h1 className={customStyle().classTitleH2} id="tituloPagina">{texts.PAGE_TITLE}</h1>
                    </Grid>
                </Grid>
                <Grid className={customStyle().backgroundRectangle}>
                    <Grid container direction='row'  justify='space-around'>                        
                        
                        {/* Grid contenedor de contactos de cada procedimiento*/}
                        <Grid className={customStyle().classGridContainerContactos}>
                            <Grid className={customStyle().classGridContactos}>
                                <h5 className={customStyle().classTitleH5}>Atención a la ciudadanía para procedimientos de Empresa Digital, Infraestructura de Telecomunicaciones, Investigación e innovación y Universidades</h5>
                                <ul item className={ isPhoneVersion ? customStyle().gridTableResponsivePager : customStyle().classUl} >
                                    <p><FiberManualRecordIcon fontSize="inherit" color="action"></FiberManualRecordIcon>&nbsp;&nbsp;&nbsp;E-mail: <a target="_blank" className={customStyle().classLink} href={"mailto:" + mailProcCTDIIUE}>{mailProcCTDIIUE}</a></p>
                                    <br></br><p><FiberManualRecordIcon fontSize="inherit" color="action"></FiberManualRecordIcon>&nbsp;&nbsp;&nbsp;Tfno: {tlfProcCTDIIUE}</p>
                                </ul>
                            </Grid>
                            <Grid className={customStyle().classGridContactos}>
                                <h5 className={customStyle().classTitleH5}>Atención a la ciudadanía para procedimientos de Comercio y Otros</h5>
                                <ul item className={ isPhoneVersion ? customStyle().gridTableResponsivePager : customStyle().classUl} >
                                    <p><FiberManualRecordIcon fontSize="inherit" color="action"></FiberManualRecordIcon>&nbsp;&nbsp;&nbsp;E-mail: <a target="_blank" className={customStyle().classLink} href={"mailto:" + mailProcComercioOtros}>{mailProcComercioOtros}</a></p>
                                    <br></br><p><FiberManualRecordIcon fontSize="inherit" color="action"></FiberManualRecordIcon>&nbsp;&nbsp;&nbsp;Tfno: {tlfProcComercioOtros}</p>
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </main>
    )

}

export default ServiciosAtencionCiudadania