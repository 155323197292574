import { getContextFromUrl } from 'config/Config';

export const properties = {
    SPECIAL_COLUMN_TYPE : 'directo',
    MEDIA_PATH: 'header/',
    TLFN_ICON_PATH: `${getContextFromUrl()}` +  'header/telefono.svg',
    RESOURCE : 'configuraciones',
    URL : 'login',
    ALT_ICONO_MENU: 'Menú principal'
}

export const texts = {
    MSG_CONTACTO: 'Llámenos a nuestro número de teléfono',
    TLFN:  '955 041 111',
    BOTON_MENU: 'MI CUENTA',
    conexion: 'Última conexión: ',
    sinUltConexion: 'sin información',    
}
