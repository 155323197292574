import { makeStyles } from '@material-ui/core/styles';
import { estilosTextosPrincipales, backgroundRectangle } from '../../../AppStyle';

export const customStyle = makeStyles({
    gridComponente: {
        ...backgroundRectangle,
        paddingBottom: '3%'
    },
    title: {
        ...estilosTextosPrincipales.encabezadoH3,
        marginBottom: '2.5% !important'
    }
});