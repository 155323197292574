import React, { useState, useEffect, useContext } from 'react';
import {ThemeProvider} from '@material-ui/core'
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab'
import { ReactComponent as Chevron } from './img/chevron_top_bottom_ic.svg';
import Select from '../select/Select';
import {properties} from './BotoneraVerticalData'
import {properties as propertiesListadoProcedimientos} from '../../pages/listadoProcedimientos/ListadoProcedimientosData'
import {localStorageName} from './../../../providers/localStorageData';
import { loadState } from './../../../providers/localStorage';
import {customStyle, overrideStyle} from './BotoneraVerticalStyle';
import {properties as appProperties} from '../../../AppData';
import AppContext from 'AppContext';
import useApp from 'AppFunctions';

export default function BotoneraVertical(props) {

    // HOOKS
    const [phoneOptions, setPhoneOptions] = useState([]);
    const [firstTime, setFirstTime] = useState(true);
    const [pcSelected, setPcSelected] = useState(properties.MISTRAMITES_BUTTON);
    const contexto = useContext(AppContext)
    let style = customStyle();
    let optionsPhoneVs;
    let isClickedBtnMisTramitesPhone = loadState(localStorageName.misTramitesVphone, false);
    let username = loadState(localStorageName.username, false);
    const {quitarTildes} = useApp();

    useEffect(() => {        
        botonPorDefecto();         
    }, []) ;

    useEffect(() => { 
        if(contexto.sesion === appProperties.SESION_CIERRE){
            borrarOpcionMisTramitesSelector();
        }       
    }, contexto.sesion) ;

    /*Efecto que controla si se ha pulsado el botón Mis Trámites desde la versión móvil. Si se da el caso
    actualiza el valor de la opción seleccionada en la versión móvil, la establece en Mis Trámites y
    obtiene los trámites del usuario.*/
    useEffect(()=> {
        if (props.isPhoneVersion && isClickedBtnMisTramitesPhone !== undefined && props.data.length !== 0) {
            let value = properties.MISTRAMITES_BUTTON;
            props.updatePhoneSelectedOption(value);
            mostrarTramUsuario();
            localStorage.removeItem(localStorageName.misTramitesVphone);
        }
    }, [props.data]);

    const botonPorDefecto = () =>{
        const rutaOrigen = loadState(localStorageName.redirect, false);
        if(rutaOrigen==='loginCabecera'){
            mostrarTramUsuario();            
        }
    }

    const mostrarTramUsuario = () =>{
        let procedimientosUsuario = [];

        for(var i = 0; i < props.data.length; i++){
            for(var x = 0; x < props.dataUsuarioPCT.length; x++){
                if(props.data[i].id === props.dataUsuarioPCT[x]){
                    procedimientosUsuario.push(props.data[i]);
                }
            }
            
        }

        
        /** Ordenación de los procedimientos de la pestaña "Mis Trámites" */
        procedimientosUsuario.sort((a, b) => a.descripcion > b.descripcion ? 1 : -1);
        props.updateSearch(procedimientosUsuario);
    }

    /**Captura el evento de click sobre la botonera, borra el input que hubiera en la
    ***barra de busqueda, si el botón es Todos, lo muestra todo,
    ***pero si es otro, busca el id del tipo de procedimiento en cuestión y filtra
    ***los procedimientos que sean de ese tipo*/
    const onClick = (event) => {
       
        let value;
        if(props.isPhoneVersion){
            value = event.target.value;
            props.updatePhoneSelectedOption(value);
        }else{
            value = event.currentTarget.getAttribute('value');
            setPcSelected(value)

        }

        /* if(value===properties.DEFAULT_BUTTON ){
            if(!props.isPhoneVersion && props.refDefaultButtonBotonera.current.getAttribute('searchAll')==='false'){
                props.refDefaultButtonBotonera.current.setAttribute('searchAll', 'true')
            }else{
                props.updateSearch(props.data);
                props.refAutocomplteSearchBar.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
            }
        }else */ if(value===properties.MISTRAMITES_BUTTON){
            mostrarTramUsuario();
        }
        else{
            let idTipoProcedimientoPct3;
            props.data.map((procedimiento)=>{
                idTipoProcedimientoPct3 = props.tipoPct3.filter(val => quitarTildes(val.nombre).toLowerCase() === quitarTildes(value).toLowerCase()).map((val)=>{return val.id})[0];
                
            });

            let filtrado = [];
            props.data.map(val => {
                if(val.idTipoProcedimiento === idTipoProcedimientoPct3){
                    filtrado.push(val);
                }
            });
            props.updateSearch(filtrado);
            props.refAutocomplteSearchBar.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
            
        }  

        if(!props.isPhoneVersion){
            if (value === properties.MISTRAMITES_BUTTON /* || value === properties.DEFAULT_BUTTON */) {
                value = username === 'aplicacion'?  /*properties.idDefaultButton : */ properties.idMistramites: properties.idMistramites;
            }
            document.getElementById(value).focus();
        }

        //Actualizar pag actual
        props.paginate(propertiesListadoProcedimientos.INIT_PAGE);
    }

    

    /**Renderizado del componente como botonera para formato ordenador */
    const renderButtonProp = () =>{

        return <ThemeProvider theme={overrideStyle}>
            <ul className={style.listaBotones}>
                <ToggleButtonGroup className={style.flexContainer}
                    exclusive
                    value={pcSelected}
                    onChange={onClick}
                    style={{marginBottom:'3%'}}
                >
                    
					{props.isLogin &&
                        //Boton mis tramites para usuarios logeados
                        <ToggleButton className={style.titulos} disableRipple id={properties.idMistramites} title={properties.MISTRAMITES_BUTTON} key={properties.MISTRAMITES_BUTTON} value={properties.MISTRAMITES_BUTTON} variant='outlined'><li>{properties.MISTRAMITES_BUTTON}</li></ToggleButton>
                    }
{/*                     <ToggleButton className={style.titulos} disableRipple id={properties.idDefaultButton} key={properties.DEFAULT_BUTTON} value={properties.DEFAULT_BUTTON} ref={props.refDefaultButtonBotonera} searchAll='true' variant='outlined'><li>{properties.DEFAULT_BUTTON}</li></ToggleButton>
 */}                    {props.buttonsPropiedades && props.buttonsPropiedades.map(item => (
                        <ToggleButton className={style.titulos} disableRipple id={item.descripcion} title={item.descripcion} key={item.descripcion} value={item.descripcion} variant='outlined'><li>{recortarTitulosProcedimientos(item.descripcion)}</li></ToggleButton>
                    ))}
                    
                </ToggleButtonGroup>
            </ul>
        </ThemeProvider> 
    }


    /**Añadir para la versión movil la opción por defecto a los tipos de procedimientos*/
    const addDefaultToOptions = () =>{

        if (props.buttonsPropiedades.length !==0 && firstTime) {

            optionsPhoneVs = props.buttonsPropiedades; 
            /* optionsPhoneVs.unshift({
                nombre: properties.DEFAULT_BUTTON,
                descripcion: properties.DEFAULT_BUTTON
            }); */
            
            if (props.isLogin) {
                optionsPhoneVs.unshift({
                    nombre: properties.MISTRAMITES_BUTTON,
                    descripcion: properties.MISTRAMITES_BUTTON
                });
            } 

            setPhoneOptions(optionsPhoneVs);
            setFirstTime(false)            
        }
    }

    //Cuando cierra sesion desde la version movil hay que borrar del selector la opcion Mistramites
    const borrarOpcionMisTramitesSelector = () =>{
        let tmpOptions;

        if(props.isPhoneVersion){
            tmpOptions = phoneOptions.filter(option => 
                option.descripcion !== properties.MISTRAMITES_BUTTON
            )
            setPhoneOptions(tmpOptions);
        }
        
    }
        
    /**Renderizado del componente como select para formato ordenador */
    const renderSelectProp = () => { 
        addDefaultToOptions();
        return <div className={style.selectorMargen}>
            <Select
                id= 'nombrePropiedad'
                name= 'Propiedad'
                IconComponent= {Chevron}
                label= ''
                value={props.phoneSelectedOption} //Opción selecionada
                options={phoneOptions} //Opciones
                onChange={onClick}
                />
        </div>   
    }

    const recortarTitulosProcedimientos = (descripcion) => {
        let arrayDescripcion = descripcion.split(' ');
        if(arrayDescripcion.length == 1){
            return descripcion;
        }else{
            let descripcionLarga = '';
            for(var i = 0; i < arrayDescripcion.length; i++){
                let d = arrayDescripcion[i];
                if(d.length > 12){
                    descripcionLarga = descripcionLarga + ' ' + d.substring(0,11) + '.';
                }else{
                    descripcionLarga = descripcionLarga + ' ' + d;
                }
            }
            return descripcionLarga;
        }
    }

    // RENDER
    return (

        <div className={style.flexContainer}>
            {props.isPhoneVersion ? (
                renderSelectProp()
            ) : (  
                renderButtonProp()
            )}    
        </div>
    )
}
