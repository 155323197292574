import {textsAytm as appTexts } from '../../../AppData';
import { getContextFromUrl } from 'config/Config';

export const texts = {
    PAGE_TITLE: 'Nueva solicitud de ',
    PAGE_TITLE_SUBSANACION: 'Nueva subsanación de ',
    PAGE_TITLE_JUSTIFICACION: 'Nueva justificación de ',
    PAGE_TITLE_ALEGACION: 'Nueva alegación de ',
    PAGE_TITLE_MOD: 'Modificación de solicitud de ',
    PAGE_TITLE_SUBSANACION_MOD: 'Modificación de subsanación de ',
    PAGE_TITLE_JUSTIFICACION_MOD: 'Modificación de justificación de ',
    PAGE_TITLE_ALEGACION_MOD: 'Modificación de alegación de ',
    TEXT_PRINCIPAL: 'Nueva Solicitud de ',
    FIRMA: 'Firma',
    DESC_PAG_FIRMA: 'Consulte y firme la solicitud así como los anexos',
    DATOS_FIRMANTE_PROC: 'Datos del firmante y del procedimiento',
    NIF: 'NIF',
    NOMBRE_APELLIDOS: 'Nombre y apellidos',
    PROCEDIMIENTO: 'Procedimiento',
    ESPACIO: " ",
    IMPRESOS_SOLICITUD: 'Impresos de solicitud',
    DESC_IMPRESOS_SOLICITUD: 'Documentacion por firmar para presentar en la solicitud',
    LISTADO_DOC: 'Listado de documentos',
    TEXT_BOTON_ATRAS: 'ATRÁS',
    TEXT_BOTON_SMS:' FIRMA SMS E IR AL PASO 4: JUSTIFICANTE Y RECIBÍ',
    TEXT_BOTON_IR_PASO4: 'FIRMAR E IR AL PASO 4: JUSTIFICANTE Y RECIBÍ',
    TEXT_BOTON_FIRMA: 'FIRMAR',
    TEXT_BOTON_REVISAR_SOLICITUD: 'ENVIAR AL REPRESENTANTE LEGAL',
    TEXT_BOTON_CORREGIR_SOLICITUD: 'CORREGIR SOLICITUD',
    TEXT_BOTON_REVISAR_SUBSANACION: 'REVISAR SUBSANACIÓN',
    TEXT_BOTON_CORREGIR_SUBSANACION: 'CORREGIR SUBSANACIÓN',
    TEXT_CONFIRMAR_SOLICITUD: '¿Está seguro de firmar la solicitud?',
    TEXT_CONFIRMAR_DESCRIPCION: 'Una vez firmada la solicitud estará presentada y no podrá modificarla. Le aconsejamos que revise que todo está correcto antes de firmar.',
    TITLE_SOLICITUD_COLABORATIVA: 'Firmado del formulario',
    TITLE_SUBSANACION: 'Subsanación del formulario',
    TEXT_SOLICITUD_COLABORATIVA: 'Se va a proceder a la firma del formulario de solicitud, aún queda pendiente anexar los anexos a la solcitud. </br><b>La solicitud no quedará presentada</b>, hasta que no se realice este paso.',
    TEXT_SUBSANACION_COLABORATIVA: 'Se va a proceder a la firma del formulario de subsanación de la solicitud, aún queda pendiente subsanar los anexos a la solcitud. </br><b>La solicitud no quedará presentada</b>, hasta que no se realice este paso.',
    TITLE_REVISION_SOLICITUD_COLABORATIVA_SICA: 'Solicitud de Revisión',
    TEXT_REVISION_SOLICITUD_COLABORATIVA_SICA: 'Se va a proceder a la revisión de la solicitud.',
    TITLE_CORREGIR_SOLICITUD_COLABORATIVA_SICA: 'Solicitud de Corrección',
    TEXT_CORREGIR_SOLICITUD_COLABORATIVA_SICA: 'Se va a proceder a mandar la solicitud para su corrección',
    TITLE_REVISION_SUBSANACION_COLABORATIVA_SICA: 'Solicitud de Revisión de Subsanación',
    TEXT_REVISION_SUBSANACION_COLABORATIVA_SICA: 'Se va a proceder a la revisión de la subsanación.',
    TITLE_CORREGIR_SUBSANACION_COLABORATIVA_SICA: 'Solicitud de Corrección de subsanación',
    TEXT_CORREGIR_SUBSANACION_COLABORATIVA_SICA: 'Se va a proceder a mandar la subsanación para su corrección',
    TEXT_BOTON_ACEPTAR_FIRMA: 'QUIERO FIRMAR LA SOLICITUD',
    TITLE_ERROR_PRESENTACION: 'Ha ocurrido un error en la presentación de la solicitud',
    PARTE_1_MSG_ERROR: "Se ha producido un error técnico.",
    PARTE_2_MSG_ERROR: "Puede volver a intentar realizar el proceso de firma y, si el error persiste, contacte con el servicio de Información y Sugerencias del Servicio de Información y Atención Ciudadana que puede consultar <a href="+appTexts.URL_CONTACTO+">aquí</a>.",
    VALUE_NOT_FOUND: "No se ha encontrado ningún valor",
    TEXT_BLACKDROP: "Se está realizando el proceso de firma, espere por favor",
    TITULO_DIALOGO: "Código SMS",
    TEXTO_DIALOGO: "Se ha enviado el código para la firma por SMS al teléfono que consignó al registrarse en la Sede electrónica. Por favor, introduzca en la casilla el código SMS recibido",
    PLACEHOLDER_DIALOGO: "Código SMS",
    BTN_ACCION_DIALOGO: "ENVIAR CÓDIGO",
    BTN_CANCELAR_DIALOGO: "CANCELAR",
    TEXT_BLACKDROP_SICA: "Se está realizando el proceso, espere por favor",
}

export const properties = {
    ID_PAGE_TITLE: 'tituloPagina',
    URL_APORTE_DOCUMENTACION: '/AporteDocumentacion',
    IMAGEN_CONFIRMAR: `${getContextFromUrl()}` + '/oval.png',
    IMAGEN_ERROR : `${getContextFromUrl()}` +  'exclamation-triangle-solid.svg',
    URL_SOLICITUD_PRESENTADA: '/SolicitudPresentadaCorrectamente',
    URL_BANDEJA_SOLICITUDES: '/BandejaSolicitudes',
    RESOURCE_PRESENTAR_SOLICITUD: 'entregas',
    RESOURCE_PRESENTAR_SOLICITUD_PCT3: 'presentacion-entrega',
    URL_PRESENTAR_SOLICITUD: '/presentacionEntrega',
    PROCESO_ENTREGA_SOLICITUD: 'SOLICITUD',
    PROCESO_ENTREGA_SUBSANACION: 'SUBSANACION',
    PROCESO_ENTREGA_ALEGACION: 'ALEGACION',
    PROCESO_ENTREGA_JUSTIFICACION: 'JUSTIFICACION',
    PROCESO_ENTREGA_APORTE_DOCUMENTACION: 'APORTE DOCUMENTACION',
    PROCESO_ENTREGA_ANEXO_A_LA_SOLICITUD: 'ANEXO_A_LA_SOLICITUD',
    PROCESO_ENTREGA_SOLICITAR_REVISION: 'SOLICITAR_REVISION',
    PROCESO_ENTREGA_CORREGIR_SOLICITUD: 'CORREGIR_SOLICITUD',
    PROCESO_ENTREGA_SOLICITAR_REVISION_SUBSANACION: 'SOLICITAR_REVISION_SUBSANACION',
    PROCESO_ENTREGA_CORREGIR_SUBSANACION: 'CORREGIR_SUBSANACION',
    PROCESO_ENTREGA_FIRMAR_SOLICITUD_SICA: 'FIRMAR_SOLICITUD_SICA',
    PROCESO_ENTREGA_FIRMAR_SUBSANACION_SICA: 'FIRMAR_SUBSANACION_SICA',
    PROCESO_ENTREGA_COD_DESISTIMIENTO: 'DESISTIM',
    PROCESO_ENTREGA_COD_SUBSANACION: 'SUBSANA',
    PROCESO_ENTREGA_COD_ALEGACION: 'ALEGACIÓN',
    PROCESO_ENTREGA_COD_JUSTIFICACION: 'JUSTIFICACION', // Aún sin definir en BBDD
    PROCESO_ENTREGA_COD_APORTE_DOCUMENTACION: 'AP_DOC',
    PROCESO_ENTREGA_COD_INICIO: 'F_INICIO',
    PROCESO_ENTREGA_COD_SUBSANACION_SEQUIA: 'SUBSANACION_SEQUIA',
    PROCESO_ENTREGA_COD_SUBSANACION_ALTA_ANEXO: 'SUBSANACION_ANEXO_A_LA_SOLICITUD',
    RESOURCE_ANEXOS: 'anexos',
    RESOURCE_DOCUMENTO: 'documento',
    URL_OBTENER_ANEXOS: '/obtenerAnexos',
    TIPO_DOC_FORMULARIO: 'FORMULARIO',
    TIPO_DOC_OBLIGATORIO: 'ANEXO_OBLIGATORIO',
    TIPO_DOC_OPTATIVO: 'ANEXO_OPTATIVO',
    TIPO_DOC_VOLUNTARIO: 'ANEXO_VOLUNTARIO',
    TIPO_DOC_ANEXO: 'ANEXO',
    FASE_PENDIENTE_DE_SUBSANAR: 'PENDIENTE DE SUBSANAR',
    FASE_PDT_ALEGACIONES: 'Presentación de alegaciones',
    FASE_INICIO: 'INICIO',
    ESTADO_FASE_INICIO: 'E_INICIO',
    ESTADO_FASE_PENDIENTE_SUBSANACION: 'PENSUB_BAS',
    FASE_PENDIENTE_JUSTIFICACION: 'PENDIENTE DE JUSTIFICAR',
    URL_OBTENER_ANEXOS_PCT3: '/findDocAnexo',
    RESOURCE_FASE: 'fase',
    RESOURCE_ANEXO: 'confAnexo',
    URL_ANEXO: '/findById/',
    CRUD_TYPE_POST: 'post',
    RESOURCE_SMS: 'p3s',
    URL_ENVIAR_SMS: '/enviarSMS',
    RESOURCE_DATOS_CONTACTO: 'datos-contacto',
    URL_TLF_MOVIL: '/getTlfMovFromDcXdefecto/',
    URL_COMPROBAR_CODIGO: '/consulta/',
    COD_FASE_PDT_SUBSANACION: 'SUBSANA',
    COD_ESTADO_PDT_SUB_BAS: 'PENSUB_BAS',
    URL_NUEVA_SOLICITUD: '/NuevaSolicitud',
}

export const constantesEstadosFasePCT3 = {
    F_INICIO_OV3: null,
    F_INICIADO_OV3: null,
    F_SUBSANACION_OV3: null,
    F_ALEGACIONES_OV3: null,
    F_JUSTIFICACION_OV3: null,
    F_APORTE_DOCUMENTACION_OV3: null

};

export const atribInputDialog = {
    id: 'codigoSms',
    name: 'codigoSms',
    type: 'text',
    disabeld: false    
}

export const tipoFirma = {
    FIRMA_SIMPLE: 'FIRMA_SIMPLE',
    FIRMA_MULTIPE: 'FIRMA_MULTIPLE',
    FIRMA_SMS: 'FIRMA_SMS'
}