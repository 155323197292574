import React, {useState} from 'react';
import { Container, Grid, Button, ThemeProvider } from '@material-ui/core';
import Style, { overMenuPhone } from './menuUsuarioStyle';
import { objAtributes, objText, mock } from './menuUsuarioData';
import { loadState, saveState } from './../../../providers/localStorage';
import {useHistory} from "react-router-dom";
import { localStorageName } from './../../../providers/localStorageData';

/****************************************************************
TEMP: Iconos temporales hasta la obtención de los definitivos***/
import { ReactComponent as Tramites } from './images/tramites.svg';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
/***************************************************************/

/**
 * @function Función que devolverá el componente menu usuario en versión móvil.
 * El componente está formado por dos títulos, tres <Button/> y una etiqueta <span/>
 * que contiene la última conexión del usuario.
 * @param {*} props recibida desde la cabcera
 * @returns Devuelve el componente en sí.
 */
export default function MenuUsuarioVphone(props) {

    const infoUsuario = loadState(localStorageName.infoUsuario, true);
    const [anchorEl, setAnchorEl] = useState(null);
    const nombreUsuario = infoUsuario.interesadoApellido2 !== null ?
    infoUsuario.interesadoNombre + ' ' + infoUsuario.interesadoApellido1 + ' ' + infoUsuario.interesadoApellido2
        : infoUsuario.interesadoNombre + ' ' + infoUsuario.interesadoApellido1;

    const history = useHistory(); //Para navegar entre paginas de la propia web

    const redirectAltaUsuario = () => {
        props.cerrarMenu();
        setAnchorEl(null);
        history.push('/AltaUsuario');
    };

    const cerrarSesion = () => {
        props.handleChange();
    }

    //Funcion para Redirigir a listado de procedimentos
    const redirectListadoProc = () => {
        saveState(localStorageName.redirect, 'loginCabecera', false);
        setAnchorEl(null);
        
        if (window.location.hash === '#/') {
            //Se pulsa el botón del lista de procedimientos del usuario logado
            saveState(localStorageName.misTramitesVphone, true, false);
            props.cerrarMenu();
            window.location.reload();
        } else {
            //Se almacena un valor booleano para recuperarlo desde listadoProcedimientos.js para mostrar los procedimientos del usuario logado
            saveState(localStorageName.misTramitesVphone, true, false);
            //Reidrección al Home de la aplicación
            props.cerrarMenu();
            history.push('/');
        }
    };

    return (
        <ThemeProvider theme={overMenuPhone}>
            <Container className={Style().classContainerPhone} {...objAtributes.containers}>
                <Grid className={Style().classGridPhone} {...objAtributes.grid}>
                    <h3 className={Style().classH3Phone} {...objAtributes.h3}>{objText.mensajeBienvenida}</h3>
                    <h3 className={Style().classH1Phone} {...objAtributes.h3}>{nombreUsuario}</h3>
                    <Button
                        {...objAtributes.buttonOpcTramites}
                        className={Style().classOpcButtonsPhone}
                        startIcon={<Tramites />}
                        onClick={redirectListadoProc}
                    >
                        {mock.opcMenu[0]}
                    </Button>
                    <Button
                        {...objAtributes.buttonOpcDatosPersonales}
                        className={Style().classOpcButtonsPhone}
                        startIcon={<AccountCircleIcon />}
                        onClick={redirectAltaUsuario}
                    >
                        {mock.opcMenu[1]}
                    </Button>
                    <Button
                        {...objAtributes.button}
                        className={Style().classButtonPhone}
                        onClick={cerrarSesion}
                    >
                        {objText.boton}
                    </Button>                    
                </Grid>
            </Container>
        </ThemeProvider>
    );
}