import RestProvider from './RestProvider';
import AuthProvider from './authProvider';

const tokenHeaderName = "x-jwt-token";

const FormioProvider = () => {

    const {crudOperationEx, crudOperationsType} = RestProvider();
    const {checkError} = AuthProvider();

    /**
     * Proveedor que usa RestProvider en su tipo custom para conectar con formio.
     * @param {*} resource, el nombre del endpoint
     * @param {*} params, los parámetros
     */
    const formioOperation = (resource, params, isPrivate) => {
        return new Promise((resolve, reject) => {
            if (isPrivate) {
                params.headers = {};
                let formio_token = localStorage.getItem('formio_token');
                // No tengo token previo
                if (!formio_token) {
                    crudOperationEx('', crudOperationsType.CUSTOM, 'formio-auth')
                    .then(response => {
                        formio_token = response.data;
                        localStorage.setItem('formio_token', formio_token);

                        // Llamo con el token obtenido y lo guardo
                        params.headers[tokenHeaderName] = formio_token;
                        crudOperationEx('/formio', crudOperationsType.CUSTOM, resource, params)
                        .then(response => {
                            resolve(response);
                        })
                        .catch(error => {
                            reject(error);
                        });
                    }).catch(error => {
                        reject(error);
                    });
                } else {
                    // Tengo un token previo, lo uso
                    params.headers[tokenHeaderName] = formio_token;
                    crudOperationEx('/formio', crudOperationsType.CUSTOM, resource, params)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        // Si ha lo borro y notifico
                        if ((error.response.status === 400 && error.response.data === 'Bad Token') || error.response.status === 440) {
                            localStorage.removeItem('formio_token');

                            crudOperationEx('', crudOperationsType.CUSTOM, 'formio-auth')
                            .then(response => {
                                formio_token = response.data;
                                localStorage.setItem('formio_token', formio_token);
            
                                // Llamo con el token obtenido y lo guardo
                                params.headers[tokenHeaderName] = formio_token;
                                crudOperationEx('/formio', crudOperationsType.CUSTOM, resource, params)
                                .then(response => {
                                    resolve(response);
                                })
                                .catch(error => {
                                    reject(error);
                                });
                            }).catch(error => {
                                reject(error);
                            });
                        } else {
                            reject(error);
                        }
                    });
                }
            } else {
                crudOperationEx('/formio', crudOperationsType.CUSTOM, resource, params)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
            }
        });
    }

    const loginPageUrl = `${window.location.origin}/#/login`;

    /**
     * Comprueba si el error es del token de usuario de la app.
     * No confundir con el token de formio.
     * @param {*} error, el error recibido
     */
    const checkAppToken = (error) => {
        if (error && error.response) {
            checkError(error.response).catch(() => {
                window.location = loginPageUrl;
            });
            return error.response.status === 401 || error.response.status === 403;
        }
        return false;
    }

    return { checkAppToken, formioOperation };
}
export default FormioProvider;
