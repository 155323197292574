import RestProvider from 'providers/RestProvider';
import { properties } from './SICAData';

export default function SICAFunctions() {
    const { crudOperation, crudOperationsType } = RestProvider();

    /**
     * @function getDatosInstitucionSica Función que obtiene los datos institucionales de la api de sica
     * @param {*} acronym 
     * @param {*} registerNumber 
     * @returns 
     */
    const getDatosInstitucionSica = async (acronym, registerNumber) => {
        let res = null;

        if ((acronym !== undefined && acronym !== null) && (registerNumber !== undefined && registerNumber !== null)) {
            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE, { url: `${properties.urlInstituciones}/${acronym}/${registerNumber}`, method: 'get' }).then(response => {
                res = response.data;
            });
        }
        return res;
    }

    /**
     * @function getDatosPersonalesSica Función que obtiene los datos personales de la api de sica
     * @param {*} nif 
     * @returns 
     */
    const getDatosPersonalesSica = async (nif) => {
        let res = null;

        if (nif !== undefined && nif !== null) {
            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE, { url: `${properties.urlDatosPersonales}/${nif}`, method: 'get' }).then(response => {
                res = response.data;
            });
        }
        return res;
    }

    /**
     * @function getDatosRepresentanteSica Función que obtiene los datos de un representante de sica a partir de los datos institucionales y los personales
     * @param {*} acronym 
     * @param {*} registerNumber 
     * @returns 
     */
    const getDatosRepresentanteSica = async (acronym, registerNumber) => {
        let datosInstituciones = await getDatosInstitucionSica(acronym, registerNumber);
        let datosPersonales = await getDatosPersonalesSica(datosInstituciones[0].officialId);
        return datosPersonales !== null ? datosPersonales : null;
    }

    return { getDatosInstitucionSica, getDatosPersonalesSica, getDatosRepresentanteSica };
}