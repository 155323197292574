import React from 'react';
import { Grid } from '@material-ui/core';
import { customStyle } from './ComponenteFormioStyle';
import FormRecurso from 'components/commons/formAdmProc/FormAdmProc';
import '../../../BootstrapWrapper.scss';
import './ComponenteFormioStyle.css';

/**
 * @function ComponenteFormio Función que inserta componentes de formio (Formularios / Recursos)
 * @param {*} props.aliasFormulario Recibe el alias del formulario a insertar
 * @param {*} props.tituloComponente Recibe el título del componente a insertar y se establece el título
 * @param {*} props.onSubmit Recibe el evento onSubmit que se ejecutará cuando se ejecute dicho evento en el componente FormRecurso
 * @returns 
 */
export default function ComponenteFormio(props) {
    const Style = customStyle();

    return (
        <Grid className={'formioSolicitud'}>
            <h3 className={Style.title}>{props.tituloComponente}</h3>
            <Grid className={Style.backgroundRectangle}>
                <FormRecurso aliasFormulario={props.aliasFormulario}
                    onSubmit={props.onSubmit} onChange={props.onChange} submission={props.submission}
                    handleAcciones={props.handleAcciones}/>
            </Grid>
        </Grid>
    );
}