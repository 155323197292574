import { createMuiTheme } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles'
import {estilosTextosPrincipales, colores, tipografia, buttonPrimaryBig} from '../../../AppStyle';

export const customStyle = makeStyles ({

    colIcono:{
        width: '10%'
    },
    colAC:{
        width: '90%',
        display: 'inline-block'
    },
    colIconoResponsive:{
       
    },
    colACResponsive:{
        width: '100%',
    }, 
    gridAC:{
        width: '100%'
    },
    tituloAC:{
        ...estilosTextosPrincipales.encabezadoH5,
        fontSize: '17px',
        fontWeight: 'bold',
        lineHeight: '20px',
        marginBottom: '5px'
    },
    gridAvisoAC:{
        width: '100%',
        textAlign: 'justify',
        textJustify: 'inter-word',
        ...estilosTextosPrincipales.body1,
        fontSize: '17px',
        paddingRight: '14px',
    },
    gridSeccionTitulo:{
        width:'100%',
        height: 'auto',
        margin: '10px 0 10px',
        fontFamily: tipografia.regular,
        ...estilosTextosPrincipales.encabezadoH5,
        fontWeight: '700',
        lineHeight: '20px'
    },
    lineaHorizontal:{
        width: '100%',
        marginBottom: '15px', 
        border: `1px solid ${colores.escalaGrises.veinte}` 
    },
    gridSeccionTres:{
        marginTop: '30px',
        marginBottom: '30px',
        width: '100%',
        height: 'auto',
        justifyContent: 'center',
    },
    gridACIcono:{
        marginTop: '10px',
        width: 'fit-content',
        height: 'fit-content'
    },
    gridACIconoResponsive:{
        margin: 'auto',
        marginBottom: '15px',
        width: 'fit-content',
        height: 'fit-content'
    },
    gridLineaAC:{
        width: '100%',
    },
    classBackdrop: {
        zIndex: 0
    },
    botonEntendido:{  
        ...buttonPrimaryBig    
    },lineaHorizontalAvisos:{
        width: '95%',
        marginLeft: '15px',
        marginTop: '25px',
        marginBottom: '20px',
        border: `1px solid ${colores.escalaGrises.veinte}`,   
    }

});    

export const overAvisosCriticos = createMuiTheme({
    overrides: {
        MuiPopover: {
            root: {
                zIndex:'0',
                marginTop: '6%'
            }
        },
        MuiPaper:{
            root:{
                width: '45%',
                height: 'fit-content'
            }
        }
    }
});
