import React, {useEffect, useState}from 'react';
import {customStyle, overInputs} from './PagerStyle';
import {properties, texts} from './PagerData';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Style } from '@material-ui/icons';
import {Select, MenuItem, ThemeProvider} from '@material-ui/core';

const Pager = ({recordsPerPage, totalRecords, paginate, currentPage, numFilasMostrar, registrosTotales, handleChange, 

    numFilasPersonalizado, disabledRegistros, anchorEl}) => {


    let pageNumbersList = [];
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const claseItemActive = customStyle().ClassListingPagerlistItemActive;
    const claseItem = customStyle().ClassListingPagerlistItem;
    const claseDots = customStyle().ClassListingPagerListDots;
    const claseItemA = customStyle().classItemSelected;
    const claseItemD = customStyle().classItem;
    let disabledSiguiente = false;
    if(totalPages == 0 || totalPages == currentPage){
        disabledSiguiente = true;
    }

    useEffect(() => {
        let element = document.getElementById(properties.PREF_ID_A+properties.FIRST_PAGE);
        if(element){
            element.click();
        }
    }, []);

    /////////////////////////////////////////////////////////////////s

    var maxLeft = (currentPage - Math.floor(properties.WINDOW_SIZE / 2));
    var maxRight = (currentPage + Math.floor(properties.WINDOW_SIZE / 2));

    if (maxLeft < properties.FIRST_PAGE) {
        maxLeft = properties.FIRST_PAGE;
        maxRight = properties.WINDOW_SIZE;
    }

    if (maxRight > totalPages) {
        maxRight = totalPages;
        maxLeft = totalPages - (properties.WINDOW_SIZE - 1);
        
        if (maxLeft < properties.FIRST_PAGE){
        	maxLeft = properties.FIRST_PAGE
        }
    }

    /////////////////////////////////////////////////////////////////

    for (let i = maxLeft; i <= maxRight; i++) {
        pageNumbersList.push(i);
    }

    /////////////////////////////////////////////////////////////////

    const previusPageNumber=()=>{
        let pageNumber = currentPage;

        if(totalPages==0){
            return 0;
        }

        if(currentPage!==properties.FIRST_PAGE){
            pageNumber--;
        }
        return pageNumber;
    }

    /////////////////////////////////////////////////////////////////

    const nextPageNumber=()=>{
        let pageNumber = currentPage;
        let pagTotales = totalPages;

        if(pagTotales==0){
            return 0;
        }

        if(currentPage!==pagTotales){
            pageNumber++;
        }
        return pageNumber;
    }

    /////////////////////////////////////////////////////////////////

    const firstPageButton=()=>{
        if(maxLeft!==properties.FIRST_PAGE){
            return (
                <>
                <li className={claseItem} key={properties.FIRST_PAGE} tabIndex='0' onClick={() => numberOnClick(properties.FIRST_PAGE)}>
                    <a id={properties.PREF_ID_A+properties.FIRST_PAGE} aria-label={properties.ARIA_LABEL_PRIMERA}>
                        {properties.FIRST_PAGE}
                    </a>
                </li>
                <li className={claseDots} key={texts.DOTS+properties.FIRST_PAGE}>{texts.DOTS}</li>
               </>
            );
        }
    }

    /////////////////////////////////////////////////////////////////

    const lastPageButton=()=>{
        if(maxRight!==totalPages){
            return (
                <>
                <li className={claseDots} key={texts.DOTS+totalPages}>{texts.DOTS}</li>
                <li className={claseItem} key={totalPages} tabIndex='0' onClick={() => numberOnClick(totalPages)}>
                    <a id={properties.PREF_ID_A + properties.FIRST_PAGE} aria-label={properties.ARIA_LABEL_ULTIMA + totalPages}>
                        {totalPages}
                    </a>
                </li>
               </>
            );
        }
    }

    const setAriaCurrent = (pageNumber, previousPage) => {      
        let element = document.getElementById(properties.PREF_ID_A + pageNumber);
        let previousElement = document.getElementById(properties.PREF_ID_A + previousPage);
        
        if(element!==null && element!==undefined){
            element.setAttribute(properties.ARIA_CURRENT_CLAVE, true);
            element.setAttribute(properties.ARIA_LABEL_CLAVE, properties.ARIA_LABEL_CURRENT+pageNumber);
        }

        if(previousPage!==null && previousPage!==undefined){
            previousElement.removeAttribute(properties.ARIA_CURRENT_CLAVE)
            if(previousPage===1){           
                previousElement.setAttribute(properties.ARIA_LABEL_CLAVE, properties.ARIA_LABEL_PRIMERA);
            }else if (previousPage===totalPages){
                previousElement.setAttribute(properties.ARIA_LABEL_CLAVE, properties.ARIA_LABEL_ULTIMA + totalPages);
            }else{
                previousElement.setAttribute(properties.ARIA_LABEL_CLAVE, properties.ARIA_LABEL+previousPage);
            }
            
        }
    }

    const numberOnClick = (pageNumber) => {
        if(pageNumber!==0){
            let previousPage = paginate(pageNumber);
            setAriaCurrent(pageNumber, previousPage);
        }
    }

    /////////////////////////////////////////////////////////////////
    return (
        
        <nav role={properties.NAV_ROLE} aria-label={properties.NAV_ARIA_LABEL} className={customStyle().nav}> 
            <ul className={customStyle().ListingPagerlist}>
                <li 
                className={currentPage===properties.FIRST_PAGE ? customStyle().ClassListingPagerListButtonDisable : customStyle().ClassListingPagerListButton}
                key={texts.PREVIUS_BUTTON_LABEL} 
                tabIndex='0'
                aria-disabled={currentPage===properties.FIRST_PAGE ? true : false}
                onClick={() => numberOnClick(previusPageNumber())}>                    
                    <a className={currentPage===properties.FIRST_PAGE ? customStyle().classButtonDissable : customStyle().classButton} aria-label={properties.ARIA_LABEL_ANTERIOR} style={{paddingRight: '10px'}}><ArrowBackIosIcon className={customStyle().classIcons}/>{texts.PREVIUS_BUTTON_LABEL}</a>   
                </li>

                {firstPageButton()}

                {pageNumbersList && pageNumbersList.map(pageNumber => (<li
                className={pageNumber===currentPage ? claseItemActive : claseItem}
                key={pageNumber} 
                tabIndex='0'
                onClick={() => numberOnClick(pageNumber)}>
                    <a className={pageNumber===currentPage ? claseItemA : claseItemD} id={properties.PREF_ID_A+pageNumber} aria-label={properties.ARIA_LABEL + pageNumber}>
                    {pageNumber}
                    </a> 
                </li>))}

                {lastPageButton()}

                <li 
                className={disabledSiguiente ? customStyle().ClassListingPagerListButtonDisable : customStyle().ClassListingPagerListButton}
                key={texts.NEXT_BUTTON_LABEL} 
                tabIndex='0'
                aria-disabled={currentPage===totalPages ? true : false}
                onClick={() => numberOnClick(nextPageNumber())}>
                    <a className={!disabledSiguiente ? customStyle().classButton : customStyle().classButtonDissable}  aria-label={properties.ARIA_LABEL_SIGUIENTE} style={{paddingLeft: '10px'}}>{texts.NEXT_BUTTON_LABEL}<ArrowForwardIosIcon className={customStyle().classIcons}/></a>
                </li>
                {numFilasPersonalizado &&
                    <ThemeProvider disableRipple theme={overInputs}>
                        <a className={customStyle().classText} >{texts.MOSTRAR}</a>
                        <Select
                            id="numFilas"
                            defaultValue={numFilasMostrar}
                            onChange={handleChange}
                            disabled={disabledRegistros}
                            value={numFilasMostrar}
                            anchorEl={anchorEl}
                        >
                            <MenuItem value={properties.NUM_CINCO}>{properties.NUM_CINCO}</MenuItem>
                            <MenuItem value={properties.NUM_DIEZ}>{properties.NUM_DIEZ}</MenuItem>
                            <MenuItem value={properties.NUM_QUINCE}>{properties.NUM_QUINCE}</MenuItem>
                            <MenuItem value={registrosTotales}>{properties.TODOS}</MenuItem>
                        </Select>
                    </ThemeProvider>
                }
            </ul>
        </nav>
    );
};

export default Pager;