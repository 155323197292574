import React from 'react';
/**
 * @function ObtenerTextoEnriquecido
 * Función para poder insertar texto enriquecido (código HTML)
 * @param props Recibe por parámetro los atributos
 * @returns la función devuelve una etiqueta párrafo
 * 
 */
export default function ObtenerTextoEnriquecido(props) {
    return (
        <p
            id={props.id}
            name={props.name}
            className={props.className}
            dangerouslySetInnerHTML={{ __html: props.texto }}//Hace que se permita insertar texto con fomrato HTML
        />
    )
}