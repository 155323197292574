import { getContextFromUrl } from 'config/Config';

//Declaracion de objetos con atributos
export const objAtrib = {
    gridPrincipal: {
        id: 'gridPrincipal'
    },
    gridContenedor: {
        id: 'gridContenedor'
    },
    gridPopUp: {
        id: 'gridPopUp'
    },
    gridCabecera: {
        id: 'cabecera'
    },
    gridTitulo: {
        id: 'titulo'
    },
    gridBuscador: {
        id: 'buscador'
    },
    tituloEncabezado: {
        id: 'tituloPagina'
    },
    tituloPopUp: {
        id: 'tituloPopUp'
    },
    selectSituacion: {
        id: 'situacion',
        nameInput: 'situacion',
        inputLabel: 'Situación',
        noOptionsText: 'Se ha producido un error al cargar los valores',
        placeholder: 'Seleccione una situación',
        isRequired: false
    },
    selectEstado: {
        id: 'estado',
        idInput: 'estado',
        nameInput: 'estado',
        label: 'Estado',
        noOptionsText: '',
        placeholder: 'Seleccione un estado',
        isRequired: false
    },
    selectOrganismo: {
        id: 'organismo',
        idInput: 'organismo',
        nameInput: 'organismo',
        label: 'Unidad organizativa destinataria',
        noOptionsText: '',
        placeholder: 'Seleccione la unidad organizativa destinataria',
        isRequired: true
    },
    inputBuscador: {
        id: 'buscador',
        name: 'buscador',
        type: 'text',
        disabeld: false,
    },
    button: {
        id: 'btnNuevaSolicitud',
        name: 'btnNuevaSolicitud'
    },
    buttonCrear: {
        id: 'btnCrearSolicitud',
        name: 'btnCrearSolicitud'
    },
    buttonCancelar: {
        id: 'btnCancelarSolicitud',
        name: 'btnCancelarSolicitud'
    },
    buscador: {
        searchIconPath: `${getContextFromUrl()}` +  'searchBar/lupa.svg'
    }
}

export const objText = {
    nombreButton: 'NUEVA SOLICITUD',
    buttonCrear: 'INICIAR SOLICITUD',
    buttonCancelar: 'CANCELAR',
    searchIconText: objAtrib.inputBuscador.placeholder,
    buttonResponsiveSelects: 'MOSTRAR FILTROS',
    buttonOcultarResponsiveSelects: 'OCULTAR FILTROS',
    TEXT_NO_DATOS: 'No se han encontrados solicitudes',
    TEXT_ERROR: 'Ha ocurrido un error',
    TEXT_CONFIRMAR_BORRAR: '¿Está seguro de borrar la solicitud?',
    TEXT_AVISO_IMPORTANTE: 'Aviso importante',
    TEXT_SUBSANACION_FUERA_PLAZO: 'Ha finalizado el plazo de subsanación y no es posible realizar la subsanación de la solicitud',
    TEXT_SUBSANACION_FUERA_PLAZO_TITULO: 'Plazo finalizado',
    TEXT_ALEGACION_FUERA_PLAZO: 'Ha finalizado el plazo de alegación y no es posible realizar la alegación de la solicitud',
    TEXT_ALEGACION_FUERA_PLAZO_TITULO: 'Plazo finalizado',
    TEXT_JUSTIFICACION_FUERA_PLAZO: 'Ha finalizado el plazo de justificación y no es posible realizar la justificación de la solicitud',
    TEXT_JUSTIFICACION_FUERA_PLAZO_TITULO: 'Plazo finalizado',
    TEXT_NO_EXISTE_ORGANISMO: 'No hay un organismo asociado a esta convocatoria y no es posible crear una nueva solicitud',
    TEXT_TITLE_NO_EXISTE_ORGANISMO: 'No existe Organismo',
}

export const cabecerasTabla = [
    {
        id: 'numeroExpediente',
        titulo: "NÚMERO EXPEDIENTE",
    },
    {
        id: 'fechaPresentacion',
        titulo: "FECHA PRESENTACIÓN",
    },
    {
        id: 'situacion',
        titulo: "SITUACIÓN",
    },
    {
        id: 'fase',
        titulo: "ESTADO",
    },
    {
        id: 'acciones',
        titulo: "ACCIONES",
    },
];


export const properties = {
    RESOURCE_VALOR_CATALOGO: 'valor',
    RESOURCE_FILTROS_EXPEDIENTES: 'obtenerFiltrosExpedientes',
    RESOURCE_LISTADOS_EXPEDIENTES: 'expedientes',
    RESOURCE_ACCIONES: 'acciones',
    RESOURCE_FORMULARIOS: 'formularios',
    RESOURCE_GESTION_DATABASE: 'gestion-expediente',
    RESOURCE_MOTOR_TRAMITACION: 'formulario-fase-convocatoria',
    RESOURCE_TRANSICIONS: 'transicion',
    RESOURCE_FASE: 'fase',
    RESOURCE_ESTADO_FASE: 'estado',
    RESOURCE_DATO_EXPEDIENTE: 'dato-expediente',
    RESOURCE_INTERESADO: 'interesado',
    RESOURCE_DATOS_CONTACTO: 'datos-contacto',
    RESOURCE_ORGANISMO: 'organismo',
    URL_GET_EXPEDIENTES_INTERESADOS: '/expedientesInteresados',
    URL_CREATE_SUBSANACION: '/crearSubsanacion',
    URL_CREATE_ALEGACION: '/crearAlegacion',
    URL_CREATE_JUSTIFICACION: '/presentarJustificacion',
    RESOURCE_MODULO_ADMINISTRADOR: 'administrador',
    RESOURCE_RELOJ: 'dateTime',
    RESOURCE_LISTADO_ACCIONES: 'acciones',
    URL_GET_FINDBY_CODIGO_CATALOGO: '/findBy-codigoCatalogo/',
    URL_GET_ESTADOS: '/valoresEstado',
    URL_GET_LISTADOS_EXPEDIENTES: '/listadoExpedientes',
    URL_CREATE_NUEVA_SOLICITUD: '/nuevaSolicitud',
    URL_GET_FORM_PATH: '/getFormPath',
    URL_GET_ACCIONES_BY_PROCEDIMIENTOS: '/getAccionesByProcedimientos',
    URL_GET_RECUPERAR_FORMULARIO: '/recuperarFormulario',
    URL_GET_FORMULARIO_X_CONV_X_FASE: '/getFormulario',
    URL_GET_ESTADOFASE_X_CODIGO: '/findByCodigo/',
    URL_GET_FASE_X_CODIGO: '/find-by-codigo/',
    URL_TRANSICIONES: '/transicionesFromFlujoLikeCodigo',
    URL_GET_INTERESADO_BY_IDENTIFICADOR: '/findByIdentificador',
    URL_EXPEDIENTE: '/getExpedienteByNumeroExpediente/',
    URL_ORGANISMOS_BY_CONVOCATORIA : '/find-by-id-convocatoria',
    INIT_PAGE: 1,
    RECORDS_PER_PAGE: 5,
    PATH_NUEVA_SOLICITUD: '/NuevaSolicitud',
    situacion: null,
    estado: null,
    busqueda: null,
    situacionDefault: 'TO',//La situación con código TO (Todas), por defecto devuelve un código de situación con valor null
    situacionSinEstado: 'PP', //Sitaucion que no tiene asociados estados
    ascendente: 'ASC',
    descendente: 'DESC',
    RESOURCE_FORM_GENERICO: 'ov3-formulariogenerico',
    FASE_NUEVA_SOLICITUD: 'INICIO',
    FASE_NUEVA_SOLICITUD_PCT3: 'F_INICIO',
    FASE_SUBSANAR: 'SUBSANA',
    FASE_DESESTIMIENTO: 'DESISTIM',
    FASE_PENDIENTE_DE_SUBSANAR: 'PENDIENTE DE SUBSANAR',
    FASE_PRESENTACION_ALEGACIONES: 'Presentación de alegaciones',
    FASE_PENDIENTE_DE_JUSTIFICAR: 'PENDIENTE DE JUSTIFICAR',
    FASE_INICIADO: 'INICIADO',
    ESTADO_FASE_NUEVA_SOLICITUD: 'E_INICIO',
    ESTADO_FASE_SUBSANAR: 'PENSUB_BAS',
    ESTADO_INICIADO: 'E_INICIO',
    ESTADO_FASE_ALEGAR: 'PEND_ALEG',
    FASE_INICIO_PCT3: 'F_INICIO',
    ESTADO_FASE_INICIO_PCT3: 'E_INICIO',
    ESTADO_FASE_REVISAR_SOLICITUD: 'E_REV_PRES',
    ESTADO_FASE_CORREGIR_SOLICITUD: 'E_COR_REV_SOL',
    ESTADO_FASE_REVISAR_SUBSANACION: 'E_REV_SUB',
    ESTADO_FASE_CORREGIR_SUBSANACION: 'E_COR_REV_SUB',
    F2: 'F2',
    SUB: 'SUBV',
    codigoAplicacionF2: 'PRESENTA_FASE2_AS',
    codigoAplicacionSUB: 'PRESENTA_SUB_AS',
    URL_ACCION_ELIMINAR: '/eliminarSolicitudExpediente',
    URL_ACCION_ELIMINAR_OV3: '/eliminarExpedienteOV3/',
    URL_ACCION_CLONAR: '/clonarSolicitudExpediente/',
    URL_ACCION_CLONAR_OV3: '/clonarExpedienteOV3/',
    IMAGEN_ERROR : `${getContextFromUrl()}` +  'exclamation-triangle-solid.svg',
    RESOURCE_ESTADOS_PCT3: 'estado',
    URL_GET_ESTADOS_PCT3: '/estadosVisiblesOV3/',
    COD_CATALOGO_COMBO_SITUACION:'COM_FASEEX',
    CODIGO_FASE_INICIO: 'F_INICIO',
    CODIGO_FASE_ALEGACION: 'ALEGACIÓN',
    CODIGO_ESTADO_FASE_INICIO: 'E_INICIO',
    CODIGO_ESTADO_FASE_ALEGACION: 'PEND_ALEG',
    CODIGO_TRANSICION: 'INI0001',
    URL_GET_TRANSICION_BY_IDCONV_AND_IDPERFIL: '/getTransicionByIdPerfilAndIdConv',
    RESOURCE_OBTENER_TRANSICION: 'confPerfTransConv',
    RESOURCE_FASE_ESTADO: 'objeto-tramitable',
    URL_FASE_ESTADO: '/findFasesAndEstadosByIdExpediente'
}

export const msgError = {
    severity: 'Error',
    error: 'Ha ocurrido un error al intentar recuperar los datos.',
    formularioNotFound: 'No existe configuración de formulario para la'
}

//Ruta de iconos
export const urlIconos = {
    clonar: `${getContextFromUrl()}` + 'copyRegular.svg',
    borrar: `${getContextFromUrl()}` + 'basuraRegular.svg',
    ver: `${getContextFromUrl()}` + 'acceder_ic.png',
    guardar: `${getContextFromUrl()}` + 'icon.png',
    imprimir: `${getContextFromUrl()}` + 'printer.png',
    modificar: `${getContextFromUrl()}` + 'modificarRegular.svg',
    subsanar: `${getContextFromUrl()}` + 'subsanar.svg',
    historico_documentos: `${getContextFromUrl()}` + 'historicoRegular.svg',
    nueva_entrega: `${getContextFromUrl()}` + 'fileRegular.svg',
    desistir: `${getContextFromUrl()}` + 'desistirRegular.svg',
    default: `${getContextFromUrl()}` + 'acceder_ic.png',
    alta_anexo: `${getContextFromUrl()}` + 'anexo.svg',
    subsa_alta_anexo: `${getContextFromUrl()}` + 'subsanarAltaAnexo.svg',
    mod_baja: `${getContextFromUrl()}` + 'modBaja.svg',
    seleccionar_candidatos: `${getContextFromUrl()}` + 'seleccionar_candidatos.svg',
    renov_rada: `${getContextFromUrl()}` + 'renov_rada.svg',
    oposicion: `${getContextFromUrl()}` + 'oposicion.svg',
    alegaciones1: `${getContextFromUrl()}` + 'alegaciones1.svg',
    alegaciones2: `${getContextFromUrl()}` + 'alegaciones2.svg',
    mejoraSolicitud: `${getContextFromUrl()}` + 'mejoraSolicitud.svg',
    tramiteAudiencia: `${getContextFromUrl()}` + 'tramiteAudiencia.svg',
    subsanacionFacultativa: `${getContextFromUrl()}` + 'subsanacionFacultativa.svg',
    solicitudAmpliacionPlazo: `${getContextFromUrl()}` + 'solicitudAmpliacionPlazo.svg',
    incorporarMedidasProvisionales: `${getContextFromUrl()}` + 'incorporarMedidasProvisionales.svg',
    incorporarSolicitudPeriodoPruebas: `${getContextFromUrl()}` + 'incorporarSolicitudPeriodoPruebas.svg',
    incorporarSolicitudPeriodoExtraordinarioPruebas: `${getContextFromUrl()}` + 'incorporarSolicitudPeriodoExtraordinarioPruebas.svg',
    alegacionInformacionPublica: `${getContextFromUrl()}` + 'alegacionInformacionPublica.svg',
    incorporarSolicitudDesitimientoRenuncia: `${getContextFromUrl()}` + 'incorporarSolicitudDesitimientoRenuncia.svg',
    incorporarSolicitudContinuacionProcedimiento: `${getContextFromUrl()}` + 'incorporarSolicitudContinuacionProcedimiento.svg',
    presentacionAlegaciones: `${getContextFromUrl()}` + 'alegaciones1.svg',
    subsanacionInteresado: `${getContextFromUrl()}` + 'subsanacionInteresado.svg'
}

//Valores a mostrar en la tabla
export const keysTablaGenericaPCT2 = {
    urlImg: 'urlIcono',
    keyTitulo: 'descripcion',
    onClickFunction: 'onClickFunction'
}

export const keysTablaGenericaPCT3 = {
    urlImg: 'urlIcono',
    keyTitulo: 'nombre',
    onClickFunction: 'onClickFunction'
}

//nombreFunciones
export const nombreFuncionesAcciones = {
    clonar: 'OnClickClonar',
    borrar: 'OnClickBorrar',
    mejoraDoc: 'OnClickMejoraDoc',
    historicoDoc: 'OnClickHistoricoDoc',
    subsanar: 'OnClickSubsanar',
    modificar: 'OnClickModificar',
    presentarAlegacion: 'OnClickPresentarAlegacion',
    presentarJustificacion: 'OnClickPresentarJustificacion',
    presentarDesistimiento: 'OnClickPresentarDesistimiento',
    modificarOV3: 'OnClickModificarOV3',
    borrarOV3: 'OnClickBorrarOV3',
    clonarOV3: 'OnClickClonarOV3',
    presentarDesistimientoOV3: 'OnClickPresentarDesistimientoOV3',
    mejoraDocOV3: 'OnClickMejoraDocOV3',
    altaAnexo: 'OnClickAltaAnexo',
    revisarPresentacion: 'OnClickRevisarPresentacion',
    corregirSolicitud: 'OnClickCorregirRevisionSolicitud',
    revisarSubsanacion: 'OnClickRevisarSubsanacion',
    corregirSubsanacion: 'OnClickCorregirRevisionSubsanacion',
    subsanacionAltaAnexo: 'OnClickSubsanacionAltaAnexo',
    modBajaRada: 'OnClickModBajaRADA',
    renovacionRada: 'OnClickRenovacionRADA',
    seleccionarCandidatos: 'OnClickSeleccionarCandidatos',
    oposicionTramitacionSimplificada: 'OnClickOpoTramSimple',
    alegacion1: "OnClickAlegacion1",
    alegacion2: "OnClickAlegacion2",
    tramiteAudiencia: 'OnClickTramiteAudiencia',
    mejoraSolicitud: 'OnClickMejoraSolicitud',
    subsanacionFacultativa: 'OnClickSubsanacionFacultativa',
    solicitudAmpliacionPlazo: 'OnClickSolicitudAmpliacionPlazo',
    incorporarMedidasProvisionales: 'OnClickIncorporarMedidasProvisionales',
    incorporarSolicitudPeriodoPruebas: 'OnClickIncorporarSolicitudPeriodoPruebas',
    incorporarSolicitudPeriodoExtraordinarioPruebas: 'OnClickIncorporarSolicitudPeriodoExtraordinarioPruebas',
    alegacionInformacionPublica: 'OnClickAlegacionInformacionPublica',
    incorporarSolicitudDesitimientoRenuncia: 'OnClickIncorporarSolicitudDesitimientoRenuncia',
    incorporarSolicitudContinuacionProcedimiento: 'OnClickIncorporarSolicitudContinuacionProcedimiento',
    presentacionAlegaciones: 'OnClickPresentacionAlegaciones',
    subsanacionInteresado: 'OnclickSubsanacionInteresado'
}

//Orden a seguir para mostrar las acciones
export const ordenAcciones = [
    'CLONAR',
    'MODIFICAR',
    'BORRAR',
    'SUBSANAR_COMPETITIVA',
    'SUNSANAR_NO_COMPETITIVA',
    'MEJORA_DOCUMENTACION',
    'HISTORICO_DOCUMENTOS',
    'PRESENTAR_ALEGACION',
    'PRESENTAR_JUSTIFICACION',
    'PRESENTAR_DESISTIMIENTO'
];

export const placeholderBuscador = {
    pct2: 'Inserte id Solicitud o id Expediente',
    pct3: 'Inserte número Expediente'
}

export const constantesEstadosFasePCT3 = {
    F_INICIO_OV3: null,
    F_SUBSANACION_OV3: null,
    F_ALEGACIONES_OV3: null,
    EF_INICIADO_OV3: null,
    EF_PENDIENTE_ALEGACION_OV3: null,
    EF_PENDIENTE_SUBSANAR_OV3: null,
    EF_PEND_RL_SUB_OV3: null,
    EF_COR_REV_SUB_OV3: null,
    EF_SUB_PEND_ALTA_ANEXO: null
};

export const texts = {
    searchBarPlaceholder: 'Buscar...',
    searchBarLabel: 'Buscar...',
    searchIconAlt : 'Buscar',
    cleanText: "Borrar",
    idButton: "buscadorSolicitudes"
}

export const paths = {
    searchIconPath : `${getContextFromUrl()}` +  'searchBar/lupa.svg'
}