import React, {useState, useContext, useEffect} from 'react';
import {texts, properties} from './DesistimientoData';
import AppContext from 'AppContext';
import { useHistory } from "react-router-dom";
import {properties as appProperties} from 'AppData';
import {loadState, saveState} from '../../../providers/localStorage';
import useApp from 'AppFunctions';
import {localStorageName} from '../../../providers/localStorageData';
import RestProvider from 'providers/RestProvider';

const useFunctionsDesestimiento = () => {

    const {firmarGenerico, calcularHashBase64} = useApp();

    let documentoInicial = {
        mostrarDropZone: true,
        value: texts.TEXT_DOC,
        mostrarDocAnexo: false,
        tamDoc: "",
    }

    //Hooks
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [tamMaximoSobrepasado, setTamMaximoSobrepasado] = useState(false);
    const [documentos] = useState([]);
    const [documentosDesistir, setDocumentosDesistir] = useState([documentoInicial]);
    const [errorDropRejected, setErrorDropRejected] = useState(false);
    const [tamDropRejected, setTamDropRejected] = useState("");
    const [disabledBotonFirmar, setDisabledBotonFirmar] = useState(true);
    const [pdf, setPdf] = useState(null);  
    const [filePdf, setFilePdf] = useState(null);
    const [mostrarConfirmarFirma, setMostrarConfirmarFirma] = useState(false);
    const [mostrarErrorPresentacion, setMostrarErrorPresentacion] = useState(false);
    const [msgErrorPresentacion, setMsgErrorPresentacion] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [mostrarPopUpDesOk, setMostrarPopUpDesOk] = useState(false);
    const {crudOperation, crudOperationsType} = RestProvider();
    const [isProcessingFirma, setIsProcessingFirma] = useState(false);

    const context = useContext(AppContext);
    const history = useHistory();
    const PREFIJO_OBJETO_FILE_PDF = 'data:application/pdf;base64,';

    let arrayVoluntariosTmp;

    useEffect(() => {
        arrayVoluntariosTmp = documentosDesistir;
    }, [documentosDesistir]) ;

    /**
     * Obtiene el procedimiento en el que se encuentra para actualizar el
     * titulo de la pagina
     */
     const obtenerTituloPagina = () =>{
        context.updateTitlePage(texts.PAGE_TITLE);
    }

    /**
     * Volver a la bandeja de entrada
     */
     const volverABandeja = () =>{
        history.push(properties.URL_BANDEJA)
    }

    /**
     * @function updateFormat Segun el num de pixeles del ancho de la pantalla pone version movil o version ordenador
     */
     const updateFormat = () => {
        if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    //Metodo para mostrar el confirmar
    const mostrarConfirmacion = () => {
        setMostrarConfirmarFirma(true);
    }

    //Metodo para dejar de motrar el componente confirmar
    const dejarDeMostrarConfirmacion = () => {
        setMostrarConfirmarFirma(false);
    }

    /**
     * @function handleOnDrop Gestion de la subida de documentos
     * @param {*} files Fichero subido por el usuario
     * @param {*} rejectedFiles Fichero rechazado subido por el usuario
     */
     const handleOnDrop = (files, rejectedFiles) => {

        var tamFicheroSubido = parseFloat((files[0].file.size / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES));

        if(properties.TAM_MAX_ANEXO < tamFicheroSubido){
            setTamMaximoSobrepasado(true);
        } else {
            let documentoV = {
                fichero: files[0],
                anexo: null,
                descripcionVoluntario: documentosDesistir[0].value,
                firmado: false,
                nombreFichero: {...files[0].file},
                idSolicitud: null, // <---Por definir
                idConvocatoria: null, // <---Por definir
                idProcedimiento: null, // <---Por definir
            }
            documentoV.fichero.data = files[0].data.split('base64,')[1];
            setPdf(documentoV);
            setFilePdf(PREFIJO_OBJETO_FILE_PDF + documentoV.fichero.data);
            documentos.push(documentoV);

            const arrayTemp = [...documentosDesistir];

            arrayTemp[0].mostrarDocAnexo = true;
            arrayTemp[0].tamDoc = tamFicheroSubido;
            arrayTemp[0].mostrarDropZone = false;

            setDocumentosDesistir(arrayTemp);
            setDisabledBotonFirmar(false);

        }
        
    }

    /**
    * @function eliminarAnexo Metodo que elimina el anexo adjuntado
    * @param {*} e Fichero subido por el usuario
    */
    const eliminarAnexoVoluntario = (e) => {

        const arrayTemp = [...documentosDesistir];

        arrayTemp[0].mostrarDropZone = true;
        arrayTemp[0].mostrarDocAnexo = false;
        arrayTemp[0].tamDoc = "";

        setDocumentosDesistir(arrayTemp);
        setDisabledBotonFirmar(true);
        setPdf(null);

        documentos.splice(0, 1);

    };

    /**
     * @function mostrarConfirmarError Metodo que hace saltar el componente mostrar cuando un anexo subido es rechazado
     * @param {*} rejectedFile Fichero rechazado
     * @param {*} tamMaxDoc tamaño maximo permitido para el documento
     */
     const mostrarConfirmarError = (rejectedFile, tamMaxDoc) => {

        setErrorDropRejected(true);
        setTamDropRejected(tamMaxDoc);
    };

    /**
     * @function cerrarErrorDropRejected Metodo que cierra el componente mostrar cuando un anexo subido es rechazado
     */
     const cerrarErrorDropRejected = () => {

        setErrorDropRejected(false);
        
    };

    /**
     * @function accionFirmar Metodo que realiza la accion de firmar
     */
     const accionFirmar = () => {
        dejarDeMostrarConfirmacion();
        let doc = pdf.fichero.data;
        firmarGenerico(doc, presentarDesistimiento, null);
        
    };

    //PRESENTAR DESISTIMIENTO
    const presentarDesistimiento = (signatureBase64, certificate864) => {
        let inputPresentarSolicitud =  montarInputPresentarDesestimiento(signatureBase64);
        setIsLoading(true);
        setIsProcessingFirma(true);
        
        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_PRESENTAR_DESESTIMIENTO_PCT3, {url:'', method: 'post', data:inputPresentarSolicitud}).then(response => {       
            
            saveState(localStorageName.recibi, response.data.recibi, false);
            saveState(localStorageName.numRegistroOrve, response.data.numRegistroOrve, false);
            saveState(localStorageName.numeroExpediente, response.data.numeroExpediente, false);
            saveState(localStorageName.idExpediente, response.data.idExpediente, false);

            setIsLoading(false);
            setMostrarPopUpDesOk(true);
            

        }).catch(function (error) {
            setIsLoading(false);
            if(error.response){
                let msg = error.response.data.messageDescription;     
                setMsgErrorPresentacion(msg + texts.PARTE_2_MSG_ERROR);
            }else{
                setMsgErrorPresentacion(texts.PARTE_1_MSG_ERROR + texts.PARTE_2_MSG_ERROR); 
            }   
            
            setMostrarErrorPresentacion(true);
        })
    }

    const dejarDeMostrarErrorPresentacion = () => {
        setMostrarErrorPresentacion(false);
    }

    const montarInputPresentarDesestimiento = (firma) => {

        let procedimiento = loadState(localStorageName.procedimientoActual, true);
        let convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);
        let userLogin = loadState(localStorageName.infoUsuario, true);
        let solicitud = loadState(localStorageName.solicitudActual, true);
        let fase = loadState(localStorageName.fase, false);
        let estado = loadState(localStorageName.estado, false);
        let enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_DESISTIMIENTO;
        let perfil = loadState(localStorageName.perfilSeleccionado, true);
  
        let inputPresentarSolicitud = {
            enumProcesoEntrega: enumeradoProcesoEntrega,
            idExpediente: solicitud.id === undefined ? solicitud.idExpediente : solicitud.id, 
            numeroExpediente: solicitud.numeroExpediente,
            idConvocatoria:convocatoria.id,
            dniLogin: userLogin.interesadoNif,
            numeroSerieCertificadoLogin: userLogin.datosCertificado ? userLogin.datosCertificado.numeroSerie : null ,
            isloginCertificadoDigital:userLogin.tipoAutenticacion === appProperties.TIPO_AUTENTICACION_CERTIFICADO ? true : false,
            descripcionConvocatoria: convocatoria.descripcion,
            fechaInicioPresentacion:new Date(convocatoria.fechaInicioPresentacion),
            fechaFinPresentacion: new Date(convocatoria.fechaFinPresentacion),
            idProcedimiento: procedimiento.id,
            descripcionProcedimiento: procedimiento.descripcion,
            usuarioOrve: convocatoria.codOrveUsuario,
            passwordOrve:convocatoria.codOrvePassword,
            asuntoOrve: convocatoria.codOrveAsunto,
            destinatarioOrve: convocatoria.codOrveDestinatario,
            listDocumentos: montarDocumentosEntrega(firma), //TODO
            fase: fase,
            estado: estado,
            perfil: perfil
        }
        
        return inputPresentarSolicitud;
    }

    const montarDocumentosEntrega = (firma) => {
        let arrayDocumentoEntrega = new Array(documentos.length);
        let documentoEntrega;

        documentoEntrega = {
            firmaBase64: firma,
            hashDocumentoBase64: calcularHashBase64(pdf),
            documento: documentos[0].fichero.data,
            nombreDocumento: documentos[0].descripcionVoluntario,
            tipoDocumento: "DESISTIMIENTO", 
            descripcionDocumento: documentos[0].descripcionVoluntario,
            firmado: true
           }
                
        arrayDocumentoEntrega[0] = documentoEntrega;
          
        return  arrayDocumentoEntrega;
    }


    return {isPhoneVersion, obtenerTituloPagina, updateFormat, handleOnDrop, documentosDesistir, eliminarAnexoVoluntario, mostrarConfirmarError,
        cerrarErrorDropRejected, errorDropRejected, tamDropRejected, tamMaximoSobrepasado, volverABandeja, disabledBotonFirmar, accionFirmar,
        pdf, mostrarConfirmacion, dejarDeMostrarConfirmacion, mostrarConfirmarFirma, isLoading, mostrarErrorPresentacion,
        msgErrorPresentacion, dejarDeMostrarErrorPresentacion, mostrarPopUpDesOk, isProcessingFirma, filePdf};

}
export default useFunctionsDesestimiento;