import React from 'react';
import useStyles from './altaEntidadColaboradoraStyle';
import { texts, objComponentFormio } from './altaEntidadColaboradoraData';
import { properties as AppData } from 'AppData';
import ComponenteGenericoFormio from 'components/commons/componenteGenericoFormio/componenteGenericoFormio';
import { localStorageName } from 'providers/localStorageData';
import { loadState } from 'providers/localStorage';
import { Grid, Backdrop, CircularProgress } from '@material-ui/core';
import { Snackbar } from "material";
import { Alert } from "@material-ui/lab";
import '../../../BootstrapWrapper.scss';
import altaEntidadColaboradoraStyle from './altaEntidadColaboradoraStyle.css';
import altaEntidadColaboradoraFunctions from './altaEntidadColaboradoraFunctions';

export default function AltaEntidadColaboradora() {

    const { style } = useStyles();
    const customStyle = style();

    const { handleOnSubmit, handleCustomEvents, isLoading, alerts, message, setMessage } = altaEntidadColaboradoraFunctions();

    // Se inserta el nombre del título de la página
    document.title = AppData.TITULO_PAGINA + texts.PAGE_TITLE;

    // Se obtiene el nombre de la entidad que se va a dar de alta
    let entidadObtenida = loadState(localStorageName.entidadSeleccionada, false);

    return (
        <main>
            {/** El nombre de esta clase, tiene que coincidir con el nombre de la clase que se encuentra en el fichero BootstrapWrapper.scss */}
            <Grid className='componenteGenericoFormio'>
                <Grid className={customStyle.gridTitulo}>
                    <h1 className={customStyle.tituloPagina}>{texts.PAGE_TITLE}</h1>
                    <span className={customStyle.spanEntidad}>{entidadObtenida}</span>
                </Grid>
                <Grid className={customStyle.backgroundRectangle}>
                    <ComponenteGenericoFormio {...objComponentFormio} onSubmit={handleOnSubmit} onCustomEvent={handleCustomEvents} />
                </Grid>
            </Grid>
            <Backdrop className={customStyle.classBackdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {alerts.map(m => (
                <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
                    <Alert
                        severity={message.severity}
                        variant="filled"
                    >
                        {message.msg}
                    </Alert>
                </Snackbar>
            ))}
        </main>
    );
}