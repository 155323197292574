//Componente importado de material ui
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import { estilosTextosPrincipales, colores, tipografia } from '../../../AppStyle';
/**
 * @constant theme
 * Contiene las clases de material que se aplican al componenteRadioButton
 * Sobreescribe las clases de material con el estilo que aquí se aplica
 */

export function getTheme (condition) {
    const theme = createMuiTheme({
        overrides: {
            MuiFormHelperText: {
                root: {
                    color: colores.estado.alerta,
                },
            },
            MuiFormControl: {
                root: {
                    display: 'ruby',
                },
            },
            MuiFormControlLabel: {
                root: {
                    display: 'contents',
                },
                label: {
                    fontFamily: tipografia.medium,
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    maxWidth: '100px',
                    overflowX: 'hidden'
                },
            },
            MuiFormGroup: {
                root: {
                    ...(condition) ? { display: 'flow-root' } : { display: 'inline' },
                    paddingTop: '0px',
                    paddingBottom: '13px',
                    paddingLeft: '12px',
                    opacity: '0.8',
                    color: colores.principales.hover,
                    fontFamily: tipografia.regular,
                    height: 'auto',
                    width: '328px',                    
                },
            },
            MuiIconButton: {
                label: {
                    boxSizing: 'border-box',
                    color: colores.principales.principal,
                    border: '12px'
                },
            },
        },
    });
    return theme;
}

export default makeStyles({
    classLabelResponsive: {
        boxSizing: 'border-box',
        height: '116px',
        width: '120px',
        border: `1px solid ${colores.principales.hover}`,
        borderRadius: '4px', 
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center', 
        margin: '0px 20px 20px 0px',
        '&:hover': {
            outline: `2px solid ${colores.principales.principal}`, 
        },
        '&$focused': {
            outline: `2px solid ${colores.principales.principal}`, 
        },
    },
    classLabelActive: {
        boxSizing: 'border-box',
        height: '116px',
        width: '120px',
        border: `1px solid ${colores.principales.hover}`,
        borderRadius: '4px', 
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center', 
        marginRight: '20px',
        '&:hover': {
            outline: `2px solid ${colores.principales.principal}`, 
        },
        '&$focused': {
            outline: `2px solid ${colores.principales.principal}`, 
        },
    },
    classLabelDisactive: {
        boxSizing: 'border-box',
        height: '116px',
        width: '120px',
        border: `1px solid ${colores.principales.hover}`,
        borderRadius: '4px', 
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center', 
        marginRight: '20px',
        '&:hover': {
            outline: `2px solid ${colores.principales.principal}`, 
        },
        '&$focused': {
            outline: `2px solid ${colores.principales.principal}`, 
        },
    },
})