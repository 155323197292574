import { getContextFromUrl } from 'config/Config';

export const texts = {
    PAGE_TITLE: 'Nueva solicitud de ',
    PAGE_TITLE_EDICION: 'Edición de solicitud de ',
    PAGE_TITLE_SUBSANAR: 'Subsanación de solicitud de ',
    PAGE_TITLE_ALEGAR: 'Alegación de solicitud de ',
    PAGE_TITLE_SUBSANACION: 'Nueva subsanación de ',
    PAGE_TITLE_JUSTIFICACION: 'Nueva justificación de ',
    PAGE_TITLE_ALEGACION: 'Nueva alegación de ',
    PAGE_TITLE_MOD: 'Modificación de solicitud de ',
    PAGE_TITLE_SUBSANACION_MOD: 'Modificación de subsanación de ',
    PAGE_TITLE_JUSTIFICACION_MOD: 'Modificación de justificación de ',
    PAGE_TITLE_ALEGACION_MOD: 'Modificación de alegación de ',
    BUTTON_PREV: 'ATRÁS',
    BUTTON_NEXT: 'SIGUIENTE',
    BUTTON_SEND: 'ENVIAR',
    TITLE_ERROR_GENERAR_PDF: 'Error al generar el documento PDF',
    MSG_ERROR_GENERAR_PDF: 'Se ha producido un error al generar el documento PDF que recoge los valores que ha insertado el el formulario.'

}

export const properties = {
    ID_PAGE_TITLE: 'tituloPagina',
    FASE_NUEVA_SOLICITUD: 'INICIO',
    FASE_PENDIENTE_DE_SUBSANAR: 'PENDIENTE DE SUBSANAR',
    FASE_PRESENTACION_ALEGACIONES: 'Presentación de alegaciones',
    FASE_PENDIENTE_JUSTIFICACION: 'PENDIENTE DE JUSTIFICAR',
    IMAGEN_ERROR : `${getContextFromUrl()}` +  'exclamation-triangle-solid.svg'
}

export const objAttrib = {
    gridButtons: {
        id: 'gridButtons',
        name: 'gridButtons'
    },
    buttonPrev: {
        id: 'btn-prev',
        name: 'btn-prev',
    },
    buttonNext: {
        id: 'btn-next',
        name: 'btn-next',
    }
}

export const constantesEstadosFasePCT3 = {
    F_INICIO_OV3: null,
    F_SUBSANACION_OV3: null,
    F_ALEGACIONES_OV3: null,
    F_JUSTIFICACION_OV3: null,
};
