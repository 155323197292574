import { useRandomVar } from 'providers/RandomVarProvider';
import RestProvider from 'providers/RestProvider';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const HeaderLinks = ({ type, onScriptsLoaded }) => {
  const { randomVar } = useRandomVar();
  const { obtenerProperties } = RestProvider();
  const [cssUrl, setCssUrl] = useState('');
  const [jsUrl, setJsUrl] = useState('');

  const addProperties = async () => {
    const propertiesList = [
      'url.script.ayto.css', 'url.script.ayto.js'
    ];

    try {
      let properties = await obtenerProperties(propertiesList);
      // Actualiza el estado
      setJsUrl(properties['url.script.ayto.js']);
      setCssUrl(properties['url.script.ayto.css']);
    } catch (error) {
      console.error('error obteniendo las properties', error);
    }
  };

  useEffect(() => {
    addProperties();
  }, []);

  const links = {
    sede: {
      css: `${cssUrl}?${randomVar}`,
      js: `${jsUrl}?${randomVar}`
    }
  };

  useEffect(() => {
    const loadScripts = async () => {
      const scriptElements = document.querySelectorAll(`script[src*="sede.js"]`);

      // Si ya existen los scripts, esperar su carga
      if (scriptElements.length > 0) {
        await Promise.all(
          Array.from(scriptElements).map(script =>
            new Promise(resolve => script.onload = resolve)
          )
        );
      }

      // Esperar a que window.pctmPageLoad esté disponible
      const checkFunctionExists = async () => {
        return new Promise(resolve => {
          const interval = setInterval(() => {
            if (typeof window.pctmPageLoad === "function") {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      };

      await checkFunctionExists();
      onScriptsLoaded();
    };

    loadScripts();
  }, [onScriptsLoaded]);

  return (
    <Helmet>
      <link rel="stylesheet" href={links[type].css} />
      <script src={links[type].js} async></script>
    </Helmet>
  );
};

export default HeaderLinks;