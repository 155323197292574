//Componente importado de material ui
import { makeStyles } from '@material-ui/core/styles';
//Estilo genérico importado
import { estilosTextosPrincipales, colores, tipografia, backgroundRectangle, buttonSecundarySmall,buttonPrimarySmall } from '../../../AppStyle';

export const useStyles = () => {
    const style = makeStyles({
        backgroundRectangle: {
            ...backgroundRectangle,
            marginBottom: '2%',
            padding: '1% 0% 0% 2%'
        },
        tituloPagina: {
            ...estilosTextosPrincipales.titulosPagina,
        },
        hclassText2: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            fontSize: '15px',
            paddingTop: '2%'
        },
        gridTitulo: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '2%',
            marginLeft: '5%',
            width: '90%'
        },
        spanEntidad: {
            marginLeft: '25px',
            color: colores.escalaGrises.sesenta,
            fontFamily: tipografia.bold,
            fontSize: '13px',
            height: 'inherit',
            display: 'flex',
            alignItems: 'center'
        },
        buttonPrimary: {
            ...buttonPrimarySmall,
            marginLeft: '2%'
        },
        buttonSecundary: {
            ...buttonSecundarySmall,
            marginLeft: '2%'
        },
        gridButtons: {
            display: 'inline-flex',
            margin: '2%'
        },
        classBackdrop: {
            zIndex: 1,
            color: colores.principales.blanco,
        }
    });
    return { style }
}
