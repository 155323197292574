/************************************************************************************
 * Fichero de configuración de los diferentes apartados del formulario del componente
 ***********************************************************************************/
import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [],
                ignore: false
            },
            {
                key: "data",
                components: [
                    {
                        type: "textfield",
                        input: true,
                        label: "Inserte aquí el maxLength",
                        weight: 1,
                        key: "maxLength"
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Inserte aquí el texto que aparecerá cuando no existen opciones dispobles en el selector",
                        weight: 2,
                        key: "noOptionsText"
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Inserte aquí el controlador del endPoint",
                        weight: 3,
                        key: "controladorEndPoint"
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Inserte aquí la url del endPoint",
                        weight: 4,
                        key: "urlEndPoint"
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Inserte aquí el valor que devolverá del selector",
                        weight: 5,
                        key: "optionSelect"
                    },
                ],
                ignore: false
            },
            {
                key: "validation",
                components: [],
                ignore: false
            },
            {
                key: "api",
                components: [],
                igonere: false
            },
            {
                key: "conditional",
                components: [],
                ignore: true
            },
            {
                key: "logic",
                components: [],
                ignore: true
            },
            {
                key: "layout",
                components: [],
                ignore: true
            }
        ],
        ...extend
    );
};