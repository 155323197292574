import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { colores, estilosTextosPrincipales } from "AppStyle";

export default makeStyles({
    classGrid: {
        textAlign: 'left',
        marginTop: '15px',
        marginBottom: '19px',
        marginRight: '32px',
        width: 'auto',
        display: 'block'
    },
    classInputLabel: {
        ...estilosTextosPrincipales.body2,
        color: colores.escalaGrises.ochenta,
        fontSize: '23px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: ' 17px',
        marginBottom: '5px',
        position: 'relative',
        '&.Mui-focused': {
            color: colores.escalaGrises.ochenta,
        },
    },
    classSelect: {
        ...estilosTextosPrincipales.body3,
        boxSizing: 'border-box',
        height: '48px',
        width: '328px',
        letterSpacing: '0px',
        borderRadius: '4px',
        paddingLeft: '12px',
        color: colores.escalaGrises.cien,
        outline: `1px solid ${colores.principales.principal}`,
        '&:hover': {
            outline: `3px solid ${colores.principales.principal}`     },
        '&:focused': {
            outline: `3px solid ${colores.principales.principal}`
        },
    },
    classMenuItem: {
        boxSizing: 'border-box',
        height: '48px',
        width: '328px',
        paddingTop: '11px',
        paddingBottom: '13px',
        paddingLeft: '12px',
        color: colores.escalaGrises.ochenta,
        ...estilosTextosPrincipales.body1,
        fontSize: '16px'
    }
});

export const overrideStyle = createMuiTheme({
    overrides: {
        MuiMenuItem: {
            root: {
                ...estilosTextosPrincipales.body1,
                fontSize: '17px'
            }
        },
        MuiListItem: {
            root: {
                color: colores.escalaGrises.ochenta,
                '&:hover': {
                    backgroundColor: `${colores.principales.selectHover} !important`,
                },
                '&$selected': {
                    backgroundColor: colores.principales.apoyo
                },
                
            }
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'transparent'
                }
            },
            icon: {
                color: colores.escalaGrises.cien,
                marginRight: '5px',
                '&:hover': {
                    color: 'rgba(0, 0, 0, 0.04)'
                }
            }
        },
        MuiPaper: {
            root: {
                width: 'auto%',
                height: 'fit-content'
            },
            elevation8: {
                boxShadow: `0px 5px 5px -3px ${colores.sombras.sombraOscura1},0px 1px 1px 1px ${colores.sombras.sombraOscura3},0px 1px 1px 1px ${colores.sombras.sombraOscura4}`
            }
        },
        MuiPopover: {
            paper: {
                marginTop: '12px',
                marginLeft: '-6px',
                width: '328px'
            }
        }
    }
});