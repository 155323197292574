import { getContextFromUrl } from 'config/Config';

export const properties = {
    ID_PAGE_TITLE: 'tituloPagina',
    URL_BANDEJA: '/BandejaSolicitudes',
    RESOURCE: 'acciones',
    RESOURCE_PCT3: 'historicoDocumentos',
    RESOURCE_DOC_PCT3: 'documento',
    URL_HISTORICO_DOCUMENTOS: '/historicoDocumentos',
    URL_GET_FICHERO: '/fileArco/',
    codigoAplicacionF2: 'PRESENTA_FASE2_AS',
    codigoAplicacionSUB: 'PRESENTA_SUB_AS',
    F2: 'F2',
    SUB: 'SUBV'
}

export const texts = {
    PAGE_TITLE: 'Histórico de documentos',
    TEXT_PRINCIPAL: 'Histórico de archivos',
    TEXT_BOTON: 'VOLVER A LA BANDEJA DE ENTRADA',
    TEXT_HISTORICO_SOLICTUD: 'Histórico de solicitud',
    TEXT_HISTORICO_RECIBI: 'Histórico de recibí',
    TEXT_HISTORICO_ADJUNTOS: 'Histórico de adjuntos',
    TEXT_NO_DATOS_SOLICITUD: 'No existen históricos de solicitudes',
    TEXT_NO_DATOS_RECIBI: 'No existen históricos de recibís',
    TEXT_NO_DATOS_ADJUNTO: 'No existen históricos adjuntos',  
    TEXT_LIST_DOC: 'LISTADO DE DOCUMENTOS',
    MSG_ERROR: 'Se ha producido un error al recuperar los datos',
    SEVERITY: 'error'
}

export const tableHeader = [
    {
        titulo: 'Nº EXPEDIENTE',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'numExpediente',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'FECHA PRESENTACIÓN',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'fechaAlta',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'ESTADO',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'estado',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'VER',
        esBotonera: true,
        funcionOrdenacion: null,
        id: 'columnAccionesTabla1',
        estaOrdenado: null,
        ordenadoAsc: null
    }
];

export const table2Header = [
    {
        titulo: 'NOMBRE DEL FICHERO',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'nombreFichero',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'FECHA GUARDADO',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'fechaGuardado',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'VER',
        esBotonera: true,
        funcionOrdenacion: null,
        id: 'columnAccionesTabla2',
        estaOrdenado: null,
        ordenadoAsc: null
    }
];

export const table3Header = [
    {
        titulo: 'FECHA GUARDADO',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'fechaGuardadoAdjunto',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'NOMBRE ADJUNTO',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'nombreFichero',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'ESTADO EN EL QUE SE REALIZÓ EL APORTE',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'estado',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'VER',
        esBotonera: true,
        funcionOrdenacion: null,
        id: 'columnAccionesTabla3',
        estaOrdenado: null,
        ordenadoAsc: null
    }
];

export const keysTablaGenerica = {
    urlImg: 'img',
    keyTitulo: 'titulo',
    onClickFunction: 'funcion'
}

export const acciones = [
    {
        img: `${getContextFromUrl()}` + 'eye-regular.svg',
        funcion: null,
        titulo: "Ver expediente",
    }
];