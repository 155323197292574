/************************************************************************************
 * Fichero de configuración de los diferentes apartados del formulario del componente
 ***********************************************************************************/
import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    /***Gestión de campos del apartado display del componente***/
                    //{
                    /**Aquí se podrán habilitar o deshabilitar los campos que se deseen**/
                    //    key: "placeholder", //Identificador del campo
                    //    ignore: false //Visibilidad del campo
                    // },
                    //{
                    /**Aquí se podrán añadir nuevos campos**/
                    //    type: "textfield", //Tipo de campo
                    //   input: true,
                    //   label: "Campo añadido", //Etiqueta del campo
                    //   weight: 1, //Posición en la que aparecerá
                    //   key: "addedField", //Etiquetado del campo a través del cual se podrá referenciar
                    // }
                ],
                ignore: false
            },
            {
                key: "data",
                components: [
                    {
                        type: "textfield",
                        input: true,
                        label: "Inserte aquí el controlador del endPoint",
                        weight: 1,
                        key: "controladorEndPoint"
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Inserte aquí la url del endPoint",
                        weight: 2,
                        key: "urlEndPoint"
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Inserte aquí el valor que devolverá del selector",
                        weight: 3,
                        key: "valorSelect"
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Inserte aquí el valor a mostrar en las opciones del selector",
                        weight: 4,
                        key: "valorAmostrarSelect"
                    }
                ],
                ignore: false //Visibilidad del apartado
            },
            {
                key: "validation",
                components: [],
                ignore: true
            },
            {
                key: "api",
                components: [],
                igonere: false
            },
            {
                key: "conditional",
                components: [],
                ignore: true
            },
            {
                key: "logic",
                components: [],
                ignore: true
            },
            {
                key: "layout",
                components: [],
                ignore: true
            }
        ],
        ...extend
    );
};