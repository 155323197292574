export default {
    MS_ARQUETIPO: {path: '/arquetipo', resources: ['arquetipo', 'config-details']},
    MS_MOAD: {path: '/moad', resources: ['configuraciones', 'perfiles', 'procedimientos', 'validacion', 'logInBandeja']},
    MS_CONFIGURACION_COMUN: {path: '/footer', resources: ['dateTime','footerColumn', 'footer', 'footerRow']},
    MS_TREWA: {path: '/trewa', resources: ['manager', 'operacionesInteresado']},
    MS_CLAVE: {path: '/clave', resources: ['clave','SpringSAMLIntegrationExample']}, 
    MS_AFIRMA: {path: '/afirma', resources: ['afirma']},
    MS_GESTION_EXPEDIENTES: {path: '/expedientes', resources: ['obtenerFiltrosExpedientes', 'expedientes', 'formularios', 'anexos', 'acciones', 'entregas', 'pdfNormalizado', 'solicitudes']},
    MS_CATALOGO: {path: '/catalogo', resources: ['catalogo','valor','familia','ict']},
    MS_MODULO_ADMINISTRACION: {path: '/modadmin', resources: ['administrador', 'verAcciones']},
    MS_FORMIO: {path: '/formio', resources: ['form']},
    MS_PCT3_MOTOR_TRAMITACION: {path: '/api', resources: ['procedimiento', 'tipo-procedimiento', 'convocatoria', 'pais', 'provincia', 'municipio', 'estado', 'confAnexoGlobal', 'fase', 'formulario-fase-convocatoria', 'transicion', 'confAnexo', 'organismo', 'pdf-normalizado', 'avisosov3', 'fasesEstadosXconvOV3', 'confPerfTransConv', 'motivoSubsanacionConvocatoria','accion']},
    MS_PCT3_TRAMITADOR: {path: '/tramitador', resources: ['tipoVia', 'interesado', 'tipo-interesado', 'formulario-expediente', 'dato-expediente', 'datos-contacto', 'presentacion-entrega', 'objeto-tramitable','dato-accion']},
    MS_PCT3_GESTION_DATABASE: {path: '/gestion-database', resources: ['gestion-expediente']},
    MS_PCT3_MOTOR_DOCUMENTAL: {path: '/documental', resources: ['historicoDocumentos', 'documento', 'tipo-documento', 'documento-hcv', 'portafirmas']},
    MS_SMS_API_PROXY: {path: '/sms', resources: ['p3s']},
    MS_PCT3_GESTION_USUARIOS: {path: '/gestion-usuarios', resources: ['login-usuario', 'login']},
    MS_SICA_API_PROXY: {path: '/sica', resources: ['sica']},
}