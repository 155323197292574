import React, { useEffect } from 'react';
import { Grid, Backdrop, CircularProgress } from '@material-ui/core';
import useStyles  from  './AporteMejoraDocumentacionStyle';
import { texts, properties, formato } from './AporteMejoraDocumentacionData';
import useFunctions from './AporteMejoraDocumentacionFunction';
import SubirArchivoVoluntario from 'components/commons/subirArchivoVoluntario/SubirArchivoVoluntario';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import { compProperties, properties as appProperties } from './../../../AppData';
import { loadState } from 'providers/localStorage';
import { localStorageName } from 'providers/localStorageData';

const documentoVoluntario = [{
    mostrarDropZone: false,
    value: "",
    mostrarDocAnexo: false,
    tamDoc: "",
    pdf : "",
}];

export default function AporteMejoraDocumentacion() {

    const { obtenerTituloPagina, volverABandeja, updateFormat, isPhoneVersion, documentosVoluntarios, setDocumentosVoluntarios, handleOnDrop, handleChange, 
        eliminarAnexoVoluntario, mostrarConfirmarErrorFichero, navegarBandeja, mostrarMensaje, textoError, mostrarError, disabledBotonFirmar, firmar, mostrarConfirmacionFirma,
        cerrarConfirmacionFirma, abrirConfirmacionFirma, isLoading, cerrarError, obtenerAccion} = useFunctions();


    const { style } = useStyles(isPhoneVersion);
    const customStyle = style(); 
    
    //Variable documentoVoluntario vacio para evitar la precarga en la misma sesion.
    const documentoVoluntario = [{
        mostrarDropZone: false,
        value: "",
        mostrarDocAnexo: false,
        tamDoc: "",
        pdf : "",
    }];

    const solicitudActual = loadState(localStorageName.solicitudActual, true);
    let accion = obtenerAccion();

    useEffect(() => {
        obtenerTituloPagina();
        updateFormat();
        setDocumentosVoluntarios(documentoVoluntario);
    }, []);

    window.addEventListener('resize', updateFormat);

    return(
        <main>
            <Grid>
                <Grid className={customStyle.gridTitulo} >
                    <h1 className={customStyle.tituloPagina}>{accion.nombreAccion}</h1>
                    <span className={customStyle.idExpediente}>{solicitudActual.numeroExpediente}</span>
                </Grid>
                <Grid className={customStyle.backgroundRectangle}>      
                    <h3 className={customStyle.hclassText2}>
                        {texts.TEXTO_TAMAÑO_MAX_ANEXO}{properties.TAM_MAX_ANEXO}{texts.TEXTO_MB}
                    </h3>
                    <Grid className={customStyle.gridSubirArchivo}> 
                    {//Componente Generación de formularios
                        documentosVoluntarios.map(anexoV => (
                            <SubirArchivoVoluntario img={properties.PATH_IMG_PDF} tamMaximoArchivo={properties.TAM_MAX_ANEXO} formato={formato} handleOnDrop={(files, rejectedFiles) => handleOnDrop(files, rejectedFiles, null)}
                            onChange={handleChange} value={anexoV.value} mostrarDrop={anexoV.mostrarDropZone} mostrarDocAnexo={anexoV.mostrarDocAnexo} tamDoc={anexoV.tamDoc}
                            bottonFunction={eliminarAnexoVoluntario} handleRejection={(rejectedFiles) => mostrarConfirmarErrorFichero(rejectedFiles, properties.TAM_MAX_ANEXO)}
                            pdf={anexoV.pdf} poderVisualizar={true}/>
                    ))}
                    </Grid>
                </Grid>
                {/*Confirmar error*/}
                <Confirmacion 
                    accionConfirmacion={cerrarError}
                    titulo={texts.TITULO_ERROR} 
                    mostrar={mostrarError} 
                    isPhoneVersion={isPhoneVersion} 
                    mensaje={textoError}
                    botonera={compProperties.SOLO_CERRAR}
                    quitarImg={false}
                    tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                    isPopUpPdf={false}
                    textBotonConfirmarPersonalizado={false}
                    isImagenPersonalizada={true}
                    centrarContenido={true}
                    imagen={properties.IMAGEN_ERROR}
                    isTextoEnriquecido={true}
                    />
                {/*Confirmar firma*/}
                 <Confirmacion 
                    accionConfirmacion={firmar}
                    accionDenegacion={compProperties.CONFIR_SIN_ACCION}
                    handleChange={cerrarConfirmacionFirma}
                    titulo={accion.nombreAccion} 
                    mostrar={mostrarConfirmacionFirma} 
                    isPhoneVersion={isPhoneVersion}
                    mensaje={texts.MSG_CONFIRMACION_FIRMA}
                    botonera={compProperties.CONFIR_CANCELAR_ACEPTAR}
                    quitarImg={false}
                    tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                    isPopUpPdf={false}
                    textBotonConfirmarPersonalizado={false}
                    centrarContenido={true}    
                    isImagenPersonalizada={false}   
                    isTextoEnriquecido={true}           
                    />
                {/**Confirmar aporte ok */}
                <Confirmacion 
                    accionConfirmacion={navegarBandeja}
                    titulo={texts.TITULO_APORTE_OK} 
                    mostrar={mostrarMensaje} 
                    isPhoneVersion={isPhoneVersion} 
                    mensaje={texts.MSG_APORTE_OK}
                    botonera={compProperties.CONFIR_SOLO_ACEPTAR}
                    quitarImg={false}
                    tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                    isPopUpPdf={false}
                    textBotonConfirmarPersonalizado={false}
                    isImagenPersonalizada={true}
                    centrarContenido={true}
                    imagen={properties.IMAGEN_APORTE_OK}
                    isTextoEnriquecido={true}
                    />
                <Grid container className={customStyle.gridBoton} justify='flex-end' spacing={1}>
                    <button className={customStyle.botonVolver} onClick={volverABandeja}>{texts.TEXT_BOTON}</button>
                    <button className={customStyle.botonFirma} disabled={disabledBotonFirmar} onClick={abrirConfirmacionFirma}>{texts.TEXT_FIRMAR}</button>
                </Grid>
            </Grid>
            <Backdrop className={customStyle.classBackdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </main>    
    );
}
