import {textsAytm as appTexts } from '../../../AppData';
import { getContextFromUrl } from 'config/Config';

export const texts = {
    PAGE_TITLE: 'Aportar documentación',
    TEXT_DOCUMENTACION_A_APORTAR: 'Documentación a aportar',
    TEXT_BOTON: 'VOLVER A LA BANDEJA DE ENTRADA',
    TEXT_FIRMAR: 'INICIAR FIRMAR',
    TITULO_ERROR: "Se ha producido un error",
    TEXTO_ERROR_DROP_REJECTED1: "Tenga en cuenta que el documento deber ser un PDF con un tamaño máximo de ",
    TEXTO_ERROR_DROP_REJECTED2: " MB. Por favor, seleccione otro documento que cumpla con las condiciones indicadas.",
    TEXTO_TAMAÑO_MAX_ANEXO: 'Tamaño maximo por anexo: ',
    TEXTO_MB:'MB',
    TITULO_CONFIRMACION_FIRMA: "Aporte de documentación",
    MSG_CONFIRMACION_FIRMA: "¿Desea iniciar la presentación?",
    TITULO_APORTE_OK: "Documentación aportada",
    MSG_APORTE_OK: "Se ha presentado la documentación aportada de forma correcta",
    PARTE_1_MSG_ERROR: "Se ha producido un error técnico.",
    PARTE_2_MSG_ERROR: "Puede volver a intentar realizar el proceso de firma y, si el error persiste, contacte con el servicio de Información y Sugerencias del Servicio de Información y Atención Ciudadana que puede consultar <a href="+appTexts.URL_CONTACTO+">aquí</a>.",
}

export const properties = {
    URL_BANDEJA: '/BandejaSolicitudes',
    PATH_IMG_PDF: `${getContextFromUrl()}` + 'pdfGris.png',
    TAM_MAX_ANEXO: '1',
    UN_MEGABYTE_EN_BYTE : 1000000,
    NUMERO_DECIMALES: 2,
    PROCESO_ENTREGA_APORTE: "APORTE_DOCUMENTACION",
    TIPO_DOC_MEJORA: "MEJORA_DOCUMENTACION",
    RESOURCE_PRESENTAR_SOLICITUD: "entregas",
    URL_PRESENTAR_SOLICITUD: '/presentacionEntrega',
    IMAGEN_ERROR : `${getContextFromUrl()}` + 'exclamation-triangle-solid.svg',
    IMAGEN_APORTE_OK: `${getContextFromUrl()}` + 'archivoOk.png',
    IMAGEN_CONFIRMAR: `${getContextFromUrl()}` + 'oval.png',
    PROCESO_ENTREGA_MEJORA_DOC: 'APORTE_DOCUMENTACION',
    RESOURCE_PRESENTAR_MEJORA_DOC: 'presentacion-entrega',
    RESOURCE_TRANSICIONS: 'transicion',
    URL_TRANSICIONES: '/obtenerTransicion',
    URL_ACTUALIZAR_EXP: '/updateOV3',
    RESOURCE_OB_TRAM: 'objeto-tramitable',
    RESOURCE_DATO_EXPEDIENTE: 'dato-expediente',
    URL_GET_ESTADOFASE_X_CODIGO: '/findByCodigo/',
    URL_GET_FASE_X_CODIGO: '/find-by-codigo/',
    RESOURCE_FASE: 'fase',
    RESOURCE_ESTADO_FASE: 'estado',
}

export const msgError = {
    severity: 'Error',
    error: 'Ha ocurrido un error al intentar recuperar los datos.',
    formularioNotFound: 'No existe configuración de formulario para la'
}

export const formato = ['.pdf'];