import { useState, useContext } from "react";
import { loadState, saveState } from '../../../providers/localStorage';
import { localStorageName } from '../../../providers/localStorageData';
import { properties as propertiesAppData } from '../../../AppData';
import { useHistory } from "react-router-dom";
import AppContext from 'AppContext';
import { useParams } from "react-router-dom";
import { properties as appProperties } from '../../../AppData';
import AuthProvider from 'providers/authProvider';
import RestProvider from 'providers/RestProvider';
import useApp from 'AppFunctions';
import { objAttributesSeleccionConvocatoriaPerfil, objTextSeleccionConvocatoriaPerfil, properties } from './SeleccionConvocatoriaPerfilData';

/**
 * @constant useFunctions
 * Contiene las funciones unificadas de la PCT2 y PCT3 que serán devueltas al formulario para que sean tratadas.
 */
const useFunctions = () => {
    /***********************************
     * Variables y constantes
     ***********************************/
    const estadoVisibleOV = 'activo público'
    let procedimientoActual = loadState(localStorageName.procedimientoActual, true);

    const history = useHistory();
    const context = useContext(AppContext);
    const { idProcedimiento } = useParams();
    const objFields = {
        seleccionConvocatoria: '',
        perfil: '',
    };

    const [values, setValues] = useState(objFields);
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [mostrarMensajeBienvenida, setMostrarMensajeBienvenida] = useState(false);
    const [expandedMensajeBienvenida, setExpandedMensajeBienvenida] = useState('');
    const [procActual, setProcActual] = useState(procedimientoActual);
    const [mostrarVentana, setMostrarVentana] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [pintarPantalla, setPintarPantalla] = useState(false);
    const [convocatorias, setConvocatorias] = useState([]);

    // Inicialización necesaria para el componente Alert de mensajes y errores
    const [message, setMessage] = useState({ msg: "", severity: "" });
    const [alerts, setAlerts] = useState([]);
    let _ID = 0;

    const addAlert = (msg) => {
        setAlerts([...alerts, { id: _ID++, msg }]);
    }

    // Gestiona los mensajes de por pantalla para errores, info, etc...
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    const handleLoanding = (booleano) => {
        setIsLoading(booleano);
    }

    const { crudOperation, crudOperationsType } = RestProvider();
    const { firmar, loginIdp } = useApp(handleSetMessage, handleLoanding, false);

    // Obtención de los id del selector y del radioButton
    const idSelect = objAttributesSeleccionConvocatoriaPerfil.select.id;
    const idRadioGroup = objAttributesSeleccionConvocatoriaPerfil.radioButton.idRadioGroup;

    /**
     * @function getPerfilesPCT3 Función que obtiene los perfiles de las convocatorias pertenecientes a la PCT3
     * @param conv Convocatorias recibidas por parámetros
     * @returns Devuelve los perfiles de PCT3
     */
    const getPerfilesPCT3 = (conv) => {
        let perfilesObtenidos = [];
        let convocatoriasObtenidas = (conv === undefined) ? convocatorias : conv;
        
        convocatoriasObtenidas.forEach(buscarConvocatoria => {
            if (buscarConvocatoria.procedencia === 'PCT3') {
                let perfiles = buscarConvocatoria.perfiles;
                perfiles.forEach(buscarPerfiles => {
                    let perfilAdd = buscarPerfiles;
                    (!getPerfilExistente(perfilesObtenidos, perfilAdd)) && perfilesObtenidos.push(perfilAdd);
                });
            }
        });
        return perfilesObtenidos;
    }

    /**
     * @function mostrarAcceso Función que obtiene los tipos de acceso presentes
     * @param {*} convocatorias Recibe las convocatorias
     * @returns Devuelve un listado con los accesos de PCT
     */
    const mostrarAcceso = (tipoAcceso) => {

        // Creamos un conjunto para almacenar los métodos de acceso presentes en las convocatorias
        const accesosEnConvocatorias = new Set();

        // Recorremos la lista de convocatorias y tiposIdentificacion. Agregamos los códigos al conjunto
        convocatorias.forEach(buscarConvocatoria => {
            let tiposIdentificacion = buscarConvocatoria.tiposIdentificacion;
            tiposIdentificacion.forEach(objeto => accesosEnConvocatorias.add(objeto.codigo));
        });
        return accesosEnConvocatorias.has(tipoAcceso)
    }

    /**
     * @function getEstadoSaltoBandejaPCT3 Función que indica si se realiza el salto a la bandeja
     * @param {*} infoUsuario Contiene la información del usuario logado
     * @param {*} tiposIdentificacionObtenidos Array con los tipos de idenficiadores obtenidos
     * @returns Devuelve un valor booleano, el cual indicará si se realiza el salto a la bandeja
     */
    const getEstadoSaltoBandejaPCT3 = (infoUsuario, tiposIdentificacionObtenidos) => {

        let saltoActivado = false;
        if (infoUsuario !== undefined && infoUsuario.tipoAutenticacion !== undefined) {
            let codeTipoAutenticacion = infoUsuario.tipoAutenticacion;
            let idTipoAutenticacion;

            if (codeTipoAutenticacion === objAttributesSeleccionConvocatoriaPerfil.buttons.certificadoDigital.code) {
                idTipoAutenticacion = properties.CODIGO_CERTIFICADO;
            } else if (codeTipoAutenticacion === objAttributesSeleccionConvocatoriaPerfil.buttons.entradaClave.code || codeTipoAutenticacion === "idp" ) {
                idTipoAutenticacion = properties.CODIGO_CLAVE;
            } else if (codeTipoAutenticacion === objAttributesSeleccionConvocatoriaPerfil.buttons.entradaRegistrado.code) {
                idTipoAutenticacion = properties.CODIGO_REGISTRADO;
            }

            tiposIdentificacionObtenidos.forEach(iterador => {
                if (iterador.codigo.toUpperCase() === idTipoAutenticacion.toUpperCase()) {
                    saltoActivado = true;
                }
            });
        }
        return saltoActivado;
    }

    /**
     * @function getConvocatorias Función que obtiene las convocatorias correspondientes al sistema al que pertenezca el procedimiento (PCT2 o PCT3) 
     * @returns Devuelve un array de convocatorias
     */
    const getConvocatorias = async (idProcedimientoParam) => {
        let newConvocatorias = [];
        if (!idProcedimientoParam) {
            // Obtención de convocatorias de un procedimiento de PCT3 a través de su idProcedimiento
            if(!idProcedimientoParam){
                await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_CONVOCATORIAS, { url: properties.RESOURCE_GET_CONVOCATORIAS + idProcedimiento.split('_')[1], method: 'get' }).then(response => {
                    newConvocatorias = response.data;
                });
            }else{
                await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_CONVOCATORIAS, { url: properties.RESOURCE_GET_CONVOCATORIAS + idProcedimientoParam.split('_')[1], method: 'get' }).then(response => {
                    newConvocatorias = response.data;
                });
            }
            // Tratamiento de los perfiles para añadir la clave descripción y para incluir la procedencia de la convocatoria
            if (newConvocatorias.length !== 0) {
                newConvocatorias.forEach(convo => {
                    Object.assign(convo, {"procedencia": "PCT3"});
                    convo.perfiles.forEach(p => {
                        Object.assign(p, { "descripcion": p.codigo });
                        delete p.codigo;
                    });
                });
                saveState(localStorageName.convocatoriasProcedimientoPCT, convocatorias, true)
            }
        } else {
            let convocatoriaActual = loadState(localStorageName.convocatoriasProcedimientoPCT, true);
            // Se incluye la procedencia de las convocatorias
            convocatoriaActual.forEach(convo => {
                Object.assign(convo, {"procedencia": "PCT3"});
            });
            newConvocatorias = convocatoriaActual;
        }
        return newConvocatorias;
    }

    /**
     * @function getPerfilConvocatoria Función que obtiene el perfil de las convocatorias correspondientes al sistema al que pertenezca la convocatoria (PCT2 o PCT3)
     * @param idConvocatoriaSeleccionada Recibe el id de la convocatoria seleccionada
     * @returns Devuelve el perfil de la convocatoria
     */
    const getPerfilConvocatoria = (idConvocatoriaSeleccionada) => {
        let perfilesObtenidos = [];

        convocatorias.forEach(buscarConvocatoria => {
            let perfiles = buscarConvocatoria.perfiles;
            perfiles.forEach(buscarPerfil => {
                (buscarConvocatoria.id === idConvocatoriaSeleccionada) && perfilesObtenidos.push(buscarPerfil);
            });
        });
        return perfilesObtenidos;
    }

    /**
     * @function getEstadoBoton Función que comprueba si el tipo de indentificación está disponible para el perfil seleccionado de la convocatoria seleccionada
     * @param perfilSeleccionado recibe un array de objetos del perfilSeleccionado
     * @param atributos recibe los atributos del boton
     * @returns Devuelve un valor booleano.
     */
    const getEstadoBoton = (atributos) => {
        let idButton = atributos.id, codigoOV3 = atributos.codigoOV3;
        let disabled = true;
        let infoUsuario = loadState(localStorageName.infoUsuario, true);        
        let perfilSeleccionado = loadState(localStorageName.perfilSeleccionado, true);
        let convocatoriaSeleccionada = loadState(localStorageName.convocatoriaSeleccionada, true);

        if (perfilSeleccionado !== undefined && perfilSeleccionado) {
            let tipoAutenticacion = convocatoriaSeleccionada.tiposIdentificacion;
            let saltoActivado = getEstadoSaltoBandejaPCT3(infoUsuario, tipoAutenticacion);

            if (idButton === objAttributesSeleccionConvocatoriaPerfil.buttons.saltoABandeja.id) {
                disabled = !saltoActivado;
            }

            tipoAutenticacion.forEach(iterador => {
                if (!saltoActivado && idButton !== objAttributesSeleccionConvocatoriaPerfil.buttons.saltoABandeja.id && codigoOV3 !== undefined) {
                    if (iterador.codigo.toLowerCase() === codigoOV3.toLowerCase()) {
                        disabled = false;
                    }
                }
            });
        }
        return disabled;
    }

    /**
     * @function compruebaUsuario Función que comprueba la siguiente información del usuario
     * 
     * Login con certificado:
     *  - Si el usuario no existe en la aplicación, se realiza una redirección hacia la pantalla de alta de usuario
     *  - Si el usuario existe en la aplicación, se realiza una redirección hacia la bandeja de solicitudes
     *  - Si se produce un error que no sea el 404, se lanza una excepción.
     * Login con clave:
     *  - Si se produce un error al realizar el login con clave, se lanza una excepción.
     */
    const compruebaUsuario = async () => {
        setIsLoading(true);
        let infoUsuario = loadState(localStorageName.infoUsuario, true);
        let errorClave = loadState(localStorageName.errorClave, true);

        if (errorClave !== undefined) {
            handleSetMessage(errorClave.errorClave.mensaje, 'error');
            localStorage.removeItem('errorClave');
        } else if (infoUsuario !== undefined) {
            let infoUsuario = loadState(localStorageName.infoUsuario, true);
            let interesadoBuscado = {
                identificador: infoUsuario.interesadoNif,
                identificadorEntidad: infoUsuario.entidadCif
            };

            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_INTERESADO, { url: properties.RESOURCE_OBTENER_DATOS_INTERESADO, method: 'post', data: interesadoBuscado }).then(response => {
                redirectBandejaSolicitudes(history);
            }).catch(function (error) {
                (error.response.data.errorCode === 404) ? ventanaUsuarioSinAltaAbrirCerrar(true) : handleSetMessage(errorClave.errorClave.mensaje, 'error');
            });
        }
    }

    /**
     * @function montarPantallaPorUrl Función que monta la pantalla através de URL sin necesidad de navegación en la app
     */
    const montarPantallaPorUrl = async () => {
        let res;
        // Se obtiene y almacena en localStorage la url de procedencia del procedimiento para que la utilice las migas de pan
        let urlActual = window.location.hash;
        let posicion = urlActual.lastIndexOf('/', urlActual.length);
        let urlProcedimiento = urlActual.substring(posicion, urlActual.length);
        localStorage.setItem(localStorageName.urlProcedenciaProcedimiento, urlProcedimiento);
        
        let dataProc = idProcedimiento.split('_');
        let procedimientosObtenidos = null;
        if (dataProc) {
            try {
                await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_PROCEDIMIENTOS_PCT3, {
                    url: '/' + dataProc[1], method: 'get' }).then(response => {
                    procedimientosObtenidos = response.data;
                    procedimientosObtenidos.fromPct3 = true;
                    res = procedimientosObtenidos;
                
                    // Restringir acceso si el procedimiento tiene un estado distinto a "Activo público"
                    if (res.estado.toUpperCase() !== estadoVisibleOV.toUpperCase()){
                        saveState(localStorageName.popupProcInterno, true, false);
                        history.push('/');
                        return;
                    }

                    getListConvocatorias(procedimientosObtenidos.id);
                    saveState(localStorageName.procedimientoActual, procedimientosObtenidos, true);
                })
            } catch (error) {
                handleSetMessage('Se ha producido un error al obtener el procedimiento', 'error');
                history.push('/')
                return;
            }

        } else {
            res = procedimientoActual;
        }
        setProcActual(res);
    }

    /**
     * @function guardarProcActual Función que realiza el guardado del procedimiento, independientemente del sistema del que provenga
     * @param {*} idProcedimiento 
     */
    const guardarProcActual = async (convocatoria) => {
        // Paso 1. Se comprueba el contenido de los parámetros
        if ((convocatoria !== undefined && convocatoria !== null)) {
            let idProcedimiento = convocatoria.idProcedimiento;
        
            // Paso 2. Se comprueba si el procedimiento actual no es el mismo que el que se va a guardar
            if (procActual.idProcedimiento !== idProcedimiento) {

                // Paso 3. Se comprueba la procedencia para realizar el guardado del procedimiento
                let proc = await guardarProcActualPCT3(idProcedimiento)
                
                // Paso 4. Acciones del guardado del procedimiento    
                if (proc !== undefined && proc != null) {
                    setProcActual(proc);
                    context.updateTitlePage(objTextSeleccionConvocatoriaPerfil.titulos.tituloPagina + ((proc.descripcion === undefined) ? proc.nombre : proc.descripcion));
                    saveState(localStorageName.procedimientoActual, proc, true);
                    comprobarMensajeBienvenida();
                    updateUrl(proc);
                }
            }
        }
    }

    /**
     * @function redirectBandejaSolicitudes Función que realiza la redirección a la bandeja de solicitudes
     * @param {*} history 
     */
    const redirectBandejaSolicitudes = (history) => {
        setIsLoading(true);
        saveState(localStorageName.accesoBandejaSol, true, false);
        history.push('/BandejaSolicitudes');
        setIsLoading(false);;
    }

    /**
     * @function getListConvocatorias Función que obtiene el listado de convocatorias
     */
    const getListConvocatorias = async (idProcedimientoParam) => {
        let perfilesPublicos = [];
        let listaConvocatorias = await getConvocatorias(idProcedimientoParam);  
        setConvocatorias(listaConvocatorias);
        if (listaConvocatorias && listaConvocatorias.length === 1) {

            listaConvocatorias[0].perfiles.forEach((perfil) => {
                perfilesPublicos.push(perfil);
            });

            //SELECCIONAR DIRECTAMENTE
            if (perfilesPublicos.length === 1) {
                //Si solo existe una convocatoria y un perfil lo marca directamente
                setValues({
                    ...values,
                    [idSelect]: listaConvocatorias[0],
                    [idRadioGroup]: JSON.stringify(perfilesPublicos[0])
                });
                saveState(localStorageName.convocatoriaSeleccionada, listaConvocatorias[0], true);
                saveState(localStorageName.perfilSeleccionado, perfilesPublicos[0], true);
            } else {
                //Si solo existe una convocatoria la marca directamente
                setValues({
                    ...values,
                    [idSelect]: listaConvocatorias[0],
                });
                saveState(localStorageName.convocatoriaSeleccionada, listaConvocatorias[0], true);
            }
            comprobarMensajeBienvenida();
        }else{
            //Si viene mas de una convocatoria y estamos cargando por URL limpiamos la convocatoria/perfil seleccionado
            if (idProcedimientoParam) {
                saveState(localStorageName.convocatoriaSeleccionada, null, true);
                saveState(localStorageName.perfilSeleccionado, null, true);
                setValues({
                    ...values,
                    [idSelect]: '',
                    [idRadioGroup]: ''
                });
            }
        }
    }

    /**
     * @function getPerfilExistente Función auxiliar de las funciones getPerfiles y getPerfilesNoIncluidosConvocatoria. Comprueba si existe un perfil ya añadido
     * @param {*} arrayPerfiles Recibe un array de objetos que contentiene todos los perfiles existentes 
     * @param {*} perfilAdd Objeto que se añade al perfil
     */
    function getPerfilExistente(arrayPerfiles, perfilAdd) {
        let existe = false;

        arrayPerfiles.forEach(buscarPerfiles => {
            if (buscarPerfiles.descripcion === perfilAdd.descripcion) {
                existe = true;
            }
        });
        return existe;
    }

    /**
     * @function getIdsPerfilesConvocatoria Obtiene los id de cada perfil de la convocatoria
     * La función devuelve un array con los id.
     * @param {*} convocatoriaSeleccionada convocatoria seleccionada en el selector que se recibirá por parámetros
     */
    const getIdsPerfilesConvocatoria = (convocatoriaSeleccionada) => {
        let idsObtenidos = [];
        if (convocatoriaSeleccionada) {
            let idConvocatoriaSeleccionada = convocatoriaSeleccionada.id;
            let perfiles = getPerfilConvocatoria(idConvocatoriaSeleccionada);

            perfiles.forEach(element => {
                idsObtenidos.push(element.id);
            });

        }
        return idsObtenidos;
    }

    const ventanaUsuarioSinAltaAbrirCerrar = (accion) => {
        setMostrarVentana(accion);
        setIsLoading(accion)
    }

    const cargarValoresEffect = async () => {
        updateFormat();
        await getListConvocatorias(null);

        const procActual = loadState(localStorageName.procedimientoActual, true);

        context.updateTitlePage(objTextSeleccionConvocatoriaPerfil.titulos.tituloPagina + procActual?.descripcion);

        const convocatoriaSeleccionada = loadState(localStorageName.convocatoriaSeleccionada, true);
        const perfilSeleccionado = loadState(localStorageName.perfilSeleccionado, false);

        if (convocatoriaSeleccionada !== undefined) {
            values.seleccionConvocatoria = convocatoriaSeleccionada;
        }
        if (perfilSeleccionado !== undefined) {
            values.perfil = perfilSeleccionado;
        }

        let redirect = loadState(localStorageName.redirect, false);
        if (redirect === 'clave') {
            await compruebaUsuario();
        }
        return () => window.removeEventListener('resize', updateFormat);
    }

    const effectAux = async () => {
        setIsLoading(true);
        await montarPantallaPorUrl();
        await cargarValoresEffect();
        setPintarPantalla(true);
        setIsLoading(false);
    }

    const comprobarMensajeBienvenida = () => {
        let procedimientoActual = loadState(localStorageName.procedimientoActual, true);
        if (procedimientoActual && procedimientoActual.mensajeBienvenida) {
            setMostrarMensajeBienvenida(true);
        } else {
            setMostrarMensajeBienvenida(false);
        }
    }
    const postDatosSalto = (e, tipoAutenticacion) => {
        setIsLoading(true);
        const datosSalto = getDatosSaltoBandejaAnonimo(tipoAutenticacion);
        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_SALTO_BANDEJA, { url: '', method: 'post', data: datosSalto }).then(response => {
            saveState(localStorageName.redirect, 'bandeja', false);
            const url = response.data;
            window.location.href = url;
        }).catch(function (error) {
            if (error.response.status !== 200) {
                handleSetMessage("Ha ocurrido un error al intentar saltar a la bandeja.", 'error');
            }
        }).finally(function () {
            setIsLoading(false);
        });
    }

    const getDatosSaltoBandejaAnonimo = (tipoAutenticacion) => {
        const perfil = JSON.parse(values.perfil)
        let procedimientoActual = loadState(localStorageName.procedimientoActual, true);

        let datosSalto = {
            tipoAutenticacion: tipoAutenticacion,/*Obtener el tipo de autenticacion de forma dinamica*/
            idProcedimientoActivo: procedimientoActual.id,
            idConvocatoriaActiva: values.seleccionConvocatoria.id,
            idOrdenActiva: values.seleccionConvocatoria.idOrden,
            idPerfilActivo: perfil.id,
            urlSalto: procedimientoActual.url
        }
        return datosSalto;
    }

    const handleChange = async (e, newValue, idComponente) => {
        let perfilesPublicos = [];
        if (idComponente) {
            if (idComponente === idSelect) {

                newValue && newValue.perfiles && newValue.perfiles.forEach((perfil) => {
                    perfilesPublicos.push(perfil);
                });

                if (perfilesPublicos.length === 1) {
                    setValues({
                        ...values,
                        [idComponente]: newValue,
                        [idRadioGroup]: JSON.stringify(perfilesPublicos[0]),
                    });
                    saveState(localStorageName.convocatoriaSeleccionada, newValue, true);
                    saveState(localStorageName.perfilSeleccionado, perfilesPublicos[0], true);

                } else {
                    setValues({
                        ...values,
                        [idComponente]: newValue,
                        [idRadioGroup]: '',//Se reestablece el valor inicial cada vez que cambia el valor de la convocatoria
                    });
                    saveState(localStorageName.convocatoriaSeleccionada, newValue, true);
                    localStorage.removeItem(localStorageName.perfilSeleccionado);
                }
                // Guardado del procedimineto al cual pertenece la convocatoria
                await guardarProcActual(newValue);
            } else {
                setValues({
                    ...values,
                    [idComponente]: newValue,
                });
                saveState(localStorageName.perfilSeleccionado, newValue, false);
            }
        }
    }

    /**
     * @function guardarProcActualPCT3 Función que almacena el procedimiento obtenido en local storage
     * @param {*} id
     */
    const guardarProcActualPCT3 = async (id) => {
        let procedimientoObtenido = null, proc = null;

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_PROCEDIMIENTOS_PCT3, { url: '/' + id, method: 'get' }).then(response => {
            procedimientoObtenido = response.data;
        }).catch(function (error) {
            handleSetMessage('Se ha producido un error al obtener el procedimiento', 'error');
        });

        if (procedimientoObtenido !== null) {
            proc.fromPct3 = true;
        }
        return proc;
    }

    const handleChangeMensajeBienvenida = (panel) => (event, newExpanded) => {
        setExpandedMensajeBienvenida(newExpanded ? panel : false);
    };

    /**Llamada al microservicio de clave-api-proxy para realizar el login con clave */
    const useLoginIdp = () => {
        setIsLoading(true);

        saveState(localStorageName.redirect, 'clave', false);
        saveState(localStorageName.origenLogin, '{"redireccion":"/SeleccionConvocatoriaPerfil"}', false);

        loginIdp(propertiesAppData.URL_LOGIN);
    }

    const onClickCertificado = () => {
        firmar();
    }

    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    };

    /**
     * @function getLabelConvocatoria Función que inserta el texto a mostrar en las opciones del selector de convocatorias
     * @param {*} option 
     * @returns 
     */
    const getLabelConvocatoria = (option) => {
        let label = '';
       
        if (option) {
            let descripcion = (option.descripcion !== undefined) ? option.descripcion : '';
            let descripcionProc = (option.descripcionProc !== undefined) ? " - " + option.descripcionProc : '';
            label = descripcion +  descripcionProc;
        }
        return label.toUpperCase();
    }

    /**
     * @function mostrarPerfiles Función que muestra los perfiles de las convocatorias existentes
     * @returns 
     */
    const mostrarPerfiles = () => {
        let perfiles = [], perfPCT3 = [], perfilesObtenidos = [];

        if (convocatorias.length > 0) {
            // Paso 1. Obtención de las convocatorias
            let convoPCT3 = convocatorias.filter((convo) => convo.procedencia === 'PCT3');

            // Paso 2. Obtención de los perfiles pertenecientes a las convocatorias de PCT3
            if (convoPCT3.length > 0) {
                perfPCT3 = getPerfilesPCT3(convoPCT3);
                perfilesObtenidos.push(...perfPCT3);
            }

            // Paso 3. Obtención de los perfiles de las convocatorias
            if (perfilesObtenidos.length > 0) {
                for (const perf of perfilesObtenidos) {
                    // Paso 4.1. Se comprueba si previamente existe el perfil
                    let existePerfil = false;
                    if (perfiles.length > 0) {
                        let perfilEncontrado = perfiles.filter((p) => p.descripcion === perf.descripcion);
                        existePerfil = (perfilEncontrado.length > 0) ? true : false;
                    }
                    !existePerfil && perfiles.push(perf);
                }
            }
        }
        return perfiles;
    }

    /**
     * @function updateUrl Función que actualiza la url de procedencia del procedimiento
     */
    const updateUrl = (procedimiento) => {
        if (procedimientoActual !== undefined && procedimientoActual !== '') {            
            let urlProcedimiento = `/${procedimiento.id}`;
            localStorage.setItem(localStorageName.urlProcedenciaProcedimiento, urlProcedimiento);
        }
    }

    const irAccesoConContrasena = (e, entidad) => {
        history.push('/AccesoConContrasena');
    }

    return {
        values, convocatorias, isPhoneVersion, handleChange, updateFormat, mostrarPerfiles, mostrarAcceso,
        getIdsPerfilesConvocatoria, getEstadoBoton, alerts, message, setMessage, getLabelConvocatoria,
        mostrarMensajeBienvenida, expandedMensajeBienvenida, handleChangeMensajeBienvenida,
        procActual, useLoginIdp, onClickCertificado, compruebaUsuario, postDatosSalto, effectAux,
        isLoading, ventanaUsuarioSinAltaAbrirCerrar, mostrarVentana, pintarPantalla, context,
        irAccesoConContrasena
    };
}

export default useFunctions;
