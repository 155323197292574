export const properties = {
    RESOURCE : 'procedimientos',
    URL_TIPO_PROCEDIMIENTOS : 'tipo-procedimientos',
    URL_SISTEMAS_SUBVF2 : 'sistemasSubvF2',
    INIT_PAGE : 1,
    RECORDS_PER_PAGE : 5,
    DISPLAY_TIME_ERROR: 5000,
    ALABEL_FILTRADO: 'Filtrar procedimientos',
    ALABEL_LISTADO: 'Listado de procedimientos',
    URL_CERRAR_SESION_DESDE_PCT2: 'cerrarSesion.do',
    ID_BOTON_CERRAR_SESION: 'cerrarSesion',
    POPUP_ICON: 'app/iconoAC.png',
    RESOURCE_GESTION_EXPEDIENTE: 'gestion-expediente',
    RESOURCE_GETPROCEDIMIENTOS_INTERESADO: '/getProcedimientosInteresado',
    RESOURCE_PCT_PROCEDIMIENTO : 'procedimiento',
    URL_PROCEDIMIENTOS_OV : '/procedimientoOV',
    RESOURCE_TIPO: 'tipo-procedimiento',
    ID_BOTON_CERRAR_SESION: 'cerrarSesion',
    RESOURCE_CONVOCATORIAS: 'convocatoria',
    RESOURCE_GET_CONVOCATORIAS: '/procedimiento/',
    DATE_TIME: 'dateTime',
}

export const texts  = {
    PAGE_TITLE : 'Procedimientos',
    SERVER_ERROR_MSG: 'Se ha producido un error en el servidor',
    ESTADO_ABIERTO: 'Abierto el plazo de presentación de solicitudes',
    ESTADO_CERRADO: 'Cerrado el plazo de presentación de solicitudes'
}

export const flagAc = {
    VISTO : 'visto'
}

export const objDataPopupProcInterno = {
    titulo: 'Procedimiento no disponible',
    mensaje: 'El procedimiento seleccionado no está disponible actualmente.'
}