import React, { useEffect, useState } from 'react';
import { Grid, Backdrop, CircularProgress, ThemeProvider, IconButton, Snackbar, Popover } from '@material-ui/core';
import { objAtrib, objText, properties, keysTablaGenericaPCT3, placeholderBuscador, constantesEstadosFase, paths, texts } from './BandejaSolicitudesData';
import customizate, { style, objStyle } from './BandejaSolicitudesStyle';
import { Alert } from '@material-ui/lab';
import { loadState } from './../../../providers/localStorage';
import { properties as AppData } from 'AppData';

//Importacion de componentes horizontales
import SelectSimple from '../../commons/SelectSimple/Select';
import Select from '../../commons/selectAutocomplete/SelectAutocomplete';
import Input from '../../commons/input/Input';
import Pager from '../../commons/pager/Pager';
import useOperations from './BandejaSolicitudesFunction';
import TablaGenerica from '../../commons/tablaGenerica/tablaGenerica';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import { compProperties, properties as appProperties  } from './../../../AppData';


import { useHistory } from "react-router-dom";
import { localStorageName } from 'providers/localStorageData';

//Contenedor de la pagina
export default function BandejaSolicitudes() {

  const history = useHistory();

  const { tituloPagina, situacionExp, estadoExp, selectSituacion, menuItem, selectEstado, buscador, isDisabled,
    handleChangeSituacion, handleChangeEstado, handleChangeBuscador, handleClickAcciones,
    headerTableObject, dataTableObject,
    getSituacion, getEstados, getValoresTabla, crearNuevaSolicitud, seleccionarFormulario,
    totalRecords, currentPage, paginate, valorBuscado,
    isLoading, isPhoneVersion, updateFormat, funcionOrdena,
    ordenacionTipo, campoOrdenado, alerts, message, setMessage, refEstado, formatearTexto, mostrarFiltros, mostrarOcultarFiltros,
    setIsLoading, cerrarErrorDropRejected, errorDropRejected, mensajePopUp, busquedaPorBuscador, mensajeError, mostrarPopUpAviso,
    accionBorrar, cerrarPopUpAvisoAccion, mensajeTitulo, mostrarBotonNuevaSolicitudFunction, mostrarBotonNuevaSolicitud,
    anchorEl, numMostrar, handleChangePager, refBuscador, pressBuscador, obtenerFasesEstados,
    showOrgSelector, listadoOrganismos, handleChangeOrganismo, handleIniciarSolicitud, handleCancelarSolicitud } = useOperations();

    const [clickBuscar, setClickBuscar] = useState(false);

  //Almacenamiento de Hooks
  const customStyle = style();
  const objetoDatos = dataTableObject();
  const objetoCabecera = headerTableObject(funcionOrdena);

  //Variables de acceso rápido
  let situacion = properties.situacion;
  let estado = properties.estado;
  let pagina = properties.INIT_PAGE;

  /**Método que renderiza el paginador solo en versión ordenador */
  const renderPager = () => {
    if (!isPhoneVersion) {
      return <Grid item>
          <Pager recordsPerPage={numMostrar} totalRecords={totalRecords} currentPage={currentPage}
            paginate={paginate} numFilasMostrar={numMostrar} registrosTotales={totalRecords} handleChange={handleChangePager}
            numFilasPersonalizado={true} disabledRegistros={totalRecords < 5} anchorEl={anchorEl}
          />
        </Grid>
    }
  }

  // Se inserta el nombre del título de la página
  document.title = AppData.TITULO_PAGINA + tituloPagina;

  /////////////////////////////////////////////////////Llamadas a endPoints
  useEffect(() => {
    mostrarBotonNuevaSolicitudFunction();
    borrarValoresLocalStorage();
    getSituacion();
    getEstados(situacion);
    updateFormat();
    obtenerFasesEstados();
    return () => window.removeEventListener('resize', updateFormat);
  }, []);

  useEffect(() => {
    if (loadState(localStorageName.accesoBandejaSol, false) !== undefined) {
      localStorage.removeItem(localStorageName.accesoBandejaSol);
    }
    getValoresTabla(situacion, estado, pagina, campoOrdenado, ordenacionTipo, valorBuscado);
    setClickBuscar(false)
  }, [numMostrar, clickBuscar]);

  /* Elimina los valores del localStorage cuando se renderiza la página */
  const borrarValoresLocalStorage = () => {
    localStorage.removeItem(localStorageName.solicitudActual);
    localStorage.removeItem(localStorageName.fase);
    localStorage.removeItem(localStorageName.pdfSolicitud);
    localStorage.removeItem(localStorageName.idExpTrewa);
    localStorage.removeItem(localStorageName.accionModificar);
    localStorage.removeItem(localStorageName.entidadSeleccionada);
    localStorage.removeItem(localStorageName.entidadesDeAlta);
    localStorage.removeItem(localStorageName.organismo);
  }

  /*Navegar a la pantalla Nueva Solicitud*/
  const onClickNuevaSolicitud = async (e) => {
    setIsLoading(true);
    await crearNuevaSolicitud();
  }

  window.addEventListener('resize', updateFormat);

  let keysTablaGenerica = keysTablaGenericaPCT3;

  return (
    <ThemeProvider theme={customizate(isPhoneVersion)}>
      {/*Grid principal*/}
      <Grid container spacing={12} {...objAtrib.gridPrincipal}>
        {isPhoneVersion &&
          <Grid {...objAtrib.gridBuscador} className={customStyle.classGridBuscadorResponsive}>
          <Grid container>
            <Grid>
              <Input
                {...objAtrib.inputBuscador}
                classes={customStyle.classGrid}
                classInput={customStyle.classInput}
                value={buscador}
                onChange={handleChangeBuscador}
                inputRef={null}
                placeholder={placeholderBuscador.pct3}
                />
            </Grid>
            <Grid>
              <IconButton 
                  disableRipple
                  id={texts.idButton}
                  className={customStyle.searchButtonPhone}
                  onClick={()=>{setClickBuscar(true)}}
                  >
                      <img 
                          className={customStyle.searchImgPhone}
                          src={paths.searchIconPath} 
                          alt={texts.searchIconAlt}/>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>  
        }
        {/*Grid cabecera*/}
        <Grid {...objAtrib.gridCabecera} className={customStyle.classGridCabecera} container>
          {/*Titulo*/}
          <Grid {...objAtrib.gridTitulo}>
            <h1 {...objAtrib.tituloEncabezado} className={customStyle.classH2}>{tituloPagina}</h1> 
          </Grid>
          {!isPhoneVersion &&
              <Grid {...objAtrib.gridBuscador} className={customStyle.classGridBuscador}>
              <Grid container>
                <Grid>
                  <Input
                    {...objAtrib.inputBuscador}
                    classes={customStyle.classGrid}
                    classInput={customStyle.classInput}
                    value={buscador}
                    onChange={handleChangeBuscador}
                    inputRef={null}
                    onKeyPress={pressBuscador}
                    placeholder={placeholderBuscador.pct3}
                  />
                </Grid>
                <Grid>
                  <IconButton 
                      disableRipple
                      id={texts.idButton}
                      className={customStyle.searchButton}
                      onClick={()=>{setClickBuscar(true)}}
                      >
                          <img className={customStyle.searchImg}
                              src={paths.searchIconPath} 
                              alt={texts.searchIconAlt}/>
                  </IconButton>
                </Grid>
                
              </Grid>
            </Grid>        
          }
          {/*Buscador Buscador*/}
          
        </Grid>
        {/*Grid contenedor*/}
        <Grid container spacing={3} {...objAtrib.gridContenedor} className={customStyle.classGridContenedor}>
          {isPhoneVersion ? <button {...objAtrib.button} className={customStyle.classButtonResponsive}>{objText.nombreButton}</button>
              : <Grid container xs={9} className={customStyle.classGridSelect}>
                {/*Selector Situacion*/}
                <SelectSimple
                  {...objAtrib.selectSituacion}
                  value={selectSituacion}
                  onChange={handleChangeSituacion}
                  map={menuItem(situacionExp)}
                />
                {/*Selector Estado*/}
                <Select
              {...objAtrib.selectEstado}
              classes={customStyle}
              options={estadoExp}
              getOptionLabel={(option) => formatearTexto(option.nombre)}
              onChange={handleChangeEstado}
              values={selectEstado}
              refSelect={refEstado}
              disabled={isDisabled}
            />
              </Grid>
          }
          {(() => {
              if (isPhoneVersion && mostrarFiltros) {
                return (
                  <Grid className={customStyle.classSelectResponsive}>
                      {/*Selector Situacion*/}
                      <SelectSimple
              {...objAtrib.selectSituacion}
              value={selectSituacion}
              onChange={handleChangeSituacion}
              map={menuItem(situacionExp)}
            />
                    {/*Selector Estado*/}
                    <Select
              {...objAtrib.selectEstado}
              classes={customStyle}
              options={estadoExp}
              getOptionLabel={(option) => formatearTexto(option.nombre)}
              onChange={handleChangeEstado}
              values={selectEstado}
              refSelect={refEstado}
              disabled={isDisabled}
            />
                  </Grid>
                )                        
            } 
          })()}
            
              {!isPhoneVersion ?
                <Grid item xs={2}>
                  {mostrarBotonNuevaSolicitud &&
                    <button {...objAtrib.button}  onClick={onClickNuevaSolicitud} className={customStyle.buttonNuevaSolicitud}>{objText.nombreButton}</button>
                  }
                </Grid>
                : <Grid className={customStyle.classGridContenedor} >
                    <button onClick={mostrarOcultarFiltros} {...objAtrib.button} className={customStyle.classButtonFilterResponsive}>{mostrarFiltros ? objText.buttonOcultarResponsiveSelects : objText.buttonResponsiveSelects }</button>
                  </Grid> 
                }

          
          
          {/*Grid contenedor tabla*/}
          <Grid container spacing={12} >
          <TablaGenerica
              tableData={objetoDatos}
              headingColumns={objetoCabecera}
              isPhoneVersion={isPhoneVersion}
              messageNoData={objText.TEXT_NO_DATOS}
              {...keysTablaGenerica}
            />
          </Grid>
          {/*Grid contendor Paginador*/}
          <Grid container {...objStyle.classGridPager} className={customStyle.classPager}>
            {renderPager()}
          </Grid>
        </Grid>
        <Backdrop className={customStyle.classBackdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Confirmacion
          accionConfirmacion={cerrarErrorDropRejected}
          titulo={mensajeTitulo}
          mostrar={errorDropRejected}
          isPhoneVersion={isPhoneVersion}
          mensaje={mensajeError}
          botonera={compProperties.CONFIR_SOLO_ACEPTAR}
          quitarImg={false}
          tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
          isPopUpPdf={false}
          textBotonConfirmarPersonalizado={false}
          isImagenPersonalizada={true}
          centrarContenido={true}
          imagen={properties.IMAGEN_ERROR}
          isTextoEnriquecido={true}
        />
        <Confirmacion
          handleChange={cerrarPopUpAvisoAccion}
          accionConfirmacion={accionBorrar}
          accionDenegacion={compProperties.CONFIR_SIN_ACCION}
          titulo={objText.TEXT_AVISO_IMPORTANTE}
          mostrar={mostrarPopUpAviso}
          isPhoneVersion={isPhoneVersion}
          mensaje={mensajePopUp}
          botonera={compProperties.CONFIR_CANCELAR_ACEPTAR}
          quitarImg={false}
          tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
          isPopUpPdf={false}
          textBotonConfirmarPersonalizado={false}
          isImagenPersonalizada={false}
          centrarContenido={true}
          isTextoEnriquecido={true}
        />

        {/*PopUp contendor del selector de organismos*/}
        <Popover
          open={showOrgSelector}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
          }}
          transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
          }}>
          <Grid>
            <h2 {...objAtrib.tituloPopUp} className={customStyle.classPopupTitle}>
              Seleccione la unidad organizativa destinataria
            </h2>
          <Grid className={customStyle.selectOrganismo}>
            <Select
            style={{ margin: '0px' }}
              {...objAtrib.selectOrganismo}
              classes={customStyle}
              options={listadoOrganismos}
              getOptionLabel={(option) => option.nombre}
              onChange={handleChangeOrganismo}
              disabled={false}
            />
          </Grid>
          <Grid container className={customStyle.botoneraPopupOrganismo} >
            <button  
              {...objAtrib.buttonCancelar}  
              onClick={handleCancelarSolicitud} 
              className={customStyle.classButton}>
                {objText.buttonCancelar}
            </button>
            <button 
              {...objAtrib.buttonCrear}  
              onClick={handleIniciarSolicitud} 
              className={customStyle.classButton}>
                {objText.buttonCrear}
            </button>
            </Grid>
          </Grid>
        </Popover>

        {alerts.map(m => (
          <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
            <Alert
              severity={message.severity}
              variant="filled"
            >
              {message.msg}
            </Alert>
          </Snackbar>
        ))}
      </Grid>
    </ThemeProvider>
  );
}
