import React, {useEffect, useContext} from 'react';
import {Grid} from '@material-ui/core';
import {texts, properties} from './MatrizCompatibilidadData'
import {customStyle} from './MatrizCompatibilidadStyle';
import AppContext from 'AppContext';

export default function MatrizCompatibilidad() {

    //HOOKS
    const context = useContext(AppContext);

    //LLAMADAS
    useEffect(() => {
        context.updateTitlePage(texts.PAGE_TITLE);
    }, []) ;

    //RENDER
    return (
        <main>   
            <Grid container className={customStyle().gridPageTitle}>
                <h2 className={customStyle().classTitleH2} id={properties.ID_PAGE_TITLE}>{texts.PAGE_TITLE}</h2>
            </Grid>

            <Grid className={customStyle().backgroundRectangle}>
                <div className={customStyle().divTabla}>
                    <p className={customStyle().tableTitle}>{texts.TABLE_TITLE}</p>
                    <table className={customStyle().tablaSoftware}>
                        <tr>
                            <th> TIPOLOGÍA </th>
                            <th> VERSIÓN </th>
                        </tr>
                        <tr>
                            <td> Sistema Operativo </td>
                            <td>
                                <table className={customStyle().tablaSoftwareLv2}>
                                    <tr><td> Windows XP </td></tr>
                                    <tr><td> Windows Vista </td></tr>
                                    <tr><td> Windows 7 </td></tr>
                                    <tr><td> Windows 8 </td></tr>
                                    <tr><td> Windows 10 </td></tr>
                                    <tr><td> Windows 11 </td></tr>
                                    <tr><td> Mac OS </td></tr>
                                </table>
                            </td>                        
                        </tr>
                        <tr>
                            <td> Autofirma </td>
                            <td> Autofirma del Gobierno de España </td>
                        </tr>
                        <tr>
                            <td> Navegador </td>
                            <td>
                                <table className={customStyle().tablaSoftwareLv2}>
                                    <tr><td> Google Chrome (versión 132 o superior)</td></tr>
                                    <tr><td> Mozilla Firefox (versión 134 o superior)</td></tr>
                                    <tr><td> Microsoft Edge (versión 132 o superior)</td></tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td> Navegador en dispositivos Android </td>
                            <td className={customStyle().tdFondoBlanco}>
                                <table className={customStyle().tablaSoftwareLv3}>
                                    <tr><td> Google Chrome (versión 132 o superior) </td></tr>
                                    <tr><td> Mozilla Firefox (versión 134 o superior) </td></tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td> Navegador en dispositivos iOS </td>
                            <td>
                                <table className={customStyle().tablaSoftwareLv3}>
                                    <tr><td> Google Chrome (versión 132 o superior) </td></tr>
                                    <tr><td> Mozilla Firefox (versión 134 o superior) </td></tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td> Certificado digital vigente </td>
                            <td> Compatible con @firma </td>
                        </tr>    
                    </table>
                </div>
            </Grid>
        </main>
    );
}