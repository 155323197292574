import { useState } from "react";
import FormioProvider from '../../../providers/FormioProvider';
import RestProvider from 'providers/RestProvider';

const useOperations = (props) => {
    const [formData, setFormData] = useState();
    const { formioOperation } = FormioProvider();

    /**
     * @function getForm Método que obtiene el recurso de formio
     * @param {*} aliasFormulario Recibe el alias del formulario
     */
    const getResource = async (aliasFormulario) => {
        formioOperation(aliasFormulario, {}, true).then(response => {
            setFormData(response.data);
        })
    }

    

    const handleCustomEvents = (customEvent) => {
        //Captura cuando se hace click sobre el botón Añadir todas las acciones
        if (customEvent.type === 'btnAnadirTodasAcciones') {
            //Props que envía el data del formulario en el estado que se encuentre cuando se haga click sobre el botón
            props.handleAcciones(customEvent.data);
        }
    }
    
    return { formData, getResource, handleCustomEvents };
}

export default useOperations;