import { makeStyles } from '@material-ui/core/styles';
import { backgroundRectangle , estilosTextosPrincipales, colores, tipografia, buttonSecundarySmall, buttonPrimarySmall } from '../../../AppStyle';

const useStyles = (isPhoneVersion) => {
    
    const style = makeStyles({
        pageTitle: {
            ...estilosTextosPrincipales.titulosPagina,
        },
        gridPageTitle: {
            marginTop: '2%',
            marginLeft: '5%',
            width: '90%'
        },
        backgroundRectangle: {
            ...backgroundRectangle,
            marginBottom: '0px'
        },
        gridBoton: {
            width:'100%', 
            paddingRight: '5%', 
            margin: 0 ,
        },
        botonVolver:{
            ...buttonPrimarySmall,
            margin: '1% 0.5% 1% 1%',
        },
        hclassText: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            paddingTop: '2%',
            paddingLeft: '2%',
        },
        classDivRect: {
            marginLeft: '5%',
        },
        classBackdrop: {
            zIndex: 1,
            color: colores.principales.blanco,
        },
        gridTitulo:{
            display: 'flex', 
            flexDirection: 'row',
        },
        idExpediente:{
            marginLeft:'25px',
            color: colores.escalaGrises.sesenta,
            fontFamily: tipografia.bold,
            fontSize: '13px',
            height: 'inherit',
            display:'flex',
            alignItems: 'center'
        }
    });

    return {style}
}

export default useStyles;
