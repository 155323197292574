import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { colores, estilosTextosPrincipales } from "AppStyle";

export default makeStyles({
    classGrid: {
        textAlign: 'left',
        marginTop: '15px',
        marginBottom: '15px',
        width: 'auto'
    },
    classInputLabel: {
        color: colores.escalaGrises.cien,
        ...estilosTextosPrincipales.body1,
        marginBottom: '5px',
        position: 'relative'
    },
    classSelect: {
        boxSizing: 'border-box',
        height: '48px',
        width: '328px',
        borderRadius: '4px',
        paddingLeft: '12px',
        color: colores.escalaGrises.cien,
        border: `1px solid ${colores.principales.principal}`,
        '&:hover': {
            borderColor: colores.principales.principal
        },
        '&:focused': {
            borderColor: colores.accesibilidad.focus
        }
    },
    classMenuItem: {
        boxSizing: 'border-box',
        height: '48px',
        width: '328px',
        paddingTop: '11px',
        paddingBottom: '13px',
        paddingLeft: '12px',
        color: colores.escalaGrises.cien,
        ...estilosTextosPrincipales.body1
    }
});

export const overrideStyle = createMuiTheme({
    overrides: {
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: colores.principales.apoyo
                },
                '&:hover': {
                    backgroundColor: colores.principales.apoyo
                }
            }
        }
    }
});