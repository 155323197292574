import {makeStyles} from '@material-ui/core/styles'
import {colores, tipografia, estilosTextosPrincipales} from '../../../AppStyle'

export const customStyle = makeStyles ({
 
    // Bloques Principales

    gridSeccionUno:{
        width: '100%',
        height: '30px',
        backgroundColor: colores.principales.hover
    },
    gridSeccionDos:{
        width: '100%',
    },
    gridSeccionDosPhone:{
        width: '100%',
        paddingBottom: '10px',
        marginTop: '10px',
        marginBottom: '15px',
        boxShadow: `0px 0.2px 5px ${colores.sombras.sombraOscura5}`
    },
    gridSeccionTres:{
        width: 'auto',
        margin:'0 5%',
        height: '73px',
        backgroundColor: colores.escalaGrises.diecinueve,
        padding: '20px 0',
    },

    // Bloques Secundarios
    gridSeccionTresInterior:{
        margin: '0 3%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    gridSeccionTresTitulos:{
        width: 'fit-content',
        height: 'inherit',
    },
    gridSeccionDosArriba:{
        display: 'flex',
        justifyContent: 'center' ,
        width: 'inherit',
        height: '62%',
        boxShadow:`0px 0.2px 5px ${colores.sombras.sombraOscura5}`
    },
    gridSeccionDosArribaInterior:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 5%',
    },
    gridSeccionDosArribaPhone:{
        width: 'inherit',
        height: '62%'
    },
    gridSeccionDosAbajo:{
        width: 'inherit',
        height: '38%',
    },
    gridSeccionUnoContacto:{
        width:'fit-content',
        height: '20px',
        paddingRight: '25px',
        marginRight: '25px',
        borderRight: '1px solid ' + colores.escalaGrises.cero   
    },
    gridSeccionUnoRRSS:{
        width:'fit-content',
        marginRight:'5%',
        marginTop: '5px'
    },

    // Resto de Bloques

    gridSeccionDosAbajoBreadcrumbs:{
        width: 'auto',
        maxWidth: '60%',
        marginLeft: '5%'
    },
    gridSeccionDosAbajoDerecha:{
        width: 'auto',
        maxWidth: '30%',
        marginRight: '5%',
        maxWidth: '40%',
        marginTop: '10px', 
        marginBottom: '10px' 
    },
    gridSeccionDosArribaJA:{
        width: 'fit-content',
    },
    gridLogoJA:{
        width: 'fit-content',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    gridOvTexto:{
        paddingRight: '10px',
        marginRight: '10px'
    },

    // Elementos Franja Verde
    ovTexto:{
        ...estilosTextosPrincipales.encabezadoH1,
        fontWeight: '700',
        lineHeight: '24px',
        color: colores.escalaGrises.cero,
    },
    consejeriaTexto:{
        fontFamily: tipografia.regular,
        fontSize: '17px',
        lineHeight: '24px',
        color: colores.escalaGrises.cero
    },
    barraDivisoria:{
        fontSize: '32px',
        fontStyle: 'normal',
        lineHeight: '32px',
        fontFamily: tipografia.regular,
        color: colores.escalaGrises.cero
    },

    // Elementos Franja Blanca

    botonHome:{
        background: 'transparent',
        border: 'transparent',
        outline:'transparent'
    },
    botonCabecera:{
        height: '48px',
        width: '175px',
        border: '1px solid',
        borderRadius: '4px',
        color: colores.escalaGrises.cero,
        fontFamily: tipografia.bold,
        fontSize: '12px',
        lineHeight: '16px',
        backgroundColor: colores.principales.hover,     
    },
    telefono:{
        ...estilosTextosPrincipales.body1,
        fontWeight: '600',
        color: colores.escalaGrises.cien,
        marginRight: '35px'
    },
    ayuda: {
        marginRight: '20px',
        color: colores.principales.hover,
        '&:hover': {
            backgroundColor: colores.escalaGrises.quince,
            borderRadius: '60%',
            padding: '1px', 
        },
    },
    iconoTelefono:{
        marginRight: '10px',
        height: '24px'
    },
    iconoJA:{
        width: '190px',
        height: '72.2px', 
        cursor: 'pointer',
    },
    iconoJAPhone:{
        height:'40px',
        borderRadius: '6px',
        marginRight: '10px'
    },
    enlaceSinBorder:{
        outline: 'none',
    },

    // Elementos Franja Negra    
    gridContacto: {
        alignItems: 'self-center',
        gap: '25px' //añado una separación entre elementos
    },
    textoHeader: {
        fontFamily: tipografia.regular,
        fontSize: '12px',
        color: colores.escalaGrises.cero,
        cursor: 'pointer',
        background: 'transparent' ,
        border: 'none',
         margin: 0, 
        padding: 0,
        '&:hover':{
            textDecoration: 'underline',
            //outline: '1px solid ' + colores.escalaGrises.cero,

        },
        '&:focus':{
            outline: 'none' //'1px solid ' +colores.escalaGrises.cero,
        },
        
    },
    iconoRRSS:{
        height: '17px'
    },
    lista:{
        listStyleType: 'none'
    },
    elementoLista:{
        float: 'left'
    },
    iconoAyuda:{
        marginRight: '5px',
        color: colores.escalaGrises.cero,
        height: '15px', 
    },
    linkRrss:{
        marginRight: '15px',
        '&:hover':{
            outline: 'none', //'1px solid ' + colores.escalaGrises.cero, quito el recuadro blanco del botón de enlace
            filter: 'opacity(0.7)'

        },
        '&:focus':{
            outline: '1px solid ' +colores.escalaGrises.cero,
        }
    }, classGridTlf: {
        display: 'flex'
    }
});