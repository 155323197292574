import React, {useEffect, useState} from 'react'
import RestProvider from 'providers/RestProvider';
import {customStyle} from './RelojStyle'
import {properties} from './RelojData'
import {Grid} from '@material-ui/core';
import {months} from 'AppData';

export default function Reloj() {

    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const {crudOperation, crudOperationsType} = RestProvider();
    const [serverTime, setServerTime] = useState(null);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        getServerDateTime();
    }, []) ;

    useEffect(() => {
        if (!serverTime) return;

        let animationFrameId;
        const updateClock = () => {
            const now = new Date();
            const adjustedTime = new Date(now.getTime() + offset); 
            formatDateTime(adjustedTime);
            animationFrameId = requestAnimationFrame(updateClock);
        };

        updateClock(); 
        return () => cancelAnimationFrame(animationFrameId); 
    }, [serverTime]);

    /*
    * Recuperar la hora del servidor
    */
    const getServerDateTime = async () => {
        try {
            const response = await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE, { url: "", method: "get" });
            const serverDate = parseServerDate(response.data);
            setServerTime(serverDate);
            setOffset(serverDate - new Date()); // Calcula la diferencia entre servidor y cliente
        } catch (error) {
            console.error("Error obteniendo la hora del servidor", error);
        }
    }

    const parseServerDate = (fechaString) => {
        const [timePart, datePart] = fechaString.split(properties.DATE_TIME_SEPARATOR);
        const [hours, minutes, seconds] = timePart.split(properties.TIME_SEPARATOR);
        const [day, month, year] = datePart.split(properties.DATE_SEPARATOR);
        return new Date(year, month - 1, day, hours, minutes, seconds);
    };


    const formatDateTime = (dateTime) => {
        setTime(
            `${("0" + dateTime.getHours()).slice(-2)}:${("0" + dateTime.getMinutes()).slice(-2)}:${("0" + dateTime.getSeconds()).slice(-2)}`
        );
        setDate(
            `${("0" + dateTime.getDate()).slice(-2)} ${months[dateTime.getMonth()]} ${dateTime.getFullYear()}`
        );
    };

    return (
        <Grid container className={customStyle().gridFecha} direction='row' alignItems='center'>
            <Grid item>
                <span className={customStyle().diaTexto}>{date}</span>
            </Grid>
            <Grid item>
                <span className={customStyle().horaTexto}>{time}</span>
            </Grid>    
        </Grid>
    )
}