import React from "react";

/*
* Contexto que engloba Header, Main y Footer
*/
export default React.createContext({
  titlePage: {},
  updateTitlePage: (newTitle) => {},
  sesion: '',
  updateSesion: (estado) => {}, //inicio, cierre, caducada
});