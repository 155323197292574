import {makeStyles} from '@material-ui/core/styles'
import {estilosTextosPrincipales, colores, backgroundRectangle, links} from '../../../AppStyle'

export const customStyle = makeStyles ({
    pageRectangle:{
        width: '75%',
        height: 'auto',
        margin: 'auto',
        marginTop: '2%',
        marginBottom: '5%',
        backgroundColor: colores.escalaGrises.cero,
        border: '2px',
        borderStyle: 'solid',
        borderColor: colores.principales.apoyo2,
        boxSizing: 'border-box',
        marginRight: '394px',
        padding: '20px 0',
        textIndent: '42px',
    },
    backgroundRectangle:{
        ...backgroundRectangle
    },
    pageTitle:{
        color: colores.principales.hover,
        fontWeight: 'normal',
        textDecoration: 'none'
    },
    gridPageTitle:{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2%',
        width: '90%',
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.cien,
    },
    gridSearchBar:{
        marginRight: '5%',
        marginLeft: '5%',
        marginTop: '2%',
        alignSelf: 'center'
    },
    classLine1: {
        boxSizing: 'border-box',
        height: '1px',
        width: '90%',
        border: '1px',
        borderStyle: 'solid',
        borderColor: colores.escalaGrises.veinticinco,
        marginTop: '13px',
        marginBottom: '23px'
    },
    classBackdrop: {
      zIndex: 1,
      color: colores.principales.blanco,
    },
    gridTableResponsivePager:{
        marginTop: '6.5%',
        marginBottom: '6.5%',
        width: '80%',
        ...estilosTextosPrincipales.body1        
    },
    classTitleH2: {
        ...estilosTextosPrincipales.titulosPagina,
    },
    classTitleH3: {
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien
    },
    classTitleH5: {
        ...estilosTextosPrincipales.encabezadoH5,
        color: colores.escalaGrises.cien
    },
    classLink: {
        ...links
    }, classGridContactos: {
        margin: '1% 0'
    },
    classGridContainerContactos: {
        width: '90%',
        margin: '1% 0'
    },
    classUl: {
        margin: '1% 0'
    }
});
