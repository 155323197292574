import React, { useState } from 'react';
import RestProvider from 'providers/RestProvider';
import { properties as appProperties } from '../../../../AppData';
import FormioProvider from '../../../../providers/FormioProvider';

/**
 * @function useFunctions Función que devuelve las funciones y hooks necesarias para el componente
 * @returns {value, options, isPhoneVersion, handleChange}
 */
const useFunctions = () => {
    /***** Objeto de datos *****/
    const msgError = {
        severity: 'Error',
        error: 'Ha ocurrido un error al intentar recuperar los datos.'
    }

    /***** HOOKS *****/
    const [value, setValue] = useState('');
    const [options, setOptions] = useState();
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const { crudOperation, crudOperationsType } = RestProvider();
    const { formioOperation, checkAppToken } = FormioProvider();
    const [message, setMessage] = useState({ msg: '', severity: '' });
    let _ID = 0;
    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const [alerts, setAlerts] = useState([]);
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    /**** Variables para el CRUD de form.io ****/
    let formMaxAmostrar = 1000; //Contiene el número máximo de formularios que se va a mostrar

    /**
     * @function updateFormat Actualiza el formato de la página
     */
    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    };

    /**
     * @function handleChange Función que actualiza el valor del componente
     */
    const handleChange = (e) => {
        setValue(e.target.value);
    };

    /***** FUNCIONES AUXILIARES *****/

    /**
     * @function checkController Función que comprueba si la cadena contiene el caracter / y en caso de que lo contenga, lo elimina
     * @param {*} controller controlador recibido
     * @returns Devuelve el controlador sin la / en caso de que la tuviera
     */
    const checkController = (controller) => {
        if (controller.includes('/')) {
            controller = controller.substr(controller.indexOf('/') + 1, controller.length);
        }
        return controller;
    }

    /**
     * @function checkUrlEndPoint Función que comprueba si la cadena contiene el caracter / y en caso de que no lo contenga, lo añade
     * @param {*} url url recibida
     * @return Devuelve la url del endPoint con la / en la primera posicion de la cadena en caso de que lo la tuviera
     */
    const checkUrlendPoint = (url) => {
        if (!url.includes('/')) {
            url = `/${url}`;
        }
        return url;
    }

    /**
     * @function logicaCrudOperations Función que contiene la lógica del crudOperations
     * @param {*} response Valor recibido desde crudoperations
     */
    const logicaCrudOperations = (response) => {
        //Variables que contienen las posibles opciones a insertar en el Hook setOptions
        let valorObtenido = response.data; //Se inicializa con el valores response data.
        let valores = response.data.valores;
        let valoresCombo = response.data.valoresCombo;
        let formName = response.data.name;
        //Array que contiene los posibles nodos de response.data que puedan contener valores
        let nodos = [valores, valoresCombo, formName];
        //Paso 1. Se recorre con un bucle forof el array de nodos en busca de que contengan valores.
        for (const valoresNodo of nodos) {
            //Paso 2. Si algún nodo contiene valores se sobreescriben en la variable valorObtenido
            if (valoresNodo !== undefined) {
                valorObtenido = valoresNodo;
                break;
            }
        }
        //Paso 3. Se insertan los valores obtenidos
        setOptions(valorObtenido);
    }

    /**
     * @function getOptionsFromEndPoint Función que obtiene las opciones del selector a travé-s del endPoint pasado por parámetros
     * @param {*} controller Url del controlador
     * @param {*} url Url del endPoint
     */
    const getOptionsFromEndPoint = (controller, url) => {
        //Paso 1. Se comprueba que los enlaces al microservico esten bien compuestos
        controller = checkController(controller);
        url === undefined ? (url = '') : (url = checkUrlendPoint(url));
        //Paso 2. Se realiza la llamada al endPoint
        //Paso 2.1. Se comprueba previamente si se trata de una llamada a la API de form.io
        (!controller.includes('form')) ?
            crudOperation(crudOperationsType.CUSTOM, controller, { url: url, method: 'get' }).then(response => {
                logicaCrudOperations(response);
            }).catch(function (error) {
                handleSetMessage(msgError.error, msgError.severity);
            })
            :
            formioOperation(controller, { method: 'get', url: '', urlParams: { limit: formMaxAmostrar } }, true).then(response => {
                logicaCrudOperations(response);
            }).catch(function (error) {
                handleSetMessage(msgError.error, msgError.severity);
            });

    };
    return { value, options, isPhoneVersion, updateFormat, handleChange, getOptionsFromEndPoint };
}

export default useFunctions;