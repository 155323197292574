import React, { useState, useEffect, useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { TextField, ThemeProvider, Typography } from '@material-ui/core';
import { getContextFromUrl } from 'config/Config';
import { Grid, CircularProgress, Backdrop } from '@material-ui/core';
import { properties, texts, tableHeader, keysTablaGenerica, paths } from './DescargarDocumentosData';
import { compProperties, properties as appProperties } from './../../../AppData';
import customStyle from './DescargarDocumentosStyle';
import TablaGenerica from 'components/commons/tablaGenerica/tablaGenerica';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import ButtonVisualPdf from '../../commons/visualizadorPdf/VisualizadorPdf';
import RestProvider from 'providers/RestProvider';
import { overrideStyleF } from 'components/commons/searchBar/SearchBarStyle';
import AppContext from 'AppContext';

export default function DescargarDocumentos() {

    const { crudOperation, crudOperationsType } = RestProvider();
    let style = customStyle();

    // HOOKS
    const context = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isPrevisualizarLoading, setIsPrevisualizarLoading] = useState(false);
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [documentoHCV, setDocumentoHCV] = useState([]);
    const [codCSV, setCodCSV] = useState(null);
    const [documentoCompleto, setDocumentoCompleto] = useState(null);
    const [isDocumentoHelp, setIsDocumentoHelp] = useState(false);

    // TÍTULO PÁGINA
    useEffect(() => {
        obtenerTituloPagina();
    }, []);

    const obtenerTituloPagina = () => {
        context.updateTitlePage(texts.TEXT_TITULO_PAGINA);
    };

    // LLAMADAS
    const buscarDocumento = async () => {
        setIsLoading(true);
        const urlCompleta = `${properties.URL_GET_DOCUMENTO}/${codCSV}`;
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE, { url: urlCompleta, method: 'get' })
            .then(response => {
                const resDocumento = response.data;
                setIsDocumentoHelp(false);
                setDocumentoCompleto(resDocumento);
                setIsLoading(false);
            })
            .catch(function (error) {
                descargarDocumentoHelp();
            });
    };

    const descargarDocumentoHelp = async () => {
        setIsLoading(true)
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_HELP, { url: `/${codCSV}`, method: 'get', responseType: 'blob' })
            .then((res) => {
                // Descarga del documento
                const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'documento.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setIsDocumentoHelp(true);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsDocumentoHelp(false);
                setIsLoading(false);
            });
    }
    
    const descargarDocumento = async () => {
        setIsLoading(true)
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_HELP, { url: `/${documentoCompleto.hcv}`, method: 'get', responseType: 'blob' })
            .then((res) => {
                // Descarga del documento
                const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentoCompleto.nombre);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (documentoCompleto) {
            const documentoHCV = {
                nombreDoc: documentoCompleto.nombre,
                fechaAlta: documentoCompleto.fechaIncorporacion,
                estado: documentoCompleto.estadoFirma,
                columnAcciones: acciones
            };
            setDocumentoHCV([documentoHCV]);
        }
    }, [documentoCompleto]);

    // ACCIONES Y MÉTODOS DE ACCIONES
    const acciones = [
        {
            img: `${getContextFromUrl()}` + 'fileRegular.svg',
            funcion: () => descargarDocumento(),
            titulo: "Descargar documento"
        },
        {
            img: `${getContextFromUrl()}` + 'download.png',
            funcion: () => descargarInformeFirma(),
            titulo: "Descargar informe de firma"
        },
    ];

    const descargarInformeFirma = () => {
        setIsLoading(true)
        crudOperation(crudOperationsType.CUSTOM, 'portafirmas',
            { url: `idDocArco/${documentoCompleto.idEniDocumento}/nameFile/${documentoCompleto.nombre}`, method: 'get', responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentoCompleto.nombre);
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }


// OTROS MÉTODOS COMO EL PREVISUALIZADOR Y HANDLE DE DATOS
const handleTextDocumento = (value) => {
    setCodCSV(value.target.value);
};


return (
    <Grid>
        <Grid container direction={isPhoneVersion ? 'column-reverse' : 'row'} justify='space-between'>
            <Grid item className={style.gridPageTitle}>
                <h1 id="tituloPagina" className={style.pageTitle}>{texts.PAGE_TITLE}</h1>
            </Grid>
            <Grid className={style.gridSearchBar}>
                <ThemeProvider theme={overrideStyleF(isPhoneVersion)}>
                    <Grid container alignItems='flex-end'>
                        <Grid item>
                            <TextField className={isPhoneVersion ? style.searchTextFieldPhone : style.searchTextField}
                                placeholder='Introduzca el CSV'
                                onChange={handleTextDocumento}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                disableRipple
                                id={texts.idButton}
                                className={isPhoneVersion ? style.searchButtonPhone : style.searchButton}
                                onClick={buscarDocumento}>
                                <img className={isPhoneVersion ? style.searchImgPhone : style.searchImg}
                                    src={paths.searchIconPath}
                                    alt={texts.searchIconAlt} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </Grid>
        </Grid>

        <div className={style.backgroundRectangle}>
            <Grid>
                {isDocumentoHelp ? (
                    <Grid className={style.msgDocHelp}>
                        <Typography component='p'>{texts.DOCUMENTO_HELP_ENCONTRADO}</Typography>
                    </Grid>
                ) : (
                    <Grid className={style.contenedorTabla}>
                        <Backdrop open={isLoading} className={style.classBackdrop}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <TablaGenerica headingColumns={tableHeader} tableData={documentoHCV} isPhoneVersion={isPhoneVersion}
                            messageNoData={texts.TEXT_NO_DATOS_DOCUMENTOS} {...keysTablaGenerica} />
                    </Grid>
                )}
            </Grid>
        </div>
        <Backdrop open={isPrevisualizarLoading} className={style.classBackdropFullScreen}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Grid>
);
}
