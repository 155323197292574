import React, { useEffect } from 'react';
import { Grid, ThemeProvider } from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import useStyles from './SubirArchivoStyle';
import { texts } from './SubirArchivoData';
import { AttachFile } from '@material-ui/icons';
import useOperations from './SubirArchivoFunction';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


export default function SubirArchivo(props) {
    const { updateFormat, isPhoneVersion, cortarTexto, mostrarTextoEntero, mostrarTexto } = useOperations();
    const { customStyle, theme } = useStyles(isPhoneVersion);
    const style = customStyle();
    const imageMaxSize = props.tamMaximoArchivo * 1000000 //Bytes

    useEffect(() => {
        updateFormat();
        return () => window.removeEventListener('resize', updateFormat);
    }, []);

    window.addEventListener('resize', updateFormat);

    return (
        <Grid className={style.clasGridContenedor}>
            <ThemeProvider theme={theme}>
                {mostrarTexto ? (
                    <Grid className={style.classDivIcon} onClick={mostrarTextoEntero}>
                        <KeyboardArrowDownIcon className={style.classIcon} />
                        <p title={props.texto} className={style.classTexto} >{props.texto}</p>
                    </Grid>
                            ) : (  
                    <Grid className={style.classDivIcon} onClick={mostrarTextoEntero}>
                        <KeyboardArrowRightIcon className={style.classIcon}/>   
                        <p title={props.texto} className={style.classTexto} onClick={mostrarTextoEntero}>{cortarTexto(props.texto)}</p>
                    </Grid>   
                )}
            <Grid>
                <Grid className={style.classGridImg}>
                    <img src={props.img} className={style.classImg}/>
                    <p className={style.classParrafo}>{props.mostrarBotonEliminar ? texts.TAM : texts.MAX}{props.mostrarBotonEliminar ? props.tamDoc : props.tamMaximoArchivo}{texts.PESO}</p>
                </Grid>
                {!props.mostrarBotonEliminar &&
                    <DropzoneAreaBase
                    Icon={AttachFile}
                    acceptedFiles={props.formato}
                    dropzoneText={texts.TEXTO_DROP}
                    filesLimit={1}
                    onAdd={props.handleOnDrop}
                    maxFileSize={imageMaxSize}
                    onDropRejected={props.handleRejection}
                    showAlerts={[]}
                    />
                }
                {props.mostrarBotonEliminar &&
                    <button className={style.classButtonCancelar} name={props.id} onClick={props.bottonFunction}>{texts.ELIMINAR}</button>
                }
                
            </Grid>
            </ThemeProvider>
        </Grid>
        
    )
}