import { getContextFromUrl } from 'config/Config';

export const properties = {
    ID_PAGE_TITLE: 'tituloPagina',
    RESOURCE: 'administrador',
    RESOURCE_PCT: 'confAnexoGlobal',
    RESOURCE_EXPEDIENTE: 'gestion-expediente',
    URL: '/getInfoAnexos',
    URL_PCT: '/getInfoAnexos',
    URL_EXPEDIENTE: '/getExpedienteByNumeroExpediente/',
    TMP_ID_PLANTILLA: 999998,
    FASE: 'INICIO',
    SEVERITY: 'Error',
    INFO:'Info',
    CRUD_TYPE_POST: 'post',
    CRUD_TYPE_GET: 'get',
    TIPO_VOLUNTARIO: 'Voluntario',
    TIPO_OBLIGATORIO: 'Obligatorio',
    TIPO_OPTATIVO: 'Optativo',
    NO: 'NO',
    ESTADO_ANEXO: 'Anexado',
    RUTA_IMG_BORRAR: `${getContextFromUrl()}` + 'trash-2.png',
    TITULO_ELIMINAR: 'Eliminar anexo',
    TITULO_LUPA: 'Visualizar anexo',
    PATH_IMG_PDF: `${getContextFromUrl()}` + 'pdfGris.png',
    RUTA_IMG_LUPA: `${getContextFromUrl()}` + 'eye-regular.svg',
    RUTA_IMG_MAX_NUM_ANEXO: `${getContextFromUrl()}` + 'maxNumAnexo.svg',
    RUTA_IMG_MAX_PESO: `${getContextFromUrl()}` + 'maxPeso.svg',
    OPCION_BTNS_POPUP: 'confirSoloAceptar',
    TIPO_PDF: 'application/pdf',
    URL_CREATE_NUEVA_SOLICITUD: '/nuevaSolicitud',
    NUM_BOTONERA: '3',
    UN_MEGABYTE_EN_BYTE : 1000000,
    NUMERO_DECIMALES: 2,
    MOSTRAR_ELIMINAR: 'mostrarEliminar',
    ELEMENTO_DESCARGA: 'a',
    TAM_DOC_SUB: 'tamDocSubido',
    QUERY_PADRE_BTN_VOL: '#botonera > div',
    QUERY_BTN_VOL: '#btnVoluntarios',
    RESOURCE_ANEXOS: 'anexos',
    URL_GUARDAR_DOCS: '/guardarDocumentos',
    URL_ALMACENAR_DOC: '/almacenarAnexo',
    URL_OBTENER_ANEXOS: '/obtenerAnexos',
    F2: 'F2',
    SUB: 'SUBV',
    codigoAplicacionF2: 'PRESENTA_FASE2_AS',
    codigoAplicacionSUB: 'PRESENTA_SUB_AS',
    URL_FIRMA_SOLICITUD: '/FirmaSolicitud',
    FASE_NUEVA_SOLICITUD: 'INICIO',
    FASE_PENDIENTE_DE_SUBSANAR: 'PENDIENTE DE SUBSANAR',
    FASE_PRESENTACION_ALEGACIONES: 'Presentación de alegaciones',
    FASE_PENDIENTE_JUSTIFICACION: 'PENDIENTE DE JUSTIFICAR',
    RESOURCE_ANEXO_PCT: 'documento',
    RESOURCE_TIPO_DOC: 'tipo-documento',
    RESOURCE_ELIMINAR_ANEXO_PCT: '/eliminarDoc/',
    RESOURCE_OBTENER_ANEXO_PCT: '/findDocAnexo/',
    RESOURCE_TIPO_DOC_CODIGO: '/codigo/',
    CODIGO_VOL: 'ANEXO',
}

export const texts = {
    PAGE_TITLE: 'Nueva solicitud de ',
    PAGE_TITLE_SUBSANACION: 'Nueva subsanación de ',
    PAGE_TITLE_JUSTIFICACION: 'Nueva justificación de ',
    PAGE_TITLE_ALEGACION: 'Nueva alegación de ',
    PAGE_TITLE_MOD: 'Modificación de solicitud de ',
    PAGE_TITLE_SUBSANACION_MOD: 'Modificación de subsanación de ',
    PAGE_TITLE_JUSTIFICACION_MOD: 'Modificación de justificación de ',
    PAGE_TITLE_ALEGACION_MOD: 'Modificación de alegación de ',
    BUTTON_PREV: 'ATRÁS',
    BUTTON_NEXT: 'SIGUIENTE',
    BUTTON_SEND: 'ENVIAR',
    DOCUMENTACION: 'Documentación',
    NUM_DOCUMENTOS_ANEXAR: 'Maximo de documentos a anexar',
    PESO_DOCUMENTOS_ANEXAR: 'Peso Maximo de documentos a anexar',
    ARCHIVOS: 'Archivos',
    SELECCIONAR_ARCHIVOS: 'Selecciona los archivos a adjuntar',
    MIN_DOCUMENTOS: 0,
    MAX_DOCUMENTOS: 5,
    ERROR: 'Ha ocurrido un error inesperado',
    TEXT_BOTON_LISTO: 'LISTO',
    TEXT_BOTON_ATRAS: 'ATRÁS',
    TEXT_BOTON_IR_PASO3: 'FINALIZAR E IR A PASO 3',
    TITULO_CONFIRMARPESOSOBREPASADO: "No es posible anexar el documento.",
    DESCRIPCION_CONFIRMARPESOSOBREPASADO_1: "Con la inclusión de este nuevo anexo ha superado el ",
    DESCRIPCION_CONFIRMARPESOSOBREPASADO_2: ". Por favor, seleccione otro documento con menor tamaño.",
    COMILLAS: "'",
    TEXTO_ERROR_DROP_REJECTED1: "Tenga en cuenta que el documento deber ser un PDF con un tamaño máximo de ",
    TEXTO_ERROR_DROP_REJECTED2: " MB. Por favor, seleccione otro documento que cumpla con las condiciones indicadas.",
    TEXTO_NOEXISTEN_ANEXOS_OBLIGATORIOS: "No es necesario incluir anexos obligatorios",
    TEXTO_NOEXISTEN_ANEXOS_OPTATIVOS: "No es necesario incluir anexos optativos",
    PDF: "pdf",
    MESSAGE_NO_HAY_ANEXOS: 'No hay anexos que añadir',
    BTN_GUARDAR: 'Guardar',
    AVISO_GUARDADO: 'Aviso de guardado de anexos',
    MESSAGE_GUARDADO_SIN_ANEXOS: 'Para poder realizar el guardado de los anexos, debe adjuntar al menos uno.'
}

export const msgError = {
    error: 'Ha ocurrido un error al intentar guardar los documentos',
    severity: 'Error',
}

export const buttons = [
    { id: 'btnObligatorios', name: 'btnObligatorios', textButton: 'Documentación' }
];
export const buttonOptativo = { id: 'btnOptativos', name: 'btnOptativos', textButton: 'Optativos' }

export const buttonVoluntario = { id: 'btnVoluntarios', name: 'btnVoluntarios', textButton: 'Otra Documentación Voluntaria' };

export const formato = ['.pdf'];

export const objAttrib = {
    gridBotonera: {
        gridId: 'botonera',
        gridName: 'botonera'
    },
    buttons: {
        idButton: 'id',
        nameButton: 'name',
        textButton: 'textButton'
    },
    buttonGuardar: {
        id: 'btnGuardarAnexo',
        name: 'btnGuardarAnexo'
    } 
}

export const mockInfoAnexos = {
    tamMaximoTotal: 1000.0,
    permiteVoluntarios: true,
    tamMaxPorAnexoVoluntario: 80.0,
    listAnexosObligatorios: [
        {
            nombreAnexo: "DNI",
            descripcionAnexo: "Documento Nacional de Identidad del interesado",
            ordenAnexo: 10,
            tamanoAnexo: 250.0,
            obligatorioAnexo: "si",
            condicionesAnexo: "",
            faseAnexo: "Inicio",
        },
        {
            nombreAnexo: "Seguridad Social",
            descripcionAnexo: "Seguridad Social",
            ordenAnexo: 20,
            tamanoAnexo: 250.0,
            obligatorioAnexo: "si",
            condicionesAnexo: "",
            faseAnexo: "Inicio"
        }
    ],
    listAnexosOptativos: [
        {
            nombreAnexo: "Pasaporte",
            descripcionAnexo: "Pasaporte del interesado",
            ordenAnexo: 20,
            tamanoAnexo: 250.0,
            obligatorioAnexo: "no",
            condicionesAnexo: "",
            faseAnexo: "Inicio"
        }
    ]
}

export const tableHeader = [

    {
        titulo: 'NOMBRE',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'columnNombre',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'ESTADO',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'columnEstado',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'TIPO',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'columnTipo',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'ACCIONES',
        esBotonera: true,
        funcionOrdenacion: null,
        id: 'columnAcciones',
        estaOrdenado: null,
        ordenadoAsc: null
    }
]

export const mockTableData = [

    {
        nombre: 'Copia del DNI',
        estado: 'Anexado',
        tipo: 'Obligatorio',
        acciones: [
            {
                img: '/trash-2.png',
                funcion: null,
                titulo: "Eliminar anexo",
            }
        ]
    },
    {
        nombre: 'Seguridad Social',
        estado: 'Anexado',
        tipo: 'Obligatorio',
        acciones: [
            {
                img: '/trash-2.png',
                funcion: null,
                titulo: "Eliminar anexo",
            }
        ]
    },
    {
        nombre: 'Documento anexado',
        estado: 'Anexado',
        tipo: 'Optativo',
        acciones: [
            {
                img: '/trash-2.png',
                funcion: null,
                titulo: "Eliminar anexo",
            }
        ]
    }
]

export const keysTablaGenerica = {
    urlImg: 'img',
    keyTitulo: 'titulo',
    onClickFunction: 'funcion'
}


export const constantesEstadosFasePCT3 = {
    F_INICIO_OV3: null,
    F_SUBSANACION_OV3: null,
    F_ALEGACIONES_OV3: null,
    F_JUSTIFICACION_OV3: null
};