/**
 * @constant objAtributes contiene un objeto con todos los atributos del componente.
 */
 export const objAtributes = {
    container: {
        id: 'containerMenuMiCuenta',
    },
    grid: {
        id: 'gridMenuCuenta',
    },
    gridMenuInicial: {
        id: 'gridCuenta',
    },
    menu: {
        id: 'menuCuenta',
    },
    opcMenu: {
        opc1: {
            id: 'opc-misTramites'
        },
        opc2: {
            id: 'opc-misDatosPersonales',        },
    },
    iconButton: {
        'aria-label': 'Menú',
        'aria-controls': 'menuUsuario',
        'aria-haspopup': 'true',
    },    
    //////////////////////////VERSIÓN MÓVIL
    h3: {
        id:'mensajeBienvenida',
    },
    h1: {
        id:'nombreUsuadio',
    },
    buttonOpcTramites: {
        id: 'tramites',
        name: 'reamites',
        type: 'submit',
    },
    buttonOpcDatosPersonales: {
        id: 'datosPersonales',
        name: 'datosPersonales',
        type: 'submit',
    },
    conexion: {
        id: 'datosConexion',
    },
};

/**
 * @constant objText contiene los textos necesarios para el componente.
 */
export const objText = {
    BOTON_CABECERA: 'MI CUENTA',
    BOTON_CUENTACONTENIDO: 'Métodos de acceso',
    LOGINCLAVE: 'Login con Cl@ve',
    LOGINCERTIFICADO: 'Login con certificado',
    /////////////////VERSIÓN MOVIL
    conexion: 'Última conexión',
    mensajeBienvenida: 'Bienvenido/a',
};

export const properties = {
    RESOURCE: 'clave',
    RESOURCE_LOCAL: 'SpringSAMLIntegrationExample',
    URL_LOGIN: 'login',
    RESOURCE_INTERESADO_ENTIDAD:'operacionesInteresado',
    URL_CARGA_PANTALLA_INTERESADO: 'cargaPantallaInteresado',
    RESOURCE_SALTO_BANDEJA:'logInBandeja',
    ID_BUTTON: 'idButtonMiCuenta',
    ALABEL_BUTTON :'Menú mi cuenta',
    ARIA_EXPANDED :'ariaExpanded'
}

