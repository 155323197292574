import React, { useEffect } from 'react';
import { Grid, Container } from '@material-ui/core';
//Estilo importado
import Style, { overAcordeon } from './mensajesAyudaStyle';
//Datos importados
import { objAttb, objText} from './mensajesAyudaData';
//Componente Acordeon importado
import Acordeon from '../../commons/acordeon/acordeon';
//Funciones importadas
import UseStyleResponsive from './mensajesAyudaFunctions';

/**
 * @function mensajesAyuda contiene los mensajes de ayuda que se mostrarán al usuario
 */
function MensajesAyuda(props) {

    const { isPhoneVersion, updateFormat, loadInfoCloud, nombreConsejeria } = UseStyleResponsive();
    const classContainerDesk = Style().classContainer;
    const classContainerMov = Style().classContainerMovil;
    const classGridAcordeonDesk = Style().classGridAcordeon;
    const classGridAcordeonMov = Style().classGridAcordeonMovil;
    const classGridCabeceraTextoDesk = Style().classGridCabeceraTexto;
    const classGridCabeceraTextoMov = Style().classGridCabeceraTextoMovil;

    useEffect (() => {
        updateFormat();
        loadInfoCloud();
    }, []);

    const texto = props.textoTituloPopUp.cabecera.parrafo === objText.cabecera.parrafo ? props.textoTituloPopUp.cabecera.parrafo + '' + nombreConsejeria : props.textoTituloPopUp.cabecera.parrafo;

    const renderAcordeons = () => {
        return props.preguntas && props.preguntas.map((pregunta)=>{
            return  (
                <Acordeon
                theme={overAcordeon}
                classesGridAcordeon={isPhoneVersion ? classGridAcordeonMov : classGridAcordeonDesk}
                classes={Style()}
                attbGridsAcordeon={objAttb.grids.acordeon}
                icon={objAttb.acordeon.expandIcon}
                id={pregunta.descripcion.id}
                descripcion={pregunta.descripcion}
                messages={pregunta.mensajes}
                />
            );
        });
    }

    window.addEventListener('resize', updateFormat);

    return (
        ///////////////////////CONTENEDOR DE LA PANTALLA
        <Container className={isPhoneVersion ? classContainerMov : classContainerDesk} {...objAttb.container}>
            {/*///////////////////////CABECERA*/}
            <Grid className={Style().classGridCabecera} container {...objAttb.grids.cabecera}>
                <button {...objAttb.button} className={Style().classButton} onClick={props.handleChange}>
                    {objAttb.icon}{props.textoTituloPopUp.button}
                </button>
                {/*///////////////////////CABECERA TEXTO*/}
                <Grid className={isPhoneVersion ? classGridCabeceraTextoMov : classGridCabeceraTextoDesk} container {...objAttb.grids.cabeceraTexto}>
                    <h2 className={Style().classTitleEncabezado}>{props.textoTituloPopUp.cabecera.titulo}</h2>
                    <p className={Style().classParrafEncabezado}>{texto}</p>
                </Grid>
            </Grid>
            
            {renderAcordeons()}
        </Container>
    );
}

export default MensajesAyuda;