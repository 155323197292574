import React from 'react';
import {Table, TableContainer, TableHead, TableCell, TableBody, TableRow, ThemeProvider} from '@material-ui/core';
import { style, overrideStyle } from './tablaGenerciaStyle';
import { Link } from 'react-router-dom';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {properties} from './tablaGenericaData'

const TablaGenerica = (props) => {

    const formatTitle = (title) => {
        return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
    };

    const customStyle = style();
    const clase = style().classTablaResponsiveImpar;
    const claseAlterna = style().classTablaResponsivePar;
    const claseTablaGeneral = style().classTablaResponsiveGeneral;
    const claseCeldaCabecera = style().classCeldaCabeceraResponsive;
    const claseCeldaNombreResponsive = style().classCeldaNombreResponsive;
    const claseCeldaCampos = style().classCeldaCamposResponsive;
    const claseBarraSeparacion = style().classBarraSeparacion;

    const isEmptyDataTabla = props.tableData.length == '0';

    /*Funcion qie se encarga de dibujar las celdas*/
    const renderCell = (mainFilaList, claseParoImpar, i, identificador) => {
        return (
            <div className={claseParoImpar}>            
                <TableContainer className={claseTablaGeneral}>
                        <Table>
                             <TableRow>
                                <TableCell className={claseCeldaCabecera}>{mainFilaList.key}</TableCell>
                                {mainFilaList.boton ? 
                                    <TableCell align="left">
                                        {mainFilaList.val.map((post) =>
                                            post[props.onClickFunction] != null ? 
                                                <Link onClick={(e) => {post[props.onClickFunction](e, identificador)}} className={customStyle.classIcons} title={formatTitle(post[props.keyTitulo])}>                                                    
                                                    <img src={post[props.urlImg]} className={customStyle.classImgIconResponsive}/>
                                                </Link> :
                                                <Link to={post.redirect} className={customStyle.classIcons} title={formatTitle(post[props.keyTitulo])}>
                                                    <img src={post[props.urlImg]} className={customStyle.classImgIconResponsive}/>
                                                </Link>
                                        )}
                                    </TableCell>
                                    : <TableCell className={i == '0' ? claseCeldaNombreResponsive : claseCeldaCampos}>{mainFilaList.val}</TableCell> 
                                }
                                                                                                 
                            </TableRow>   
                        </Table> 
                </TableContainer>
            </div>
        )
    }

    const conversionDataTablaResponsive = props.tableData.map((row, index) => {    

        let rowData = [];
        let i = 0;
        const tablaResponsive = [];
        let identificador = null;

        for (const key in row) {
            let clave = key.toUpperCase();
            if(clave !== properties.identificador){
                rowData.push({
                    key: props.headingColumns[i].titulo,
                    val: row[key],
                    boton: props.headingColumns[i].esBotonera,
                });
                i++;
            }else{
                identificador = row[key];
            } 
        }

        let claseCss = clase;
            if( index % 2 != 0){
                claseCss = claseAlterna;
            }

        for (let i = 0; i < rowData.length; i++) {            

            tablaResponsive.push(renderCell(rowData[i], claseCss, i, identificador));
        }
        return (
            <div className={claseBarraSeparacion}>
                {tablaResponsive}
            </div> 
        )
        
    }); 


    const renderMessageEmptyResponsive = () =>{
        
        return <div className={customStyle.classDivNoDataResponsive}>
            <p>{props.messageNoData}</p>
        </div>

    }

    const renderTableResponsive = () =>{
        
        return <div>
            {isEmptyDataTabla ? renderMessageEmptyResponsive() : conversionDataTablaResponsive}
        </div>

    }    

    //Rrecorro los datos de la tabla y por cada registro lo añado a un array para pintar fila a fila
    const data = props.tableData.map((row, index) => {
        
        let rowData = [];
        let i = 0;
        let identificador = null;

        for (const key in row) {
            let clave = key.toUpperCase();
            if(clave !== properties.identificador){
                rowData.push({
                    key: props.headingColumns[i].titulo,
                    val: row[key],
                    boton: props.headingColumns[i].esBotonera,
                });
                i++; 
            }else{
                identificador = row[key];
            } 
        }

        /*Recorro el array creado para ir pintando en cada celda, si no es botonera pinto una celda normal 
        En caso de ser botonera(Acciones) recorro el array Acciones recibido para pintar cada uno de los botones
        si el boton tiene una funcion usamos el evento Onclick en caso de no serlo usamos un evento To para la redireccion*/
        return <TableRow key={index}>
            {rowData.map((dato, indice) =>
                dato.boton ? 
                <TableCell>
                    {dato.val.map((post) =>
    	            post[props.onClickFunction] != null ? 
                        <Link onClick={(e) => {post[props.onClickFunction](e, identificador)}} className={customStyle.classIcons} title={formatTitle(post[props.keyTitulo])}>
                            <img src={post[props.urlImg]} className={customStyle.classImgIcon}/>
                        </Link> :
                        <Link to={post.redirect} className={customStyle.classIcons} title={formatTitle(post[props.keyTitulo])}>
                            <img src={post[props.urlImg]} className={customStyle.classImgIcon}/>
                        </Link>

                )}
                </TableCell>
                    :
                    <TableCell key={indice} data-heading={dato.key} className={indice == '0' && customStyle.classCeldaNombre}>{dato.val}</TableCell>
            )}
        </TableRow>
    });

    const renderMessageEmpty = () =>{
        
        return <div className={customStyle.classDivNoData}>
            <p>{props.messageNoData}</p>
        </div>

    }
    
    const renderDesktop = () => { 
        return <div>
        <TableContainer>
            <Table>
                <ThemeProvider theme={overrideStyle}>
                    <TableHead className={customStyle.classthead}>
                        <TableRow>
                            {props.headingColumns.map((col, index) => (
                                <TableCell  onClick={col.funcionOrdenacion} id={col.id}>{col.titulo}
                                    {(() => {
                                        if (col.estaOrdenado && col.ordenadoAsc) {
                                            return (
                                                <ArrowUpwardIcon/>
                                              )
                                            
                                        } else if (col.estaOrdenado && !col.ordenadoAsc) {
                                            return (
                                                <ArrowDownwardIcon/>
                                              )
                                        } 
                                    })()}
                                </TableCell> 
                            ))}
                        </TableRow>
                    </TableHead>
                </ThemeProvider>
                <ThemeProvider theme={overrideStyle}>
                    <TableBody>
                        {!isEmptyDataTabla && data}
                    </TableBody>
                </ThemeProvider>
            </Table>
            {isEmptyDataTabla && renderMessageEmpty()}
        </TableContainer>
    </div> 
    }

    

    return (
        <div className={props.isPhoneVersion ? customStyle.classTableGenericResponsive : customStyle.classTableGeneric}>
            {props.isPhoneVersion ? (
                renderTableResponsive()
            ) : (
                renderDesktop()
            )}
            
        </div>
    )
}
export default TablaGenerica;