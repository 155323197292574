import { createMuiTheme } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles'
import {colores, tipografia, buttonSecundaryBig, buttonPrimaryBig, estilosTextosPrincipales} from '../../../AppStyle';

export const customStyle = makeStyles ({
    classIcons:{
        height: '50px', 
        display: 'inline-block',
        width: '100%',
        marginTop: '30px',
    },
    colAC:{
        width: '90%'
    },
    colIconoResponsive:{
        width: '40%'
    },
    colACResponsive:{
        width: '60%'
    }, 
    gridAC:{
        width: '100%'
    },
    tituloAC:{
        ...estilosTextosPrincipales.body1,
        marginLeft: '15px',
        marginBottom: '5px'
    },
    gridAvisoAC:{
        width: '100%',
        fontSize: '15px',
        marginLeft: '15px'
    },
    gridSeccionTitulo:{
        width:'100%',
        height: '55px',
        ...estilosTextosPrincipales.encabezadoH2
    },
    gridSeccionTituloResponsive:{
        width:'100%',
        height: '55px',
        ...estilosTextosPrincipales.encabezadoH2,
        marginBottom: '25px'
    },
    gridSeccionPasos:{
        marginTop: '3%',
        display: 'flex'
    },
    classA:{
        color: colores.principales.negro,
        display: 'inline',
        marginRight: '3%',
        ...estilosTextosPrincipales.body1
    },
    lineaHorizontal:{
        width: '100%',
        marginBottom: '15px'     
    },
    gridSeccionTres:{
        marginTop: '30px',
        marginBottom: '30px',
        width: '100%',
        height: '55px',
        justifyContent: 'center',
    },
    gridACIcono:{
        marginTop: '10px',
        width: 'fit-content',
        height: 'fit-content'
    },
    gridLineaAC:{
        width: '100%'
    },
    classBackdrop: {
        zIndex: 0
    },
    botonAceptar:{
        ...buttonPrimaryBig ,
        marginLeft: '15px'
    },
    botonCancelar:{
        ...buttonSecundaryBig,
        marginRight: '15px'
    },
    lineaHorizontalAvisos:{
        width: '95%',
        marginLeft: '15px',
        marginTop: '25px',
        marginBottom: '20px'   
    },
    classTituloH2: {
        ...estilosTextosPrincipales.encabezadoH2,
        color: colores.escalaGrises.cien
    },
    clssTituloH2Responsive: {        
        ...estilosTextosPrincipales.encabezadoH2,
        textAlign: 'center',
        color: colores.escalaGrises.cien
    },
    classParrafo: {
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.cien
    },
    alinearBotones: {
        display: 'inline-flex'
    }
});    

export const overAvisosCriticos = createMuiTheme({
    overrides: {
        MuiPopover: {
            root: {
                zIndex:'0',
                marginTop: '10%',
            }
        },
        MuiPaper:{
            root:{
                width: '45%',
                height: 'fit-content',
               
            }
        }
    }
});

export const overAvisosCriticosResponsive = createMuiTheme({
    overrides: {
        MuiPopover: {
            root: {
                zIndex:'0',
                marginTop: '10%',
            }
        },
        MuiPaper:{
            root:{
                width: '56%',
                height: 'fit-content',
            }
        }
    }
});
