import React, { useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, ThemeProvider } from '@material-ui/core';
import Input from './../input/Input';
import customizate, { style } from './DialogoStyle';

/**
 * @function Dialogo Componente cuadro de diálogo, compuesto por un input de tipo text y dos botones (cancelar y acción)
 * @param {*} props 
 * @returns 
 */
export default function Dialogo(props) {
    const customStyle = style();

    return (
        <ThemeProvider theme={customizate(props.isPhoneVersion)}>
            <Grid>
                <Dialog open={props.show}>
                    <DialogTitle className={customStyle.classTitulo}>{props.titulo}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={customStyle.classTexto}>{props.texto}</DialogContentText>
                        <Input
                            {...props.objAtributosInput}
                            classes={customStyle.classGrid}
                            classInput={customStyle.classInput}
                            value={props.valueInput}
                            onChange={props.onChangeInput}
                            inputRef={null}
                            placeholder={props.placeholderInput}
                        />
                    </DialogContent>
                    <DialogActions>
                        <button onClick={props.handleHide} className={customStyle.buttonCancelar}>{props.textBtnCancela}</button>
                        <button onClick={props.handleAccion} className={customStyle.buttonAccion}>{props.textBtnAccion}</button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </ThemeProvider>
    );
}