import React, {useEffect, useContext, useState}from 'react';
import FormNuevaSolicitud from '../../commons/FormNuevaSolicitud/FormNuevaSolicitud';
import AppContext from 'AppContext'
import {customStyle} from './NuevaSolicitudStyle';
import { properties, texts, constantesEstadosFasePCT3 } from './NuevaSolicitudData';
import { Grid, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import LineaMetro from 'components/commons/lineaMetro/lineaMetro';
import '../../../BootstrapWrapper.scss';
import {pasosNuevaSolicitud} from './../../../AppData';
import { Alert } from '@material-ui/lab';
import { Errors } from '@formio/react';
import LineaProgreso from 'components/commons/lineaProgreso/lineaProgreso';
import { loadState } from 'providers/localStorage';
import { localStorageName } from 'providers/localStorageData';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import { compProperties, properties as appProperties  } from './../../../AppData';
import useApp from 'AppFunctions';

export default function NuevaSolicitud() {
    const context = useContext(AppContext);
    const style = customStyle();
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const activeStep = 0;
    const [errors, setErrors] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [idExpedienteTrewa, setIdExpedienteTrewa] = useState();
    const [tituloPagina, setTituloPagina] = useState();
    const [errorPdf, setErrorPdf] = useState(false);
    const solicitudActual = loadState(localStorageName.solicitudActual, true);

    const { obtenerCodigosFaseEstado } = useApp();
  
     ///Hooks gestion msg errores
    let idAlert = 0;
    const [message, setMessage] = useState({ msg: '', severity: '' });
    const addAlert = msg => setAlerts([...alerts, { id: idAlert++, msg }]);
    const [alerts, setAlerts] = useState([]);
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    useEffect(() => {     
        obtenerFasesEstados();   
        let solAct = loadState(localStorageName.solicitudActual, true);
        if(solAct.edicion){
          context.updateTitlePage(texts.PAGE_TITLE_EDICION);
        }else if (solAct.subsanar){
          context.updateTitlePage(texts.PAGE_TITLE_SUBSANACION);
        }else if (solAct.alegar){
          context.updateTitlePage(texts.PAGE_TITLE_ALEGACION);
        }else{
          context.updateTitlePage(texts.PAGE_TITLE);
        }
        mostrarIdExpediente();  
        obtenerTituloPagina(); 
        updateFormat();
        return () => window.removeEventListener('resize', updateFormat);
    }, []);    

    const updateFormat = () => {
      if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
          setIsPhoneVersion(true);
      } else {
          setIsPhoneVersion(false);
      }
  }
    const mostrarIdExpediente = () => {
      let solicitudActual = loadState(localStorageName.solicitudActual, true);

      if(solicitudActual && solicitudActual.idExpediente){
        setIdExpedienteTrewa(`(${solicitudActual.idExpediente})`);
      } 
    }

    const updateIsLoading = (boolean) => { 
      setIsLoading(boolean);
    }

    const updateErrors = (value) => {
      setErrors(value);
    }
    const updateIsProcessing = (boolean) => {
      setIsProcessing(boolean);
    }

    const updateErrorPdf = (booelan) => {
      setErrorPdf(booelan);
    }

    const obtenerTituloPagina = () =>{
      let primeraParte;
      let procedimientoActual = loadState(localStorageName.procedimientoActual, true);    
      let fase = loadState(localStorageName.fase, true);
      let accionModificar = loadState(localStorageName.accionModificar, false);

      switch(fase){
        case properties.FASE_NUEVA_SOLICITUD || constantesEstadosFasePCT3.F_INICIO_OV3:
          let solActEdi = loadState(localStorageName.solicitudActual, true);
          if(solActEdi.edicion){
            primeraParte = texts.PAGE_TITLE_EDICION;
          }else if(solActEdi.subsanar){
            primeraParte = texts.PAGE_TITLE_SUBSANAR;
          }else if(solActEdi.alegar){
            primeraParte = texts.PAGE_TITLE_ALEGAR;
          }else{
            primeraParte = texts.PAGE_TITLE;
          }
          break;
        case  properties.FASE_PENDIENTE_DE_SUBSANAR || constantesEstadosFasePCT3.F_SUBSANACION_OV3:
          primeraParte = accionModificar ? texts.PAGE_TITLE_SUBSANACION_MOD : texts.PAGE_TITLE_SUBSANACION;
          break;
        case properties.FASE_PRESENTACION_ALEGACIONES || constantesEstadosFasePCT3.F_ALEGACIONES_OV3:
          primeraParte = accionModificar ? texts.PAGE_TITLE_ALEGACION_MOD : texts.PAGE_TITLE_ALEGACION;
          break;
        case properties.FASE_PENDIENTE_JUSTIFICACION || constantesEstadosFasePCT3.F_JUSTIFICACION_OV3:
          primeraParte = accionModificar ? texts.PAGE_TITLE_JUSTIFICACION_MOD : texts.PAGE_TITLE_JUSTIFICACION;
          break;
        default:
          let solActEdi1 = loadState(localStorageName.solicitudActual, true);
          if(solActEdi1.edicion){
            primeraParte = texts.PAGE_TITLE_EDICION;
          }else if(solActEdi1.subsanar){
            primeraParte = texts.PAGE_TITLE_SUBSANAR;
          }else if(solActEdi1.alegar){
            primeraParte = texts.PAGE_TITLE_ALEGAR;
          }else{
            primeraParte = texts.PAGE_TITLE;
          }
          break;
      }
      context.updateTitlePage(primeraParte + ((procedimientoActual.descripcion === undefined) ? procedimientoActual.nombre : procedimientoActual.descripcion));
      setTituloPagina(primeraParte + ((procedimientoActual.descripcion === undefined) ? procedimientoActual.nombre : procedimientoActual.descripcion));
    }

    /**
     * @function obtenerFasesEstados Función que obtiene los códigos de las fases y los estados buscados
     * @returns 
     */
    const obtenerFasesEstados = async () => {
      let constantes = [];

      let convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);

      // Paso 1. Obtiene las constantes decalradas en BandejaSolicitudesData y se insertan en el array cosntates.
      for (const key in constantesEstadosFasePCT3) {
          constantes.push(key);
      }

      // Paso 2. Se obtienen las fases y estados 
      let fasesEstadosObtenidos = await obtenerCodigosFaseEstado(convocatoria.id, constantes, constantes);        
      
      // Paso 3. Se recorren los valores obtenidos y se insertan en las constantes que se utilizaran en la pantalla
      for (const feo of fasesEstadosObtenidos.valores) {

        switch (feo.clave) {
          case 'F_INICIO_OV3':
            constantesEstadosFasePCT3.F_INICIO_OV3 = feo.valor;
            break;
          case 'F_ALEGACIONES_OV3':
            constantesEstadosFasePCT3.F_ALEGACIONES_OV3 = feo.valor;
            break;
          case 'F_SUBSANACION_OV3':
            constantesEstadosFasePCT3.F_SUBSANACION_OV3 = feo.valor;
            break;
          case 'F_JUSTIFICACION_OV3':
            constantesEstadosFasePCT3.F_JUSTIFICACION_OV3 = feo.valor;
            break;
          default:
            break;
        }
      }
    }

    return(
        <main>
          <Grid className={'formioSolicitud'}>
            <div className={style.gridPageTitle}>
              <Grid className={style.gridTitulo} >
                <h1 id={properties.ID_PAGE_TITLE} className={style.pageTitle}>{tituloPagina}</h1>
                <span className={style.idExpediente}>{solicitudActual.numeroExpediente}</span>
            
              </Grid>
                <LineaMetro pasos={pasosNuevaSolicitud} activeStep={activeStep}/>
            </div>            
            <div className={style.backgroundRectangle}>
                <Errors errors={errors} />
                <FormNuevaSolicitud handleLoading={updateIsLoading} handleSetMessage={handleSetMessage} handleError={updateErrors} handleProcessing={updateIsProcessing} handleErrorPdf={updateErrorPdf}/>                
            </div>
            </Grid>
            <Backdrop className={style.classBackdrop} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            {alerts.map(m => (
          <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
            <Alert
              severity={message.severity}
              variant="filled"
            >
              {message.msg}
            </Alert>
          </Snackbar>
        ))}
        {
          isProcessing &&  
            <LineaProgreso />
        }
        <Confirmacion
          accionConfirmacion={updateErrorPdf}
          titulo={texts.TITLE_ERROR_GENERAR_PDF}
          mostrar={errorPdf}
          isPhoneVersion={isPhoneVersion}
          mensaje={texts.MSG_ERROR_GENERAR_PDF}
          botonera={compProperties.CONFIR_SOLO_ACEPTAR}
          quitarImg={false}
          tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
          isPopUpPdf={false}
          textBotonConfirmarPersonalizado={false}
          isImagenPersonalizada={true}
          centrarContenido={true}
          imagen={properties.IMAGEN_ERROR}
          isTextoEnriquecido={true}
        />
        </main>    
    );
}