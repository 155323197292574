import React, {useState, useContext, useEffect} from 'react';
import {localStorageName} from '../../../providers/localStorageData';
import {texts, properties, msgError} from './AporteMejoraDocumentacionData';
import AppContext from 'AppContext';
import { useHistory } from "react-router-dom";
import {properties as appProperties} from 'AppData';
import useApp from 'AppFunctions';
import RestProvider from 'providers/RestProvider' ;
import {loadState, saveState} from '../../../providers/localStorage';

const useFunctions = () => {

    //Hooks
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [documentosVoluntarios, setDocumentosVoluntarios] = useState([]);
    const [tamMaximoSobrepasado, setTamMaximoSobrepasado] = useState(false);
    const [numInput, setNumInput] = useState(0);
    const [documentos] = useState([]);
    const [mostrarMensaje, setMostrarMensaje] = useState(false);
    const [mostrarError, setMostrarError] = useState(false);
    const [textoError, setTextoError] = useState("");
    const [disabledBotonFirmar, setDisabledBotonFirmar] = useState(true);
    const [documentosEntrega, setDocumentosEntrega] = useState([]);
    const [mostrarConfirmacionFirma, setMostrarConfirmacionFirma] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessingFirma, setIsProcessingFirma] = useState(false);
    const [mostrarPopUpDesOk, setMostrarPopUpDesOk] = useState(false);
    const [msgErrorPresentacion, setMsgErrorPresentacion] = useState();
    const [mostrarErrorPresentacion, setMostrarErrorPresentacion] = useState(false);
    const [values, setValues] = useState("");

    const context = useContext(AppContext);
    const history = useHistory();
    const {firmaGenericaMasiva, calcularHashBase64, calcularDataSource, firmarGenerico} = useApp();
    const {crudOperation, crudOperationsType} = RestProvider();

    let _ID = 0;
    const [message, setMessage] = useState({ msg: '', severity: '' });
    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const [alerts, setAlerts] = useState([]);
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    let solicitud = loadState(localStorageName.solicitudActual, true);
    let accionActual = loadState(localStorageName.accion, false);

    let arrayVoluntariosTmp;

    useEffect(() => {
        arrayVoluntariosTmp = documentosVoluntarios;
    }, [documentosVoluntarios]) ;

    /**
     * Obtiene el procedimiento en el que se encuentra para actualizar el
     * titulo de la pagina
     */
     const obtenerTituloPagina = () =>{
        context.updateTitlePage(texts.PAGE_TITLE);
    }

    /**
     * Volver a la bandeja de entrada
     */
     const volverABandeja = () =>{
        history.push(properties.URL_BANDEJA)
    }

    /**
     * @function updateFormat Segun el num de pixeles del ancho de la pantalla pone version movil o version ordenador
     */
     const updateFormat = () => {
        if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    /**
     * @function tratamienoAnexoVoluntario Metodo que crea un nuevo registro en el array voluntarios
     * @param {*} pesoDoc tamaño del documento subido
     */
    const tratamienoAnexoVoluntario = (pesoDoc) => {
        documentosVoluntarios[numInput].mostrarDocAnexo = true;
        documentosVoluntarios[numInput].tamDoc = (pesoDoc / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES);

        const arrayTemp = [...documentosVoluntarios];

        setNumInput(numInput + 1);
        setDisabledBotonFirmar(false);
        let documentoVoluntario = {
            mostrarDropZone: false,
            value: "",
            mostrarDocAnexo: false,
            tamDoc: "",
            pdf: "",
        }

        arrayTemp.push(documentoVoluntario);
        setDocumentosVoluntarios(arrayTemp); 

};

    /**
     * @function handleOnDrop Gestion de la subida de documentos
     * @param {*} files Fichero subido por el usuario
     * @param {*} rejectedFiles Fichero rechazado subido por el usuario
     */
     const handleOnDrop = (files, rejectedFiles) => {

        var tamFicheroSubido = parseFloat((files[0].file.size / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES));

        if(properties.TAM_MAX_ANEXO < tamFicheroSubido){
            setTamMaximoSobrepasado(true);
        } else {
            let documentoV = {
                fichero: files[0],
                anexo: null,
                descripcionVoluntario: documentosVoluntarios[numInput].value,
                firmado: false,
                nombreFichero: {...files[0].file},
                idSolicitud: null, // <---Por definir
                idConvocatoria: null, // <---Por definir
                idProcedimiento: null, // <---Por definir
            }

            documentosVoluntarios[numInput].pdf = files[0].data;
            documentoV.fichero.data = files[0].data.split('base64,')[1];
            documentos.push(documentoV);
            tratamienoAnexoVoluntario(files[0].file.size);

        }
        
    }

    /**
     * @function handleChange Gestion de la subida de documentos
     * @param {*} e Fichero subido por el usuario
     */
    const handleChange = (e) => {
        setValues(e.target.value);
        documentosVoluntarios[numInput].value = e.target.value;

        if(e.target.value === null || e.target.value === undefined || e.target.value === ""){
            documentosVoluntarios[numInput].mostrarDropZone = false;
        }else{

            if(numInput !== 0){
                for( var j = 0; j < documentosVoluntarios.length - 1; j++){ 
                    if(documentosVoluntarios[j].value === e.target.value) {
                        const arrayTemp = [...documentosVoluntarios];
                        arrayTemp[numInput].mostrarDropZone = false;
                        setDocumentosVoluntarios(arrayTemp);
                        break;
                    } else {
                        documentosVoluntarios[numInput].mostrarDropZone = true;
                    }
                 }  
            } else {
                documentosVoluntarios[numInput].mostrarDropZone = true;
            }
        }
    };

    /**
     * @function eliminarAnexoVoluntario Metodo que controla el INPUT del componente SubidaArchivoVoluntario para mostrar el Dropzone
     * @param {*} e Fichero subido por el usuario
     */
    const eliminarAnexoVoluntario = (e) => {

        const arrayTemp = [...documentosVoluntarios];

        for( var i = 0; i < arrayTemp.length; i++){ 
            if(e.target.name === arrayTemp[i].value) {
                arrayTemp.splice(i, 1);
                setDocumentosVoluntarios(arrayTemp);
                setNumInput(numInput - 1);
            }
        }

        if(numInput == "1"){
            setDisabledBotonFirmar(true);
        }

        for( var j = 0; j < documentos.length; j++){ 
            if(e.target.name === documentos[j].descripcionVoluntario) {
                documentos.splice(j, 1);
            }
        }

    };

    /**
     * @function cerrarError
     */
     const cerrarError = () => {
        setMostrarError(false);      
    };

    /**
     * @function mostrarConfirmarError Metodo que hace saltar el componente mostrar cuando un anexo subido es rechazado
     * @param {*} rejectedFile Fichero rechazado
     * @param {*} tamMaxDoc tamaño maximo permitido para el documento
     */
    const mostrarConfirmarErrorFichero = (rejectedFile, tamMaxDoc) => {
        setTextoError(texts.TEXTO_ERROR_DROP_REJECTED1 + tamMaxDoc + texts.TEXTO_ERROR_DROP_REJECTED2);
        setMostrarError(true);
    };

    const cerrarConfirmacionFirma = () => {
        setMostrarConfirmacionFirma(false);
    };

    const abrirConfirmacionFirma = () => {
        setMostrarConfirmacionFirma(true);
    };

    const firmarKo = (type, message) => {
        setTextoError(message);
        setMostrarError(true);
    }

    const navegarBandeja = async () => {

        if (accionActual !== undefined && accionActual !== null && accionActual !== '' && accionActual === 'SUBSANACION_FACULTATIVA') {
            await transicionarExpediente('SUBSAFACU', 'INCSUBFA');
        }
        history.push("/BandejaSolicitudes");
    }

    /**
     * Firmar documentos aportados
     */
    const firmar = () => {
        cerrarConfirmacionFirma(false);
        let documentosAfirmar = [];
        let doc = null;

        for (const documento of documentos) {
            documento.fichero.data !== undefined && documentosAfirmar.push(documento.fichero.data);
            doc = documentosAfirmar;
        }        

        firmaGenericaMasiva(doc, firmarOk, firmarKo);
    }

    const montarDocumentosEntrega = (signatureBase64, tipoDocumento) => {
        let firmas = signatureBase64.split(':');
        setDocumentosEntrega([]);
        
        for(let i=0; i<documentos.length; i++){
            let documentoEntrega = {
                firmaBase64: firmas[i],
                hashDocumentoBase64: calcularHashBase64(documentos[i]),
                documento: documentos[i].fichero.data,
                nombreDocumento: documentos[i].descripcionVoluntario,
                tipoDocumento: tipoDocumento,
                descripcionDocumento: documentos[i].descripcionVoluntario,
                firmado:true
            }
            documentosEntrega.push(documentoEntrega);
        } 
          
        return  documentosEntrega;
    }

    

    //PRESENTAR MEJORA DOCUMENTACION 
    const firmarOk = (signatureBase64, certificate864, index) => {
        let inputPresentarMejoraDoc =  montarInputMejoraDocumentacion(signatureBase64);
        setIsLoading(true);
        setIsProcessingFirma(true);
        
        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_PRESENTAR_MEJORA_DOC, {url:'', method: 'post', data:inputPresentarMejoraDoc}).then(response => {       
            
            saveState(localStorageName.recibi, response.data.recibi, false);
            saveState(localStorageName.numRegistroOrve, response.data.numRegistroOrve, false);
            saveState(localStorageName.numeroExpediente, response.data.numeroExpediente, false);
            saveState(localStorageName.idExpediente, response.data.idExpediente, false);

            setIsLoading(false);
            setMostrarMensaje(true);
            

        }).catch(function (error) {
            setIsLoading(false);
            if(error.response){
                let msg = error.response.data.messageDescription;     
                setMsgErrorPresentacion(msg + texts.PARTE_2_MSG_ERROR);
            }else{
                setMsgErrorPresentacion(texts.PARTE_1_MSG_ERROR + texts.PARTE_2_MSG_ERROR); 
            }   
            
            setMostrarErrorPresentacion(true);
        })
    }

    const obtenerAccion = () => {
        let datosAccion = {
            enumProcesoEntrega: properties.PROCESO_ENTREGA_MEJORA_DOC,
            tipoDocumento: properties.TIPO_DOC_MEJORA,
            nombreAccion: 'Mejora de Documentación'
        };
        if (accionActual !== undefined && accionActual !== null) {
            switch (accionActual) {
                case 'SUBSANACION_FACULTATIVA':
                    datosAccion.enumProcesoEntrega = 'SUBSANACION_FACULTATIVA';
                    datosAccion.tipoDocumento = 'SUBSANACION_FACULTATIVA';
                    datosAccion.nombreAccion = 'Subsanación Facultativa';
                    break;
                case 'PRESENTACION_ALEGACIONES_OTROS_DOCS':
                    datosAccion.enumProcesoEntrega = 'PRESENTACION_ALEGACIONES_OTROS_DOCS';
                    datosAccion.tipoDocumento = 'PRESENTACION_ALEGACIONES_OTROS_DOCS';
                    datosAccion.nombreAccion = 'Presentación de Alegaciones, documentos u otros elementos de juicio';
                    break;
            }
        }
        return datosAccion;
    }

    const montarInputMejoraDocumentacion = (firma) => {

        let procedimiento = loadState(localStorageName.procedimientoActual, true);
        let convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);
        let userLogin = loadState(localStorageName.infoUsuario, true);
        let perfilSeleccionado = loadState(localStorageName.perfilSeleccionado, true);
        let datosAccion = obtenerAccion();

        let inputPresentarMejoraDoc = {
            enumProcesoEntrega: datosAccion.enumProcesoEntrega,
            idExpediente: solicitud.id === undefined ? solicitud.idExpediente : solicitud.id, 
            numeroExpediente: solicitud.numeroExpediente,
            idConvocatoria:convocatoria.id,
            dniLogin: userLogin.interesadoNif,
            numeroSerieCertificadoLogin: userLogin.datosCertificado ? userLogin.datosCertificado.numeroSerie : null ,
            isloginCertificadoDigital:userLogin.tipoAutenticacion === appProperties.TIPO_AUTENTICACION_CERTIFICADO ? true : false,
            descripcionConvocatoria: convocatoria.descripcion,
            fechaInicioPresentacion:new Date(convocatoria.fechaInicioPresentacion),
            fechaFinPresentacion: new Date(convocatoria.fechaFinPresentacion),
            idProcedimiento: procedimiento.id,
            descripcionProcedimiento: procedimiento.descripcion,
            usuarioOrve: convocatoria.codOrveUsuario,
            passwordOrve:convocatoria.codOrvePassword,
            asuntoOrve: convocatoria.codOrveAsunto,
            destinatarioOrve: convocatoria.codOrveDestinatario,
            listDocumentos: montarDocumentosEntrega(firma, datosAccion.tipoDocumento), //TODO
            perfil: perfilSeleccionado
        }
        
        return inputPresentarMejoraDoc;
    }

    /**
     * @function getEstadoFasePCT3 Función que obtiene el estadoFase
     * @params Código del estadoFase a obtener
     * @returns estadoFase obtenido
     */
    const getEstadoFasePCT3 = async (codigoEstadoFase) => {
        let estadoFase = null;

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ESTADO_FASE, { url: properties.URL_GET_ESTADOFASE_X_CODIGO + codigoEstadoFase, method: 'GET'}).then(response => {
            estadoFase = response.data;
        }).catch(function (error) {
            if (error.response.status !== 404) {
                handleSetMessage(msgError.error, msgError.severity);
            }
        });

        return estadoFase;
    }

    /**
     * @function getFasePCT3 Función que obtiene una fase a través de su código para procedimientos de PCT3
     * @param codigoFase Código de la fase a obtener
     * @returns fase obtenida
     */
    const getFasePCT3 = async (codigoFase) => {
        let fase = null;

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_FASE, { url: properties.URL_GET_FASE_X_CODIGO + codigoFase, method: 'GET'}).then(response => {
            fase = response.data;
        }).catch(function (error) {
            if (error.response.status !== 404) {
                handleSetMessage(msgError.error, msgError.severity);
            }
        });

        return fase;
    }

    const transicionarExpediente = async (codFase, codEstado) => {
        let res = null;
        let datExp = null;
        let dto = {
            codTransicion: 'INSUBFA',
	        objetosTramitables: [],
	        codigoFase: codFase,
	        codigoEstado: codEstado
        };

        let fase = await getFasePCT3(codFase);
        let estadoFase = await getEstadoFasePCT3(codEstado);

        let idExpediente = (solicitud.id === undefined) ? solicitud.idExpediente : solicitud.id;

        // Paso 1. Obtención del objeto datoExpedienteDto
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DATO_EXPEDIENTE, { url: `/ov3/${idExpediente}`, method: 'GET' }).then(response => {
            datExp = response.data;
            dto.objetosTramitables = (datExp.objetosTramitables === undefined || datExp.objetosTramitables === null)
                ? dto.objetosTramitables.push(datExp.objetoTramitable)
                : datExp.objetosTramitables;
        }).catch(() => {
            handleSetMessage('Se ha producido al obtener los datos del expediente', msgError.severity);
        });

        // Paso 2. Obtención de la transición
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_TRANSICIONS, { url: properties.URL_TRANSICIONES, data: dto, method: 'POST' }).then(response => {
            let transicion = response.data;
            Object.assign(datExp, {'transicion': transicion});
            
            // Se inserta el objeto tramitable el cual se va a transicionar
            let otTramitar = null;

            for (const ot of datExp.objetosTramitables) {
                if (ot.idEstadoFaseActual === estadoFase.id && ot.idFaseActual === fase.id) {
                    otTramitar = ot;
                    break;
                }
            }
            Object.assign(datExp, {'objetoTramitable': otTramitar});
        }).catch(() => {
                handleSetMessage('Se ha producido un error al obtener la transición', msgError.severity);
        });

        // Paso 3. Transición del expediente y añadir dato-accion para visualizarlo en el timeline
        if (datExp !== null && datExp.objetoTramitable !== null) {
            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DATO_EXPEDIENTE, { url: properties.URL_ACTUALIZAR_EXP, data: datExp, method: 'POST' }).then(response => {
                 res = response.data;
            }).catch(() => {
                handleSetMessage('Se ha producido un error al realizar la transición del expediente', msgError.severity);
            });  

        let codigoAccion = 'SUBIDA_DOC';
        let accion = null;

        //Se obtiene la accion SUBIDA_DOC
        await crudOperation(crudOperationsType.CUSTOM, 'accion', { url: `/findByCodigo/${codigoAccion}`, method: 'get'}).then( response => {
            accion = response.data;
        }).catch(e => {
            handleSetMessage(`Se ha producido un error al obtener la acción ${codigoAccion}`, 'warning');
        });

         //Se obtiene los documentos asociados ya que no tenemos el ID del documento para asociar al dato-accion
         const numeroRegistro = localStorage.getItem(localStorageName.numRegistroOrve);
         await crudOperation(crudOperationsType.CUSTOM, 'documento', { url: `/numeroRegistro/${numeroRegistro}`, method: 'get'}).then( response => {
            const documentosGuardados = response.data || [];

            for(const docEntrega of documentosGuardados){
                if (accion != null && docEntrega.id !== null) {
                    let datoAccion = {
                        idExpediente: datExp.id,
                        idAccion: accion.id,
                        objetoTramitable: datExp.objetoTramitable,
                        idDocumento: docEntrega.id
                    };
    
                    // Se realiza el guardado de datoAccion
                    crudOperation(crudOperationsType.CUSTOM, 'dato-accion', { method: 'post', data: datoAccion }).then(response => {
                    }).catch(e => {
                        handleSetMessage("Se ha producido un error al crear el dato acción", "warning");
                    });
                }
            }
        }).catch(e => {
            handleSetMessage(`Se ha producido un error al obtener la acción ${codigoAccion}`, 'warning');
        });


        }

        return res;
    }

    return { obtenerTituloPagina, volverABandeja, updateFormat, isPhoneVersion, documentosVoluntarios, setDocumentosVoluntarios, tamMaximoSobrepasado, handleOnDrop, handleChange, 
        eliminarAnexoVoluntario, mostrarConfirmarErrorFichero, mostrarMensaje, textoError, mostrarError, disabledBotonFirmar, firmar, mostrarConfirmacionFirma,
        cerrarConfirmacionFirma, abrirConfirmacionFirma, isLoading, navegarBandeja, cerrarError, isProcessingFirma, mostrarPopUpDesOk, msgErrorPresentacion, mostrarErrorPresentacion, obtenerAccion};

}
export default useFunctions;