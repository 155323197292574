import {makeStyles} from '@material-ui/core/styles'
import {estilosTextosPrincipales, backgroundRectangle, pantallaCarga } from '../../../AppStyle'

export const customStyle = makeStyles ({

    backgroundRectangle:{
        ...backgroundRectangle
    },
    gridCabecera: {
        display: 'flex'
    },
    pageTitle:{
        ...estilosTextosPrincipales.titulosPagina,
        fontWeight: 'bold',
    },
    gridPageTitle:{
        marginLeft: '5%',
        marginTop: '2%',
        alignItems: 'center',
        display: 'flex'
    },
    gridSearchBar:{
        marginRight: '5%',
        marginLeft: '5%',
        marginTop: '2%',
        alignSelf: 'center'
    },
    gridSearchBarResponsive:{
        margin: '5% 5% 0 5%',
        alignSelf: 'center'
    },
    gridBotonera:{
        marginTop: '2%'
    },
    gridTablePager:{
        marginTop: '0.5%',
        marginRight: '4%',
        width: '80%',
    },
    gridTableResponsivePager:{
        marginTop: '0.5%',
        width: '90%',
    },
    gridTable:{
        marginBotton: '0.5%'
    },
    backdrop:{
        ...pantallaCarga
    },
    classAlerta: {
        width: '90%',
    }
});
