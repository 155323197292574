import React, { useEffect } from 'react';
import { Grid, Snackbar, Backdrop, CircularProgress } from '@material-ui/core';
import { buttons, objAttrib, objText, objComponentFormio } from './menuNavegacionData';
import theme, { customStyle } from './MenuNavegacionStyle';
import useFunctions from './MenuNavegacionFunction';
import Botonera from './../../commons/botoneraGenerica/botoneraGenerica';
/**** Componentes importados ****/
import ListadoFormularios from '../listadoFormularios/ListadoFormularios';
import ComponenteFormio from '../componenteFormio/ComponenteFormio';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import { Alert } from '@material-ui/lab';
import {properties as appProperties} from '../../../AppData';

export default function MenuNavegacion() {
    const { isVisible, mostrarPopUp, handleChangeIsVisible, aceptarContinuacion, cancelarContinuacion, botonPorDefecto, isPhoneVersion,
        updateFormat, onSubmit, handleChange, submissionData, isLoading,
        alerts, setMessage, message, updateAcciones, acciones, setAcciones, getAcciones } = useFunctions();

    const Style = customStyle();
    const idPrimerBoton = { ...buttons[0] }.id;

    useEffect(() => {
        botonPorDefecto(idPrimerBoton);
        updateFormat();
        return () => window.removeEventListener('resize', updateFormat);
    }, []);

    //UseEffect utilizado para la gestión de las acciones
    useEffect(()=> {
        if (acciones !== null) {
            getAcciones(acciones);
            //Se reestablecen una vez recibidas y utilizadas
            setAcciones(null);
        }
    }, [acciones]);

    window.addEventListener('resize', updateFormat);

    return (
        //Página
        <Grid {...objAttrib.gridContainer}>
            {/*Encabezado*/}
            <Grid className={Style.gridEncabezado} {...objAttrib.gridEncabezado}>
                <h2 className={Style.titulo}>{objText.pageTitle}</h2>
            </Grid>
            {/*Rectangulo*/}
            <Grid className={Style.gridRectangulo} {...objAttrib.gridRectangulo}>
                <Grid className={Style.gridBotonera} {...objAttrib.gridBotonera}>
                    {/*Botonera*/}
                    <Botonera
                        gridClassName={Style.gridBotonera}
                        className={Style.buttons}
                        theme={theme}
                        {...objAttrib.gridBotonera}
                        {...objAttrib.buttons}
                        object={buttons}
                        orientation={'horizontal'}
                        onClick={(e) => handleChangeIsVisible(e)}
                    /></Grid>
                {/*omponente*/}
                <Grid className={isPhoneVersion ? Style.gridCompPhoneVersion : Style.gridComp} {...objAttrib.gridComp}>
                    {/*POPUP*/}
                    <Confirmacion
                        {...objText.popup}
                        isPhoneVersion={isPhoneVersion}
                        handleChange={cancelarContinuacion}
                        mostrar={mostrarPopUp}
                        accionConfirmacion={(e) => aceptarContinuacion(e)}
                        tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                        isPopUpPdf={false}
                        textBotonConfirmarPersonalizado={false}
                        isImagenPersonalizada={false}
                        centrarContenido={true}
                        isTextoEnriquecido={true}
                    />
                    {/*Componentes*/}
                    <Grid className={isPhoneVersion ? Style.gridComponentesPhoneVersion : Style.gridComponentes} {...objAttrib.gridComponentes}>
                        {//Componente Generación de formularios
                            isVisible.btnGeneracionFormularios &&
                            <ListadoFormularios />
                        }
                        {//Componente Administración de procedimientos

                            isVisible.btnAdministracionProcedimientos &&
                            <ComponenteFormio {...objComponentFormio.administracionProcedimientos} onSubmit={onSubmit} onChange={handleChange} submission={submissionData} handleAcciones={updateAcciones} />
                        }
                    </Grid>
                </Grid>
                <Backdrop className={Style.classBackdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {alerts.map(m => (
                    <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
                        <Alert
                            severity={message.severity}
                            variant="filled"
                        >
                            {message.msg}
                        </Alert>
                    </Snackbar>
                ))}
            </Grid>
        </Grid>
    )
}