import React, { useEffect } from 'react';
import LineaMetro from 'components/commons/lineaMetro/lineaMetro';
import { Grid } from '@material-ui/core';
import useStyles  from './SolicitudPresentadaCorrectamenteStyle';
import { properties, texts } from './SolicitudPresentadaCorrectamenteData';
import { pasosNuevaSolicitud } from './../../../AppData';
import useFunctions from './SolicitudPresentadaCorrectamenteFunctions';

export default function SolicitudPresentadaCorrectamente() {

    // IMPORTS
    const { tituloPagina, obtenerTituloPagina, updateFormat, isPhoneVersion, obtenerInfoSolicitud, onClickRecibi, idTrewa, idSolicitudPdf, numRegOrve, onClickVolverBandeja, numExpediente, idExpediente} = useFunctions();
    const { style } = useStyles(isPhoneVersion);
    const customStyle = style();

    useEffect(() => {
        obtenerTituloPagina();
        obtenerInfoSolicitud();
    }, []);

    window.addEventListener('resize', updateFormat);

    return(
        <main>
            <Grid className={customStyle.gridPageTitle}>
                <Grid>
                    <h2 id={properties.ID_PAGE_TITLE} className={customStyle.pageTitle}>
                        {tituloPagina}
                    </h2>
                    <LineaMetro pasos={pasosNuevaSolicitud} activeStep={3}/>
                </Grid>
                <Grid className={customStyle.backgroundRectangle}>
                    <Grid>
                        <h2 className={customStyle.hclassFirma}>
                            {texts.TEXT_JUSTIFICANTE_RECIBI}
                        </h2>
                    </Grid>
                    <Grid className={customStyle.gridCentrar}>
                        <img src={properties.URL_ICON}/>
                        <h3 className={customStyle.classMargin}>{texts.TEXT_SOLICITUD_PRESENTADA}</h3>
                        <Grid className={customStyle.classGridParrafo}>
                            <p className={customStyle.classMarginP} >{texts.TEXT_DESCRIPCION1}</p>
                            <p>{texts.TEXT_DESCRIPCION2}</p>
                            <Grid className={customStyle.gridRespuesta}>
                                {idSolicitudPdf && <p>{texts.TEXT_ID_SOLICITUD}{idSolicitudPdf}</p>}
                                {idTrewa && <p>{texts.TEXT_ID_TREWA}{idTrewa}</p>}

                                {numExpediente && <p>{texts.TEXT_NUM_EXPEDIENTE}{numExpediente}</p>}
                                {idExpediente && <p>{texts.TEXT_ID_EXPEDIENTE}{idExpediente}</p>}
                                
                                <p>{texts.TEXT_NUM_ORVE}{numRegOrve}</p>
                            </Grid>
                        </Grid>
                        <Grid className={customStyle.gridBotonRecibi}>
                            <button className={customStyle.classBoton} onClick={onClickRecibi}>{texts.TEXT_DESCARGAR_RECIBI}</button>
                        </Grid>     
                    </Grid>
                </Grid>
                <Grid container className={customStyle.gridBotonesListoAtras} justify='flex-end' spacing={1}>
                    <button className={customStyle.botonListo} onClick={onClickVolverBandeja}>{texts.TEXT_BOTON}</button>
                </Grid>
            </Grid>
        </main>    
    );
}
