import React from 'react';
import { Grid } from '@material-ui/core';
import Style from './metodoAccesoStyle';

export default function MetodoAcceso(props) {
    //Según el valor booleano recibido, aplicará una opacidad u otra / aplicará la propiedad container o no.
    let opacity = { ...(props.opacity ? { style: { opacity: '50%', pointer: 'cursor' } } : { style: { opacity: '100%' } }) };
    let container = {...(props.responsive ? { container: false } : { container: true })};

    //Caputra de estilos
    let classGridResponsive = Style().classGridResponsive;
    let classGrid = Style().classGrid;
    let classEncabezadoResponsive = Style().classEncabezadoResponsive;
    let classEncabezado = Style().classEncabezado;
    let classButtonResponsive = Style().classButtonResponsive;
    let classButton = Style().classButtonActive;
    let classParraf = Style().classParrafo;

    let gridStyle = props.responsive ? classGridResponsive : classGrid;
    let titleSyle = props.responsive ? classEncabezadoResponsive : classEncabezado;
    let buttonStyle = props.responsive ? classButtonResponsive : classButton;

    const renderParraf = () => {
        if (!props.responsive) {
            return <p className={classParraf}>{props.parrafo}</p>;
        }
    } 

    return (
        < Grid
            id={props.idGrid}
            className={gridStyle} {...container}
            {...opacity}
            aria-label={props.metodo}
        >
            { props.icon}
            <h4
                className={titleSyle}
                {...opacity}
            >
                {props.titulo}
            </h4>
            {renderParraf()}
            <button
                id={props.idButton}
                name={props.nameButton}
                className={buttonStyle}
                {...props.buttonAtributtes}
                {...props.stateButton/*Insertará la propiedad disabled, que actuará según el valor booleano*/}
                onClick={props.metodoAcceso}
            >
                {props.textButton}
            </button>
        </Grid >


    )
}