export const loadState = (key, convertJSON) => {
    try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      if(convertJSON){
        return JSON.parse(serializedState);
      }else{
        return serializedState;
      }
    } catch (err) {
      return undefined;
    }
  };
  
  export const saveState = (key, value, convertJSON) => {
    try {
      if(convertJSON){
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
      }else{
        localStorage.setItem(key, value);
      }    
    } catch (err) {
      // Ignore write errors.
    }
  };