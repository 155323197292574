import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core';
import { colores, tipografia } from 'AppStyle'
import { BorderBottom } from '@material-ui/icons';

export const customStyle = makeStyles({

    gridMenu: {
        width: 'fit-content',
    },
    appBar: {
        boxShadow: 'none',
        backgroundColor: 'transparent'
    },
    tabs: {
        //fontFamily: $´{tipografia.medium}!important´, //NO LO  COCGE
        display: 'inline-flex',
        justifyContent:'center',
        color: colores.escalaGrises.cien,

    },
    tab: {
        display: 'inline-flex',
        justifyContent: 'center',
        fontSize: '17px',
        fontWeight: '700',
        height: '100px',
        lineHeight: '16px',
        whiteSpace: 'nowrap',
        color: colores.principales.hover,
        width: 'auto',
        opacity: '0.9',
        alignItems: 'center',
        padding: '0px 10px',
        textTransform:'none',
    },
    tabIcon: {
        height: '24px',
    },
    //Inserta una imagen al producirse el evento hover
    classIcon: {
        height: '24px',
        filter: 'invert(91%) sepia(26%) saturate(150%) hue-rotate(77deg) brightness(90%) contrast(92%)',
        '&:hover': {
            filter: 'invert(30%) sepia(20%) saturate(3452%) hue-rotate(96deg) brightness(94%) contrast(94%)'
        }
    }
})

export const overrideStyle = createMuiTheme({
    overrides: {
        MuiTab: {
            root: {
                textTransform: 'none',
                fontFamily: `${tipografia.medium}!important`,
                width: 'auto',
                minWidth: '0',
                '&:hover': {
                    opacity: '1',
                    '&::after': { //estilos para que el borde inferior no desplace el texto
                        content: '""',
                        position: 'absolute',
                        bottom: '0px',
                        left: '0px',
                        right: '0px',
                        height: '4px',
                        backgroundColor: colores.principales.principal,
                    },
                },
            },
            textColorInherit: {
                minWidth: '30px'
            },
            wrapper: {
                fontFamily: tipografia.medium
            }
        },
        MuiTabs: {
            root: {
                width: 'auto',
            },
            indicator: {
                color: colores.principales.principal,
                backgroundColor: colores.principales.principal,
                height: '4px',
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: colores.escalaGrises.cero
            },
            root: {
                zIndex: 0,
                width: 'auto',
                margin: '0px 0px 0px 0px',
                padding: '0px',
            }
        }
    }
})