import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import RestProvider from 'providers/RestProvider';

/**
 * @function useOperations Fución que devuelve las hooks y funciones necesarias para el componente
 * @returns {select, options, handleChangeSelect, menuItem, getPais}
 */
export const useOperations = () => {
    //Objeto para evitar fallos de la aplicacion
    const objMock = [
        { codigo: '', descripcionLarga: '' },
    ];
    //Objeto de datos
    const msgError = {
        severity: 'Error',
        error: 'Ha ocurrido un error al intentar recuperar los datos.'
    }
    //Hook que obtiene el crudOperation
    const { crudOperation, crudOperationsType } = RestProvider();
    let _ID = 0;
    const [select, setSelect] = useState('');
    const [options, setOptions] = useState(objMock); //Para evitar que la aplicación falle en caso de devolver un valor undefined por parte del endPoint, se establece por defecto un objeto con la estructura necesaria vacía
    const handleChangeSelect = (e) => {
        setSelect(e.target.value);
    }
    const [message, setMessage] = useState({ msg: '', severity: '' });
    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const [alerts, setAlerts] = useState([]);
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    //Funciones auxiliares

    /**
     * @function checkController Función comprueba si la cadena contiene el caracter / y en caso de que lo contenga, lo elimina
     * @param {*} controller controlador recibido
     * @returns Devuelve el controlador sin la / en caso de que la tuviera
     */
    const checkController = (controller) => {
        if (controller.includes('/')) {
            controller = controller.substr(controller.indexOf('/') + 1, controller.length);
        }
        return controller;
    }

    /**
     * @function checkUrlEndPoint Función que comprueba si la cadena contiene el caracter / y en caso de que no lo contenga, lo añade
     * @param {*} url url recibida
     * @return Devuelve la url del endPoint con la / en la primera posicion de la cadena en caso de que lo la tuviera
     */
    const checkUrlendPoint = (url) => {
        if (!url.includes('/')) {
            url = `/${url}`;
        }
        return url;
    }

    /**
    * @constant getPais Método que realiza la llamada al endPoint obtenerSituacion
    */
    const getPais = async (controlador, url) => {
        //Paso 1.- Se comprueba que los enlaces al microservico esten bien compuestos
        controlador = checkController(controlador);
        url = checkUrlendPoint(url);

        //Paso 2.- Se realiza la llamada al microservicio
        crudOperation(crudOperationsType.CUSTOM, controlador, { url: url, method: 'get' }).then(response => {
            //Paso 3.- Se insertan los valores en el Hook setOptions
            //Paso 3.1- Se verifica si el valor del combo es response.data o response.data.valoresCombo
            let valorObtenido = response.data;
            if (valorObtenido === undefined) {
                valorObtenido = response.data.valoresCombo;                
            }
            setOptions(valorObtenido);
        }).catch(function (error) {
            handleSetMessage(msgError.error, msgError.severity);
        });
    }

    /**
     * @function menuItem Función simple que recorre el objeto recibido con los valores del selector y va insertando las opciones recibidas
     * @param {*} objeto Valores recibidos para alimentar las opciones del selector
     * @param {*} clave Valor que se establecerá como valor que devolverá la opción seleccionada por el select
     * @param {*} valor Valor a mostrar en la opción seleccionada
     * @returns 
     */
    const menuItem = (objeto, clave, valor) => {
        return (
            objeto.map((filtro) => (
                <MenuItem key={filtro[clave]} value={filtro[clave]}>
                    {filtro[valor]}
                </MenuItem>
            )))
    };

    return { select, options, handleChangeSelect, menuItem, getPais };
}

export default useOperations;