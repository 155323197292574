import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { colores, backgroundRectangle, buttonPrimaryBig, buttonPrimarySmall, estilosTextosPrincipales } from '../../../AppStyle';

export const customStyle = makeStyles({
    gridComponentes: {
        width: '100%',
        margin: '2% 0% 2% 2%',
        paddingRight: '2%'
    },
    gridComponentesPhoneVersion: {
        width: '90%',
        margin: '2% 5% 2% 5%'
    },
    gridRectangulo: {
        ...backgroundRectangle
    },
    gridBotonera: {
        margin: '2% 0 0 1%',
        alignItems: 'center',
        display: 'flex'
    },
    gridEncabezado: {
        marginLeft: '5%',
        marginTop: '2%',
        alignItems: 'center',
        display: 'flex'
    },
    gridComp: {
        display: 'flex',
        width: '100%'
    },
    gridCompPhoneVersion: {
        display: 'block',
        width: '100%',
        paddingTop: '12px'
    },
    titulo: {
        ...estilosTextosPrincipales.encabezadoH2,
        fontWeight: 'bold',
    },
    ///////////////////////////////BACKDROP
    classBackdrop: {
        zIndex: 1,
        color: colores.principales.blanco,
    }
});


const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                ...buttonPrimarySmall,
                top: 'none',
                left: 'none',
                backgroundColor: colores.escalaGrises.cero,
                color: colores.escalaGrises.cien,
                width: '142px',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '16px',
                marginBottom: '16px',
                marginRight: '5%',
                '&:hover': {
                    backgroundColor: colores.principales.fondoEspecifico,
                    color: colores.principales.principal,
                    borderColor: colores.principales.principal
                },
                '&:focus': {
                    backgroundColor: colores.principales.fondoEspecifico,
                    color: colores.principales.principal,
                    borderColor: colores.principales.principal
                },
            },
            outlined: {
                border: `1px solid ${colores.escalaGrises.apoyo}`,
                borderTopLeftRadius: '4px !important',
                borderTopRightRadius: '4px !important',
                borderBottomLeftRadius: '4px !important',
                borderBottomRightRadius: '4px !important',
            }
        },
        MuiButtonGroup: {
            root: {
                marginBottom: '10px'
            },
            groupedOutlinedVertical: {
                '&:not(:last-child)': {
                    borderBottomColor: 'none'
                }
            }
        }
    }
});
export default theme;