import React, {useState, useContext} from 'react';
import {loadState} from '../../../providers/localStorage';
import {texts } from './SolicitudPresentadaCorrectamenteData';
import AppContext from 'AppContext';
import {localStorageName} from '../../../providers/localStorageData';
import {properties as appProperties} from 'AppData';
import {useHistory} from "react-router-dom";

const useFunctions = () => {
    const history = useHistory();

    const PREFIJO_OBJETO_FILE_PDF = 'data:application/pdf;base64,';
    const NOMBRE_DOC_RECIBI = 'Recibi.pdf'
    const context = useContext(AppContext);

    //Hooks
    const [tituloPagina, setTituloPagina] = useState();
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [idTrewa, setIdTrewa] = useState();
    const [idSolicitudPdf, setIdSolicitudPdf] = useState();
    const [idExpediente, setIdExpediente] = useState();
    const [numExpediente, setNumExpediente] = useState();
    const [numRegOrve, setNumRegOrve] = useState();

    /**
     * @function updateFormat Segun el num de pixeles del ancho de la pantalla pone version movil o version ordenador
     */
     const updateFormat = () => {
        if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    /**
     * Obtiene el procedimiento en el que se encuentra para actualizar el
     * titulo de la pagina
     */
     const obtenerTituloPagina = () =>{

        let procedimiento = loadState(localStorageName.procedimientoActual, true);
        let descripcion = procedimiento.descripcion;
        setTituloPagina(texts.TEXT_PRINCIPAL + descripcion);
        context.updateTitlePage(texts.PAGE_TITLE + descripcion);
    }

    const obtenerInfoSolicitud = () =>{
        let numOrve = loadState(localStorageName.numRegistroOrve, false);
        setNumRegOrve(numOrve);
        let idExpediente = loadState(localStorageName.idExpediente, false);
        setIdExpediente(idExpediente);
        let numExpediente = loadState(localStorageName.numeroExpediente, false);
        setNumExpediente(numExpediente);
    }

    const onClickRecibi = () => {
        let recibiBase64 = loadState(localStorageName.recibi, false);
        let linkSource = PREFIJO_OBJETO_FILE_PDF + recibiBase64;
        let downloadLink = document.createElement("a");
        let fileName = NOMBRE_DOC_RECIBI;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const onClickVolverBandeja = () => {
        history.push('/BandejaSolicitudes');
    }

    return { tituloPagina, obtenerTituloPagina, updateFormat, isPhoneVersion, obtenerInfoSolicitud, onClickRecibi, idTrewa, idSolicitudPdf, numRegOrve, onClickVolverBandeja, idExpediente, numExpediente};

}
export default useFunctions;