import React, {useEffect, useState} from 'react';
import { TextField, IconButton, Grid, ThemeProvider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {texts, paths, properties} from './SearchBarData';
import {overrideStyleF, customStyle} from './SearchBarStyle';
import {properties as propertiesBotoneraVertical} from '../botonera/BotoneraVerticalData'
import {properties as propertiesListadoProcedimientos} from '../../pages/listadoProcedimientos/ListadoProcedimientosData'


function SearchBar(props) {  

    /*Hook que almacena el termino de busqueda */
    const [searchTerm, setSearchTerm] = useState('');    

    /**Metodo que actualiza los registros filtrandolos según el término de busqueda, si este fuera nulo
    ***los mostraría todos. Cuando la busqueda se realiza marca el boton Todos de la botonera vertical*/
    const search = (selectedOptions) => {
        
        let searchData = searchTerm;

        if(selectedOptions.length > 0){
            searchData = selectedOptions;
        }

        if(!searchData){
            props.updateSearch(props.data);
        }else{
            props.updateSearch(props.data.filter(value => extraerVocalAcentuada(value.descripcion) === extraerVocalAcentuada(searchData)));
        }

        if(window.location.hash === '#/'){
            if(!props.isPhoneVersion ){
                props.refDefaultButtonBotonera.current.setAttribute('searchAll', 'false');
                props.refDefaultButtonBotonera.current.click()
            }else{
                props.updatePhoneSelectedOption(propertiesBotoneraVertical.MISTRAMITES_BUTTON);
            }
        }
        

        //Actualizar pag actual
        props.paginate(propertiesListadoProcedimientos.INIT_PAGE);
    }

    /**Método que actualiza el hook del termino de busqueda, ya sea mediante el desplegable
    ***del autocompletado (opción if) o mediante lo introducido en el input (opcion del else)*/
    const updateTerm = (event, value, reason) =>{

        if(reason === 'select-option'){
            setSearchTerm(value);
            search(value);  
        }else{
            setSearchTerm(event.target.value); 
        }
    }

    /**
     * @function extraerVocalAcentuada Función que extrae los acentos de una cadena de texto
     * @param {*} texto Cadena de texto recibida 
     * @returns Devuelve la cadena de texto sin acentos
     */
    const extraerVocalAcentuada = (texto) => {        
        const vocalesAcentuadas = ["á", "é", "í", "ó", "ú"];
        const vocales = ["a", "e", "i", "o", "u"];

        //Paso 1. Se convierte la cadena al completo en minúsculas
        texto = texto.toLowerCase();
        
        //Paso 2. Se recorre con un bucle forof la cadena de texto recibida y seguidamente se recorre con otro bucle forof el array que contiene las vocales acentuadas.
        for (const caracter of texto) {
            for (const vocal of vocalesAcentuadas) {
            //Paso 2.1. Se comprueba con esta estructura de control si el caracter actual de la cadena recibida es igual que la vocal acentuada actual
                if (caracter === vocal) {
                    //Se obtiene la posición que ocupa en el array de vocalesAcentuadas la vocal obtenida
                    let vocalObtenida = vocales[vocalesAcentuadas.indexOf(vocal)];
                    //Se obtiene la posición del caracter acentuado en la cadena recibida
                    let posicionCaracter = texto.indexOf(caracter);
                    //Se extrae la primera parte de la cadena (antes del caracter acentuado)
                    let primeraParte = texto.substring(0, posicionCaracter);
                    //Se extra la segunda parte de la cadena (después del caracter acentuado)
                    let segundaParte = texto.substring(posicionCaracter + 1, texto.length);
                    //Paso 2.2. Se compone la cadena sin el caracter acentuado
                    texto = primeraParte + vocalObtenida + segundaParte;
                }
            }
        }
        return texto;
    };
    
    /**
     * Efecto para que cada vez que se vacíe el contenido del searchbar realice una búsqueda de procedimientos y los muestre todos.
     */
     useEffect(() => {
        //Paso 1. Estructura de control que evalúa si el contenido de la variable searchTerm es undefined y si además el botón borrar del componente existe
        if (searchTerm === undefined && document.querySelector(`#${texts.idButton}`)) {
            //Paso 2. Al cumplirse la condición, se pulsa el botón buscar
            document.querySelector(`#${texts.idButton}`).click();
        }
    },[searchTerm]);

    /**Render del componente buscador con autocompletado, compuesto por el componente Autocomplete y IconButton unidos
    ***como si fueran un solo componente mediante un grid*/
    return (
        <ThemeProvider theme={overrideStyleF(props.isPhoneVersion)}>{/*Se sobreescribe las classes de material ui*/}
            <Grid container  alignItems={props.isPhoneVersion ? 'center' : 'flex-end'}>

                <Grid item>
                    <Autocomplete 
                        freeSolo
                        ref={props.refAutocomplteSearchBar}
                        className={props.isPhoneVersion ? (customStyle().searchAutocompletePhone) : (customStyle().searchAutocomplete)}    
                        options={props.data.filter(() => searchTerm && searchTerm.length>=properties.minLettersForAutocomplete).map((value) => value.descripcion)}
                        onChange={updateTerm}
                        onInputChange={updateTerm}
                        clearText={texts.cleanText}
                        renderInput= {(params) => 
                            <TextField 
                                {...params}
                                ref={props.refTextFieldSearchBar} 
                                className={props.isPhoneVersion ? (customStyle().searchTextFieldPhone) : (customStyle().searchTextField)}
                                variant="outlined" 
                                placeholder={texts.searchBarPlaceholder}
                                onKeyPress = {(e) => e.key === 'Enter' && document.querySelector(`#${texts.idButton}`).click()}
                            />}
                    />
                </Grid>

                <Grid item>
                    <IconButton 
                        disableRipple
                        id={texts.idButton}
                        className={props.isPhoneVersion ? (customStyle().searchButtonPhone) : (customStyle().searchButton)}
                        onClick={search}>
                            <img className={props.isPhoneVersion ? (customStyle().searchImgPhone) : (customStyle().searchImg)}
                                src={paths.searchIconPath} 
                                alt={texts.searchIconAlt}/>
                    </IconButton>
                </Grid>

            </Grid>
        </ThemeProvider>
    )
}  
export default SearchBar;