import RestProvider from 'providers/RestProvider';
import { properties } from './ICTData'
export default function ICTFunctions() {

    const { crudOperation, crudOperationsType } = RestProvider();
    /**
    * @function getValorICT Función que obtiene los valores de los campos del formulario ICT a partir del idZona obtenido en el selector idZona
    */
    const getValorICT = async (nombreCampo, idZona) => {
        let res = null;
        //Paso 1 - Declaramos que nuestros valores pasados por parametro no esten vacios
        if (idZona !== null && idZona !== undefined && idZona !== '') {
            let input = {
                "nombreCampo": nombreCampo,
                "idZona": idZona
            };
            //Paso 2 - Declaramos que el crudOperation sera de tipo CUSTOM, en properties indicaremos nuestro requestMapping para saber a cual endpoint apuntamos
            // la url contendrá el controlador de nuestro metodo, luego le pasaremos el tipo de metodo y introduciremos el objeto input para obtener la respuesta
            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE, { url: properties.urlValores, method: 'post', data: input }).then(response => {
                //  Debido a que se puede obtener más de un valor por campo, se realiza un tratamiento para obtener los valores no repetidos
                let valoresObtenidosICT = [];
                for (const valor of response.data) {
                    !valoresObtenidosICT.includes(valor) && valoresObtenidosICT.push(valor);
                }
                res = valoresObtenidosICT.toString();
                //Paso 3 - Guandaremos la respuesta en una variable que devolveremos más tarde, toString convertirá nuestro objeto en un string
            });
        }
        return res;
    }
    return { getValorICT };
}