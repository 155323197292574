import { useState } from 'react';
import { properties as appProperties } from 'AppData';

const useOperations = () => {
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    
    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    return { isPhoneVersion, updateFormat };
}

export default useOperations;