import {makeStyles} from '@material-ui/core/styles';
import {buttonPrimarySmall, buttonSecundarySmall, estilosTextosPrincipales, colores} from '../../../AppStyle';
import { createMuiTheme } from '@material-ui/core';

const useStyles = (isPhoneVersion, isDisableButtonNext, isDisableButtonBack, isDisabledAumentaZoom, isDisabledQuitarZoom) => {

    const customStyle = makeStyles({
        mainDiv:{
            ...(!isPhoneVersion) ? { width: '100%'} : { width: '95%'},
            background: colores.escalaGrises.diez,
            marginBottom: '1%',
            borderRadius: '4px',
            border: '2px solid ' + colores.principales.principal,
        },
        botonSiguiente:{
            ...buttonPrimarySmall,
            margin: '10px',
            position: 'inherit'
        },
        botonAnterior:{
            ...buttonSecundarySmall,
            margin: '10px',
            position: 'inherit'
        },
        textoNavegacion:{
            ...estilosTextosPrincipales.body2,
            textAlign: 'center'
        },
        gridDocumento:{
            ...(!isPhoneVersion) ? { marginTop:'25px'} : { marginTop: '1%'},
            ...(!isPhoneVersion) ? { marginBottom:'15px'} : { marginBottom: '1%'},
            ...(isPhoneVersion) && { marginLeft: '1%'},
            ...(isPhoneVersion) && { marginRight: '1%'},
        },
        classImg: {
            cursor: 'pointer'
        },
        classImgArrowLeft: {
            cursor: 'pointer',
            ...(isDisableButtonBack) ? { opacity:'0.5'} : { opacity:'1'},
        },
        classImgArrowRight: {
            cursor: 'pointer',
            ...(isDisableButtonNext) ? { opacity:'0.5'} : { opacity:'1'},
        },
        classImgAumentarZoom: {
            cursor: 'pointer',
            ...(isDisabledAumentaZoom) ? { opacity:'0.5'} : { opacity:'1'},
        },
        classImgQuitarZoom: {
            cursor: 'pointer',
            ...(isDisabledQuitarZoom) ? { opacity:'0.5'} : { opacity:'1'},
        },
        classControl: {
            ...(!isPhoneVersion) ? { width: '50%'} : { width: '80%'},
            marginTop: '1%',
            backgroundColor: 'white',
            borderRadius: '4px',
            border: '2px solid ' + colores.principales.principal,
            display: 'inline-grid'
        },
        classPanel: {
            marginTop: '3%',
            marginBottom: '3%',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'space-around'
        },
        classGridPanel: {
            display: 'contents',
        }
    });

    const theme = createMuiTheme({
        overrides: {
            MuiPopover: {
                root: {
                    marginTop: '23% !important' 
                },
            },
            
        },
    });

    return { customStyle, theme };
}

export default useStyles;
