import React, { useState } from "react";
import { pdfjs, Document, Page } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Button, Grid, MuiThemeProvider } from '@material-ui/core';
import { texts, properties } from "./VisualizadorPdfData";
import useStyles from "./VisualizadorPdfStyle";
import './VisualizadorPdfCss.css';

export default function AporteDocumentacion(props) {
  
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const [prevDisable, setPrevDisable] = useState(true);
    const [nextDisable, setNextDisable] = useState(true);

    const [numberOfPages, setNumberOfPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.0);
    
    const zoomMin = scale < 0.6;
    const zoomMax = scale > 2.0;

    const { customStyle, theme } = useStyles(props.isPhoneVersion, nextDisable, prevDisable, zoomMax, zoomMin);
    const style = customStyle();

    const onLoadSuccess = (e) => {
        let numPag = e._pdfInfo.numPages;
        setPrevDisable(true); //Se inicializa para cada carga
        setNextDisable(numPag > 1 ? false : true);
        setNumberOfPages(numPag);
        setPageNumber(1);
    }

    const onLoadError = (error) =>{
        console.log(error)
    }
    
    const changePage = (event) => {
        let pageNum = pageNumber;
        let idBoton = event.currentTarget.id;
        if(idBoton === properties.idBtnAnt && !prevDisable){
            pageNum = pageNumber-1;
        }else if(idBoton === properties.idBtnSig && !nextDisable){
            pageNum = pageNumber+1;
        }       
        setPageNumber(pageNum);
        disablePrev(pageNum);
        disableNext(pageNum)
    }

    const disablePrev = (pageNum) =>{
        if(pageNum<=1){
            setPrevDisable(true);
        }else{
            setPrevDisable(false);
        }
    }

    const disableNext = (pageNum) =>{
        if(pageNum==numberOfPages){
            setNextDisable(true);
        }else{
            setNextDisable(false);
        }
    }

    const downloadPdf = () =>{
        let link = document.createElement(properties.ELEMENTO_DESCARGA);
        link.href = props.pdf;
        link.download = props.pdfNombre;
        link.click();
    }

    const aumentarZoom = () =>{
        if(!zoomMax)
            setScale(scale + 0.1);
    }
    
    const disminuirZoom = () =>{
        if(!zoomMin)
            setScale(scale - 0.1);
    }

    return (
    
        <Grid container alignItems='center' direction='column' className={style.mainDiv} id={properties.idDivPdf}>
            <Grid className={style.classControl}>
                <Grid className={style.classPanel}>
                    <img src={properties.imgDescarga} className={style.classImg} onClick={downloadPdf} title={texts.TEXTO_DESCARGAR}/>
                    <Grid className={style.classGridPanel} >
                        <img src={properties.imgArrowLeft} className={style.classImgArrowLeft} onClick={e => {changePage(e)}} id={properties.idBtnAnt} disabled={prevDisable} title={texts.TEXTO_ANTERIOR_PAGINA}/>
                        <p className={style.textoNavegacion}>{texts.PAGINA + pageNumber + texts.DE + numberOfPages}</p>
                        <img src={properties.imgArrowRigth} className={style.classImgArrowRight} onClick={e => {changePage(e)}} id={properties.idBtnSig} disabled={nextDisable} title={texts.TEXTO_SIGUIENTE_PAGINA} />
                    </Grid>
                    {!props.isPhoneVersion &&
                        <Grid className={style.classGridPanel}>
                        <img src={properties.imgZoomMenos} className={style.classImgQuitarZoom} onClick={disminuirZoom} title={texts.TEXTO_DISMINUIR_ZOOM}/>
                        <span>{(scale * 100).toFixed()}%</span>
                        <img src={properties.imgZoomMas} className={style.classImgAumentarZoom} onClick={aumentarZoom} title={texts.TEXTO_AUMENTAR_ZOOM}/>
                    </Grid>
                    }
                </Grid> 
            </Grid>
            <Grid className={style.gridDocumento}>
                <Document file={props.pdf} onLoadSuccess={onLoadSuccess} onLoadError={onLoadError}>
                    <Grid>
                        <Page pageNumber={pageNumber} scale={scale}/>
                    </Grid>        
                </Document>
            </Grid>
        </Grid>
    );
}