import { makeStyles } from '@material-ui/core/styles';
import { estilosTextosPrincipales, colores, backgroundRectangle } from '../../../AppStyle';

export const style = makeStyles({
    classGridContenedor: {
        ...backgroundRectangle,
        padding: '2%'
    },
    classGridSelect: {
        margin: '19px auto auto 32px'
    },
    classH2: {
        ...estilosTextosPrincipales.titulosPagina,
        marginLeft: '95px',
        marginTop: '2%',
    },
    classBorrame: {
        marginTop: '10px',
    },
    classTable: {
        backgroundColor: '',
        width: '100%',
        height: '300px',
        margin: 'auto 32px 0px 32px'
    },
    classPager: {
        marginRight: '28px'
    },
    //Estilos aplicados a los selectores
    classGrid: {
        textAlign: 'left',
        marginTop: '15px',
        marginBottom: '19px',
        marginRight: '32px',
        width: 'auto',
        display: 'block',
    },
    classInputLabel: {
        ...estilosTextosPrincipales.body2,
        color: colores.escalaGrises.ochenta,
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: ' 17px',
        marginBottom: '5px',
        position: 'relative',
        '&.Mui-focused': {
            color: colores.escalaGrises.ochenta ,
        },
    },
    classSpanRequired: {
        color: colores.principales.required,
        ...estilosTextosPrincipales.body2,
    },
    ///////////////////////////////BACKDROP
    classBackdrop: {
        zIndex: 1,
        color: colores.principales.blanco,
    },
});

export const objStyle = {
    classGridPager: {
        direction: "row",
        justify: "flex-end",
        alignItems: "center"
    }
}