import { makeStyles } from "@material-ui/core"
import { estilosTextosPrincipales, colores, tipografia, buttonPrimaryBig, buttonSecundaryBig } from '../../../AppStyle';

export default makeStyles({
    ///////////////////////////////GRID
    classGridResponsive: {
        width: '304px',
        height: '32px',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '32px',
        marginTop: '22.81px',
    },
    classGrid: {
        width: '185px',
        height: 'auto',
        textAlign: 'left',
        display: 'inline-flex',
        justifyContent: 'center',
        marginTop: '33px',
        marginBottom: '63px',
        marginRight: '46px',
    },
    ///////////////////////////////TEXTOS
    classEncabezadoResponsive: {
        ...estilosTextosPrincipales.encabezadoH4,
        color: colores.escalaGrises.cien,
        lineHeight: '26px',
        marginBottom: '37.74px',
        marginLeft: '15px',
        marginRight: '15px',
        width: '100px',
        height: '32px',
    },
    classEncabezado: {
        ...estilosTextosPrincipales.encabezadoH4,
        color: colores.escalaGrises.cien,
        fontSize: '18px',
        marginTop: '13px'
    },
    classParrafo: {
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.cien,
        fontWeight: 'normal',
        textAlign: 'center',
        marginBottom: '17px'
    },
    ///////////////////////////////BOTON
    classButtonResponsive: {
        ...buttonSecundaryBig
    },
    classButtonActive: {
        ...buttonSecundaryBig
    },
})