import { properties } from '../../pages/pageAltaUsuario/pageAltaUsuarioData';
import RestProvider from 'providers/RestProvider';
import { loadState } from './../../../providers/localStorage';
import { localStorageName } from './../../../providers/localStorageData'
import { getUrl } from 'config/Config';

export default function formioFunctions() {

    const { crudOperation, crudOperationsType } = RestProvider();

    /**Devuelve una lista de provincias*/
    const getProvincias = async () => {
        let res = null;

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_MANAGER, { url: properties.URL_GET_PROVINCIAS, method: 'get' }).then(response => {
        res = response.data;
        }).catch(function (error) {
        
        })      

        return res;
    }
    const getProvinciasDescripcion = async (codigoProvincia) => {
        let res = null;
        let resultado = null;
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_MANAGER, { url: properties.URL_GET_PROVINCIAS, method: 'get' }).then(response => {
        res = response.data;
        }).catch(function (error) {
        })
        for (const element of res) {
            if(element.codigo==codigoProvincia){
                resultado = element.descripcionLarga;
            }
        }
        return resultado;
    }


    /**Devuelve una lista de municipios de la provincia correspondiente al codProvincia*/
    const getMunicipios = async (codProvincia) => {
        let res = null;
        let completeUrl = properties.URL_GET_MUNICIPIOS.concat(codProvincia);
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_MANAGER, { url: completeUrl, method: 'get' }).then(response => {
        res = response.data;
        });

        return res;
    }

    /**Devuelve una lista de tipos de vía*/
    const getTipoVias = async () => {
        let res = null;

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_MANAGER, { url: properties.URL_GET_TIPO_VIAS, method: 'get' }).then(response => {
            res = response.data;
        });

        return res;
    }

    /**Devuelve una lista de paises*/
    const getPaises = async () => {
        let res = null;

    await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_MANAGER, { url: properties.URL_GET_PAISES, method: 'get' }).then(response => {
            res = response.data;
    });

        return res;
    }

    /**Devuelve una lista de organismos*/
    const getOrganismos = async () => {
        let res = null;
        const convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_MOTOR_TRAMITACION, { url: properties.URL_ORGANISMOS + '/' + convocatoria.id, method: 'get' }).then(response => {
        res = response.data;
        }).catch(function (error) {
        
        })      

        return res;
    }

    /**
     * Calcula el valor del check de "Actuo en calidad de: " existente en el bloque de datos Personales
     * @returns 
     */
     const calculaActuaEnCalidadDe = async () => {

        const usuariLogado = loadState(localStorageName.infoUsuario, true);
        let res = null;
        
        if (usuariLogado !== null && usuariLogado.entidadCif !== null){
            
            res = "representanteDeEntidadUOtraPersona";
        }else{
            res="auxiliar"
        }
        
        return res;
    }

    /**
     * Obtiene el valor de la información del usuario almacenada en sesión
     * @param {*} campo 
     * @returns 
     */
    const obtenerValoresUsuario = async (campo) => {

        const usuariLogado = await loadState(localStorageName.infoUsuario, true);
        let personaJuridica = usuariLogado != null && usuariLogado.entidadNombre != null;

        let res = null;
        
        switch (campo) {
            case "entidadCif": res = personaJuridica ? usuariLogado.entidadCif : "";
                break;
            case "entidadNombre": res = personaJuridica ? usuariLogado.entidadNombre : "";
                break;
            case "interesadoNombre": res = usuariLogado.interesadoNombre
                break;
            case "interesadoApellido1": res = usuariLogado.interesadoApellido1
                break;
            case "interesadoApellido2": res = usuariLogado.interesadoApellido2
                break;
            case "interesadoNif": res = usuariLogado.interesadoNif
                break;
            case "personaFisicaJuridica": res = personaJuridica ? "juridica" : "fisica";
                break;
            default: res = "";
                break;
        }
        return res;
    }

    /**
     * Obtiene el valor de la información del usuario almacenada en sesión
     * @param {*} campo 
     * @returns 
     */
     const obtenerUrlContexto = async () => {
        let url = getUrl();
        return url;
    }

    /**
     * Obtiene la convocatoria en la que nos encontramos
     * @returns 
     */
     const obtenerConvocatoria = async () => {
        return loadState(localStorageName.convocatoriaSeleccionada, true);    
    }

    return {getProvincias, getProvinciasDescripcion, getMunicipios, getTipoVias, getPaises, getOrganismos, calculaActuaEnCalidadDe, obtenerValoresUsuario, obtenerUrlContexto, obtenerConvocatoria};
}