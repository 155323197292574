// Fichero que contendrá todos los estilos por defecto de la aplicación. 

/*Las constantes que pertenecen a este bloque, solo pueden ser usadas como tal
****ej: (estilosTextosPrincipales.encabezadoH1.fontSize)**********************/

export const colores = {
    principales: {
        hover: '#b00202',                         
        principal: '#cc0000',
        negro: '#000000',
        apoyo: '#eee',
        apoyo1: '#eee', //PROVISIONAL
        //apoyo2: '#C8E6CF',
        fondoEspecifico: '#ffe4e4',
        blanco: '#FFFFFF', //NO CAMBIADO
        required: '#E2573C', //NO CAMBIADO
        selectHover: '#ffa7a7',
        verde: '#007500',
    },
    escalaGrises: {        
        cien: '#333333',//Color principal en el prototipo
        noventaYcinco: '#2D2926',
        noventa: '#444444',
        ochentaYcinco: '#464646',
        ochenta: '#555555', 
        setenta: '#7B7B7B',
        sesenta: '#909090',
        cincuenta: '#999999',
        cuarenta: '#B3B3B3',
        treinta: '#C8C8C8',
        veinte: '#D8D8D8',
        diecinueve: '#6E6B6B',
        veinticinco: '#D5D4D4',
        quince: '#EFEFEF',
        diez: '#EEEEEE',//Color contenedor en el prototipo
        cinco: '#F5F5F5',//Color apoyo especifico en el prototipo
        cuatro: '#F6F6F6',
        cero: '#FFFFFF',
        cero1: '#808080',
        principal: '#333333',
        contenedor: '#F5F5F5',
        secundario: '#767676',
        apoyoEspecífico: '#EEEEEE',
        apoyo: '#eee'
    },

    sombras:{
        sombraGris1: 'rgba(188,197,201,0.5)',
        sombraGris2: 'rgba(188,197,201,0.28)',
        sombraNegra: 'rgba(0,0,0,0)',
        sombraOscura1: 'rgba(0,0,0,0.2)',
        sombraOscura2: 'rgba(0, 0, 0, 0.5)',
        sombraOscura3: 'rgba(0,0,0,0.14)',
        sombraOscura4: 'rgba(0,0,0,0.12)',
        sombraOscura5: 'rgba(0, 0, 0, 0.25)',
    },

    estado: {
        exito: '#2ABC67',
        informacion: '#5BC0DE',
        atencion: '#F0AD4E',
        error: '#d4403a',
        alerta: '#f44336',
        alertaBorde: '#EA4648',
        messageAlert: '#B00020',
        bordeAlert: '#FFF5F7',
        amarilloAlerta: '#F8CA61',
    },
    accesibilidad: {
        focus: '#9831AD',
    },
}

/////////////////////////////////////////////////////////////


export const tipografia = {
    regular: 'Source Sans 3',
    bold: 'Source Sans 3 Bold',
    light: 'Source Sans 3 Light',
    medium: 'Source Sans 3 Medium',
    thin: 'Source Sans 3 Thin'
}

export const estilosTextosPrincipales = {
    encabezadoH1: {
        fontFamily: tipografia.bold,
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '32px',
    },
    encabezadoH2: {
        fontFamily: tipografia.regular,
        fontSize: '26px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '32px',

    },
    encabezadoH3: {
        fontFamily: tipografia.regular,
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '32px',
    },
    encabezadoH4: {
        fontFamily: tipografia.regular,
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '26px',
    },
    encabezadoH5: {
        fontFamily: tipografia.regular,
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px',
    },
    titulosPagina:{
        fontFamily: tipografia.regular,
        fontSize: '30px',
        fontWeight: '700',
        color: colores.escalaGrises.cien,
    },
    body1: {
        fontFamily: tipografia.regular,
        fontSize: '17px', 
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
    },
    body2: {
        fontFamily: tipografia.regular,
        fontSize: '17px', 
        lineHeight: '16px',
    },
    body3: {
        fontFamily: tipografia.regular,
        fontSize: '16px', 
        lineHeight: '16px',
    },
}

/////////////////////////////////////////////////////////////

export const backgroundRectangle = {
    width: '90%',
    height: 'fit-content',
    margin: 'auto',
    marginTop: '2%',
    marginBottom: '5%',
    backgroundColor: colores.escalaGrises.cero,
    boxShadow: `0 0 6px 0 ${colores.sombras.sombraGris1}, 0 0 30px 0 ${colores.sombras.sombraGris2}`,
}

/////////////////////////////////////////////////////////////
export const pantallaCarga = {
    zIndex: 1,
    color: colores.principales.blanco,
}

/////////////////////////////////////////////////////////////Estilo para aplicar a los enlaces
export const links = {
    color: colores.principales.principal,
    textDecoration: 'none',
    fontFamily: tipografia.medium,
    fontStyle: 'normal',
    fontWeight: 'normal',
    outline: '0',
    fontSize: '17px', 
    lineHeight: '18px',
    '&:hover': {
        textDecoration: 'underline',
     },
     '&:focus': {
        textDecoration: 'underline',
     },
    
}

/////////////////////////////////////////////////////////////Estilo para aplicar a los botones primarios (a la espera del desarrllo del componente boton y migrar el estilo a este)
export const buttonPrimaryBig = {
    fontFamily: tipografia.medium,
    fontWeight: '700',
    fontSize: '13px', 
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing:  '0.5px',
    textAlign: 'center',
    height: '40px',
    minWidth: '135px',
    borderRadius: '4px',
    padding: '8px 24px',
    background: colores.principales.principal,
    color: colores.principales.blanco,
    border: `solid 1px ${colores.principales.principal}`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: '0px',
    '&:hover': {
       background: colores.principales.hover,
       outline: '0px',
    },
    '&:disabled': {
        backgroundColor: colores.principales.apoyo,
        color: colores.escalaGrises.cero1,
        border: `solid 1px ${colores.principales.apoyo}`,
    },
    '&:focus': {
        background: colores.principales.hover,
        outline: '0px',
    }
}

export const buttonPrimarySmall = {
    fontFamily: tipografia.medium,
    fontWeight: '700',
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing:  '0.5px',
    textAlign: 'center',
    height: '40px',
    minWidth: '103px',
    borderRadius: '4px',
    padding: '8px 24px',
    background: colores.principales.principal,
    color: colores.principales.blanco,
    border: `solid 1px ${colores.principales.principal}`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: '0px',
    '&:hover': {
        background: colores.principales.hover,
        outline: '0px',
    },
    '&:disabled': {
        backgroundColor: colores.principales.apoyo,
        color: colores.escalaGrises.cero1,
        border: `solid 1px ${colores.principales.apoyo}`,
    },
    '&:focus': {
        background: colores.principales.hover,
        outline: '0px',
    }
}
export const buttonSecundaryBig = {
    fontFamily: tipografia.medium,
    fontSize: '13px',
     fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing:  '0.5px',
    textAlign: 'center',
    height: '40px',
    minWidth: '135px',
    left: '330px',
    top: '286px',
    borderRadius: '4px',
    padding: '8px 24px 8px 24px',
    backgroundColor: colores.principales.blanco,
    color: colores.principales.principal,
    border: `1px solid ${colores.principales.principal}`,
    cursor: 'pointer',
   '&:hover': {
        outline: `1px solid ${colores.principales.hover}`, 
        color: colores.principales.hover 
    },
    '&:disabled': {
        border: `1px solid ${colores.principales.apoyo}`,        
        color: colores.principales.apoyo,
        cursor: 'default'
    },
    '&:focus': {
        outline: `1px solid ${colores.principales.hover}`, 
        color: colores.principales.hover 
    }
}
export const buttonSecundarySmall = {
    fontFamily: tipografia.medium,
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing:  '0.5px',
    textAlign: 'center',
    height: '40px',
    minWidth: '103px',
    left: '330px',
    top: '286px',
    borderRadius: '4px',
    padding: '8px 24px 8px 24px',
    background: 'transparent',
    color: colores.principales.principal,
    border: `1px solid ${colores.principales.principal}`,
    cursor: 'pointer',
   '&:hover': {
        outline: `1px solid ${colores.principales.hover}`, 
        color: colores.principales.hover 
    },
    '&:disabled': {
        border: `1px solid ${colores.principales.apoyo}`,        
        color: colores.principales.apoyo
    },
    '&:focus': {
        outline: `1px solid ${colores.principales.hover}`, 
        color: colores.principales.hover 
    }
}