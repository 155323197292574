import React from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, MuiThemeProvider } from '@material-ui/core';
import TextoEnriquecido from '../../commons/textoEnriquecido/TextoEnriquecido';
import { properties } from './AcordeonData';

export default function Acordeon(props) {
    return (
        <MuiThemeProvider theme={props.theme}>
            <Grid className={props.classesGridAcordeon} item {...props.attbGridsAcordeon}>
                <h4 className={props.classes.classTitleAcordeon}>{props.descripcion}</h4>
                {
                    props.messages.map((content, index) => {
                        return (
                            <article aria-label={properties.ARIA_LABEL_ARTICLE}>
                                <Accordion
                                    className={props.classes.classAcordeon}
                                    id={props.id}
                                    key={index}
                                >
                                    <AccordionSummary
                                        className={props.classes.classSummary}
                                        id={content.idDescripcion + '-header'}
                                        arial-controls={content.idDescripcion + '-content'}
                                        expandIcon={props.icon}
                                    >
                                        {content.descripcionMensaje}
                                    </AccordionSummary>
                                    <AccordionDetails
                                        className={props.classes.classDetails}
                                    >
                                        <TextoEnriquecido
                                            id={content.idMensaje}
                                            texto={content.contenidoMensaje}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </article>
                        )
                    })
                }
            </Grid>
        </MuiThemeProvider>
    );
}