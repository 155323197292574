import { useState } from 'react';
import { properties as appProperties } from 'AppData';

const useOperations = () => {
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [mostrarPDF, setMostrarPDF] = useState(false);
    const [mostrarTexto, setMostrarTexto] = useState(false);
    
    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    /**
     * @function cerrarPopUpPdf Actualiza el hook que controla el pop up para visualizar pdfs y lo cierra
     */
     const cerrarPopUpPdf = () =>{
        setMostrarPDF(false);
    }

    /**
     * @function abrirPopUpPdf Actualiza el hook que controla el pop up para visualizar pdfs y lo abre
     */
     const abrirPopUpPdf = () =>{
        setMostrarPDF(true);
    }

    /**
     * @function cerrarPopUpPdf Corta el texto que aparece en el dropzone
     */
     const cortarTexto = (texto) => {
        let valor = (texto !== undefined && texto !== null) ? (texto.length <= 15) ? (texto) : (texto.substring(0, 15) + '...') : '';
        return valor;
    };

    /**
     * @function mostrarTextoEntero Abre o cierra el acordeon que muestra el texto
     */
     const mostrarTextoEntero = () =>{
        setMostrarTexto(!mostrarTexto);
    };

    return { isPhoneVersion, updateFormat, mostrarPDF, cerrarPopUpPdf, abrirPopUpPdf, mostrarTextoEntero, mostrarTexto, cortarTexto};
}

export default useOperations;