import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { loadState, saveState } from "./localStorage";
import { localStorageName } from "./localStorageData";

const useProtectedRoutes = () => {

    //////////////////////////// OBJETO PARA EL COMPONENTE CONFIRMACION (INCLUIDO EN ListadoProcedimientos)

    const objDataPopup = {
        titulo: 'Redirección',
        mensaje: 'Ha sido redireccionado a la página principal de la aplicación. No está permitida la navegación directa por URL.'
    };

    /**
     * @function inicializarCondiciones Función que inicializa las condiciones obtenidas que condicionan el acceso a las pantallas de la aplicación
     * @returns Objeto que contiene las condiciones de cada pantalla.
     * IMPORTANTE: El nombre de la key es el valor de la path que recibe el objeto ProtectedRoute a excepción de la /
     * Ej.: Si la path es /MenuNavegacion, se extrae la / y quedaría MenuNavegacion
     */
    const inicializarCondiciones = () => {

        // Paso 1. Se obtienen los valores del localstorage
        let infoUsuario = loadState(localStorageName.infoUsuario, true);
        let username = loadState(localStorageName.username, false);
        let convocatoriaSeleccionada = loadState(localStorageName.convocatoriaSeleccionada, true);
        let procedimientoActual = loadState(localStorageName.procedimientoActual, true);
        let loginOut = loadState(localStorageName.loginOut, false);

        // Paso 2. Se definen las condiciones para cada pantalla

        /**
         * @constant loggedUser Constante que indica si el usuario está logado.
         * Al logarse el usuario en la aplicación se crea un nuevo valor (infoUsuario)
         * en el localStorage e inserta información referente al usuario.
         * Se tiene en cuenta si se ha cerrado sesión estando en cualquier otra parte
         * de la aplicación, para evitar que se lance el mensaje de redireccionamiento.
         */
        const loggedUser = infoUsuario === undefined && loginOut === undefined;

        /**
         * @constant loggedAdminUser Constante que indica si se ha logado un usuario Administrador.
         * Al logarse un usuario administrador, el valor username aparecerá informado con su DNI.
         * Se tiene en cuenta si se ha cerrado sesión estando en cualquier otra parte
         * de la aplicación, para evitar que se lance el mensaje de redireccionamiento.
         */
        const loggedAdminUser = loginOut === undefined && username === undefined || (username === 'aplicacion' || username === 'ciudadano');

        /**
         * @constant accessToApp Constante que indica si se puede acceder a la aplicación una vez se ha pasado la
         * pantalla de selección de convocatoria.
         */
        const accessToApp = loggedUser || procedimientoActual === undefined || convocatoriaSeleccionada === undefined;

        // Paso 3. Se devuelve un objeto con las condiciones para cada pantalla

        return {
            LoginAdministrador: loggedUser,
            MenuNavegacion: loggedAdminUser,
            ListadoFormularios: loggedAdminUser,
            CreacionFormularios: loggedAdminUser,
            BandejaSolicitudes: accessToApp,
            NuevaSolicitud: accessToApp,
            AporteDocumentacion: accessToApp,
            FirmaSolicitud: accessToApp,
            SolicitudPresentadaCorrectamente: accessToApp,
            HistoricoDocumentos: accessToApp,
            Desistimiento: accessToApp,
            AltaAnexo: accessToApp,
            AltaEntidadColaboradora: accessToApp,
            DescargarDocumentos: loggedUser
        }
    }

    //////////////////////////// FUNCIONES

    /**
     * @function ProtectedRoute Función que en caso de que se cumpla una serie de condiciones redijirá hacia
     * el home de la aplicación, en caso contrario continuará la navegación
     * @param {*} param recibe el componente y la path (ruta) de este
     * @returns Devuelve la redirección en caso de que se cumpla las condiciones o la ruta del componente en
     * caso de que no se cumplan las condiciones
     */
    const ProtectedRoute = ({ component: Component, path }) => {
        let loginOut = loadState(localStorageName.loginOut, false);
        // Paso 1. Se inicializan las condiciones
        let condiciones = inicializarCondiciones();

        /* Paso 2. Obtención de la key de la condición.
         * Para obtenter la condición, se aprovecha el nombre de la path y se quita el primer caracter (/).
         * Ej.: Si el path es /MenuNavegacion, se recorta la /.
        */
        let condicion = path.substring(1, path.length);
        let res = null;
        
        /* Paso 3. Acceso a la pantalla solicitada o redireccionamiento al home de la aplicación.
         * Si se cumple la condición se redireccionará al home de la aplicación, de lo contrario se navegará a la pantalla indicada.
        */
        if (condiciones[condicion]) {
            // Se almacena para mostrar en el listado de procedimientos un popup informando del redireccionamiento
            saveState(localStorageName.protectedRoute, true, false);
            res = <Redirect to='/' />
        } else {
            loginOut && localStorage.removeItem(localStorageName.loginOut);
            res = <Route path={path} exact component={Component} />;
        }
        return res;
    }
    return { ProtectedRoute, objDataPopup };
}

export default useProtectedRoutes;