import { createMuiTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//Estilo genérico importado
import { colores, estilosTextosPrincipales, tipografia } from '../../../AppStyle';


export const styleAlert = makeStyles ({
    root: {
        height: 'fit-content',
        minHeight: '57px',
        width: '100%',
        textAlign: 'justify',
        background: colores.estado.amarilloAlerta,
        display: 'flex',
    },

    descripcionAviso: {
        height: 'fit-content',
        width: 'fit-content',
        color: colores.escalaGrises.cien,
        ...estilosTextosPrincipales.body1,
        textAlign: 'initial'
    },

    tituloAviso: {
        height: 'fit-content',
        minHeight: '20px',
        width: 'fit-content',
        color: colores.escalaGrises.cien,
        ...estilosTextosPrincipales.encabezadoH5,
    },

    btnNav: {
        padding: '0 5px',
        width: '5%',
        minWidth: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left',
    },

    btnClose: {
        padding: '0 5px',
        width: '5%',
        minWidth: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'right',
        marginLeft: 'auto',
    },

    iconClose: {
        padding: '3px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
        },
        '&.Mui-focusVisible':{
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
        }
    },

    iconBtn: {
        height: 'auto',
        width: '24px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
        },
        '&.Mui-focusVisible':{
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
        }
    },

});

export const overAvisos = createMuiTheme({
    overrides: {
       MuiAlert:{
            root:{
                padding: '0px 16px',
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: `0px 0px 0px 0px ${colores.sombras.sombraNegra} 0px 0px 0px 0px ${colores.sombras.sombraNegra} 0px 0px 0px 0px ${colores.sombras.sombraNegra}`,
            }
        },
        MuiTypography: {
            gutterBottom: {
                marginBottom:'0px'
            }
        }
    }
});




