import decodeJwt from 'jwt-decode';
import axios from 'axios';
import queryString from 'query-string';
import {localStorageName} from './localStorageData';
import { useContext} from 'react';
import AppContext from 'AppContext';
import { properties } from 'AppData';
import lodash, { update } from 'lodash';

const AuthProvider = () => {
    const contexto = useContext(AppContext);


    const clearLocalStorage = () => {
        localStorage.removeItem(localStorageName.username);
        localStorage.removeItem(localStorageName.token);
        localStorage.removeItem(localStorageName.refresh_token);
        localStorage.removeItem(localStorageName.expiredTimeRefresh);
        localStorage.removeItem(localStorageName.permissions);
        localStorage.removeItem(localStorageName.credencialGuardada);
    }

    // called when the user attempts to log in
    const login = async (user, pass, refreshToken) => {                
        // Obteniendo los datos necesarios de Keycloak
        var token = localStorage.getItem(localStorageName.token);
        localStorage.setItem(localStorageName.token, localStorageName.obteniendo_token);
        
        var client_id;
        var username;
        var refresh_token;
        if(user === undefined) {
            username = 'aplicacion';
        } else {
            username = user;
        }

        if(refreshToken) refresh_token = localStorage.getItem(localStorageName.refresh_token);
        const url = refreshToken 
        ? `/keycloak/refresh/${username}`
        : `/keycloak/login/${username}`;
    
        return axios.get(url, {
            headers: {
                charset: 'UTF-8',
                ...(refreshToken && { 'Authorization': `${refresh_token}` })
            }
        })
        .then(({ data }) => {
            localStorage.setItem(localStorageName.username, username);
            
            localStorage.setItem(localStorageName.token, data.access_token);
            const decodedToken = decodeJwt(data.access_token);
            localStorage.setItem(localStorageName.expiredTime, decodedToken.exp);
            
            localStorage.setItem(localStorageName.refresh_token, data.refresh_token);
            const decodedRefreshToken = decodeJwt(data.refresh_token);
            localStorage.setItem(localStorageName.expiredTimeRefresh, decodedRefreshToken.exp);

            const permissions = lodash.get(decodedToken, `resource_access[${client_id}].roles`, null);
            localStorage.setItem(localStorageName.permissions, permissions);

        }).catch(error => {
            console.error("Error al hacer login:", error);
            if(username === 'aplicacion'){
                logout();
            }else{
                localStorage.setItem(localStorageName.token, token);
            }
                        
            let respuesta = {
                response: {
                  status: "401",
                  data: {
                    messageDescription: "No Autorizado"
                    }
                }
            };                
            return Promise.reject(respuesta);
        });
    };

    // called when the user clicks on the logout button
    const logout = async () => {
        try {
            clearLocalStorage();
            await login('aplicacion', '', false);
        } catch (error) {
            console.error("Error al hacer logout:", error);
        }
       
        //return Promise.resolve();
    };
    // called when the API returns an error
    const checkError = ({ status }) => {
        if (status === 401) {
            logout();
            return Promise.reject();
        } else if (status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    };
    // called when the user navigates to a new location, to check for authentication
    const checkTokenTime = async (refreshToken) => {
        let lifespan;
        let notExpired;

        if(refreshToken){
            lifespan = localStorage.getItem(localStorageName.expiredTimeRefresh) > (Date.now()/1000);
            notExpired = localStorage.getItem(localStorageName.refresh_token) && lifespan;
        }else{
            lifespan = localStorage.getItem(localStorageName.expiredTime) > (Date.now()/1000);
            notExpired = localStorage.getItem(localStorageName.token) && lifespan;
        }

        return notExpired;
    };
    // called when the user navigates to a new location, to check for permissions / roles
    const getPermissions = () => { 
        return localStorage.getItem('permissions');
        //return role ? Promise.resolve(role) : Promise.reject();
    };

    const updateAccessToken = async (username) => {
        const refresh_token = localStorage.getItem(localStorageName.refresh_token)
        const url = `/keycloak/refresh/${username}`
        
    
        return axios.get(url, {
            headers: {
                charset: 'UTF-8',
                'Authorization': refresh_token
            }
        })
        .then(({ data }) => {
            localStorage.setItem(localStorageName.username, username);
            localStorage.setItem(localStorageName.token, data.access_token);
            const decodedToken = decodeJwt(data.access_token);
            localStorage.setItem(localStorageName.expiredTime, decodedToken.exp);

         
        }).catch(error => {
            console.error("Error al actualizar el token:", error);
            if(username === 'aplicacion'){
                logout();
            }
        });
    }

    const getToken = async () => {
        let verificarTokenTime = 100;
        let access_token = localStorage.getItem(localStorageName.token);
        var username = localStorage.getItem(localStorageName.username);
        var notExpired = true;
        //2. Verificación de la expiración del access_token
        if (!username) {         
            username = 'aplicacion';
        }else{
            notExpired = await checkTokenTime(false);
        }

        //3. Si no se está obteniendo el token y el access_token ha expirado,
        //se comprueba la expiración del refresh_token
        if (access_token !== localStorageName.obteniendo_token && !notExpired) {
            notExpired = await checkTokenTime(true);
            //4. Si el refresh_token no ha expirado se refresca el access_token
            if(notExpired){      
                await updateAccessToken(username);  
                access_token = localStorage.getItem(localStorageName.token);
            }else{
                //5. Si el refresh_token ha expirado y el usuario es aplicacion, se solicita un nuevo access_token
                if(username === 'aplicacion'){
                    await login('aplicacion', '', false);
                    access_token = localStorage.getItem(localStorageName.token);
                }else {
                    //6. Si el refresh_token ha expirado y el usuario es ciudadano, se cierra sesión
                    contexto.updateSesion(properties.SESION_CADUCADA)
                    clearLocalStorage();
                    await login('aplicacion', '', false);
                    access_token = localStorage.getItem(localStorageName.token);
                }                    
            }
        } else {
            //6. Se solicita un access_token, en caso de que ya se este solicitando uno se pone a la espera las request
            if (access_token) {
                if (access_token === localStorageName.obteniendo_token) {
                    await checkToken(verificarTokenTime);
                    access_token = localStorage.getItem(localStorageName.token);
                }
            } else {                
                await login('aplicacion', '', false);
                access_token = localStorage.getItem(localStorageName.token);            
            }
        }
        return access_token;
    };    

    const getSecurityCheck = async () => {
        return localStorage.getItem(localStorageName.securityCheck);
    };    

    const checkToken = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let access_token = localStorage.getItem(localStorageName.token);
                if (access_token === localStorageName.obteniendo_token) {
                    resolve(checkToken(ms));
                }else{
                    resolve();
                }
            }, ms);
        });
    };

    return {getToken, login, logout, checkError, getPermissions, getSecurityCheck};
}
export default AuthProvider;