import { colores, estilosTextosPrincipales } from "AppStyle";
import { createMuiTheme, makeStyles } from '@material-ui/core';

const useStyles = () => {
    const style = makeStyles({
        classGridRoot: {
            position: 'fixed',
            zIndex: 1300,
            inset: 0,
            marginBottom: '0.25%',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
        },
        classGridContainer: {
            width: '90%',
            height: '65%',
            marginTop: '268.1px',
            marginLeft: '5%',
            marginRight: '5%',
            position: 'absolute',
            backgroundColor: colores.principales.blanco,
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
        },
        classGridLine: {
            marginLeft: '25%',
            marginRight: '25%',
            marginTop: '10%'
        },
        classGridState: {
            textAlign: 'center'
        },
        classGridPorcentage: {
            marginTop: '1%'
        },
        classTitle: {
            ...estilosTextosPrincipales.encabezadoH5,
            marginBottom: '1%'
        },
        classPorcentage: {
            ...estilosTextosPrincipales.body1,
            marginTop: '5%'
        }
    });

    const theme = createMuiTheme({
        overrides: {
            MuiLinearProgress: {
                barColorPrimary: {
                    backgroundColor: colores.principales.principal,
                },
                colorPrimary: {
                    backgroundColor: colores.principales.apoyo
                }
            }
        }
    });

    return { style, theme };
}

export default useStyles;