import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { ThemeProvider } from '@material-ui/styles';

export default function botoneraGenerica(props) {
    return (
        <Grid
            className={props.gridClassName}
            id={props.gridId}
            name={props.gridName}
        >
            <ThemeProvider theme={props.theme}>
                <ButtonGroup orientation={props.orientation}>
                    {
                        (props.object).map((button) =>
                            <Button
                                disableRipple
                                disableTouchRipple
                                disableFocusRipple
                                id={button[props.idButton]}
                                name={button[props.nameButton]}
                                onClick={props.onClick}
                            >
                                {button[props.textButton]}
                            </Button>
                        )
                    }
                </ButtonGroup>
            </ThemeProvider>
        </Grid>
    )
}