import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Table, TableContainer, TableHead, TableCell, TableBody, TableRow, ThemeProvider} from '@material-ui/core'
import { ReactComponent as AccesoProc} from '../../commons/table/img/acceder_ic-1.svg'
import useStyle from './TableStyle';
import {cabeceras, properties} from './TableData';
import {saveState, loadState} from 'providers/localStorage'
import {localStorageName} from 'providers/localStorageData';
import ObtenerTextoEnriquecido from '../textoEnriquecido/TextoEnriquecido';
import RestProvider from 'providers/RestProvider';


const Tabla = (props) => {

    /* Efecto aplicado para truncar el texto de la columna descripción.
     * Se capturan los elementos del DOM y se va recorreindo, si el contenido
     * del texto es mayor que 18, se trunca.
     */
    useEffect(()=> {
        if (document.querySelectorAll('#descripcion')) {
            let descripcion = document.querySelectorAll('#descripcion');
        
            for (const elem of descripcion) {
                if (elem.textContent.length > 18) {
                elem.innerText = elem.textContent.substring(0, 18) + '...';
            }
        }

       }
    },[document.querySelectorAll('.descripcion')]);

    const { customStyle, overrideStyle } = useStyle();
    const {crudOperation, crudOperationsType} = RestProvider();

    const history = useHistory(); //Para navegar entre paginas de la propia web
    const OCULTAR_COLUMNA_ESTADO = true;

    {/* Metodo que obtendrá las convocatorias de un procedimiento de la pct3*/}
    const obtenerConvocatoriaPct3 = async (idprocedimiento) =>{

        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_CONVOCATORIAS, { url: properties.RESOURCE_GET_CONVOCATORIAS + idprocedimiento, method: 'get'}).then(response => {
            
            response.data.forEach((convocatoria) => {
                convocatoria.perfiles.forEach(p => {
                    Object.assign(p, {"descripcion":p.codigo});
                    delete p.codigo;
                });

            });

            saveState(localStorageName.convocatoriasProcedimientoPCT, response.data , true);
            history.push('/SeleccionConvocatoriaPerfil/PCT3_' + idprocedimiento);
            localStorage.setItem(localStorageName.urlProcedenciaProcedimiento, `/PCT3_${idprocedimiento}`);
        })
        
    }

    {/*TMP: Metodo tmp para redirigir a pag seleccion convocatoria*/}
    const onClickSeleccionConvocatoriaTMP = (objProcediminto) =>{
        if (objProcediminto.externo){
            window.location.href = objProcediminto.url;
        }else{
            let objProcedimintoJson = JSON.stringify(objProcediminto);
            saveState(localStorageName.procedimientoActual, objProcedimintoJson , false);
            let procedimiento = loadState(localStorageName.procedimientoActual, true);
            obtenerConvocatoriaPct3(procedimiento.id);

        }
    }
    /*Declaramos variables para poder usar estilos de TableStyle dentro de los bucles */
    const classTablaContainer = customStyle('').classTablaContainer;
    const classCabecera = customStyle('').classCabecera;
    const classThead = customStyle('').classThead;
    const classEncabezado = customStyle('').classEncabezados;
    const classEncabezadoNombre = customStyle('').classEncabezadoNombre;
    const classEncabezadoAcciones = customStyle('').classEncabezadoAcciones;
    const classNombre = customStyle(props.isPhoneVersion).classNombre;
    const classEstado = customStyle('').classEstado;
    const classFamilia = customStyle('').classFamilia;
    const classInformacion = customStyle('').classInformacion;
    const classAcciones = customStyle('').classAcciones;
    const classCeldaPar = customStyle('').classCeldaPar;
    const classCeldaImpar = customStyle('').classCeldaImpar;
    const classIcono = customStyle('').classIcono;
    const classLink = customStyle(props.isPhoneVersion).classLink;
    const classParrafoCeldas = customStyle('').classParrafoCeldas;

    /*Funcion que se encarga de dibujar la tabla version responsive*/
    const renderTableResponsive = () =>{

        const tablaResponsive = [];

        let mainTablaList = conversionDataTablaResponsive(props);
    

        for (let index = 0; index < mainTablaList.length; index++) {
            let claseCss = classCeldaImpar;
            if( index % 2 != 0){
                claseCss = classCeldaPar;
            }
            tablaResponsive.push(renderCell(mainTablaList[index], claseCss, index));

        }

        return (
            <div>
                {tablaResponsive}
            </div> 
        )
    } 

    const conversionDataTablaResponsive = (listadoProcedimientos) =>{

        let mainTablaList = [];

        if(listadoProcedimientos.options.length > 0){
            listadoProcedimientos.options.forEach(element => {
                let tablaObject = []
                tablaObject.push({
                    ["cabecera"]: cabeceras[0], //Nombre
                    ["value"]: element.descripcion
                })
                /*  OCULTAMOS CABECERA ESTADO
                    tablaObject.push({
                        ["cabecera"]: cabeceras[1], //Estado
                        ["value"]: element.plazo
                    }) 
                */
                tablaObject.push({
                    ["cabecera"]: cabeceras[1], //Familia
                    ["value"]: element.descripcionCorta
                })
                tablaObject.push({
                    ["cabecera"]: cabeceras[2], //Descripción
                    ["value"]: campoInformacion(element.informacion)
                })
                tablaObject.push({
                    ["cabecera"]: cabeceras[3], //Acciones
                })
                mainTablaList.push(tablaObject)
            });
        }
        
        return (
            mainTablaList
        )
    } 

    /*Funcion que se encarga de diferenciar si hay que dibujar la celda correspondiente a Acciones a las demas la tabla version responsive*/
    const renderIcon = (numVueltas, valor, indice, nombreProcedimientoActual) =>{

        if(numVueltas == "4"){
            return(<TableCell className={classAcciones}> 
            <Link onClick={() => onClickSeleccionConvocatoriaTMP(props.options[indice])} title={properties.ALT_ICONO_PROCEDIMIENTO + nombreProcedimientoActual}>
                <AccesoProc title={properties.ALT_ICONO_PROCEDIMIENTO + nombreProcedimientoActual} alt={properties.ALT_ICONO_PROCEDIMIENTO}/> 
            </Link>
        </TableCell>)
        } else if (numVueltas == "0"){
            return(<TableCell className={classNombre}><Link className={classLink} onClick={() => onClickSeleccionConvocatoriaTMP(props.options[indice])} dangerouslySetInnerHTML={{__html:valor}}/></TableCell>)
        } else if (numVueltas == "1"){
            return(<TableCell className={classEstado}><p className={classParrafoCeldas} dangerouslySetInnerHTML={{__html: valor != undefined ? valor : 'Sin Información' }}/></TableCell>)
        } else if (numVueltas == "3"){
            return(<TableCell className={classFamilia}><p className={classParrafoCeldas} dangerouslySetInnerHTML={{__html: valor != undefined ? valor : 'Sin Información' }}/></TableCell>)
        } else {
            return(<TableCell className={classInformacion} title={valor != undefined ? valor : properties.TEXTO_NO_INFO}><p className={classParrafoCeldas} dangerouslySetInnerHTML={{__html: valor != undefined ? valor : 'Sin Información' }}/></TableCell>)
        }
    } 

    /*Funcion qie se encarga de dibujar las celdas*/
    const renderCell = (mainFilaList, clase, indice) => {
        let nombreProcedimientoActual = mainFilaList[0].value;
        return (
            <div className={clase}>            
                <TableContainer>
                        <Table>
                            {mainFilaList.map((fila, index) => (
                                <TableRow>
                                    <TableCell className={classCabecera} dangerouslySetInnerHTML={{__html: fila.cabecera}}/>
                                    {renderIcon(index, fila.value, indice, nombreProcedimientoActual)}                                
                                </TableRow>
                            ))}
                        </Table> 
                </TableContainer>
                <hr></hr>
            </div>
        )
    }

    const campoInformacion = (informacion) => {
        let info;

        if(informacion != undefined){
            let etHref = '<a href=';
            info = (informacion.length >= properties.MAX_INFO && !informacion.includes(etHref))
                    ? informacion.substring(0, properties.MAX_INFO) + properties.TEXTO_MAX_INFO 
                    : informacion;
            
        }else{
            // No hay información a mostrar
            info = properties.TEXTO_NO_INFO
        }   
        return info;
    }

    /** Función que se encarga en pintar todo el contenido referente a la pagina*/
    const renderTable = () => {
    return (
        <div className={classTablaContainer}>
            <TableContainer>
                <Table>
                    <ThemeProvider theme={overrideStyle}>
                        <TableHead className={classThead}>
                            <TableRow>
                            {cabeceras.map(cabecera => (
                                <TableCell className={
                                    cabecera === 'Nombre' ? classEncabezadoNombre : (cabecera === 'Acciones' ? classEncabezadoAcciones : classEncabezado)
                                }>{cabecera}</TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                    </ThemeProvider>
                    <ThemeProvider theme={overrideStyle}>   
                        <TableBody >
                        {props.options.map(procedimiento=>(
                            <TableRow key={procedimiento.id}>
                                {/* NOMBRE */}
                                <TableCell className={classNombre}><Link className={classLink} onClick={() => onClickSeleccionConvocatoriaTMP(procedimiento)} dangerouslySetInnerHTML={{__html: procedimiento.descripcion}}/></TableCell>
                                {/* ESTADO */} {/* OCULTAMOS EL ESTADO POR PETICIÓN DEL CLIENTE */}
                                {!OCULTAR_COLUMNA_ESTADO && (
                                    <TableCell className={classEstado}>
                                        <ObtenerTextoEnriquecido 
                                        className={classParrafoCeldas} 
                                        texto={procedimiento.plazo !== undefined ? procedimiento.plazo : 'Sin Información'}
                                        />
                                    </TableCell>
                                    )}    
                                {/* TIPO */}
                                <TableCell className={classFamilia}><ObtenerTextoEnriquecido className={classParrafoCeldas} texto={procedimiento.descripcionCorta != undefined ? procedimiento.descripcionCorta : 'Sin Información'}/></TableCell>
                                {/* INFORMACIÓN */}
                                <TableCell id='descripcion' className={classInformacion} title={procedimiento.informacion != undefined ? procedimiento.informacion : properties.TEXTO_NO_INFO}><ObtenerTextoEnriquecido className={classParrafoCeldas} texto={campoInformacion(procedimiento.informacion)}/></TableCell>
                                {/* ACCIONES */}
                                <TableCell className={classAcciones}> 
                                    <Link className={classIcono} onClick={() => onClickSeleccionConvocatoriaTMP(procedimiento)} title={properties.ALT_ICONO_PROCEDIMIENTO + procedimiento.descripcion}>
                                       <AccesoProc title={properties.ALT_ICONO_PROCEDIMIENTO + procedimiento.descripcion} alt={properties.ALT_ICONO_PROCEDIMIENTO}/>
                                    </Link>
                                </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </ThemeProvider>
                </Table>
            </TableContainer>
        </div>
    
    );
}

return (
    <div>
        {props.isPhoneVersion ? (
            renderTableResponsive()
        ) : (  
            renderTable()
        )}    
        </div>
)



}

export default Tabla;
