import React, { useEffect } from "react";

const CordobaUtils = () => {
  useEffect(() => {
    const waitForPctmPageLoad = (retries = 5) => {
      if (typeof window.pctmPageLoad === "function") {
        window.pctmPageLoad();
      } else if (retries > 0) {
        console.warn(`pctmPageLoad no disponible, reintentando (${retries})...`);
        setTimeout(() => waitForPctmPageLoad(retries - 1), 1000);
      } else {
        console.error("No se pudo cargar pctmPageLoad después de varios intentos");
      }
    };

    const loadScripts = async () => {
      const scriptElements = document.querySelectorAll('script[src*="sede.js"]');

      if (scriptElements.length === 0) {
        console.warn("No se encontraron scripts con 'sede.js' en el src");
        waitForPctmPageLoad(); 
        return;
      }

      try {
        await Promise.all(
          Array.from(scriptElements).map(
            (script) =>
              new Promise((resolve) => {
                script.addEventListener("load", resolve, { once: true });
              })
          )
        );
        waitForPctmPageLoad();
      } catch (error) {
        console.error("Error al cargar los scripts:", error);
      }
    };

    // Intentar ejecutar inmediatamente si ya existe
    if (typeof window.pctmPageLoad === "function") {
      window.pctmPageLoad();
    } else {
        // Si no existe o no se ha cargado aún, empieza un proceso de reintentos con intervalos
      console.warn("pctmPageLoad no está disponible todavía, esperando carga de scripts...");
      loadScripts();
    }
  }, []);

  return null;
};

export default CordobaUtils;
