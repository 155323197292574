import { makeStyles } from '@material-ui/core/styles';
import { backgroundRectangle , estilosTextosPrincipales, colores, buttonPrimarySmall, buttonSecundaryBig, tipografia } from '../../../AppStyle';

const useStyles = (isPhoneVersion) => {
    
    const style = makeStyles({
        backgroundRectangle: {
            ...backgroundRectangle,
            marginBottom: '0px',
            paddingLeft: '2%',
        },
        tituloPagina:{
            ...estilosTextosPrincipales.titulosPagina,
        },
        hclassText2: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            fontSize: '15px',
            paddingTop: '2%'
        },
        gridBoton: {
            width:'100%', 
            paddingRight: '5%',
            ...(isPhoneVersion) && { paddingLeft: '5%', },
            margin: 0 ,
        },
        botonVolver:{
            ...buttonPrimarySmall,
            margin: '1% 0.5% 1% 1%',
            ...(isPhoneVersion) && { width: '100%', },
        },
        gridSubirArchivo: {
            paddingBottom: '2%',
            display: 'inline-flex'
        },
        botonFirma: {
            ...buttonSecundaryBig,
            margin: '1% 0.5% 1% 1%',
            ...(isPhoneVersion) && { width: '100%', },
        },
        classBackdrop: {
            zIndex: 1,
            color: colores.principales.blanco,
        },
        gridTitulo:{
            display: 'flex', 
            flexDirection: 'row',
            marginTop: '2%',
            marginLeft: '5%',
            width: '90%'
        },
        idExpediente:{
            marginLeft:'25px',
            color: colores.escalaGrises.sesenta,
            fontFamily: tipografia.bold,
            fontSize: '13px',
            height: 'inherit',
            display:'flex',
            alignItems: 'center'
        }
    });

    return {style}
}

export default useStyles;