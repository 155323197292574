export const InputDescripcion = {
    id: 'descripcionAnexo',
    name: 'descripcionAnexo',
    type: 'text',
    placeholder: 'Descripción del Anexo',
    maxlength: 100,
    helperText: '',
    titlTextField: 'Descripción del Anexo', 
    textRequired: '*',
    isRequired: true,
}

export const texts = {
    TAM: 'Tamaño ',
    PESO: ' MB',
    ELIMINAR: 'ELIMINAR',
    VER: 'VER',
    TEXTO_DROP: 'Subir Archivo',
}

        
        
