import React, { useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import useStyles from './lineaMetroStyle';
import { ThemeProvider } from '@material-ui/core';
import useOperations from './lineaMetroFunctions';

export default function LineaMetro(props) {
  const pasos = props.pasos;  
  const { isPhoneVersion, updateFormat } = useOperations();
  const { style, overrideStyle, QontoConnector } = useStyles(isPhoneVersion, pasos);  

    const classRoot = style().root;

    useEffect(() => {
      updateFormat();
      return () => window.removeEventListener('resize', updateFormat);
  }, []);

  window.addEventListener('resize', updateFormat);

    return (
        <div className={classRoot}>
            <ThemeProvider theme={overrideStyle}>
                <Stepper activeStep={props.activeStep} connector={<QontoConnector />} alternativeLabel>
                {pasos.map((label) => (
                    <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
                </Stepper>
            </ThemeProvider>
      </div>
    )
}
