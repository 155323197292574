import React, {useState, useContext, useEffect} from 'react';
import {loadState, saveState} from '../../../providers/localStorage';
import AppContext from 'AppContext';
import {localStorageName} from '../../../providers/localStorageData';
import { texts, properties, constantesEstadosFasePCT3, tipoFirma } from './FirmaSolicitudData';
import { useHistory } from "react-router-dom";
import {properties as appProperties} from 'AppData';
import RestProvider from 'providers/RestProvider' ;
import useApp from 'AppFunctions';

const useFunctions = () => {
    
    const PROPIEDAD_FILE_BASE64 = "base64,";
    const NOMBRE_DOC_SOLICITUD = "Solicitud.pdf";
    const ID_DOC_SOLICITUD = "Solicitud";
    const PREFIJO_OBJETO_FILE_PDF = 'data:application/pdf;base64,';

    const context = useContext(AppContext);
    const history = useHistory();
    const {crudOperation, crudOperationsType, obtenerProperties} = RestProvider();
    const { firmaGenericaMasiva, calcularHashBase64, obtenerCodigosFaseEstado } = useApp();
    
    //Hooks
    const [tituloPagina, setTituloPagina] = useState();
    const [procedimientoActual, setProcedimientoActual] = useState();
    const [NIFUsuario, setNIFUsuario] = useState();
    const [nombreApellidosUsuario, setNombreApellidosUsuario] = useState();
    const [docMarcado, setDocMarcado] = useState(ID_DOC_SOLICITUD);
    const [pdfMostrado, setPdfMostrado] = useState();
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [mostrarConfirmarFirma, setMostrarConfirmarFirma] = useState(false);
    const [mostrarConfirmarCorregir, setMostrarConfirmarCorregir] = useState(false);
    const [mostrarConfirmarCorregirSubsanacion, setMostrarConfirmarCorregirSubsanacion] = useState(false);
    const [mostrarErrorPresentacion, setMostrarErrorPresentacion] = useState(false);
    const [msgErrorPresentacion, setMsgErrorPresentacion] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [listDocs, setListDocs] = useState([]);
    const [nombrePdfDescargar, setNombreDescarga] = useState(ID_DOC_SOLICITUD);
    const [isProcessingFirma, setIsProcessingFirma] = useState(false);
    const [solicitudColaborativa, setSolicitudColaborativa] = useState(false);
    const [perfilSeleccionado, setPerfilSeleccionado] = useState(false);
    const [accionSeleccionada, setAccionSeleccionada] = useState(false);
    const [corregirSolicitud, setCorregirSolicitud] = useState(false);
    const [corregirSubsanacion, setCorregirSubsanacion] = useState(false);
    const [codigoSms, setCodigoSms] = useState(null);
    const [mostrarDialogo, setMostrarDialogo] = useState(false);
    const [convocatoriasColaborativasCodigo, setConvocatoriasColaborativasCodigo] = useState(null);
    const [perfilesColaborativosCodigo, setPerfilesColavorativosCodigo] = useState(null);
    ///Hooks gestion msg errores
    const [message, setMessage] = useState({msg: "", severity: ""});
    let _ID = 0;
    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const [alerts, setAlerts] = useState([]);

    let solAct = loadState(localStorageName.solicitudActual, true);
    let perfil = loadState(localStorageName.perfilSeleccionado, true);
    let convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);
    let infoUsuario = loadState(localStorageName.infoUsuario, true);
    let accion = loadState(localStorageName.accion, false);



    
    const addProperties = async () => {      
        const propertiesList = [
            'convocatorias.colaborativas.codigo', 'perfiles.colaborativos.codigo',
        ];

        try {
            let properties = await obtenerProperties(propertiesList);    
            // Actualiza el estado
            setPerfilesColavorativosCodigo(properties['perfiles.colaborativos.codigo'])
            setConvocatoriasColaborativasCodigo(properties['convocatorias.colaborativas.codigo'])
        } catch (error) {
            console.error('error obteniendo las properties', error);
        }
    };

    
    useEffect(() => {
        addProperties();
    }, []);
    

    //METODOS

    /**
     * @function obtenerAnexosYSolicitud Función que obtiene los anexos y la solicitud
     */
    const obtenerAnexosYSolicitud = async () =>{
        setIsLoading(true);

        //Array que se insertará en la hook listDocs, la cual mostrará la solicitud y los anexos existentes
        let documentos = [];

        //Paso 1. Obtención de la solicitud
        let pdfGenerado = loadState(localStorageName.pdfSolicitud, true);
        setPdfMostrado(pdfGenerado);

        //Paso 2. Generación del objeto que contiene la solicitud
        let solicitud = {
            texto: ID_DOC_SOLICITUD,
            nombreArchivo: NOMBRE_DOC_SOLICITUD,
            descripcion: ID_DOC_SOLICITUD,
            id: ID_DOC_SOLICITUD,
            pdf: pdfGenerado,
            tipoDoc: properties.TIPO_DOC_FORMULARIO
        }

        //Paso 3. Se inserta la solicitud
        documentos.push(solicitud);

        let faseEstado = loadState(localStorageName.faseEstadoExpediente, true);
        const request = {
            'idExpediente': solAct.id,
            ...faseEstado
        }
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DOCUMENTO, { url: properties.URL_OBTENER_ANEXOS_PCT3, method: 'POST', data: request}).then(response => {
            
            for (const doc of response.data) {
                let anexosObtenidos = {
                    texto: doc.confAnexo.nombre,
                    descripcion: doc.documento.descripcion,
                    id: doc.confAnexo.nombre, //El id debe ser el mismo que el texto para que se marque con el check el radiobutton
                    nombreArchivo: doc.documento.nombre,
                    pdf: PREFIJO_OBJETO_FILE_PDF + doc.fichero,
                    tipoDoc: doc.confAnexo.idTipoDoc,
                    idDoc: doc.documento.id,
                    firmable: doc.confAnexo.firmable
                }
                documentos.push(anexosObtenidos);
            }
        }).finally(function() {
            setListDocs(documentos);
            setIsLoading(false);
        });    
    }

    /**
     * Obtiene el procedimiento en el que se encuentra para actualizar el
     * titulo de la pagina
     */
     const obtenerTituloPagina = () =>{
        let primeraParte;
        let procedimiento = loadState(localStorageName.procedimientoActual, true);
        let fase = loadState(localStorageName.fase, false);
        setProcedimientoActual(procedimiento.descripcion);
        let accionModificar = loadState(localStorageName.accionModificar, false);
        let accionActual = loadState(localStorageName.accion, false);

        let textSolicitud = accionModificar ? texts.PAGE_TITLE_MOD : texts.PAGE_TITLE;;
        let textSubsanacion = accionModificar ? texts.PAGE_TITLE_SUBSANACION_MOD : texts.PAGE_TITLE_SUBSANACION;
        let textAlegacion = accionModificar ? texts.PAGE_TITLE_ALEGACION_MOD : texts.PAGE_TITLE_ALEGACION;
        let textJustificacion = accionModificar ? texts.PAGE_TITLE_JUSTIFICACION_MOD : texts.PAGE_TITLE_JUSTIFICACION;

        switch(fase){
            case properties.FASE_INICIO:
              primeraParte = textSolicitud;
              break;
            case constantesEstadosFasePCT3.F_INICIO_OV3:
              primeraParte = textSolicitud;
              break;
            case properties.FASE_PENDIENTE_DE_SUBSANAR:
              primeraParte = textSubsanacion;
              break;
            case constantesEstadosFasePCT3.F_SUBSANACION_OV3:
              primeraParte = textSubsanacion;
              break;
            case properties.FASE_PDT_ALEGACIONES:
              primeraParte = textAlegacion;
              break;
            case constantesEstadosFasePCT3.F_ALEGACIONES_OV3:
             primeraParte = textAlegacion;
              break;
            case properties.FASE_PENDIENTE_JUSTIFICACION:
              primeraParte = textJustificacion;
              break;
            case constantesEstadosFasePCT3.F_JUSTIFICACION_OV3:
              primeraParte = textJustificacion;
              break;
            default:
              primeraParte = texts.PAGE_TITLE;
              break;
          }
  
        setTituloPagina(primeraParte +  procedimiento.descripcion);
        context.updateTitlePage(primeraParte +  procedimiento.descripcion);
    }

    /**
     * Obtiene el NIF y nombre y apellidos del usuario logeado
     */
     const obtenerDatosUsuario = () =>{
        let infoUsuario = loadState(localStorageName.infoUsuario, true);
        let nombreApellidos = infoUsuario.interesadoNombre + texts.ESPACIO + infoUsuario.interesadoApellido1 + texts.ESPACIO + infoUsuario.interesadoApellido2;
        setNIFUsuario(infoUsuario.interesadoNif);
        setNombreApellidosUsuario(nombreApellidos);

    }

    //Metodo que te lleva al paso anterior, en este caso a Aporte Documentacion
    const atrasFormulario = () => {
        let redireccion = (perfilSeleccionado.descripcion === 'REPRESENTANTE_SICA' && solicitudColaborativa)
            ? properties.URL_NUEVA_SOLICITUD
            : properties.URL_APORTE_DOCUMENTACION;
        
        history.push(redireccion);
    };

    //Metodo para el cambio de valor de los radioButtons
    const handleChange = (e, newValue) => {
        let docMarcado = e.target.id;
        setDocMarcado(docMarcado);
        for (let doc of listDocs) {
            if (doc.id.toString() === docMarcado.toString()) { 
                setPdfMostrado(doc.pdf)
                setNombreDescarga(doc.nombreArchivo)
            }
        }
    };

    /**
     * @function updateFormat Segun el num de pixeles del ancho de la pantalla pone version movil o version ordenador
     */
     const updateFormat = () => {
        if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    //Metodo para mostrar el confirmar
    const mostrarConfirmacion = () => {
        setMostrarConfirmarFirma(true);
    }

    //Metodo para dejar de motrar el componente confirmar
    const dejarDeMostrarConfirmacion = () => {
        setMostrarConfirmarFirma(false);
    }

    //Metodo para mostrar el corregir confirmar
    const mostrarConfirmacionCorregir = () => {
        setMostrarConfirmarCorregir(true);
        setCorregirSolicitud(true);
    }

    //Metodo para mostrar el corregir confirmar subsanacion
    const mostrarConfirmacionCorregirSubsanacion = () => {
        setMostrarConfirmarCorregirSubsanacion(true);
        setCorregirSubsanacion(true);
    }

    //Metodo para dejar de motrar el componente confirmar
    const dejarDeMostrarConfirmacionCorregir = () => {
        setMostrarConfirmarCorregir(false);
        setCorregirSolicitud(false);
    }
    //Metodo para dejar de motrar el componente confirmar subsanacion
    const dejarDeMostrarConfirmacionCorregirSubsanacion = () => {
        setMostrarConfirmarCorregirSubsanacion(false);
        setCorregirSubsanacion(false);
    }

    //AUTOFIRMA
    const firmarSolicitud = () => {
        dejarDeMostrarConfirmacion();
        dejarDeMostrarConfirmacionCorregir();
        let listDocumentos = [];
        listDocs.forEach(doc => {
            if(doc.tipoDoc===properties.TIPO_DOC_FORMULARIO || doc.firmable){
                doc.esFirmable = true;
                listDocumentos.push(doc.pdf);
            }else{
                doc.esFirmable = false;
            }
        });
        let tpFirma = obtenerTipoFirma();
        if ((solicitudColaborativa && perfilSeleccionado.descripcion === 'INVESTIGADOR') || (tpFirma === tipoFirma.FIRMA_SMS)) {
            presentarSolicitud(null);
        }else{
            firmaGenericaMasiva(listDocumentos, presentarSolicitud, null);
        }
    }

    const firmarCorregirSolicitud = async () => {
        dejarDeMostrarConfirmacion();
        dejarDeMostrarConfirmacionCorregir();
        let listDocumentos = [];
        listDocs.forEach(doc => {
            if(doc.tipoDoc===properties.TIPO_DOC_FORMULARIO || doc.firmable){
                doc.esFirmable = true;
                listDocumentos.push(doc.pdf);
            }else{
                doc.esFirmable = false;
            }
        });
        presentarSolicitud(null);    
    }

    //PRESENTAR SOLICITUD
      const presentarSolicitud = (signatureBase64, certificate864) => {
        let inputPresentarSolicitud =  montarinputPresentarSolicitud(signatureBase64);
        setIsLoading(true);
        setIsProcessingFirma(true);
        let ruta = null;

        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_PRESENTAR_SOLICITUD_PCT3, {url:'', method: 'post', data:inputPresentarSolicitud}).then(response => {       
            if (!solicitudColaborativa || response.data.tieneRecibi === true) {
                saveState(localStorageName.recibi, response.data.recibi, false);
                saveState(localStorageName.numRegistroOrve, response.data.numRegistroOrve, false);
                saveState(localStorageName.numeroExpediente, response.data.numeroExpediente, false);
                saveState(localStorageName.idExpediente, response.data.idExpediente, false);
                ruta = properties.URL_SOLICITUD_PRESENTADA;
            } else {
                ruta = properties.URL_BANDEJA_SOLICITUDES;
            }

            setIsLoading(false);
            history.push(ruta);  

        }).catch(function (error) {
            setIsLoading(false);
            if(error.response){
                let msg = "Se ha producido un error genérico.";
                setMsgErrorPresentacion(msg + texts.PARTE_2_MSG_ERROR);
            }else{
                setMsgErrorPresentacion(texts.PARTE_1_MSG_ERROR + texts.PARTE_2_MSG_ERROR); 
            }   
            
            setMostrarErrorPresentacion(true);
        })
    }

    const dejarDeMostrarErrorPresentacion = () => {
        setMostrarErrorPresentacion(false);
    }

    const obtenerTipoDocumento = (tipoDocumento) => {
        let response = null;

        if(tipoDocumento==="AOB"){
            response = properties.TIPO_DOC_OBLIGATORIO
        }else if(tipoDocumento==="AOP"){
            response = properties.TIPO_DOC_OPTATIVO
        }else if(tipoDocumento==="AUS"){
            response = properties.TIPO_DOC_VOLUNTARIO
        }
        return response;
    }

    const montarinputPresentarSolicitud = (firma) => {
        let procedimiento = loadState(localStorageName.procedimientoActual, true);
        let convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);
        let userLogin = loadState(localStorageName.infoUsuario, true);
        let solicitud = loadState(localStorageName.solicitudActual, true);
        let fase = loadState(localStorageName.fase, false);
        let estado = loadState(localStorageName.estado, false);
        let perfil = loadState(localStorageName.perfilSeleccionado, true);
        let enumeradoProcesoEntrega = null;

        // Obtención de enumProcesoEntrega
        if (fase !== undefined && fase !== null) {
            // Solicitudes que pertenezcan a proyectos colaborativos en fase origen INICIO
            if (solicitudColaborativa) {
                switch (perfil.descripcion) {
                    case 'PROYECTO_COLECTIVO': 
                        if (accionSeleccionada === 'subsanar' && fase == constantesEstadosFasePCT3.F_SUBSANACION_OV3) {
                            enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_COD_SUBSANACION_SEQUIA;
                        } else if (accionSeleccionada === 'subsanarAltaAnexo') {
                           enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_COD_SUBSANACION_ALTA_ANEXO;
                        } else {
                            enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_ANEXO_A_LA_SOLICITUD;
                        }
                        break;
                    case 'INVESTIGADOR': 
                        if(accionSeleccionada === 'subsanar' || accionSeleccionada === 'corregirSubsanacion'){
                            enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_SOLICITAR_REVISION_SUBSANACION;
                        }else {
                            enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_SOLICITAR_REVISION;
                        }
                        break;
                    case 'REPRESENTANTE_SICA': 
                        if(corregirSolicitud === false && corregirSubsanacion === false && fase === constantesEstadosFasePCT3.F_SUBSANACION_OV3){
                            enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_FIRMAR_SUBSANACION_SICA;
                        }else if(corregirSolicitud){
                            enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_CORREGIR_SOLICITUD;
                        }else if(corregirSubsanacion){
                            enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_CORREGIR_SUBSANACION;
                        }else if(corregirSolicitud === false){
                            enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_FIRMAR_SOLICITUD_SICA;
                        }
                        break;
                }                   
            } else {
                switch (fase) {
                    case constantesEstadosFasePCT3.F_ALEGACIONES_OV3: enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_ALEGACION;
                        break;
                    case constantesEstadosFasePCT3.F_APORTE_DOCUMENTACION_OV3: enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_APORTE_DOCUMENTACION;
                        break;
                    case constantesEstadosFasePCT3.F_JUSTIFICACION_OV3: enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_JUSTIFICACION;
                        break;
                    case constantesEstadosFasePCT3.F_SUBSANACION_OV3: enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_SUBSANACION;
                        break;
                    case constantesEstadosFasePCT3.F_INICIO_OV3: enumeradoProcesoEntrega = properties.PROCESO_ENTREGA_SOLICITUD;
                        break;
                }
            }
        }

        let inputPresentarSolicitud = {
            enumProcesoEntrega: enumeradoProcesoEntrega,
            idExpediente: solicitud.id === undefined ? solicitud.idExpediente : solicitud.id, 
            numeroExpediente: solicitud.numeroExpediente,
            idConvocatoria:convocatoria.id,
            dniLogin: userLogin.interesadoNif,
            numeroSerieCertificadoLogin: userLogin.datosCertificado ? userLogin.datosCertificado.numeroSerie : null ,
            isloginCertificadoDigital:userLogin.tipoAutenticacion === appProperties.TIPO_AUTENTICACION_CERTIFICADO ? true : false,
            descripcionConvocatoria: convocatoria.descripcion,
            fechaInicioPresentacion:new Date(convocatoria.fechaInicioPresentacion),
            fechaFinPresentacion: new Date(convocatoria.fechaFinPresentacion),
            idProcedimiento: procedimiento.id,
            descripcionProcedimiento: procedimiento.descripcion,
            usuarioOrve: convocatoria.codOrveUsuario,
            passwordOrve:convocatoria.codOrvePassword,
            asuntoOrve: convocatoria.codOrveAsunto,
            destinatarioOrve: convocatoria.codOrveDestinatario,
            listDocumentos: montarDocumentosEntrega(firma), //TODO
            fase: fase,
            estado: estado,
            perfil: perfil,
            enumTipoFirma: obtenerTipoFirma()
        }
        
        return inputPresentarSolicitud;
    }

    /**
     * @function obtenerTipoFirma Función que encargada de obtener el tipo de firma que se realizará en el proceso de entrega
     */
    const obtenerTipoFirma = () => {
        let res = tipoFirma.FIRMA_SIMPLE;
        if  (infoUsuario.tipoAutenticacion !== undefined && infoUsuario.tipoAutenticacion !== '') {
            res = (convocatoria.firmaSms && infoUsuario.tipoAutenticacion === 'ur') ? tipoFirma.FIRMA_SMS : tipoFirma.FIRMA_SIMPLE;
        }

        return res;
    }

    const montarDocumentosEntrega = (firma) => {
        let arrayDocumentoEntrega = new Array(listDocs.length);
        let firmas = firma !== null ? firma.split(':') : null;
        let documentoEntrega;
        let indexFirmas = 0;

        listDocs.map((doc, index)=>{
            if(doc.tipoDoc===properties.TIPO_DOC_FORMULARIO){
                documentoEntrega = {
                    firmaBase64: firmas !== null ? firmas[index] : null,
                    hashDocumentoBase64: calcularHashBase64(listDocs[index].pdf),
                    documento: doc.pdf.split(PROPIEDAD_FILE_BASE64)[1],
                    nombreDocumento: doc.texto,
                    tipoDocumento: properties.TIPO_DOC_FORMULARIO,
                    descripcionDocumento: doc.descripcion,
                    idDoc: doc.idDoc,
                    firmado: true
                }
                indexFirmas++;
                
            }else{
                let firma = null;
                if(doc.esFirmable){
                    firma = firmas !== null ? firmas[indexFirmas] : null;
                    indexFirmas++;
                }
                documentoEntrega = {
                    firmaBase64: firma,
                    hashDocumentoBase64: calcularHashBase64(listDocs[index].pdf),
                    documento: doc.pdf.split(PROPIEDAD_FILE_BASE64)[1],
                    nombreDocumento: doc.texto,
                    tipoDocumento: properties.TIPO_DOC_ANEXO,
                    descripcionDocumento: doc.descripcion,
                    idDoc: doc.idDoc,
                    firmado: doc.esFirmable
                }
            }
            arrayDocumentoEntrega[index] = documentoEntrega;
        });
        return  arrayDocumentoEntrega;
    }

    /**
     * @function obtenerFasesEstados Función que obtiene los códigos de las fases y los estados buscados
     * @returns 
     */
    const obtenerFasesEstados = async () => {
        let constantes = [];

        let convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);

        // Paso 1. Obtiene las constantes decalradas en BandejaSolicitudesData y se insertan en el array cosntates.
        for (const key in constantesEstadosFasePCT3) {
            constantes.push(key);
        }

        // Paso 2. Se obtienen las fases y estados 
        let fasesEstadosObtenidos = await obtenerCodigosFaseEstado(convocatoria.id, constantes, constantes);        
        
        // Paso 3. Se recorren los valores obtenidos y se insertan en las constantes que se utilizaran en la pantalla
        for (const feo of fasesEstadosObtenidos.valores) {

            switch (feo.clave) {
                case 'EF_INICIADO_OV3':
                    constantesEstadosFasePCT3.EF_INICIADO_OV3 = feo.valor;
                    break;
                case 'EF_PENDIENTE_ALEGACION_OV3':
                    constantesEstadosFasePCT3.EF_PENDIENTE_ALEGACION_OV3 = feo.valor;
                    break;
                case 'EF_PENDIENTE_SUBSANAR_OV3':
                    constantesEstadosFasePCT3.EF_PENDIENTE_SUBSANAR_OV3 = feo.valor;
                    break;
                case 'F_INICIO_OV3':
                    constantesEstadosFasePCT3.F_INICIO_OV3 = feo.valor;
                    break;
                case 'F_ALEGACIONES_OV3':
                    constantesEstadosFasePCT3.F_ALEGACIONES_OV3 = feo.valor;
                    break;
                case 'F_SUBSANACION_OV3':
                    constantesEstadosFasePCT3.F_SUBSANACION_OV3 = feo.valor;
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * @function isSolicitudColaborativa Función que comprueba si se trata de una solicitud que pertenezca a un proyecto colaborativo.
     * Para que sea una solicitud colaborativa, la convocatoria debe ser colaborativa y además cuando se ha accedido a la convocatoria,
     * se ha seleccionado el perfil colaborativo.
     */
    const isSolicitudColaborativa = async () => {
        // Paso 1. Se obtienen las convocatorias y los perfiles colaborativos
        let convocatoriasColaborativas = convocatoriasColaborativasCodigo;
        let perfilesColaborativos = perfilesColaborativosCodigo;

        // Paso 2. Se genera el array de cada uno
        let perfilesObtenidos = perfilesColaborativos !== undefined ? perfilesColaborativos.split(', ') : null;
        let convocatoriasObtenidas = convocatoriasColaborativas !== undefined ? convocatoriasColaborativas.split(', ') : null;

        // Paso 3. Se evalúa si la convocatoria actual y el perfil seleccionado pertenecen a convocatorias y perfiles colaborativos
        if (perfilesObtenidos !== null && convocatoriasObtenidas !==  null) {
            let isPerfilColaborativo = perfilesObtenidos.includes(perfil.descripcion);
            let isConvocatoriaColaborativa = convocatoriasObtenidas.includes(convocatoria.codigo);
            setSolicitudColaborativa(isPerfilColaborativo && isConvocatoriaColaborativa);
            setPerfilSeleccionado(perfil);
            setAccionSeleccionada(accion);
        }
    }

    /**
     * @function handleSetMessage Gestiona los mensajes de por pantalla para errores, info, etc
     */
    const handleSetMessage = (msg, severity) => {
        setMessage({msg, severity});
        addAlert(message);
    }

    /**
     * @function handleShowDialogo Función encargada de mostrar el cuadro de diálogo
     */
    const handleShowDialogo = () => {
        setMostrarDialogo(true);
    }

    /**
     * @function handleHideDialogo Función encargada de ocultar el cuadro de diálogo
     */
    const handleHideDialogo = () => {
        setCodigoSms('');
        setMostrarDialogo(false);
    }

    /**
     * @function enviarCodigoSms Función encargada de enviar el código sms al ciudadano
     */
    const enviarCodigoSms = async () => {
        return null;
    }

    const handleChangeDialogo = (e) => {
        setCodigoSms(e.target.value);
    }

    /**
     * @function validarCodigoSms Función que evalúa si el código sms que ha insertado el usuario es el que ha recibido,
     * si es correcto realiza la presentación del expediente utilizando la firma sms
     * @param {*} e 
     * @returns 
     */
    const validarCodigoSms = async () => {
        return null;
    }

    /**
     * @function mostrarBtnFirmaCertOrClave Función que devuelve un valor booleando indicando.
     * Comprueba si el usuario se ha logado con certificado digital o con clave además, de si se trata de una solicitud colaborativa
     * @returns 
     */
    const mostrarBtnFirmaCertOrClave = () => {
        let res = false;

        if  (infoUsuario.tipoAutenticacion !== undefined && infoUsuario.tipoAutenticacion !== '') {
            res = ((infoUsuario.tipoAutenticacion === 'cd' || infoUsuario.tipoAutenticacion === 'cl' || infoUsuario.tipoAutenticacion === 'idp') && solicitudColaborativa === false);
        }

        return res;
    }

    return { obtenerTituloPagina, tituloPagina, procedimientoActual, obtenerDatosUsuario, NIFUsuario, nombreApellidosUsuario, 
        atrasFormulario, handleChange, docMarcado, listDocs, isPhoneVersion, updateFormat, pdfMostrado, mostrarConfirmarFirma, mostrarConfirmarCorregir, mostrarConfirmarCorregirSubsanacion,
        mostrarConfirmacion, mostrarConfirmacionCorregir, mostrarConfirmacionCorregirSubsanacion, dejarDeMostrarConfirmacion, dejarDeMostrarConfirmacionCorregir, dejarDeMostrarConfirmacionCorregirSubsanacion, firmarSolicitud, firmarCorregirSolicitud, mostrarErrorPresentacion, dejarDeMostrarErrorPresentacion,
        msgErrorPresentacion, isLoading, obtenerAnexosYSolicitud, nombrePdfDescargar, isProcessingFirma, presentarSolicitud,
        obtenerFasesEstados, solicitudColaborativa, isSolicitudColaborativa, perfilSeleccionado, accionSeleccionada, convocatoria, validarCodigoSms, codigoSms, handleShowDialogo, handleHideDialogo, mostrarDialogo, enviarCodigoSms, handleChangeDialogo,
        obtenerTipoFirma, message, setMessage, alerts, mostrarBtnFirmaCertOrClave
    };
}
export default useFunctions;