import React, {useState} from 'react';
//Componentes de material importados
import {Grid, Menu, MenuItem, MenuList, ThemeProvider, CircularProgress, Backdrop, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ListItemIcon from '@material-ui/core/ListItemIcon';
//Importación del objeto que contiene los atributos y el ***mock***
import { objAtributes, objText, properties } from './menuCuentaData';
//Importación del estilo a aplicar al componente
import Style, { overMenu } from './menuCuentaStyle';
//Iconos
import { ReactComponent as EntradaClave } from '../../pages/seleccionConvocatoriaPerfil/imagen/entrada-clave-ic.svg';
import { ReactComponent as CertificadoDigital } from '../../pages/seleccionConvocatoriaPerfil/imagen/certificado-digital-ic.svg';

import { saveState } from './../../../providers/localStorage';
import { localStorageName } from './../../../providers/localStorageData';
import { properties as propertiesAppData } from 'AppData';
import useApp from 'AppFunctions';

/**
 * @function MenuCuenta Función que devolverá el componente menú usuario.
 * El componente esta compuesto por un botón (<IconButton/>) de acceso al menú de usuario (<Menu/>) y este esta compuesto
 * de el nombre del usuario logado, un listado de las opciones del menu (<MenuList/>), las opciones del menú (<MenuItem/>)
 * y un botón para cerrar la sesión del usuario.
 * @returns Devuelve el componente en sí.
 */
export default function MenuCuenta(props) {
    //Variables y cambios de estado de los componentes del Menú
    const [message, setMessage] = useState({msg: "", severity: ""});
    const [isLoading, setIsLoading] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    let _ID = 0;

    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const [alerts, setAlerts] = useState([]);

    const handleClick = (event) => {   
        let element = document.getElementById(properties.ID_BUTTON);
        if(element){
            element.setAttribute(properties.ARIA_EXPANDED, true);
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        let element = document.getElementById(properties.ID_BUTTON);
        if(element){
            element.setAttribute(properties.ARIA_EXPANDED, false);
        }
        setAnchorEl(null);
    };

    const handleLoanding = (booleano) =>{
        setIsLoading(booleano);
    }

    //**Gestiona los mensajes de por pantalla para errores, info, etc */
    const handleSetMessage = (msg, severity) => {
        setMessage({msg, severity});
        addAlert(message);
    }

    const {firmar, loginIdp} = useApp(handleSetMessage, handleLoanding, true);

    const useLoginIdp = () => {
        setIsLoading(true);

        saveState(localStorageName.redirect, 'loginCabecera', false);
        saveState(localStorageName.origenLogin, '{"redireccion":"/"}', false);

        loginIdp(propertiesAppData.URL_LOGIN);
    }


    return (
            
                <Grid {...objAtributes.grid}>
                    <Grid {...objAtributes.gridMenuInicial} item>
                        <button id={properties.ID_BUTTON} className={props.isPhoneVersion ? Style().classBotonCabeceraResponsive : Style().botonCabecera} onClick={handleClick}
                        aria-label={properties.ALABEL_BUTTON} aria-expanded="false">
                            {objText.BOTON_CABECERA}
                        </button>
                    </Grid>
                        {/*///////////////////MENU Mi CUENTA*/}
                        <ThemeProvider theme={props.isPhoneVersion && overMenu }>
                        <Menu
                            {...objAtributes.menu}
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            className={Style().classMenu}
                        >
                            <span className={Style().classLabelMetodosAcceso} tabIndex={-1}>{objText.BOTON_CUENTACONTENIDO}</span>
                            {/*///////////////////OPCIONES DEL MENU*/}
                            <MenuList>{/*///////////////////LISTADO DE OPCIONES DEL MENU*/}
                                <MenuItem onClick={handleClose} className={Style().classMenuItem}>{/*///////////////////OPCIÓN DEL MENU*/}
                                    <ListItemIcon>
                                        <EntradaClave onClick={useLoginIdp} className={Style().classIcons}/>{/*ICONO*/}
                                    </ListItemIcon>
                                    <a className={Style().classMenuItem} onClick={useLoginIdp} {...objAtributes.opcMenu.opc1}>{objText.LOGINCLAVE}</a>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        </ThemeProvider>
                        <Backdrop className={Style().classBackdrop} open={isLoading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {alerts.map(m => (
                        <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({severity: "", msg: ""})}>
                            <Alert
                                severity={message.severity}
                                variant="filled"
                                >
                                {message.msg}
                            </Alert>
                        </Snackbar>
                        ))}
                </Grid>
    );
}