import React, {useState} from 'react';
import Popover from '@material-ui/core/Popover'; //Componente de material para que el elemento sea PopUp
import { Slide, ThemeProvider } from '@material-ui/core';
import {overPopover, customStyle} from './popUpStyle';
import MensajesAyuda from '../../pages/mensajesAyuda/mensajesAyuda';
import {objText, preguntas} from '../../pages/mensajesAyuda/mensajesAyudaData';
import {IconButton} from '@material-ui/core';
import {HelpOutlineRounded} from '@material-ui/icons'
import {properties, pages} from './PopUpData'

/**
 * @function PopUp hace que su contenido aparezca en modo PopUp
 * @param {*} props recibe el componente a través de las props y la variable isVisible para hacer el componente visible o no.
 * @returns devuelve el componente en si.
 */
export default function PopUp() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleChange = (event) => {
        let element = document.getElementById(properties.ID_BOTON);
        if(element){
            element.setAttribute(properties.ATRIBUTO_ARIA_EXPANDED, true)
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        let element = document.getElementById(properties.ID_BOTON);
        if(element){
            element.setAttribute(properties.ATRIBUTO_ARIA_EXPANDED, false)
        }
        setAnchorEl(null);
    };

    const paginaActual = () =>{
        const path = window.location.href.split('/').pop();
        switch(path) {
           case pages.HOME:
               return preguntas;
            default:
               return preguntas;
        }        
    } 

    return (
        <ThemeProvider theme={overPopover}>
                <IconButton id={properties.ID_BOTON} onClick={handleChange} aria-expanded='false' aria-label={properties.ARIA_LABEL_BOTON} className={customStyle().iconButton}>
                    <HelpOutlineRounded className={customStyle().iconoAyuda}/>
                </IconButton>
                <Popover
                    open={open}
                    onClose={handleClose}
                    transitionDuration={'auto'}
                    anchorReference="anchorPosition"
                    disableScrollLock
                    anchorPosition={{ top: 0, left: 0 }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                        transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}    
               
                >
                {/*/////////////////////////////////////////INSERTA EL SLIDE
                Recibe por parametros el contenido de este.
                El componente Slide hace que se desplace el contenido de un lado a otro.*/}

                <Slide className={customStyle().slide} direction="left" in={open} mountOnEnter unmountOnExit>
                    <div><MensajesAyuda handleChange={handleClose} preguntas={paginaActual()} textoTituloPopUp={objText}/></div>
                </Slide>

            </Popover>

        </ ThemeProvider>
    );
}