import React, { useEffect } from 'react';
import { properties, texts, tableHeader, table2Header, table3Header, keysTablaGenerica } from './HistoricoDocumentosData';
import useStyles  from './HistoricoDocumentosStyle';
import { Grid, Backdrop, Snackbar, CircularProgress } from '@material-ui/core';
import useFunctions from './HistoricoDocumentosFunction';
import TablaGenerica from 'components/commons/tablaGenerica/tablaGenerica';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import { compProperties, properties as appProperties } from './../../../AppData';
import ButtonVisualPdf from 'components/commons/buttonVisualPdf/ButtonVisualPdf';
import { Alert } from '@material-ui/lab';
import './HistoricoDocumentos.css';

export default function HistoricoDocumentos() {

    const { obtenerTituloPagina, tituloPagina, volverABandeja, isPhoneVersion, updateFormat, mostrarPDF, pdfNombre, cerrarAnexosDesdeTabla, 
            listadoDocumentos, docMarcado, pdfMostrado, handleChange, isLoading, obtenerHistoricoDocumentos, alerts, message, setMessage,
            tablaHistoricoAdjuntos, tablaHistoricoRecibi, tablaHistoricoSol,
            solicitudActual, obtenerValores, documentosObtenidos } = useFunctions();
 
    const { style } = useStyles();
    const customStyle = style();

    
    useEffect(() => {
        obtenerTituloPagina();    
        obtenerHistoricoDocumentos();
    }, []);

    useEffect(() => {
        documentosObtenidos.length !== 0 &&
            obtenerValores(documentosObtenidos);
    }, [documentosObtenidos])

    window.addEventListener('resize', updateFormat);

    return(
        <main>
            <Grid> 
                <Grid className={customStyle.gridPageTitle}>             
                    <Grid className={customStyle.gridTitulo} >
                        <h1 id={properties.ID_PAGE_TITLE} className={customStyle.pageTitle}>{tituloPagina}</h1>
                        <span className={customStyle.idExpediente}>{solicitudActual.numeroExpediente}</span>
                    </Grid>
                </Grid>
                <Grid className={customStyle.backgroundRectangle}>
                    <Grid>
                        <h2 className={customStyle.hclassText}>
                            {texts.TEXT_HISTORICO_SOLICTUD}
                        </h2>
                        <TablaGenerica headingColumns={tableHeader} tableData={tablaHistoricoSol} isPhoneVersion={isPhoneVersion} messageNoData={texts.TEXT_NO_DATOS_SOLICITUD} {...keysTablaGenerica}/>
                    </Grid>
                </Grid>
                <Grid className={customStyle.backgroundRectangle}>
                    <Grid>
                        <h2 className={customStyle.hclassText}>
                            {texts.TEXT_HISTORICO_RECIBI}
                        </h2>
                        <TablaGenerica headingColumns={table2Header} tableData={tablaHistoricoRecibi} isPhoneVersion={isPhoneVersion} messageNoData={texts.TEXT_NO_DATOS_RECIBI} {...keysTablaGenerica}/>
                    </Grid>
                </Grid>
                <Grid className={customStyle.backgroundRectangle}>
                    <Grid>
                        <h2 className={customStyle.hclassText}>
                            {texts.TEXT_HISTORICO_ADJUNTOS}
                        </h2>
                        <TablaGenerica headingColumns={table3Header} tableData={tablaHistoricoAdjuntos} isPhoneVersion={isPhoneVersion} messageNoData={texts.TEXT_NO_DATOS_ADJUNTO} {...keysTablaGenerica}/>
                    </Grid>
                </Grid>
                <Grid container className={customStyle.gridBoton} justify='flex-end' spacing={1}>
                    <button className={customStyle.botonVolver} onClick={volverABandeja}>{texts.TEXT_BOTON}</button>
                </Grid>
            </Grid>
            {/*Confirmar para mostrar pdf*/}
            <Confirmacion 
            accionConfirmacion={cerrarAnexosDesdeTabla} 
            mostrar={mostrarPDF} 
            isPhoneVersion={isPhoneVersion}
            titulo={texts.TEXT_LIST_DOC}
            mensaje={<ButtonVisualPdf listDoc={listadoDocumentos.docs} value={docMarcado} isPhoneVersion={isPhoneVersion} pdf={pdfMostrado} pdfNombre={pdfNombre} onChange={(e, newValue) => handleChange(e, newValue)}/>}
            botonera={compProperties.SOLO_CERRAR}
            quitarImg={true}
            tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_0}
            isPopUpPdf={true}
            textBotonConfirmarPersonalizado={false}
            isImagenPersonalizada={false}
            centrarContenido={false}
            isTextoEnriquecido={false}
            />
            <Backdrop className={customStyle.classBackdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {alerts.map(m => (
            <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
                <Alert
                    severity={message.severity}
                    variant="filled"
                >
                {message.msg}
                </Alert>
            </Snackbar>
        ))}
        </main>    
    );
}
