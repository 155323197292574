import React, { useEffect } from "react";
import { Grid, Backdrop, CircularProgress, ThemeProvider } from '@material-ui/core';
import { texts, properties, propertiesBtns } from './PageAltaAnexoData';
import useStyles from './PageAltaAnexoStyle';
import { loadState } from 'providers/localStorage';
import { localStorageName } from "providers/localStorageData";
import { Snackbar } from "material";
import { Alert } from "@material-ui/lab";
import { compProperties, properties as appProperties } from './../../../AppData';
import useFunctions from './PageAltaAnexoFunctions';
import SubirArchivoVoluntario from "components/commons/subirArchivoVoluntario/SubirArchivoVoluntario";
import Confirmacion from "components/commons/Confirmacion/Confirmacion";
import { properties as AppData } from 'AppData';
import { getContextFromUrl } from "config/Config";
import ObtenerTextoEnriquecido from "components/commons/textoEnriquecido/TextoEnriquecido";

export default function AltaAnexo() {

    const { handleOnDrop, handleChange, eliminarAnexo, mostrarConfirmarErrorFichero, initAnexos,
        volverAbandeja, updateFormat, cerrarConfirmacionErrorFirma, comprobarEstadoEntidad,
        firmar, cerrarConfirmacionFirma, irFormularioAltaEntidadColaboradora,
        disabledBotonFirmar, documentosAnexos, values, isLoading, alerts, mostrarMsgSalir, dejarMostrarMsgSalir,
        message, setMessage, isPhoneVersion, msgErrorPresentacion, mostrarMsg, eliminarEntidadesColaboradoras,
        comprobarAllEstadosEntidades, mostrarErrorPresentacion, mostrarConfirmacionFirma, isSubsanacion, disabledBtnPresentSubsa } = useFunctions();

    const { style, theme } = useStyles(isPhoneVersion);
    const customStyle = style();

    // Carga de valores desde localStorage
    const solicitudActual = loadState(localStorageName.solicitudActual, true);

    // Se inserta el nombre del título de la página
    document.title = AppData.TITULO_PAGINA + (isSubsanacion() ? texts.PAGE_TITLE_SUBSA : texts.PAGE_TITLE);

    useEffect(() => {
        eliminarEntidadesColaboradoras();
        initAnexos();
    }, []);

    window.addEventListener('resize', updateFormat);

    return (
        <main>
            <Grid>
                <Grid className={customStyle.gridTitulo}>
                    <h1 className={customStyle.tituloPagina}>{(isSubsanacion() ? texts.PAGE_TITLE_SUBSA : texts.PAGE_TITLE)}</h1>
                    <span className={customStyle.idExpediente}>{solicitudActual.numeroExpediente}</span>
                </Grid>
                <Grid className={customStyle.backgroundRectangle}>
                    {   
                        isSubsanacion() &&
                            <Grid className={customStyle.gridParrafoSubsanacion}>
                                <ObtenerTextoEnriquecido className={customStyle.parrafoSubsanacion} texto={texts.TEXTO_SUBSANACION}/>
                            </Grid>
                    }
                    <Grid className={customStyle.gridSubirArchivo}>
                        {
                            documentosAnexos !== undefined && documentosAnexos.map((a, i) => {
                                let id = (a.id !== undefined && a.id !== null && a.id !== '') ? a.id : `anexo${i}`;
                                let campo = Object.keys(values)[i];
                                let entidadDeAlta = comprobarEstadoEntidad(a.titulo);
                                let estadoAllEntidades = comprobarAllEstadosEntidades();
                                return (
                                    <Grid className={customStyle.componente}>
                                        <ThemeProvider theme={theme}>
                                            <h3>{a.titulo}</h3>
                                            <Grid className={customStyle.gridAltaEntidad}>
                                                <img className={customStyle.icon} src={`${getContextFromUrl()}` + 'building.svg'}/>
                                                <button {...propertiesBtns.btnAltaInteresado} className={customStyle.botonAltaInteresado} onClick={e => irFormularioAltaEntidadColaboradora(e, a.titulo)} disabled={entidadDeAlta} alt={entidadDeAlta ? `La entidad ${a.titulo} ya ha sido dada de alta`: ''}>{texts.TEXT_BTN_ALTA_ENTI}</button>
                                            </Grid>
                                            {estadoAllEntidades &&
                                                <SubirArchivoVoluntario
                                                    id={id}
                                                    img={properties.PATH_IMG_PDF} 
                                                    tamMaximoArchivo={properties.TAM_MAX_ANEXO} 
                                                    formato={properties.FORMATO}
                                                    texto={a.value} 
                                                    handleOnDrop={(files) => handleOnDrop(files, id)}
                                                    onChange={e => handleChange(e, a, campo)} 
                                                    value={values[`val${i}`]} 
                                                    mostrarDrop={a.mostrarDropZone}
                                                    mostrarDocAnexo={a.mostrarDocAnexo} 
                                                    tamDoc={a.tamDoc} 
                                                    bottonFunction={e => eliminarAnexo(e, id, campo)}
                                                    handleRejection={(rejectedFiles) => mostrarConfirmarErrorFichero(rejectedFiles, properties.TAM_MAX_ANEXO)}
                                                    pdf={a.pdf} 
                                                    poderVisualizar={true}
                                                />
                                            }
                                        </ThemeProvider>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>
                <Grid container className={customStyle.gridBoton} justify='flex-end' spacing={1}>
                    {/** Botón de vuelta a la bandeja de solicitudes */}
                    <button className={customStyle.botonVolver} onClick={mostrarMsg}>{texts.TEXT_BOTON}</button>

                    {/** Botón para firmar los anexos */}
                    <button {...propertiesBtns.btnSaveFirmar} className={customStyle.botonFirmarAnexos} disabled={disabledBotonFirmar} onClick={firmar}>{texts.TEXT_FIRMAR_ANEXOS}</button>

                    { // Botón para presentar la subsanación (solo se mostrará cuando no se encuentre en el estado pendiente de subsanación de alta de anexo)
                        isSubsanacion() &&
                            <button {...propertiesBtns.btnPresentarSubsanacion} className={customStyle.botonFirmarAnexos} disabled={disabledBtnPresentSubsa} onClick={firmar}>{texts.TEXT_PRESENTAR_SUBSANACION}</button>
                    }
                </Grid>
            </Grid>
            <Confirmacion
                accionConfirmacion={cerrarConfirmacionFirma}
                titulo={texts.AVISO_FIRMA}
                mostrar={mostrarConfirmacionFirma}
                isPhoneVersion={isPhoneVersion}
                mensaje={texts.MESSAGE_FIRMADO}
                botonera={compProperties.CONFIR_SOLO_ACEPTAR}
                quitarImg={false}
                tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={false}
                isImagenPersonalizada={false}
                centrarContenido={true}
                isTextoEnriquecido={true}
            />
            <Confirmacion
                accionConfirmacion={cerrarConfirmacionErrorFirma}
                titulo={isSubsanacion() ? texts.ERROR_FIRMA_SUBSA_SIN_ENTI : texts.ERROR_FIRMA}
                mostrar={mostrarErrorPresentacion}
                isPhoneVersion={isPhoneVersion}
                mensaje={msgErrorPresentacion}
                botonera={compProperties.CONFIR_SOLO_ACEPTAR}
                quitarImg={false}
                tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={false}
                isImagenPersonalizada={false}
                centrarContenido={true}
                isTextoEnriquecido={true}
            />
            <Confirmacion 
                accionConfirmacion={volverAbandeja}
                accionDenegacion={compProperties.CONFIR_SIN_ACCION}
                handleChange={dejarMostrarMsgSalir}
                titulo={texts.TITLE_SALIR} 
                mostrar={mostrarMsgSalir} 
                isPhoneVersion={isPhoneVersion}
                mensaje={texts.TEXTO_SALIR}
                botonera={compProperties.CONFIR_CANCELAR_ACEPTAR}
                quitarImg={false}
                tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={false}
                centrarContenido={true}    
                isImagenPersonalizada={false}   
                isTextoEnriquecido={true}           
            />
            <Backdrop className={customStyle.classBackdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {alerts.map(m => (
                <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
                    <Alert
                        severity={message.severity}
                        variant="filled"
                    >
                        {message.msg}
                    </Alert>
                </Snackbar>
            ))}
        </main>
    );
}