export const properties = {
    RESOURCE : 'configuraciones',
    URL : 'avisos',
    HOME : 'SeleccionConvocatoriaPerfil',
    SEVERIDAD : 'INFO',
    TITLE_CERRAR: 'Cerrar',
    TITLE_ANT: 'Anterior',
    TITLE_SIG: 'Siguiente',
    V2: 'PCT2',
    V3: 'PCT3',
    URL_AVISOSOV3: 'avisosov3',
    URL_AVISOSOV3_INFORMATIVOS: '/avisosInformativos',
    URL_AVISOSOV3_INFORMATIVO_PROC: '/avisosInformativosProcedimiento',
}
