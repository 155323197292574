import React from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { saveState, loadState } from './../../../providers/localStorage';
import {localStorageName} from './../../../providers/localStorageData';
import AuthProvider from './../../../providers/authProvider';
import RestProvider from 'providers/RestProvider';
import {properties} from './RespuestaClaveData';

function RespuestaClave() {
    const {datosUsarioClave} = useParams();
    const history = useHistory(); //Para navegar entre paginas de la propia web
    const {login} = AuthProvider();
    const {crudOperation, crudOperationsType} = RestProvider();

    const crearObjetoCredencial = () => {
        
        var infoUsuario = loadState(localStorageName.infoUsuario, true)

        let credencialDto = {                
            tipoAutenticacion: infoUsuario.tipoAutenticacion,
            nifUsuario: infoUsuario.interesadoNif,
            nombre: infoUsuario.interesadoNombre,
            apellido1: infoUsuario.interesadoApellido1,
            apellido2: infoUsuario.interesadoApellido2,
            tipoCredencial: properties.TIPO_CREDENCIAL, 
        }

        return credencialDto;

    }

    let redireccion = loadState(localStorageName.origenLogin, true);
    let urlProcedenciaProcedimiento = loadState(localStorageName.urlProcedenciaProcedimiento, false);
    let urlRespuesta = redireccion.redireccion === '/SeleccionConvocatoriaPerfil'
        ? redireccion.redireccion + urlProcedenciaProcedimiento
        : redireccion.redireccion;

    try {    
        let datosUsuarios = datosUsarioClave;

        datosUsuarios = datosUsarioClave.replaceAll("-", "+");
        datosUsuarios = datosUsuarios.replaceAll("_", "/");

        var CryptoJS = require("crypto-js");
        var encryptedBase64Key = "MjIyMjQ0NDQ2NjY2ODg4OA==";
        var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);

        var datosDesencriptado = CryptoJS.AES.decrypt(datosUsuarios, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
        });

        var decryptedText = datosDesencriptado.toString(CryptoJS.enc.Utf8 );

        let objeto = null;
        if (decryptedText.startsWith("ERROR:")) {
            objeto = decryptedText.split("ERROR:");
            saveState(localStorageName.errorClave, '{"errorClave":{"mensaje":"'+ objeto[1] + '"}}',false);
        } else{
            objeto = decryptedText.split("~#");
            var usuarioClave = '{"interesadoNif":"'+ objeto[0] +'", "interesadoNombre":"'+ objeto[1] +'", "interesadoApellido1":"'+ objeto[2] +'", "interesadoApellido2":"'+ objeto[3] +'", "tipoAutenticacion":"idp"' + ', "entidadCif":'+ null + ', "entidadNombre":'+ null +'}';
            saveState(localStorageName.infoUsuario, usuarioClave, false);
            if(objeto.length > 4){
                saveState(localStorageName.securityCheck, objeto[4], false);
            }
            login('ciudadano', '', false);
        }


        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_VALIDACION, { url: properties.REGISTRAR_CREDENCIAL, method: 'post', data: crearObjetoCredencial()}).then(response => {                

         }).catch(function (error) {
             
         });

        history.push(urlRespuesta);
    } catch (error) {
        saveState(localStorageName.errorClave, '{"errorClave":{"mensaje":"Ha ocurrido un error al intentar realizar el login con Cl@ve"}',false);
        history.push(urlRespuesta);
    }

    return(
        <div/>
    )
    
}

export default RespuestaClave
