import { ReactComponent as Cehvron_bottom_ic } from './images/chevron_bottom_ic.svg';
import { ReactComponent as Exit_ic } from './images/exit_ic.svg';
import { getContextFromUrl } from 'config/Config';

export const pages = {
    HOME: '',
}
export const objAttb = {
    container: {
        id: 'contenedor',
        name: 'contenedor',
        maxWidth: false,
    },
    grids: {
        cabecera: {
            id: 'cabecera',
            name: 'cabecera',
        },
        cabeceraTexto: {
            id: 'cabeceraTexto',
            name: 'cabeceraTexto',
        },
        acordeon: {
            id: 'acordeon',
            name: 'acordeon',
        },
    },
    button: {
        id: 'btnCerrar',
        name: 'btnCerrar',
        type: 'submit',
        alt: 'Cerrar',
    },
    icon: <Exit_ic/>,//<img src='images/exit_ic.svg'></img>,
    acordeon: {
        expandIcon: <Cehvron_bottom_ic />,
    },
}

export const objText = {
    cabecera: {
        titulo: 'Ayuda al usuario',
        parrafo: 'Damos respuestas a las preguntas frecuentes de la Sede electrónica de la ',
    },
    acordeon: {
        titulo: 'Problemas de acceso',
    },
    button: 'Volver',
}

export const preguntas = [
    {
        id: '1001',
        descripcion: 'Manuales',
        mensajes: [
            {
                idDescripcion: '001',
                descripcionMensaje: 'Manual de Navegación',
                idMensaje: '001',
                contenidoMensaje: `<a target="_blank" href="${getContextFromUrl()}ManualNavegacionCiudadano.pdf" download>Descarga aquí el Manual de Navegación</a>`,
            }
        ],
    }
]