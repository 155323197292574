
function getUrl() {
    let url =  window.location.href.endsWith('/') ? window.location.href.substring(0, window.location.href.length-1) : window.location.href
    if (url.includes('/#')){
        url = window.location.href.substring(0,url.indexOf('/#'));
    }
    return url.endsWith('/') ? url : url.concat('/');
}

function getContextFromUrl() {
    let url = getUrl();
    let url_origin = window.location.origin;
    url = url.substring(url_origin.length, url.length)
    return url.endsWith('/') ? url : url.concat('/');
}

export { getUrl, getContextFromUrl };
