import React, { useEffect } from 'react';
import SelectSimple from '../../SelectSimple/Select';
import useOperations from './selectSimpleFormioFunction';

export default function Test(props) {
    const { select, options, handleChangeSelect, menuItem, getPais } = useOperations();

    //Variables de acceso rápido
    let controlador = props.controladorEndPoint;
    let url = props.urlEndPoint;
    let valorSelect = props.valorSelect;
    let valorAmostrarSelect = props.valorAmostrarSelect;

    useEffect(() => {
        if (controlador !== undefined || url !== undefined) {
            getPais(controlador, url);
        }
    }, []);

    return (
        <SelectSimple
            value={select}
            onChange={handleChangeSelect}
            inputLabel={props.label}
            id={props.id}
            name={props.name}
            disabled={false}
            map={menuItem(options, valorSelect, valorAmostrarSelect)}
        />
    );
}