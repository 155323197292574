import React, {useState, useContext} from 'react';
import {texts, properties, acciones} from './HistoricoDocumentosData';
import AppContext from 'AppContext';
import { useHistory } from "react-router-dom";
import {properties as appProperties} from 'AppData';
import RestProvider from 'providers/RestProvider';
import { localStorageName } from './../../../providers/localStorageData';
import { loadState } from './../../../providers/localStorage';

const useFunctions = () => {

    //Hooks
    const [tituloPagina, setTituloPagina] = useState();
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [mostrarPDF, setMostrarPDF] = useState(false);
    const [docMarcado, setDocMarcado] = useState('');
    const [pdfMostrado, setPdfMostrado] = useState('');
    const [pdfNombre, setPdfNombre] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [tablaHistoricoSol, setTablaHistoricoSol] = useState(['']);
    const [tablaHistoricoRecibi, setTablaHistoricoRecibi] = useState(['']);
    const [tablaHistoricoAdjuntos, setTablaHistoricoAdjuntos] = useState(['']);
    const [listadoDocumentos, setListadoDocumentos] = useState([]);
    const [documentosObtenidos, setDocumentosObtenidos] = useState([]);
    let valoresObtenidos = [tablaHistoricoSol, tablaHistoricoRecibi, tablaHistoricoAdjuntos];



    const context = useContext(AppContext);
    const history = useHistory();
    const { crudOperation, crudOperationsType } = RestProvider();

    let _ID = 0;

    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const [alerts, setAlerts] = useState([]);
    const [message, setMessage] = useState({msg: "", severity: ""});
    

    const solicitudActual = loadState(localStorageName.solicitudActual, true);

    /**
     * Obtiene el procedimiento en el que se encuentra para actualizar el
     * titulo de la pagina
     */
     const obtenerTituloPagina = () =>{

        setTituloPagina(texts.TEXT_PRINCIPAL);
        context.updateTitlePage(texts.PAGE_TITLE);
    }

    /**
     * Volver a la bandeja de entrada
     */
     const volverABandeja = () =>{
        history.push(properties.URL_BANDEJA)
    }

    /**
     * @function updateFormat Segun el num de pixeles del ancho de la pantalla pone version movil o version ordenador
     */
     const updateFormat = () => {
        if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    /**
     * @function obtenerListadoDocumentos Función que obtiene los valores a insertar en el listado de documentos
     * @param {*} historico Recibe el objeto que contiene los documentos a insertar
     */
    const obtenerListadoDocumentos = async (historico) => {
        let docs = [];
        //Paso 1. Se comprueba que el objeto recibido no es null o undefined
        if (historico !== undefined || historico !== null) {
            //Paso 2. Se recorre el objeto (historico) recibido y se va insertando los valores necesarios para el componente ButtonVisualPdf
            for (const valor of historico) {
                if (valor !== undefined) {
                    //Si el objeto no trae el nombre de fichero, usamos el del documento
                    valor.nombreFichero = (valor.nombreFichero !== undefined && valor.nombreFichero !== null) ? valor.nombreFichero : valor.nombreDocumento;
                    //Paso 2.1 En caso de que alguno de los valores del objeto contenga un array se recorrerá este para obtener los valores de sus objetos
                    if (Array.isArray(valor)) {
                        for (const valorObtenido of valor) {
                            if (valorObtenido !== undefined) {
                                let documento = null;
                                //Se solicita el fichero asociado al ID y se asigna al objeto.
                                if((valorObtenido.documento === undefined || valorObtenido.documento === null) && (valorObtenido.idDocumento !== undefined && valorObtenido.idDocumento !== null ) ){
                                    documento =  await getFichero(valorObtenido.idDocumento);
                                    Object.assign(valorObtenido, {'documento': documento});
                                }
                                //Se añade a la lista de documentos
                                if (documento !== null || (valorObtenido.documento !== undefined && valorObtenido !== null)){
                                    docs.push({
                                    texto: valorObtenido.nombreFichero,
                                    id: valorObtenido.documento.substr(0, (valor.length - 4)).toLowerCase(),
                                    pdf: `data:application/pdf;filename=${valorObtenido.nombreDocumento};base64,${valorObtenido.documento}`,
                                    nombreDocumento: valorObtenido.nombreDocumento
                                    });
                                }
                            }
                        }
                    } else {
                        let regexEspacioBlanco = /\s/.test(valor.nombreFichero);
                        let nombreFichero = ((!regexEspacioBlanco && valor.nombreFichero.length > 15) ? valor.nombreFichero.substring(0, 15).concat('...') : valor.nombreFichero);
                        docs.push({
                            texto: (valor.nombreFichero ? nombreFichero : undefined),
                            id: valor.documento.substr(0, (valor.length - 4)).toLowerCase(),
                            pdf: `data:application/pdf;filename=${valor.nombreDocumento};base64,${valor.documento}`,
                            nombreDocumento: valor.nombreDocumento
                        });
                    }
                }
            }
            //Paso 4. Se insertan los valores seleccionados en las hooks que recibirá el componente ButtonVisualPdf
            let documentosObtenidos = {docs};
            let texto = documentosObtenidos.docs[0].texto === undefined ? null : documentosObtenidos.docs[0].texto;
            let pdf = documentosObtenidos.docs[0].pdf === undefined ? null : documentosObtenidos.docs[0].pdf;
            let nombreDocumento = documentosObtenidos.docs[0].nombreDocumento === undefined ? null : documentosObtenidos.docs[0].nombreDocumento;

            setListadoDocumentos(documentosObtenidos);
            texto !== null && setDocMarcado(texto);
            pdf !== null && setPdfMostrado(pdf);
            nombreDocumento !== null && setPdfNombre(nombreDocumento);
        }       
    }

    /**
     * @function visualizarAnexoDesdeTabla mostrar pop up con visualizador
     */
    const visualizarAnexosDesdeTabla = async (event, identificador) => {
        let valoresObtenidos = [];
        let objetos = [];
        /* Paso 1. Estructura de control que comprueba si se trata del histórico de solicitud, en caso de ser así obtiene los objetos que contienen el
        *  histórico de solicitud y el historico de anexos. En caso contrario se obtendrá los demás objetos (histórico recibí e históricos adjuntos)
        */
        
        // Paso 1. Obtención de los valores del objeto (documento seleccionado)
        if (identificador !== undefined
            && (identificador.idSolicitud !== undefined || (identificador.historicoSolicitudDto !== undefined))) {
            
            // Paso 1.1.1. Se obtiene el documento de la solicitud
            objetos.push(identificador.historicoSolicitudDto);
            
            // Paso 1.1.2. Se obtienen los anexos en caso de que existan
            if (identificador.historicoAnexoDto !== undefined && identificador.historicoAnexoDto.length > 0) {
                objetos.push(...identificador.historicoAnexoDto);
            }
             // Paso 1.2. Se asignan los valores obtenidos del objeto (documento seleccionado)
            valoresObtenidos = [identificador.historicoSolicitudDto, identificador.historicoAnexoDto];
                
        }  else {
            // Valores obtenidos del objeto (documento seleccionado)
            objetos.push(identificador);
            valoresObtenidos = [identificador];
        }

        // Paso 2. Se procede a la obtención del fichero de cada documento
        for (const obj of objetos) {
            let fichero = await getFichero(obj.idDocumento);
            Object.assign(obj, {'documento': fichero});
        }

        // Paso 3. Se obtiene el listado de documentos
        await obtenerListadoDocumentos(valoresObtenidos);
        setMostrarPDF(true); 
    }

    /**
     * @function cerrarAnexosDesdeTabla cerrar pop up con visualizador
     */
     const cerrarAnexosDesdeTabla = () =>{
        setMostrarPDF(false); 
    }

    //Metodo para el cambio de valor de los radioButtons
    const handleChange = (e, newValue) => {
        setDocMarcado(newValue);
        for(let doc of listadoDocumentos.docs) {
            if(doc.pdf === newValue){
                setPdfMostrado(doc.pdf); //Inserta el documento pdf a mostrar
                setPdfNombre(doc.nombreDocumento); //Inserta el nombre del documento pdf cuando este se descargue
            }
        }
    };

    //**Gestiona los mensajes de por pantalla para errores, info, etc */
    const handleSetMessage = (msg, severity) => {
        setMessage({msg, severity});
        addAlert(message);
    }
    
    /**
     * @function obtenerHistoricoDocumentos Función que obtiene los valores de la pantalla Histórico de documentos según la procedencia del procedimiento
     */
    const obtenerHistoricoDocumentos = async () => {
        setIsLoading(true);
        //Paso 1. Se realiza la llamada
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_PCT3, { url: '/' + solicitudActual.idExpediente , method: 'get' }).then(response => {    
            //Paso 1.1 Se obtienen los valores necesarios
            setDocumentosObtenidos(response.data);
        }).catch(function(error) {
            handleSetMessage(error.messageDescription ? error.messageDescription : texts.MSG_ERROR , texts.SEVERITY);
                setIsLoading(false);
        });
        setIsLoading(false);
    }

    /**
     * @function valoresSeleccionados Función auxiliar que obtiene los valores seleccionados para cada tabla
     * @param {*} objeto Objeto original obtenido
     * @param {*} objetoRecibido Objeto recibido
     * @param {*} i Posición del objeto
     * @returns Objeto con los valores seleccionados
     */
    const valoresSeleccionados = async (objeto, objetoRecibido, i) => {
        let valoresSeleccionados;
        /* Estructura switch que según el objeto recibido, evalúa si se trata de uno de los objetos que contiene el objeto original.
        * Y en caso de que se produzca tal coincidencia, se crea un objeto con los valores que se mostrarán en la tabla.
        */
       /* Se inserta la función que se ejecutará */
       acciones[0].funcion = await visualizarAnexosDesdeTabla;
        switch(objetoRecibido) {            
            case objeto['historicoSolicitud']:
                valoresSeleccionados = {
                    identificador: objetoRecibido[i],
                    numExpediente: solicitudActual.numeroExpediente ,
                    fechaPresentacion: objetoRecibido[i]["fechaAlta"],
                    estado: objetoRecibido[i]["estado"],             
                    acciones: acciones
            };
                break;
            case objeto['historicoRecibi']:
                valoresSeleccionados = {
                    identificador: objetoRecibido[i],
                    nombreDocumento: objetoRecibido[i]["nombreFichero"],
                    fechaGuardado: objetoRecibido[i]["fechaGuardado"],
                    acciones: acciones
                };
                break;
            case objeto['historicoAdjuntos']:
                valoresSeleccionados = {
                    identificador: objetoRecibido[i],
                    fechaGuardado: objetoRecibido[i]["fechaAlta"],
                    nombreFichero: objetoRecibido[i]["nombreFichero"],
                    estado: objetoRecibido[i]["estado"],
                    acciones: acciones
                };
                break;
            default: valoresSeleccionados = null;
                break;
        }
        return valoresSeleccionados;
    }
    /**
     * @function obtenerValores Función que obtiene los valores a insertar en las tablas
     * @param {*} objeto Recibe el objeto que contiene los valores de los históricos
     */
    const obtenerValores = async (objeto) => {
        //Declaración de arrays que contendrán los valores seleccionados de cada objeto para insertar en las tablas
        let historicoSolicitud = [], historicoRecibi = [], historicoAdjutos = [];
        let objetoObtenido =  null;

        //Paso 1. Se comprueba si el objeto recibido no es null
        if (objeto !== null) {
            //Paso 2. Se obtiene las claves de los objetos que contienen los valores y se recorren estos
          let keysObjeto = Object.keys(objeto);
          for (const keys of keysObjeto) {
              //Paso 3. Se recorre el objeto recibido, accediendo a cada objeto que contiene a través de su key
            for (let i = 0; i < objeto[keys].length; i++) {
                //Paso 4. Se obtienen los valores seleccionados para insertar en su tabla correspondiente
                objetoObtenido = await valoresSeleccionados(objeto, objeto[keys], i);
                //Paso 5. Dependiendto del objeto que se esté recorriendo, se insertarán los valores obtenidos en su array correspondiente
                switch (objeto[keys]) {
                    case objeto['historicoSolicitud']: historicoSolicitud.push(objetoObtenido);
                        break;
                    case objeto['historicoRecibi']: historicoRecibi.push(objetoObtenido);
                        break;
                    case objeto['historicoAdjuntos']: historicoAdjutos.push(objetoObtenido);
                        break;
                }
            }
          }
        }
        //Paso 6. Se insertan los arrays con los valores obtenidos en la hook correspondiente
        setTablaHistoricoSol(historicoSolicitud);
        setTablaHistoricoRecibi(historicoRecibi);
        setTablaHistoricoAdjuntos(historicoAdjutos);
      };

    /**
     * @function getFichero Función encargada de obtener el fichero de un documento a través de su id documento
     * @param {*} idDocumento 
     * @returns 
     */
    const getFichero = async (idDocumento) => {
        let res = null;
        setIsLoading(true);

        // Paso 1. Obtención del fichero
        if (idDocumento !== undefined && idDocumento !== null) {
            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DOC_PCT3, { url: properties.URL_GET_FICHERO + idDocumento, method: 'get' }).then(response => {
                res = response.data;    
            }).catch(function(error) {
                handleSetMessage(error.messageDescription ? error.messageDescription : texts.MSG_ERROR , texts.SEVERITY);
            });
        }
        setIsLoading(false);
        return res;
    }
    
    return { obtenerTituloPagina, tituloPagina, volverABandeja, isPhoneVersion, updateFormat, mostrarPDF, pdfNombre, cerrarAnexosDesdeTabla,
            listadoDocumentos, docMarcado, pdfMostrado, handleChange, isLoading, obtenerHistoricoDocumentos, alerts, message, setMessage, obtenerValores,
            tablaHistoricoAdjuntos, tablaHistoricoRecibi, tablaHistoricoSol, valoresObtenidos, solicitudActual, documentosObtenidos };

}
export default useFunctions;