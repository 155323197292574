import React, {useEffect, useState} from 'react';
import Popover from '@material-ui/core/Popover';
import { Slide, ThemeProvider, Grid, Container } from '@material-ui/core';
import { overAvisosCriticos, customStyle } from './avisosCriticosStyle';
import { properties, texts, tipoAviso } from './AvisosCriticosData';
import { properties as appProperties } from '../../../AppData';
import { Backdrop } from '@material-ui/core';
import RestProvider from 'providers/RestProvider';
import { loadState, saveState } from './../../../providers/localStorage';
import {localStorageName} from './../../../providers/localStorageData';
import TextoEnriquecido from '../textoEnriquecido/TextoEnriquecido';

/**
 * @function AvisoCritico muestra un aviso critico en PopUp modal
 * @param {*} props recibe el componente a través de las props y la variable isVisible para hacer el componente visible o no.
 * @returns devuelve el componente en si.
 */

export default function(props) {
    const [isVisible, setVisible] = useState(false); //Estado visible de los avisos
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [avisosData, setAvisosData] = useState([]);
    const [titulo, setTitulo] = useState('');
    var contexto = loadState(localStorageName.procedimientoActual, true);
    const {crudOperation, crudOperationsType} = RestProvider();


    let estilos = customStyle() 

    useEffect(() => {        
        cambioPantalla();
        updateFormat();
        return () => window.removeEventListener('resize', updateFormat);
    }, []) ;
    
    const mostrarLineaAC = index => {
        if(index + 1 != avisosData.length){
            return <hr className={estilos.lineaHorizontalAvisos}/>
        }
    }

    const cambioPantalla = async () => {
        const path = window.location.href.split('/').pop();
        const pathAux = path.split('_');
        if(pathAux[0] === properties.V3){
            await getDataAvisosProcedimientoPCT3();
            crearTitulo('proc');
        }else if(!pathAux[0]){
            await getDataAvisos();
            crearTitulo('home');
        }
    }

    const crearTitulo = pantalla => {
        if(pantalla === 'home'){
            setTitulo(texts.TIT_GENERAL);
        }else{
            const nomProc = contexto.descripcion;
            const tituloProc = texts.TIT_PROCEDIMIENTO + nomProc;
            setTitulo(tituloProc);
        }
    }

    const getDataAvisos = async () => {
        let avisos =[];
        let avisosPCT3 = await getDataAvisosPCT3();

        if (avisosPCT3 !== undefined && avisosPCT3.length > 0 && avisosPCT3[0].length>0){
            avisosPCT3[0].forEach(element => {
                avisos.push({nombreAvisoNoticia : element.nombre, descripcion : element.descripcion});
            });
        }
    
        if(avisos.length > 0){
            setAvisosData(avisos);        
            setVisible(true);         
    
        }
    }

    /**Llamada al microservicio de motor-tramitacion para recuperar el listado de avisos generales PCT3 */
    const getDataAvisosPCT3 = async () => {
        let avisos = [];
        await crudOperation(crudOperationsType.CUSTOM, 'avisosov3', {url: '/avisosCriticos', method: 'get'}).then(response => {
            const avisosCriticos = response.data;
            if (avisosCriticos.length>0){
                avisos.push(avisosCriticos);
            }
        }).catch();

        return avisos;
    }

    const getDataAvisosProcedimientoPCT3 = async () => {
        crudOperation(crudOperationsType.CUSTOM, properties.URL_AVISOSOV3, {url:properties.URL_AVISOSOV3_CRITICOS_PROC + '/' + contexto.id, method: 'get'}).then(response => {            
            const avisosCriticosPorProc = response.data;
            let avisos =[];
    
            if (avisosCriticosPorProc !== undefined && avisosCriticosPorProc.length > 0){
                avisosCriticosPorProc.forEach(element => {
                    avisos.push({nombreAvisoNoticia : element.nombre, descripcion : element.descripcion});
                });
            }

            setAvisosData(avisos);
            if (avisos.length>0){
                setVisible(true);                 
            }
        }).catch();
    }    

    /**Salto a versión movil*/
     const updateFormat = () => {
        if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    };

    

    /**Guardar si se ha visualizado el aviso */
    const flagAvisosCriticos = () => {
        const path = window.location.href.split('/').pop();
        if(!path){
            saveState(localStorageName.flagAvisosCriticos, 'visto', false);
        }
    }
    
    window.addEventListener('resize', updateFormat);

    return (
        isVisible &&
        <ThemeProvider theme={overAvisosCriticos}>
          <Backdrop className={estilos.classBackdrop} open={isVisible}>                
            <Popover //Inserta el popUp
                open={isVisible}            
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                    }}
            >
                {/*/////////////////////////////////////////INSERTA EL SLIDE
                Recibe por parametros el contenido de este.
                El componente Slide hace que se desplace el contenido de un lado a otro.*/}
                <Slide direction="left" in={isVisible} mountOnEnter unmountOnExit>
                    <Container>
                        {/*TITULO AVISOS CRITICOS*/}                        
                        <Grid container className={estilos.gridSeccionTitulo} alignItems='center'>
                            <h5 className={estilos.gridSeccionTitulo} dangerouslySetInnerHTML={{ __html: titulo}}/>
                        </Grid>                        
                        <hr className={estilos.lineaHorizontal}/>                        
                            {
                                avisosData && avisosData.map((aviso, index) => {
                                    return(                                                                                                                              
                                        <Grid container direction='row' className={estilos.gridAC}>
                                            <Grid container className={isPhoneVersion ? estilos.colIconoResponsive : estilos.colIcono } >
                                                <img className={isPhoneVersion ? estilos.gridACIconoResponsive : estilos.gridACIcono} src={properties.AC_ICON_PATH} alt={texts.ALT_AC}/>
                                            </Grid>

                                            <Grid container className={isPhoneVersion ? estilos.colACResponsive : estilos.colAC}>                                            
                                                <Grid item>                                                    
                                                    <h5
                                                        dangerouslySetInnerHTML={{ __html: aviso.nombreAvisoNoticia}}
                                                        className={estilos.tituloAC}                                                        
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextoEnriquecido
                                                        texto={aviso.descripcion}
                                                        className={estilos.gridAvisoAC}
                                                    />
                                                </Grid>
                                                <Grid item className={estilos.gridLineaAC}>
                                                        {/*SI ES EL ULTIMO AVISO NO LLEVA LINEA DE SEPARACION*/}
                                                        {mostrarLineaAC(index)}
                                                </Grid>
                                            </Grid>
                                        </Grid>                                        
                                    )
                                })
                            }
                            {/*BOTON ENTENDIDO-CERRAR*/}
                            <Grid container className={estilos.gridSeccionTres} direction='row' justify='flex-end'>                                
                                <Grid item>
                                    <button className={estilos.botonEntendido} onClick={() => {setVisible(false); flagAvisosCriticos()}} >{texts.BOTON_ENTENDIDO}</button>
                                </Grid>                                
                            </Grid>
                    </Container>
                </Slide>                
            </Popover>
        </Backdrop>
    </ ThemeProvider>
    );
}
