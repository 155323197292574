import { makeStyles, withStyles } from '@material-ui/core/styles';
import {createMuiTheme} from '@material-ui/core';
import {colores, estilosTextosPrincipales} from '../../../AppStyle'
import StepConnector from "@material-ui/core/StepConnector";

const useStyles = (isPhoneVersion, pasos) => {

const style = makeStyles({
  root: {
    width: '100%',
  }
});

const overrideStyle = createMuiTheme({
  overrides: {
    MuiStepper: {
      root: {
        ...(isPhoneVersion) ? { padding: '0px', margin: '10% 0px' }  : { padding: '24px' }
      }
    },
    ...(isPhoneVersion) && {
      MuiStep: {
        horizontal: {
          /*Se calcula el tamaño de cada paso a partir del (tamaño de la pantalla menos el 10% de los margenes) y 
          dividido entre el (número de pasos el 25% de márgen entre cada paso)*/
          width: `calc((${window.screen.width}px - 10%) / ${pasos.length} - 25%)` 
        }
      }
    },
    MuiStepIcon: {
          root: {
              color: colores.principales.apoyo,
              fontSize: 'xx-large',
              '&$active': {
                color: colores.principales.principal,
                fontSize: 'xx-large',
              },
              '&$completed': {
                color: colores.principales.principal,
                fontSize: 'xx-large',
              },
          },
      },
    MuiStepConnector: {
      line: {
        borderColor: colores.principales.apoyo,
        marginTop: '2px',
        '&$active': {
          borderColor: colores.principales.principal,
        },
      }
    },
    MuiStepLabel: {
      label: {
        fontWeight: 'bold',
        '&$active': {
          fontWeight: 'bold',
        },
        '&$completed': {
          fontWeight: 'bold',
        },
      }
    },
    MuiTypography: {
      body2: {
        ...estilosTextosPrincipales.body2,
        fontWeight: '700 !important',
        ...(!isPhoneVersion) && {letterSpacing: '0.26px', fontSize: '15px'}
      }}
  }
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: colores.principales.principal,
    }
  },
  completed: {
      "& $line": {
      borderColor: colores.principales.principal,
      }
    },
  line: {
    borderColor: colores.principales.apoyo,
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

return { style, overrideStyle, QontoConnector };
}

export default useStyles;