export const localStorageName = {
    redirect : 'redirect',
    infoUsuario: 'infoUsuario',
    procedimientoActual: 'procedimientoActual',
    convocatoriaSeleccionada: 'convocatoriaSeleccionada',
    solicitudColaborativa: 'solicitudColaborativa',
    perfilSeleccionado: 'perfilSeleccionado',
    origenLogin: 'origenLogin',
    errorClave: 'errorClave',
    flagAvisosCriticos: 'flagAvisosCriticos',
    token: 'token',
    refresh_token: 'refresh_token',
    username: 'username',
    expiredTime: 'expiredTime',
    expiredTimeRefresh: 'expiredTimeRefresh',
    obteniendo_token: 'loading',
    permissions: 'permissions',
    misTramites: 'misTramites',
    misTramitesVphone: "misTramitesVphone",
    credencialGuardada: 'credencialGuardada',
    tipoProcedimientos: 'tipoProcedimientos',
    resourceFormNuevaSolicitud: 'ov3-formulariogenerico',
    solicitudActual: 'solicitudActual',
    formularioSolicitud: 'formularioSolicitud',
    pdfSolicitud: 'pdfSolicitud',
    recibi: 'recibi',
    numRegistroOrve: 'numRegistroOrve',
    idExpTrewa: 'idExpTrewa',
    fase: 'fase',
    estado: 'estado',
    protectedRoute: 'protectedRoute',
    convocatoriasProcedimientoPCT: 'convocatoriasProcedimientoPCT',
    urlProcedenciaProcedimiento: 'urlProcedenciaProcedimiento',
    faseEstadoExpediente: 'faseEstadoExpediente',
    loginOut: 'loginOut',
    accesoBandejaSol: 'accesoBandejaSol',
    numeroExpediente: 'numeroExpediente',
    idExpediente: 'idExpediente',
    desestimiento: 'desestimiento',
    accionModificar: 'accionModificar',
    idFase: 'idFase',
    idEstadoFase: 'idEstadoFase',
    entidadSeleccionada: 'entidadSeleccionada',
    entidadesDeAlta: 'entidadesDeAlta',
    accion: 'accion',
    organismo: 'organismo',
    securityCheck: 'securityCheck',
    popupProcInterno: 'popupProcInterno'
}