import React from "react";
//Componentes importados desde material ui
import { InputLabel, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
/**
 * 
 * @function ComponenteInput
 * Función compuesta por Grid, InputLabel, Input, FormHelperText
 * El componente InputLabel contiene el texto de la etiqueta del componente Input
 * El componente Input contiene el Input en si
 * El componente FormHelperText inserta el texto de ayuda en las validaciones de los campos
 * @param props
 * Las propiedades de los atributos y el estilo se reciben a través de las props
 */
export default function ComponenteInput(props) {
    return (
        <Grid
            id={props.idGrid}
            container={props.container}
            className={props.classes.classGrid}
        >
            {          
                props.isRequired &&
                    <Grid container>
                        <InputLabel
                            htmlFor={props.htmlFor}
                            className={props.classes.classInputLabel}
                            shrink
                        >
                        {props.titlTextField}
                        </InputLabel>
                        <span className={props.classes.classSpanRequired}>{props.textRequired}</span>
                    </Grid>
            }
            {          
                !props.isRequired &&
                    <InputLabel
                        htmlFor={props.htmlFor}
                        className={props.classes.classInputLabel}
                        shrink
                    >
                    {props.titlTextField}
                    </InputLabel>
            }
            <TextField
                className={props.classInput}
                id={props.id}
                name={props.titlTextField}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                disabled={props.disabled}
                helperText={props.helperText}
                variant='outlined'
                error={props.error}
                inputRef={props.inputRef}
                autoFocus={props.autoFocus}
                required={props.isRequired}
                aria-required={props.isRequired}
                autoComplete={props.autoComplete}
                onKeyPress={props.onKeyPress}
                inputProps={{
                    maxLength: props.maxlength
                }}
            />
        </Grid>
    )
}