import React, {useEffect, useState} from 'react';
import {Errors, FormGrid} from '@formio/react';
import {Backdrop, CircularProgress, Grid, Snackbar} from '@material-ui/core';
import {msgError, objAtrib, objText, properties} from './ListadoFormulariosData';
import {style, theme} from './ListadoFormulariosStyle';
import Pager from '../../commons/pager/Pager';
import TablePagination from '@material-ui/core/TablePagination';
import useOperations from './ListadoFormulariosFunction';
import FormioProvider from '../../../providers/FormioProvider';
import {useHistory} from 'react-router-dom';
import AuthProvider from 'providers/authProvider';
import {saveState} from '../../../providers/localStorage';
import {localStorageName} from '../../../providers/localStorageData';
import es from '../../../translations/es';
import '../../../BootstrapWrapper.scss';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import {Alert} from '@material-ui/lab';
import {ThemeProvider} from '@material-ui/styles';
import {properties as appProperties} from '../../../AppData';

export default function ListadoFormularios() {

  const [forms, setForms] = useState([]);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [maximo, setMaximo] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedForm, setSelectedForm] = useState({ name: '' });
  const { formioOperation, checkAppToken } = FormioProvider();
  const history = useHistory();
  const customStyle = style();
  const [image, setImage] = useState("");


  const { setListadoFormularios, isPhoneVersion, handleSetMessage,
    dataTableObject, headerTableObject, cancelarContinuacion, aceptarContinuacion, mostrarPopUp, setMostrarPopUp, deleteForm,
    mostrarImport, setMostrarImport, alerts, message, setMessage, aceptarContinuacionImport, cancelarContinuacionImport,
    aceptarContinuacionActu, cancelarContinuacionActu, mostrarImportActu, setMostrarImportActu, reload, setReload} = useOperations();

  let formularios = {
    forms: forms,
    pagination: {
      page: 0
    }
  }

  const columns = [{
    key: 'title',
    sort: true,
    title: 'Formulario',
    width: 4
  }, {
    key: 'type',
    title: 'Tipo',
    width: 3
  }, {
    key: 'operations',
    title: 'Operaciones',
    width: 5
  }];

  const options = {
    language: 'es',
    i18n: {
      'es': es
    }
  };
  const [isDelete, setIsDelete] = useState(false);

  const operations = [{
    action: 'edit',
    buttonType: 'secondary',
    icon: 'edit',
    permissionsResolver: function permissionsResolver() {
      return true;
    },
    title: 'Editar formulario'
  }, {
    action: 'delete',
    buttonType: 'danger',
    icon: 'trash',
    permissionsResolver: function permissionsResolver() {
      return true;
    },
    title: 'Eliminar formulario'
  },{
    action: 'download',
    buttonType: 'info',
    icon: 'download',
    permissionsResolver: function permissionsResolver() {
      return true;
    },
    title: 'Exportar formulario'
  }
  /*, {
    action: 'submission',
    buttonType: 'info',
    icon: 'list-alt',
    permissionsResolver: function permissionsResolver() {
      return true;
    },
    title: 'Rellenar formulario'
  }, {
    action: 'dataSubmitted',
    buttonType: 'warning',
    icon: 'info',
    permissionsResolver: function permissionsResolver() {
      return true;
    },
    title: 'Ver datos enviados'
  }*/];

  const handleAction = (form, action, e) => {

    switch (action) {
      case 'edit':
        history.push("/CreacionFormularios/" + form.path);
        break;
      case 'delete':
        setSelectedForm(form);
        setMostrarPopUp(true);
        break;
      case 'download':
        setSelectedForm(form);
        handleExportForm(form);
      break;
      case 'submission':
        //doRedirect(redirect, 'formulario', 'edit', form.path);
        break;
      case 'dataSubmitted':
        //doRedirect(redirect, 'submission/list', 'edit', form.path);
        break;
      default:
        return null;
    }
  }

  const handleDeleteForm = () => {
    setLoading(true);
    formioOperation(selectedForm.path, { method: 'delete', url: '' }, true).then(() => {
      setErrors(null);
      setReload(true);
      if (formularios.forms.length === 1 && page !== 0) {
        setPage(page - 1);
      }
      handleSetMessage('Se ha realizado correctamente la eliminación del formulario.', 'success');
    }).catch(error => {
      if (!checkAppToken(error)) {
        if (error && error.response) {
          setErrors(error.response);
        } else if (error && error.response && error.response.data) {
          setErrors(error.response.data);
        } else {
          setErrors(error);
        }
      }
      handleSetMessage('Se ha producido un error y no se ha podido eliminar el formulario.', 'danger');
    }).finally(() => {
      setLoading(false);
    });
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadForms();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    loadForms();
  };
 /**
  * @function handleExportForm Esta función sirve para exportar el json y descargarlo en nuestro equipo
  */
  const handleExportForm = (form) => {
    //Paso 1 Se vuelve loading true para que salga el efecto de carga
    setLoading(true);
    //Paso 2 Conectamos a la API de formio, le pasos metodo, direccion, si no conecta pasamos
    if (form === null || form === undefined) {
      handleSetMessage('Se ha producido un error y no se ha podido exportar el formulario.', 'danger');
    } else {
      //Paso 3 Guardamos el nombre de la respuesta para nuestro archivo mas tarde
      const nombreForm = form.name; 
      //Paso 4 Convertimos los datos que nos proporciona el objeto en un string
      const datosForm = JSON.stringify(form);
      //Paso 5 Creamos el objeto tipo fichero del json que nos llega en string
      const objetoBlob = new Blob([datosForm],{type:'application/json'});
      //Paso 6 Guardamos nuestra url a nuestro objeto blob
      const href = URL.createObjectURL(objetoBlob);
      //Paso 7 Creamos nuestro elemento de link
      const enlace = document.createElement('a');
      //Paso 8 En nuestro elemento de enlace le asignamos la URL de nuestro blob
      enlace.href = href;
      //Paso 9 En nuestro elemento le decimos que nos descargue el archivo con el nombre que guardamos con json
      enlace.download = nombreForm + ".json";
      //Paso 10 En el momento que hagamos click, activaremos el elemento y se nos bajará
      enlace.click();
      handleSetMessage('Se ha realizado correctamente la exportación del formulario.', 'success'); 
    }
    setLoading(false) ;
  }


  const loadForms = () => {
    setReload(true);
  }

  useEffect(() => {
    if (!loading && reload) {
      setLoading(true);
      formioOperation('form', { method: 'get', url: '', urlParams: { limit: rowsPerPage, skip: page * rowsPerPage, sort: 'title' } },true)
        .then(response => {
          let maximo = response.headers['content-range'];
          const maxx = parseInt(maximo.split('/')[1]);
          setMaximo(maxx);
          setForms(response.data);
          setErrors(null);
          window.scrollTo(0, 0);
        })
        .catch(error => {
          const errorFormio = "";
          if (checkAppToken(error)) {
            //notify("session_expired", 'warning');
          } else if (errorFormio) {
            setErrors(errorFormio);
          } else {
            setErrors(error.response.data);
          }
        })
        .finally(() => {
          setLoading(false);
          setReload(false);
        });
    }
  }, [loading, page, reload, rowsPerPage]);

  //Si en el popUp de confirmación de eliminación del formulario se acepta, se ejecuta el método handleDeleteForm
  useEffect(() => {
    deleteForm &&
      handleDeleteForm();
  }, [deleteForm]);

  const funcionOrdena = () => {
    console.log("FUNCIONA");
  };

  const objetoDatos = dataTableObject();
  const objetoCabecera = headerTableObject(funcionOrdena);
  const { getPermissions } = AuthProvider();
  const [pathOrigen, setPathOrigen] = useState('/ListadoFormularios');

  /**Devuelve los valores de la tabla */
  const getValoresTabla = async () => {
    formioOperation('form', { method: 'get', url: '', urlParams: { limit: rowsPerPage, skip: page * rowsPerPage, sort: 'title' } }, true).then(response => {
      setForms(response.data);
      setListadoFormularios(response.data);
    }).catch(function (error) {
      handleSetMessage(msgError.error, msgError.severity);
    });
  }
  /**Método que renderiza el paginador solo en versión ordenador */
  const renderPager = () => {
    if (!isPhoneVersion) {
      return <Pager recordsPerPage={properties.RECORDS_PER_PAGE} totalRecords={''} currentPage={''} paginate={''} numFilasPersonalizado={false}/>
    }
  }

  const redirigirNuevoFormulario = () => {
    saveState(localStorageName.redirect, pathOrigen, false);
    history.push("/CreacionFormularios");
  }





  return (
    //Grid principal
    <ThemeProvider theme={theme}>
    <Grid className="formioBuilderScope" container direction='column' justify='space-between' {...objAtrib.gridPrincipal}>
      <Grid item>
      </Grid>
      <Grid container direction={isPhoneVersion ? 'column-reverse' : 'row'} justify='space-between'>
        <Grid item className={customStyle.gridPageTitle}>
          {/*Titulo*/}
          <h1 id="tituloPagina" className={customStyle.pageTitle}>{objText.tituloEncabezado}</h1>
        </Grid>
        {/*Grid contenedor boton*/}
        <Grid item className={customStyle.gridSearchBar}>
          <Grid >
            <input {...objAtrib.inputImportar} multiple className={customStyle.classInputImportarStyle}/>
            <button {...objAtrib.buttonImportar} onClick={setMostrarImport} className={customStyle.classButton}>{objText.nombreImport}</button>
            <button {...objAtrib.button} onClick={redirigirNuevoFormulario} className={customStyle.classButton}>{objText.nombreButton}</button>
          </Grid>
          <Grid >
          
          </Grid>
          
          
        </Grid>
      </Grid>
      {/*Grid contenedor*/}
      <Grid container spacing={3} {...objAtrib.gridContenedor} className={customStyle.classGridContenedor}>
        {/*Grid contenedor tabla*/}

        <Errors errors={errors} />
        <Grid container spacing={12} >
          <Grid item xs={12}>
            <FormGrid
              forms={formularios}
              operations={operations}
              options={options}
              columns={columns}
              onAction={(form, action) => handleAction(form, action)}
            />
          </Grid>
        </Grid>
        {/*POPUP*/}
        <Confirmacion
          {...objText.popup}
          isPhoneVersion={isPhoneVersion}
          handleChange={cancelarContinuacion}
          mostrar={mostrarPopUp}
          accionConfirmacion={(e) => aceptarContinuacion(e)}
          tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
          isPopUpPdf={false}
          textBotonConfirmarPersonalizado={false}
          isImagenPersonalizada={false}
          centrarContenido={true}
          isTextoEnriquecido={true}
        />
          {/*POPUPIMPORT*/}
          <Confirmacion
          {...objText.popupImportar}
          isPhoneVersion={isPhoneVersion}
          handleChange={cancelarContinuacionImport}
          mostrar={mostrarImport}
          accionConfirmacion={(e) => aceptarContinuacionImport(e)}
          tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
          isPopUpPdf={false}
          textBotonConfirmarPersonalizado={false}
          isImagenPersonalizada={false}
          centrarContenido={true}
          isTextoEnriquecido={true}
        />
        {/*POPUPIMPORT*/}
          <Confirmacion
          {...objText.popupImportar}
          isPhoneVersion={isPhoneVersion}
          handleChange={cancelarContinuacionImport}
          mostrar={mostrarImport}
          accionConfirmacion={(e) => aceptarContinuacionImport(e)}
          tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
          isPopUpPdf={false}
          textBotonConfirmarPersonalizado={false}
          isImagenPersonalizada={false}
          centrarContenido={true}
          isTextoEnriquecido={true}
        />
        {/*POPUPIMPORTACTU*/}
        <Confirmacion
          {...objText.popupImportarActu}
          isPhoneVersion={isPhoneVersion}
          handleChange={cancelarContinuacionActu}
          mostrar={mostrarImportActu}
          accionConfirmacion={(e) => aceptarContinuacionActu(e)}
          tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
          isPopUpPdf={false}
          textBotonConfirmarPersonalizado={false}
          isImagenPersonalizada={false}
          centrarContenido={true}
          isTextoEnriquecido={true}
        />
        <TablePagination
          component="div"
          count={maximo}
          page={page}
          onChangePage={handleChangePage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          labelRowsPerPage="Filas por página: "
          nextIconButtonText="Siguiente página"
          backIconButtonText="Página anterior"
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Backdrop className={customStyle.classBackdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {alerts.map(m => (
          <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
            <Alert
              severity={message.severity}
              variant="filled"
            >
              {message.msg}
            </Alert>
          </Snackbar>
        ))}
      </Grid>
    </Grid>
    </ThemeProvider>
  );
}
