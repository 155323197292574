export const properties  = {
    TLFN_ICON_PATH: 'header/telefono.svg',
    /*JA_ICON_PATH: 'app/jaLogoGenerico.png',
    JA_PHONE_ICON_PATH: 'app/jaLogoGenericoNegativo.png',*/
    JA_ICON_PATH: 'app/',
    JA_PHONE_ICON_PATH: 'app/',
    RRSS_ICON_PATH: 'images/RRSS/',
    RESOURCE: 'clave',
    RESOURCE_LOCAL: 'SpringSAMLIntegrationExample',
    URL_LOGOUT: 'logout',
    TITLE_RRSS: 'Síguenos en ',
    AC_ICON: 'images/AC/logo-ayuntamiento.svg'
}

export const texts  = {
    OV : 'Sede electrónica',
    barraDivi: ' | ',
    JA: 'Junta de Andalucía',
    JAPhone: 'OFICINA VIRTUAL',
    NOMBRE_CONSEJERIA: 'Consejería de Transformación Económica, Industria, Conocimiento y Universidades',
    BOTON_CABECERA: 'MI CUENTA',
    ALT_AYUDA: 'Mostrar ayuda',
    ALT_TLFNO: 'Teléfono',
    ALT_JA: 'Ir a la página de inicio de la ',
    ALT_CONTACTO: 'Icono contacto',
    CONTACTO: 'Contacto',
    ALABEL_NAV: 'contacto y redes sociales',
    INICIO: 'Inicio',
    ATENCION_CIUDADANA: 'Atención ciudadana',
    AYUDA_USUARIO: 'Ayuda al usuario',
    HERRAMIENTAS_ENLACES: 'Herramientas y enlaces',
}

export const mockRRSS = {
    
    label:'Síguenos en:',
    columnType:'RRSS',
    rowList: [
        {text: 'x_logo.svg', link: 'https://x.com/ayuncordoba_es'},
        {text: 'facebook.svg', link: 'https://www.facebook.com/ayuncordoba.es'},
        {text: '', link: ''},
        {text: '', link: ''},
        {text: '', link: ''}
    ]
}

export const headerLinks = [
    {
        text: 'Ayuntamiento de Córdoba',
        href: 'https://www.cordoba.es/',
    },
    {
        text: 'Atención ciudadana',
        href: 'https://www.cordoba.es/servicios/informacion-y-atencion-ciudadana/atencion-ciudadana',
    }
]

export const mainMenu = [
    {
        texto: 'Información general',
        href: 'https://www.cordoba.es/informacion_general'
    },
    {
        texto: 'Trámites',
        href: 'https://www.cordoba.es/tramites'
    },
    {
        texto: 'Servicios',
        href: 'https://www.cordoba.es/servicios'
    },
    {
        texto: 'Buscar',
        href: 'https://www.cordoba.es/sede/buscar'
    },
]
export const mockMenu = [  
    //Apartado 1
    {
        idSeccion:'1',
        tituloSeccion:'Economía Digital',
        imagen:'fa-laptop.svg',
		enlace:'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/tic-telecomunicaciones.html',
        columnasPrincipales:[

            //COLUMNA PRINCIPAL 1
            {
                idColumnaPrincipal:'1',
                columnas:[
                    {
                        idColumna:'1',
                        tituloColumna:'Transformación digital de la PYME',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/tic-telecomunicaciones/transformacion-digital.html',
                        filas:[]
                    },
                    {
                        idColumna:'2',
                        tituloColumna:'Desarrollo del sector TIC',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/tic-telecomunicaciones/desarrollo-tic.html',
                        filas:[]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 2
            {
                idColumnaPrincipal:'2',
                columnas:[
                    {
                        idColumna:'3',
                        tituloColumna:'Infraestructuras de telecomunicaciones',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/tic-telecomunicaciones/infraestructuras.html',
                        filas:[]
                    },
                    {
                        idColumna:'4',
                        tituloColumna:'Territorio inteligente',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/tic-telecomunicaciones/territorio-inteligente.html',
                        filas:[]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 3
            {
                idColumnaPrincipal:'3',
                columnas:[
                    {
                        idColumna:'5',
                        tituloColumna:'Promoción igualdad género en el sector TIC',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/tic-telecomunicaciones/promocion-igualdad-genero.html',
                        filas:[]
                    },
					{
                        idColumna:'6',
                        tituloColumna:'Andalucía Conectada',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/tic-telecomunicaciones/andalucia-conectada.html',
                        filas:[]
                    },
                ]
            },
			//COLUMNA PRINCIPAL 4
            {
                idColumnaPrincipal:'4',
                columnas:[
                    {
                        idColumna:'7',
                        tituloColumna:'Directo a',
                        tipoColumna:'directo',
                        enlace: '',
                        filas:[

                            {
                                idFila:'1',
                                texto:'Catálogo de cursos gratuitos en internet y tecnología',
                                enlace: 'https://www.formacion.andaluciaesdigital.es/',
                                imagen: 'fa-mouse-pointer.svg'
                            },
                            {
                                idFila:'2',
                                texto:'Observatorio Economía Digital',
                                enlace: 'https://observatorio.andaluciaconectada.es/',
                                imagen: 'fa-laptop-code.svg'
                            },
                            {
                                idFila:'3',
                                texto:'Herramienta autodiagnóstico de madurez digital',
                                enlace: 'http://www.programaempresadigital.es/',
                                imagen: 'fa-file-alt.svg'
                            },
                            {
                                idFila:'4',
                                texto:'Herramienta autodiagnóstico para el sector TIC',
                                enlace: 'https://tic.andaluciaconectada.es/madurez-digital/',
                                imagen: 'fa-star.svg',
                            }
                        ]
                    },
                ]
            }
        ]
    },
	//Apartado 2
    {
        idSeccion:'2',
        tituloSeccion:'Industria y minas',
        imagen:'fa-industry.svg',
		enlace:'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria.html',
        columnasPrincipales:[

            //COLUMNA PRINCIPAL 5
            {
                idColumnaPrincipal:'5',
                columnas:[
                    {
                        idColumna:'8',
                        tituloColumna:'Promoción industrial',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/promocion-industrial.html',
                        filas:[]
                    },
                    {
                        idColumna:'9',
                        tituloColumna:'Instalaciones radiactivas',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/radiactividad.html',
                        filas:[]
                    },
					                    {
                        idColumna:'10',
                        tituloColumna:'Planificación Industrial y Minera',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/planificacion-industrial-minera.html',
                        filas:[]
                    },
					                    {
                        idColumna:'11',
                        tituloColumna:'Minas',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/minas.html',
                        filas:[]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 6
            {
                idColumnaPrincipal:'6',
                columnas:[
                    {
                        idColumna:'12',
                        tituloColumna:'Puesta en servicio de instalaciones',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/instalaciones.html',
                        filas:[]
                    },
                    {
                        idColumna:'13',
                        tituloColumna:'Carnés y empresas instaladoras',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/instaladores.html',
                        filas:[]
                    },
					                    
                    {
                        idColumna:'14',
                        tituloColumna:'Vehiculos y talleres',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/vehiculos.html',
                        filas:[]
                    },
					
                    {
                        idColumna:'15',
                        tituloColumna:'Calidad y seguridad industrial',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/seguridad.html',
                        filas:[]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 7
            {
                idColumnaPrincipal:'7',
                columnas:[
                    {
                        idColumna:'16',
                        tituloColumna:'Metrología',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/metrologia.html',
                        filas:[]
                    },
					                    {
                        idColumna:'17',
                        tituloColumna:'Seguridad de los productos - Marcado CE',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/marcado.html',
                        filas:[]
                    },
					                    {
                        idColumna:'18',
                        tituloColumna:'Propiedad industrial',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/propiedad-industrial.html',
                        filas:[]
                    },
					                    {
                        idColumna:'19',
                        tituloColumna:'Metales preciosos',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/metales-preciosos.html',
                        filas:[]
                    }
                ]
            },
			//COLUMNA PRINCIPAL 8
            {
                idColumnaPrincipal:'8',
                columnas:[
                    {
                        idColumna:'20',
                        tituloColumna:'Directo a',
                        tipoColumna:'directo',
                        enlace: '',
                        filas:[

                            {
                                idFila:'5',
                                texto:'Cita previa para la ITV',
                                enlace: 'https://www.itvcita.com/Welcome.do#fragment-3',
                                imagen: 'fa-car-side.svg'
                            },
                            {
                                idFila:'6',
                                texto:'Información sobre los exámenes de instaladores',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/industria/instaladores/paginas/plantillas-examenes.html',
                                imagen: 'fa-tools.svg'
                            },
                            {
                                idFila:'7',
                                texto:'Cambio de titularidad en instalaciones industriales',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/procedimientos/detalle/22006.html',
                                imagen: 'fa-building.svg'
                            },
                            {
                                idFila:'8',
                                texto:'Autorización de entidades y talleres de dispositivos de limitación de velocidad',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/procedimientos/detalle/13092.html',
                                imagen: 'fa-check.svg',
                            },
                            {
                                idFila:'9',
                                texto:'Inspección y supervisión en materia de Industria',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/procedimientos/detalle/22123.html',
                                imagen: 'fa-cog.svg',
                            }
                        ]
                    },
                ]
            }
        ]
    },
	//Apartado 3
    {
        idSeccion:'3',
        tituloSeccion:'Universidad',
        imagen:'fa-graduation-cap.svg',
		enlace:'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad.html',
        columnasPrincipales:[

            //COLUMNA PRINCIPAL 9
            {
                idColumnaPrincipal:'9',
                columnas:[
                    {
                        idColumna:'21',
                        tituloColumna:'Acceso a la universidad',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/acceso.html',
                        filas:[]
                    },
                    {
                        idColumna:'22',
                        tituloColumna:'Informe sobre la eficiencia del Sistema Público Universitario de Andalucía',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/estudio-eficiencia-universitaria.html',
                        filas:[]
                    },
					{
                        idColumna:'23',
                        tituloColumna:'Tu esfuerzo y talento cuentan',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/talento.html',
                        filas:[]
                    },
					{
                        idColumna:'24',
                        tituloColumna:'Titulaciones y centros',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/titulaciones.html',
                        filas:[]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 10
            {
                idColumnaPrincipal:'10',
                columnas:[
                    {
                        idColumna:'25',
                        tituloColumna:'Inserción laboral',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/insercion.html',
                        filas:[]
                    },
                    {
                        idColumna:'26',
                        tituloColumna:'Formación a lo largo de la vida',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/formacion-continua.html',
                        filas:[]
                    },
					{
                        idColumna:'27',
                        tituloColumna:'Cultura y divulgación de conocimiento',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/divulgacion.html',
                        filas:[]
                    },
					{
                        idColumna:'28',
                        tituloColumna:'Calidad y acreditación del profesorado',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/acreditacion-profesorado.html',
                        filas:[]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 11
            {
                idColumnaPrincipal:'11',
                columnas:[
                    {
                        idColumna:'29',
                        tituloColumna:'Sistema universitario andaluz',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/sistema-universitario.html',
                        filas:[]
                    },
					                    {
                        idColumna:'30',
                        tituloColumna:'Espacio Europeo de Educación Superior',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/espacio-europeo.html',
                        filas:[]
                    }
                ]
            },
			//COLUMNA PRINCIPAL 12
            {
                idColumnaPrincipal:'12',
                columnas:[
                    {
                        idColumna:'31',
                        tituloColumna:'Directo a',
                        tipoColumna:'directo',
                        enlace: '',
                        filas:[

                            {
                                idFila:'10',
                                texto:'Catálogo de grados universitarios',
                                enlace: 'https://www.juntadeandalucia.es/economiaconocimientoempresasyuniversidad/sguit/?q=grados&d=g_catalogo_top.php',
                                imagen: 'fa-graduation-cap.svg'
                            },
                            {
                                idFila:'11',
                                texto:'Bonificación de matrículas',
                                enlace: 'https://www.juntadeandalucia.es/organismos/economiaconocimientoempresasyuniversidad/areas/universidad/talento/paginas/guia-99',
                                imagen: 'fa-check-circle.svg'
                            },
                            {
                                idFila:'12',
                                texto:'Ayudas para acreditar nivel de idiomas B1 o superiores',
                                enlace: 'https://www.juntadeandalucia.es/organismos/economiaconocimientoempresasyuniversidad/temas/universidad/talento/paginas/ayudas-acreditacion-idiomas.html',
                                imagen: 'fa-flag.svg'
                            },
                            {
                                idFila:'13',
                                texto:'Universidades andaluzas',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/universidad/titulaciones/paginas/universidades-andaluzas.html',
                                imagen: 'fa-university.svg',
                            },
                            {
                                idFila:'14',
                                texto:'Distrito Único',
                                enlace: 'https://www.juntadeandalucia.es/economiaconocimientoempresasyuniversidad/sguit/',
                                imagen: 'fa-arrow-alt-circle-right.svg',
                            }
                        ]
                    },
                ]
            }
        ]
    },
	//Apartado 4
    {
        idSeccion:'4',
        tituloSeccion:'Más temas',
        imagen:'fa-th.svg',
		enlace:'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas.html',
        columnasPrincipales:[

            //COLUMNA PRINCIPAL 13
            {
                idColumnaPrincipal:'13',
                columnas:[
                    {
                        idColumna:'32',
                        tituloColumna:'Comercio y Artesanía',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/comercio.html',
                        filas:[
                            {
                                idFila:'15',
                                texto:'Comercio interior',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/comercio/interior.html',
                            },
                            {
                                idFila:'16',
                                texto:'Artesanía',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/comercio/artesania.html',
                            },
                            {
                                idFila:'17',
                                texto:'Cámaras Oficiales de Comercio, Industria, Servicios y Navegación',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/comercio/camara-comercio-industria-navegacion.html',
                            }
                        ]
                    },
                    {
                        idColumna:'33',
                        tituloColumna:'Empresa, Innovación y Emprendimiento',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/empresas-emprendedores.html',
                        filas:[
                            {
                                idFila:'18',
                                texto:'Incentivos y ayudas a empresas',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/empresas-emprendedores/ayudas-empresas.html',
                            },
                            {
                                idFila:'19',
                                texto:'Emprendimiento - Startup Andalucía',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/empresas-emprendedores/startup.html',
                            },
                            {
                                idFila:'20',
                                texto:'Atracción y captación de inversiones',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/empresas-emprendedores/invertir-andalucia.html',
                            }
                        ]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 14
            {
                idColumnaPrincipal:'14',
                columnas:[
                    {
                        idColumna:'34',
                        tituloColumna:'Economía',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/economia.html',
                        filas:[
                            {
                                idFila:'21',
                                texto:'Declaración de inversión empresarial de interés estratégico para Andalucía',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/economia/declaracion-inversion-empresarial.html',
                            },
                            {
                                idFila:'22',
                                texto:'Planificación económica',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/economia/planificacion.html',
                            },
                            {
                                idFila:'23',
                                texto:'Situación económica y previsiones',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/economia/situacion.html',
                            }
                        ]
                    },
                    {
                        idColumna:'35',
                        tituloColumna:'Investigación',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/investigacion-desarrollo.html',
                        filas:[
                            {
                                idFila:'24',
                                texto:'Sistema Andaluz del Conocimiento',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/investigacion-desarrollo/sistema-conocimiento.html',
                            },
                            {
                                idFila:'25',
                                texto:'Fomento de la I+D+I',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/investigacion-desarrollo/fomento.html',
                            },
                            {
                                idFila:'26',
                                texto:'Transferencia del conocimiento',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/investigacion-desarrollo/transferencia.html',
                            }
                        ]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 15
            {
                idColumnaPrincipal:'15',
                columnas:[
                    {
                        idColumna:'36',
                        tituloColumna:'Medidas ante el COVID-19',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/medidas-covid19.html',
                        filas:[
                            {
                                idFila:'27',
                                texto:'Investigación',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/medidas-covid19/faq-investigacion-covid.html',
                            },
                            {
                                idFila:'28',
                                texto:'PLAnd COVID-19',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/medidas-covid19/pland-covid19.html',
                            },
                            {
                                idFila:'29',
                                texto:'Comercio y Artesanía',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/medidas-covid19/comercio-artesania-covid.html',
                            }
                        ]
                    }
                ]
            },
        ]
    },
    //Apartado 5
    {
        idSeccion:'5',
        tituloSeccion:'La consejería',
        imagen:'simbolo-generico_positivo.svg',
		enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria.html',
        columnasPrincipales:[

            //COLUMNA PRINCIPAL 16
            {
                idColumnaPrincipal:'16',
                columnas:[
                    {
                        idColumna:'37',
                        tituloColumna:'Consejero',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/titular',
                        filas:[]
                    },
                    {
                        idColumna:'38',
                        tituloColumna:'Organigrama',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/organigrama',
                        filas:[]
                    },
                    {
                        idColumna:'39',
                        tituloColumna:'Transparencia',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/transparencia',
                        filas:[]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 17
            {
                idColumnaPrincipal:'17',
                columnas:[
                    {
                        idColumna:'40',
                        tituloColumna:'Centros directivos',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/centros-directivos.html',
                        filas:[
                            {
                                idFila:'30',
                                texto:'Viceconsejería',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/viceconsejeria.html',
                            },
                            {
                                idFila:'31',
                                texto:'S.G de Empresa, Innovación y Emprendimiento',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/sgeie.html',
                            },
                            {
                                idFila:'32',
                                texto:'S.G de Industria y Minas',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/sgim.html',
                            },
                            {
                                idFila:'33',
                                texto:'S.G de Economía',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/sge.html',
                            }
                        ]
                    },
                    {
                        idColumna:'41',
                        tituloColumna:'Órganos colegiados',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/organos-colegiados.html',
                        filas:[]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 18
            {
                idColumnaPrincipal:'18',
                columnas:[
                    {
                        idColumna:'42',
                        tituloColumna:'Delegaciones territoriales',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/delegaciones.html',
                        filas:[]
                    },
                    {
                        idColumna:'43',
                        tituloColumna:'Entidades y organismos adscritos',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/consejeria/adscritos.html',
                        filas:[

                            {
                                idFila:'34',
                                texto:'Instituto de Estadística y Cartografía de Andalucía',
                                enlace: 'https://www.juntadeandalucia.es/organismos/ieca.html',
                            },
                            {
                                idFila:'35',
                                texto:'Agencia de la Competencia y de la Regulación Económica de Andalucía',
                                enlace: 'https://www.juntadeandalucia.es/organismos/adca.html',
                            },
                            {
                                idFila:'36',
                                texto:'Empresa Pública de Gestión de Activos, S.A.',
                                enlace: 'https://www.juntadeandalucia.es/organismos/agesa.html',
                            },
                            {
                                idFila:'37',
                                texto:'Agencia Andaluza del Conocimiento',
                                enlace: 'https://www.juntadeandalucia.es/organismos/aac.html',
                            },
                            {
                                idFila:'38',
                                texto:'Agencia de Innovación y Desarrollo de Andalucía (IDEA)',
                                enlace: 'https://www.juntadeandalucia.es/organismos/idea.html',
                            }
                        ]
                    },
                ]
            },
        ]
    },
    //Apartado 6
    {
        idSeccion:'6',
        tituloSeccion:'Servicios',
        imagen:'fa-laptop.svg',
		enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios.html',
        columnasPrincipales:[

            //COLUMNA PRINCIPAL 19
            {
                idColumnaPrincipal:'19',
                columnas:[
                    {
                        idColumna:'44',
                        tituloColumna:'Catálogo de procedimientos y servicios',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/procedimientos.html',
                        filas:[]
                    },
                    {
                        idColumna:'45',
                        tituloColumna:'Canales de presentación',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/canales.html',
                        filas:[
                            {
                                idFila:'39',
                                texto:'Oficina virtual',
                                enlace: 'https://www.juntadeandalucia.es/economiaconocimientoempresasyuniversidad/oficinavirtual/',
                            },
                            {
                                idFila:'40',
                                texto:'Oficina de asistencia en materia de registros',
                                enlace: 'https://www.juntadeandalucia.es/servicios/oficinas-asistencia-registros.html',
                            },
                            {
                                idFila:'41',
                                texto:'Presentación electrónica general',
                                enlace: 'https://ws094.juntadeandalucia.es/V_virtual/SolicitarTicket?v=PEG',
                            }
                        ]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 20
            {
                idColumnaPrincipal:'20',
                columnas:[
                    {
                        idColumna:'46',
                        tituloColumna:'Información y seguimiento',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/informacion-seguimiento.html',
                        filas:[
                            {
                                idFila:'42',
                                texto:'Tablón de anuncios',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/anuncios.html',
                            },
                            {
                                idFila:'43',
                                texto:'Carpeta ciudadana',
                                enlace: 'http://www.juntadeandalucia.es/servicios/carpeta-ciudadana.html',
                            },
                            {
                                idFila:'44',
                                texto:'Sugerencias y reclamaciones',
                                enlace: 'https://www.juntadeandalucia.es/lsr/lsr/inicio.jsp',
                            }
                        ]
                    },
                    {
                        idColumna:'47',
                        tituloColumna:'Actualidad',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/actualidad.html',
                        filas:[
                            {
                                idFila:'45',
                                texto:'Últimas noticias',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/actualidad/noticias.html',
                            },
                            {
                                idFila:'46',
                                texto:'Histórico de noticias',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/actualidad/noticias-historico.html',
                            },
                            {
                                idFila:'47',
                                texto:'Agenda',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/actualidad/agenda.html',
                            }
                        ]
                    }
                ]
            },
            //COLUMNA PRINCIPAL 21
            {
                idColumnaPrincipal:'21',
                columnas:[
                    {
                        idColumna:'48',
                        tituloColumna:'Servicios de información',
                        tipoColumna:'default',
                        enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/servicios-informacion.html',
                        filas:[

                            {
                                idFila:'48',
                                texto:'Normativa',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/normativa.html',
                            },
                            {
                                idFila:'49',
                                texto:'Participación',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/participacion.html',
                            },
                            {
                                idFila:'50',
                                texto:'Catálogo de publicaciones',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/publicaciones.html',
                            },
                            {
                                idFila:'51',
                                texto:'Vídeos',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/videos.html',
                            },
                            {
                                idFila:'52',
                                texto:'Estadística y cartografía',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/servicios/estadistica-cartografia.html',
                            }
                        ]
                    },
                ]
            },
            //COLUMNA PRINCIPAL 22
            {
                idColumnaPrincipal:'22',
                columnas:[
                    {
                        idColumna:'49',
                        tituloColumna:'Directo a',
                        tipoColumna:'directo',
                        enlace: '',
                        filas:[

                            {
                                idFila:'53',
                                texto:'Horarios comerciales',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/areas/comercio/interior/paginas/horarios-comerciales.html',
                                imagen: 'fa-clock.svg'
                            },
                            {
                                idFila:'54',
                                texto:'Distrito Único Andaluz',
                                enlace: 'https://www.juntadeandalucia.es/economiaconocimientoempresasyuniversidad/sguit/',
                                imagen: 'fa-graduation-cap.svg'
                            },
                            {
                                idFila:'55',
                                texto:'Agencia IDEA',
                                enlace: 'https://www.juntadeandalucia.es/organismos/transformacioneconomicaindustriaconocimientoyuniversidades/idea.html',
                                imagen: 'fa-star.svg'
                            },
                            {
                                idFila:'56',
                                texto:'Instituto de Estadística y Cartografía de Andalucía',
                                enlace: 'https://www.juntadeandalucia.es/institutodeestadisticaycartografia',
                                imagen: 'fa-chart-bar.svg',
                            },
                            {
                                idFila:'57',
                                texto:'Andalucía Conectada',
                                enlace: 'https://andaluciaconectada.es/',
                                imagen: 'fa-laptop.svg',
                            }
                        ]
                    },
                ]
            }
        ]
    },
]

export const menuCordoba = [
    
    {
        codigo: "cod_informacion_general",
        tituloSeccion: "Información General",
        enlace: "https://www.cordoba.es/sede/informacion-general",
        columnasPrincipales: [
            {
                columnas: [
                    {
                        tituloColumna: "Titularidad",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/sede/informacion-general/titularidad",
                        filas: []
                    },
                    {
                        tituloColumna: "Normativa",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/sede/informacion-general/normativa",
                        filas: []
                    },
                    {
                        tituloColumna: "Requisitos de acceso",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/sede/informacion-general/requisitos-acceso",
                        filas: []
                    },
                ]
            },
            {
                columnas: [
                    {
                        tituloColumna: "Fecha y hora oficiales",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/sede/informacion-general/fecha-hora-oficiales",
                        filas: []
                    },
                    {
                        tituloColumna: "Interrupciones de servicio",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/sede/informacion-general/interrupciones-servicio",
                        filas: []
                    },
                ]
            },
        ]
    },
    {
        codigo: "cod_tramites",
        tituloSeccion: "Trámites",
        enlace: "https://www.cordoba.es/sede/tramites",
        columnasPrincipales: [
            {
                columnas: [
                    {
                        tituloColumna: "Mis trámites",
                        tipoColumna: "default",
                        enlace: "https://sede.cordoba.es/",
                        filas: []
                    },
                    {
                        tituloColumna: "Catálogo de procedimientos y servicios",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/sede/tramites/catalogo",
                        filas: []
                    },
                    {
                        tituloColumna: "Calendario oficial",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/sede/tramites/calendario-oficial",
                        filas: []
                    },
                ]
            },
            {
                columnas: [
                    {
                        tituloColumna: "Instancia General",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/tramites/general/instancia-general",
                        filas: []
                    },
                    {
                        tituloColumna: "Quejas, sugerencias y/o agradecimientos",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/tramites/general/quejas-sugerencias-agradecimientos",
                        filas: []
                    },
                ]
            },
        ]
    },
    {
        codigo: "cod_servicios",
        tituloSeccion: "Servicios",
        enlace: "https://www.cordoba.es/sede/servicios",
        columnasPrincipales: [
            {
                columnas: [
                    {
                        tituloColumna: "Mis trámites",
                        tipoColumna: "default",
                        enlace: "https://sede.cordoba.es/",
                        filas: []
                    },
                    {
                        tituloColumna: "Verificación de documentos",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/sede/servicios/verificacion-documentos",
                        filas: []
                    },
                    {
                        tituloColumna: "Tablón de anuncios",
                        tipoColumna: "default",
                        enlace: "https://www.cordoba.es/sede/servicios/tablon",
                        filas: []
                    }
                ]
            },            
        ]
    },
    {
        codigo: "cod_buscar",
        tituloSeccion: "Buscar",
        enlace: "https://www.cordoba.es/sede/buscar",
        columnasPrincipales: []
    },
]
