import { makeStyles, createMuiTheme  } from '@material-ui/core/styles'
import { estilosTextosPrincipales, colores, buttonSecundaryBig } from '../../../AppStyle'

let anchoPantalla = window.screen.width;
let altoPantalla = window.screen.height;

const  useStyles = (isPhoneVersion) => {
const customStyle = makeStyles({

    classTexto: {
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '25px',
    },
    classDivIcon:{
        display: 'flex',
        cursor: 'pointer',
    },
    classIcon:{
        color: colores.principales.principal,
    },
    classGridImg: {
        backgroundColor: colores.escalaGrises.apoyoEspecífico,
        marginBottom: '5%',
        textAlign: 'center',
        height: '125px',
        ...(isPhoneVersion) && { marginTop: '5%'}
    },
    clasGridContenedor: {
        ...(isPhoneVersion) ? { margin: '5%' } : { marginLeft: '5%', width: '15%', display:'inline-table', paddingBottom:'2%' }        
    },
    classGridImgDrop: {
        textAlign: 'center',
        height: '125px'
    },
    classImg:{
        marginTop: '15%',
    },
    classParrafo:{
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',
        fontSize: '15px',
    },
    classButtonCancelar: {
        ...buttonSecundaryBig,
        color: colores.estado.error,
        width: '100%',
        '&:hover': {
            border: `1px solid ${colores.principales.hover}`,        
            color: colores.estado.error 
        },
    },
});

const theme = createMuiTheme({
    overrides: {  
        MuiDropzoneArea: {
            text: {
                marginTop: '2%',
                marginBottom: '0%',
            },
            root: {
                minHeight: '0px',
                '&:hover': {
                    backgroundColor: colores.principales.fondoEspecifico,
                    color: colores.principales.principal,
                    borderColor: colores.principales.principal
                },
            },
        },
    }    
});

return { customStyle, theme };
};

export default useStyles;