import { useState } from 'react';
import { properties as appProperties } from 'AppData';

const useOperations = () => {
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [mostrarTexto, setMostrarTexto] = useState(false);
    
    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    /**
     * @function cerrarPopUpPdf Corta el texto que aparece en el dropzone
     */
     const cortarTexto = (texto) =>{
        let numCaracteres = isPhoneVersion ? 40 : 20;
        return (texto.length <= numCaracteres) ? (texto) : (texto.substring(0, numCaracteres) + '...');
    };

    /**
     * @function mostrarTextoEntero Abre o cierra el acordeon que muestra el texto
     */
     const mostrarTextoEntero = () =>{
        setMostrarTexto(!mostrarTexto);
    };

    return { isPhoneVersion, updateFormat, cortarTexto, mostrarTextoEntero, mostrarTexto };
}

export default useOperations;