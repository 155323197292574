
export const mockFooterBottom = {   
    enlaces:[
        {
            texto: "Accesibilidad"
        },
        {
            texto: "Aviso legal",
            link: "https://www.juntadeandalucia.es/informacion/legal.html"
        },
        {
            texto: "Protección de datos",
            link: "https://www.juntadeandalucia.es/protecciondedatos.html"
        }
        
    ],
    iconos:[
        {
            alt: "Logotipo Ayuntamiento de Córdoba",
            src: "images/AC/logo-ayuntamiento.svg"
        }
    ],
    iconoPrincipal:[
        {
            alt: "Logotipo Ayuntamiento de Córdoba",
            src: "images/AC/logo-ayuntamiento-cordoba-blanco.png",
            link: "https://www.cordoba.es/"
        }
    ],
    iconosCabecera:[
        {
            alt: "Logotipo Edusi",
            src: "images/AC/logo-edusi.jpg"
        },
        {
            alt: "Logotipo Europea",
            src: "images/AC/logo-europa.png"
        },
        {
            alt: "Logotipo Unión Europea",
            src: "images/AC/logo-union-europea.png"
        }
    ],
    informacion:[
        {
            texto: "Capitulares, 1. 14071",
            texto2: "Córdoba - España",
            src: "university.svg",
            link: ""
        },
        {
            texto: "957 49 99 00",
            src: "phone-alt.svg",
            link: ""
        },
        {
            texto: "957 47 80 50",
            src: "fax.svg",
            link: ""
        }
    ],
    redes:[
        {
            src:"x_logo.svg",
            link:"https://x.com/ayuncordoba_es",
            alt:"X - Ayuntamiento de Córdoba"
        },
        {
            src:"facebook.svg",
            link:"https://www.facebook.com/ayuncordoba.es",
            alt:"Facebook - Ayuntamiento de Córdoba"
        }
    ]
    
}