import {makeStyles} from '@material-ui/core/styles'
import {createMuiTheme} from '@material-ui/core';
import {estilosTextosPrincipales, colores, tipografia} from '../../../AppStyle'

export const customStyle = makeStyles ({
 
    /***********COMPUTER VERSION***********/

    searchAutocomplete:{
        width: 'fit-content',
        height: '48px',
    },

    searchTextField:{   
        width: '280px',
        height: '48px',
        margin: '0px',

    },
    searchButton: {
        borderRadius: '0',
        height: '48px',
        width: '48px',
        backgroundColor: colores.principales.principal, 
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px', 
        '&:hover': {
            backgroundColor: colores.principales.principal, 
        }
    },
    searchImg: {
        height: '48px',
        width: '48px',  
        borderBottomRightRadius: '5px', /**Que tenga la esquina curvo */
        borderTopRightRadius: '5px',
        '&:hover': {
            backgroundColor: colores.principales.hover, 
        }
    },

    /***********PHONE VERSION***********/

    searchAutocompletePhone:{
        width: 'fit-content',
        height: '40px',
    },

    searchTextFieldPhone:{   
        width: '288px',
        height: '40px',
        margin: '0px',
    },

    searchButtonPhone:{
        borderRadius:'0', /**Que sea un cuadrado */
        height: '40px',
        width: '40px',
        borderBottomRightRadius:'5px', /**Que tenga la esquina curvo */
        borderTopRightRadius:'5px',
        '&$focused': {
            boxShadow: `${colores.principales.principal} 0 0 10px`,       
        },        
    },
    searchImgPhone:{
        height: '40px',
        width: '40px',
        borderBottomRightRadius:'5px', /**Que tenga la esquina curvo */
        borderTopRightRadius:'5px'
    }
});

export function overrideStyleF(condition){

    const overrideStyle = createMuiTheme({
        overrides: {
    
            MuiOutlinedInput: {
                root: {
                    ...(condition ? { height: '40px' } : { height: '48px' }),                    
                    alignContent: 'center',
                    borderBottomRightRadius: '0px',
                    borderTopRightRadius: '0px',
                    border: `1px solid ${colores.principales.principal}`,
                    ...estilosTextosPrincipales.body1,
                    '&.Mui-focused':{
                        outline: `2px solid ${colores.principales.principal}`,
                    },
                    '&:hover':{
                        outline: `2px solid ${colores.principales.principal}`,
                    },
                },
                notchedOutline:{
                    borderStyle: 'none',
                }
            },
            MuiInputBase: {
                input: {
                    fontSize: '17px',
                    fontFamily: tipografia.regular,
                    '&::placeholder': {
                        color: colores.escalaGrises.cien,
                        fontSize: '17px',
                        fontFamily: tipografia.regular,
                    },
                },
            },
        }
    });

    return overrideStyle;
}


