import React, {useState, useEffect, useRef, useContext} from 'react';
import { saveState } from 'providers/localStorage';
import RestProvider from 'providers/RestProvider';
import {properties, texts, flagAc, objDataPopupProcInterno} from './ListadoProcedimientosData';
import { properties as appProperties, compProperties } from '../../../AppData';
import Botonera from '../../commons/botonera/BotoneraVertical';
import Table from '../../commons/table/Table';
import SearchBar from '../../commons/searchBar/SearchBar';
import Pager from '../../commons/pager/Pager';
import {Grid, CircularProgress, Backdrop, Snackbar} from '@material-ui/core';
import {customStyle} from './ListadoProcedimientosStyle';
import {properties as propertiesSearchBar} from '../../commons/botonera/BotoneraVerticalData';
import {Alert} from '@material-ui/lab';
import {localStorageName} from './../../../providers/localStorageData';
import { loadState } from './../../../providers/localStorage';
import AvisosCriticos from '../../commons/avisosCriticos/avisosCriticos'
import Avisos from '../../commons/avisos/Avisos'
import AppContext from 'AppContext';
import {properties as botoneraVerticalData  }  from './../../commons/botonera/BotoneraVerticalData';
import {properties as cloudConfigProperties, getContextFromUrl} from 'config/Config'
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import useProtectedRoutes from '../../../providers/useProtectedRoutes';
import useApp from 'AppFunctions';

function ListadoProcedimientos() {

    const { objDataPopup } = useProtectedRoutes();

    let popup = loadState(localStorageName.protectedRoute, false);
    let popupProcInterno = loadState(localStorageName.popupProcInterno, false);
    let infoUsuario = loadState(localStorageName.infoUsuario, true);

    const [mostrarPopup, setMostrarPopup] = useState(false);
    const [mostrarPopupProcInterno, setMostrarPopupProcInterno] = useState(false);

    useEffect(()=> {
        popup !== undefined && setMostrarPopup(true);
    },[popup]);

    useEffect(()=> {
        popupProcInterno !== undefined && setMostrarPopupProcInterno(true);
        localStorage.removeItem(localStorageName.popupProcInterno);
    },[popupProcInterno]);

    const handleConfirmar = () => {
        localStorage.removeItem(localStorageName.protectedRoute);
        setMostrarPopup(false);
    }

    //HOOKS
    const [tipoProcedimientos, setTipoProcedimientos] = useState([]);
    const [tipoProcedimientosPct3, setTipoProcedimientosPct3] = useState([]);
    const [procedimientos, setProcedimientos] = useState([]);
    const [procedimientosFiltrados, setProcedimientosFiltrados] = useState([]);
    const [currentPage, setCurrentPage] = useState(properties.INIT_PAGE);
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const refDefaultButtonBotonera = useRef(null);
    const refAutocomplteSearchBar = useRef(null);
    const [phoneSelectedOption, setPhoneSelectedOption] = useState(propertiesSearchBar.MISTRAMITES_BUTTON);
    const [isLoading, setIsLoading] = useState(false);
    const [errores, setErrores] = useState([]);
    const [mostrarAvisoC, setMostrarAvisoC] = useState(true);
    const [idProcedimientoPCT3, setIdProcedimientoPCT3] = useState([]);   
    const context = useContext(AppContext);
    const {crudOperation, crudOperationsType, obtenerProperties} = RestProvider();
    const [numMostrar, setNumMostar] = useState('5');
    const [anchorEl, setAnchorEl] = useState(null);

    let style = customStyle();
    let btnMisTramites = document.querySelector(`#${botoneraVerticalData.idMistramites}`);
    let isClickedBtnMisTramites = true;//loadState(localStorageName.misTramites, true);
    
    //LLAMADAS

    const addProperties = async () => { 
        const propertiesList = [
            'lista.tipo.procedimientos'
        ];
    
        try {
            let properties = await obtenerProperties(propertiesList);    
            getDataProcedimientos(properties);
        } catch (error) {
            console.error('error obteniendo las properties', error);
        }
    };
    useEffect(() => {
        addProperties()
        setIsLoading(true); 
        mostrarAC();
        updateFormat();
        resetLocalStorage();
        context.updateTitlePage(texts.PAGE_TITLE);   
        compruebaUsuarioIsLogin();
        return () => window.removeEventListener('resize', updateFormat);
    }, []) ;

    /*Se crea efecto para mostrar el listado de procedimientos del usuario logado, siempre y cuando se cumpla la condición.
    Se recupera el valor booleano almacenado en localStorage que se ha insertado desde el fichero menuUsuario.js para indicar que se ha pulsado
    el botón mis trámites desde el menú usuario*/
    useEffect(() => {
        if (btnMisTramites && isClickedBtnMisTramites) {
            //Paso 1. Se pulsa el botón Mis Trámites de la botonera vertical
            btnMisTramites.click();        
            //Paso 2. Se elimina la variable del contexto
            localStorage.removeItem(localStorageName.misTramites);
        }
    }, [procedimientos]);

    const compruebaUsuarioIsLogin = () => {
        let usuarioClave = loadState(localStorageName.infoUsuario, true);

        if (usuarioClave !== undefined) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }; 

    useEffect(() => {
        if(context.sesion && context.sesion !== appProperties.SESION_CADUCADA){
            compruebaUsuarioIsLogin();
        } 
    }, context.sesion)

    /**
     * @function getDataTipoProcedimientos Función que obtiene los tipos de procedimientos.
     * Contando con los procedimientos
     * @returns Devuelve un array con los tipos de procedimientos
     */
    const getDataTipoProcedimientos = async (properties) => {
        let respuesta = [];       

        // Paso 1. Se obtienen los tipos de procedimientos a mostrar (desde cloudConfig)
        let listTiposAMostrar = properties['lista.tipo.procedimientos'].split(',');

        // Paso 3. Se obtienen los tipos de procedimientos de PCT3
        respuesta = await getTipoProcedimientos();

        /* Paso 5. Filtrado de procedimientos a través de los procedimientos obtenidos del sistema
         y los tipos de procedimientos definidos en cloudConfig */
        let procFiltrados = filtrarTiposProcedimientos(listTiposAMostrar, respuesta);
        
        // Paso 6. Se ordena el array, estableciendo el tipo de procedimiento Otros en último lugar
        let respuestaOrdenada = mostrarUltimoTipoProcOtros(procFiltrados);

        // Paso 7. Se insertan los tipos de procedimientos
        setTipoProcedimientos(respuestaOrdenada);
        saveState(localStorageName.tipoProcedimientos, respuestaOrdenada, true);
        setIsLoading(false);
        return respuestaOrdenada;
    }

    /**
     * @function getTipoProcedimientos Función que obtiene los tipos de procedimientos
     * @returns Devuelve un array con los tipos de procedimientos obtenidos
     */
    const getTipoProcedimientos = async () => {
        let tipoProcedimientosPCT3 = [];
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_TIPO, { url: '', method: 'get'}).then(response => {
            let listTipoProcPCT3 = response.data.content;
            // El objeto no contiene la clave descripcion, se añade con el valor de la clave-valor nombre
            for (const tipoProc of listTipoProcPCT3) {
                Object.assign(tipoProc, {descripcion: tipoProc.nombre});
            }
            tipoProcedimientosPCT3 = listTipoProcPCT3;
            setTipoProcedimientosPct3(tipoProcedimientosPCT3);
        }).catch(function (error) {
            handleSetMessage({ex: {error}, severity: 'error'})
        });
        return tipoProcedimientosPCT3;
    }
    
    /**
     * @function filtrarTiposProcedimientos Función que realiza el filtrado de los tipos de procedimientos a mostrar.
     * Comprueba los procedimientos de las dos listas, si un procedimiento de tiposProcedimiento está contenido en listTiposAMostrar,
     * se devuelve en la lista final de los tipos de procedimientos a mostrar.
     * @param {*} listTiposAMostrar Listado de tipos de procedimientos de los cuales se deben mostrar
     * @param {*} tiposDeProcedimiento Listado de procedimientos obtenidos
     * @returns Array con los procedimientos a mostrar
     */
    const filtrarTiposProcedimientos = (listTiposAMostrar, tiposDeProcedimiento) =>{
        let tiposFiltrados = [];

        tiposDeProcedimiento &&
            tiposDeProcedimiento.forEach((tipoProc) => {
                let i = 0;
                let encontrado = false;

                while(i < listTiposAMostrar.length && !encontrado) {
                    let tipoProcMostrar = listTiposAMostrar[i];
                    if (tipoProc.descripcion.toLowerCase()===tipoProcMostrar.toLowerCase()) {
                        tiposFiltrados.push(tipoProc);
                        encontrado = true;
                    }
                    i++;
                }
            });
        return tiposFiltrados;
    }

    /**
     * @function mostrarUltimoTipoProcOtros Función que establece el tipo de procedimiento "Otros" en último lugar
     * @param {*} tiposDeProcedimiento Listado de tipos de procedimientos
     * @returns Array con el listado de tipos de procedimientos ordenados, apareciendo en último lugar el tipo de procedimiento "Otros"
     */
    const mostrarUltimoTipoProcOtros = (tiposDeProcedimiento) => {
        let tipoProcOtros;
        let tiposDeProcedimientoOrd = [];
    
        tiposDeProcedimiento &&
            tiposDeProcedimiento.forEach((tipoProc) => {
                if (tipoProc.descripcion.toLowerCase() === "otros") {
                    tipoProcOtros = tipoProc;
                } else {
                    tiposDeProcedimientoOrd.push(tipoProc);
                }
            });

        tipoProcOtros &&
            tiposDeProcedimientoOrd.push(tipoProcOtros);
        return tiposDeProcedimientoOrd;
    }

    /**
     * @function getDataProcedimientos Función que obtiene los procedimientos de RPS y PCT3
     */
    const getDataProcedimientos = async (cloudProperties) => {
        let respuesta = [];
        let procedimientosPct3 = [];

        // Paso 2. Obtención de los procedimientos de la PCT3
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_PCT_PROCEDIMIENTO, { url: properties.URL_PROCEDIMIENTOS_OV, method: 'get'}).then(response => {
            procedimientosPct3 = response.data;
        }).catch(function (error) {
            handleSetMessage({ex: {error}, severity: 'error'});
        });

        // Paso 3. Obtención de los tipos de procedimientos
        await getDataTipoProcedimientos(cloudProperties);

        // Paso 4. Filtrado de procedimientos duplicados;
        let procFiltrados = await filtrarProcedimientosDuplicados(procedimientosPct3);

        // Paso 5. Ordenación de los procedimientos
        respuesta = await ordenarProcedimientos(procFiltrados);

        setProcedimientos(respuesta);
        setProcedimientosFiltrados(respuesta);

        compruebaUsuarioIsLogin(procedimientosPct3);

        let infoUsuario = loadState(localStorageName.infoUsuario, true);
        if(infoUsuario !== undefined){
            let idProcedimientosUsuarioArray = await getProcedimientosInteresado(infoUsuario.interesadoNif);
            filtrarProcedimientosUsuario(respuesta,idProcedimientosUsuarioArray);
        }
        setIsLoading(false);
    }

    
    const filtrarProcedimientosUsuario = (procedimientosArray,idProcedimientosUsuarioArray) =>{
        let procedimientosUsuario = [];

        for(var i = 0; i < procedimientosArray.length; i++){
            for(var x = 0; x < idProcedimientosUsuarioArray.length; x++){
                if(procedimientosArray[i].id === idProcedimientosUsuarioArray[x]){
                    procedimientosUsuario.push(procedimientosArray[i]);
                }
            }
            
        }
        
        /** Ordenación de los procedimientos de la pestaña "Mis Trámites" */
        procedimientosUsuario.sort((a, b) => a.descripcion > b.descripcion ? 1 : -1);
        setProcedimientos(procedimientosUsuario);
        setProcedimientosFiltrados(procedimientosUsuario);
    }


    /**
     * @function getFechaActual Función que obtiene la fecha actual
     * @returns 
     */
    const getFechaActual = async () => {
        let fechaObtenida =  null;

        await crudOperation(crudOperationsType.CUSTOM, properties.DATE_TIME, { url:'', method: 'get'}).then(response => {
            fechaObtenida = response.data;
        });

        let hora = fechaObtenida.substring(0,2);
        let minutos = fechaObtenida.substring(3,5);
        let segundos = fechaObtenida.substring(6,8);
        let dia = fechaObtenida.substring(9,11);
        let mes = fechaObtenida.substring(12,14);
        let anyo = fechaObtenida.substring(15,19);
      
        let fechaActual = new Date();
        fechaActual.setHours(hora);
        fechaActual.setMinutes(minutos);
        fechaActual.setSeconds(segundos);
        fechaActual.setDate(dia);
        fechaActual.setMonth(mes - 1);
        fechaActual.setFullYear(anyo);

        return fechaActual;
    }


     /**
     * @function ordenarProcedimientos Función que realiza el ordenado de los procedimientos
     * La ordenación se realiza de la siguiente forma:
     * 1.- Procedimientos activos
     * 2.- Procedimientos inactivos (cerrado plazo de presentación)
     * La ordenación se basa en los siguientes criterios: Fecha de inicio de presentación más reciente a la actual y en caso de igualdad de fecha orden alfabético.
     * @param {*} procedimientos 
     * @returns 
     */
     const ordenarProcedimientos = async (procedimientos) => {
        let procedimientosOrdenados = [];
        let procedimientosActivos = [];
        let procedimientosInactivos = [];
        let fechaActual = await getFechaActual();

        if (procedimientos !== null && procedimientos.length !== 0) {

            // Clasificación de procedimientos, entre activos e inactivos
            for (const proc of procedimientos) {
                let fechaMayorFinPresentacion = null;

                // Se obtiene la fecha mayor de fin de presentación
                if (proc.fechaMayorFinPresentacion !== undefined && proc.fechaMayorFinPresentacion !== null) {
                    fechaMayorFinPresentacion = new Date(proc.fechaMayorFinPresentacion);
                }
                
                ((fechaMayorFinPresentacion !== null && fechaActual !== null) && fechaMayorFinPresentacion > fechaActual)
                    ? procedimientosActivos.push(proc)
                    : procedimientosInactivos.push(proc);
            }
            
            // Ordenación de los procedimientos activos e inactivos
            procedimientosActivos = await ordenacionProcedimientos(procedimientosActivos);
            procedimientosInactivos = await ordenacionProcedimientos(procedimientosInactivos);
            //Asignación de los procedimientos ordenados
            procedimientosOrdenados = procedimientosActivos.concat(procedimientosInactivos);
            
        } else {
            procedimientosOrdenados = procedimientos !== null && procedimientos;
        }
        return procedimientosOrdenados;
    }


    /**
     * @function ordenacionProcedimientos Función que realiza el ordenado de los procedimientos
     * Criterios de ordenación: Fecha más reciente (campo fechaMayorInicioPresentacion) y en caso de que las fechas sean iguales por orden alfabético (campo descripción)
     * @param {*} procedimientos 
     * @returns 
     */
    const ordenacionProcedimientos = async (procedimientos) => {
        procedimientos.sort(function ordenarBloque(a, b) {
            // Evaluación por fecha
            if (new Date(a.fechaMayorInicioPresentacion) > new Date(b.fechaMayorInicioPresentacion)) {
              return -1;
            } else if (new Date(a.fechaMayorInicioPresentacion) < new Date(b.fechaMayorInicioPresentacion)) {
              return 1;
            // En caso de que la fecha sea igual, se evalúa por orden alfabético
            } else if (a.descripcion < b.descripcion) {
              return -1;
            } else if (a.descripcion > b.descripcion) {
              return 1;
            } else {
              return 0;
            }
          });
        return procedimientos;
    }

    const filtrarProcedimientosDuplicados = async (respuesta) => {
        let respuestaSinDuplicados = [];
        let index;
        let duplicado;
        respuesta.forEach(procedimiento => {
            index = 0;
            duplicado = false;
            while(index < respuestaSinDuplicados.length && !duplicado){
                if(respuestaSinDuplicados[index].descripcion === procedimiento.descripcion){
                    duplicado = true;
                }
                index++;
            }

            if(!duplicado){
                respuestaSinDuplicados.push(procedimiento);
            }
        });
       
        return respuestaSinDuplicados;
    }

    /**
     * @function getProcedimientosInteresado Función que obtiene los procedimientos en los que el usuario tiene solicitudes
     */
    const getProcedimientosInteresado = async (interesadoNif) => {
        let procedimientosUsuario = [];
        if(interesadoNif !== undefined && interesadoNif !== null){
            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_GESTION_EXPEDIENTE, { url: properties.RESOURCE_GETPROCEDIMIENTOS_INTERESADO + '/' + interesadoNif, method: properties.CRUD_TYPE_GET}).then(response => {
                procedimientosUsuario = response.data;
                
            }).catch(function (error) {
                handleSetMessage({ex: {error}, severity: 'error'})
            });
        }
        setIdProcedimientoPCT3(procedimientosUsuario);
        return procedimientosUsuario;
    }

    //FUNCIONES

    /**Método que actualiza la página actual en la que se encuentra */
    const paginate = pageNumber => {
        let previousPage = currentPage;
        setCurrentPage(pageNumber);
        return previousPage
    }
    
    /**Método que actualiza los procedimientos que se están mostrando actualmente */
    const updateSearch = value => {
        setProcedimientosFiltrados(value);  
        setNumMostar(5);
    }

    /**Método que actualiza el elemento selecionado en el select de tipos
    ***de procedimientos en versión movil*/
    const updatePhoneSelectedOption = value => {
        setPhoneSelectedOption(value);
    }

    /**Método que actualiza el numero de filas que muestra la tabla*/
    const handleChange = (event) => {
        setAnchorEl(event.currentTarget);
        setNumMostar(event.target.value);
        setCurrentPage(properties.INIT_PAGE);
      };

    /**Método que renderiza el paginador solo en versión ordenador */
    const renderPager = () =>{
        if(!isPhoneVersion){
            return <Grid item>
                <Pager recordsPerPage={numMostrar} totalRecords={procedimientosFiltrados.length} currentPage={currentPage} 
                paginate={paginate} numFilasMostrar={numMostrar} registrosTotales={procedimientosFiltrados.length} handleChange={handleChange}
                numFilasPersonalizado={true} disabledRegistros={disabledNumregistros} anchorEl={anchorEl}/>

            </Grid>
        }   
    }

    //**Gestiona los mensajes de por pantalla para errores, info, etc */
    const handleSetMessage = (error) => {
        setErrores(oldArray => [...oldArray, error]);
    }

    

    /**Método que capta el tamaño de la ventana y actualiza actualiza
    ***el hook que controla cuando se da el salto a versión movil*/
    const updateFormat = () => {

        if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    };   

    const mostrarAC = () =>{
        const flag = loadState(localStorageName.flagAvisosCriticos, false);
        if(flag != flagAc.VISTO){
            setMostrarAvisoC(true);
        }else{
            setMostrarAvisoC(false);
        }
    }

    const resetLocalStorage = () => {
        localStorage.removeItem(localStorageName.convocatoriaSeleccionada);
        localStorage.removeItem(localStorageName.perfilSeleccionado);
        localStorage.removeItem(localStorageName.procedimientoActual);
        localStorage.removeItem(localStorageName.resourceFormNuevaSolicitud);
        localStorage.removeItem(localStorageName.solicitudActual);
        localStorage.removeItem(localStorageName.recibi);
        localStorage.removeItem(localStorageName.idExpTrewa);
        localStorage.removeItem(localStorageName.numRegistroOrve);
        localStorage.removeItem(localStorageName.pdfSolicitud);
        localStorage.removeItem(localStorageName.urlProcedenciaProcedimiento);
        localStorage.removeItem(localStorageName.convocatoriasProcedimientoPCT);
        localStorage.removeItem(localStorageName.entidadSeleccionada);
        localStorage.removeItem(localStorageName.entidadesDeAlta);
        localStorage.removeItem(localStorageName.organismo);
    }

    window.addEventListener('resize', updateFormat);

    const indexOfLastRecord = currentPage * numMostrar;
    const indexOfFirstRecord = indexOfLastRecord - numMostrar;
    let disabledNumregistros = false;
    if(procedimientosFiltrados.length<5){
        disabledNumregistros = true;
    }
    let procedimientosMostrados = procedimientosFiltrados.slice(indexOfFirstRecord, indexOfLastRecord);

    //RENDER

    return (
    
        <main>
            <Avisos />
            <Grid container direction='column'  justify='space-between'>
                <Grid item>
                </Grid>
                <Grid container direction={isPhoneVersion ? 'column-reverse' : 'row'} justify='space-between'>
                    <Grid item className={style.gridPageTitle}>
                        <h1 id="tituloPagina" className={style.pageTitle}>{texts.PAGE_TITLE}</h1>
                    </Grid>
                    <Grid item className={isPhoneVersion ? style.gridSearchBarResponsive: style.gridSearchBar}>
                        <div className='x'><SearchBar data={procedimientos} updateSearch={updateSearch} updatePhoneSelectedOption={updatePhoneSelectedOption} refDefaultButtonBotonera={refDefaultButtonBotonera} refAutocomplteSearchBar={refAutocomplteSearchBar} paginate={paginate} isPhoneVersion={isPhoneVersion}/></div> 
                    </Grid>
                </Grid>

                <div className={style.backgroundRectangle}>
                    {isLogin && <section>
                        <Grid container direction='row'  justify='space-around'>
                            <section className={style.gridBotonera} aria-label={properties.ALABEL_FILTRADO}>
                                <Botonera dataUsuario={null} data={procedimientos} buttonsPropiedades={tipoProcedimientos} phoneSelectedOption={phoneSelectedOption} updatePhoneSelectedOption={updatePhoneSelectedOption} updateSearch={updateSearch}  refDefaultButtonBotonera={refDefaultButtonBotonera} refAutocomplteSearchBar={refAutocomplteSearchBar} paginate={paginate} isLogin={isLogin} isPhoneVersion={isPhoneVersion} tipoPct3={tipoProcedimientosPct3} dataUsuarioPCT={idProcedimientoPCT3}/>
                            </section>
                            <section className={isPhoneVersion ? style.gridTableResponsivePager : style.gridTablePager} aria-label={properties.ALABEL_LISTADO}>
                                <Grid item className={style.gridTable}>
                                    <Table options={isPhoneVersion ? procedimientosFiltrados : procedimientosMostrados} isPhoneVersion={isPhoneVersion}></Table>
                                </Grid>
                                {renderPager()}
                            </section>
                        </Grid>
                    </section>}
                </div>
            </Grid> 
            <Backdrop className={style.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Confirmacion
                {...objDataPopup}
                accionConfirmacion={handleConfirmar}
                mostrar={mostrarPopup}
                isPhoneVersion={isPhoneVersion}
                botonera={compProperties.CONFIR_SOLO_ACEPTAR}
                quitarImg={false}
                tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={false}
                isImagenPersonalizada={true}
                centrarContenido={true}
                isTextoEnriquecido={true}
                imagen={`${getContextFromUrl()}` + properties.POPUP_ICON}
            />

            <Confirmacion
                {...objDataPopupProcInterno}
                // titulo= 'Procedimiento no disponible'
                // mensaje= 'El procedimiento seleccionado no está disponible actualmente.'
                accionConfirmacion={()=>setMostrarPopupProcInterno(false)}
                mostrar={mostrarPopupProcInterno}
                isPhoneVersion={isPhoneVersion}
                botonera={compProperties.CONFIR_SOLO_ACEPTAR}
                quitarImg={false}
                tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={false}
                isImagenPersonalizada={true}
                centrarContenido={true}
                isTextoEnriquecido={true}
                imagen={`${getContextFromUrl()}` + properties.POPUP_ICON}
            />

            {(mostrarAvisoC && !mostrarPopup) && 
                <AvisosCriticos/>
            }

            {errores.map(error => ( 
                <Snackbar open={error.ex.error.response} autoHideDuration={properties.DISPLAY_TIME_ERROR} onClose={() => setErrores([])}>
                    <Alert
                        severity={error.severity}
                        variant="filled"
                        className={style.classAlerta}
                        >
                        {error.ex.error.response && error.ex.error.response.data && error.ex.error.response.data.messageDescription ? error.ex.error.response.data.messageDescription : texts.SERVER_ERROR_MSG}
                    </Alert>
                </Snackbar>
            ))}
        </main>
    )
}

export default ListadoProcedimientos;