import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { customStyle } from './PageNotFoundStyle';
import { useHistory } from 'react-router-dom';
import { getContextFromUrl } from 'config/Config';
import AppContext from 'AppContext';
import { properties as appProperties } from '../../../AppData';

export default function PageNotFound() {

    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const updateFormat = () => {
        window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches ? setIsPhoneVersion(true) : setIsPhoneVersion(false);
    }
    
    let classContenedorPrincipal = customStyle().classContenedorPrincipal;
    let classContenedorImgAndText = customStyle().classContenedorImgAndText;
    let classH2 = customStyle().classH2;
    let classParrafo = customStyle().classParrafo;
    let classList = customStyle().classList;
    let classLink = customStyle().classLink;
    let classImg = customStyle().classImg;
    let classImgPhoneVersion = customStyle().classImgPhoneVersion;
    
    let history = useHistory();
    let titulo = 'Error 404 - Página no encontrada';

    // Actualización del nombre de la página
    const context = useContext(AppContext);
    context.updateTitlePage(titulo);

    /**
     * @function goToHome Función para ir a la pantalla de inicio
     * Se utiliza cuando se hace click en el link inicio
     */
    const goToHome = () => {
        history.push('/');
    }
    /**
     * @function goToContacto Función para ir a la pantalla de contacto
     * Se utiliza cuando se hace click en el link contacto
     */
    const goToContacto = () => {
        history.push('/PantallaContacto');
    }

    useEffect(() => {
        updateFormat();
        return () => window.removeEventListener('resize', updateFormat);
    }, []) ;

    window.addEventListener('resize', updateFormat);

    return (
        // Contenedor principal
        <Grid className={classContenedorPrincipal}>
            {/* Contenedor de imagen y texto */}
            <Grid className={classContenedorImgAndText}>
                <img className={isPhoneVersion ? classImgPhoneVersion : classImg} src={`${getContextFromUrl()}` + 'search.svg'} />
                {/* Contenedor de texto */}
                <Grid>
                    <h2 className={classH2}>{titulo}</h2>
                    <p className={classParrafo}>La página que has solicitado no está disponible. Puede que haya cambiado de dirección o que ya no exista.</p>
                    <br />
                    <p className={classParrafo}>Para encontrar la información que buscas intenta lo siguiente:</p>
                    <br />
                    <ul className={classList}>
                        <li type="disc">Revisa la dirección que aparece en tu navegador y comprueba que no haya errores tipográficos.</li>
                        <li type="disc">Accede a la página de <a target="_blank" className={classLink} onClick={goToHome}>inicio</a> de la aplicación.</li>
                    </ul>
                    <br />
                    <p className={classParrafo}>Si piensas que la dirección es correcta o el enlace proviene de nuestro portal, avísanos a través de la página de <a target="_blank" className={classLink} onClick={goToContacto}>contacto</a>.</p>
                    <br />
                    <p className={classParrafo}><strong>Por favor, disculpa las molestias</strong></p>
                </Grid>
            </Grid>
        </Grid>
    );
}