import { makeStyles, createMuiTheme } from '@material-ui/core';
//Importación de estilos
import { colores, tipografia, estilosTextosPrincipales, buttonPrimaryBig } from '../../../AppStyle';

/**************************************************************************
* Clases de estilos para aplicar en los diferentes elementos del componente
**************************************************************************/
export default makeStyles({
    classContainer: {
        width: '72px',
        height: '60px',
        backgroundColor: colores.principales.apoyo,
        textAlign: 'center',
        padding: '0px !important',
    },
    classGrid: {
        width: '72px',
        height: '60px'
    },
    classIniciales: {
        fontFamily: tipografia.bold,
        fontSize: '24px',
        color: colores.principales.principal,
    },
    classTresPuntos: {
        color: colores.principales.principal,
    },
    classLabelUserName: {
        ...estilosTextosPrincipales.encabezadoH4,
        color: colores.principales.principal,
        overflow: 'hidden',
        margin: '20.27px auto 16px 24px',
    },
    classMenuItem: {
        ...estilosTextosPrincipales.body1,
        fontWeight: '600',
        color: colores.escalaGrises.cien,
        marginLeft: '24px',
    },
    classButton: {
        ...buttonPrimaryBig
    },
    classButtonUsuario: {
        width: '62px',
        height: '60px'
    },
    classLabelUltConexion: {
        ...estilosTextosPrincipales.body1,
        fontFamily: tipografia.light,
        color: colores.escalaGrises.ochenta,
        overflow: 'hidden',
        margin: '20.27px auto 16px 24px',
    },
    /************************VERSIÓN MÓVIL**********************/
    classContainerPhone: {
        width: 'auto',
        heigth: 'auto',
        backgroundColor: colores.escalaGrises.diez,
        marginLeft: '5%',
    },
    classGridPhone: {
        display: 'inline-grid',
    },
    classH3Phone: {
        ...estilosTextosPrincipales.encabezadoH4,
        color: colores.escalaGrises.cien,
        fontFamily: tipografia.regular,
        textAlign: 'left',
        marginTop: '16.74px',
    },
    classH1Phone: {
        fontFamily: tipografia.medium,
        fontSize: '18px',
        lineHeight: '24px',
        color: colores.principales.principal,
        textAlign: 'left',
        margin: '13.81px 0px 19px 0px',
    },
    classOpcButtonsPhone: {
        ...estilosTextosPrincipales.encabezadoH4,
        fontFamily: tipografia.medium + '!important',
        lineHeight: '16px',
        color: 'colores.escalaGrises.noventaYcinco',
        backgroundColor: colores.escalaGrises.veinte,
        height: '56px',
        width: '343px',
        marginBottom: '8px',
    },
    classButtonPhone: {
        backgroundColor: colores.principales.principal,
        color: colores.escalaGrises.cero,
        fontFamily: tipografia.bold,
        fontSize: '12px',
        lineHeight: '16px',
        margin: '15px 0px 15px 0px',
        borderRadius: '4px',
        border: '2px',
        borderStyle: 'solid',
        borderColor: colores.principales.verde,
        height: '56px',
        width: '343px',
    },
    classUltimaConexionPhone: {
        fontFamily: tipografia.regular,
        fontSize: '12px',
        lineHeight: '17px',
        color: colores.escalaGrises.ochenta,
        marginBottom: '16px',
    },
    classIcon: {
        width: '30px',
        height: '30px'
    }
});

/**
 * @constant overMenu contiene las clases de material UI que serán sobreescritas con las propiedades definidas
 * y posteriormente aplicadas sobre el componente.
 */
export const overMenu = createMuiTheme({
    overrides: {
        MuiIconButton: {
            root: {
                //padding: '3px !important ',
                color: `${(colores.escalaGrises.cero)} !important`                
            },
        },
        MuiPopover: {
            root: {
                marginTop: '2.5% !important',
            },
        },
        MuiListItem: {
            root: {
                height: '56px',
                paddingLeft: '24px !important',
                width: '100%'
            },
            button: {
                '&:hover': {
                    backgroundColor: colores.escalaGrises.quince,
                    width: '100%',
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 'auto',
            },
        },
        MuiList: {
            root: {
                padding: '0px !important',
                width: '100%',
            },
        },
        MuiMenu: {
            list: {
                marginRight: '7px',
            },
            paper: {
                width: '300px', 
                maxHeight: '90vh', 
            },
        },
    },
});

export const overMenuPhone = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
            },
            label: {
                fontFamily: tipografia.medium,
            },
        },
    },
});