export const texts = {
    PAGE_TITLE: 'Alta de entidad colaboradora',
    BTN_ALTA_ENTIDAD: 'ALTA DE ENTIDAD',
    BTN_ATRAS: 'ATRÁS'
}

export const objComponentFormio = {    
    aliasFormulario: 'interesadosProyectosColaborativos',
    sp: {
        complete: 'Formulario cumplimentado correctamente',
        error: 'Hubo un problema',
        next: 'Siguiente',
        previous: 'Atrás',
        cancel: 'Cancelar',
        submit: 'Guardar',
        required: 'Es obligatorio rellenar este campo',
        confirmCancel: '¿Estás seguro que quieres cancelar?',
        submitError: 'Por favor revise el formulario y corrija todos los errores.',
        pattern: 'El valor no cumple con el patrón requerido',
        email: 'Debe ser una dirección de correo electrónico válida',
        pattern: 'El valor no cumple con el patrón requerido',
        invalid_regex: 'El valor insertado no es válido'
    }
}

export const buttons = {
    btnAltaEntidad: {
        id: 'btnAltaEntidad',
        name: 'btnAltaEntidad'
    },
    btnAtras: {
        id: 'btnAtras',
        name: 'btnAtras'
    }
}

export const cruds = {
    interesado: {
        resource: 'interesado',
        url: '/insertarActualizarInteresadoOV3'
    },
    tipoVia: {
        resource: 'tipoVia',
        url: '/findByCodigo/'
    },
    tipoInteresado: {
        resource: 'tipo-interesado',
        url: '/findByCodigo/'
    }
}

export const datosInteresado = {
    interesado: {
        id: '',
        nombre: '',
        apellido1: '',
        apellido2: '',
        tipoIdentificador: 'DNI',
        identificador: '',
        sexo: '',
        fechaNacimiento: '',
        datosContactoPorDefecto: {}
    },
    datosContacto: {
        id: '',
        idDatoExpediente: null,
        interesado: {},
        tipoInteresado: {},
        tipoNotificacion: {},
        tipoVia: {},
        nombreVia: '',
        numero: '',
        letra: '',
        escalera: '',
        piso: '',
        puerta: '',
        postal: '',
        telefono: '',
        telefonoMovil: '',
        fax: '',
        email: '',
        idPais: '',
        pais: {},
        provincia: {},
        municipio: {},
        idProvincia: '',
        idMunicipio: '',  
        region: '',
        ciudad: '',
        fechaBaja: '',
        isNotificacion: ''
    }
}