import React, { useEffect, useState } from 'react';
import { Grid, Snackbar, ThemeProvider, Backdrop, CircularProgress } from '@material-ui/core';
import LineaMetro from 'components/commons/lineaMetro/lineaMetro';
import { properties, texts, buttons, objAttrib, tableHeader, formato, keysTablaGenerica, buttonVoluntario, buttonOptativo } from './AporteDocumentacionData';
import useStyles from './AporteDocumentacionStyle';
import { pasosNuevaSolicitud, compProperties, properties as appProperties } from './../../../AppData';
import DocumentoAnexoVisual from 'components/commons/documentoAnexoVisual/DocumentoAnexoVisual';
import Botonera from './../../commons/botoneraGenerica/botoneraGenerica';
import useFunctions from './AporteDocumentacionFunction';
import SubirArchivo from 'components/commons/subirArchivo/SubirArchivo';
import { Alert } from '@material-ui/lab';
import TablaGenerica from 'components/commons/tablaGenerica/tablaGenerica';
import VisualizadorPdf from 'components/commons/visualizadorPdf/VisualizadorPdf'
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import SubirArchivoVoluntario from 'components/commons/subirArchivoVoluntario/SubirArchivoVoluntario';
import { loadState } from 'providers/localStorage';
import { localStorageName } from 'providers/localStorageData';

export default function AporteDocumentacion() {

  // IMPORTS
  const { getInfoAnexos, infoAnexos, handleOnClickListo, listo, obtenerTituloPagina, tituloPagina, botonPorDefecto,
    handleOnDrop, datosTabla, alerts, message, setMessage, isPhoneVersion, updateFormat, handleChangeIsVisible, isVisible,
    numDocumentos, tamDocumentos, mostrarPDF, cerrarPopUpPdf, pdf, handleChange, documentosVoluntarios, eliminarAnexoVoluntario, anyadirPropiedadEliminar,
    eliminarAnexo, disabledListo, atrasFormulario, atrasOcultarTabla, isLoading, tamMaximoSobrepasado, cerrarConfirmarPeso, mostrarConfirmarError,
    cerrarErrorDropRejected, errorDropRejected, tamDropRejected, existenAnexosObligatrios, existenAnexosOptativos,
    nombrePdfDescargar, objectButtons, handleGuardarAnexos, mostrarConfirmacionGuardado, cerrarConfirmacionGuardado
  } = useFunctions();

  const { customStyle, theme } = useStyles(isPhoneVersion);
  const [idExpedienteTrewa, setIdExpedienteTrewa] = useState();

  const style = customStyle();

  // OTROS
  const idPrimerBoton = { ...buttons[0] }.id;

  let documentoVoluntario = {
    mostrarDropZone: false,
    value: "",
    mostrarDocAnexo: false,
    tamDoc: "",
  }
  let proAct = loadState(localStorageName.procedimientoActual, true);

  useEffect(() => {
    //Se vuelve a mostrar visible el scroll vertical, el cual se ocultó en FomrNuevaSolicitud
    document.querySelector('body').style.overflow = 'auto';
    getInfoAnexos();
    obtenerTituloPagina();
    documentosVoluntarios.push(documentoVoluntario);
    anyadirPropiedadEliminar();
    botonPorDefecto(idPrimerBoton);
    mostrarIdExpediente();
  }, []);

  /*se utiliza para tener marcado el boton obligatorio cuando volvemos atras
  desde la pantalla donde aparece el resumen de documentos*/
  useEffect(() => {
    if (datosTabla.length === 0) {
      document.querySelector(`#btnObligatorios`).click();
    }
  }, [datosTabla]);

  // Efecto que se ejecutará para mostrar el botón de anexos voluntarios
  useEffect(() => {

    if (infoAnexos?.listAnexosOptativos.length > 0) {

      for (var i = 0; i < buttons.length; i++) {
        if (buttons[i].id == 'btnOptativos') {
          buttons.splice(i, 1);
        }
      }

      Object.assign(objectButtons, { btnOptativos: false })
      buttons.push(buttonOptativo);
    }
    if (infoAnexos !== undefined && infoAnexos.permiteVoluntarios) {

      for (var i = 0; i < buttons.length; i++) {
        if (buttons[i].id == 'btnVoluntarios') {
          buttons.splice(i, 1);
        }
      }

      Object.assign(objectButtons, { btnVoluntarios: false })
      buttons.push(buttonVoluntario);
    }
  }, [infoAnexos]);

  const mostrarIdExpediente = () => {
    let solicitudActual = loadState(localStorageName.solicitudActual, true);
    if (solicitudActual && solicitudActual.idExpediente) {
      setIdExpedienteTrewa(`(${solicitudActual.idExpediente})`);
    }
  }

  const renderSeccionAnexoObligatorio = () => {
    return (
      !existenAnexosObligatrios
        ? <p className={style.classParrafoAnexoObligatorio}>{texts.TEXTO_NOEXISTEN_ANEXOS_OBLIGATORIOS}</p>
        :
        infoAnexos && infoAnexos.listAnexosObligatorios.map(anexo => (
          <SubirArchivo img={properties.PATH_IMG_PDF} tamMaximoArchivo={anexo.tamanoAnexo} texto={anexo.descripcionAnexo} formato={formato} handleOnDrop={(files, rejectedFiles) => handleOnDrop(files, rejectedFiles, anexo, anexo.nombreAnexo)}
            mostrarBotonEliminar={anexo.mostrarEliminar} id={anexo.nombreAnexo} bottonFunction={eliminarAnexo} tamDoc={anexo.tamDocSubido} handleRejection={(rejectedFiles) => mostrarConfirmarError(rejectedFiles, anexo.tamanoAnexo)} />
        ))
    )
  }

  const renderSeccionAnexoOptativo = () => {
    return (
      !existenAnexosOptativos
        ? <p className={style.classParrafoAnexoObligatorio}>{texts.TEXTO_NOEXISTEN_ANEXOS_OPTATIVOS}</p>
        :
        infoAnexos && infoAnexos.listAnexosOptativos.map(anexo => (
          <SubirArchivo img={properties.PATH_IMG_PDF} tamMaximoArchivo={anexo.tamanoAnexo} texto={anexo.descripcionAnexo} formato={formato} handleOnDrop={(files, rejectedFiles) => handleOnDrop(files, rejectedFiles, anexo, anexo.nombreAnexo)}
            mostrarBotonEliminar={anexo.mostrarEliminar} id={anexo.nombreAnexo} bottonFunction={eliminarAnexo} tamDoc={anexo.tamDocSubido} handleRejection={(rejectedFiles) => mostrarConfirmarError(rejectedFiles, anexo.tamanoAnexo)} />
        ))
    )
  }

  let numDocumentosMaximos = (infoAnexos ? infoAnexos.listAnexosObligatorios.length : 0)
    + (infoAnexos ? infoAnexos.listAnexosOptativos.length : 0);

  window.addEventListener('resize', updateFormat);

  return (
    <main>
      <Grid>
        <div className={style.gridPageTitle}>
          <Grid className={style.gridTitulo}>
            <h1 id={properties.ID_PAGE_TITLE} className={style.pageTitle}>{tituloPagina}</h1>
            {idExpedienteTrewa && <span className={style.idExpediente}>{idExpedienteTrewa}</span>}
          </Grid>
          <LineaMetro pasos={pasosNuevaSolicitud} activeStep={1} />
        </div>
        <div className={style.backgroundRectangle}>
          <Grid className={style.classSeparador}>
            <div>
              <h3 className={style.hclass}>
                {texts.DOCUMENTACION}
              </h3>
            </div>
            <div className={style.classDocumentos}>
              <DocumentoAnexoVisual img={properties.RUTA_IMG_MAX_NUM_ANEXO} texto={texts.NUM_DOCUMENTOS_ANEXAR} min={texts.MIN_DOCUMENTOS} max={numDocumentosMaximos} value={numDocumentos} />
              <DocumentoAnexoVisual img={properties.RUTA_IMG_MAX_PESO} texto={texts.PESO_DOCUMENTOS_ANEXAR} min={texts.MIN_DOCUMENTOS} max={infoAnexos ? infoAnexos.tamMaximoTotal : 0} value={tamDocumentos.toFixed(2)} esPeso={true} />
            </div>
          </Grid>
          {!listo &&
            <Grid className={style.classGridArchivos}>
              <Grid>
                <p className={style.classParrafoArchivo}>
                  {texts.ARCHIVOS}
                </p>
                <p className={style.classParrafoArchivoInformacion}>
                  {texts.SELECCIONAR_ARCHIVOS}
                </p>
                <Botonera
                  gridClassName={style.gridBotonera}
                  className={style.buttons}
                  theme={theme}
                  {...objAttrib.gridBotonera}
                  {...objAttrib.buttons}
                  object={buttons}
                  orientation={'horizontal'}
                  onClick={(e) => handleChangeIsVisible(e)}
                />
              </Grid>
              {infoAnexos !== undefined &&
                <ThemeProvider theme={theme}>
                  <Grid className={!isVisible.btnVoluntarios ? style.classGridAnexos : style.clasGridConjuntoSubirArchivo}>
                    {//Componente Generación de formularios
                      isVisible.btnObligatorios &&
                      renderSeccionAnexoObligatorio()
                    }
                    {//Componente Generación de formularios
                      isVisible.btnOptativos &&
                      renderSeccionAnexoOptativo()
                    }
                    {//Componente Generación de formularios
                      isVisible.btnVoluntarios &&
                      documentosVoluntarios.map(anexoV => (
                        <SubirArchivoVoluntario img={properties.PATH_IMG_PDF} tamMaximoArchivo={infoAnexos.tamMaxPorAnexoVoluntario} formato={formato} handleOnDrop={(files, rejectedFiles) => handleOnDrop(files, rejectedFiles, null)}
                          onChange={handleChange} value={anexoV.value} mostrarDrop={anexoV.mostrarDropZone} mostrarDocAnexo={anexoV.mostrarDocAnexo} tamDoc={anexoV.tamDoc}
                          bottonFunction={eliminarAnexoVoluntario} handleRejection={(rejectedFiles) => mostrarConfirmarError(rejectedFiles, infoAnexos.tamMaxPorAnexoVoluntario)} />
                      ))}
                  </Grid>
                </ThemeProvider>
              }
            </Grid>
          }
          {listo &&
            <Grid>
              <TablaGenerica tableData={datosTabla} headingColumns={tableHeader} isPhoneVersion={isPhoneVersion} {...keysTablaGenerica} messageNoData={!infoAnexos && texts.MESSAGE_NO_HAY_ANEXOS} />
            </Grid>
          }
        </div>
          <Grid container className={style.gridBotonesListoAtras} justify='flex-end' spacing={1}>
            <button className={style.botonAtras} onClick={!listo ? atrasFormulario : atrasOcultarTabla}>{texts.TEXT_BOTON_ATRAS}</button>
            {
              proAct.fromPct3 === undefined &&
              <button {...objAttrib.buttonGuardar} onClick={handleGuardarAnexos} className={style.botonListo}>{texts.BTN_GUARDAR}</button>
            }
            <button onClick={handleOnClickListo} className={style.botonListo} disabled={disabledListo}>{!listo ? texts.TEXT_BOTON_LISTO : texts.TEXT_BOTON_IR_PASO3}</button>
          </Grid>
      </Grid>

      {/*Confirmar mensaje error cuando sobrepasa el tamTotal de los docs*/}
      <Confirmacion
        accionConfirmacion={cerrarConfirmarPeso}
        titulo={texts.TITULO_CONFIRMARPESOSOBREPASADO}
        mostrar={tamMaximoSobrepasado}
        isPhoneVersion={isPhoneVersion}
        mensaje={texts.DESCRIPCION_CONFIRMARPESOSOBREPASADO_1 + texts.COMILLAS + texts.PESO_DOCUMENTOS_ANEXAR + texts.COMILLAS + texts.DESCRIPCION_CONFIRMARPESOSOBREPASADO_2}
        botonera={compProperties.SOLO_CERRAR}
        quitarImg={false}
        tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
        isPopUpPdf={false}
        textBotonConfirmarPersonalizado={false}
        isImagenPersonalizada={false}
        centrarContenido={true}
        isTextoEnriquecido={true}
      />

      {/*Confirmar para mostrar pdf*/}
      <Confirmacion
        accionConfirmacion={cerrarPopUpPdf}
        mostrar={mostrarPDF}
        isPhoneVersion={isPhoneVersion}
        mensaje={<VisualizadorPdf pdf={pdf} isPhoneVersion={isPhoneVersion} pdfNombre={nombrePdfDescargar} />}
        botonera={compProperties.SOLO_CERRAR}
        quitarImg={true}
        tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_0}
        isPopUpPdf={true}
        textBotonConfirmarPersonalizado={false}
        isImagenPersonalizada={false}
        centrarContenido={true}
        isTextoEnriquecido={false}
      />



      {/*Confirmar mensaje error cuando sobrepasa el tamTotal de los docs*/}
      <Confirmacion
        accionConfirmacion={cerrarErrorDropRejected}
        titulo={texts.TITULO_CONFIRMARPESOSOBREPASADO}
        mostrar={errorDropRejected}
        isPhoneVersion={isPhoneVersion}
        mensaje={texts.TEXTO_ERROR_DROP_REJECTED1 + tamDropRejected + texts.TEXTO_ERROR_DROP_REJECTED2}
        botonera={compProperties.SOLO_CERRAR}
        quitarImg={false}
        tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
        isPopUpPdf={false}
        textBotonConfirmarPersonalizado={false}
        isImagenPersonalizada={false}
        centrarContenido={true}
        isTextoEnriquecido={true}
      />

      {/* Confirmar mensaje de error cuando se pulsa el botón guardar y no existe ningún documento obligatorio seleccionado */}
      <Confirmacion
        accionConfirmacion={cerrarConfirmacionGuardado}
        titulo={texts.AVISO_GUARDADO}
        mostrar={mostrarConfirmacionGuardado}
        isPhoneVersion={isPhoneVersion}
        mensaje={texts.MESSAGE_GUARDADO_SIN_ANEXOS}
        botonera={compProperties.CONFIR_SOLO_ACEPTAR}
        quitarImg={false}
        tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
        isPopUpPdf={false}
        textBotonConfirmarPersonalizado={false}
        isImagenPersonalizada={false}
        centrarContenido={true}
        isTextoEnriquecido={true}
      />

      <Backdrop className={style.classBackdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {alerts.map(m => (
        <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
          <Alert
            severity={message.severity}
            variant="filled"
          >
            {message.msg}
          </Alert>
        </Snackbar>
      ))}
    </main>
  );
}