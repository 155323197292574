import {makeStyles} from '@material-ui/core/styles'
import {estilosTextosPrincipales, colores, backgroundRectangle} from '../../../AppStyle'

export const customStyle = makeStyles ({
    classTitleH2: {
        ...estilosTextosPrincipales.encabezadoH2,
        color: colores.escalaGrises.cien
    },
    gridPageTitle:{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2%',
        width: '90%',
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.cien,
    },
    backgroundRectangle:{
        ...backgroundRectangle
    },
});
