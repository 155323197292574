import { getContextFromUrl } from 'config/Config';

export const properties  = {
    btnConfirmacion: 'confirmacion',
    btnDenegacion: 'denegacion',
    AC_ICON_PATH: `${getContextFromUrl()}app/iconoAC.png`
}

export const texts  = {
    btnSiText: 'Sí',
    btnNoText: 'No',
    btnAceptarText: 'ACEPTAR',
    btnCancelarText: 'CANCELAR',
    ALT_AC: 'Confirmación',
    txtBtnCerrar: 'CERRAR'
}