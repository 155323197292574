/**************************************
 * Fichero de definición del componente
 *************************************/
import React from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import ConfiguracionDelComponente from "./SelectAutocompleteSectionConfiguration";
import SelectAutocompleteFormio from "./selectAutocompleteFormio";

export default class CustomComponent extends ReactComponent {
  /**
   * Esta función contiene las etiquetas del componente
   * 
   * 
   * @function builderInfo 
   */
  static get builderInfo() {
    return {
      title: "OV3-Select Autocomplete", //Nombre del componente
      icon: "caret-down", //Icono que aparece en el componente
      group: "basic", //Grupo al que pertenece
      documentation: "", //Documentación del componente
      weight: -11, //Posición en la que aparecerá el componente en su grupo correspondiente
      schema: CustomComponent.schema() //Schema del componente
    };
  }

  /**
   * Esta función es la configuración predeterminada del componente
   * 
   * 
   * @function schema
   */
  static schema() {
    return ReactComponent.schema({
      type: "selectAutocomplete",
      label: ""
    });
  }

  /*
   * Define el settingsForm al editar un componente en el constructor.
   */
  static editForm = ConfiguracionDelComponente;

  /**
   * @function setValues Funcion que recibe el objeto del formulario del componente SelectSimple
   * A partir de este objeto, genera un objeto con los valores necesarios para alimentar las props del componente SelectSimple.
   * @param {*} component Objeto devuelto por el formulario del componente SelectSimple
   * @returns Devuelve un objeto con los valores de las props del componente SelectSimple
   */

  setValues(component) {
    return (
      {
        idGrid: `grid${component.properties.id}`,
        id: component.properties.id,
        name: component.properties.name,
        label: component.label,
        maxlength: component.maxlength,
        nombreFiltrado: component.optionSelect,
        idInput: component.properties.id,
        nameInput: component.properties.name,
        placeholder: component.placeholder,
        noOptionsText: component.noOptionsText,
        refSelect: null,
        disabled: component.disabled,
        controladorEntpoint: component.controladorEndPoint,
        urlEndPoint: component.urlEndPoint,
        optionSelect: component.optionSelect,
        isRequired: component.validate.required
      }
    )
  }

  /**
   * Función que renderiza el componente customizado en el DOM
   * 
   * 
   * @function attachReact
   */
  attachReact(element) {
    return ReactDOM.render(
      <SelectAutocompleteFormio
        {...this.setValues(this.component)}
      />,
      element
    );
  }

  /**
   * Función que separa cualquier componente de react
   * 
   *
   * @function detachReact
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}