import { makeStyles } from "@material-ui/core";
import { backgroundRectangle, estilosTextosPrincipales, links } from "AppStyle";

export const customStyle = makeStyles({
    classContenedorPrincipal: {
        ...backgroundRectangle
    },
    classContenedorImgAndText: {
        padding: '2%',
        display: 'flex',
        alignItems: 'start'
    },
    classH2: {
        ...estilosTextosPrincipales.encabezadoH2,
        marginBottom: '2%'
    },
    classParrafo: {
        ...estilosTextosPrincipales.body1
    },
    classList: {
        ...estilosTextosPrincipales.body1,
        paddingLeft: '2%'
    },
    classLink: {
        ...links,
        cursor: 'pointer'
    },
    classImg: {
        width: '10%',
        marginRight: '2%',
        opacity: '10%'
    },
    classImgPhoneVersion: {
        width: '50%',
        marginRight: '2%',
        opacity: '10%'
    }
});