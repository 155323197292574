import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { backgroundRectangle, estilosTextosPrincipales, colores, buttonPrimarySmall, buttonSecundarySmall, tipografia, buttonPrimaryBig } from '../../../AppStyle';

const useStyles = (isPhoneVersion) => {

const customStyle = makeStyles({

    classDocumentos: {
        ...(isPhoneVersion) ? { display: 'block' } : { display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 'inherit' }        
    },
    pageTitle: {
        ...estilosTextosPrincipales.titulosPagina,
    },

    gridPageTitle: {
        marginTop: '2%',
        marginLeft: '5%',
        width: '90%'
    },

    backgroundRectangle: {
        ...backgroundRectangle,
        marginBottom: '0px'
    },

    hclass: {
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',
        marginLeft: '5%',
        marginBottom: 'revert',
        paddingTop: '2%'
    },
    classSeparador: {
        borderBottom: `1px solid ${colores.escalaGrises.diez}`,
    },
    classGridArchivos:{
        marginTop: '1%',
        paddingBottom: '1%',
        paddingRight: '5%',
    },
    classParrafoArchivo: {
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',
        marginLeft: '5%',
    },
    classParrafoArchivoInformacion: {
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.noventa,
        fontWeight: 'normal',
        marginLeft: '5%',
    },
    gridBotonera: {
       ...(isPhoneVersion) ? { margin: '5%' } : { margin: '2% 0 0 5%'}
    },
    classGridAnexos: {
        ...(isPhoneVersion) && { display: 'block' } 
    },
    botonListo:{
        ...buttonPrimarySmall,
        ...(isPhoneVersion) ? {marginLeft: '2.5%', width: '42.5%'} : {margin: '1% 0.5% 1% 1%'},
        
    },

    botonAtras:{
        ...buttonSecundarySmall,
        ...(isPhoneVersion) ? {marginRight: '2.5%', width: '42.5%'} : {margin: '1% 0.5% 1% 1%'},
        '&:hover': {
                    backgroundColor: colores.principales.fondoEspecifico,
                    color: colores.principales.principal,
                    borderColor: colores.principales.principal
                },
    },

    gridBotonesListoAtras:{
        ...(isPhoneVersion) ? { margin: '5% 0', justifyContent: 'center', width: '100%' } : { width:'100%', margin: 0, padding:'5%' },
        margin: 'auto',
        width:'auto',
        paddingTop:'20px',
        paddingBottom:'20px'
    }, 

    gridTabla:{
        paddingBottom: '5%'
    },
    
    classBackdrop: {
        zIndex: 1,
        color: colores.principales.blanco,
    },

    clasGridConjuntoSubirArchivo:{
        marginLeft: '5%',
        marginRight: '5%'

    },
    classParrafoAnexoObligatorio:{
        marginLeft: '5%',
    },
    gridTitulo:{
        display: 'flex', 
        flexDirection: 'row',
    },
    idExpediente:{
        marginLeft:'25px',
        color: colores.escalaGrises.sesenta,
        fontFamily: tipografia.bold,
        fontSize: '13px',
        height: 'inherit',
        display:'flex',
        alignItems: 'center'
    }
});

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                ...buttonPrimarySmall,
                top: 'none',
                left: 'none',
                backgroundColor: colores.escalaGrises.cero,
                color: colores.escalaGrises.cien,
                ...(isPhoneVersion) ? { width: '100%' } : { width: '225px' },
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '16px',
                marginBottom: '16px',
                marginRight: '5%',
                '&:hover': {
                    backgroundColor: colores.principales.fondoEspecifico,
                    color: colores.principales.principal,
                    borderColor: colores.principales.principal
                },
                '&:focus': {
                    backgroundColor: colores.principales.fondoEspecifico,
                    color: colores.principales.principal,
                    borderColor: colores.principales.principal
                },
            },
            outlined: {
                border: `1px solid ${colores.escalaGrises.apoyo}`,
                borderTopLeftRadius: '4px !important',
                borderTopRightRadius: '4px !important',
                borderBottomLeftRadius: '4px !important',
                borderBottomRightRadius: '4px !important',
            }
        },
        MuiButtonGroup: {
            root: {
                ...(isPhoneVersion) && { display: 'block' },
                marginBottom: '10px'
            },
            groupedOutlinedVertical: {
                '&:not(:last-child)': {
                    borderBottomColor: 'none'
                }
            },
            groupedOutlinedHorizontal: {
                '&:not(:last-child)': {
                    borderRightColor: 'none'
                }
            }
        },
        MuiDropzoneArea: {
            root: {
                minHeight: '175px',
                borderColor: colores.principales.principal,       
            }
        },
        MuiTypography: {
            body2: {
              ...estilosTextosPrincipales.body2,
              fontWeight: '700 !important',
              ...(!isPhoneVersion) && {letterSpacing: '0.26px', fontSize: '15px'}
        }},
    }    
});
return {customStyle, theme};
}

export default useStyles;