import React, { useEffect, useState } from 'react';
import { Grid,  CircularProgress, Backdrop, Snackbar } from '@material-ui/core';
import LineaMetro from 'components/commons/lineaMetro/lineaMetro';
import useFunctions from './FirmaSolicitudFunction';
import { pasosNuevaSolicitud, compProperties as componentsProperties, properties as propertiesApp } from './../../../AppData';
import useStyles  from './FirmaSolicitudStyle';
import { properties, texts, atribInputDialog, tipoFirma } from './FirmaSolicitudData';
import ButtonVisualPdf from 'components/commons/buttonVisualPdf/ButtonVisualPdf';
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import { loadState } from 'providers/localStorage';
import { localStorageName } from 'providers/localStorageData';
import Dialogo from './../../commons/dialogo/Dialogo';
import { Alert } from '@material-ui/lab';

export default function FirmaSolicitud() {

    // IMPORTS
    const { obtenerTituloPagina, tituloPagina, procedimientoActual, obtenerDatosUsuario, NIFUsuario, nombreApellidosUsuario, 
        atrasFormulario, handleChange, docMarcado, listDocs, isPhoneVersion, updateFormat, pdfMostrado, mostrarConfirmarFirma, mostrarConfirmarCorregir, mostrarConfirmarCorregirSubsanacion,
        mostrarConfirmacion, mostrarConfirmacionCorregir, mostrarConfirmacionCorregirSubsanacion, dejarDeMostrarConfirmacion, dejarDeMostrarConfirmacionCorregir, dejarDeMostrarConfirmacionCorregirSubsanacion, firmarSolicitud, firmarCorregirSolicitud, mostrarErrorPresentacion, dejarDeMostrarErrorPresentacion,
        msgErrorPresentacion, isLoading, obtenerAnexosYSolicitud, nombrePdfDescargar, isProcessingFirma, obtenerFasesEstados,
        solicitudColaborativa, isSolicitudColaborativa, perfilSeleccionado, accionSeleccionada, validarCodigoSms, codigoSms, handleShowDialogo, handleHideDialogo, mostrarDialogo,
        enviarCodigoSms, handleChangeDialogo, message, setMessage, alerts, obtenerTipoFirma, mostrarBtnFirmaCertOrClave } = useFunctions();
    
    const { style } = useStyles(isPhoneVersion);
    const customStyle = style();
    const [idExpedienteTrewa, setIdExpedienteTrewa] = useState();

    useEffect(() => {
        isSolicitudColaborativa();
        obtenerFasesEstados();
        obtenerAnexosYSolicitud();
        obtenerTituloPagina();
        obtenerDatosUsuario();  
        mostrarIdExpediente();  
    }, []);

    const mostrarIdExpediente = () => {
        let solicitudActual = loadState(localStorageName.solicitudActual, true);
        if(solicitudActual && solicitudActual.idExpediente){
          setIdExpedienteTrewa(`(${solicitudActual.idExpediente})`);
        }
    }

    let codEstadoActual = loadState(localStorageName.estado, false);

    window.addEventListener('resize', updateFormat);

    return(
        <main>
          <Grid>
            <Grid className={customStyle.gridPageTitle}>
                <Grid className={customStyle.gridTitulo}>
                    <h1 id={properties.ID_PAGE_TITLE} className={customStyle.pageTitle}>{tituloPagina}</h1>
                    {idExpedienteTrewa && <span className={customStyle.idExpediente}>{idExpedienteTrewa}</span>}
                </Grid>
                
                <LineaMetro pasos={pasosNuevaSolicitud} activeStep={2}/>
            </Grid>
            <Grid className={customStyle.backgroundRectangle}>
                <Grid className={customStyle.classDivRect}>
                    <Grid>
                        <h2 className={customStyle.hclassFirma}>
                            {texts.FIRMA}
                        </h2>
                        <p className={customStyle.classP}>
                            {texts.DESC_PAG_FIRMA}
                        </p>
                    </Grid>
                    <Grid>
                        <h2 className={customStyle.hclassFirma}>
                            {texts.DATOS_FIRMANTE_PROC}
                        </h2>
                        <Grid className={customStyle.gridContenedorDatos}>
                            <Grid className={customStyle.gridDatos}>
                                <h3 className={customStyle.hclassFirmante}>
                                    {texts.NIF}
                                </h3>
                                <p className={customStyle.classP}>
                                    {NIFUsuario}
                                </p>
                            </Grid>
                            <Grid className={customStyle.gridDatos}>
                                <h3 className={customStyle.hclassFirmante}>
                                    {texts.NOMBRE_APELLIDOS}
                                </h3>
                                <p className={customStyle.classP}>
                                    {nombreApellidosUsuario}
                                </p>
                            </Grid>
                            <Grid className={customStyle.gridDatos}>
                                <h3 className={customStyle.hclassFirmante}>
                                    {texts.PROCEDIMIENTO}
                                </h3>
                                <p className={customStyle.classP}>
                                    {procedimientoActual}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <h2 className={customStyle.hclassFirma}>
                            {texts.IMPRESOS_SOLICITUD}
                        </h2>
                        <p className={customStyle.classP}>
                            {texts.DESC_IMPRESOS_SOLICITUD}
                        </p>
                    </Grid>
                    
                    <Grid>
                        <h2 className={customStyle.gridListDoc}>{texts.LISTADO_DOC}</h2>
                        <ButtonVisualPdf listDoc={listDocs} value={docMarcado} onChange={(e, newValue) => handleChange(e, newValue)} isPhoneVersion={isPhoneVersion}
                            pdf={pdfMostrado} pdfNombre={nombrePdfDescargar}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={customStyle.gridBotonesListoAtras} justify='flex-end' spacing={1}>

                {/** Botón Atrás */}
                <button className={customStyle.botonAtras} onClick={atrasFormulario} >{texts.TEXT_BOTON_ATRAS}</button>

                {/** REVISAR SOLICITUD SICA */}
                {solicitudColaborativa && perfilSeleccionado.descripcion === 'INVESTIGADOR' && accionSeleccionada !== 'subsanar' && accionSeleccionada !== 'corregirSubsanacion' && 
                    <button className={customStyle.botonListo} onClick={mostrarConfirmacion} >{texts.TEXT_BOTON_REVISAR_SOLICITUD}</button>} 

                {/** REVISAR SUBSANACIÓN SICA */}
                {solicitudColaborativa && perfilSeleccionado.descripcion === 'INVESTIGADOR' && (accionSeleccionada === 'subsanar' || accionSeleccionada === 'corregirSubsanacion') && 
                    <button className={customStyle.botonListo} onClick={mostrarConfirmacion} >{texts.TEXT_BOTON_REVISAR_SUBSANACION}</button>} 

                {/** CORREGIR SOLICITUD SICA */}                    
                {solicitudColaborativa && perfilSeleccionado.descripcion === 'REPRESENTANTE_SICA' && accionSeleccionada !== 'revisarSubsanacion' && 
                    <button className={customStyle.botonListo} onClick={mostrarConfirmacionCorregir} >{texts.TEXT_BOTON_CORREGIR_SOLICITUD}</button>}

                {/** CORREGIR SUBSANACIÓN SICA */}
                {solicitudColaborativa && perfilSeleccionado.descripcion === 'REPRESENTANTE_SICA' && accionSeleccionada === 'revisarSubsanacion' && 
                    <button className={customStyle.botonListo} onClick={mostrarConfirmacionCorregirSubsanacion} >{texts.TEXT_BOTON_CORREGIR_SUBSANACION}</button>}

                {/** FIRMAR E IR AL PASO 4*/}
                {mostrarBtnFirmaCertOrClave() && 
                    <button className={customStyle.botonListo} onClick={mostrarConfirmacion} > {texts.TEXT_BOTON_IR_PASO4}</button>} 

                 {/** FIRMAR SICA */}
                {solicitudColaborativa && perfilSeleccionado.descripcion !== 'INVESTIGADOR' && 
                    <button className={customStyle.botonListo} onClick={mostrarConfirmacion} >{texts.TEXT_BOTON_FIRMA}</button>}

                {/** FIRMAR SMS */}                    
                {obtenerTipoFirma() === tipoFirma.FIRMA_SMS && <button className={customStyle.botonListo} onClick={enviarCodigoSms}>{texts.TEXT_BOTON_SMS}</button>}
            </Grid>

            {solicitudColaborativa && perfilSeleccionado.descripcion !== 'INVESTIGADOR' && perfilSeleccionado.descripcion !== 'REPRESENTANTE_SICA'
            && (codEstadoActual === properties.ESTADO_FASE_INICIO || codEstadoActual === properties.ESTADO_FASE_PENDIENTE_SUBSANACION) &&
                <Confirmacion
                    mostrar={mostrarConfirmarFirma} 
                    isPhoneVersion={isPhoneVersion}
                    titulo={codEstadoActual === properties.ESTADO_FASE_PENDIENTE_SUBSANACION ? texts.TITLE_SUBSANACION : texts.TITLE_SOLICITUD_COLABORATIVA}
                    mensaje={codEstadoActual === properties.ESTADO_FASE_PENDIENTE_SUBSANACION ? texts.TEXT_SUBSANACION_COLABORATIVA : texts.TEXT_SOLICITUD_COLABORATIVA}
                    botonera={componentsProperties.CONFIR_CANCELAR_ACEPTAR}
                    accionConfirmacion={firmarSolicitud}
                    accionDenegacion={componentsProperties.CONFIR_SIN_ACCION}
                    handleChange={dejarDeMostrarConfirmacion}
                    tamMarginTop={propertiesApp.COMFIRMAR_MARGINTOP_10}
                    isPopUpPdf={false}
                    textBotonConfirmarPersonalizado={true}
                    textoBotonConfirmar={texts.TEXT_BOTON_FIRMA}
                    isImagenPersonalizada={false}
                    centrarContenido={true}
                    isTextoEnriquecido={true}
                />
            }

            {solicitudColaborativa && perfilSeleccionado.descripcion === 'INVESTIGADOR' && accionSeleccionada !== 'subsanar' && accionSeleccionada !== 'corregirSubsanacion' && <Confirmacion
                mostrar={mostrarConfirmarFirma} 
                isPhoneVersion={isPhoneVersion}
                titulo={texts.TITLE_REVISION_SOLICITUD_COLABORATIVA_SICA}
                mensaje={texts.TEXT_REVISION_SOLICITUD_COLABORATIVA_SICA}
                botonera={componentsProperties.CONFIR_CANCELAR_ACEPTAR}
                accionConfirmacion={firmarSolicitud}
                accionDenegacion={componentsProperties.CONFIR_SIN_ACCION}
                handleChange={dejarDeMostrarConfirmacion}
                tamMarginTop={propertiesApp.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={true}
                textoBotonConfirmar={texts.TEXT_BOTON_REVISAR_SOLICITUD}
                isImagenPersonalizada={false}
                centrarContenido={true}
                isTextoEnriquecido={true}
            />}

            {solicitudColaborativa && perfilSeleccionado.descripcion === 'INVESTIGADOR' && (accionSeleccionada === 'subsanar' || accionSeleccionada === 'corregirSubsanacion') && <Confirmacion
                mostrar={mostrarConfirmarFirma} 
                isPhoneVersion={isPhoneVersion}
                titulo={texts.TITLE_REVISION_SUBSANACION_COLABORATIVA_SICA}
                mensaje={texts.TEXT_REVISION_SUBSANACION_COLABORATIVA_SICA}
                botonera={componentsProperties.CONFIR_CANCELAR_ACEPTAR}
                accionConfirmacion={firmarSolicitud}
                accionDenegacion={componentsProperties.CONFIR_SIN_ACCION}
                handleChange={dejarDeMostrarConfirmacion}
                tamMarginTop={propertiesApp.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={true}
                textoBotonConfirmar={texts.TEXT_BOTON_REVISAR_SUBSANACION}
                isImagenPersonalizada={false}
                centrarContenido={true}
                isTextoEnriquecido={true}
            />}

            {solicitudColaborativa && perfilSeleccionado.descripcion === 'REPRESENTANTE_SICA' && accionSeleccionada !=='revisarSubsanacion' && <Confirmacion
                mostrar={mostrarConfirmarCorregir} 
                isPhoneVersion={isPhoneVersion}
                titulo={texts.TITLE_CORREGIR_SOLICITUD_COLABORATIVA_SICA}
                mensaje={texts.TEXT_CORREGIR_SOLICITUD_COLABORATIVA_SICA}
                botonera={componentsProperties.CONFIR_CANCELAR_ACEPTAR}
                accionConfirmacion={firmarCorregirSolicitud}
                accionDenegacion={componentsProperties.CONFIR_SIN_ACCION}
                handleChange={dejarDeMostrarConfirmacionCorregir}
                tamMarginTop={propertiesApp.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={true}
                textoBotonConfirmar={texts.TEXT_BOTON_CORREGIR_SOLICITUD}
                isImagenPersonalizada={false}
                centrarContenido={true}
                isTextoEnriquecido={true}
            />}

            {solicitudColaborativa && perfilSeleccionado.descripcion === 'REPRESENTANTE_SICA' && accionSeleccionada ==='revisarSubsanacion' && <Confirmacion
                mostrar={mostrarConfirmarCorregirSubsanacion} 
                isPhoneVersion={isPhoneVersion}
                titulo={texts.TITLE_CORREGIR_SUBSANACION_COLABORATIVA_SICA}
                mensaje={texts.TEXT_CORREGIR_SUBSANACION_COLABORATIVA_SICA}
                botonera={componentsProperties.CONFIR_CANCELAR_ACEPTAR}
                accionConfirmacion={firmarCorregirSolicitud}
                accionDenegacion={componentsProperties.CONFIR_SIN_ACCION}
                handleChange={dejarDeMostrarConfirmacionCorregirSubsanacion}
                tamMarginTop={propertiesApp.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={true}
                textoBotonConfirmar={texts.TEXT_BOTON_CORREGIR_SUBSANACION}
                isImagenPersonalizada={false}
                centrarContenido={true}
                isTextoEnriquecido={true}
            />}

            {(solicitudColaborativa === false || (solicitudColaborativa && perfilSeleccionado.descripcion === 'REPRESENTANTE_SICA')) && <Confirmacion
                mostrar={mostrarConfirmarFirma} 
                isPhoneVersion={isPhoneVersion}
                titulo={texts.TEXT_CONFIRMAR_SOLICITUD}
                mensaje={texts.TEXT_CONFIRMAR_DESCRIPCION}
                botonera={componentsProperties.CONFIR_CANCELAR_ACEPTAR}
                accionConfirmacion={firmarSolicitud}
                accionDenegacion={componentsProperties.CONFIR_SIN_ACCION}
                handleChange={dejarDeMostrarConfirmacion}
                tamMarginTop={propertiesApp.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={true}
                textoBotonConfirmar={texts.TEXT_BOTON_ACEPTAR_FIRMA}
                isImagenPersonalizada={false}
                centrarContenido={true}
                isTextoEnriquecido={true}
            />}

            <Confirmacion
                mostrar={mostrarErrorPresentacion} 
                isPhoneVersion={isPhoneVersion}
                titulo={texts.TITLE_ERROR_PRESENTACION}
                mensaje={msgErrorPresentacion}
                botonera={componentsProperties.CONFIR_SOLO_ACEPTAR}
                accionConfirmacion={dejarDeMostrarErrorPresentacion}
                handleChange={dejarDeMostrarErrorPresentacion}
                tamMarginTop={propertiesApp.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                isImagenPersonalizada={true}
                imagen={properties.IMAGEN_ERROR}
                centrarContenido={true}
                isTextoEnriquecido={true}
            />
          <Dialogo
            titulo={texts.TITULO_DIALOGO}
            texto={texts.TEXTO_DIALOGO}
            objAtributosInput={{...atribInputDialog}}
            valueInput={codigoSms}
            onChangeInput={handleChangeDialogo}
            placeholderInput={texts.PLACEHOLDER_DIALOGO}
            handleAccion={validarCodigoSms}
            show={mostrarDialogo}
            handleShow={handleShowDialogo}
            handleHide={handleHideDialogo}
            textBtnCancela={texts.BTN_CANCELAR_DIALOGO}
            textBtnAccion={texts.BTN_ACCION_DIALOGO}
          />
          </Grid>
          <Backdrop className={customStyle.classBackdrop} open={isLoading}>
                <CircularProgress color="inherit" />
                {isProcessingFirma  && solicitudColaborativa && perfilSeleccionado.descripcion === 'REPRESENTANTE_SICA' &&
                    <h1 className={customStyle.classMarginTextInBlackDrop}>{texts.TEXT_BLACKDROP_SICA}</h1>
                }
                {isProcessingFirma  && solicitudColaborativa && perfilSeleccionado.descripcion === 'INVESTIGADOR' &&
                    <h1 className={customStyle.classMarginTextInBlackDrop}>{texts.TEXT_BLACKDROP_SICA}</h1>
                }
                
                {isProcessingFirma  && perfilSeleccionado.descripcion !== 'INVESTIGADOR' 
                    && perfilSeleccionado.descripcion !== 'REPRESENTANTE_SICA' &&
                    <h1 className={customStyle.classMarginTextInBlackDrop}>{texts.TEXT_BLACKDROP}</h1>
                } 
            </Backdrop>
            {alerts.map(m => (
          <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
            <Alert
              severity={message.severity}
              variant="filled"
            >
              {message.msg}
            </Alert>
          </Snackbar>
        ))}
        </main>    
    );
}
