
const useOperations = () => {
	const functionCamposEspecificos = function(input) {
		let expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
		let expresion_regular_cif = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;
		var tipo = input;
		if (expresion_regular_dni.test(tipo)) {
			tipo = "dni";
			return true;
		} else if (expresion_regular_cif.test(tipo)) {
			tipo = "cif";
			return "false";
		}
	}

	const functionValidadorRegex = function(input, identificador) {

		const regex = {
			email: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
			apellidoPrimero: /^[\w'\-,.][^0-9!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>_:[\]]{1,}$/,
			apellidoSegundo: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>:[\]]{1,}$/,
			codigoPostal: /^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/,
			numeric: /^\d+$/,
			numPiso: /^[0-9]{1,3}$/,
			mobilePhone: /^(6|7)[ -]*([0-9][ -]*){8}$/,
			phone: /^(9|8)[ -]*([0-9][ -]*){8}$/,
			birthdate: /^([0][1-9]|[12][0-9]|3[01])(\/|-)([0][1-9]|[1][0-2])\2(\d{4})$/,
			swift: /^[A-Z -]{7}[A-Z0-9 -]{3}([X0-9 -]{4})?$/,
			empty: /^[^]+$/,
			soloNumero: /^[\d]/,
		};
		const mensajeRegex = {
			email: "La dirección de correo introducida no cumple con el formato correcto.",
			apellidoPrimero: "El primer apellido introducido no cumple el formato correcto.",
			apellidoSegundo: "El segundo apellido introducido no cumple el formato correcto.",
			codigoPostal: "El C.P. no cumple el formato correcto.",
			numeric: "Solo se admiten números",
			numPiso: /^[0-9]{1,3}$/,
			mobilePhone: "El número de teléfono introducido no cumple con el formato correcto.",
			phone: "El número de teléfono introducido no cumple con el formato correcto.",
			birthdate: /^([0][1-9]|[12][0-9]|3[01])(\/|-)([0][1-9]|[1][0-2])\2(\d{4})$/,
			swift: "El SWIFT no cumple el formato correcto.",
			empty: "El campo esta vacío"
		}
		let mensaje = mensajeRegex[identificador];
		let expresion = regex[identificador];
		if (!!(expresion.test(input)) || input.length == 0) {
			return true;
		} else { return mensaje }

	};


	const functionValidacionID = function(input, component) {
		var numero;
		var tipo = input;
		var letr;
		var letra;
		let expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
		let expresion_regular_cif = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;
		if (expresion_regular_dni.test(tipo)) {
			tipo = "dni";
		} else if (expresion_regular_cif.test(tipo)) {
			tipo = "cif";
		} else if (tipo.length === 0) {
			tipo = "vacío";
		}
		else {
			tipo = "error";
		}

		switch (tipo.valueOf()) {
			case "dni":
				if (expresion_regular_dni.test(input) === true) {
					numero = input.substr(0, input.length - 1);
					numero = numero.replace("X", 0);
					numero = numero.replace("Y", 1);
					numero = numero.replace("Z", 2);
					letr = input.substr(input.length - 1, 1);
					numero = numero % 23;
					letra = "TRWAGMYFPDXBNJZSQVHLCKET";
					letra = letra.substring(numero, numero + 1);
					if (letra !== letr.toUpperCase()) {
						return "DNI/NIE incorrecto";

					} else {

						return true;
					}
				} else {
					return "NIF incorrecto";

				}

			case "cif":
				//quita primer y último caracter de cif ->sí
				var aux = input.substr(1, input.length - 2);
				var suma = 0;
				//suma de pares ->sí
				for (var i = 1; i < aux.length; i += 2) {
					suma = suma + parseInt(aux.substr(i, 1), 10);
				}

				//suma de impares multiplicadas por 2 ->sí
				var suma2 = 0;
				for (i = 0; i < aux.length; i += 2) {
					var result = parseInt(aux.substr(i, 1), 10) * 2;
					if (String(result).length === 1) {
						suma2 = suma2 + parseInt(result, 0);
					} else {
						suma2 =
							suma2 +
							parseInt(String(result).substr(0, 1), 10) +
							parseInt(String(result).substr(1, 1), 10);
					}
				}
				//suma los pares y los impares ->sí
				suma = suma + suma2;

				//nos quedamos con la segunda cifra que salga ->sí
				var unidad = "";
				if (suma > 9) {
					unidad = String(suma).substr(1, 1);
				} else {
					unidad = String(suma);
				}
				//console.log(unidad.valueOf());

				//se resta 10 a la segunda cifra que salió ->sí
				unidad = 10 - parseInt(unidad, 10);
				//console.log(unidad.valueOf());

				//almacena primer y último caracter ->sí
				var primerCaracter = input.substr(0, 1).toUpperCase();
				var lastchar = input.substr(input.length - 1, 1);
				var lastcharchar = parseInt(lastchar, 10);
				//si no es un número ->sí
				if (isNaN(lastchar) !== false) {
					lastcharchar = String.fromCharCode(64 + parseInt(lastchar, 10));
				}

				// revisas segun primer caracter ->sí

				if (primerCaracter.match(/^[KLMNPQRSW]$/)) {
					if (String.fromCharCode(64 + unidad).toUpperCase() === lastchar) {
						// cambiamos atributo cif del campo dni a true para que bloque los demas campos.


						return true;
					} else {

						return "CIF incorrecto";

					}
				}
				if (primerCaracter.match(/^[ABCDEFGHJUV]$/)) {
					if (unidad === 10) {
						unidad = 0;
					}

					//revisa si el último dígito es un número ->sí
					if (parseInt(input.substr(input.length - 1, 1), 10) === unidad) {
						//cif correcto, se bloquea apellidos
						console.log("componente:");
						const aux2 = JSON.stringify(component);
						console.log(aux2);

						return true;
					} else {
						return "CIF incorrecto";

					}
				}

				break;
			case "error":
				return "El DNI/NIE/NIF no cumple el formato correcto";
				break;

			case "vacío":
				if (input.length === 0) {
					return true
				};
				break;

			default:
				return "El DNI/NIE/NIF no cumple el formato correcto";
				break;
		}


	}
	function functionValidacionIBAN(IBAN) {

		if (IBAN) {
			//Se pasa a Mayusculas
			IBAN = IBAN.toUpperCase();
			//Se quita los blancos de principio y final.
			IBAN = IBAN.split('/').join('');
			IBAN = IBAN.trim();

			IBAN = IBAN.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

			let letra1, letra2, num1, num2;
			let isbanaux;
			//La longitud debe ser siempre de 24 caracteres
			if (IBAN.length != 24) {
				return "El IBAN no cumple el formato correcto";
			}

			// Se coge las primeras dos letras y se pasan a números
			letra1 = IBAN.substring(0, 1);
			letra2 = IBAN.substring(1, 2);
			num1 = getnumIBAN(letra1);
			num2 = getnumIBAN(letra2);
			//Se sustituye las letras por números.
			isbanaux = String(num1) + String(num2) + IBAN.substring(2);
			// Se mueve los 6 primeros caracteres al final de la cadena.
			isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

			//Se calcula el resto, llamando a la función modulo97, definida más abajo
			var resto = modulo97(isbanaux);
			if (resto == 1 || IBAN.length == 0) {
				return true;
			} else {
				return "El IBAN no cumple el formato correcto.";
			}

		} else {
			return true;
		}


	}

	function modulo97(iban) {
		var parts = Math.ceil(iban.length / 7);
		var remainer = "";

		for (var i = 1; i <= parts; i++) {
			remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
		}

		return remainer;
	}

	function getnumIBAN(letra) {
		var ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		return ls_letras.search(letra) + 10;
	}
	return {
		functionValidacionID, functionValidacionIBAN, functionValidadorRegex, functionCamposEspecificos

	};

}
export default useOperations;
