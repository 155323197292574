import { makeStyles } from '@material-ui/core/styles';
import { estilosTextosPrincipales, colores, tipografia } from '../../../AppStyle';
import {createMuiTheme} from '@material-ui/core';

export const style = makeStyles({
    classTableGeneric: {
        fontFamily: tipografia.regular,
        width: '100%',
        paddingRight: '2%',
        fontSize: '17px',
        paddingLeft: '2%',
        paddingBottom: '2%',
    },
    classTableGenericResponsive: {
        fontFamily: tipografia.regular,
        width: '100%',
        paddingRight: '4%',
        paddingLeft: '4%',
        fontSize: '17px',
        paddingTop: '5%',
        paddingBottom: '5%'
    },
    /*Estilo aplicado para el texto de la cabereca de la tabla*/
    classthead: {
        textTransform: 'uppercase',
        fontFamily: tipografia.regular,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '17px',
        lineHeight: '18.86px',
        borderBottom: `1px solid ${colores.escalaGrises.cien}`,
        cursor: 'pointer'
    },
    classCeldaNombre: {
        color: colores.principales.negro,
        fontFamily: tipografia.regular,
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        textDecoration: 'none',       
    },
    classIcons:{
        marginLeft: '5px',
        cursor: 'pointer',
        fill: colores.escalaGrises.ochentaYcinco,
            '&:hover': {
                opacity: 0.7,
                border: 'none',
            },
    },
    /*Clase para la tabla responsive par*/
    classTablaResponsivePar: {
        backgroundColor: colores.escalaGrises.cero,
        fontFamily: tipografia.regular,
        
    },
    /*Clase para la tabla responsive Impar*/
    classTablaResponsiveImpar: {
        backgroundColor: colores.principales.apoyo1,
    },
    classTablaResponsiveGeneral: {
        alignContent: 'center',

    },
    /*Clase para la celda de la cabecera en la tabla responsive*/
    classCeldaCabeceraResponsive: {
        color: colores.escalaGrises.cien,
        fontFamily: tipografia.regular,
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '19px',
        letterSpacing: '0px',
        textAlign: 'left',
        textTransform: 'uppercase',
        width: '38%',
    },
    /*Clase para la primera celda en la tabla responsive */
    classCeldaNombreResponsive: {
        color: colores.principales.principal,
        fontFamily: tipografia.regular,
        width: '182px',
        textDecoration: 'none',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        alignItems: 'center',
    },
    /*Clase para las celda de los demas campos en la tabla responsive*/
    classCeldaCamposResponsive: {
        color: colores.escalaGrises.cien,
        ...estilosTextosPrincipales.body1,
        alignItems: 'center',
    },
    classBarraSeparacion: {
        borderBottom: `1px solid ${colores.escalaGrises.cien}`,
    },
    classDivNoData: {
        backgroundColor: colores.principales.apoyo1,
        textAlign: 'center',
        paddingTop: '1%',
        paddingBottom: '1%',
    },
    classDivNoDataResponsive: {
        backgroundColor: colores.principales.apoyo1,
        textAlign: 'center',
        paddingTop: '2%',
        paddingBottom: '2%',
        borderBottom: `1px solid ${colores.escalaGrises.cien}`,
    }, 
    classImgIcon: {
        width: '25px',
        height: '25px',
        opacity: '0.70',
        fill: colores.escalaGrises.ochentaYcinco,
            '&:hover': {
                opacity: 0.7,
            },
    },
    classImgIconResponsive: {
        width: '25px',
        height: '25px',
        marginRight: '10%',
        opacity: '0.70',
        fill: colores.escalaGrises.ochentaYcinco,
            '&:hover': {
                opacity: 0.7,
            },
    }
});
export const overrideStyle = createMuiTheme({
    overrides: {
        MuiTableRow: {
            
            root: {
                backgroundColor: colores.escalaGrises.cero,
                boxShadow: `0px 1px 0px ${colores.escalaGrises.cien}`,
                '&:nth-of-type(odd)': {
                    backgroundColor: colores.principales.apoyo1,
                    fontWeight: 'bold',
                },
            },
        },
        //Evito que se apliquen los estilos de las demas columnas de la tabla al encabezado
        MuiTableCell: {
            root: {                
                borderBottom: 'none',
                fontFamily: tipografia.regular,
            },
            head:{
                fontWeight: 'bold',
                fontSize: '17px', 
                fontFamily: tipografia.regular,
                '&:hover': {
                    backgroundColor: colores.escalaGrises.cero,
                    fontWeight: 'bold',
                },
                '&:nth-of-type(odd)': {
                    backgroundColor: colores.escalaGrises.cero,
                    fontWeight: 'bold',
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: colores.escalaGrises.cero,
                    fontWeight: 'bold',
                  },
                  
            },    
            body: {
                fontFamily: tipografia.regular,
                fontSize: '16px', 
            }      
        },
    },
});
