import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { estilosTextosPrincipales, colores, tipografia, backgroundRectangle, buttonPrimaryBig, buttonSecundaryBig } from '../../../AppStyle';

export const style = makeStyles({
    classGridContenedor: {
        ...backgroundRectangle
    },
    classGridCabecera: {
        height: 'fit-content',
        margin: 'auto',
        width: '90%',
        alignItems: 'end',
        marginTop: '24px'
    },
    classGridBuscador: {
        margin: '0 0 0 auto'
    },
    classGridBuscadorResponsive: {
        width: '100%',
        marginLeft: '5%',
        marginRight: '5%',
    },
    classGridSelect: {
        margin: '19px auto auto 32px'
    },
    selectOrganismo: {
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        '& div': {
            margin: '0px',
        }
    },
    classH2: {
        ...estilosTextosPrincipales.titulosPagina,
    },
    classPopupTitle: {
        ...estilosTextosPrincipales.encabezadoH2,
        color: colores.escalaGrises.cien,
        margin: '32px 32px 32px 32px'
    },
    buttonNuevaSolicitud: {
        ...buttonPrimaryBig,
        margin: '48px 20px 50px auto',
        display: 'block'
    },
    botoneraPopupOrganismo: {
        margin: '150px 0 30px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px',
    },
    classButton: {
        ...buttonPrimaryBig,
    },
    classButtonResponsive: {
        width: '100%',
        marginLeft: '4%',
        ...buttonPrimaryBig,
        margin: '15px 14px 13px auto',
        display: 'block'
    },
    classButtonFilterResponsive: {
        ...buttonSecundaryBig,
        width: '100%',
        
        marginRight: '4%',
    },
    classBorrame: {
        marginTop: '10px',
    },
    classTable: {
        backgroundColor: '',
        width: '100%',
        height: '300px',
        margin: 'auto 32px 0px 32px'
    },
    classImgIcon: {
        fill: colores.escalaGrises.ochentaYcinco, 
        '&:hover': {
            opacity: 0.7, 
        },
    },
    classPager: {
        marginRight: '28px',
        marginBlock: '1%',
    },
    ///////////////////////////////BACKDROP
    classBackdrop: {
        zIndex: 1,
        color: colores.principales.blanco,
    },
    //Estilos aplicados a los selectores
    classGrid: {
        textAlign: 'left',
        marginTop: '15px',
        marginBottom: '19px',
        marginRight: '32px',
        width: 'auto',
        display: 'block',
    },
    classInputLabel: {
        ...estilosTextosPrincipales.body2,
        color: colores.escalaGrises.ochenta,
        fontSize: '17px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: '17px',
        marginBottom: '5px',
        position: 'relative',
        '&.Mui-focused': {
            color: colores.escalaGrises.ochenta,
        },
    },
    classSpanRequired: {
        color: colores.principales.required,
        ...estilosTextosPrincipales.body2,
    },
    searchButton: {
        borderRadius: '0',
        height: '48px',
        width: '48px',
        backgroundColor: colores.principales.principal, 
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px', 
        '&:hover': {
            backgroundColor: colores.principales.principal, 
        }
    },
    searchImg: {
        height: '48px',
        width: '48px',  
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
        '&:hover': {
            backgroundColor: colores.principales.hover, 
        }
    },
    searchButtonPhone:{
        borderRadius:'0', /**Que sea un cuadrado */
        height: '40px',
        width: '40px',
        borderBottomRightRadius:'5px', /**Que tenga la esquina curvo */
        borderTopRightRadius:'5px',
        '&$focused': {
            boxShadow: `${colores.principales.principal} 0 0 10px`,       
        },        
    },
    searchImgPhone:{
        height: '40px',
        width: '40px',
        borderBottomRightRadius:'5px', /**Que tenga la esquina curvo */
        borderTopRightRadius:'5px'
    },
    classSelectResponsive: {
        marginLeft: "5%",
        width: '100%',
    },
    

});

export const objStyle = {
    classGridPager: {
        direction: "row",
        justify: "flex-end",
        alignItems: "center"
    }
}


const obtenerTamanoBuscadorPhoneVersion = () => {
    const tamanoPagina = window.screen.width;
    const tamanoLupa = 48;
    const margen = 10;
    let resultado = '';
    resultado = `${tamanoPagina - ((tamanoPagina * margen) / 100) - tamanoLupa}px`;
    return resultado;
}

export default function customizate(condition) {
    const overInputs = createMuiTheme({
        overrides: {
            MuiInputBase: {//Clases aplicadas en los inputs
                root: {
                    border: `1px solid ${colores.principales.principal}`,
                    height: '48px',
                    ...(condition) ? { width: obtenerTamanoBuscadorPhoneVersion } : { width: '280px' }
                },
                input: {
                    ...estilosTextosPrincipales.body2,
                    fontSize: '17px',
                    //lineHeight: '24px',
                    letterSpacing: '0px',
                    color: colores.escalaGrises.cien,
                    '&::placeholder': {
                        ...estilosTextosPrincipales.body2,
                        fontSize: '17px',
                        //lineHeight: '24px',
                        letterSpacing: '0px',
                        opacity: '0.8',
                        color: colores.escalaGrises.cien,
                    },
                },
            },
            MuiOutlinedInput: {//Clases aplicadas a los bordes de los inputs
                root: {
                    '&:hover $notchedOutline': {
                        outline: '2px',
                        borderStyle: 'solid',
                        borderColor: colores.principales.principal
                    },
                    '&$focused $notchedOutline': {
                        outline: '2px',
                        borderStyle: 'solid',
                        borderColor: colores.principales.principal
                    },
                },
                input: {
                    fontSize: '16px',
                    fontFamily: tipografia.regular,
                    '&::placeholder': {
                        color: colores.escalaGrises.cien,
                        fontSize: '16px',
                        fontFamily: tipografia.regular,
                    },
                },
                notchedOutline: {
                    border: '1px solid',
                    borderColor: colores.principales.apoyo,
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px'
                },
            },
            MuiInputLabel: {//Evita que se reduzca el título
                shrink: {
                    transform: 'none'
                },
            },
        },
    })
    return overInputs;
}