import { getContextFromUrl } from 'config/Config';

export const properties = {
    ID_PAGE_TITLE: 'tituloPagina',
    URL_ICON: `${getContextFromUrl()}` +  'archivoOk.svg',
}

export const texts = {
    PAGE_TITLE: 'Justificante y recibí de ',
    TEXT_PRINCIPAL: 'Nueva Solicitud de ',
    TEXT_JUSTIFICANTE_RECIBI: 'Justificante y recibí',
    TEXT_BOTON: 'FINALIZAR E IR A LA BANDEJA DE SOLICITUDES',
    TEXT_SOLICITUD_PRESENTADA: 'Solicitud presentada correctamente',
    TEXT_DESCRIPCION1: 'Su solicitud ha sido presentada satisfactoriamente, podra consultar en su bandeja de entrada el estado de la solicitud.',
    TEXT_DESCRIPCION2: 'Además podrá descargarse la solicitud sellada y el recibí.',
    TEXT_DESCARGAR_SOLICITUD: 'DESCARGAR SOLICITUD SELLADA',
    TEXT_DESCARGAR_RECIBI: 'DESCARGAR RECIBÍ',
    TEXT_NUM_ORVE: 'Número de registro en orve: ',
    TEXT_ID_TREWA: 'Identificador del expediente: ',
    TEXT_ID_SOLICITUD: 'Identificador de la solicitud: ',
    TEXT_ID_EXPEDIENTE: 'Identificador del expediente: ',
    TEXT_NUM_EXPEDIENTE: 'Número del expediente: '
}