import { createMuiTheme } from '@material-ui/core';
import { colores, estilosTextosPrincipales } from '../../../AppStyle';

export default function getTheme(condition) {
    const theme = createMuiTheme({
        overrides: {
            MuiAutocomplete: {
                //.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input
                root: {
                    ...(condition) ? { height: '40px' } : { height: '48px' },
                    ...(condition) ? { width: '312px' } : { width: '328px' },
                },
                option: {
                    color: colores.escalaGrises.ochenta,
                    '&:hover': {
                        backgroundColor: colores.principales.selectHover,
                    },
                }
            },
            MuiFormHelperText: {//Clases aplicadas en los helpTexts
                root: {
                    ...estilosTextosPrincipales.body2,
                    color: colores.estado.error,
                    fontSize: '12px',
                },
                contained: {
                    marginLeft: '0px',
                    marginRight: '0px',
                }
            },
            MuiInputBase: {//Clases aplicadas en los inputs
                root: {
                    border: `1px solid ${colores.principales.principal}`,
                    ...estilosTextosPrincipales.body2,
                    ...(condition) ? { height: '40px' } : { height: '48px' },
                    ...(condition) ? { width: '312px' } : { width: '348px' },
                    backgroundColor: colores.escalaGrises.cero,
                    '&$disabled': {//Clase aplicada cuando el input está deshabilitado
                        backgroundColor: colores.escalaGrises.diez,
                    },
                },
                input: {
                    ...estilosTextosPrincipales.body2,
                    fontSize: '16px',
                    //lineHeight: '24px',
                    letterSpacing: '0px',
                    color: colores.escalaGrises.cien,
                    '&::placeholder': {
                        ...estilosTextosPrincipales.body2,
                        fontSize: '16px',
                        //lineHeight: '24px',
                        letterSpacing: '0px',
                        opacity: '0.8',
                        color: colores.escalaGrises.cien,
                    },
                },
            },
            MuiOutlinedInput: {//Clases aplicadas a los bordes de los inputs
                root: {
                    '&:hover $notchedOutline': {
                        border: '2px solid !important',
                        borderColor: `${colores.principales.principal} !important`
                    },
                    '&$focused $notchedOutline': {
                        border: '2px solid !important',
                        borderColor: `${colores.principales.principal} !important`
                    },
                    '&&& $input': {
                        padding: "0px"
                    }
                },
                notchedOutline: {
                    border: '1px solid !important',
                    borderColor: `${colores.principales.apoyo} !important`,
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                },
                '&&& $input': {
                    padding: "0px"
                },
            },
            MuiInputLabel: {//Evita que se reduzca el título
                shrink: {
                    transform: 'none'
                },
            }
        },
    });
    return theme;
}