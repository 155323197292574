import { ReactComponent as CertificadoDigital } from './imagen/certificado-digital-ic.svg';
import { ReactComponent as EntradaClave } from './imagen/sin-certificado-bandeja.svg';
import { ReactComponent as Interesado } from './imagen/interesado-ic.svg';
import { ReactComponent as SinCertificado } from './imagen/entrada-clave-ic.svg';
import { ReactComponent as UsuarioRegistrado } from './imagen/usuario-registrado-ic.svg';
import React from 'react';

/**
 * @constant stateButtons función simple que obtiene el estado de los botones (disabled)
 * @param {*} condition valor booleano que recibe por parametro
 */
export const setStateButtons = (condition) => ({
    ...(condition ? { disabled: true } : { disabled: false }),
});

export const properties = {
    RESOURCE: 'clave',
    RESOURCE_LOCAL: 'SpringSAMLIntegrationExample',
    URL_LOGIN: 'login',
    RESOURCE_INTERESADO_ENTIDAD:'operacionesInteresado',
    URL_CARGA_PANTALLA_INTERESADO: 'cargaPantallaInteresado',
    RESOURCE_SALTO_BANDEJA:'logInBandeja',
    label_acces_convocatoria: 'convocatoria',
    label_acces_perfil: 'perfil',
    label_acces_metodoAcceso: 'metodo de acceso',
    aria_acces_certificadoDigital: 'Certificado digital',
    aria_acces_clave: 'Entrada con clave',
    aria_acces_registrado: 'Entrada mediante login',
    aria_acces_sinCertificado: 'Entrada sin certificado',
    aria_acces_entradaBandeja: 'Entrada a la bandeja',
    RESOURCE_PROCEDIMIENTOS_PCT3: 'procedimiento',
    RESOURCE_GET_PROCEDIMIENTOS: '/getProcedimiento',
    CODIGO_CERTIFICADO: 'Certificado',
    CODIGO_CLAVE: 'Clave',
    CODIGO_REGISTRADO: 'Registrado',
    CODIGO_ANONIMO: "Anonimo",
    RESOURCE_INTERESADO: 'interesado',
    RESOURCE_OBTENER_DATOS_INTERESADO: 'obtenerDatosInteresadoPorDefecto',
    PROCEDIMIENTO_V3: 'V3',
    URL_PROCEDIMIENTOS_OV : '/procedimientoOV',
    RESOURCE_PCT_PROCEDIMIENTO : 'procedimiento',
    RESOURCE_CONVOCATORIAS: 'convocatoria',
    RESOURCE_GET_CONVOCATORIAS: '/getConvocatorias/',
}

export const flagAcProc = {
    VISTO : 'visto'
}

/**
 * @constant objAttributesSeleccionConvocatoriaPerfil 
 * Objeto que contiene los atributos y propiedades de los componentes.
 */
export const objAttributesSeleccionConvocatoriaPerfil = {
    //CONTAINERS
    containers: {
        formSeleccionConvocatoriaPerfil: {
            id: 'containerFormSeleccionConvocatoriaPerfil',
        },
        componentes: {
            id: 'containerComponentesConvocatoriaPerfil',
        },
    },
    //GRIDS
    grids: {
        encabezado: {
            id: 'gridEncabezadoConvocatoriaPerfil',
        },
        seccion1: {
            id: 'gridConvocatoriaPerfil',
        },
        seccion2: {
            id: 'gridPerfilConvocatoriaPerfil,',
        },
        seccion3: {
            id: 'gridMetodoAccesoConvocatoriaPerfil',
        },
    },
    //ICONOS
    icon: {
        interesado: <Interesado />,
        interesadoRespon: <Interesado style={{ height: '40px', width: '40px' }} />,
        entradaConCertificado: <CertificadoDigital />,
        entradaConCertificadoRespon: <CertificadoDigital style={{ height: '40px', width: '40px' }} />,
        entradaConClave: <EntradaClave />,
        entradaConClaveRespon: <EntradaClave style={{ height: '40px', width: '40px' }} />,
        entradaConRegistro: <UsuarioRegistrado />,
        entradaConRegistroRespon: <UsuarioRegistrado style={{ height: '40px', width: '40px' }} />,
        entradaSinCertificado: <SinCertificado />,
        entradaSinCertificadoRespon: <SinCertificado style={{ height: '40px', width: '40px' }} />,
    },
    form: {
        id: 'formSeleccionConvocatoria',
        name:'formSeleccionConvocatoria',
    },
    //SELECT
    select: {
        idGrid: 'gridSelectConvocatoria',
        id: 'seleccionConvocatoria',
        name: 'seleccionConvocatoria',
        maxlength: '',
    },
    //RadioButton
    radioButton: {
        idGrid: 'gridRadioButtonPerfil',
        componentFormControl: 'div',
        title: '',
        componentFormLabel: 'label',
        ariaLabel: 'perfil',
        idRadioGroup: 'perfil',
        name: 'perfil',
    },
    //Método de acceso
    metodoAcceso: {
        idGrid: 'gridMetodoAcceso',
        id: 'metodoAcceso',
        name: 'metodoAcceso',
    },
    //Buttons
    buttons: {
        certificadoDigital: {
            id: 3,
            name: 'certificadoDigital',
            type: 'submit',
            code: 'cd',
            codigoOV3: 'certificado',
            codigoPCT3: 'Certificado'
        },
        entradaClave: {
            id: 5,
            name: 'entradaClave',
            type: 'sumbit',
            code: 'cl',
            codigoOV3: 'clave',
            codigoPCT3: 'CLAVE'
        },
        entradaRegistrado: {
            id: 7,
            name: 'entradaRegistrado',
            type: 'sumbit',
            code: 'ur',
            codigoOV3: 'registrado',
            codigoPCT3: 'Registrado'
        },
        entradaSinCertificado: {
            id: 4,
            name: 'entradaSinCertificado',
            type: 'sumbit',
            code: 'sc',
            codigoOV3: 'sinCertificado',
            codigoPCT3: 'Anonimo'
        },
        saltoABandeja: {
            id: 6,
            name: 'saltoABandeja',
            type: 'sumbit',
            code: 'sb',
        },
    },
};
/**
 * @constant objTextSeleccionConvocatoriaPerfil
 * Objeto que contiene un objeto con los textos de la página.
 */
export const objTextSeleccionConvocatoriaPerfil = {
    titulos: {
        tituloPagina: 'Accede a ',
        primeraSeccion: 'Versión',
        segundaSeccion: 'Perfil',
        terceraSeccion: 'Método de acceso',
    },
    parrafo: 'Para acceder selecciona una versión, un perfil    y un método de acceso.',
    placeholder: {
        convocatoria: 'Selecciona la versión',
    },
    noOptionsText: {
        convocatoria: 'No hay opciones disponibles',
    },
    metodoAcceso: {
        titulos: {
            certificadoDigital: ' Certificado digital',
            entradaConClave: 'Entrada con Cl@ve',
            entradaRegistrado: 'Entrada con registro',
            entradaSinCertificado: 'Entrada sin certificado',
            accesoABandeja: 'Entrada a la bandeja',
        },
        parrafo: 'Presente de forma online su solicitud.',
        parrafoSinCertificado: 'Presente de forma presencial descargando su solicitud.',
        parrafoRegistrado: 'Acceda a la solicitud mediante usuario y contraseña.',
        parrafoUsuarioLogeado: 'Acceda directamente a la bandeja (login ya realizado)',
    },
    buttons: 'ACCEDER',
};
/**
 * @constant objConvocatorias
 * Objeto de pruebas para alimentar el selector temporalmente hasta que se alimente con el endPoint
 */
export const objConvocatorias = [
    { id: 'convocatoria1', name: 'Primera convocatoria' },
    { id: 'convocatoria2', name: 'Segunda convocatoria' },
    { id: 'convocatoria3', name: 'Tercera convocatoria' },
];

//Objeto JSON de prueba para alimentar las opciones del componenteRadioButton
export const objOpcRadio = [
    { id: 1, descripcion: 'Interesado' },
    { id: 2, descripcion: 'Representante legal' },
];

