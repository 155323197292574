import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { backgroundRectangle, estilosTextosPrincipales, colores, buttonPrimarySmall, buttonSecundaryBig, tipografia } from '../../../AppStyle';

const useStyles = (isPhoneVersion) => {

    const style = makeStyles({
        backgroundRectangle: {
            ...backgroundRectangle,
            marginBottom: '0px',
            paddingLeft: '2%',
        },
        tituloPagina: {
            ...estilosTextosPrincipales.titulosPagina,
        },
        hclassText2: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            fontSize: '15px',
            paddingTop: '2%'
        },
        gridBoton: {
            width: '100%',
            paddingRight: '5%',
            ...(isPhoneVersion) && { paddingLeft: '5%', },
            margin: 0,
        },
        gridParrafoSubsanacion: {
            width: '100%',
            paddingTop: '2%'
        },
        botonVolver: {
            ...buttonPrimarySmall,
            margin: '1% 0.5% 1% 1%',
            ...(isPhoneVersion) && { width: '100%', },
        },
        botonAltaInteresado: {
            ...buttonPrimarySmall,
            margin: '1% 0.5% 1% 1%',
            ...(isPhoneVersion) && { width: '100%', },
            margin: '2.5%'
        },
        gridSubirArchivo: {
            ...(isPhoneVersion) ? { padding: '5%' } : { padding: '2% 0' }
        },
        botonFirmarAnexos: {
            ...buttonSecundaryBig,
            margin: '1% 0% 1% 1%',
            ...(isPhoneVersion) && { width: '100%', },
        },
        classBackdrop: {
            zIndex: 1,
            color: colores.principales.blanco,
        },
        gridTitulo: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '2%',
            marginLeft: '5%',
            width: '90%'
        },
        idExpediente: {
            marginLeft: '25px',
            color: colores.escalaGrises.sesenta,
            fontFamily: tipografia.bold,
            fontSize: '13px',
            height: 'inherit',
            display: 'flex',
            alignItems: 'center'
        },
        componente: {
           ...(isPhoneVersion)
            ? { display: 'contents' }
            : { width: '15%',
                display: 'inline-table',
                marginTop: '1%',
                marginRight: '5%',
                marginBottom: '1%',
                border: `1px solid ${colores.principales.principal}`,
                padding: '1%'}
        },
        gridAltaEntidad: {
            margin: '5% 0%',
            display: 'flex',
            padding: '5%',
            borderColor: colores.principales.principal,
            width: '328px',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        icon: {
            width: '50px',
            border: '2px solid',
            borderRadius: '50%',
            padding: '1%',
            margin: '2.5%'
        },
        parrafoSubsanacion: {
            ...estilosTextosPrincipales.body1
        }
    });

    const theme = createMuiTheme({
        overrides: {
            MuiDropzoneArea: {
                text: {
                    marginTop: '2%',
                    marginBottom: '0%',
                },
                root: {
                    width: '100%',
                    marginTop: '5%',
                    borderColor: colores.principales.principal,
                    minHeight: '0px'
                }
            },
        }
    });

    return { style, theme }
}

export default useStyles;