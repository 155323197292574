import { makeStyles, createMuiTheme } from '@material-ui/core';
//Importación de estilos
import { colores, tipografia, estilosTextosPrincipales, buttonPrimaryBig } from '../../../AppStyle';

/**************************************************************************
* Clases de estilos para aplicar en los diferentes elementos del componente
**************************************************************************/
export default makeStyles({
    classContainer: {
        width: '120px',
        height: '72px',
        textAlign: 'center',
    },
    classIniciales: {
        fontFamily: tipografia.bold,
        fontSize: '24px',
        color: colores.escalaGrises.cero,
    },
    classLabelUserName: {
        ...estilosTextosPrincipales.encabezadoH3,
        fontFamily: tipografia.medium,
        color: colores.principales.principal,
        overflow: 'hidden',
        margin: '20.27px auto 16px 24px',
        display: 'block'
    },
    classLabelMetodosAcceso: {
        ...estilosTextosPrincipales.encabezadoH3,
        fontFamily: tipografia.medium,
        color: colores.escalaGrises.cien,
        overflow: 'hidden',
        margin: '20.27px 30px 16px 24px',
        display: 'block',
        pointerEvents: 'none',
    },
    classMenuItem: {
        ...estilosTextosPrincipales.encabezadoH4,
        lineHeight: '16px',
        color: colores.escalaGrises.noventaYcinco,
        marginLeft: '24px',
        '&:hover': {
            backgroundColor: colores.escalaGrises.quince,
        },

    },
    classButton: {
        ...buttonPrimaryBig
    },
    botonCabecera:{
        ...buttonPrimaryBig,
        '&:focus':{
            backgroundColor: colores.principales.hover,
            outline: 'none'
        }      
    },
    classIcons:{
        height: '40px', 
        width: '40px',
    },
    classBotonCabeceraResponsive:{
        ...buttonPrimaryBig,
        display:'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    classMenu:{
        marginTop: '65px',
    },
    classMenuItem:{
        width: '100%',
        '&:hover': {
            backgroundColor: colores.escalaGrises.quince,
        },
    },
    ///////////////////////////////BACKDROP
    classBackdrop: {
        zIndex: 1,
        color: colores.principales.blanco,
    },

    /************************VERSIÓN MÓVIL**********************/
    classContainerPhone: {
        width: 'auto',
        heigth: 'auto',
        backgroundColor: colores.escalaGrises.diez,
    },
    classGridPhone: {
        display: 'inline-grid',
    },
    classH3Phone: {
        ...estilosTextosPrincipales.encabezadoH4,
        color: colores.escalaGrises.cien,
        fontFamily: tipografia.regular,
        textAlign: 'left',
        marginTop: '16.74px',
    },
    classH1Phone: {
        fontFamily: tipografia.medium,
        fontSize: '18px',
        lineHeight: '24px',
        color: colores.principales.principal,
        textAlign: 'left',
        margin: '13.81px 0px 19px 0px',
    },
    classOpcButtonsPhone: {
        ...estilosTextosPrincipales.encabezadoH4,
        fontFamily: tipografia.medium + '!important',
        lineHeight: '16px',
        color: 'colores.escalaGrises.noventaYcinco',
        backgroundColor: colores.escalaGrises.veinte,
        height: '56px',
        width: '343px',
        marginBottom: '8px',
    },
    classButtonPhone: {
        backgroundColor: colores.principales.principal,
        color: colores.escalaGrises.cero,
        fontFamily: tipografia.bold,
        fontSize: '12px',
        lineHeight: '16px',
        margin: '15px 0px 15px 0px',
        borderRadius: '4px',
        border: '2px',
        borderStyle: 'solid',
        borderColor: colores.principales.verde,
        height: '56px',
        width: '343px',
    },
    classUltimaConexionPhone: {
        fontFamily: tipografia.regular,
        fontSize: '12px',
        lineHeight: '17px',
        color: colores.escalaGrises.ochenta,
        marginBottom: '16px',
    },
});

/**
 * @constant overMenu contiene las clases de material UI que serán sobreescritas con las propiedades definidas
 * y posteriormente aplicadas sobre el componente.
 */
export const overMenu = createMuiTheme({
    overrides: {
        MuiPaper: {
            root: {
                width: '100%',
            },
        },
    },
});

export const overMenuPhone = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
            },
            label: {
                fontFamily: tipografia.medium,
            },
        },
    },
});