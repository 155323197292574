import { makeStyles } from '@material-ui/core/styles';
import { backgroundRectangle, estilosTextosPrincipales, colores, tipografia, buttonPrimarySmall } from '../../../AppStyle';
import { createMuiTheme } from '@material-ui/core';


const customStyle = makeStyles({
    pageTitle: {
        ...estilosTextosPrincipales.titulosPagina,
    },
    gridPageTitle: {
        marginLeft: '5%',
        marginTop: '2%',
        alignItems: 'center',
        display: 'flex'
    },
    backgroundRectangle: {
        ...backgroundRectangle,
        marginBottom: '0px'
    },
    gridBoton: {
        width: '100%',
        paddingRight: '5%',
        margin: 0,
    },
    botonVolver: {
        ...buttonPrimarySmall,
        margin: '1% 0.5% 1% 1%',
    },
    hclassText: {
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',
        paddingTop: '2%',
        paddingLeft: '2%',
    },
    classDivRect: {
        marginLeft: '5%',
    },
    classBackdrop: {
        position: 'absolute',
        zIndex: 1000,
        color: `${colores.principales.principal}`,
        backgroundColor: 'rgba(211, 211, 211, 0.5)',
    },
    contenedorTabla: {
        position:'relative'
    },
    msgDocHelp: {
        padding: '10px',
    },
    gridTitulo: {
        display: 'flex',
        flexDirection: 'row',
    },
    idExpediente: {
        marginLeft: '25px',
        color: colores.escalaGrises.sesenta,
        fontFamily: tipografia.bold,
        fontSize: '13px',
        height: 'inherit',
        display: 'flex',
        alignItems: 'center'
    },
    backgroundRectangle: {
        ...backgroundRectangle
    },
    pageTitle: {
        ...estilosTextosPrincipales.titulosPagina,
    },
    gridPageTitle: {
        marginLeft: '5%',
        marginTop: '2%'
    },
    gridSearchBar: {
        marginRight: '5%',
        marginLeft: '5%',
        marginTop: '2%',
        alignSelf: 'center'
    },
    gridTablePager: {
        marginTop: '0.5%',
        marginRight: '4%',
        width: '85%',
    },
    gridTableResponsivePager: {
        marginTop: '0.5%',
        width: '90%',
    },
    gridTable: {
        marginBotton: '0.5%'
    },
    classLine1: {
        boxSizing: 'borderBox',
        height: '1px',
        width: '90%',
        border: '1px',
        borderStyle: 'solid',
        borderColor: 'red',
        marginTop: '13px',
        marginBottom: '23px'
    },

    searchAutocomplete: {
        width: 'fit-content',
        height: '48px',
    },
    searchTextField: {
        width: '380px',
        height: '48px',
        margin: '0px',

    },
    searchButton: {
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
        borderRadius: '0',
        height: '48px',
        width: '48px',
        backgroundColor: colores.principales.principal, 
        '&:hover': {
            backgroundColor: colores.principales.hover, 
        }
    },
    searchImg: {
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
        height: '48px',
        width: '48px',
    },
    /***********PHONE VERSION***********/

    searchTextFieldPhone: {
        width: '288px',
        height: '40px',
        margin: '0px',
    },
    searchButtonPhone: {
        height: '40px',
        width: '40px',
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
    },
    searchImgPhone: {
        height: '40px',
        width: '40px',
    },
    classGridPdfWeb: {
        width: '100%',
        marginLeft: '1%',
        marginRight: '1%',
    },
    classBackdropFullScreen: {
        zIndex: 1,
        color: colores.principales.blanco,
    },

});

export default customStyle;

export function overrideStyleF(isPhoneVersion) {
    const overrideStyle = createMuiTheme({
        overrides: {
            MuiOutlinedInput: {
                root: {
                    height: isPhoneVersion ? '40px' : '48px',
                    borderBottomRightRadius: '0px',
                    borderTopRightRadius: '0px',
                    border: `1px solid ${colores.principales.principal}`,
                    '&:hover': {
                        border: `2px solid ${colores.principales.principal}`,
                    },
                    '&.Mui-focused': {
                        border: `2px solid ${colores.principales.principal}`,
                    }
                },
                notchedOutline: {
                    borderColor: colores.principales.principal,
                },
            },
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        color: colores.escalaGrises.cien,
                    },
                },
            },
        }
    });

    return overrideStyle;
}