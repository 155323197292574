import React, {useState, useContext, useEffect}from 'react';
import {colores} from './../../../AppStyle';
import RestProvider from 'providers/RestProvider';
import { properties, texts, msgError, constantesEstadosFasePCT3 } from './AporteDocumentacionData';
import {localStorageName} from '../../../providers/localStorageData';
import {loadState} from '../../../providers/localStorage';
import AppContext from 'AppContext';
import {properties as appProperties} from 'AppData';
import { useHistory } from "react-router-dom";
import useApp from 'AppFunctions';

const useFunctions = () => {

    //Imports

    const {crudOperation, crudOperationsType, obtenerProperties} = RestProvider();
    const context = useContext(AppContext);
    const history = useHistory();

    const PREFIJO_OBJETO_FILE_PDF = 'data:application/pdf;base64,';

    const { obtenerCodigosFaseEstado } = useApp();

    ///Hooks gestion msg errores
    let idAlert = 0;
    const [message, setMessage] = useState({ msg: '', severity: '' });
    const addAlert = msg => setAlerts([...alerts, { id: idAlert++, msg }]);
    const [alerts, setAlerts] = useState([]);
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    const proActual = loadState(localStorageName.procedimientoActual, true);
    const convActual = loadState(localStorageName.convocatoriaSeleccionada, true);

    /**
     * @constant objectButtons Objeto que contiene el valor inicial del hook de estado isVisble.
     * Si se incorporan nuevos botones, habrá que insertar su clave (id) y valor a false. La clave con valor true será el botón seleccionado por defecto.
     */
     const objectButtons = {
        btnObligatorios: true
    }

    // Hooks
    const [infoAnexos, setInfoAnexos] = useState();
    const [listo, setListo] = useState(false);
    const [tituloPagina, setTituloPagina] = useState();
    const [documentos] = useState([]);
    const [datosTabla, setDatosTabla] = useState([]);
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    const [isVisible, setIsvisible] = useState(objectButtons); //Contiene un objeto con todos los botones y su estado booleano
    const [numDocumentos, setNumDocumentos] = useState(0);
    const [tamDocumentos, setTamDocumentos] = useState(0);
    const [mostrarPDF, setMostrarPDF] = useState(false);
    const [pdf, setPdf] = useState(null);
    const [documentosVoluntarios, setDocumentosVoluntarios] = useState([]);
    const [numInput, setNumInput] = useState(0);
    const [numAnexosObligatoriosEntregados, setNumAnexosObligatoriosEntregados] = useState(0);
    const [disabledListo, setDisabledListo] = useState(true);
    const [values, setValues] = useState("");    
    const [isLoading, setIsLoading] = useState(true);
    const [tamMaximoSobrepasado, setTamMaximoSobrepasado] = useState(false);
    const [errorDropRejected, setErrorDropRejected] = useState(false);
    const [tamDropRejected, setTamDropRejected] = useState("");
    const [existenAnexosObligatrios, setExistenAnexosObligatrios] = useState(true);
    const [existenAnexosOptativos, setExistenAnexosOptativos] = useState(true);
    const [nombrePdfDescargar, setNombrePdfDescargar] = useState("");
    const [mostrarConfirmacionGuardado, setMostrarConfirmacionGuardado] = useState(false);
    const [convocatoriasColaborativasCodigo, setConvocatoriasColaborativasCodigo] = useState(undefined);

    let arrayVoluntariosTmp;

    let perfil = loadState(localStorageName.perfilSeleccionado, true);
    let convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);

    const addProperties = async () => {      
        const propertiesList = [
            'convocatorias.colaborativas.codigo'
        ];

        try {
            let properties = await obtenerProperties(propertiesList);    
            // Actualiza el estado
            setConvocatoriasColaborativasCodigo(properties['convocatorias.colaborativas.codigo'])
        } catch (error) {
            console.error('error obteniendo las properties', error);
        }
    };

    
    useEffect(() => {
        addProperties();
    }, []);

    useEffect(() => {
        arrayVoluntariosTmp = documentosVoluntarios;
    }, [documentosVoluntarios]) ;

    /**
     * @function styleBotonClickado Función que aplica estilo sobre el botón clickado
     * @param {*} botonClickado Valor booleano para saber si se ha clickado o no el botón que corresponde con su id
     * @param {*} idBoton ID del botón que se ha clickado
     */
     const styleBotonClickado = (botonClickado, idBoton) => {
        let backgroundPrimary = colores.principales.fondoEspecifico;
        let colorPrimary = colores.principales.principal;
        let borderColorPrimary = colores.principales.principal;

        let backgroundSecundary = colores.escalaGrises.cero;
        let colorSecundary = colores.escalaGrises.cien;
        let borderColorSecundary = colores.escalaGrises.apoyo;

        //Paso 1. Se aplican los estilos al backgroundColor, color y borderColor
        cambiarEstiloComponente(botonClickado, 'background-color', idBoton, backgroundPrimary, backgroundSecundary);
        cambiarEstiloComponente(botonClickado, 'color', idBoton, colorPrimary, colorSecundary);
        cambiarEstiloComponente(botonClickado, 'border-color', idBoton, borderColorPrimary, borderColorSecundary);
        //Paso 2. Se pone el foco sobre el botón clickado
        document.querySelector(`#${idBoton}`).focus();
    }

    /**
     * @function cambiarEstiloComponente Función auxiliar para cambiar el estilo de un componente si este es clickado
     * @param {*} botonClickado Valor booleano para saber si se ha clickado o no el botón que corresponde con su id
     * @param {*} estilo El estilo que se va a aplicar sobre el componente
     * @param {*} idComponente ID del componente sobre el que se va a cambiar el estilo
     * @param {*} colorPrimary Color primario a aplicar
     * @param {*} colorSecundary Color secundario a aplicar
     */
     const cambiarEstiloComponente = (botonClickado, estilo, idComponente, colorPrimary, colorSecundary) => {
        let color = colorPrimary;
        //Paso 1. Se comprueba si el componente se ha clickado, en caso de que no se establecerá la variable color con otro valor
        !botonClickado && (color = colorSecundary);
        //Paso 2. Se aplica el estilo al componente obtenido a través de su ID
        document.querySelector(`#${idComponente}`).style[estilo] = color;
    }
    
    /**
     * @function botonPorDefecto Función que establece por defecto el botón seleccionado al cargar la página.
     * Se captura el botón a través de su id, se aplica el evento focus y se establece el estilo por defecto con la función styleBotonClickado.
     * @param {*} id Recibe el id del botón por parámetros
     */
     const botonPorDefecto = (id) => {
        if(id !== undefined && id !== null && id !== 'undefined' && document.querySelector(`#${id}`) !== null){
            document.querySelector(`#${id}`).focus();
            styleBotonClickado(true, id);
        }
    }

    /**
     * @function getInfoAnexos Obtiene la informacion de los anexos correspondientes
     */
    const getInfoAnexos = async () => { 
        setIsLoading(true);

        let conAct = loadState(localStorageName.convocatoriaSeleccionada, true);
        let faseEstadoExpediente = loadState(localStorageName.faseEstadoExpediente, true);

        let solicitudActual = loadState(localStorageName.solicitudActual, true);

        let input = {
            idExpediente: solicitudActual.id,
            idConvocatoria: conAct.id,
            idFase: faseEstadoExpediente.idFase,
            idEstado: faseEstadoExpediente.idEstado, 
        }
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_PCT, { url: properties.URL_PCT, method: properties.CRUD_TYPE_POST, data: input}).then(response => {
            setInfoAnexos(response.data);
            response.data !== undefined ? recuperarAnexos(response.data) : setListo(true);
            
            if(response.data.listAnexosObligatorios.length === 0){
                setExistenAnexosObligatrios(false);
                setDisabledListo(false);
            }
            if(response.data.listAnexosOptativos.length === 0){
                setExistenAnexosOptativos(false);
            }
        }).catch(function (error) {
            if (error.response.status !== 404) {
                handleSetMessage(texts.ERROR, properties.SEVERITY);
            } else {
                setDisabledListo(false);
                setListo(true);
            }
            setIsLoading(false);
        });
    }

    const handleOnClickListo = async () =>{
        setIsLoading(true);
        if (listo) {
            history.push(properties.URL_FIRMA_SOLICITUD);
        } else {
            setListo(true); 
            generarDatosTabla();
        }
        setIsLoading(false);
    }

    /**
     * @function obtenerTituloPagina obtiene el procedimiento en el que se encuentra para actualizar el
     * titulo de la pagina
     */
    const obtenerTituloPagina = async () =>{
        let primeraParte;
        let procedimientoActual = loadState(localStorageName.procedimientoActual, true);
        let fase = loadState(localStorageName.fase, false);
        let accionModificar = loadState(localStorageName.accionModificar, false);
        let constantesObtenidas = await obtenerFasesEstados();

        let textNuevaSolicitud = accionModificar ? texts.PAGE_TITLE_MOD : texts.PAGE_TITLE;
        let textSubsanacion = accionModificar ? texts.PAGE_TITLE_SUBSANACION_MOD : texts.PAGE_TITLE_SUBSANACION;
        let textAlegacion = accionModificar ? texts.PAGE_TITLE_ALEGACION_MOD : texts.PAGE_TITLE_ALEGACION;
        let textJustifiacion = accionModificar ? texts.PAGE_TITLE_JUSTIFICACION_MOD : texts.PAGE_TITLE_JUSTIFICACION;

        switch(fase){
          case constantesObtenidas.F_INICIO_OV3:
            primeraParte = textNuevaSolicitud;
            break;
          case properties.FASE_NUEVA_SOLICITUD:
            primeraParte = textNuevaSolicitud;
            break;  
          case constantesObtenidas.F_SUBSANACION_OV3:
            primeraParte = textSubsanacion;
            break;
          case properties.FASE_PENDIENTE_DE_SUBSANAR:
            primeraParte = textSubsanacion;
            break;
          case constantesObtenidas.F_ALEGACIONES_OV3:
            primeraParte = textAlegacion;
            break;
          case properties.FASE_PRESENTACION_ALEGACIONES:
            primeraParte = textAlegacion;
            break;
          case properties.FASE_PENDIENTE_JUSTIFICACION:
            primeraParte = textJustifiacion;
            break;
          case constantesObtenidas.F_JUSTIFICACION_OV3:
            primeraParte = textJustifiacion;
            break;
          default:
            primeraParte = texts.PAGE_TITLE;
            break;
        }

        setTituloPagina(primeraParte + ((procedimientoActual.descripcion === undefined) ? procedimientoActual.nombre : procedimientoActual.descripcion));
        context.updateTitlePage(primeraParte + ((procedimientoActual.descripcion === undefined) ? procedimientoActual.nombre : procedimientoActual.descripcion));
    }
    

    /**
     * @function handleOnDrop Gestion de la subida de documentos, se almacenan junto con el anexo al que pertenecen
     * @param {*} files Fichero subido por el usuario
     * @param {*} rejectedFiles Fichero rechazado subido por el usuario
     * @param {*} anexoDto Anexo asociado al fichero
     */
    const handleOnDrop = (files, rejectedFiles, anexoDto, id) => {
        //Se obtiene el tamaño del fichero subido, se convierte a mb, se obtienen dos decimales (toFixed) y se pasa a float  (ya que to fixed devuelve un String).
        let tamFicheroSubido = parseFloat((files[0].file.size / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES));
        //Se suma el tamaño del fichero subido al tamaño total de documentos
        
        let tamTotal = tamDocumentos + tamFicheroSubido;

        let solAct = loadState(localStorageName.solicitudActual, true);

        let proAct = loadState(localStorageName.procedimientoActual, true);

        if (infoAnexos.tamMaximoTotal < tamTotal){
            setTamMaximoSobrepasado(true);
        } else {
            setTamDocumentos(tamTotal);

            // Anexos voluntarios
            if (anexoDto === null) {
                let documentoV = {
                    fichero: files[0],
                    anexo: null,
                    descripcionVoluntario: documentosVoluntarios[numInput].value,
                    firmado: false,
                    nombreFichero: {...files[0].file},
                    idSolicitud: solAct.id,
                    idConvocatoria: convActual.id,
                    idProcedimiento: proActual.id,
                    extensionFormato: texts.PDF,
                    tamnyoFichero: tamFicheroSubido * 1000, //<---- Hay que pasarlo en kbytes para guardar el tamaño en el mismo formato qe los demas
                }
                documentoV.fichero.data = files[0].data.split('base64,')[1];

                guardarAnexoPCT(documentoV , true);
                tratamienoAnexoVoluntario(files[0].file.size);

            } else {
                handleDropNoVoluntarios(files, anexoDto, id, tamFicheroSubido);
            }
            

        }
        
    }

    /**
     * Gestion de la subida de documentos de tipo no voluntario
     * @param {*} files 
     * @param {*} anexoDto 
     * @param {*} id 
     * @param {*} tamFicheroSubido
     */
    const handleDropNoVoluntarios = (files, anexoDto, id, tamFicheroSubido) => {
        let numDocObligatoriosEntregados = numAnexosObligatoriosEntregados;
        for (const anexoObligatorio of infoAnexos.listAnexosObligatorios) {
            if(anexoObligatorio.nombreAnexo === id) {
                anexoObligatorio.mostrarEliminar = true;
                ++numDocObligatoriosEntregados;
                setNumAnexosObligatoriosEntregados(numDocObligatoriosEntregados);
                anexoObligatorio[properties.TAM_DOC_SUB] = tamFicheroSubido;
            }
        }

        if (numDocObligatoriosEntregados === infoAnexos.listAnexosObligatorios.length) {
            setDisabledListo(false);
        }

        for (const anexoOptativos of infoAnexos.listAnexosOptativos) {
            if(anexoOptativos.nombreAnexo === id){
                anexoOptativos.mostrarEliminar = true;
                anexoOptativos[properties.TAM_DOC_SUB] = tamFicheroSubido;
            }
        }

        let solAct = loadState(localStorageName.solicitudActual, true);

        let documento = {
            fichero: files[0],
            anexo: anexoDto,
            descripcionVoluntario: null,
            firmado: false,
            nombreFichero: {...files[0].file},
            idSolicitud: solAct.idMoaddSolicitudPdf,
            idConvocatoria: convActual.id,
            idProcedimiento: proActual.id,
            extensionFormato: texts.PDF,
            tamnyoFichero: tamFicheroSubido* 1000, //<---- Hay que pasarlo en kbytes para guardar el tamaño en el mismo formato qe los demas
        }

        documento.fichero.data = files[0].data.split('base64,')[1];
        Object.assign(documento, {idConfAnexo: anexoDto.id});
        guardarAnexoPCT(documento, false);
        setNumDocumentos(numDocumentos + 1);
    }

    /**
     * @function guardarAnexoPCT 
     */
     const guardarAnexoPCT = async (documento, isVoluntario) => {
         let faseEstado = loadState(localStorageName.faseEstadoExpediente, true);
         let expediente = loadState(localStorageName.solicitudActual, true);
         let organismo = loadState(localStorageName.organismo, true);
         let DocumentoDto = null;

         let tipoDocVoluntario;
         if(isVoluntario){
             tipoDocVoluntario = await recuperarTipoDocVol();
         }
            if (organismo !== undefined && organismo !== null && organismo !== '') {
                DocumentoDto = {
                    nombre: isVoluntario ? documento.descripcionVoluntario : documento.fichero.file.path,
                    fichero: documento.fichero.data,
                    descripcion: isVoluntario ? documento.descripcionVoluntario : documento.anexo.descripcionAnexo,
                    estadoElaboracion: 'EE_03',
                    fechaOrve: null, //<----No hace falta
                    idEstado: faseEstado.idEstado,
                    idFase: faseEstado.idFase,
                    idExpediente: expediente.id,
                    nivelAcceso: 'Libre',
                    numeroRegistroEntrada: null, //<----¿?
                    tipoCreador: 'ciudadano', 
                    idConfAnexo: isVoluntario ? null : documento.idConfAnexo, //ATENTO CON LA CONFIGURACION QUE NOS DICE EL TIPO
                    organismoDto: organismo,
                    datoExpedienteDto: {
                        organismo: {
                            idOrganicoDir3: organismo.idOrganicoDir3,
                        }, 
                    },
                    tipoDocumento: {
                        id: isVoluntario ? tipoDocVoluntario.id : documento.anexo.tipoDocumento.id,
                        nombre: isVoluntario ? tipoDocVoluntario.nombre : documento.anexo.tipoDocumento.nombre,
                        codigo: isVoluntario ? tipoDocVoluntario.codigo : documento.anexo.tipoDocumento.codigo,
                        descripcion: isVoluntario ? tipoDocVoluntario.descripcion : documento.anexo.tipoDocumento.descripcion,
                        origen: isVoluntario ? tipoDocVoluntario.origen : documento.anexo.tipoDocumento.origen,
                        tipoDocumentoEni: {
                            id: isVoluntario ? tipoDocVoluntario.tipoDocumentoEni.id : documento.anexo.tipoDocumento.tipoDocumentoEni.id,
                            codigo: isVoluntario ? tipoDocVoluntario.tipoDocumentoEni.codigo : documento.anexo.tipoDocumento.tipoDocumentoEni.codigo,
                            descripcionCorta: isVoluntario ? tipoDocVoluntario.tipoDocumentoEni.descripcionCorta : documento.anexo.tipoDocumento.tipoDocumentoEni.descripcionCorta,
                        },
                        firmable: true,
                        firmableSello: false,
                        registrable: true,
                        firmaServidor: true,
                        aliasCertificado:isVoluntario ? tipoDocVoluntario.aliasCertificado : documento.anexo.tipoDocumento.aliasCertificado,
                    }, //<---- Tipo de documento
                }
                setIsLoading(true);
                crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ANEXO_PCT, {  method: properties.CRUD_TYPE_POST, data: DocumentoDto }).then(response => {
                    Object.assign(documento, {idPCT: response.data.id});
                    documentos.push(documento);
                    setIsLoading(false);
                });
            } else {
                handleSetMessage('Se ha producido un error al obtener los organismos, contacte con el adminsitrador', 'error');
            }
    }
    

    /**
     * @function generarDatosTabla Monta los objetos necesarios para la tabla a partir de la informacion
     * de los documentos subidos y los anexos a los que pertenecen
     */
    const generarDatosTabla = () => {
        documentos.forEach(documento => {
            let tipo, nombre, identificador;
            if(documento.anexo===null || documento.anexo.length===0){
                nombre = documento.descripcionVoluntario;
                identificador = documento.descripcionVoluntario; 
                tipo = properties.TIPO_VOLUNTARIO;
            }else{
                nombre = documento.anexo.descripcionAnexo
                identificador = documento.anexo.nombreAnexo

                let tipoTmp = documento.anexo.obligatorioAnexo
                if(tipoTmp!=null && tipoTmp.length>0 ){
                    if(tipoTmp.toUpperCase() === properties.NO){
                        tipo = properties.TIPO_OPTATIVO;
                    }else{
                        tipo = properties.TIPO_OBLIGATORIO
                    }
                }
            }
            guardarFila(identificador, nombre, tipo)
        });
        arrayVoluntariosTmp = documentosVoluntarios;
    }

    /**
     * @function guardarFila crea y añade a la tabla una nueva fila
     * @param {*} identificador del anexo
     * @param {*} nombre del anexo
     * @param {*} tipo de anexo, obligatorio, optativo o voluntario
     */
    const guardarFila = (identificador, nombre, tipo) => {
        let convocatoriasColaborativas = convocatoriasColaborativasCodigo;
        let convocatoriasObtenidas = convocatoriasColaborativas !== undefined ? convocatoriasColaborativas.split(', ') : null;           
        let fila;

        let accion = loadState(localStorageName.accion, false);

        if (convocatoriasObtenidas !==  null && convocatoriasObtenidas.includes(convocatoria.codigo) && perfil.descripcion === 'REPRESENTANTE_SICA' && (accion === 'revisarPresentacion' || accion === 'revisarSubsanacion')) {
            fila = {
                identificador: identificador,
                nombre: nombre,
                estado: properties.ESTADO_ANEXO,
                tipo: tipo,
                acciones: [
                    {
                        img: properties.RUTA_IMG_LUPA,
                        funcion: visualizarAnexoDesdeTabla,
                        titulo: properties.TITULO_LUPA,
                    }
                ]
            }
        }else{
            fila = {
                identificador: identificador,
                nombre: nombre,
                estado: properties.ESTADO_ANEXO,
                tipo: tipo,
                acciones: [
                    {
                        img: properties.RUTA_IMG_BORRAR,
                        funcion: deleteAnexoDesdeTabla,
                        titulo: properties.TITULO_ELIMINAR,
                    },
                    {
                        img: properties.RUTA_IMG_LUPA,
                        funcion: visualizarAnexoDesdeTabla,
                        titulo: properties.TITULO_LUPA,
                    }
                ]
            }
        }

        datosTabla.push(fila);
    }

    /**
     * @function updateFormat Segun el num de pixeles del ancho de la pantalla pone version movil o version ordenador
     */
    const updateFormat = () => {
        if(window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    }

    /**
     * @function deleteAnexoDesdeTabla boton borrar de la tabla de visualizacion de documentos subidos,
     * elimina el documento, la fila de la tabla y el objeto que gestiona en el caso de ser voluntario
     * que coincida con el identificador
     * @param {*} identificador campo por el que se elimina
     */
    const deleteAnexoDesdeTabla = (e, identificador) => {
        //Borrar de tabla
        let i = 0;
        let encontradoTabla = false;
        while(i< datosTabla.length && !encontradoTabla){
            if ( datosTabla[i].identificador === identificador ) { 
                datosTabla.splice(i, 1); 
                encontradoTabla = true;
            } 
            i++;
        }

        let voluntario = borrarDocumentoPorIdentificador(identificador);
        if(voluntario){
            borrarAnexoVoluntarioPorIdentificador(identificador);
        }
    }

    /**
     * @function borrarDocumentoPorIdentificador elimina de la coleccion de
     * documentos el que coincida con el identificador y actualiza todos los
     * campos relacionados con el borrado del documento
     * @param {*} identificador campo por el que se borra el documento
     */
    const borrarDocumentoPorIdentificador = (identificador) => {
        let i = 0;
        let voluntario = false;
        let encontrado = false;
        let solAct = loadState(localStorageName.solicitudActual, true);

        while(i< documentos.length && !encontrado){
            let documento = documentos[i];

            if ((documento.anexo !==null && documento.anexo.nombreAnexo === identificador) 
                || documento.descripcionVoluntario === identificador) { 
                
                let convert = parseFloat((documento.fichero.file.size / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES));
                let tamDocu = parseFloat((tamDocumentos - convert).toFixed(properties.NUMERO_DECIMALES));
                setTamDocumentos(tamDocu);

                if(documento.anexo !== null){
                    setNumDocumentos(calcularNumDocNoVoluntarios() -1);
                }else{
                    voluntario = true;
                }
                
                if(documento.anexo !==null){
                    resetearAnexo(documento, identificador);
                }

                setIsLoading(true);
                crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ANEXO_PCT, {url: properties.RESOURCE_ELIMINAR_ANEXO_PCT + documentos[i].idPCT + '/expediente/' +solAct.id ,method: properties.CRUD_TYPE_GET }).then(response => {
                    setIsLoading(false);
                });

                encontrado=true;               
                documentos.splice(i, 1);            
            }  
            i++;
        }
        return voluntario;
    }

    /**
     * Resetea el aspecto visual de los anexos obligatorios y optativos
     * en la sección de añadir anexo para mostras de nuevo el dropzone y 
     * quitar el boton eliminar
     * @param {*} documento documento en cuestion
     * @param {*} identificador parametro por el que se busca el documento
     */
    const resetearAnexo  = (documento, identificador) => {
        let i = 0;
        let j = 0;
        let anexoReseteado = false;

        if(documento.anexo.obligatorioAnexo.toUpperCase() === properties.NO){
            while(j < infoAnexos.listAnexosOptativos.length && !anexoReseteado){
                if(infoAnexos.listAnexosOptativos[j].nombreAnexo === identificador){
                    infoAnexos.listAnexosOptativos[j].mostrarEliminar = false;
                    anexoReseteado = true;
                }
                j++;
            }
        } else {
            while(i< infoAnexos.listAnexosObligatorios.length && !anexoReseteado) {
                if(infoAnexos.listAnexosObligatorios[i].nombreAnexo === identificador){
                    infoAnexos.listAnexosObligatorios[i].mostrarEliminar = false;
                    setNumAnexosObligatoriosEntregados(calcularNumDocObligatorios() - 1)
                    anexoReseteado = true;
                    setDisabledListo(true);
                }
                i++;
            }
        }
    }

    /**
     * @function borrarAnexoVoluntarioPorIdentificador borrado del anexo voluntario del array que gestiona
     * los anexos voluntarios por el identificador
     * @param {*} identificador campo por el que se borra el anexo
     */
    const borrarAnexoVoluntarioPorIdentificador = (identificador) => {
        let x = 0;
        let encontradoVoluntario = false;

        if(arrayVoluntariosTmp){
            while(x<arrayVoluntariosTmp.length && !encontradoVoluntario){
                if(identificador === arrayVoluntariosTmp[x].value) {
                    setNumInput(calcularNumeroInputs() -1)
                    arrayVoluntariosTmp.splice(x, 1);
                    setDocumentosVoluntarios(arrayVoluntariosTmp);
                    encontradoVoluntario=true;
                }
                x++;
            }
        }  
    }

    /**
     * TMP: Los hooks no se actualizan correctamente
     * @function calcularNumDocNoVoluntarios
     * @returns numDocNoVol numero de documentos no volunatrios subidos actualmente
     */
    const calcularNumDocNoVoluntarios = () =>{
        let numDocNoVol = 0;
        for(let doc of documentos){
            if(doc.anexo !== null){
                numDocNoVol++;
            }
        }
        return numDocNoVol;
    }

    /**
     * TMP: Los hooks no se actualizan correctamente
     * @function calcularNumDocObligatorios
     * @returns numDocObl numero de documentos obligatorios subidos actualmente
     */
    const calcularNumDocObligatorios = () =>{
        let numDocObl = 0;
        for(let doc of documentos){
            if(doc.anexo !== null){
                if(doc.anexo.obligatorioAnexo.toUpperCase() !== properties.NO ){
                    numDocObl++;
                }  
            }
        }
        return numDocObl;
    }

    /**
     * TMP: Los hooks no se actualizan correctamente
     * @function calcularTamDocumentos
     * @returns tamDocs calcula el peso total de los archivos subidos actualmente
     */
    const calcularTamDocumentos = () => {
        let tamDocs = 0;
        for(let doc of documentos){
            tamDocs = tamDocs + parseFloat((doc.fichero.file.size / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES));  
        }
        return tamDocs;
    }

    /**
     * TMP: Los hooks no se actualizan correctamente
     * @function calcularNumeroInputs
     * @returns numInputs calcula el numero total de inputs actuales en
     * la seccion de subir archivos voluntarios
     */
    const calcularNumeroInputs = () => {
        let numInputs = 0;      
        if(documentosVoluntarios){
            for(let i=0; i<documentosVoluntarios.length; i++){
                numInputs=i;
            }
        }     
        return numInputs;
    }
    
    /**
     * @function buttonsState Función auxiliar utilizada para establecer el estado de los botones del menú de navegación y su estilo.
     * @param {*} idBtnClickado Recibe el id del botón que se ha clickado
     */
     const buttonsState = (idBtnClickado) => {
        // Paso 1. Se obtienem las keys del objeto objectButtons                        
        let keysObjectButtons = Object.keys(isVisible);
        //Paso 2. Se recorre el objeto keysObjectButtons
        for (const idButton of keysObjectButtons) {
            //Paso 3. Se inserta el valor false a todos las keys que no correspondan con el id del botón clickado para reinicializar los valores.
            if (idBtnClickado !== idButton) {
                isVisible[idButton] = false;
                //Paso 3.1. Se reinicializan los estilos de los botones
                styleBotonClickado(false, idButton);
            }
        }
        //Paso 4. Se inserta el valor true para la hook visible que corresponda con el botón clickado
        setIsvisible({
            ...isVisible,
            [idBtnClickado]: true
        });
        //Paso 4.1. Se inserta el estilo al botón clickado
        styleBotonClickado(true, idBtnClickado);
    }

    /**
     * @function handleChangeIsVisible Función para manejar el cambio de estado de la hook visible
     * @param {*} e Recibe el evento por parámetros
     */
     const handleChangeIsVisible = (e) => {
        //Paso 1. Se obtiene el id del botón clickado
        let idBoton = e.currentTarget.id;
        //Paso 2. Se llama a la función buttonState y se introduce por parámetros el id del botón clickado
        buttonsState(idBoton);
    } 

    /**
     * @function visualizarAnexoDesdeTabla mostrar pdf seleccionado en un pop up, si no es un pdf
     * lo descarga
     * @param {*} e evento onClick del boton
     * @param {*} identificador campo por el que identificar los anexos
     */
    const visualizarAnexoDesdeTabla = (e, identificador) =>{
        let index = 0;
        let encontrado = false;
        
        while(index<documentos.length && !encontrado){
            let documento = documentos[index];
            let idDocumento;
            if(documento.anexo===null){
                idDocumento = documento.descripcionVoluntario;
            }else{
                idDocumento = documento.anexo.nombreAnexo;      
            }

            if(idDocumento === identificador){
                let file =PREFIJO_OBJETO_FILE_PDF + documento.fichero.data;
                
                let tipo = documento.nombreFichero.path.substring(documento.nombreFichero.path.length -3, documento.nombreFichero.path.length)
        
                if(tipo === texts.PDF){
                    setNombrePdfDescargar(documento.nombreFichero.path);
                    setPdf(file);
                    setMostrarPDF(true); 
                }
                else{
                    let blob = new Blob([file], {type: "application/pdf"});
                    let link = document.createElement(properties.ELEMENTO_DESCARGA);
                    link.href = window.URL.createObjectURL(blob);
                    link.download = documento.nombreFichero.path;
                    link.click();
                }
                
                
                encontrado=true;      
            }    
            index++; 
        } 
    }

    /**
     * @function cerrarPopUpPdf Actualiza el hook que controla el pop up para visualizar pdfs y lo cierra
     */
    const cerrarPopUpPdf = () =>{
        setMostrarPDF(false);
    }

    //Metodo que controla el INPUT del componente SubidaArchivoVoluntario para mostrar el Dropzone
    const handleChange = (e) => {
        setValues(e.target.value);
        documentosVoluntarios[numInput].value = e.target.value;

        if(e.target.value === null || e.target.value === undefined || e.target.value === ""){
            documentosVoluntarios[numInput].mostrarDropZone = false;
        }else{

            if(numInput !== 0){
                for( var j = 0; j < documentosVoluntarios.length - 1; j++){ 
                    if(documentosVoluntarios[j].value === e.target.value) {
                        const arrayTemp = [...documentosVoluntarios];
                        arrayTemp[numInput].mostrarDropZone = false;
                        setDocumentosVoluntarios(arrayTemp);
                        break;
                    } else {
                        documentosVoluntarios[numInput].mostrarDropZone = true;
                    }
                 }  
            } else{
                documentosVoluntarios[numInput].mostrarDropZone = true;
            }
        }
              
    };

    //Metodo que controla el INPUT del componente SubidaArchivoVoluntario para mostrar el Dropzone
    const eliminarAnexoVoluntario = (e) => {

        const arrayTemp = [...documentosVoluntarios];
        let solAct = loadState(localStorageName.solicitudActual, true);

        for( var i = 0; i < arrayTemp.length; i++){ 
           if(e.target.name === arrayTemp[i].value) {
                let parseTamDocu = parseFloat(arrayTemp[i].tamDoc);
                let tamDocu = parseTamDocu.toFixed(properties.NUMERO_DECIMALES);
                let tamDocuTotal = parseFloat((tamDocumentos - tamDocu).toFixed(properties.NUMERO_DECIMALES));

                setTamDocumentos(tamDocuTotal);
                arrayTemp.splice(i, 1);
                setDocumentosVoluntarios(arrayTemp);
                setNumInput(numInput - 1);
                if (numAnexosObligatoriosEntregados === infoAnexos.listAnexosObligatorios.length){
                    setDisabledListo(false);
                }
                
           }
        } 
        
        for( var j = 0; j < documentos.length; j++){ 
            if(e.target.name === documentos[j].descripcionVoluntario) {
                setIsLoading(true);
                crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ANEXO_PCT, {url: properties.RESOURCE_ELIMINAR_ANEXO_PCT + documentos[j].idPCT + '/expediente/' +solAct.id ,method: properties.CRUD_TYPE_GET }).then(response => {
                    setIsLoading(false);
                });
                documentos.splice(j, 1);
            }
         }    

    };

    //Metodo que crea un nuevo registro en el array voluntarios
    const tratamienoAnexoVoluntario = (pesoDoc) => {

            documentosVoluntarios[numInput].mostrarDocAnexo = true;
            documentosVoluntarios[numInput].tamDoc = (pesoDoc / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES);    

            const arrayTemp = [...documentosVoluntarios];

            setNumInput(numInput + 1);
            let documentoVoluntario = {
                mostrarDropZone: false,
                value: "",
                mostrarDocAnexo: false,
                tamDoc: "",
            }

            arrayTemp.push(documentoVoluntario);
            setDocumentosVoluntarios(arrayTemp);  
    };

    //Metodo que crea una nueva clave valor para controlar si el usuario ha subido un anexo y mostrar el boton eliminar
    const anyadirPropiedadEliminar = () => {
        if(infoAnexos){
         
            for (const anexoObligatorio of infoAnexos.listAnexosObligatorios) {
                anexoObligatorio[properties.MOSTRAR_ELIMINAR] = false;
            }
           
            for (const anexoOptativo of infoAnexos.listAnexosOptativos) {
                anexoOptativo[properties.MOSTRAR_ELIMINAR] = false;
            }
        }    
    };

    //Metodo que elimina el anexo y cambia la variable para volver a mostrar el dropzone
    const eliminarAnexo = (e) => {
        var numDocObligatorios = numAnexosObligatoriosEntregados;
        let solAct = loadState(localStorageName.solicitudActual, true);

		for (let doc of infoAnexos.listAnexosObligatorios){
            if ( doc.nombreAnexo === e.target.name ) { 
                doc.mostrarEliminar = false;                
                --numDocObligatorios;
                setNumAnexosObligatoriosEntregados(numDocObligatorios);
                setDisabledListo(true);
            }
        }

        for (let doc of infoAnexos.listAnexosOptativos) {
            if ( doc.nombreAnexo === e.target.name ) { 
                doc.mostrarEliminar = false;
            } 
        }

        for( var j = 0; j < documentos.length; j++){
            if(documentos[j].anexo !== null) {
                if(e.target.name === documentos[j].anexo.nombreAnexo) {
                    if (numDocObligatorios === infoAnexos.listAnexosObligatorios.length){
                        setDisabledListo(false);
                    }
                    let tamDocuActual = parseFloat((documentos[j].fichero.file.size / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES));
                    let tamDocu = parseFloat((tamDocumentos - tamDocuActual).toFixed(properties.NUMERO_DECIMALES));
                    setTamDocumentos(tamDocu);
                    setIsLoading(true);
                    crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ANEXO_PCT, {url: properties.RESOURCE_ELIMINAR_ANEXO_PCT + documentos[j].idPCT + '/expediente/' +solAct.id ,method: properties.CRUD_TYPE_GET }).then(response => {
                        setIsLoading(false);
                    });

                    documentos.splice(j, 1);

                    break;
                }
            } 
        }

        setNumDocumentos(numDocumentos - 1);

    };
    
    //Metodo que te lleva al paso anterior, en este caso a formulario
    const atrasFormulario = () => {
        history.push(properties.URL_CREATE_NUEVA_SOLICITUD);  
    };

    //Metodo que oculta la tabla y muestra subir archivos (en caso de que existan anexos). En caso de que no existan anexos retorna a la pantalla nueva solicitud
    const atrasOcultarTabla = () => {
        if (infoAnexos !== undefined) {
            if(numAnexosObligatoriosEntregados !== infoAnexos.listAnexosObligatorios.length){
                setDisabledListo(true);
            }
            setListo(false);
            setDatosTabla([]);
        } else {
            history.push('/NuevaSolicitud');
        }
    };

    //Metodo que cierra el componente confirmar que salta al añadirmas peso de lo permitido
    const cerrarConfirmarPeso = () => {
        setTamMaximoSobrepasado(false);       
    };

    //Metodo que hace saltar el componente mostrar cuando un anexo subido es rechazado
    const mostrarConfirmarError = (rejectedFile, tamMaxDoc) => {

        setErrorDropRejected(true);
        setTamDropRejected(tamMaxDoc);
        
    };

    //Metodo que cierra el componente mostrar cuando un anexo subido es rechazado
    const cerrarErrorDropRejected = () => {

        setErrorDropRejected(false);
        
    };

    const cerrarConfirmacionGuardado = () => {
        setMostrarConfirmacionGuardado(false);
    }

    //Metodo que consulta en la base de datos si la solicitud tiene anexos entregados, en caso de que exista rellenaremos la pagina
    const recuperarAnexos = async (informacionAnexos) => {
        setIsLoading(true);

        let solAct = loadState(localStorageName.solicitudActual, true);
        let conAct = loadState(localStorageName.convocatoriaSeleccionada, true);
        const docsEnform = [];
        const docsEnBD = [];

        let faseEstado = loadState(localStorageName.faseEstadoExpediente, true);
        const request = {
            'idExpediente': solAct.id,
            ...faseEstado
        }
        let arrayTempVoluntariosIds = []; //Array temporal para guardar los ids de los docs voluntarios, para que no se eliminen posteriormente
        let arrayTempOptativosIds = []; //Array temporal para guardar los ids de los docs optativos, para que no se eliminen posteriormente

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ANEXO_PCT, {url: properties.RESOURCE_OBTENER_ANEXO_PCT, method: properties.CRUD_TYPE_POST, data: request }).then(response => {
            
            let numAnexosObligatorios = 0;
            let numAnexosAnexar = 0;
            const arrayTempVoluntarios = [];
            let contieneAnexosVoluntarios = false; 
            let numInputsCreados = numInput;
            let pesoDocumentosTotal = 0;

            for (const docAnexo of response.data) {
                docsEnBD.push(docAnexo.documento.id); //Se guardan todos los recuperados en este array

                let tamFichero = parseFloat((docAnexo.tamAnexo / properties.UN_MEGABYTE_EN_BYTE).toFixed(properties.NUMERO_DECIMALES));

                pesoDocumentosTotal = pesoDocumentosTotal + tamFichero;

                // Los documentos "Depende del campo del formulario" se han de gestionar igual que los obligatorios en este punto
                if(docAnexo.confAnexo.obligatorio==="Si" || docAnexo.confAnexo.obligatorio==="Depende del campo del formulario"){
                    numAnexosObligatorios++;

                    for (const infoAnexoObligatorio of informacionAnexos.listAnexosObligatorios) {
                        
                        if(docAnexo.documento.descripcion===infoAnexoObligatorio.descripcionAnexo){
                            docsEnform.push(docAnexo.documento.id);

                            Object.assign(infoAnexoObligatorio, {mostrarEliminar: true});
                            Object.assign(infoAnexoObligatorio, {tamDocSubido: tamFichero}); //PESO DEL DOC

                            let documentoV = {
                                fichero: {
                                    data: docAnexo.fichero,
                                    file: {
                                        path: docAnexo.documento.nombre,
                                        size: docAnexo.tamAnexo //<---- Pongo el tamaño a 0 ya que no lo guardamos
                                    }
                                },
                                anexo: infoAnexoObligatorio,
                                descripcionVoluntario: null,
                                firmado: false,
                                nombreFichero: {
                                    path: docAnexo.documento.nombre,
                                },
                                idSolicitud: solAct.id,
                                idConvocatoria: conAct.id,
                                idProcedimiento: proActual.id,
                                idPCT: docAnexo.documento.id,
                            }
                            documentos.push(documentoV);
                        }
                    }
                } else if(docAnexo.confAnexo.obligatorio==="No"){
                    numAnexosAnexar++;

                    for (const infoAnexoOptativo of informacionAnexos.listAnexosOptativos) {
                        
                        if(docAnexo.documento.descripcion===infoAnexoOptativo.descripcionAnexo){


                            Object.assign(infoAnexoOptativo, {mostrarEliminar: true});
                            Object.assign(infoAnexoOptativo, {tamDocSubido: tamFichero}); //PESO DEL DOC

                            let documentoV = {
                                fichero: {
                                    data: docAnexo.fichero,
                                    file: {
                                        path: docAnexo.documento.nombre,
                                        size: docAnexo.tamAnexo, //<---- Pongo el tamaño a 0 ya que no lo guardamos
                                    }
                                },
                                anexo: infoAnexoOptativo,
                                descripcionVoluntario: null,
                                firmado: false,
                                nombreFichero: {
                                    path: docAnexo.documento.nombre,
                                },
                                idSolicitud: solAct.id,
                                idConvocatoria: conAct.id,
                                idProcedimiento: proActual.id,
                                idPCT: docAnexo.documento.id,
                            }
                            documentos.push(documentoV);
                            arrayTempOptativosIds.push(docAnexo.documento.id);
                        }
                    }
                } else {
                    let documentoV = {
                        fichero: {
                            data: docAnexo.fichero,
                            file: {
                                path: docAnexo.documento.nombre,
                                size: docAnexo.tamAnexo, //<---- Pongo el tamaño a 0 ya que no lo guardamos
                            },
                        },
                        anexo: null,
                        descripcionVoluntario: docAnexo.documento.descripcion,
                        firmado: false,
                        nombreFichero: {
                            path: docAnexo.documento.nombre,
                        },
                        idSolicitud: solAct.id,
                        idConvocatoria: conAct.id,
                        idProcedimiento: proActual.id,
                        idPCT: docAnexo.documento.id,
                    }
                    documentos.push(documentoV);
                    contieneAnexosVoluntarios = true;
                    numInputsCreados++;
                    let documentoVoluntario = {
                        mostrarDropZone: true,
                        value:  docAnexo.documento.descripcion,
                        mostrarDocAnexo: true,
                        tamDoc: tamFichero
                    }
                    arrayTempVoluntarios.push(documentoVoluntario);
                    arrayTempVoluntariosIds.push(docAnexo.documento.id);
                }
            }
            numAnexosObligatorios = docsEnform.length;
            setTamDocumentos(parseFloat(pesoDocumentosTotal.toFixed(properties.NUMERO_DECIMALES)));
            setNumAnexosObligatoriosEntregados(numAnexosObligatorios);
            setNumDocumentos(numAnexosObligatorios + numAnexosAnexar)
            setInfoAnexos(informacionAnexos);

            if(contieneAnexosVoluntarios){
                let documentoVoluntario = {
                    mostrarDropZone: false,
                    value: "",
                    mostrarDocAnexo: false,
                    tamDoc: "",
                }
                arrayTempVoluntarios.push(documentoVoluntario);
                setNumInput(numInputsCreados);
                setDocumentosVoluntarios(arrayTempVoluntarios);
            }
            if(informacionAnexos.listAnexosObligatorios.length ===numAnexosObligatorios){
                setDisabledListo(false);
            }
        }).finally(function () {
            let convocatoriasColaborativas = convocatoriasColaborativasCodigo;
            let convocatoriasObtenidas = convocatoriasColaborativas !== undefined ? convocatoriasColaborativas.split(', ') : null;
            let accion = loadState(localStorageName.accion, false);
             //Se eliminaban los docs voluntarios, se crea array con todos para tenerlos en cuenta a la hora de combinar
            const docsTotales = [
                ...arrayTempOptativosIds,
                ...arrayTempVoluntariosIds,
                ...docsEnform
            ];
            const combinados = [...new Set([...docsTotales, ...docsEnBD])];

            //Si no estan en ambos arrays se usan para borrar para no generar docs indeseados
            const noCoincidentes = combinados.filter(
                elemento =>
                  (docsTotales.includes(elemento) && !docsEnBD.includes(elemento)) ||
                  (docsEnBD.includes(elemento) && !docsTotales.includes(elemento))
              );

            if (noCoincidentes.length > 0) {
                for (const idDoc of noCoincidentes) {
                    crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ANEXO_PCT, {url: properties.RESOURCE_ELIMINAR_ANEXO_PCT + idDoc + '/expediente/' +solAct.id ,method: properties.CRUD_TYPE_GET }).then(response => {
                    });
                }
            }
            if (convocatoriasObtenidas !==  null && convocatoriasObtenidas.includes(convocatoria.codigo) && perfil.descripcion === 'REPRESENTANTE_SICA' && (accion === 'revisarPresentacion' || accion === 'revisarSubsanacion')) {
                setListo(true);
                generarDatosTabla();
            }
            setIsLoading(false);
        });
        
    }
    
    /**
     * @function recuperarTipoDocVol 
     */
    const recuperarTipoDocVol = async () => {
        let tipoDocVol;
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_TIPO_DOC, { url: properties.RESOURCE_TIPO_DOC_CODIGO + properties.CODIGO_VOL, method: 'GET' }).then(response => {
            tipoDocVol = response.data;
        }).catch(() => {
            handleSetMessage(msgError.error, msgError.severity);
        });

        return tipoDocVol;
    };

    /**
     * @function obtenerFasesEstados Función que obtiene los códigos de las fases y los estados buscados
     * @returns 
     */
    const obtenerFasesEstados = async () => {
        let constantes = [];

        // Paso 1. Obtiene las constantes decalradas en BandejaSolicitudesData y se insertan en el array cosntates.
        for (const key in constantesEstadosFasePCT3) {
            constantes.push(key);
        }

        // Paso 2. Se obtienen las fases y estados 
        let fasesEstadosObtenidos = await obtenerCodigosFaseEstado(convActual.id, constantes, constantes);        
        
        // Paso 3. Se recorren los valores obtenidos y se insertan en las constantes que se utilizaran en la pantalla
        for (const feo of fasesEstadosObtenidos.valores) {

            switch (feo.clave) {
                case 'F_INICIO_OV3':
                    constantesEstadosFasePCT3.F_INICIO_OV3 = feo.valor;
                    break;
                case 'F_ALEGACIONES_OV3':
                    constantesEstadosFasePCT3.F_ALEGACIONES_OV3 = feo.valor;
                    break;
                case 'F_SUBSANACION_OV3':
                    constantesEstadosFasePCT3.F_SUBSANACION_OV3 = feo.valor;
                    break;
                case 'F_JUSTIFICACION_OV3':
                    constantesEstadosFasePCT3.F_JUSTIFICACION_OV3 = feo.valor;
                default:
                    break;
            }
        }
        return constantesEstadosFasePCT3;
    }

    return { botonPorDefecto, alerts, message, setMessage, getInfoAnexos, infoAnexos, handleOnClickListo, listo, obtenerTituloPagina, 
            tituloPagina, handleOnDrop, datosTabla, isPhoneVersion, updateFormat, handleChangeIsVisible,
            isVisible, numDocumentos, tamDocumentos, mostrarPDF, cerrarPopUpPdf, pdf, handleChange, documentosVoluntarios, eliminarAnexoVoluntario, anyadirPropiedadEliminar, 
            eliminarAnexo, disabledListo, atrasFormulario, atrasOcultarTabla, numInput, isLoading, tamMaximoSobrepasado, cerrarConfirmarPeso, mostrarConfirmarError,
            cerrarErrorDropRejected, errorDropRejected, tamDropRejected, existenAnexosObligatrios, existenAnexosOptativos, recuperarAnexos,
            nombrePdfDescargar, objectButtons, setTamDocumentos, mostrarConfirmacionGuardado, cerrarConfirmacionGuardado, obtenerFasesEstados
        };

}
export default useFunctions;