import { makeStyles } from '@material-ui/core/styles';
import { estilosTextosPrincipales, colores, tipografia } from './../../../../AppStyle';

export default makeStyles({  
    classGrid: {
        textAlign: 'left',
        marginTop: '15px',
        marginBottom: '19px',
        marginRight: '32px',
        width: 'auto',
        display: 'block',
    },
    classInputLabel: {
        ...estilosTextosPrincipales.body2,
        fontFamily: tipografia.regular,
        color: colores.escalaGrises.ochenta,
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: ' 17px',
        marginBottom: '5px',
        position: 'relative',
        '&.Mui-focused': {
            color: colores.escalaGrises.ochenta,
        },
    },
    classSpanRequired: {
        color: colores.principales.required,
        ...estilosTextosPrincipales.body2,
    }
});