import { useState, useRef, useEffect } from 'react';
import RestProvider from 'providers/RestProvider';
import { properties as appProperties, texts_Acciones_Ok } from '../../../AppData';
import useApp from 'AppFunctions';
import { cabecerasTabla, properties, msgError, urlIconos, ordenAcciones, objText, nombreFuncionesAcciones, constantesEstadosFasePCT3 } from './BandejaSolicitudesData';
import { loadState, saveState } from './../../../providers/localStorage';
import { localStorageName } from './../../../providers/localStorageData';
import { MenuItem } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { getContextFromUrl } from 'config/Config';

const useOperations = () => {
    /////////////////////////////////Valores cargados desde localStorage
    const convocatoriaSeleccionada = loadState(localStorageName.convocatoriaSeleccionada, true);
    const procedimientoActual = loadState(localStorageName.procedimientoActual, true);
    const datosUsuarioInteresado = loadState(localStorageName.infoUsuario, true);
    const perfilSeleccionado = loadState(localStorageName.perfilSeleccionado, true);
    //Inserta el título de la página seguún el valor obtenido de localStorage
    const tituloPagina = `Bandeja de solicitudes de ${convocatoriaSeleccionada === undefined ? '' : ((procedimientoActual.descripcion === undefined) ? procedimientoActual.nombre : procedimientoActual.descripcion)}`;

    const { obtenerCodigosFaseEstado } = useApp();

    //Variables de acceso rápido
    let situacion = properties.situacion;
    let estado = properties.estado;
    let busqueda = properties.busqueda;
    let pagina = properties.INIT_PAGE;

    ////////////////////////////////Hooks utilizadas en los selectores    
    //Devuelven las opciones             
    const [situacionExp, setSituacionExp] = useState([]);
    const [estadoExp, setEstadoExp] = useState([]);
    ////////////////////////////////Hook que delvuelve el valor del Select situación
    const [selectSituacion, setSelectSituacion] = useState(properties.situacionDefault);
    ////////////////////////////////Hook que delvuelve el valor del Select situación
    const [selectEstado, setSelectEstado] = useState('');
    ////////////////////////////////Hook que devuelve el valor del buscador
    const [buscador, setBuscador] = useState(null);
    ////////////////////////////////Hooks utilizadas en el método paginate para obtener las situaciones y estados actuales    
    const [situacionCambiada, setSituacionCambiada] = useState(null);
    const [estadoCambiado, setEstadoCambiado] = useState(null);
    ////////////////////////////////Hook que almacena el estado habilitado o deshabilitado del Select estado
    const [isDisabled, setIsDisabled] = useState(false);
    ////////////////////////////////Hooks que contiene el objeto del listado de expedientes que recibirá la tabla
    const [listadoExpedientes, setListadoExpedientes] = useState([]);
    ////////////////////////////////Hook que contiene el valor obtenido en el buscador
    const [valorBuscado, setValorBuscado] = useState(null);
    ////////////////////////////////Hooks utilizadas en el paginador
    const [totalRecords, setTotalRecords] = useState('');
    const [currentPage, setCurrentPage] = useState(properties.INIT_PAGE);
    ////////////////////////////////Hook que inserta el estado componente CircularProgress
    const [isLoading, setIsLoading] = useState(false);
    ////////////////////////////////Hook que inserta el estado de la versión móvil
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    //////////////////////////////////Hook para guardar el campo que está haciendo ordenado de forma Asc o Desc
    const [campoOrdenado, setCampoOrdenado] = useState('numeroExpediente');
    const [ordenacionTipo, setOrdenacionTipo] = useState('ASC');
    const [ordenadoAscente, setOrdenadoAscente] = useState(true);
    ////////////////////////////////Ocultar/Mostrar filtros 
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    
    ////////////////////////////////Hooks dedicados al selector de organismos si existe más de uno activo
    const [showOrgSelector, setShowOrgSelector] = useState(false);
    const [listadoOrganismos, setListadoOrganismos] = useState([]);
    const [orgSelected, setOrgSelected] = useState(null);

    ///////////////////////////////Cerrar popup de aviso de errores
    const [errorDropRejected, setErrorDropRejected] = useState(false);

    ///////////////////////////////Cerrar popup de aviso antes de eliminar
    const [mostrarPopUpAviso, setMostrarPopUpAviso] = useState(false);

    //////////////////////////////Mensaje de error del popup
    const[mensajeError, setMensajeError] = useState('')
    const [mensajeTitulo, setMensajeTitulo] = useState(objText.TEXT_ERROR);

    //////////////////////////////Mensaje del popup
    const[mensajePopUp, setMensajePopUp] = useState(objText.TEXT_CONFIRMAR_BORRAR)

    ////Hook para almacenar la idsolicitud cuando se pulse una accion como la de eliminar, al mostrar un popup no le llega el identifiador
    const[solicitudElegida, setSolicitudElegida] = useState('')

    ////Hook mostrar el boton de la solicitud
    const[mostrarBotonNuevaSolicitud, setMostrarBotonNuevaSolicitud] = useState(false);

    const refBuscador = useRef();
    const refEstado = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [numMostrar, setNumMostrar] = useState(properties.RECORDS_PER_PAGE);
    const [solicitudColaborativa, setSolicitudColaborativa] = useState(false);
    const [convocatoriasColaborativasCodigo, setConvocatoriasColaborativasCodigo] = useState(null);


    let _ID = 0;
    //Hook que obtiene el crudOperation
    const { crudOperation, crudOperationsType, obtenerProperties } = RestProvider();
    ////////////////////////////////Hooks para controlar los mensajes
    const [message, setMessage] = useState({ msg: '', severity: '' });
    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const [alerts, setAlerts] = useState([]);


    const addProperties = async () => {      
        const propertiesList = [
            'convocatorias.colaborativas.codigo'
        ];

        try {
            let properties = await obtenerProperties(propertiesList);    
            // Actualiza el estado
            setConvocatoriasColaborativasCodigo(properties['convocatorias.colaborativas.codigo'])
        } catch (error) {
            console.error('error obteniendo las properties', error);
        }
    };

    
    useEffect(() => {
        addProperties();
    }, []);

    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    const history = useHistory();

    const convocatoria = loadState(localStorageName.convocatoriaSeleccionada, true);

    /**
     * @constant handleChangeSituacion Método para cambiar el valor de la Hook selectSituacion
     * @param {*} e 
     * @param {*} newValue Valor cambiado en el selector
     */
    const handleChangeSituacion = async (e) => {
        //Paso 1. Cambia el valor seleccionado en la Hook
        setSelectSituacion(e.target.value);
        //Paso 2. Reinicializa el valor del Select Estado y el buscador        
        setSelectEstado(null);
        setBuscador(null);
        setValorBuscado(null);
        /*Paso 3. Inserta el valor en las variables de situacion y estado, las cuales se pasaran por parametro al endPoint listadoExpedientes
        y se insertan estos valores en las Hooks situacionCambiada y estadoCambiado, las cuales usara el paginador*/
        situacion = e.target.value;
        estado = null;
        setSituacionCambiada(situacion);
        setEstadoCambiado(estado);
        //Paso 4. En caso de que la situación sea pendiente de presentación, el Select estado será deshabilitado
        situacion === properties.situacionSinEstado ? getValueSituacion(true) : getValueSituacion(false)
        //Paso 5. Llamada al endPoint listadoExpedientes para actualizar la vista de los valores de la tabla
        //Paso 5.1. Llamada al endPoint obtenerEstado en caso de que la situacion no sea "PP"
        if (situacion !== properties.situacionSinEstado) {
            getEstados(situacion);
        }
        await getValoresTabla(situacion, estado, properties.INIT_PAGE, campoOrdenado, ordenacionTipo, null);
        refEstado.current.getElementsByTagName('button')[0].click();   
    }

    /**
     * @constant handleChangeEstado Método para cambiar el valor de la Hook selectEstado
     * @param {*} e 
     * @param {*} newValue Valor cambiado en el selector
     */
    const handleChangeEstado = (e, newValue) => {
        //Paso 1. Se verificad con una estructura de control el valor obtenido para capturar las excepciones
        if (newValue !== null) {
            //Paso 2. Cambia el valor seleccionado en la Hook
            setSelectEstado(newValue.codigo);
            //Paso 3. Inserta el valor en las variable de estado, la cual se pasa por parametro al endPoint listadoExpedientes
            estado = newValue.id;
        } else {
            //Se inserta el valor esperado (null)
            estado = newValue;
            setSelectEstado(newValue);
        }
        /*Paso 4. Se inicializa el valor de la variable situacion con el valor de la Hook selectSituacion, para pasar por parametro al endPoint listadosExpedientes
        y se insertan estos valores en las Hooks situacionCambiada y estadoCambiado, las cuales usara el paginador*/
        situacion = selectSituacion;
        setSituacionCambiada(situacion);
        setEstadoCambiado(estado);
        //Paso 5. Reinicializa el valor del buscador
        setBuscador(null);
        setValorBuscado(null);
        //Paso 6. Llamada al endPoint listadoExpedientes para actualizar la vista de los valores de la tabla
        //getEstados(situacion);
        getValoresTabla(situacion, estado, properties.INIT_PAGE, campoOrdenado, ordenacionTipo, null);
    }

    /**
     * @constant handleChangeBuscador Método para cambiar el estado del valor de la Hook buscador
     * @param {*} e 
     */
    const handleChangeBuscador = (e) => {
        //Paso 1. Inserta el valor recibido en el buscador en la Hook
        setBuscador(e.target.value);
        //Paso 2 Se inserta el valor obtenido en el buscador en la variable busqueda
        busqueda = e.target.value === '' ? null : e.target.value;
        //Paso 3. Se inserta el valor obtenido de la vairable busqueda en el Hook valorBuscado
        setValorBuscado(busqueda);
    }
    
    /**
     * @constant handleChangeOrganismo Método para setear el organismo seleccionado desde el selector
     * @param {*} e 
     * @param {*} newValue Valor cambiado en el selector
     */
    const handleChangeOrganismo = async (e, newValue) => {
        setOrgSelected(newValue)
    }
    
    /**
     * @constant handleIniciarSolicitud Método para iniciar la creación de la solicitud especificando el organismo
     */
    const handleIniciarSolicitud = async () => {
        setShowOrgSelector(false)
        saveState(localStorageName.organismo, orgSelected, true)
        await crearSolicitudxOrganismo(orgSelected)
    }

    /**
     * @constant handleCancelarSolicitud Método de vuelta a la bandeja de solicitudes, ocultando el selector de organismo 
     */
    const handleCancelarSolicitud = async () => {
        setIsLoading(false)
        setShowOrgSelector(false)
    }

    /**
     * @constant menuItem Método auxiliar al componente SelectSimple, inserta las opciones del selector
     * @param {*} objeto Objeto recibido por parámetros que contendrá las opciones recibidas por el selector
     * @returns Devuelve las opciones del Selector
     */
    const menuItem = (objeto) => {
        return (
            objeto.map((filtro) => (
                <MenuItem key={filtro.codigoValor} value={filtro.codigoValor}>
                    {filtro.descripcionValor}
                </MenuItem>
            )))
    };

    ////////////////////////////////Llamadas a los endPoints

    /**
     * @constant getSituacion Método que realiza la llamada al endPoint findBy-codigoCatalogo
     */
    const getSituacion = async () => {
        setIsLoading(true);
        let codigoCatalog  = properties.COD_CATALOGO_COMBO_SITUACION;
        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_VALOR_CATALOGO, { url: properties.URL_GET_FINDBY_CODIGO_CATALOGO + codigoCatalog, method: 'get'}).then(response => {
            //Se insertan los valores en el Hook sitiacionExp
            setSituacionExp(response.data);
        }).catch(function (error) {
            
            handleSetMessage(msgError.error, msgError.severity);
        });
    }
    /**
     * @constant getEstados Método que realiza la llamada al endPoint obtenerEstado
     */
    const getEstados = async (situacionObtenida) => {
        if(situacionObtenida === null){
            situacionObtenida = 'TO';
        }
        let convSituacion = {
            idConvocatoria: convocatoriaSeleccionada.id,
            codSituacion: situacionObtenida
        }

        setIsLoading(true);
        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ESTADOS_PCT3, { url: properties.URL_GET_ESTADOS_PCT3, method: 'post', data: convSituacion}).then(response => {
            //Se insertan los valores en el Hook estadoExp
            setEstadoExp(response.data);
        }).catch(function (error) {
            handleSetMessage(msgError.error, msgError.severity);
        });
    }

    /**
     * @function crearNuevaSolicitud Función que crea una nueva solicitud
     */
    const crearNuevaSolicitud = async () => {
        
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ORGANISMO, { url: properties.URL_ORGANISMOS_BY_CONVOCATORIA + '/' + convocatoria.id, method: 'get' }).then(async response => {
            
            if (response.data.length === 1) {
                // Si solo hay un organismo configurado, se utiliza para crear la nueva solicitud
                saveState(localStorageName.organismo, response.data[0], true);
                await crearSolicitudxOrganismo(response.data[0]);
            } else {
                // En caso de existir más de un organismo, mostramos el selector para elegir con que
                // organismo se desea tramitar la solicitud
                setListadoOrganismos(response.data)
                setShowOrgSelector(true)
            }
        }).catch(function (error) {
            if (error.response.status !== 404) {
                handleSetMessage(msgError.error, msgError.severity);
            } else {
                setMensajeError(objText.TEXT_NO_EXISTE_ORGANISMO);
                setErrorDropRejected(true);
                setMensajeTitulo(objText.TEXT_TITLE_NO_EXISTE_ORGANISMO);
            }
            setIsLoading(false);
        });
    }

    /**
     * @function seleccionarFormulario Función que obtiene el formulario configurado para la convocatoria
     * @param fase Código de la fase
     * @param estadoFase Código del estado fase
     */
    const seleccionarFormulario = async (fase, estadoFase) => {
        let isFormularioSeleccionado = false;

        // Se obtiene la selección del formulario
        isFormularioSeleccionado = await seleccionarFormularioPCT3(fase, estadoFase);

        if (isFormularioSeleccionado) {
            //Se almacena la fase y se establece la ruta de la nueva solicitud
            saveState(localStorageName.fase, fase, false);
            saveState(localStorageName.estado, estadoFase, false);

            history.push(properties.PATH_NUEVA_SOLICITUD);
        }
    }


    /**
     * @constant getValoresTabla Método que realiza la llamada al endPoint listadoExpedientes que devuelve los valores a insertar en la tabla
     * @param {*} situacionObtenida Obtiene el valor del Select situación
     * @param {*} estadoObtenido Obtiene el valor del Select estado
     * @param {*} paginaObtenida Obtiene la página actual del paginador
     */
    const getValoresTabla = async (situacionObtenida, estadoObtenido, paginaObtenida, campoAOrdenar, formaOrdenacion, busquedaObtenida) => {
        //Paso 1. Se almacena en una variable el objeto recibido para hacer la llamada al endPoint
        let datosExpedientes = obtenerDatosExpedientes(situacionObtenida, estadoObtenido, paginaObtenida, campoAOrdenar, formaOrdenacion, busquedaObtenida);

        //Paso 2. Se establece a true el valor isLoading para que aparezca la "ruleta" de carga mientras se recuperan los valores del endPoint
        setIsLoading(true);
        //Paso 3. Se comprueba si se ha realizado una busqueda
        if (!isPhoneVersion && busquedaObtenida !== null) {
            //Paso 3.1. Se inserta el valor por defecto en el Select situación (Todas)
            setSelectSituacion(properties.situacionDefault);
            //Paso 3.2. Se borra el valor del Select estado y se reinicializa su variable
            refEstado.current.getElementsByTagName('button')[0].click();
            setSelectEstado(null);
            //Paso 3.3. Se establece siempre a false el valor isDisabled del Select estado
            getValueSituacion(false);
        }        
        //Paso 4. Se obtienen los valores de la tabla y el nº de página
        let numeroPaginaObtenido = null;        
        const interesado = loadState(localStorageName.infoUsuario, true);
        let perfil = loadState(localStorageName.perfilSeleccionado, true);
        perfil['codigo'] = perfil.descripcion;
        Object.assign(datosExpedientes, {'cifEntidad': interesado.entidadCif});
        Object.assign(datosExpedientes, {'perfilSeleccionado': perfil});
        getValoresTablaPCT3(datosExpedientes);
        numeroPaginaObtenido = datosExpedientes.numeroPagina + 1             
        setCurrentPage(numeroPaginaObtenido);
    }

    ////////////////////////////////Parametros enviados a los endPoints

    /**
     * @constant obtenerDatosExpedientes Método que devuelve el objeto que será enviado al endPoint listadoExpedientes
     * @param {*} situacionObtenida Se recibe la situación obtenida en el Select situación
     * @param {*} estadoObtenido Se recibe el estado obtenido en el Select estado
     * @param {*} paginaObtenida Se recibe la página obtenida
     * @returns Devuelve el objeto generado
     */
    const obtenerDatosExpedientes = (situacionObtenida, estadoObtenido, paginaObtenida, campoAOrdenar, formaOrdenacion, busquedaObtenida) => {
        const interesado = loadState(localStorageName.infoUsuario, true);
        let datosExpedientes = '';

        datosExpedientes = {
            dataSource: null,
            inputListadoExpedientes: {
                idConvocatoria: convocatoriaSeleccionada.id,
                nifInteresado: datosUsuarioInteresado.interesadoNif,
                numeroPagina: paginaObtenida - 1,
                campoOrdenacion: campoAOrdenar, //idSolicitud
                tipoOrdenacion: formaOrdenacion,
                registrosPorPagina: window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches ? 10000 : numMostrar,
                idSolicitudOrIdExpedienteTw: busquedaObtenida, //Recibira el valor insertado por el usuario en el buscador
                idEstado: estadoObtenido,
                codSituacion: situacionObtenida === properties.situacionDefault ? null : situacionObtenida
            },
            procedimiento: {
                ...procedimientoActual    
            },
            convocatoria: {
                ...convocatoria
            },
            datosUsuario: {
                interesadoNif: interesado.interesadoNif,
                interesadoNombre: interesado.interesadoNombre,
                interesadoApellido1: interesado.interesadoApellido1,
                interesadoApellido2: interesado.interesadoApellido2,
                entidadCif: interesado.entidadCif,
                tipoAutenticacion: interesado.tipoAutenticacion,
            }
        }
        
        datosExpedientes = datosExpedientes.inputListadoExpedientes;
        Object.assign(datosExpedientes, {numeroExpediente: 
            ((busquedaObtenida !== undefined && busquedaObtenida !== null) 
                ? busquedaObtenida.toUpperCase() : busquedaObtenida)
        });
        delete datosExpedientes.idSolicitudOrIdExpedienteTw;

        return datosExpedientes;
    }

    /**
     * @constant conversionTexto Conversión de los valores de la descripción del objeto estado. Para que el texto se muestre en el selector con
     * la primera letra en mayúscula y el resto en minúscula
     * @param {*} valores array recibido con los valores
     */
    const conversionTexto = (valores) => {
        valores.map(function (opc) {
            //Paso 1. Se convierte en minúsucla los valores de la key descripción
            opc.descripcion = opc.descripcion.toLowerCase();
            //Paso 2. Se convierte en mayúscula la primera letra de los valores de la key descripción
            opc.descripcion = opc.descripcion.charAt(0).toUpperCase() + opc.descripcion.slice(1);
        });
    }
    conversionTexto(estadoExp);//Se formatea el texto del selector de estado de expedientes

    /**
         * @constant headerTableObject Obtención del objeto que recibe la tabla con los valores a insertar en la cabecera
         * @param {*} funcionOrdena Función que recibe
         * @returns {headersObject} Devuelve un array de objetos con las cabeceras de la tabla
         */
    const headerTableObject = (funcionOrdenacion) => {
        let headersObject = []; //Array declarado, donde se insertarán los objetos
        //Paso 1. Se recorre el array recibido con las cabeceras
        cabecerasTabla.map(valores => {
            if (valores.titulo != 'ACCIONES') {
                headersObject.push(
                    {
                        titulo: valores.titulo,
                        esBotonera: false,
                        funcionOrdenacion: funcionOrdenacion,
                        id: valores.id,
                        estaOrdenado: valores.id === campoOrdenado ? true : false,
                        ordenadoAsc: ordenadoAscente
                    }
                );
            } else {
                headersObject.push(
                    {
                        titulo: valores.titulo,
                        esBotonera: true,
                        funcionOrdenacion: null,
                        id: valores.id,
                    }
                );
            }
        });
        return headersObject;
    }

    ////////////////////////////// Acciones //////////////////////////////
    /**
     * Instrucciones para dar de alta una nueva acción en el FRONT
     * 
     * OBLIGATORIAS:
     * Paso 1. Dar de alta las acciones deseadas en el Módulo de Administración, en el apartado de Administración de procedimientos (en el procedimiento correspondiente).
     * Paso 2. Desarrollar la función que se ejecutará cuando se haga click sobre el icono de la acción.
     * Paso 3. Insertar la función en el array de funciones (se encuentra en este mismo fichero con el nombre FUNCIONES).
     * 
     * OPCIONALES:
     * Para asignar un icono que no aparezca en el objeto urlIconos (en el fichero BandejaSolicitudesData.js), se deberá insertar en este objeto, con la key del valor
     * de la key iconoAcciones y como valor la url del icono. El icono se insertará en la carpeta public.
     * 
     * En caso de querer asignar un orden de aparición para los iconos de las acciones, se deberá de insertar en la posición deseada en el array ordenAcciones en el
     * fichero BandejaSolicitudesData.js.
     * 
     * NOTA: En caso de que se desee cambiar los valores a mostrar en la tabla por parte de las acciones, se deberá configurar en el objeto keysTablaGenerica
     * (se encuentra en el fichero BandejaSolicitudesData.js).
     */

    //Funciones que realizan la operación en cuestión de cada acción

    const OnClickClonarOV3 = async (e, identificador) => {
        saveState(localStorageName.solicitudActual, identificador.numeroExpediente, true);
        setIsLoading(true);
       
        let numeroExpediente = loadState(localStorageName.solicitudActual, true);
        let infoUsuario = loadState(localStorageName.infoUsuario, true);

        //Paso 1. Endoponint para recuperar el id del expediente a clonar
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_GESTION_DATABASE, { url: properties.URL_EXPEDIENTE + numeroExpediente + '/' + infoUsuario.interesadoNif, method: 'get'}).then(response => { 
            let idExpediente = response.data.id;
            saveState(localStorageName.solicitudActual, idExpediente, false);
        })
        let idExpediente = loadState(localStorageName.solicitudActual, true);
        let expediente;
        let msg_exito;

        //Paso 2. Se realiza la llamada al endPoint para clonar expediente
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DATO_EXPEDIENTE, { url: properties.URL_ACCION_CLONAR_OV3 + idExpediente, method: 'post'}).then(response => {
            msg_exito = texts_Acciones_Ok.MESSAGE_CLONAR + " " + numeroExpediente;
            expediente = response.data;      
            //Paso 2.2. Se inserta el mensaje de éxito
            handleSetMessage(msg_exito, 'success');
            
        }).catch(function (error) {
            setMensajeError(error.response.data.messageDescription);
            setErrorDropRejected(true);
            setIsLoading(false);
        });
        // Paso 3. Se refrescan los expedientes de la tabla con el expediente clonado
        await getValoresTabla(situacionCambiada, estadoCambiado, currentPage, campoOrdenado, ordenacionTipo, null);
    }

    const accionBorrar = async () => {       
        let numeroExpediente = loadState(localStorageName.solicitudActual, true);
        let infoUsuario = loadState(localStorageName.infoUsuario, true);
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_GESTION_DATABASE, { url: properties.URL_EXPEDIENTE + numeroExpediente + '/' + infoUsuario.interesadoNif, method: 'get'}).then(response => { 
            let idExpediente = response.data.id;
            saveState(localStorageName.solicitudActual, idExpediente, false);
        });

        setMostrarPopUpAviso(false);
        setIsLoading(true);
        let idExpediente = loadState(localStorageName.solicitudActual, true);

        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DATO_EXPEDIENTE, { url: properties.URL_ACCION_ELIMINAR_OV3 + idExpediente, method: 'post'}).then(response => {
            handleSetMessage(texts_Acciones_Ok.MESSAGE_BORRAR, 'success');
            getValoresTabla(situacionCambiada, estadoCambiado, currentPage, campoOrdenado, ordenacionTipo, null);
        }).catch(function (error) {
            setMensajeError(error.response.data.messageDescription);
            setErrorDropRejected(true);
            setIsLoading(false);
        })
    }

    const OnClickMejoraDoc = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        history.push('/AporteMejoraDocumentacion')
    }

    const OnClickMejoraDocOV3 = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        history.push('/AporteMejoraDocumentacion')
    }

    /**
     * @function OnClickHistoricoDoc Función que traslada a la página de histórico de documentos
     * @param {*} e 
     * @param {*} identificador 
     */
    const OnClickHistoricoDoc = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        history.push('/HistoricoDocumentos');
    } 

    const OnClickPresentarDesistimiento = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        history.push('/Desistimiento');
    }


    /**
     * @function OnClickModificar Función que realiza la modificación de una solicitud
     * @param {*} e 
     * @param {*} identificador Recibe el objeto de la solicitud
     */
    const OnClickModificar = async (e, identificador) => {
        /* Paso 1. Se añade la key idMoaddSolicitudPdf con el valor del idSolicitud, ya que el método getSubmission espera recibir
         * el id de la solicitud con la clave idMoaddSolicitudPdf 
         */
        let idMoaddSolicitudPdf = {'idMoaddSolicitudPdf': identificador.idSolicitud};
        Object.assign(identificador, idMoaddSolicitudPdf);
        identificador = JSON.stringify(identificador);
        //Paso 2. Se almacena el objeto de la solicitud en el localStorageName
        saveState(localStorageName.solicitudActual, identificador, false);
        /* Paso 3. Se llamada al m'etodo seleccionarFormulario para indicar el formulario a modificar y que se obtenda con los valores de la solicitud
         * almacenada en el localStorageName
         */
        seleccionarFormulario(properties.FASE_NUEVA_SOLICITUD, properties.ESTADO_FASE_NUEVA_SOLICITUD);
        saveState(localStorageName.accionModificar, true, false);
    }

    /**
     * @function OnClickSubsanar Función que realiza la subsanación de una solicitud competitiva o no competitiva
     * @param {*} e 
     * @param {*} identificador Recibe el objeto de la solicitud
     */
    const OnClickSubsanar = async (e, identificador) => {        
        //Paso 1. Asignamos un nuevo parametro al identificador para indicar que es una edición de solicitud
        Object.assign(identificador, {'subsanar': true});

        //Paso 2. Se almacena el objeto de la solicitud en el localStorageName
        let json = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, json, false);
        saveState(localStorageName.accion, 'subsanar', false);
        let solColab = await isSolicitudColaborativa();
        saveState(localStorageName.solicitudColaborativa, solColab, false);
        saveState(localStorageName.perfilSeleccionado, perfilSeleccionado, true);
        seleccionarFormulario(constantesEstadosFasePCT3.F_SUBSANACION_OV3, constantesEstadosFasePCT3.EF_PENDIENTE_SUBSANAR_OV3);
        await guardarOrganismoExpediente(identificador.idExpediente);
    }

    /**
     * @function mostrarPopUpConfirmarBorrar Metodo que muestra el popup confirmar eliminar solicitud
     */
     const OnClickBorrar = (e, identificador) => {
        setMensajePopUp(objText.TEXT_CONFIRMAR_BORRAR)
        setMostrarPopUpAviso(true);
        setSolicitudElegida(identificador.idSolicitud);
    };

    /**
     * @function OnClickPresentarAlegacion Función que realiza la alegacion de una solicitud competitiva o no competitiva
     * @param {*} e 
     * @param {*} identificador Recibe el objeto de la solicitud
     */
     const OnClickPresentarAlegacion = (e, identificador) => { 
        setIsLoading(true);
        //Paso 1. Se almacena el objeto de la solicitud en el localStorage
        Object.assign(identificador, {'alegar': true});
        let json = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, json, false);
        saveState(localStorageName.accion, 'alegar', false);
        /* Paso 2. Se llama al método seleccionarFormulario para indicar el formulario a modificar que se obtendrá con los valores de la solicitud
         * almacenada en el localStorage
         */
        seleccionarFormulario(constantesEstadosFasePCT3.F_ALEGACIONES_OV3, constantesEstadosFasePCT3.EF_PENDIENTE_ALEGACION_OV3);
        setIsLoading(false);
    }

    /**
     * @function OnClickModificarOV3 Función que realiza la modificación de una solicitud de la OV3
     * @param {*} e 
     * @param {*} identificador Recibe el objeto de la solicitud
     */
    const OnClickModificarOV3 = async (e, identificador) => {
        //Paso 1. Asignamos un nuevo parametro al identificador para indicar que es una edición de solicitud
        Object.assign(identificador, {'edicion': true});

        //Paso 2. Se almacena el objeto de la solicitud en el localStorageName
        let json = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, json, false);
        saveState(localStorageName.accion, 'edicion', false);
        let fase = constantesEstadosFasePCT3.F_INICIO_OV3;
        seleccionarFormulario(fase, properties.ESTADO_FASE_NUEVA_SOLICITUD);
        await guardarOrganismoExpediente(identificador.idExpediente);
    }

    /**
     * @function OnClickRevisarPresentacion Función que realiza la revisión de una presentación de soliciutd SICA de la OV3
     * @param {*} e 
     * @param {*} identificador Recibe el objeto de la solicitud
     */
    const OnClickRevisarPresentacion = (e, identificador) => {
        //Paso 1. Asignamos un nuevo parametro al identificador para indicar que es una revision de la presentación
        Object.assign(identificador, {'revisarPresentacion': true});
        saveState(localStorageName.accion, 'revisarPresentacion', false);

        //Paso 2. Se almacena el objeto de la solicitud en el localStorageName
        let json = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, json, false);
        let fase = properties.FASE_INICIADO;
        seleccionarFormulario(fase, properties.ESTADO_FASE_REVISAR_SOLICITUD);
    }

    /**
     * @function OnClickCorregirRevisionSolicitud Función que realiza la correccion de una solicitud en SICA de la OV3
     * @param {*} e 
     * @param {*} identificador Recibe el objeto de la solicitud
     */
    const OnClickCorregirRevisionSolicitud = (e, identificador) => {
            //Paso 1. Asignamos un nuevo parametro al identificador para indicar que es una corrección de solicitud
            Object.assign(identificador, {'corregirSolicitud': true});
            saveState(localStorageName.accion, 'corregirSolicitud', false);
    
            //Paso 2. Se almacena el objeto de la solicitud en el localStorageName
            let json = JSON.stringify(identificador);
            saveState(localStorageName.solicitudActual, json, false);
            let fase = properties.FASE_INICIADO;
            seleccionarFormulario(fase, properties.ESTADO_FASE_CORREGIR_SOLICITUD);
    }

    /**
     * @function OnClickRevisarSubsanacion Función que realiza la revisión de una subsanacion de soliciutd SICA de la OV3
     * @param {*} e 
     * @param {*} identificador Recibe el objeto de la solicitud
     */
    const OnClickRevisarSubsanacion = (e, identificador) => {
        //Paso 1. Asignamos un nuevo parametro al identificador para indicar que es una revision de la subsanacion
        Object.assign(identificador, {'revisarSubsanacion': true});
        saveState(localStorageName.accion, 'revisarSubsanacion', false);

        //Paso 2. Se almacena el objeto de la solicitud en el localStorageName
        let json = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, json, false);
        let fase = properties.FASE_SUBSANAR;
        seleccionarFormulario(fase, properties.ESTADO_FASE_REVISAR_SUBSANACION);
    }

    /**
     * @function OnClickCorregirRevisionSubsanacion Función que realiza la correccion de una solicitud en SICA de la OV3
     * @param {*} e 
     * @param {*} identificador Recibe el objeto de la solicitud
     */
    const OnClickCorregirRevisionSubsanacion = (e, identificador) => {
            //Paso 1. Asignamos un nuevo parametro al identificador para indicar que es una corrección de subsanación
            Object.assign(identificador, {'corregirSubsanacion': true});
            saveState(localStorageName.accion, 'corregirSubsanacion', false);
    
            //Paso 2. Se almacena el objeto de la solicitud en el localStorageName
            let json = JSON.stringify(identificador);
            saveState(localStorageName.solicitudActual, json, false);
            let fase = properties.FASE_SUBSANAR;
            seleccionarFormulario(fase, properties.ESTADO_FASE_CORREGIR_SUBSANACION);
    }

    /**
     * @function mostrarPopUpConfirmarBorrar Metodo que muestra el popup confirmar eliminar solicitud
    */
    const OnClickBorrarOV3 = (e, identificador) => {
        saveState(localStorageName.solicitudActual, identificador.numeroExpediente, true);
        setMensajePopUp(objText.TEXT_CONFIRMAR_BORRAR)
        saveState(localStorageName.accion, 'borrar', false);
        setMostrarPopUpAviso(true);
    };

    const OnClickPresentarDesistimientoOV3 = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        history.push('/Desistimiento');
    }

    /**
     * @function OnClickAltaAnexo Método que muestra el botón de la acción alta anexo
     */
    const OnClickAltaAnexo = async (e, identificador) => {
        await obtenerFaseYestado(identificador.idExpediente);
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'altaAnexo', false);
        history.push('/AltaAnexo');
    }

    const OnClickSubsanarAltaAnexo = async (e, identificador) => {
        await obtenerFaseYestado(identificador.idExpediente);
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'subsanarAltaAnexo', false);
        history.push('/AltaAnexo');
    }
  
    /**
     * @function OnClickOposicionTramitacionSimplificada Función que muestra el botón de la acción Oposición Tramitación Simplificada
     * @param {*} e 
     * @param {*} identificador 
     */
    const OnClickOposicionTramitacionSimplificada = async (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'OPOSICION_TRAMITACION_SIMPLIFICADA', false);        
        history.push('/Entregas');
    }

    /**
     * @function OnClickAlegacion1 Función que muestra el botón de la acción Alegación 1
     * @param {*} e 
     * @param {*} identificador 
     */
    const OnClickAlegacion1 = async (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'ALEGACION1', false);        
        history.push('/Entregas');
    }

    /**
     * @function OnClickAlegacion2 Función que muestra el botón de la acción Alegación 2
     * @param {*} e 
     * @param {*} identificador 
     */
    const OnClickAlegacion2 = async (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'ALEGACION2', false);        
        history.push('/Entregas');
    }

    /**
     * @function OnClickAlegacion3 Función que muestra el botón de la acción Alegación 3
     * @param {*} e 
     * @param {*} identificador 
     */
    const OnClickAlegacionInformacionPublica = async (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'ALEGACION_INFORMACION_PUBLICA', false);        
        history.push('/Entregas');
    }

    /**
     * @function OnClickMejoraSolicitud Función que muestra el botón de la acción Mejora Solicitud
     * @param {*} e 
     * @param {*} identificador 
     */
    const OnClickMejoraSolicitud = async (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'MEJORA_SOLICITUD', false);        
        history.push('/Entregas');
    }

    /**
     * @function OnClickTramiteAudiencia Función que muestra el botón de la acción Trámite de Audiencia
     * @param {*} e 
     * @param {*} identificador 
     */
    const OnClickTramiteAudiencia = async (e, identificador) => {
       identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'TRAMITE_AUDIENCIA', false);        
        history.push('/Entregas');
    }

    const OnClickSubsanacionFacultativa = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'SUBSANACION_FACULTATIVA', false); 
        history.push('/AporteMejoraDocumentacion')
    }

    const OnClickPresentacionAlegaciones = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'PRESENTACION_ALEGACIONES_OTROS_DOCS', false); 
        history.push('/AporteMejoraDocumentacion')
    }

    const OnClickSolicitudAmpliacionPlazo = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'SOLICITUD_AMPLIACION_PLAZO', false);        
        history.push('/Entregas');
    }

    const OnClickIncorporarMedidasProvisionales = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'INCORPORAR_MEDIDAS_PROVISIONALES', false);        
        history.push('/Entregas');
    }

    const OnClickIncorporarSolicitudPeriodoPruebas = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'INCORPORAR_SOLICITUD_PERIODO_PRUEBAS', false);        
        history.push('/Entregas');
    }

    const OnClickIncorporarSolicitudPeriodoExtraordinarioPruebas = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'INCORPORAR_SOLICITUD_PERIODO_EXTRAORDINARIO_PRUEBAS', false);        
        history.push('/Entregas');
    }

    const OnClickIncorporarSolicitudDesitimientoRenuncia = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'INCORPORAR_SOLICICTUD_DESISTIMIENTO_RENUNCIA', false);        
        history.push('/Entregas');
    }

    const OnClickIncorporarSolicitudContinuacionProcedimiento = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'INCORPORAR_SOLICICTUD_CONTINUACION_PROCEDIMIENTO', false);        
        history.push('/Entregas');
    }

    const OnclickSubsanacionInteresado = (e, identificador) => {
        identificador = JSON.stringify(identificador);
        saveState(localStorageName.solicitudActual, identificador, false);
        saveState(localStorageName.accion, 'SUBSANACION_INTERESADO', false);
        history.push('/Entregas');
    }


    /** 
     * @function asociarValores Función auxiliar que inserta los iconos y asigna las funciones a ejecutar en cada acción
     * @param acciones Objeto recibido con las acciones
     * @returns Objeto con los valores modificados
     */
    const asociarValores = (acciones) => {
        //Array que contiene el nombre de las keys del objeto urlIconos
        let keysIconos = Object.keys(urlIconos);
        //Se decalara esta variable para acceder al icono de la acción
        let iconoAccionObtenido = 'icono';
        
        //Paso 1. Se recorre el objeto recibido con las acciones.
        for (const accion of acciones) {
            let tipoIcono = (accion[iconoAccionObtenido]).toUpperCase();

            //Paso 2. Se recorre el array que contiene las keys del objeto urlIconos
            for (const keyBuscada of keysIconos) {
                /* Paso 2.1. Si el el valor de la key del icono (accion.iconoAccion) de la acción corresponde con el nombre de la keyBuscada, se inserta una clave/valor
                en el objeto acciones, que contendrá la url del icono, el cual será mostrado en la tabla */  
                if (keyBuscada.toUpperCase() === tipoIcono) {
                    let urlIcono = urlIconos[keyBuscada];
                    if((`${getContextFromUrl()}` + (urlIconos[keyBuscada])).substring(2) == "//"){
                        urlIcono = (urlIconos[keyBuscada]).substring(1);
                    }
                    Object.assign(accion, {'urlIcono': urlIcono});
                    break;
                } else  {
                    let urlIconoDefault = urlIconos.default;
                    if((`${getContextFromUrl()}` + (urlIconos.default)).substring(2) == "//"){
                        urlIconoDefault = (urlIconos.default).substring(1);
                    }
                    /* Paso 2.2. En caso de que no corresponda, se insertará el icono por defecto */
                    Object.assign(accion, {'urlIcono': urlIconoDefault});
                }
            }

            //Switch para la asociación de las funciones a ejecutar
            switch (accion.onClickFunction) {
                case nombreFuncionesAcciones.borrar: 
                    accion.onClickFunction = OnClickBorrar;
                    break;
                case nombreFuncionesAcciones.mejoraDoc: 
                    accion.onClickFunction = OnClickMejoraDoc;
                    break;         
                case nombreFuncionesAcciones.historicoDoc:
                    accion.onClickFunction = OnClickHistoricoDoc;
                    break;
                case nombreFuncionesAcciones.subsanar: 
                    accion.onClickFunction = OnClickSubsanar;
                    break;   
                case nombreFuncionesAcciones.modificar:
                    accion.onClickFunction = OnClickModificar;
                    break; 
                case nombreFuncionesAcciones.presentarAlegacion:
                    accion.onClickFunction = OnClickPresentarAlegacion;
                    break;
                case nombreFuncionesAcciones.presentarDesistimiento:
                    accion.onClickFunction = OnClickPresentarDesistimiento;
                    break;
                case nombreFuncionesAcciones.modificarOV3:
                    accion.onClickFunction = OnClickModificarOV3;
                    break;
                case nombreFuncionesAcciones.borrarOV3:
                    accion.onClickFunction = OnClickBorrarOV3;
                    break;     
                case nombreFuncionesAcciones.clonarOV3: 
                    accion.onClickFunction = OnClickClonarOV3;
                    break;                
                case nombreFuncionesAcciones.presentarDesistimientoOV3: 
                    accion.onClickFunction = OnClickPresentarDesistimientoOV3;
                    break;     
                case nombreFuncionesAcciones.mejoraDocOV3: 
                    accion.onClickFunction = OnClickMejoraDocOV3;
                    break;
                case nombreFuncionesAcciones.altaAnexo:
                    accion.onClickFunction = OnClickAltaAnexo;
                    break;
                case nombreFuncionesAcciones.revisarPresentacion:
                    accion.onClickFunction = OnClickRevisarPresentacion;
                    break;                     
                case nombreFuncionesAcciones.corregirSolicitud:
                    accion.onClickFunction = OnClickCorregirRevisionSolicitud;
                    break;       
                case nombreFuncionesAcciones.revisarSubsanacion:
                    accion.onClickFunction = OnClickRevisarSubsanacion;
                    break;                     
                case nombreFuncionesAcciones.corregirSubsanacion:
                    accion.onClickFunction = OnClickCorregirRevisionSubsanacion;
                    break;
                case nombreFuncionesAcciones.subsanacionAltaAnexo:
                    accion.onClickFunction = OnClickSubsanarAltaAnexo;
                    break;
                case nombreFuncionesAcciones.oposicionTramitacionSimplificada:
                    accion.onClickFunction = OnClickOposicionTramitacionSimplificada;
                    break;
                case nombreFuncionesAcciones.alegacion1:
                        accion.onClickFunction = OnClickAlegacion1;
                    break;
                case nombreFuncionesAcciones.alegacion2:
                        accion.onClickFunction = OnClickAlegacion2;
                    break;
                case nombreFuncionesAcciones.alegacionInformacionPublica:
                        accion.onClickFunction = OnClickAlegacionInformacionPublica;
                    break;
                case nombreFuncionesAcciones.mejoraSolicitud:
                        accion.onClickFunction = OnClickMejoraSolicitud;
                    break;
                case nombreFuncionesAcciones.tramiteAudiencia:
                        accion.onClickFunction = OnClickTramiteAudiencia;
                    break;
                case nombreFuncionesAcciones.subsanacionFacultativa:
                    accion.onClickFunction = OnClickSubsanacionFacultativa;
                    break;
                case nombreFuncionesAcciones.solicitudAmpliacionPlazo:
                    accion.onClickFunction = OnClickSolicitudAmpliacionPlazo;
                    break;
                case nombreFuncionesAcciones.incorporarMedidasProvisionales:
                    accion.onClickFunction = OnClickIncorporarMedidasProvisionales;
                    break;
                case nombreFuncionesAcciones.incorporarSolicitudPeriodoPruebas:
                    accion.onClickFunction = OnClickIncorporarSolicitudPeriodoPruebas;
                    break;
                case nombreFuncionesAcciones.incorporarSolicitudPeriodoExtraordinarioPruebas:
                    accion.onClickFunction = OnClickIncorporarSolicitudPeriodoExtraordinarioPruebas;
                    break;
                case nombreFuncionesAcciones.incorporarSolicitudDesitimientoRenuncia:
                    accion.onClickFunction = OnClickIncorporarSolicitudDesitimientoRenuncia;
                    break;
                case nombreFuncionesAcciones.incorporarSolicitudContinuacionProcedimiento:
                    accion.onClickFunction = OnClickIncorporarSolicitudContinuacionProcedimiento;
                    break;
                case nombreFuncionesAcciones.presentacionAlegaciones:
                    accion.onClickFunction = OnClickPresentacionAlegaciones;
                    break;
                case nombreFuncionesAcciones.subsanacionInteresado:
                    accion.onClickFunction = OnclickSubsanacionInteresado;
                    break;
            }
        };

        //Paso 4. Se devuelve el array de objetos tras su modificación   
        return acciones;
    }

    /**
     * @function ordenarAcciones Función que realiza la ordenación de las acciones recibidas siguiendo un orden establecido
     * @param {*} ordencciones Array recibido con el orden de las acciones (Debe contener las siglas de cada acción, similar al valor de la clave iconoAccion)
     * @param {*} acciones Acciones recibidas
     * @returns Array de objetos con las acciones
     */
    const ordenarAcciones = (ordenAcciones, acciones) => {
        let accionesObtenidas = [];
        //Paso 1. Inserta las acciones obtenidas que aparecen en el array ordenarAcciones y siguiendo su orden
        
        for (const orden of ordenAcciones) {
            for (const accion of acciones) {
                (orden === accion.nombre && !accionesObtenidas.includes(accion)) &&
                    accionesObtenidas.push(accion);
            }    
        }
        //Paso 2. Inserta las acciones obtenidas que no aparecen en el array ordenarAcciones, insertándolas al final de este
        for (const accion of acciones) {
            !accionesObtenidas.includes(accion) &&
                accionesObtenidas.push(accion);
        }
        return accionesObtenidas;
    }

    ////////////////////////////// FIN Acciones //////////////////////////////

    /**
     * @constant dataTabletObject Obtención del objeto que recibe la tabla con los valores a insertar en ella
     * @returns Se devuelve el objeto que alimentará la tabla
     */
    const dataTableObject = () => {
        let dataObject = []; //Array que contendrá los objetos
        //Recorrido del objeto para obtener los valores a insertar en la tabla
        listadoExpedientes.map(valores => {
            let estadoObtenido = ''; //Variable que contendrá los estados
            let arrayEstados = []; //Array donde se insertarán los estados, en caso de que haya más de uno por fase
            let fase = valores.fase;
            let fecPresentacion = obtenerFechaPresentacion(valores); // Fecha de firmado del expediente
            let acciones = valores.acciones === null ? [] : asociarValores(ordenarAcciones(ordenAcciones, valores.acciones)); //Primero se ordenan las acciones y luego se realiza la asociación de valores (devuelve array de objetos con las acciones ordenadas y los valores asociados)
            //Paso 1. Se comprueba si los expedientes tienen fases
            estadoObtenido = valores.estadoFase.descripcion;
            
            //Paso 2. Se agregan al array las claves y valores del objeto que se va a formar
            dataObject.push({
                //IMPORTANTE, el identificador nos sirve para cuando pulsemos una accion seamos capaces de reconocer que registro de la tabla se ha pulsado
                identificador: valores,
                idSolicitud: (valores.idSolicitud === undefined ? '' : valores.idSolicitud),
                idExpediente: valores.numeroExpediente,
                fechaPresentacion: fecPresentacion,
                situacion: valores.situacion,
                estado: estadoObtenido,
                acciones: acciones 
            });
        });
        for (const obj of dataObject) {
            delete obj.idSolicitud;
        }
        return dataObject;
    }

    /**
     * @function obtenerFechaPresentacion Función que obtiene la fecha de presentación.
     * Hasta ahora se obtenía la fecha de presentación desde la propiedad fechaPresentacion, la cual se obtenía desde la fecha de alta.
     * Ahora se obtiene desde la fecha de orve. Se incluye ambos valores porque las solicitudes ya presentadas tienen almacenado dicho valor.
     * @param {*} valor 
     * @returns 
     */
    const obtenerFechaPresentacion = (valor) => {
        let res = (valor.fechaOrve === undefined || valor.fechaOrve === null) ? '' : valor.fechaOrve;
        
        if (res !== '') {
            res = formatearFecha(res);
        }
        
        return res;
    }

    /**
     * @param formatearTexto Metodo utilizado para formatear el texto recibido en mayusculas
     * @param {*} texto Valor recibido a formatear
     * @returns Devuelve el texto formateado con la primera letra en mayusculas y el resto en minucsuclas
     */
    const formatearTexto = (texto) => {
        //Paso 1. Se convierte en minúsucla los valores de la key estados
        texto = texto.toLowerCase();
        //Paso 2. Se convierte en mayúscula la primera letra de los valores de la key estados
        texto = texto.charAt(0).toUpperCase() + texto.slice(1);
        return texto;
    }

    /**
     * @constant fotmatearFecha Se formatea la fecha obtenida y se formatea según el formato DD/MM/YYYY.
     * @param {*} fechaObtenida Se recibe la fecha obtenida
     * @returns Devuelve la fecha en formato DD/MM/YYYY
     */
    const formatearFecha = (fechaObtenida) => {

        var fechaDate = new Date(fechaObtenida);

        Number.prototype.padLeft = function(base,chr){
            var  len = (String(base || 10).length - String(this).length)+1;
            return len > 0? new Array(len).join(chr || '0')+this : this;
        }

        var dateFormat = [fechaDate.getDate().padLeft(),
            (fechaDate.getMonth()+1).padLeft(),
            fechaDate.getFullYear()].join('/') +' ' +
           [fechaDate.getHours().padLeft(),
            fechaDate.getMinutes().padLeft(),
            fechaDate.getSeconds().padLeft()].join(':');

        return dateFormat;
    }

    /**
     * @constant paginate Actualiza la página actual en la que se encuentra el paginador
     * @param {*} pageNumber 
     * @returns Devuelve la página anterior
     */
    const paginate = pageNumber => {
        //Paso 1. Se obtiene el numero total de paginas a mostrar
        let paginasTotales = Math.round(totalRecords / properties.RECORDS_PER_PAGE);
        //Paso 2. Se inserta en la Hook currentPage el numero de pagina actual
        let previousPage = currentPage;
        setCurrentPage(pageNumber);
        pagina = pageNumber;
        /*Paso 3. Estructura de control que evita que se pagine cuando se llega a la primera página y se quiere paginar de forma descendente
        y viceversa cuando se llega a la última página y se quiere paginar de forma ascendente. El valor que se devolverá será null.
        Si no se cumple esta condición, se realiza la llamada al endPoint para cargar los valores en al tabla según la paginación realizada y se devuelve
        el valor de la página anterior.*/
        if ((previousPage === 1 && pagina === 1) || previousPage === paginasTotales && pagina === paginasTotales) {
            previousPage = null;
            return previousPage;
        } else {
            getValoresTabla(situacionCambiada, estadoCambiado, pagina, campoOrdenado, ordenacionTipo, valorBuscado);
            return previousPage;
        }
    }

    /**
     * @constant updateFormat Método que capta el tamaño de la ventana y actualiza actualiza 
     * el hook que controla cuando se da el salto a versión movil
     */
    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    };

    /**
     * @constant funcionOrdena Método que Ordena de forma ascendente o descendente llamando al endpoint.
     */
    const funcionOrdena = (event) => {
        setCampoOrdenado(event.target.id);
        setCurrentPage(properties.INIT_PAGE);
        if (campoOrdenado != event.target.id) {
            getValoresTabla(situacionCambiada, estadoCambiado, properties.INIT_PAGE, event.target.id, properties.ascendente, valorBuscado); //ASC
            setOrdenacionTipo("ASC");
            setOrdenadoAscente(true);
        } else if (ordenacionTipo == "ASC") {
            getValoresTabla(situacionCambiada, estadoCambiado, properties.INIT_PAGE, event.target.id, properties.descendente); //DESC
            setOrdenacionTipo("DESC");
            setOrdenadoAscente(false);
        } else if (ordenacionTipo == "DESC") {
            getValoresTabla(situacionCambiada, estadoCambiado, properties.INIT_PAGE, event.target.id, properties.ascendente); //ASC

            setOrdenacionTipo("ASC");
            setOrdenadoAscente(true);
        }
    };

    /*ASC*
     * @constant getSituacion Método que obtiene la situación obtenida en el Select situación, e indica si existen estados para el Select estados.
     * En caso de que no tengan estados, seteará un valor booleano a true para indicarlo.
     * @param {*} estado Recibe un valor booleano
     */
    const getValueSituacion = (valor) => {
        setIsDisabled(valor);
    };

    /*
        Al pulsar el boton Mostar/Ocultar filtros setearemos el valor.
     */
    const mostrarOcultarFiltros = () => {
        if (mostrarFiltros) {
            setMostrarFiltros(false)
        } else {
            setMostrarFiltros(true)
        }
    };

    /**
     * @function cerrarErrorDropRejected Metodo que cierra el componente mostrar cuando se pulsa el boton aceptar
     */
     const cerrarErrorDropRejected = () => {

        setErrorDropRejected(false);
        
    };

    /**
     * @function busquedaPorBuscador Metodo intermedio que seteara el hook situacion cuando usemos el buscador
     */
     const busquedaPorBuscador = (situacion, estado, pagina, campoOrdenado, ordenacionTipo, valorBuscado) => {
        setSituacionCambiada(situacion);
        
        getValoresTabla(situacion, estado, pagina, campoOrdenado, ordenacionTipo, valorBuscado);
    };

    /**
     * @function cerrarPopUpAvisoAccion Metodo que cierra el componente popup cuando pulsamos una accion que necesite confirmacion
     */
     const cerrarPopUpAvisoAccion = () => {

        setMostrarPopUpAviso(false);
        
    };

    /**
     * @function convertirFechaADate Metodo que te devuelve una fecha en formato Date, 
     * para ello hay que pasarle la fecha en el siguiente formato: 2050-01-01T14:00:00
     */
     const convertirFechaADate = (fecha) => {

        let anyoInicio = fecha.substring(0, 4);
        let mesIncio = fecha.substring(5, 7);
        let diaIncio = fecha.substring(8, 10);
        let horaIncio = fecha.substring(11, 13);
        let minutoIncio = fecha.substring(14, 16);
        let segundoIncio = fecha.substring(17, 19);

        return new Date(anyoInicio, mesIncio, diaIncio, horaIncio, minutoIncio, segundoIncio);
        
    };

    /*
        Mostrar boton nueva solicitud
     */
        const mostrarBotonNuevaSolicitudFunction = async () => {
            if (convocatoriaSeleccionada.fechaInicioPresentacion !== undefined && convocatoriaSeleccionada.fechaFinPresentacion !== undefined) {
                let fechaActual = null;

                await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_RELOJ, { url:'', method: 'get'}).then(response => {
                    let anyoActual = (response.data).substring(15, 19);
                    let mesActual = (response.data).substring(12, 14);
                    let diaActual = (response.data).substring(9, 11);
                    let horaActual = (response.data).substring(0, 2);
                    let minutoActual = (response.data).substring(3, 5);
                    let segundoActual = (response.data).substring(6, 8);
                    fechaActual = new Date(anyoActual, mesActual, diaActual, horaActual, minutoActual, segundoActual);
                });
            
                let fechaInicio = convertirFechaADate(convocatoriaSeleccionada.fechaInicioPresentacion);
                let fechaFin = convertirFechaADate(convocatoriaSeleccionada.fechaFinPresentacion);
                const perfil = loadState(localStorageName.perfilSeleccionado, true);

                if (fechaActual > fechaInicio && fechaActual < fechaFin && perfil.descripcion !== 'REPRESENTANTE_SICA') {
                    setMostrarBotonNuevaSolicitud(true);
                }
            }
        };

    const getValoresTablaPCT3 = (datosExpedientes) => {
        crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_GESTION_DATABASE, { url: properties.URL_GET_EXPEDIENTES_INTERESADOS, method: 'post', data: { ...datosExpedientes} }).then(response => {
            let expedienteInteresado = response.data.expedienteInteresado;
            expedienteInteresado !== undefined && setListadoExpedientes(expedienteInteresado);
            let totalRecords = response.data.totalRecords;
            totalRecords !== undefined && setTotalRecords(totalRecords);
        }).catch(function (error) {
            if (error.response.status !== 404) {
                handleSetMessage(msgError.error, msgError.severity);
                setListadoExpedientes([]);
                setTotalRecords(0);
            } else {
                setListadoExpedientes([]);
                setTotalRecords(0);
            }
            setIsLoading(false);
        }).finally(function() {
            setIsLoading(false);
        });
    }

    /**
     * @function getEstadoFasePCT3 Función que obtiene el estadoFase de las convocatoria de la PCT3
     * @params Código del estadoFase a obtener
     * @returns estadoFase obtenido
     */
    const getEstadoFasePCT3 = async (codigoEstadoFase) => {
        let estadoFase = null;

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ESTADO_FASE, { url: properties.URL_GET_ESTADOFASE_X_CODIGO + codigoEstadoFase, method: 'GET'}).then(response => {
            estadoFase = response.data;
            saveState(localStorageName.idEstadoFase, estadoFase.id, false);
        }).catch(function (error) {
            if (error.response.status !== 404) {
                handleSetMessage(msgError.error, msgError.severity);
            }
        });

        return estadoFase;
    }

    /**
     * @function getFasePCT3 Función que obtiene una fase a través de su código para procedimientos de PCT3
     * @param codigoFase Código de la fase a obtener
     * @returns fase obtenida
     */
    const getFasePCT3 = async (codigoFase) => {
        let fase = null;

        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_FASE, { url: properties.URL_GET_FASE_X_CODIGO + codigoFase, method: 'GET'}).then(response => {
            fase = response.data;
            saveState(localStorageName.idFase, fase.id, false);
        }).catch(function (error) {
            if (error.response.status !== 404) {
                handleSetMessage(msgError.error, msgError.severity);
            }
        });

        return fase;
    }

    /**
     * @function seleccionarFormularioPCT3 Función que selecciona el formulario para las convocatorias de la PCT3
     * @param fase Recibe el código de la fase
     * @param estadoFase Recibe el código del estadoFase
     */
    const seleccionarFormularioPCT3 = async (fase, estadoFase) => {
        let estadoObtenido = await getEstadoFasePCT3(estadoFase);
        let faseObtenida = await getFasePCT3(fase);
        let input = {
                idConvocatoria: convocatoriaSeleccionada.id,
                idEstado: estadoObtenido.id,
                idFase: faseObtenida.id
        }
        let formularioObtenido = false;
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_MOTOR_TRAMITACION, { url: properties.URL_GET_FORMULARIO_X_CONV_X_FASE, method: 'POST', data: input}).then(response => {
            saveState(localStorageName.resourceFormNuevaSolicitud, response.data, false);
            setIsLoading(false);
            formularioObtenido = true;
        }).catch(function (error) {
            if (error.response.status === 404) {
                errorFormulario(msgError.formularioNotFound + " convocatoria " + convocatoriaSeleccionada.descripcion + " con fase "
                + faseObtenida.descripcion + " y estado " + estadoObtenido.descripcion);
            } else{
                handleSetMessage(msgError.error, msgError.severity);                
            }
            setIsLoading(false);
        });
        return formularioObtenido;
    }

    /**
     * @function crearSolicitudxOrganismo Función que crea una nueva solicitud para la PCT3 con el organismo especificado
     */
    const crearSolicitudxOrganismo = async (organismo) => {
        let expediente = null;
        let transicionObtenida = await obtenerTransicion();
        saveState(localStorageName.accion, 'nuevaSolicitud', false);
                
        transicionObtenida.estadoDestino = transicionObtenida.estadoOrigen; 
        transicionObtenida.faseDestino = transicionObtenida.faseOrigen;

        let input = {
            pendienteAlta: true,
            isFromOV3: true,
            isCrearNuevo: true,
            transicion: {...transicionObtenida},
            idOrganismo: organismo.id,
            organismo: {
                id: organismo.id,
                idOrganicoDir3: organismo.idOrganicoDir3
            },
            objetoTramitable: {
                convocatoria: {...convocatoria}
            }
        }
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DATO_EXPEDIENTE, { url: '/', method: 'post', data: input }).then(response => {
            expediente = response.data;
            let json = JSON.stringify(expediente);
            saveState(localStorageName.solicitudActual, json, false);
        }).catch(() => {
            handleSetMessage(msgError.error, msgError.severity);
        });
        expediente.id !== null && await asignarInteresado(expediente.id);

        await seleccionarFormulario(properties.FASE_INICIO_PCT3, properties.ESTADO_FASE_INICIO_PCT3);
    }

    /**
     * @function obtenerTransicion Función que obtiene el objeto transición
     * @returns 
     */
    const obtenerTransicion = async () => {
        let transicion = null, codigoTransicion = 'INI0001', idConvocatoria = convocatoria.id;
        
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_TRANSICIONS, { url: `/getTransicionInicioSedeElec/transicion/${codigoTransicion}/convocatoria/${idConvocatoria}`, method: 'GET' }).then(response => {
            transicion = response.data;
        }).catch(() => {
            handleSetMessage(msgError.error, msgError.severity);
        });

        return transicion;
    }    

    /**
     * @function asignarInteresado Función que asigna el interesado al expediente creado
     */
    const asignarInteresado = async (idExpediente) => {
        let dcInteresado = await obtenerInteresado();
        //Se inserta el idDatoExpediente
        Object.assign(dcInteresado, {'idDatoExpediente': idExpediente});
        dcInteresado.id = null;
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DATOS_CONTACTO, { url: '/', method: 'POST', data: dcInteresado }).then(response => {
        }).catch(() => {
            handleSetMessage(msgError.error, msgError.severity);
        });
    }

    /**
     * @function obtenerInteresado  Función que obtiene los datos de contacto por defecto del interesado logado
     * @return Devuelve un objeto con los datos de contacto por defecto del interesado
     */
    const obtenerInteresado = async () => {
        let dc = null;
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DATOS_CONTACTO, { url: properties.URL_GET_INTERESADO_BY_IDENTIFICADOR + '/' + datosUsuarioInteresado.interesadoNif, method: 'get' }).then(response => {
            dc = response.data;
        }).catch(function (error) {
            handleSetMessage(msgError.error, msgError.severity);
        });
        return dc;
    }

    /**Método que actualiza el numero de filas que muestra la tabla*/
    const handleChangePager = (event) => {
        setAnchorEl(event.currentTarget);
        setNumMostrar(event.target.value);
        setCurrentPage(properties.INIT_PAGE);
      };

    /**
     * Método que se lanzará cuando no haya un formulario configurado
     */
    const errorFormulario = (msg) => {        
        setMensajeError(msg);
        setErrorDropRejected(true);
    }

    const pressBuscador  = (e) => {
        e.key === "Enter" && refBuscador.current.click();
    }

    /**
     * @function obtenerFasesEstados Función que obtiene los códigos de las fases y los estados buscados
     * @returns 
     */
    const obtenerFasesEstados = async () => {
        let constantes = [];

        // Paso 1. Obtiene las constantes decalradas en BandejaSolicitudesData y se insertan en el array cosntates.
        for (const key in constantesEstadosFasePCT3) {
            constantes.push(key);
        }

        // Paso 2. Se obtienen las fases y estados 
        let fasesEstadosObtenidos = await obtenerCodigosFaseEstado(convocatoriaSeleccionada.id, constantes, constantes);        
        
        // Paso 3. Se recorren los valores obtenidos y se insertan en las constantes que se utilizaran en la pantalla
        for (const feo of fasesEstadosObtenidos.valores) {
            switch (feo.clave) {
                case 'EF_INICIADO_OV3':
                    constantesEstadosFasePCT3.EF_INICIADO_OV3 = feo.valor;
                    break;
                case 'EF_PENDIENTE_ALEGACION_OV3':
                    constantesEstadosFasePCT3.EF_PENDIENTE_ALEGACION_OV3 = feo.valor;
                    break;
                case 'EF_PENDIENTE_SUBSANAR_OV3':
                    constantesEstadosFasePCT3.EF_PENDIENTE_SUBSANAR_OV3 = feo.valor;
                    break;
                case 'EF_PEND_RL_SUB_OV3':
                    constantesEstadosFasePCT3.EF_PEND_RL_SUB_OV3 = feo.valor;
                    break;                    
                case 'F_INICIO_OV3':
                    constantesEstadosFasePCT3.F_INICIO_OV3 = feo.valor;
                    break;
                case 'F_ALEGACIONES_OV3':
                    constantesEstadosFasePCT3.F_ALEGACIONES_OV3 = feo.valor;
                    break;
                case 'F_SUBSANACION_OV3':
                    constantesEstadosFasePCT3.F_SUBSANACION_OV3 = feo.valor;
                    break;
                default:
                    break;
            }
        }
    }

    const obtenerFaseYestado = async (idDatoExpediente) => {
        (idDatoExpediente !== undefined && idDatoExpediente !== null) &&
            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_FASE_ESTADO, { url: `${properties.URL_FASE_ESTADO}/${idDatoExpediente}` , method: 'GET' }).then(response => {
                let idFaseIdEstado = {
                    idFase : response.data.fase.id,
                    idEstado : response.data.estadoFase.id,
                }
                saveState(localStorageName.fase, response.data.fase, true);
                saveState(localStorageName.estado, response.data.estadoFase, true);
                saveState(localStorageName.faseEstadoExpediente, idFaseIdEstado, true);
            }).catch(() => {
                handleSetMessage(msgError.error, msgError.severity);
            });
    }

        /**
     * @function isSolicitudColaborativa Función que comprueba si se trata de una solicitud que pertenezca a un proyecto colaborativo.
     * Para que sea una solicitud colaborativa, la convocatoria debe ser colaborativa y además cuando se ha accedido a la convocatoria,
     * se ha seleccionado el perfil colaborativo.
     */
    const isSolicitudColaborativa = async () => {
        // Paso 1. Se obtienen las convocatorias y los perfiles colaborativos
        let convocatoriasColaborativas = convocatoriasColaborativasCodigo;
        let perfilesColaborativos = convocatoriasColaborativasCodigo;
        let isConvocatoriaColaborativa;
        let isPerfilColaborativo;

        // Paso 2. Se genera el array de cada uno
        let perfilesObtenidos = perfilesColaborativos !== undefined ? perfilesColaborativos.split(', ') : null;
        let convocatoriasObtenidas = convocatoriasColaborativas !== undefined ? convocatoriasColaborativas.split(', ') : null;

        // Paso 3. Se evalúa si la convocatoria actual y el perfil seleccionado pertenecen a convocatorias y perfiles colaborativos
        if (perfilesObtenidos !== null && convocatoriasObtenidas !==  null) {
            isPerfilColaborativo = perfilesObtenidos.includes(perfilSeleccionado.descripcion);
            isConvocatoriaColaborativa = convocatoriasObtenidas.includes(convocatoriaSeleccionada.codigo);
        }

        return isPerfilColaborativo && isConvocatoriaColaborativa;
    }

    const guardarOrganismoExpediente = async (idDatoExpediente) => {
             //Obtención del objeto datoExpedienteDto con el organismo
            await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_DATO_EXPEDIENTE, { url: `/ov3/${idDatoExpediente}`, method: 'GET' }).then(response => {
                if(response?.data?.organismo){
                    saveState(localStorageName.organismo, response.data.organismo, true);
                }else{
                    handleSetMessage('Se ha producido al obtener los datos del expediente', msgError.severity);
                }
            }).catch(() => {
                handleSetMessage('Se ha producido al obtener los datos del expediente', msgError.severity);
            });
    }

    return {
        tituloPagina, situacionExp, estadoExp, selectSituacion, menuItem, selectEstado, buscador, isDisabled,
        handleChangeSituacion, handleChangeEstado, handleChangeBuscador, handleChangePager,
        headerTableObject, dataTableObject,
        getSituacion, getEstados, getValoresTabla, crearNuevaSolicitud, seleccionarFormulario,
        totalRecords, currentPage, paginate, valorBuscado,
        isLoading, isPhoneVersion, updateFormat, funcionOrdena,
        ordenacionTipo, campoOrdenado, alerts, message, setMessage, refEstado, formatearTexto, mostrarFiltros, setMostrarFiltros, 
        mostrarOcultarFiltros, setIsLoading, cerrarErrorDropRejected, errorDropRejected, mensajePopUp, busquedaPorBuscador, mensajeError,
        mostrarPopUpAviso, accionBorrar, cerrarPopUpAvisoAccion, mensajeTitulo, mostrarBotonNuevaSolicitudFunction, mostrarBotonNuevaSolicitud,
        anchorEl, numMostrar,refBuscador, pressBuscador, obtenerFasesEstados, 
        showOrgSelector, listadoOrganismos, handleChangeOrganismo, handleIniciarSolicitud, handleCancelarSolicitud
    };
}
export default useOperations;
