import React from 'react';
import { Grid, LinearProgress, MuiThemeProvider } from '@material-ui/core';
import useStyles from './lineaProgresoStyle';
export default function LineaProgreso() {

    const { style, theme } = useStyles();
    
    const container = style().classGridContainer;
    const root = style().classGridRoot;
    const line = style().classGridLine;
    const title = style().classTitle;
    const state = style().classGridState;

    return (
        <Grid className={root}>
            <Grid className={container}>
                <Grid className={line}>
                    <h5 className={title}>Se está realizando la generación del formulario PDF de su solicitud</h5>
                    <Grid className={state}>
                        <MuiThemeProvider theme={theme}>
                            <LinearProgress/>
                        </MuiThemeProvider>                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}