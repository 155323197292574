import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { customStyle } from './HeaderStyle'
import { texts, properties, mockRRSS, headerLinks } from './HeaderData'
import { properties as appProperties, compProperties, texts as appTexts } from '../../../AppData';
import Reloj from '../reloj/Reloj';
import TabMenu from '../tabMenu/TabMenu';
import Breadcrumbs from 'components/commons/breadcrumb/breadcrumb';
import HamburgerMenu from '../hamburgerMenu/HamburgerMenu';
import { Grid, Link } from '@material-ui/core';
import MenuCuenta from '../../commons/menuCuenta/menuCuenta';
import PopUp from '../../commons/popUp/popUp';
import MenuUsuario from '../../pages/menuUsuario/menuUsuario';
import Confirmacion from '../../commons/Confirmacion/Confirmacion';
import { loadState, saveState } from './../../../providers/localStorage';
import { localStorageName } from './../../../providers/localStorageData';
import AuthProvider from './../../../providers/authProvider';
import useApp from 'AppFunctions';
import AppContext from 'AppContext';
import PopUpHerramientas from '../popUpHerramientas/PopUpHerramientas';
import { getUrl } from 'config/Config';
import RestProvider from 'providers/RestProvider';

export default function Header() {
    const { loginClave } = useApp();
    const {obtenerProperties} = RestProvider();
    const [consejeria, setConsejeria] = useState(null);
    const [isPhoneVersion, setIsPhoneVersion] = useState();
    const history = useHistory();
    const [menuMiCuenta, setMenuMiCuenta] = useState([]);
    const [mostrarConfirmacion, setMostrarConfirmacion] = useState(false);
    const context = useContext(AppContext);
    const { logout } = AuthProvider();
    const [rrSs, setRrss] = useState(mockRRSS);
    const [nombreConsejeria, setNombreConsejeria] = useState();
    const [tlfProcedimiento, setTlfProcedimiento] = useState();
    const [nombreAyuntamiento, setNombreAyuntamiento] = useState('Ayuntamiento de Córdoba');
    
    const MOSTRAR_AYUDA_USUARIO = false; //A petición del cliente se oculta el popUp ayuda usuario temporalmente

    const addProperties = async () => {      
        const propertiesList = [
            'consejeria.nombre', 'rrss.url.facebook', 'rrss.url.instagram',
            'rrss.url.twitter', 'rrss.url.youtube', 'rrss.url.flickr'
        ];
    
        try {
            let properties = await obtenerProperties(propertiesList);    
            // Actualiza el estado
            setConsejeria(properties['consejeria.nombre']);
            setNombreConsejeria(properties['consejeria.nombre']);
            loadCloudInfo(properties);
        } catch (error) {
            console.error('error obteniendo las properties', error);
        }
    };
    
    const loadCloudInfo = (properties) => {
        mockRRSS.rowList[0].link = properties['rrss.url.twitter'];
        mockRRSS.rowList[1].link = properties['rrss.url.facebook'];
        mockRRSS.rowList[2].link = properties['rrss.url.instagram'];
        mockRRSS.rowList[3].link = properties['rrss.url.youtube'];
        mockRRSS.rowList[4].link = properties['rrss.url.flickr'];
    
        setRrss(mockRRSS);
    };
    
    useEffect(() => {
        addProperties();
    }, []);

    useEffect(() => {
        if (context.sesion === appProperties.SESION_CADUCADA) {
            setMostrarConfirmacion(true);
        } else {
            isLogeado();
        }
        updateFormat();
    }, [context.sesion]);

    //Funcion para cerrar sesion
    const closeSession = async () => {

        localStorage.removeItem(localStorageName.redirect);
        localStorage.removeItem(localStorageName.origenLogin);
        saveState(localStorageName.loginOut, true, false);

        await logout();
        setMostrarConfirmacion(false);
        setMenuMiCuenta(true);

        var usuarioClave = loadState(localStorageName.infoUsuario, true);
        if (usuarioClave.tipoAutenticacion == "cl") {
            localStorage.removeItem(localStorageName.infoUsuario);
            loginClave(properties.URL_LOGOUT);
        } else {
            localStorage.removeItem(localStorageName.infoUsuario);
            context.updateSesion(appProperties.SESION_CIERRE)
            document.location.href = getUrl();
        }
    }

    const isLogeado = () => {
        var usuarioClave = loadState(localStorageName.infoUsuario, true);

        if (usuarioClave == undefined) {
            setMenuMiCuenta(true);
        } else {
            setMenuMiCuenta(false);
        }
    }
    const style = customStyle();

    const sesionCaducadaMostrarConfirmacion = accion => {
        setMostrarConfirmacion(accion);
    }

    /**
     * Metodo que renderiza de forma dinamica los iconos de las RRSS
     */
    const renderRRSS = () => {
        return (
            <ul className={style.lista}> {
                rrSs && rrSs.rowList.map((row) => {

                    let dotIndex = row.text.indexOf('.');
                    let altText = row.text.substring(0, dotIndex);
                    if (altText === "x_logo") {
                        altText = "X";
                    }

                    return (
                        <li className={style.elementoLista}>
                            <a target="_blank" className={style.linkRrss} href={row.link} title={properties.TITLE_RRSS + altText}>
                                <img className={style.iconoRRSS} src={row.text} alt={altText} />
                            </a>
                        </li>
                    )
                })}
            </ul>
        )
    }


    /**Método que capta el tamaño de la ventana y actualiza actualiza
    ***el hook que controla cuando se da el salto a versión movil*/
    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    };

    const procedimientoActual = loadState(localStorageName.procedimientoActual, true);

    /**
     * Efecto creado para mostrar el número de teléfono, el nombre de la consejería y el logotipo de la consejería
     * asociado al procedimiento seleccionado.
     */
    /**
     * Se comenta porque actualmente no se está ejecutando 
  useEffect(() => {
        if (procedimientoActual !== null && procedimientoActual !== undefined) {
            getConfiPorProcedimiento(procedimientoActual.idTipoProcedimiento);
        } else {
            setTlfProcedimiento(null);
            setNombreConsejeria(consejeria);
        }
    }, [procedimientoActual]);

     */
  

    function transformarTexto(cadena) {
        const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };
        // Paso 1. Elimina los espacios en blanco y los sustituye por un _ y establece todas las letras en mayúsculas
        cadena = cadena.replace(/\s+/g, '_').toLocaleUpperCase();
        // Paso 2. Elimina los acentos de las letras que lo contengan
        return cadena.split('').map(letra => acentos[letra] || letra).join('').toString();
    }
    /**
     * @function getConfiPorProcedimiento Función que obtiene la configuración del procedimiento seleccionado
     * @param {*} idProc Id del procedimiento seleccionado
     */
    // 21-feb-2025 se comenta la obtencion de properties ya que el método no se usa
    const getConfiPorProcedimiento = (idProc) => {
        // Paso 1. Se obtienen los tipos de procedimientos obtenidos
        let tipoProcedimientosObtenidos = loadState(localStorageName.tipoProcedimientos, true);

        if (tipoProcedimientosObtenidos !== null && tipoProcedimientosObtenidos !== undefined) {
            // Paso 2. Se recorren los tipos de procedimientos obtenidos y se evalúa si su id conincide con el id del procedimiento seleccionado
            for (const procedimiento of tipoProcedimientosObtenidos) {
                if (procedimiento.id === idProc) {
                    /**
                     * Paso 3. Se obtiene la descripción del procedimiento seleccionado y se obtiene
                     * las propiedades de cloudconfig que contiene el número de tlf, nombre de consejería, logo y logo móvil.
                     * En caso de que no exista una propiedad en cloudConfig no se setea.
                     */

                    // esta propertie no se está usando ya que nunca entra en este bucle
                    // Teléfono
                    //let propertyTlf = 'TELEFONO_SAC_' + transformarTexto(procedimiento.descripcion);
                    //let tlfCloudConfig = propertiesCloudConfig[propertyTlf];
                    //tlfCloudConfig && setTlfProcedimiento(tlfCloudConfig);
                    // esta propertie no se está usando ya que nunca entra en este bucle
                    // Nombre consejería
                    //let propertyNombreConsejeria = 'CONSEJERIA_NOMBRE_' + transformarTexto(procedimiento.descripcion);
                    //let nombreConsejeriaCloudConfig = propertiesCloudConfig[propertyNombreConsejeria];
                    //nombreConsejeriaCloudConfig && setNombreConsejeria(nombreConsejeriaCloudConfig);

                    break;
                }
            }
        }
    }

    window.addEventListener('resize', updateFormat);

    return (
        <header>
            <nav aria-label={texts.ALABEL_NAV}>
                {/*FRANJA NEGRA*/}
                {!isPhoneVersion && <Grid container className={style.gridSeccionUno} direction='row' justify='flex-end' alignItems='center'>

                    <Grid container className={style.gridSeccionUnoContacto} alignItems='center'>
                        <Grid container className={style.gridContacto}>
                            {headerLinks.map((data) => (
                                <Link target="_blank" href={data.href} alt={data.text} className={style.textoHeader}>
                                    {data.text}
                                </Link>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid container className={style.gridSeccionUnoRRSS}>
                        {renderRRSS()}
                    </Grid>

                </Grid>}
            </nav>

            {/*FRANJA BLANCA*/}
            <Grid container className={isPhoneVersion ? style.gridSeccionDosPhone : style.gridSeccionDos}>

                {/*SUPERIOR*/}
                <Grid container className={isPhoneVersion ? style.gridSeccionDosArribaPhone : style.gridSeccionDosArriba} >
                    <Grid container className={style.gridSeccionDosArribaInterior}>
                        {/*IZQUIERDA*/}
                        <Grid container className={style.gridSeccionDosArribaJA}>
                            <Grid container className={style.gridLogoJA}>
                                {
                                    isPhoneVersion ?
                                        <button className={style.botonHome}>
                                            <a href='/' className={style.enlaceSinBorder}>
                                                <img className={style.iconoJAPhone} src={properties.AC_ICON} alt={texts.ALT_JA + '' + nombreConsejeria} />
                                            </a>
                                        </button> 
                                        :
                                        <button className={style.botonHome}>
                                            <a href='/' className={style.enlaceSinBorder}>
                                                <img className={style.iconoJA} src={properties.AC_ICON} alt={texts.ALT_JA + '' + nombreConsejeria} />
                                            </a>
                                        </button>
                                }

                            </Grid>
                        </Grid>

                        {/*DERECHA*/}
                        {
                            !isPhoneVersion ? <TabMenu /> : <HamburgerMenu isLogeado={menuMiCuenta} handleChange={closeSession} />
                        }
                    </Grid>

                </Grid>


                {/*IZQUIERDA*/}
                {isPhoneVersion && <Grid container className={style.gridSeccionDosAbajo} direction='row' justify='space-between'>
                    <Grid container className={style.gridSeccionDosAbajoBreadcrumbs} alignItems='center'>
                        <Breadcrumbs></Breadcrumbs>
                    </Grid>
                </Grid>}

                {/*INFERIOR*/}
                {!isPhoneVersion && <Grid container className={style.gridSeccionDosAbajo} direction='row' justify='space-between'>

                    {/*IZQUIERDA*/}
                    <Grid container className={style.gridSeccionDosAbajoBreadcrumbs} alignItems='center'>
                        <Breadcrumbs />
                    </Grid>

                    {/*DERECHA*/}
                    <Grid container className={style.gridSeccionDosAbajoDerecha} direction='row' alignItems='center'>
                        {/*TELEFONO*/
                            (tlfProcedimiento !== null && tlfProcedimiento !== undefined) &&
                            <Grid className={style.classGridTlf}>
                                <Grid item title={texts.ATENCION_CIUDADANA}>
                                    <img className={style.iconoTelefono} src={properties.TLFN_ICON_PATH} alt={texts.ALT_TLFNO} />
                                </Grid>
                                <Grid item title={texts.ATENCION_CIUDADANA}>
                                    <span className={style.telefono}>{tlfProcedimiento}</span>
                                </Grid>
                            </Grid>
                        }
                        { MOSTRAR_AYUDA_USUARIO ? 
                            <Grid item className={style.ayuda} title={texts.AYUDA_USUARIO}>
                                <PopUp/> 
                            </Grid> : null
                        }                        
                        <Grid item className={style.ayuda} title={texts.HERRAMIENTAS_ENLACES}>
                            <PopUpHerramientas />
                        </Grid>

                        {menuMiCuenta ? (
                            <Grid item>

                                <MenuCuenta />
                            </Grid>
                        ) : (
                            <Grid item>

                                <MenuUsuario handleChange={closeSession} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>}
            </Grid>
            <Confirmacion handleChange={sesionCaducadaMostrarConfirmacion} mostrar={mostrarConfirmacion} isPhoneVersion={isPhoneVersion}
                titulo={'Sesión Caducada'}
                mensaje={'Su sesión ha expirado'}
                botonera={compProperties.CONFIR_SOLO_ACEPTAR}
                accionConfirmacion={closeSession}
                tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_10}
                isPopUpPdf={false}
                textBotonConfirmarPersonalizado={false}
                isImagenPersonalizada={false}
                centrarContenido={true}
                isTextoEnriquecido={true} />
            {/*FRANJA VERDE*/}
            {!isPhoneVersion && <Grid container className={style.gridSeccionTres} direction='row' justify='space-between'>
                <Grid container className={style.gridSeccionTresInterior} >
                    {/*IZQUIERDA*/}
                    <Grid container className={style.gridSeccionTresTitulos} direction='row' alignItems='center'>
                        <Grid item className={style.gridOvTexto}>
                            <span className={style.ovTexto}>{texts.OV}</span>
                        </Grid>
                    </Grid>

                    {/*DERECHA*/}
                    <Reloj />
                </Grid>



            </Grid>}
        </header>
    )
}
