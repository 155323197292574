export const properties = {
    ALT_ICONO_PROCEDIMIENTO: 'Ir al procedimiento ',
    CAPTION_TABLE: 'Listado de procedimientos',
    MAX_INFO: 40,
    TEXTO_MAX_INFO: '...',
    TEXTO_NO_INFO: 'Sin Información',
    RESOURCE_CONVOCATORIAS: 'convocatoria',
    RESOURCE_PROCEDIMIENTOS: '/procedimiento/',
    RESOURCE_GET_CONVOCATORIAS: '/getConvocatorias/',
}

export const cabeceras = [
    "Nombre",
//  "Estado", OCULTAMOS CABECERA ESTADO
    "Familia", //Tipo
    "Descripción", //Información
    "Acciones",
]