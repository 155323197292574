import React, {useEffect, useState} from 'react';
//Componentes importados desde material ui
import { Grid, Backdrop, CircularProgress} from '@material-ui/core';
//Estilo importado
import {useStyles} from './PageAltaUsuarioStyle';
//Fichero data importado
import { texts, objComponentFormio, propertiesPCT3} from './pageAltaUsuarioData';
import {AltaEdicionUsuario} from './pageAltaUsuarioFunctions';
import { Snackbar } from "material";
import { Alert } from "@material-ui/lab";
import { loadState} from './../../../providers/localStorage';
import RestProvider from 'providers/RestProvider';
import { localStorageName } from './../../../providers/localStorageData';
import ComponenteGenericoFormio from 'components/commons/componenteGenericoFormio/componenteGenericoFormio';

/**
 * @function AltaUsuario función que contiene la pantalla de alta de usuario y es devuelta
 */
export default function AltaUsuario() {

    const [submissionData, setSubmissionData] = useState({});      
    const usuarioClave = loadState(localStorageName.infoUsuario, true);
    const { crudOperation, crudOperationsType } = RestProvider();

    const { handleOnSubmit, handleCustomEvents, isLoading, alerts, message, setMessage, formData,
         getResource, setAlerts, setHayRepresentante } = AltaEdicionUsuario();

    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);

    let _ID = 0;
    let interesado = null;
    let hayRepresentante;

    const { style } = useStyles();
    const customStyle = style();

    // Obtenemos el formulario en la carga inicial de la página
    useEffect(() => {
        getResource(objComponentFormio.aliasFormulario);
        cargarDatosClaveCertificado();
    }, []);

    const cargarDatosClaveCertificado = async () => {
        let nifInteresado = usuarioClave.interesadoNif;

        //Es representante
        if (usuarioClave.entidadCif !== null && usuarioClave.entidadCif !== undefined) {                       
            setHayRepresentante(true);
            hayRepresentante = true;
        }else{
            setHayRepresentante(false);
            hayRepresentante = false;
        }

        const infoUsuario = {
            interesadoNif: usuarioClave.interesadoNif,
            entidadCif: usuarioClave.entidadCif
        };

        await getInteresadoPorDefectoPCT3(infoUsuario);

        let precargaDatos = true;
        const datosForm = {
            data: {
              componentesPersonales: {
                dNINIENIF: nifInteresado,
                nombreRazonSocialDenominacion: interesado?.interesado1?.interesado?.nombre || usuarioClave?.interesadoNombre,
                primerApellido: interesado?.interesado1?.interesado?.apellido1 || usuarioClave?.interesadoApellido1,
                segundoApellido: interesado?.interesado1?.interesado?.apellido2 || usuarioClave?.interesadoApellido2,
              },
              componentesDireccion:{},
              componentesDatosRepresentante:{},
              componentesDireccionRepresenante: {}
            }
          };

        if (usuarioClave.entidadCif !== null && usuarioClave.entidadCif !== undefined) { 
            datosForm.data.componentesDatosRepresentante.
            nombreRazonSocialDenominacionRepresentante = usuarioClave.entidadNombre;
            datosForm.data.componentesDatosRepresentante.
            dNINIENIFRepresentante = usuarioClave.entidadCif;
        }

        if(interesado !== null && interesado !== undefined){
            //Interesado
            const idInteresado = interesado.interesado1.interesado.id;
            const idContacto = interesado.interesado1.datosContacto.id;
            const telefono = interesado.interesado1.datosContacto.telefono;
            const telefonoMovil = interesado.interesado1.datosContacto.telefonoMovil;
            const correoElectronico = interesado.interesado1.datosContacto.email;
            const fechaNacimiento =  interesado.interesado1.interesado.fechaNacimiento;
            let sexo = interesado.interesado1.interesado.sexo;
            sexo = sexo === "Hombre" ? "H" : (sexo === "Mujer" ? "M" : sexo);
            const nombreVia = interesado.interesado1.datosContacto.nombreVia;
            const numero = interesado.interesado1.datosContacto.numero;
            const letra = interesado.interesado1.datosContacto.letra;
            const escalera = interesado.interesado1.datosContacto.escalera;
            const piso = interesado.interesado1.datosContacto.piso;
            const puerta = interesado.interesado1.datosContacto.puerta;
            const codigoPostal = interesado.interesado1.datosContacto.postal;
            const idPais = interesado.interesado1.datosContacto.idPais;
            const idProvincia = interesado.interesado1.datosContacto.idProvincia;
            const idMunicipio = interesado.interesado1.datosContacto.idMunicipio;
            const idTipoVia = interesado.interesado1.datosContacto.tipoVia.id;

            //Datos personales
            datosForm.data.componentesPersonales.telefonoFijo = telefono;
            datosForm.data.componentesPersonales.telefonoMovil = telefonoMovil;
            datosForm.data.componentesPersonales.correoElectronico = correoElectronico;
            datosForm.data.componentesPersonales.sexo = sexo;
            datosForm.data.componentesPersonales.fechaNacimiento = fechaNacimiento;            
            //Datos dirección
            datosForm.data.componentesDireccion.tipoVia = {
                id: idTipoVia,
            }
            datosForm.data.componentesDireccion.paisSelector= {
                id: idPais,
            };       
            datosForm.data.componentesDireccion.provinciaSelector = {
                id: idProvincia,
                pais: {
                    id: idPais,
                }
            };
            datosForm.data.componentesDireccion.municipioSelector = {
                id: idMunicipio,
                provincia: {
                    id: idProvincia,
                    pais: {
                        id: idPais,
                    }
                }
            };
            datosForm.data.componentesDireccion.nombreDeLaVia = nombreVia;
            datosForm.data.componentesDireccion.numero = numero;
            datosForm.data.componentesDireccion.letra = letra;
            datosForm.data.componentesDireccion.escalera = escalera;
            datosForm.data.componentesDireccion.piso = piso;
            datosForm.data.componentesDireccion.puerta = puerta;
            datosForm.data.componentesDireccion.postal = codigoPostal;
            datosForm.data.componentesPersonales.idInteresado = idInteresado;
            datosForm.data.componentesDireccion.idDatosInteresado = idContacto;           
            //Representante            
            if(interesado.interesado2 !== null && interesado.interesado2 !== undefined){
                precargaDatos = false;
                const represenante = interesado.interesado2;

                const idRepresentante = represenante.interesado.id
                const idContactoRepresentante = represenante.datosContacto.id
                const idTipoViaRepre = represenante.datosContacto.tipoVia.id
                const idPaisRepre = represenante.datosContacto.idPais
                const idProvinciaRepre = represenante.datosContacto.idProvincia
                const idMunicipioRepre = represenante.datosContacto.idMunicipio
                //Datos                
                datosForm.data.componentesDatosRepresentante.
                correoElectronicoRepresentante = represenante.datosContacto.email;
                datosForm.data.componentesDatosRepresentante.
                telefonoMovilRepresentante = represenante.datosContacto.telefonoMovil;
                //Datos dirección
                datosForm.data.componentesDireccionRepresenante.
                numeroRepresentante = represenante.datosContacto.numero;
                datosForm.data.componentesDireccionRepresenante.
                pisoRepresentante = represenante.datosContacto.piso;
                datosForm.data.componentesDireccionRepresenante.
                puertaRepresentante = represenante.datosContacto.puerta;
                datosForm.data.componentesDireccionRepresenante.
                letraRepresentante = represenante.datosContacto.letra;
                datosForm.data.componentesDireccionRepresenante.
                escaleraRepresentante = represenante.datosContacto.escalera;
                datosForm.data.componentesDatosRepresentante.
                telefonoFijoRepresentante = represenante.datosContacto.telefono;                  
                datosForm.data.componentesDireccionRepresenante.tipoViaRepresentante = {
                    id: idTipoViaRepre,
                }
                datosForm.data.componentesDireccionRepresenante.paisSelectorRepresentante= {
                    id: idPaisRepre,
                };       
                datosForm.data.componentesDireccionRepresenante.provinciaSelectorRepresentante = {
                    id: idProvinciaRepre,
                    pais: {
                        id: idPaisRepre
                    }
                };
                datosForm.data.componentesDireccionRepresenante.municipioSelectorRepresentante = {
                    id: idMunicipioRepre,
                    provincia: {
                        id: idProvinciaRepre,
                        pais: {
                            id: idPaisRepre,
                        }
                    }
                };
                datosForm.data.componentesDireccionRepresenante.
                postalRepresentante = represenante.datosContacto.postal;
                datosForm.data.componentesDireccionRepresenante.
                nombreDeLaViaRepresentante = represenante.datosContacto.nombreVia;
                datosForm.data.componentesDatosRepresentante.idRepresentante = idRepresentante;
                datosForm.data.componentesDireccionRepresenante.idDatosRepre = idContactoRepresentante;
            }
        }else{
            datosForm.data.componentesDireccion.paisSelector= {
                id: 47,
            };  
        }
        if(hayRepresentante){            
            datosForm.data.componentesPersonales.hayRepresentante = "true";
            if(precargaDatos){
                datosForm.data.componentesDatosRepresentante.
                telefonoMovilRepresentante = "+034 ";
                datosForm.data.componentesDatosRepresentante.
                telefonoFijoRepresentante = "+034 ";
            }         
        }else{
            datosForm.data.componentesPersonales.hayRepresentante = "false";
        }         
        setSubmissionData(datosForm);
    }


    const getInteresadoPorDefectoPCT3 = async (infoUsuario) => {
        if (infoUsuario !== null || infoUsuario !== undefined) {            
            let interesadoBuscado = {
                identificador: infoUsuario.interesadoNif,
                identificadorEntidad: infoUsuario.entidadCif
            };
            await crudOperation(crudOperationsType.CUSTOM, propertiesPCT3.resource.interesdado,
                 { url: propertiesPCT3.url.interesadoDatosContactoPorDefecto, method: 'post', data: interesadoBuscado })
                 .then(response => {
                interesado = response.data;
            }).catch(function (error) {
                error.response.status != 404 && handleSetMessage("Ha ocurrido un error al intentar recuperar los datos del interesado.", 'error');
            });         
        }
    }

    //**Gestiona los mensajes de por pantalla para errores, info, etc */
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }    
    
    return(
        <main>
            <Grid className='componenteGenericoFormio'>
                <Grid className={customStyle.gridTitulo}>
                    <h1 className={customStyle.tituloPagina}>{texts.PAGE_TITLE}</h1>
                </Grid>
                <Grid className={customStyle.backgroundRectangle}>
                <ComponenteGenericoFormio {...objComponentFormio} submission={submissionData}
                     onSubmit={(data) => handleOnSubmit(data, true)} onCustomEvent={(event) => handleCustomEvents(event, true)} />                
                </Grid>
            </Grid>
            <Backdrop className={customStyle.classBackdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {alerts.map(m => (
                <Snackbar open={message.msg} autoHideDuration={5000} onClose={() => setMessage({ severity: "", msg: "" })}>
                    <Alert
                        severity={message.severity}
                        variant="filled"
                    >
                        {message.msg}
                    </Alert>
                </Snackbar>
            ))}            
        </main>
    );
}
