import React, { useEffect } from 'react';
import { Form } from '@formio/react';
import useOperations from './componenteGenericoFormioFunctions';
import formioFunctions from '../Precargas/FormIoFunctions';
import validacionesFormIoFunctions from '../validacionesFormIO/validacionesFormioFunctions';

export default function ComponenteGenericoFormio(props) {
    const { formData, getResource } = useOperations();
    const { obtenerUrlContexto } = formioFunctions();
    const { functionValidadorRegex } = validacionesFormIoFunctions();


    useEffect(() => {
        getResource(props.aliasFormulario);
    }, []);

    return (
        <Form
            form={formData}
            onSubmit={props.onSubmit}
            options={{
                ov: true,
                language: 'sp',
                i18n: {
                    sp: {
                        ...props.sp,
                        unsavedRowsError: 'Por favor, guarde todas las filas antes de continuar.',
                        invalidRowsError: 'Por favor, corrija las filas inválidas antes de continuar.',
                        invalidRowError: 'Fila inválida. Por favor corríjala o elimínela.',
                        invalidOption: '{{field}} es un valor inválido.',
                        invalidDay: '{{field}} no es un día válido.',
                        required: '{{field}} es obligatorio',
                        unique: '{{field}} debe ser único',
                        array: '{{field}} debe ser un array',
                        array_nonempty: '{{field}} debe ser un array no vacío', // eslint-disable-line camelcase
                        'Type to search': 'Escriba para buscar',
                        nonarray: '{{field}} no debe ser un array',
                        select: '{{field}} contiene una selección inválida',
                        pattern: '{{field}} no coincide con el patrón {{pattern}}',
                        minLength: '{{field}} debe tener al menos {{length}} caracteres.',
                        maxLength: '{{field}} no debe tener más de {{length}} caracteres.',
                        minWords: '{{field}} debe tener al menos {{length}} palabras.',
                        maxWords: '{{field}} no debe tener más de {{length}} palabras.',
                        min: '{{field}} no puede ser menor que {{min}}.',
                        max: '{{field}} no puede ser mayor que {{max}}.',
                        maxDate: '{{field}} no debe contener una fecha posterior a {{- maxDate}}',
                        minDate: '{{field}} no debe contener una fecha anterior a {{- minDate}}',
                        maxYear: '{{field}} no debe contener un año mayor que {{maxYear}}',
                        minSelectedCount: 'Debe seleccionar al menos {{minCount}} elementos',
                        maxSelectedCount: 'Solo puede seleccionar hasta {{maxCount}} elementos',
                        minYear: '{{field}} no debe contener un año menor que {{minYear}}',
                        invalid_email: '{{field}} debe ser un correo electrónico válido.',
                        invalid_url: '{{field}} debe ser una URL válida.', 
                        invalid_regex: '{{field}} no coincide con el patrón {{regex}}.', 
                        invalid_date: '{{field}} no es una fecha válida.', 
                        invalid_day: '{{field}} no es un día válido.', 
                        invalidValueProperty: 'Propiedad de valor inválida',
                        mask: '{{field}} no coincide con la máscara.',
                        valueIsNotAvailable: '{{ field }} es un valor inválido.',
                        captchaTokenValidation: 'ReCAPTCHA: Error en la validación del token',
                        captchaTokenNotSpecified: 'ReCAPTCHA: No se especificó el token en la solicitud',
                        captchaFailure: 'ReCAPTCHA: No se encontró el token de respuesta',
                        time: '{{field}} no es una hora válida.',
                        invalidDate: '{{field}} no es una fecha válida',
                        number: '{{field}} no es un número válido.'    
                        /**** EJEMPLO del objeto que se debe recibir ****/
                        // complete: 'Formulario cumplimentado correctamente',
                        // error: 'Hubo un problema',
                        // next: 'Siguiente',
                        // previous: 'Atrás',
                        // cancel: 'Cancelar',
                        // submit: 'FINALIZAR E IR AL PASO 2',
                        // required: 'Es obligatorio rellenar este campo',
                        // confirmCancel: '¿Estás seguro que quieres cancelar?',
                        // submitError: 'Por favor revise el formulario y corrija todos los errores.'
                    }
                }, 
                evalContext: {
                    // Funciones auxiliares
                    obtenerUrlContexto: obtenerUrlContexto,
                    validarCampo: functionValidadorRegex,
                },
            }}
            onChange={props.onChange}
            submission={props.submission}
            onCustomEvent={props.onCustomEvent}
        />
    );
}