import { makeStyles } from '@material-ui/core/styles'
import { Height } from '@material-ui/icons';
import { backgroundRectangle, estilosTextosPrincipales, buttonPrimaryBig, buttonSecundaryBig, tipografia, colores } from '../../../AppStyle'

export const customStyle = makeStyles({

    backgroundRectangle: {
        ...backgroundRectangle,
        marginTop: '35px',
        marginBottom: '0px'
    },

    pageTitle: {
        ...estilosTextosPrincipales.titulosPagina,
    },

    gridPageTitle: {
        marginTop: '2%',
        marginLeft: '5%'
    },

    gridButtons: {
        width: '100%',
        padding: '0px 5%',
        marginTop: '24px',
        marginBottom: '56px',
        textAlign: 'end'
    },

    buttonPrimary: {
        ...buttonPrimaryBig,
        marginLeft: '24px !important',
        borderRadius: '4px !important',
        fontFamily: `${tipografia.regular} !important`,
        fontSize: '13px !important',
        lineHeight: 'normal !important',
        cursor: 'pointer !important'
    },

    buttonSecondary: {
        ...buttonSecundaryBig,
        borderRadius: '4px !important',
        fontFamily: `${tipografia.regular} !important`,
        fontSize: '13px !important',
        lineHeight: 'normal !important',
        cursor: 'pointer !important'
    },
    classBackdrop: {
        zIndex: 1,
        color: colores.principales.blanco,
    },
    gridTitulo:{
        display: 'flex', 
        flexDirection: 'row',
    },
    idExpediente:{
        marginLeft:'25px',
        color: colores.escalaGrises.sesenta,
        fontFamily: tipografia.bold,
        fontSize: '13px',
        height: 'inherit',
        display:'flex',
        alignItems: 'center'
    }
});