import React from 'react';
//Componentes importados de material ui
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Grid, MuiThemeProvider, FormLabel } from '@material-ui/core';
//Estilo importado
import Style, { getTheme } from './RadioButtonCustomStyle';
import { Opacity } from '@material-ui/icons';
/**
 * 
 * @function RadioButton
 * Función que contiene los componentes Grid, FormControl, InputLabel, RadioGroup, FormControlLabel, Radio, MuiThemeProvider
 * El componente FormControl contiene el componenteRadioButton en si
 * El componente FormLabel contiene el título de la etiqueta del componente FormControl
 * El componente RadioGroup agrupa las opciones del componenteRadioButton
 * El componente FormControlLabel contiene las opciones del componenteRadioButton
 * El componente Radio contiene la opción y su etiqueta label
 * @param props
 * A través d elas props se reciben los atributos y propiedades
 */
export default function RadioButton(props) {

    const styleLabelActive = Style().classLabelActive;
    const styleLabelResponsive = Style().classLabelResponsive;

    return (
        <MuiThemeProvider theme={getTheme(props.isPhoneVersion)}>
            <Grid
                container
                className={props.classes.classGrid}
                id={props.idGrid}
            >
                <FormControl
                    className={props.classes.classFormControlLabel}
                    component={props.componentFormControl}
                >
                    <FormLabel
                        shrink='true'
                        component={props.componentFormLabel}
                        className={props.classFormLabel}
                    >
                        {props.title}
                    </FormLabel>
                    <RadioGroup
                        aria-label={props.ariaLabel}
                        name={props.name}
                        value={props.value}
                        onChange={props.onChange}
                        id={props.idRadioGroup}
                    >
                        {
                            props.options.map((element, index) => (
                                <Grid className={props.isPhoneVersion ? styleLabelResponsive : styleLabelActive} container 
                                //Se aplica el background al radioButton seleccionado
                                {...(props.value === JSON.stringify(element) ? { style: { backgroundColor: "#ffa7a7" } } : { style: { backgroundColor: "transparent" } })}
                                {...(!props.disabled.includes(element.id) ? {style: {opacity: 0.5 }} : {style: {opacity: 1}})}
                                >
                                    {props.icon}
                                    <FormControlLabel
                                        control={<Radio id={element.id.toString()} name={element.id} />}
                                        value={JSON.stringify(element)}                                        
                                        label={(element.nombre).substring(0, 1).substring(0, 1).concat(element.nombre.substring(1, element.nombre.lenght).toLowerCase())}
                                        key={index.toString()}
                                        labelPlacement={'bottom'}
                                        disabled={
                                            /*Recibe un array y evalua si el id del radiobutton esta incluido en él.
                                            Si es así se niega el valor devuelto que sería true y lo convierte en false para evitar
                                            que el radiobutton esté deshabilidato.*/
                                            !props.disabled.includes(element.id)
                                        }
                                    />
                                </Grid>
                            ))
                        }
                    </RadioGroup>
                </FormControl>
                <FormHelperText>{props.FormHelperText}</FormHelperText>
            </Grid>
        </MuiThemeProvider>
    );
}