import React, { useState } from 'react'
import TabPanel from '../tabPanel/TabPanel'
import {customStyle, overrideStyle} from './TabMenuStyle'
import {properties} from './TabMenuData';
import {Grid, AppBar, Tabs, Tab, ThemeProvider} from '@material-ui/core';
import { menuCordoba } from '../header/HeaderData';

export default function TabMenu(props) {

    const [selectedSectionIndex, setSelectedSectionIndex] = useState(false);
    const style = customStyle();

    const handleChange = (event, newValue) => {
        const selectedTab = menuCordoba[newValue];
        if (selectedTab.codigo === "cod_buscar") {
            window.open(selectedTab.enlace, '_blank');
        } else {
            if(newValue === selectedSectionIndex){
                closePanel();
            }else{
                setSelectedSectionIndex(newValue);
            }
        }
    };

    const closePanel = () => {
        setSelectedSectionIndex(false);
    }

    const renderTabs = () => {

        return ( 
            menuCordoba.map((tab) =>{
            return(
                <Tab disableRipple tabIndex='0' title={tab.tituloSeccion} className={style.tab} label={tab.tituloSeccion}/>
            )
            })
        )
    }

    const renderTabsPanels = () => {
        return ( menuCordoba.map((tab, index) =>{
            if (tab.codigo === "cod_buscar") {
                return null;
            }
            return (
                <TabPanel tabData={tab} selectedSectionIndex={selectedSectionIndex} index={index} close={closePanel}/>
            )
        }))
    }

    return (
        <Grid container className={style.gridMenu} alignItems='center'>
            <ThemeProvider theme={overrideStyle}>
                <AppBar className={style.appBar} position='relative'>
                    <Tabs className={style.tabs}
                    value={selectedSectionIndex}
                    margin='0px'
                    onChange={handleChange}
                    role='menu'
                    >
                    {renderTabs()}
                    </Tabs>
                </AppBar>
                {renderTabsPanels()}
            </ThemeProvider>
        </Grid>
    )
}