import axios from 'axios';
import mappedServices from './mappedServices';
import lodash from 'lodash';
import AuthProvider from './authProvider';

const RestProvider = () => {
    
    const {getToken, getSecurityCheck} = AuthProvider();

    const crudOperationsType = {
        FIND_BY_ID: 'findBy',
        FIND_ALL: 'findAll',
        CREATE: 'create',
        UPDATE: 'update',
        DELETE_BY_ID: 'delete',
        DELETE: 'delete',
        CUSTOM: 'custom',
    }

    /**
     * Hacemos una petición crud por medio de una API rest, devuelve la promesa correspondiente
     * @param {*} sourceMs, el prefijo del mapeo de microservicio
     * @param {*} crudOp, la operación, hacemos uso del enumerado crudOperationsType
     * @param {*} resource, el nombre del endpoint
     * @param {*} params, los parámetros
     */
    const crudOperationEx = async (sourceMs, crudOp, resource, params = {}, serverContextPath = null) => {
        let crudPromise = null;    

        let config = {
            headers: {
                centroGestorActual:'C0026'
            }
        }
    
        let access_token = await getToken();
        config.headers["Authorization"] = `Bearer ${access_token}`;

        //Header con el SecurityCheck para comprobación de identidad del ciudadano logado en back
        let security_check = await getSecurityCheck();
        if(security_check){
            config.headers["Security-Check"] = security_check;
        }

        if (params.headers) {
            lodash.merge(config.headers, params.headers);
        }

        // actualmente no se usa la property por lo que no añadimos la nueva forma de obtenerla
        let contextUrl = '' ;
        const baseUrl = `${contextUrl}` + (sourceMs && sourceMs.length > 0 ? `${sourceMs}/${resource}` : `/${resource}`);

        switch(crudOp) {
            case crudOperationsType.CREATE:
                crudPromise = axios.post(`${baseUrl}`, params, config);
                break;
            case crudOperationsType.FIND_BY_ID:
                crudPromise = axios.get(`${baseUrl}/${params.id}`, config);
                break;
            case crudOperationsType.FIND_ALL:
                crudPromise = axios.get(`${baseUrl}`, { params, ...config });
                break;
            case crudOperationsType.UPDATE:
                crudPromise = axios.put(`${baseUrl}/${params.id}`, params, config);
                break;
            case crudOperationsType.DELETE_BY_ID:
                crudPromise = axios.delete(`${baseUrl}/${params.id}`, config);
                break;
            case crudOperationsType.CUSTOM:
                const {
                    baseURL = baseUrl,
                    url = '',
                    method = "get",
                    responseType,
                } = params;
                crudPromise = axios.request({ baseURL, url, method, params: params.urlParams, data: params.data, responseType, ...config });
                break;
            default:
                new Error("Acción no soportada");
        }
        // Devuelve la promesa concreta de la petición para controlarla en su destino
        return crudPromise;
    };

    /**
     * Hacemos una petición crud por medio de una API rest, devuelve la promesa correspondiente
     * @param {*} crudOp, la operación, hacemos uso del enumerado crudOperationsType
     * @param {*} resource, el nombre del endpoint
     * @param {*} params, los parámetros
     */
    const crudOperation = (crudOp, resource, params) => {

        let sourceMs = '';
        for (var ms in mappedServices) {
            if (Object.prototype.hasOwnProperty.call(mappedServices, ms)) {
                const resourceMatches = mappedServices[ms].resources.find(r => r === resource);
                if (resourceMatches) {
                    sourceMs = mappedServices[ms].path;
                    break;
                }
            }
        }
        if (sourceMs !== '') {
            return crudOperationEx(sourceMs, crudOp, resource, params);        
        } else {
            new Error(`Microservicio no identificado para el resource ${resource}`);
        }
    };

    const obtenerProperties = async (properties) => {        
        let params = { properties };
        try {
            const response = await crudOperationEx('', crudOperationsType.CREATE, 'propertiesCloudConfig', params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return { crudOperationsType, crudOperationEx, crudOperation, obtenerProperties };
}
export default RestProvider;