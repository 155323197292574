import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { buttonPrimaryBig, buttonSecundaryBig, colores, estilosTextosPrincipales, tipografia } from '../../../AppStyle';


export const style = makeStyles({
    buttonAccion: {
        ...buttonPrimaryBig,
        width: '145px'
    },
    buttonCancelar: {
        ...buttonSecundaryBig,
        width: '145px'
    },
    classGrid: {
        marginTop: '15px',
        marginBottom: '15px',
        marginRight: '20px',
        width: 'auto',
        textAlign: 'left',
        display: 'block',
    },
    classInputLabel: {
        ...estilosTextosPrincipales.body2,
        fontFamily: tipografia.regular,
        color: colores.escalaGrises.ochenta,
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: ' 17px',
        marginBottom: '5px',
        position: 'relative',
        '&.Mui-focused': {
            color: colores.escalaGrises.ochenta,
        }
    },
    classTexto: {
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.cien
    },
    classTitulo: {
        ...estilosTextosPrincipales.encabezadoH2,
        color: colores.escalaGrises.cien
    }
});

export default function customizate(condition) {
    const overInputs = createMuiTheme({
        overrides: {
            MuiDialog: {
                paperScrollPaper: {
                    maxHeight: 'none',
                    width: 'auto',
                    height: 'auto'
                }
            },
            MuiDialogContent: {
                root: {
                    padding: '8px 42px'
                }
            },
            MuiDialogActions: {
                root: {
                    padding: '16px 24px',
                    justifyContent: 'center'
                }
            },
            MuiInputBase: {//Clases aplicadas en los inputs
                root: {
                    height: '48px',
                    width: '298px'
                },
                input: {
                    ...estilosTextosPrincipales.body2,
                    fontSize: '14px',
                    //lineHeight: '24px',
                    letterSpacing: '0px',
                    color: colores.escalaGrises.cien,
                    '&::placeholder': {
                        ...estilosTextosPrincipales.body2,
                        fontSize: '14px',
                        //lineHeight: '24px',
                        letterSpacing: '0px',
                        opacity: '0.8',
                        color: colores.escalaGrises.apoyo,
                    },
                },
            },
            MuiOutlinedInput: {//Clases aplicadas a los bordes de los inputs
                root: {
                    '&:hover $notchedOutline': {
                        border: '2px',
                        borderStyle: 'solid',
                        borderColor: colores.principales.principal
                    },
                    '&$focused $notchedOutline': {
                        border: '2px',
                        borderStyle: 'solid',
                        borderColor: colores.principales.principal
                    },
                },
                notchedOutline: {
                    border: '1px solid',
                    borderColor: colores.principales.apoyo,
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px'
                },
            },
            MuiInputLabel: {//Evita que se reduzca el título
                shrink: {
                    transform: 'none'
                },
            },
            MuiTypography: {
                h6: {
                    ...estilosTextosPrincipales.encabezadoH2,
                    color: colores.escalaGrises.cien
                }
            }
        }
    });
    return overInputs;
}