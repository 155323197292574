/**************************************
 * Fichero de definición del componente
 *************************************/
import React from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import ConfiguracionDelComponente from './selectSimpleComponentSectionConfigurationFormio';
import SelectSimpleFormio from './selectSimpleFormio';


export default class CustomComponent extends ReactComponent {
  /**
   * Esta función contiene las etiquetas del componente
   * 
   * 
   * @function builderInfo 
   */
  static get builderInfo() {
    return {
      title: "OV3-Select Simple", //Nombre del componente
      icon: "caret-down", //Icono que aparece en el componente
      group: "basic", //Grupo al que pertenece
      documentation: "", //Documentación del componente
      weight: -10, //Posición en la que aparecerá el componente en su grupo correspondiente
      schema: CustomComponent.schema() //Schema del componente
    };
  }

  /**
   * Esta función es la configuración predeterminada del componente
   * 
   * 
   * @function schema
   */
  static schema() {
    return ReactComponent.schema({
      type: "selectSimple",
      label: ""
    });
  }

  /*
   * Define el settingsForm al editar un componente en el constructor.
   */
  static editForm = ConfiguracionDelComponente;

  /**
   * @function setValues Funcion que recibe el objeto del formulario del componente SelectSimple
   * A partir de este objeto, genera un objeto con los valores necesarios para alimentar las props del componente SelectSimple.
   * @param {*} component Objeto devuelto por el formulario del componente SelectSimple
   * @returns Devuelve un objeto con los valores de las props del componente SelectSimple
   */

  setValues(component) {
    return (
      {
        label: component.label,
        id: component.properties.id,
        name: component.properties.name,
        controladorEndPoint: component.controladorEndPoint,
        urlEndPoint: component.urlEndPoint,
        valorSelect: component.valorSelect,
        valorAmostrarSelect: component.valorAmostrarSelect
      }
    )
  }

  /**
   * Función que renderiza el componente customizado en el DOM
   * 
   * 
   * @function attachReact
   */
  attachReact(element) {
    return ReactDOM.render(
      <SelectSimpleFormio
        {...this.setValues(this.component)}
      />,
      element
    );
  }

  /**
   * Función que separa cualquier componente de react
   * 
   *
   * @function detachReact
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}