import { makeStyles, createMuiTheme } from '@material-ui/core';
import { colores, estilosTextosPrincipales, links, tipografia } from 'AppStyle';

const useStyle = (condicion) => {
    const customStyle = makeStyles({
        classTablaContainer: {
            width: '100%',
            paddingTop: '1%',
        },
        classCabecera: {
            textTransform: 'uppercase',
            fontFamily: tipografia.regular,
            fontWeight: '700',
            fontStyle: 'normal',
            fontSize: '17px',
            
        },
        classThead: {
            textTransform: 'uppercase',
            fontFamily: tipografia.regular,
            fontWeight: '700',
            fontStyle: 'normal',
            fontSize: '17px',
            borderBottom: `1px solid ${colores.escalaGrises.cien}`
        },
        classEncabezadoNombre: {
            width: '50%',
            ...estilosTextosPrincipales.body1,
            lineHeight: '19px !important',
            paddingLeft: '16px'
        },
        classEncabezados: {
            width: '15%',
            ...estilosTextosPrincipales.body1,
            lineHeight: '19px !important',
            paddingLeft: '16px'
        },
        classEncabezadoAcciones: {
            width: '5%',
            ...estilosTextosPrincipales.body1,
            lineHeight: '19px !important',
            paddingRight: '10px !important'
        },
        classNombre: {
            fontFamily: tipografia.regular,
            color: colores.principales.principal,
            fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '24px'
        },
        classEstado: {
            fontFamily: tipografia.regular,
            color: colores.escalaGrises.cien,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            paddingLeft: '16px'
        },
        classFamilia: {
            fontFamily: tipografia.regular,
            color: colores.escalaGrises.cien,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            paddingLeft: '16px'
        },
        classInformacion: {
            fontFamily: tipografia.regular,
            color: colores.escalaGrises.cien,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            paddingLeft: '16px'
        },
        classAcciones: {
            textAlign: 'center'
        },
        classCeldaPar: {
            backgroundColor: colores.escalaGrises.cero,
            fontFamily: tipografia.regular
        },
        classCeldaImpar: {
            backgroundColor: colores.escalaGrises.cuatro,
            fontFamily: tipografia.regular
        },
        classIcono: {
            fill: colores.escalaGrises.ochentaYcinco,
            '&:hover': {
                opacity: 0.7,
            },
            maxWidth: '90%',
            '&:focus': {
                outline: 'none',
                border: 'none',
            },
        },
        classLink: {
            ...links,
            fontSize: '16px',
            display: 'inline-flex',
            maxWidth:' 90%',
            ...(!condicion && { paddingLeft: '16px' })
        },
        classParrafoCeldas: {
            display: 'inline-flex',
            maxWidth:' 90%',
            overflow: 'hidden',
            overflowWrap: 'anywhere'
        }
    });

    const overrideStyle = createMuiTheme({
        overrides: {
            MuiTableRow: {
                root: {
                    backgroundColor: colores.escalaGrises.cero,
                    boxShadow: `0px 1px 0px ${colores.escalaGrises.cien}`,
                    '&:nth-of-type(odd)': {
                        backgroundColor: colores.principales.apoyo1,
                        fontWeight: 'bold',
                    },
                },
            },
            //Evito que se apliquen los estilos de las demas columnas de la tabla al encabezado
            MuiTableCell: {
                root: {
                    borderBottom: 'none',
                    letterSpacing: 'inherit',
                    padding: '16px 0px 16px 0px',
                    fontFamily: tipografia.regular,
                },
                alignLeft: {
                    textAlign: 'center'
                },
                head: {
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: colores.escalaGrises.cero,
                        fontWeight: 'bold',
                    },
                    '&:nth-of-type(odd)': {
                        backgroundColor: colores.escalaGrises.cero,
                        fontWeight: 'bold',
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: colores.escalaGrises.cero,
                        fontWeight: 'bold',
                    },

                },
            },
        }
    });

    return { customStyle, overrideStyle };
}

export default useStyle;