import { getContextFromUrl } from 'config/Config';
import {textsAytm as appTexts } from '../../../AppData';

export const texts = {
    PAGE_TITLE: 'Alta de anexos a la solicitud',
    PAGE_TITLE_SUBSA: 'Subsanación de anexos a la solicitud',
    TEXTO_ERROR_DROP_REJECTED1: 'Tenga en cuenta que el documento deber ser un PDF con un tamaño máximo de ',
    TEXTO_ERROR_DROP_REJECTED2: ' MB. Por favor, seleccione otro documento que cumpla con las condiciones indicadas.',
    TEXT_BOTON: 'VOLVER A LA BANDEJA DE ENTRADA',
    TEXT_GUARDAR_ANEXOS: 'GUARDAR ANEXOS',
    AVISO_FIRMA: 'Anexos firmados',
    MESSAGE_FIRMADO: 'Se ha realizado correctamente el guardado y firmado de los anexos.',
    GUARDAR: 'GUARDAR',
    TEXT_FIRMAR_ANEXOS: 'FIRMAR ANEXOS',
    TEXT_PRESENTAR_SUBSANACION: 'PRESENTAR SUBSANACIÓN',
    TEXT_BTN_ALTA_ENTI: 'ALTA DE ENTIDAD',
    PARTE_1_MSG_ERROR: "Se ha producido un error técnico.",
    PARTE_2_MSG_ERROR: "Puede volver a intentar realizar el proceso de firma y, si el error persiste, contacte con el servicio de Información y Sugerencias del Servicio de Información y Atención Ciudadana que puede consultar <a href="+appTexts.URL_CONTACTO+">aquí</a>.",
    ERROR_FIRMA: 'Se ha producido un error al firmar los anexos',
    ERROR_FIRMA_SUBSA_SIN_ENTI: 'Se ha producido un error al presentar la subsanacion',
    TITLE_SALIR: 'Volver a la bandeja de solicitudes',
    TEXTO_SALIR: 'Si sale de esta pantalla no se guardaran los cambios realizados, ¿desea continuar?',
    TEXTO_SUBSANACION: 'Anexe los documentos que le han sido requeridos para subsanar. En caso de que no se le haya requerido ningún documento a subsanar pulse el botón <b>PRESENTAR SUBSANACIÓN<b>.'
}

export const objects = {
    documentoAnexo: {
        mostrarDropZone: false,
        value: "",
        mostrarDocAnexo: false,
        tamDoc: "",
        pdf : "",
        titulo: "",
        id: ""
    }
}

export const properties = {
    PATH_IMG_PDF: `${getContextFromUrl()}` + 'pdfGris.png',
    TAM_MAX_ANEXO: '1',
    FORMATO: ['.pdf'],
    UN_MEGABYTE_EN_BYTE : 1000000,
    NUMERO_DECIMALES: 2,
    URL_BANDEJA: '/BandejaSolicitudes',
    RESOURCE_DOCUMENTO: 'documento',
    RESOURCE_TIPO_DOCUMENTO: 'tipo-documento',
    RESOURCE_PRESENTACION_ENTREGA: 'presentacion-entrega',
    RESOURCE_FORMULARIO_EXPEDIENTE: 'formulario-expediente',
    RESOURCE_FASE: 'fase',
    RESOURCE_ESTADO_FASE: 'estado',
    RESOURCE_PORTAFIRMAS: 'portafirmas',
    URL_FORMULARIO: '/getClaveValorForm',
    URL_CODIGO_DOCUMENTO: '/codigo/',
    CODIGO_TIPO_DOCUMENTO: 'ANEXO_FORM',
    URL_OBTENER_ANEXO: '/findDoc',
    URL_ELIMINAR_ANEXO: '/eliminarDoc/',
    URL_UPDATE_ANEXO: '/update',
    URL_COD_FASE: '/find-by-codigo/',
    URL_COD_ESTADO_FASE: '/findByCodigo/',
    ENUMERADO_FIR_ANEXOS: 'FIRMA_ANEXO_A_LA_SOLICITUD',
    ENUMERADO_FIR_SUBSA: 'SUBSANACION_ANEXO_A_LA_SOLICITUD',
    ENUMERADO_FIR_SUBSA_SIN_ENTI: 'SUBSANACION_ANEXO_A_LA_SOLICITUD_SIN_ENTIDADES',
    COD_FASE_INICIO: 'F_INICIO',
    RESOURCE_INTERESADO: 'interesado',
    URL_DELETE_INTERESADO: '/deleteEntidadColaboradora/',
    URL_OBTENER_ANEXOS_PCT3: '/findDocAnexo',
    URL_OBTENER_PDF_NORMALIZADO: '/obtenerPdfNormalizado',
    RESOURCE_PDF_NORMALIZADO: 'pdf-normalizado'
}

export const msgError = {
    error: 'Ha ocurrido un error al intentar guardar los documentos',
    guardadoOk: 'Se ha realizado correctamente el guardado de los anexos',
    severity: 'Error',
    success: 'success'
}

export const propertiesBtns = {
    btnSave: {
        id: 'save',
        name: 'save'
    },
    btnSaveFirmar: {
        id: 'firma',
        name: 'firma'
    },
    btnAltaInteresado: {
        id: 'altaInteresado',
        name: 'altaInteresado'
    }, btnPresentarSubsanacion: {
        id: 'presentarSubsanacion',
        name: 'presentarSubsanacion'
    }
}