import {makeStyles} from '@material-ui/core/styles'
import {colores, links, tipografia} from 'AppStyle'

export const customStyle = makeStyles ({

    //Bloques
    gridPanel:{
        position: 'absolute',
        zIndex: '1',
        left: 0,
        right: 0,
        top:'140px',
        height: 'fit-content',
        backgroundColor: colores.escalaGrises.cero,
        borderBottom: '5px solid '+ colores.principales.principal
    },
    gridContenidoPanel:{
        paddingRight: '5%',
        paddingLeft: '5%',
        width: '100%',
    },
    gridSuperior:{
        height: '55px',
        borderBottom: '1px solid ' + colores.escalaGrises.veinte
    },
    gridInferior:{
        paddingTop: '2%',
        paddingBottom: '2%'
    },
    gridMainColumn:{
        width: 'fit-content',
        marginRight: '50px',
        float: 'left'
    },
    gridColumn:{
        marginBottom: '15%',
        maxWidth: '400px',
        minWidth: '250px'
    },
    gridColumnDirectoA:{
        width:'350px'
    },
    gridListaDirectoA:{
        backgroundColor: colores.principales.fondoEspecifico,
        paddingLeft: '3%',
        paddingBottom: '3%',
    },
    gridTituloDirectoA:{
        backgroundColor: colores.principales.fondoEspecifico,
        marginBottom: '2%',
        paddingLeft: '6%',
        paddingTop: '2%',
        paddingBottom: '2%'
    },

    // Elementos individuales
    tituloPanel:{
        fontFamily: tipografia.bold,
        fontSize: '24px',
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',
    },
    closeIcon:{
        fill: colores.principales.principal,
        fontSize: '24px'
    },
    tituloColumna:{
        fontFamily: tipografia.bold,
        fontSize: '20px',
        fontWeight: 'bold',
        color: colores.escalaGrises.cien,
        textDecoration: 'none',
        '&:focus':{
            outline:'none'
        }
    },
    lista:{
        listStyleType: 'none',
        width: '100%',
    },
    elementosLista:{
        marginTop: '15px',
        marginLeft:'10px'
    },
    enlaces:{
        ...links,
        width: '295px'
    },
    iconoFila:{
        height: '20px',
        marginRight: '5px',
        filter:'invert(91%) sepia(26%) saturate(150%) hue-rotate(77deg) brightness(90%) contrast(92%)'
    }
})