import { useState, useEffect } from 'react';
import RestProvider from 'providers/RestProvider';
import { useParams } from "react-router-dom";
import { properties as appProperties } from '../../../AppData';
import { cabecerasTabla } from './ListadoFormulariosData';
import { objAtrib } from './ListadoFormulariosData';
import FormioProvider from '../../../providers/FormioProvider';

const useOperations = () => {
    const [isPhoneVersion, setIsPhoneVersion] = useState(false);
    let _ID = 0;
    const [listadoFormularios, setListadoFormularios] = useState([]);
    const [mostrarPopUp, setMostrarPopUp] = useState(false); //Muestra o no el popup
    const [mostrarImport, setMostrarImport] = useState(false);
    const [mostrarImportActu, setMostrarImportActu] = useState(false);
    const [deleteForm, setDeleteForm] = useState(false); //Permite o deniega la eliminación de un formulario
    const { crudOperation, crudOperationsType } = RestProvider();
    const { formioOperation, checkAppToken } = FormioProvider();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [page, setPage] = useState(0);
    const [maximo, setMaximo] = useState(1);
    const [errors, setErrors] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { formPath } = useParams();
    const [forms, setForms] = useState([]);
    //Inicialización necesaria para el componente Alert de mensajes y errores
    const [message, setMessage] = useState({ msg: "", severity: "" });
    const [alerts, setAlerts] = useState([]);
    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    /**Método que capta el tamaño de la ventana y actualiza actualiza
        ***el hook que controla cuando se da el salto a versión movil*/
    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    };

    /**
         * @constant headerTableObject Obtención del objeto que recibe la tabla con los valores a insertar en la cabecera
         * @param {*} funcionOrdena Función que recibe
         * @returns {headersObject} Devuelve un array de objetos con las cabeceras de la tabla
         */
    const headerTableObject = (funcionOrdena) => {
        let headersObject = []; //Array declarado, donde se insertarán los objetos
        //Paso 1. Se recorre el array recibido con las cabeceras
        for (const valores of cabecerasTabla) {
            //Paso 2. Estructura condicional, que determina si el valor de la cabecera es acción o no, ya que es el único valor que tiene propiedades diferentes en su objeto.
            if (valores != 'ACCIONES') {
                //Paso 3. Se inserta dentro de el array el objeto que recibirá el componente tablaGenercia
                headersObject.push(
                    {
                        titulo: valores,
                        esBotonera: false,
                        funcionOrdenacion: funcionOrdena
                    }
                );
            } else {
                headersObject.push(
                    {
                        titulo: valores,
                        esBotonera: true,
                        funcionOrdenacion: null
                    }
                );
            }
        }
        return headersObject;
    }

    /**
     * @constant dataTabletObject Obtención del objeto que recibe la tabla con los valores a insertar en ella
     * @returns Se devuelve el objeto que alimentará la tabla
     */
    const dataTableObject = () => {
        let dataObject = []; //Array que contendrá los objetos

        //Recorrido del objeto para obtener los valores a insertar en la tabla
        listadoFormularios.map(valores => {
            dataObject.push({
                nombre: valores.title,
                tipo: valores.type,
                acciones: [{ funcion: "", img: "Editar Formulario", redirect: "/" },
                { funcion: "", img: "Eliminar Formulario", redirect: "/" },
                { funcion: "", img: "Rellenar Formulario", redirect: "/" },
                { funcion: "", img: "Ver Datos Enviados", redirect: "/" },
                { funcion: "", img: "Exportar Formulario", redirect: "/" }]
            })
        })
        return dataObject;
    }

    /*const handleAction = (form, action) => {

        switch (action) {
          case 'edit':
            doRedirect(redirect, 'builder', 'edit', form.path);
            break;
          case 'delete':
            setSelectedForm(form);
            setDeletePopupOpen(true);
            break;
          case 'submission':
            doRedirect(redirect, 'formulario', 'edit', form.path);
            break;
          case 'dataSubmitted':
            doRedirect(redirect, 'submission/list', 'edit', form.path);
            break;
          default:
            return null;
        }
      }*/


    /**
     * @constant fotmatearFecha Se formatea la fecha obtenida y se formatea según el formato DD/MM/YYYY.
     * @param {*} fechaObtenida Se recibe la fecha obtenida
     * @returns Devuelve la fecha en formato DD/MM/YYYY
     */
    const formatearFecha = (fechaObtenida) => {
        //Paso 1. Se extrae de la fecha obtenida solo el año, el mes y el dia. La fecha se obtiene en este formato 2020-06-26T10:00:52.000+0000
        fechaObtenida = fechaObtenida.substring(0, 10);
        //Paso 2. Se convierte la cadena de String con la fecha obtenida en fecha
        let fecha = new Date(fechaObtenida);
        //Paso 3. Se convierte y devuelve la fecha con el formato DD/MM/YYYY
        return fecha.getDate() + '/' + (fecha.getMonth() + 1) + '/' + fecha.getFullYear();
    }

    /////////////////////////POPUP Confirmar eliminación de formulario
    /**
     * @function cancelarContinuacion Función que cancela la continuación del formulario cunado no se han guardado los valores de este
     */
    const cancelarContinuacion = accion => {
        setMostrarPopUp(accion);
    }

    /**
     * @function aceptarContinuacion Función que acepta la continuación del formulario cunado no se han guardado los valores de este
     */
    const aceptarContinuacion = () => {
        //Paso 1. Se establece la hook mostrarPopUp a false
        setMostrarPopUp(false);
        //Paso 2. Se establece la hook deleteForm a true
        setDeleteForm(true);
    }

    let formularios = {
        forms: forms,
        pagination: {
          page: 0
        }
      }

    const aceptarContinuacionImport = (e) => {
        //Paso 1. Se establece la hook mostrarPopUp a false
        setMostrarImport(false);
        //Paso 2. Se establece la hook deleteForm a true
        document.querySelector(`#${objAtrib.inputImportar.id}`).click();
        //Paso 2 Lanzamos el evento que si cambia nuestro input, lanza funcion
        document.getElementById("inputImportar").addEventListener("change", function() { 
            //Paso 3 Creamos nuestro lector
            var lectorInputJson = new FileReader();
            //Paso 4 Guardamos nuestro archivo en una variable, que esta sin leer
            var archivoInputJson = document.getElementById("inputImportar").files[0];
            //Paso 5 Leemos nuestro archivo junto a su codificacion
            lectorInputJson.readAsText(archivoInputJson, "UTF-8");
            //Paso 6 Para asegurarnos que nuestro lector esta cargado, añadimos onload y le pasamos el evento
            lectorInputJson.onload = function(e) {
                //Paso 7 Guardamos el contenido del archivo en el evento que le pasamos, utilizamos result de filereader para obtenerlo
                var contenidoInputJson = e.target.result; 
                //Paso 8 Convertimos nuestro string en objeto para poder buscar
                var objetoInputJson = JSON.parse(contenidoInputJson);
                //Paso 9 Guardamos nuestro path para formio
                var pathInputJson = objetoInputJson.path;
                delete objetoInputJson._id;
                //Paso 10 Conectamos con formio pasandole los datos y el contenido de nuestro json
                setLoading(true);
                formioOperation('form', { method: 'post', data: objetoInputJson }, true).then(response => {

                    if (formularios.forms.length === 1 && page !== 0) {
                        setPage(page - 1);
                      }
                        
                      setReload(true);
                    handleSetMessage('Se ha realizado correctamente la creación del formulario '+pathInputJson, 'success');
                    document.getElementById("inputImportar").value = "";
                    
                }).catch(error =>  {
                    setMostrarImportActu(true);
        
                
                }).finally(() => {
                    
                    setLoading(false);
                    
                  });
            };
        });
        
    }

    const cancelarContinuacionImport = accion => {
        setMostrarImport(accion);
    }


    const cancelarContinuacionActu = accion => {
        setMostrarImportActu(accion);
    }

    const aceptarContinuacionActu = () => {
        setMostrarImportActu(false);

            var lectorInputJson = new FileReader();
            var archivoInputJson = document.getElementById("inputImportar").files[0];
            lectorInputJson.readAsText(archivoInputJson, "UTF-8");
            lectorInputJson.onload = function(e) {
                var contenidoInputJson = e.target.result; 
                var objetoInputJson = JSON.parse(contenidoInputJson);
                var pathInputJson = objetoInputJson.path;
                delete objetoInputJson._id;
                formioOperation(pathInputJson, { method: 'get' }, true).then(response => {
                    const nombreForm = response.data.name; 
                    const datosForm = JSON.stringify(response.data);
                    const objetoBlob = new Blob([datosForm],{type:'application/json'});
                    const href = URL.createObjectURL(objetoBlob);
                    const enlace = document.createElement('a');
                    enlace.href = href;
                    enlace.download = nombreForm + ".json";
                    enlace.click();
                    
                })
                
                formioOperation(pathInputJson, { method: 'put', data: objetoInputJson }, true).then(response => {
    
                        handleSetMessage('Se ha realizado correctamente la actualización del formulario '+pathInputJson, 'success');
                        document.getElementById("inputImportar").value = "";
                    }).catch(error =>  {
            
                     handleSetMessage('Se ha producido un error al actualizar el formulario '+pathInputJson, 'danger');

                    });
            };
        
    }




    return {
        listadoFormularios, setListadoFormularios, isPhoneVersion,
        crudOperation, crudOperationsType, handleSetMessage, updateFormat,
        dataTableObject, headerTableObject, cancelarContinuacion, aceptarContinuacion, mostrarPopUp, setMostrarPopUp, deleteForm,
        mostrarImport, setMostrarImport, alerts, message, setMessage, aceptarContinuacionImport, cancelarContinuacionImport,
        mostrarImportActu, setMostrarImportActu, aceptarContinuacionActu, cancelarContinuacionActu, reload, setReload
    };
}
export default useOperations;