import React, { useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, ThemeProvider } from '@material-ui/core';
import { texts } from './DocumentoAnexoVisualData';
import useStyles from './DocumentoAnexoVisualStyle';
import useOperations from './DocumentoAnexoVisualFunction';

export default function DocumentoAnexoVisual(props) {

    const { isPhoneVersion, updateFormat } = useOperations();
    const { style, overrideStyle } = useStyles(isPhoneVersion);
    const normalise = value => (value - props.min) * 100 / (props.max - props.min);

    let classImagen = style().classImg;
    let classGrid = style().classGrid;
    let classLineaProgreso = style().classLineaProgreso;
    let classGridEstado = style().classGridEstado;
    let classText = style().classText;
    let classH4 = style().classH4;

    useEffect(() => {
        updateFormat();
        return () => window.removeEventListener('resize', updateFormat);
    }, []);

    window.addEventListener('resize', updateFormat);

    return (
        <Grid className={classGrid}>
            <ThemeProvider theme={overrideStyle}>
                <img className={classImagen} src={props.img} />
                <Grid className={classGridEstado}>
                    <LinearProgress className={classLineaProgreso} variant="determinate" value={normalise(props.value)}></LinearProgress>
                    <p className={classText}>{props.texto}</p>
                    <h4 className={classH4}>{props.value}{texts.BARRA}{props.max}{props.esPeso && texts.PESO}</h4>
                </Grid>
            </ThemeProvider>

        </Grid>

    )
}