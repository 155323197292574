export const properties = {
    tamanoMaxMiga: 15,
    textoTamanoMaxMiga: '...',
    ariaLabel: 'miga de pan',
    pantallaPrincipal: 'inicio'
};

/**
 * @constant rutas Contiene un objeto con la ruta de cada pantalla
 */
export const rutas = {
    procedimientos: {
        seleccionConvocatoriaPerfil: 'SeleccionConvocatoriaPerfil',
        bandejaSolicitudes: 'BandejaSolicitudes',
        nuevaSolicitud: 'NuevaSolicitud',
        aporteDocumentacion: 'AporteDocumentacion',
        firmaSolicitud: 'FirmaSolicitud',
        solicitudPresentadaCorrectamente: 'SolicitudPresentadaCorrectamente',
        historicoDocumentos: 'HistoricoDocumentos',
        aporteMejoraDocumentacion: 'AporteMejoraDocumentacion',
        desistimiento: 'Desistimiento',
        altaAnexo: 'AltaAnexo',
        entregas: 'Entregas'
    },
    moduloAdministracion: {
        loginAdministrador: 'LoginAdministrador',
        menuNavegacion: 'MenuNavegacion',
        creacionFormularios: 'CreacionFormularios'
    },
    altaUsuario: 'AltaUsuario',
    noticias: 'Noticias',
    pantallaContacto: 'PantallaContacto',
    matrizCompatibilidad: 'MatrizCompatibilidad',
    PageAccesoConContrasena: 'PageAccesoConContrasena',
    AltaUsuarioConContrasena: 'AltaUsuarioConContrasena',
    descargarDocumentos: 'DescargarDocumentos'
};

/**
 * @constant caminos Contiene un array de objetos con los caminos de cada pantalla
 */
export const caminos = [
    //procedimientos
    { seleccionConvocatoriaPerfil: rutas.procedimientos.seleccionConvocatoriaPerfil },
    { bandejaSolicitudes: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}` },
    { nuevaSolicitud: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}/${rutas.procedimientos.nuevaSolicitud}` },
    { aporteDocumentacion: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}/${rutas.procedimientos.nuevaSolicitud}/${rutas.procedimientos.aporteDocumentacion}` },
    { firmaSolicitud: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}/${rutas.procedimientos.nuevaSolicitud}/${rutas.procedimientos.aporteDocumentacion}/${rutas.procedimientos.firmaSolicitud}` },
    { solicitudPresentadaCorrectamente: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}/${rutas.procedimientos.nuevaSolicitud}/${rutas.procedimientos.aporteDocumentacion}/${rutas.procedimientos.firmaSolicitud}/${rutas.procedimientos.solicitudPresentadaCorrectamente}` },
    { historicoDocumentos: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}/${rutas.procedimientos.historicoDocumentos}` },
    { aporteMejoraDocumentacion: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}/${rutas.procedimientos.aporteMejoraDocumentacion}` },
    { desistimiento: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}/${rutas.procedimientos.desistimiento}` },
    { altaAnexo: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}/${rutas.procedimientos.altaAnexo}` },
    { altaEntidadColaboradora: `${rutas.procedimientos.seleccionConvocatoriaPerfil}/${rutas.procedimientos.bandejaSolicitudes}/${rutas.procedimientos.altaAnexo}/${rutas.procedimientos.altaEntidadColaboradora}` },
    //Módulo de administración
    { loginAdministrador: rutas.moduloAdministracion.loginAdministrador },
    { menuNavegacion: rutas.moduloAdministracion.menuNavegacion },
    { creacionFormularios: `${rutas.moduloAdministracion.menuNavegacion}/${rutas.moduloAdministracion.creacionFormularios}` },
    //Alta de usuario
    { altaUsuario: rutas.altaUsuario },
    //Noticias
    { noticias: rutas.noticias },
    //Contacto
    { pantallaContacto: rutas.pantallaContacto },
    //Matriz Compatibilidad
    { matrizCompatibilidad: rutas.matrizCompatibilidad },
    // Descarga de documentos
    { descargarDocumentos: rutas.descargarDocumentos }
];

/**
 * @constant nombresMigas Contiene un objeto con los textos que tendrán las migas de pan
 */
export const nombresMigas = {
    procedimientos: {
        seleccionConvocatoriaPerfil: 'Accede a ',
        bandejaSolicitudes: 'Bandeja de solicitudes de ',
        nuevaSolicitud: 'Nueva solicitud para ',
        aporteDocumentacion: 'Aporte de documentación para ',
        firmaSolicitud: 'Firmar solicitud de ',
        solicitudPresentadaCorrectamente: 'Justificante y recibí de ',
        historicoDocumentos: 'Histórico de documentos',
        aporteMejoraDocumentacion: 'Aportar documentación',
        desistimiento: 'Desistimiento',
        altaAnexo: 'Alta de anexos a la solicitud',
        altaEntidadColaboradora: 'Alta de la entidad colaboradora',
        PageAccesoConContrasena: 'Acceso con usuario registrado',
        entregas: 'Entregas'
    },
    moduloAdministracion: {
        loginAdministrador: 'Login administrador',
        menuNavegacion: 'Módulo de administración',
        creacionFormularios: 'Creación de formularios'
    },
    altaUsuario: 'Alta de usuario',
    pantallaContacto: 'Contacto',
    matrizCompatibilidad: 'Compatibilidades de la Sede electrónica',
    descargarDocumentos: 'Descarga de documentos'
}
