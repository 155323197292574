import { makeStyles } from '@material-ui/core/styles'
import { estilosTextosPrincipales, colores, tipografia, buttonSecundaryBig } from '../../../AppStyle'
import { createMuiTheme } from '@material-ui/core';

const  useStyles = (isPhoneVersion) => {
const customStyle = makeStyles({
    classTextoCortado: {
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '25px',
        whiteSpace: 'nowrap'
    },
    classTextoEntero: {
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '25px',
    },
    classGridImg: {
        backgroundColor: colores.escalaGrises.apoyoEspecífico,
        marginBottom: '5%',
        textAlign: 'center',
        height: '125px'
    },
    classParrafo:{
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',
        fontSize: '15px',
    },
    classImg:{
        ...(isPhoneVersion) ? {marginTop: '10%' } : {marginTop: '15%' } ,
    },
    clasGridContenedor: {
        ...(isPhoneVersion) ? { width: '100%' } : { width: '16%' },
        display: 'inline-table',
        marginTop: '1%',
        ...(isPhoneVersion) ? {marginBottom: '5%' } : {marginBottom: '1%' } ,
        marginRight: '5%',
    },
    classInputLabel: {
        ...estilosTextosPrincipales.body2,
        fontFamily: tipografia.regular,
        color: colores.escalaGrises.ochenta,
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: ' 17px',
        marginBottom: '5px',
        position: 'relative',
        '&.Mui-focused': {
            color: colores.escalaGrises.ochenta,
        },
    },
    classSpanRequired: {
        color: colores.principales.required,
        ...estilosTextosPrincipales.body2,
    },
    classButtonCancelar: {
        ...buttonSecundaryBig,
        color: colores.estado.error,
        width: '100%',
        '&:hover': {
            border: `1px solid ${colores.principales.hover}`,        
            color: colores.estado.error 
        },
    },
    classButtonVer: {
        ...buttonSecundaryBig,
        color: colores.principales.principal ,
        marginTop: '2%',
        width: '100%',
        '&:hover': {
            border: `1px solid ${colores.principales.hover}`,        
            color: colores.principales.principal 
        },
    },
    classDivIcon:{
        display: 'flex',
        cursor: 'pointer',
    },
    classIcon:{
        color: colores.principales.principal,
    },
    classGridContenedor: {
        margin: '2.5% 5% 2.5% 0%',
        width: '328px'
    }

});

const overInputs = createMuiTheme({
    overrides: {
        MuiFormHelperText: {//Clases aplicadas en los helpTexts
            root: {
                ...estilosTextosPrincipales.body2,
                color: colores.estado.error,
                fontSize: '12px',
                width: "328px",
            },
            contained: {
                marginLeft: '0px',
                marginRight: '0px',
            }
        },
        MuiInputBase: {//Clases aplicadas en los inputs
            root: {
                height: '48px',
                ...(isPhoneVersion) ? {width: 'auto'} : { width: '328px' },
                '&$disabled': {//Clase aplicada cuando el input está deshabilitado
                    backgroundColor: colores.escalaGrises.contenedor,                    
                },
            },
            input: {
                ...estilosTextosPrincipales.body2,
                fontSize: '14px',
                //lineHeight: '24px',
                letterSpacing: '0px',
                color: colores.escalaGrises.cien,
                '&::placeholder': {
                    ...estilosTextosPrincipales.body2,
                    fontSize: '14px',
                    //lineHeight: '24px',
                    letterSpacing: '0px',
                    opacity: '0.8',
                    color: colores.escalaGrises.apoyo,
                },
            },
        },
        MuiOutlinedInput: {//Clases aplicadas a los bordes de los inputs
            root: {
                '&:hover $notchedOutline': {
                    border: '2px',
                    borderStyle: 'solid',
                    borderColor: colores.principales.principal
                },
                '&$focused $notchedOutline': {
                    border: '2px',
                    borderStyle: 'solid',
                    borderColor: colores.principales.principal
                },
            },
            notchedOutline: {
                border: '1px solid',
                borderColor: colores.principales.apoyo,
                boxSizing: 'border-box',
                borderRadius: '4px',
            },
        },
        MuiInputLabel: {//Evita que se reduzca el título
            shrink: {
                transform: 'none'
            },
        },
        MuiDropzoneArea: {
            root: {
                marginTop: '5%',
                minHeight: '0px',
                borderColor: colores.principales.principal,
            },
            text: {
                marginTop: '2%',
                marginBottom: '0%',
            },
        },
        MuiFormControl : {
            root: {
                ...(isPhoneVersion) && { width: '100%' }
            }
        }
    },
});
return { customStyle, overInputs };
};

export default useStyles;