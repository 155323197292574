import { useState } from "react";
import FormioProvider from '../../../providers/FormioProvider';

const useOperations = () => {
    const [formData, setFormData] = useState();
    const { formioOperation } = FormioProvider();

    /**
     * @function getForm Método que obtiene el recurso de formio
     * @param {*} aliasFormulario Recibe el alias del formulario
     */
    const getResource = async (aliasFormulario) => {
        formioOperation(aliasFormulario, {}, true).then(response => {
            setFormData(response.data);
        })
    }

    return { formData, getResource };
}

export default useOperations;