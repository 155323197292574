export const texts  = {
    PREVIUS_BUTTON_LABEL : 'ANTERIOR',
    NEXT_BUTTON_LABEL : 'SIGUIENTE',
    DOTS : '...',
    MOSTRAR: 'Mostrar',
}

export const properties = {
    WINDOW_SIZE : 5,
    FIRST_PAGE : 1,
    NAV_ROLE: 'navigation',
    NAV_ARIA_LABEL: 'Navegación paginada',
    ARIA_LABEL_SIGUIENTE: 'Ir a la siguiente página',
    ARIA_LABEL_ANTERIOR: 'Ir a la página anterior',
    ARIA_LABEL: 'Ir a la página ',
    ARIA_LABEL_PRIMERA: 'Ir a la primera página',
    ARIA_LABEL_ULTIMA: 'Ir a la última página, página ',
    PREF_ID_A : 'page',
    ARIA_CURRENT_CLAVE : 'aria-current',
    ARIA_LABEL_CLAVE : 'aria-label',
    ARIA_LABEL_CURRENT: 'Página actual, Página ',
    NUM_CINCO: '5',
    NUM_DIEZ: '10',
    NUM_QUINCE: '15',
    TODOS: 'Todos',

}