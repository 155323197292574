import { makeStyles } from '@material-ui/core/styles';
import { backgroundRectangle , estilosTextosPrincipales, colores, tipografia, buttonSecundarySmall, buttonPrimarySmall } from '../../../AppStyle';

const useStyles = (isPhoneVersion) => {
    
    const style = makeStyles({
        gridPageTitle: {
            marginTop: '2%',
            marginLeft: '5%',
            width: '90%'
        },
        pageTitle: {
            ...estilosTextosPrincipales.encabezadoH2,
            fontWeight: 'bold',
            marginBottom: '3%'
        },
        backgroundRectangle: {
            ...backgroundRectangle,
            marginBottom: '0px',
            ...(isPhoneVersion) && { width: '100%'},
        },
        hclassFirma: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            paddingTop: '2%',
            marginLeft: '5%',
            marginBottom: '2%',
        },
        gridBotonesListoAtras: {
            width:'100%', 
            paddingRight: '5%', 
            margin: 0 ,
        },
        botonListo:{
            ...buttonPrimarySmall,
            ...(!isPhoneVersion) ? { margin: '1% 0.5% 1% 1%'} : { margin: '5% 0.5% 3% 1%'},
        },
        gridCentrar:{
            textAlign: 'center',
        },
        classBoton:{
            ...buttonSecundarySmall,
            ...(isPhoneVersion) && { width: '80%'},
            ...(!isPhoneVersion) ? { margin: '1% 0.5% 1% 1%'} : { margin: '5% 0.5% 1% 1%'},
        },
        classMargin:{
            marginBottom: '1%',
        },
        classMarginP:{
            marginBottom: '0.5%',
        },
        classGridParrafo: {
            marginLeft: '5%',
            marginRight: '5%',
        },
        gridRespuesta:{
            marginTop:'25px',
            marginBottom: '20px'
        },
        gridBotonRecibi:{
            paddingBottom:'20px'
        }
    });

    return {style}
}

export default useStyles;