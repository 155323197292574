import React, { useState } from 'react';
import { colores } from './../../../AppStyle';
import { localStorageName } from 'providers/localStorageData';
import { useHistory } from "react-router-dom";
import { saveState, loadState } from './../../../providers/localStorage';
import { buttons, objSelectorProcedimiento, properties, paramAcciones } from './menuNavegacionData';
import { properties as appProperties } from '../../../AppData';
import RestProvider from 'providers/RestProvider';
import debounce from 'lodash.debounce';
import usePrecargas from 'components/commons/Precargas/PrecargasFunction';

/**
 * @function useFunctions Función que contiene constantes y funciones necesarias para utilizar en el componente MenuNavegacion
 * @returns isVisible, mostrarPopUp, handleChangeIsVisible, handleClickGuardar, aceptarContinuacion, cancelarContinuacion, botonPorDefecto
 */
const useFunctions = () => {
    const history = useHistory();
    const pathOrigen = '/MenuNavegacion';
    const idBotonPorDefecto = buttons[0].id;
    const idBotonesExcentos = [idBotonPorDefecto]; //Esta constante contiene los botones que están excentos de que se muestre el popUp, al no tener que guardar valores.

    const {obtenerUrlEndPoint} = usePrecargas();

    //Hooks y funciones utilizadas para la acción de insertar todas las acciones
    const [acciones, setAcciones] = useState(null);
    //Función que recoge los valores obtenidos desde el formulario, obtiene el estado actual del formulario antes de haber pulsado el botón insertar todas las acciones
    const updateAcciones = (value) => {
        setAcciones(value);
    }

    //Hook que obtiene el crudOperation
    const { crudOperation, crudOperationsType } = RestProvider();

    /**
     * @function styleBotonClickado Función que aplica estilo sobre el botón clickado
     * @param {*} botonClickado Valor booleano para saber si se ha clickado o no el botón que corresponde con su id
     * @param {*} idBoton ID del botón que se ha clickado
     */
    const styleBotonClickado = (botonClickado, idBoton) => {
        let backgroundPrimary = colores.principales.fondoEspecifico;
        let colorPrimary = colores.principales.principal;
        let borderColorPrimary = colores.principales.principal;

        let backgroundSecundary = colores.escalaGrises.cero;
        let colorSecundary = colores.escalaGrises.cien;
        let borderColorSecundary = colores.escalaGrises.apoyo;

        //Paso 1. Se aplican los estilos al backgroundColor, color y borderColor
        cambiarEstiloComponente(botonClickado, 'background-color', idBoton, backgroundPrimary, backgroundSecundary);
        cambiarEstiloComponente(botonClickado, 'color', idBoton, colorPrimary, colorSecundary);
        cambiarEstiloComponente(botonClickado, 'border-color', idBoton, borderColorPrimary, borderColorSecundary);
        //Paso 2. Se pone el foco sobre el botón clickado
        document.querySelector(`#${idBoton}`).focus();
    }

    /**
     * @function cambiarEstiloComponente Función auxiliar para cambiar el estilo de un componente si este es clickado
     * @param {*} botonClickado Valor booleano para saber si se ha clickado o no el botón que corresponde con su id
     * @param {*} estilo El estilo que se va a aplicar sobre el componente
     * @param {*} idComponente ID del componente sobre el que se va a cambiar el estilo
     * @param {*} colorPrimary Color primario a aplicar
     * @param {*} colorSecundary Color secundario a aplicar
     */
    const cambiarEstiloComponente = (botonClickado, estilo, idComponente, colorPrimary, colorSecundary) => {
        let color = colorPrimary
        //Paso 1. Se comprueba si el componente se ha clickado, en caso de que no se establecerá la variable color con otro valor
        !botonClickado && (color = colorSecundary);
        //Paso 2. Se aplica el estilo al componente obtenido a través de su ID
        document.querySelector(`#${idComponente}`).style[estilo] = color;
    }

    /**
     * @constant objectButtons Objeto que contiene el valor inicial del hook de estado isVisble.
     * Si se incorporan nuevos botones, habrá que insertar su clave (id) y valor a false. La clave con valor true será el botón seleccionado por defecto.
     */
    const objectButtons = {
        btnGeneracionFormularios: true,
        btnAdministracionProcedimientos: false
    }
    //Obtención del token
    let token = loadState(localStorageName.token, false);

    /**
     * @function buiildSubmission Función que construye el objeto sumbission, según el parámetro recibido
     * @param {*} objeto Recibe el objeto con la precarga de datos
     * @returns 
     */
    const buildSubmission = (valor, idProc) => {
        //Declaración de los objetos submission. 
        let submission = '';

        //Obtención de las urls que se les pasará a los selectores para obtener los valores de su endpoint correspondiente
        let urlEndPointProcedimiento = obtenerUrlEndPoint(properties.RESOURCE_PROCEDIMIENTO, properties.MS_MOAD);
        let urlEndPointFase = obtenerUrlEndPoint(properties.RESOURCE_CATALOGO_VALOR, properties.MS_CATALOGO, properties.URL_GET_CODIGO_CATALOGO, properties.PARAM_CODIGO_CATALOGO_FASES);
        let urlEndpointAcciones = obtenerUrlEndPoint(properties.RESOURCE_ACCIONES, properties.MS_Acciones, properties.URL_GET_ACCIONES);
        let urlEndpointFormularios = obtenerUrlEndPoint(properties.RESOURCE_FORMULARIOS, properties.MS_MODULO_AD, properties.URL_GET_FORMULARIOS); //"http://192.168.0.83:3001/form?type=form&limit=500"

        //Objeto submission con la precarga de datos, recibe los valores a través del parámetro valor
        let dataSubmission = {
            data: {
                ...valor,
                token: `Bearer ${token}`,
                urlProcedimientos: urlEndPointProcedimiento,
                urlFases: urlEndPointFase,
                urlAcciones: urlEndpointAcciones,
                urlFormularios:urlEndpointFormularios
            }
        };
        //Objeto submission con los valores por defecto iniciales
        let dataSubmissionInitValue = {
            data: {
                procedimiento: idProc,
                guardarFormio: false,
                tablaConfiguracionDeFormularios: [{}],
                tablaConfiguracionGlobalDeAnexos: [{}],
                tablaConfiguracionDeAnexos: [{}],
                tablaAcciones: [{}],
                token: `Bearer ${token}`,
                urlProcedimientos: urlEndPointProcedimiento,
                urlFases: urlEndPointFase,
                urlAcciones: urlEndpointAcciones,
                urlFormularios:urlEndpointFormularios
            }
        }
        //Estructura de control que determinal que objeto devolver
        valor === null ? (submission = dataSubmissionInitValue) : (submission = dataSubmission);
        return submission;
    }

    /**************************************** HOOKS ****************************************/
    //Hooks de estado
    const [isVisible, setIsvisible] = useState(objectButtons); //Contiene un objeto con todos los botones y su estado booleano
    const [mostrarPopUp, setMostrarPopUp] = useState(false); //Muestra o no el popup
    const [formGuardado, setFormGuardado] = useState(false);
    const [idBotonClickado, setIdBotonClickado] = useState(idBotonPorDefecto); //Indica el id del boton clickado, por defecto tendrán el botón por defecto.
    const [isPhoneVersion, setIsPhoneVersion] = useState(false); //Indica si es versión móvil o no
    const [submissionData, setSubmissionData] = useState(buildSubmission('', '')); //Contiene el json de un formulario ya guardado anteriormente
    const [isLoading, setIsLoading] = useState(false);
    const [reiniciar, setReiniciar] = useState(false);

    //Inicialización necesaria para el componente Alert de mensajes y errores
    const [message, setMessage] = useState({ msg: "", severity: "" });
    const [alerts, setAlerts] = useState([]);
    let _ID = 0;
    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);

    let idProcedimientoPr = null;

    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }

    /**
     * @function buttonsState Función auxiliar utilizada para establecer el estado de los botones del menú de navegación y su estilo.
     * @param {*} idBtnClickado Recibe el id del botón que se ha clickado
     */
    const buttonsState = (idBtnClickado) => {
        // Paso 1. Se obtienem las keys del objeto objectButtons                        
        let keysObjectButtons = Object.keys(isVisible);
        //Paso 2. Se recorre el objeto keysObjectButtons
        for (const idButton of keysObjectButtons) {
            //Paso 3. Se inserta el valor false a todos las keys que no correspondan con el id del botón clickado para reinicializar los valores.
            if (idBtnClickado !== idButton) {
                isVisible[idButton] = false;
                //Paso 3.1. Se reinicializan los estilos de los botones
                styleBotonClickado(false, idButton);
            }
        }
        //Paso 4. Se inserta el valor true para la hook visible que corresponda con el botón clickado
        setIsvisible({
            ...isVisible,
            [idBtnClickado]: true
        });
        //Paso 4.1. Se inserta el estilo al botón clickado
        styleBotonClickado(true, idBtnClickado);
    }

    /**
     * @function handleChangeIsVisible Función para manejar el cambio de estado de la hook visible
     * @param {*} e Recibe el evento por parámetros
     */
    const handleChangeIsVisible = (e) => {
        //Paso 1. Se obtiene el id del botón clickado y se inserta en la hook idBotonClickado
        let idBoton = e.currentTarget.id;
        setIdBotonClickado(idBoton);

        /*Paso 2. Se comprueba si se han guardado los valores del formulario o si el id del botón clickado no se encuentra en el array de botones excentos
        o si el componente activo es generacion de formularios*/
        if (formGuardado || !idBotonesExcentos.includes(idBoton) || isVisible.btnGeneracionFormularios) {
            //Paso 2.1. Se inserta el valor (false) a la hook mostrarPopUp
            setMostrarPopUp(false);
            //Paso 2.2. Se llama a la función buttonState y se introduce por parámetros el id del botón clickado
            buttonsState(idBoton);
            //Paso 2.3. Se reinicializa el Hook que almacena si se ha pulsado o no el botón de guardar formulario (fotmGuardado)
            setFormGuardado(false);
        } else {
            //Paso 3. Se inserta el valor (true) a la hook mostrarPopUp
            setMostrarPopUp(true);
        }
    }

    /**
     * @function botonPorDefecto Función que establece por defecto el botón seleccionado al cargar la página.
     * Se captura el botón a través de su id, se aplica el evento focus y se establece el estilo por defecto con la función styleBotonClickado.
     * @param {*} id Recibe el id del botón por parámetros
     */
    const botonPorDefecto = (id) => {
        document.querySelector(`#${id}`).focus();
        styleBotonClickado(true, id);
    }

    /**
     * @function getCongiProc Función que realiza la llamada al microservicio del módulo de administración para obtener los valores para la precarga del objeto submission
     * @param {*} idProc Recibe por parámetros el id del procedimiento
     */
    const getConfiProc = (idProc) => {
        //Se establece a true la hook isLoading durante la llamada
        setIsLoading(true);
        let objetoSubmission = '';
        crudOperation(crudOperationsType.CUSTOM, appProperties.MOD_ADMIN_CONTROLLER, { url: appProperties.BUSCAR_CONFPROCE + '/' + idProc, method: 'get' }).then(response => {
            /* Paso 1. se eliminan las filas de los anexos 
                Se recorre un bucle for of con que recibe un array con las referencias de los botones y se ejecuta la función vaciarAnexo
            */
            for (const eliminar of botones) {
                vaciarAnexo(eliminar);
            }

            let objJson = response.data.jsonFormulario;

            for(const key in objJson){
                if(Array.isArray(objJson[key]) && objJson[key].length === 0){
                    objJson[key] = [{}]
                }
            }

            //Paso 1. Se obtiene el objeto y se inserta en la hook submissionData
            objetoSubmission = buildSubmission(objJson, '');
            setSubmissionData(objetoSubmission);
        }).catch(function (error) {
            /* Paso 1. se eliminan las filas de los anexos 
                Se recorre un bucle for of con que recibe un array con las referencias de los botones y se ejecuta la función vaciarAnexo
            */
            for (const eliminar of botones) {
                vaciarAnexo(eliminar);
            }
            /*Paso 1.1. Estructura de control que impide que se mueste el mensaje cuando el select de procedimientos está vacío*/
            idProc !== '' &&
                handleSetMessage('No existe configuración realizada para este procedimiento', 'warning');
            /*Se estabelce a false la hook isLoading tras recibir el error de la llamada*/            
            setIsLoading(false);
        }).finally(function () {
            //Se establece a false la hook isLoading tras finalizar la llamda
            setIsLoading(false);
        });
    }

    //Referencias de los botones que eliminan las filas de cada anexo (datagrid)
    const bntTablaConfiguracionDeFormularios = "datagrid-tablaConfiguracionDeFormularios-removeRow";
    const btnTablaConfiguracionGlobalDeAnexos = "datagrid-tablaConfiguracionGlobalDeAnexos-removeRow";
    const btnTablaConfiguracionDeAnexos = "datagrid-tablaConfiguracionDeAnexos-removeRow";
    const btnTablaAcciones = "datagrid-tablaAcciones-removeRow";
    const botones = [bntTablaConfiguracionDeFormularios, btnTablaConfiguracionGlobalDeAnexos, btnTablaConfiguracionDeAnexos, btnTablaAcciones];
    /**
     * @function handleChange Función que efectúa el cambio de estado
     * @param {*} value Recibe el valor del selector de procedimientos
     */
    const handleChange = (value) => {
        //Paso 1. Se evalúa con la estructura de control que el valor del selector no es undefined
        if (value.changed !== undefined) {
            //Paso 2. Se evalúa con la estructura de control que el componente se trata de un procedimiento
            if (value.changed.component.key === objSelectorProcedimiento.nombre) {
                //Paso 3. Se inserta el id procedimiento que se usará en la función onSubmit
                idProcedimientoPr = value.changed.value;
                //Paso 3.1. Se reinicializa la hook submissionData por si previamente estuviera inicializada
                setSubmissionData(buildSubmission(null, idProcedimientoPr));
                //Paso 3.2. Se realiza la llamada a la función getConfiProc para obtener el objeto submission
                getConfiProc(idProcedimientoPr);
            }
        }
    }
    /**
     * @function onSubmit Función que se ejecutará en el componente FormRecurso al ejecutarse el evento onSubmit
     */
    const onSubmit = debounce ((data) => {
        let datosSalto = {
            idConfProcedimiento: null,
            idProcedimiento: idProcedimientoPr,
            jsonFormulario: data.data
        }

        
            crudOperation(crudOperationsType.CUSTOM, appProperties.MOD_ADMIN_CONTROLLER, { url: appProperties.GUARDAR_CONFPROCE, method: 'post', data: datosSalto }).then(response => {
                setFormGuardado(true);
            }).catch(function (error) {
                handleSetMessage("Ha habido un error al intentar guardar los datos. " + error, 'Error');
            });
            //Desplazamiento a la cabecera de la página
            window.scroll(0, 0);
            let botonGuardar = document.querySelector('#btnGuardar');
            let icono = document.querySelector('#btnGuardar > i');
            //Se elimina si existe "la ruleta" que contiene el botón guardar cuando se produce el evento onSubmit
            icono !== null &&
                botonGuardar.removeChild(icono);    
        
        
    }, properties.MEDIO_SEGUNDO);

  
    /**
     * @function cancelarContinuacion Función que cancela la continuación del formulario cunado no se han guardado los valores de este
     */
    const cancelarContinuacion = accion => {
        setMostrarPopUp(accion);
    }

    /**
     * @function aceptarContinuacion Función que acepta la continuación del formulario cunado no se han guardado los valores de este
     */
    const aceptarContinuacion = () => {
        //Paso 1. Se inserta la ruta a la que se redirigirá
        saveState(localStorageName.redirect, pathOrigen, false);
        history.push(pathOrigen);
        //Paso 2. Se establece a (false) la hook que almacena el estado del componente para que se oculte el popup
        setMostrarPopUp(false);
        //Paso 3. Se llama a la función buttonState
        buttonsState(idBotonClickado);
        //Paso 4. Se reinicia la hook submission
        setSubmissionData(null, '');
    }

    /**
     * @function updateFormat Actualiza el formato de la página
     */
    const updateFormat = () => {
        if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
            setIsPhoneVersion(true);
        } else {
            setIsPhoneVersion(false);
        }
    };

    /**
     * @function vaciarAnexo Función que elimina el contenido de los anexos
     * @param {*} referencia Referencia del DOM del botón que se pulsará para eliminar la fila
     */
    const vaciarAnexo = (referencia) => {
        //Paso 1. Se obtiene el botón a pulsar 
        let botonRemove = document.querySelectorAll(`[ref="${referencia}"]`);
        //Paso 2. Se comprueba que el elemento del DOM exista. Si se cumple la condición se pulsará el botón y este eliminará la fila.
        if (botonRemove !== null) {
            //Paso 3. Recorre en un bucle todos los botones existentes y los pulsa
            for (const remove of botonRemove) {
                remove.click();
            }
        }
    }

    /**
     * @function getAcciones Función que obtiene todas las acciones disponibles y obtiene el estado actual del formulario y lo devuelve con las acciones obtenidas
     * @param {*} formularioActual 
     */
         const getAcciones = async (formularioActual) => {
            //Paso 1. Se realiza llamada al endPoint getAcciones
            crudOperation(crudOperationsType.CUSTOM, paramAcciones.RESOURCE_LISTADO_ACCIONES, { url: paramAcciones.URL_GET_ACCIONES, method: 'get' }).then(response => {
                let acciones = [];          
                //Paso 2. Se recorre el array obtenido con las acciones y se insertan en el array acciones. Se inserta con la estructura que espera formio.
                for (const accion of response.data) {
                    acciones.push({accion:{...accion}});
                }
                /*Paso 3. Se inserta en la hook submissionData el objeto al completo para el submission enviado al formulario, se inserta el formularioActual
                (valores actualmente insertados en el formulario antes de pulsar el botón añadir todas las acciones) y las acciones obtenidas*/
                setSubmissionData({
                    data: {
                        ...formularioActual,
                        tablaAcciones: acciones// Se sobreescribe los valores de la clave tablaAcciones con todas las acciones obtenidas
                    }
                });
            });
        }

    return {
        isVisible, mostrarPopUp, handleChangeIsVisible, aceptarContinuacion, cancelarContinuacion, botonPorDefecto, isPhoneVersion,
        updateFormat, onSubmit, handleChange, submissionData, alerts, setMessage, message, isLoading, updateAcciones, acciones, setAcciones, getAcciones
    };
}
export default useFunctions;