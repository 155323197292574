//Componente importado de material ui
import { createMuiTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colores, buttonPrimarySmall } from '../../../AppStyle';

const useStyles = (isChecked, isPhoneVersion) => {

    /**
 * @constant theme
 * Contiene las clases de material que se aplican al componenteRadioButton
 * Sobreescribe las clases de material con el estilo que aquí se aplica
 */
    const theme = createMuiTheme({
        overrides: {
            MuiIconButton: {
                label: {
                    boxSizing: 'border-box',
                    color: colores.principales.principal,
                    border: '12px'
                },
            },
            MuiFormControl: {
                root: {
                    ...(!isPhoneVersion) ? { width: '30%'} : { width: '100%'},
                    height: '100%',
                },
            },
            MuiFormGroup: {
                root: {
                    height: '100%',
                    display: '',
                },
            },
            MuiPopover: {
                root: {
                    '&3000':{
                        marginTop: '23% !important' 
                    }
                },
            },
        },
    });

    const style = makeStyles({
        gridRadioPdf: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: colores.escalaGrises.contenedor,
            height: '90px',
            marginBottom: '5%',
            borderRadius: '4px',
            border: '2px solid ' + colores.principales.principal,
            //...(isChecked) && {border: `solid 3px ${colores.principales.principal} !important`},
            ...(isPhoneVersion) && { marginRight: '5%'},
        },
        classWithColorRadio: {
            ...(!isPhoneVersion) && { display: 'flex'},
        },
        classImg: {
            height: '60%',
        },
        classTxtPdf: {
            marginLeft: '5%',
            fontWeight: 'bold',
            marginRight: '5%',
            fontSize: 'smaller'
        },
        classMarginButton: {
            marginLeft: '5%',
        },
        botonListo:{
            ...buttonPrimarySmall,
            ...(isPhoneVersion) && { marginBottom: '5%'},
        },
        gridBoton: {
            width: '100%',
            alignContent: 'center',
            justifyItems: 'center',
            display: 'grid',
        },
        prueba: {
            marginTop: '10px',
        },
        classGridPdfWeb: {
            width: '100%',
            marginLeft: '1%',
            marginRight: '1%',
        }
    });

    return { theme, style };
}

export default useStyles;