import React, {useState} from 'react';


const useFunctions = () => {

    const [mostrarPDF, setMostrarPDF] = useState(false);
    
    /**
     * @function cerrarPopUpPdf Actualiza el hook que controla el pop up para visualizar pdfs y lo cierra
     */
     const cerrarPopUpPdf = () =>{
        setMostrarPDF(false);
    };

    /**
     * @function cerrarPopUpPdf Actualiza el hook que controla el pop up para visualizar pdfs y lo cierra
     */
     const cortarTexto = (texto) =>{
        return (texto.length <= 90) ? (texto) : (texto.substring(0, 90) + '...');
    };

    /**
     * @function mostrarPopUpPdf Actualiza el hook que controla el pop up para visualizar pdfs y lo abre
     */
     const mostrarPopUpPdf = () =>{
        setMostrarPDF(true);
    };

    return { mostrarPDF, cerrarPopUpPdf, mostrarPopUpPdf, cortarTexto};

}
export default useFunctions;