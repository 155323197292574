import {makeStyles} from '@material-ui/core/styles'
import {colores, tipografia} from '../../../AppStyle'

export const customStyle = makeStyles ({
  linkPrincipal: {
    textDecoration: 'none',
    fontFamily: tipografia.regular,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '17px',
    lineHeight: '20px',
    color: colores.principales.principal,
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    '&:hover':{
      textDecoration: 'underline'

    },
    '&:focus':{
      textDecoration: 'underline',
      outline: 'none'
    }
},
  linkSecundario: {
    fontFamily: tipografia.regular,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '17px',
    lineHeight: '20px',
    color: colores.escalaGrises.cien,
    cursor: 'pointer'
}, 

paginaActual: {
  fontFamily: tipografia.regular,
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '17px',
  lineHeight: '20px',
  color: colores.escalaGrises.cien,
}, 
  separador: {
    color: colores.escalaGrises.cien,
    fontSize: '20px',
    fontFamily: tipografia.bold,
    marginRight: '10px',
    marginLeft: '10px'
  },
  separadorPhoneVersion: {
    color: colores.escalaGrises.cien,
    fontSize: '20px',
    fontFamily: tipografia.bold,
    marginRight: '10px',
    marginLeft: '10px',
    transform: 'rotate(180deg)',
    paddingBottom: '40%',
  }
});
