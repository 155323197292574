import {useState} from 'react';
import RestProvider from 'providers/RestProvider';
import { useHistory } from "react-router-dom";
import { cruds, valoresExtranjero, propertiesPCT3 } from './pageAltaUsuarioData';
import { getUrl } from "config/Config";
import FormioProvider from "providers/FormioProvider";
import { localStorageName } from 'providers/localStorageData';
import {saveState} from 'providers/localStorage'
export const AltaEdicionUsuario = () =>{
    const { crudOperation, crudOperationsType } = RestProvider();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [hayRepresentante, setHayRepresentante] = useState(null);
    const [message, setMessage] = useState({ msg: '', severity: '' });
    const handleSetMessage = (msg, severity) => {
        setMessage({ msg, severity });
        addAlert(message);
    }
    let _ID = 0;
    const addAlert = msg => setAlerts([...alerts, { id: _ID++, msg }]);
    const { formioOperation } = FormioProvider();
    const [formData, setFormData] = useState();

    let provExtranjero;
    let idProvExtranjero;
    let idMuniExtranjero;   

    /**
     * @function handleOnSubmit Función que se ejecutará cuando se produzca en el formulario el evento onSubmit
     */    
    const handleOnSubmit = async (data, altaUsuario) => {
        setIsLoading(true);
        let interesado = {};
        // Paso 1. Se construye el objeto que requiere el endpoint recibir por parámetros
        const obj = await obtenerObjetoInteresadoPCT3(data);
        // Paso 2. Se realiza la creación o actualización (en caso de que ya exista) del interesado        
        crudOperation(crudOperationsType.CUSTOM, cruds.interesado.resource, { url: cruds.interesado.url, method: 'post', data: obj }).then(response => {            
            setIsLoading(false);
            if (altaUsuario && localStorage.getItem('urlProcedenciaProcedimiento')) {
                history.push('/BandejaSolicitudes')
            } else {
                history.goBack();
            }
            //seteamos la infoUsuario a la que es:
            const usuarioLocalStorage = JSON.parse(localStorage.getItem(localStorageName.infoUsuario)) || {};
            const nuevoUsuario = {
                ...usuarioLocalStorage,
                interesadoNombre: obj.interesado1.interesado.nombre || '',
                interesadoApellido1: obj.interesado1.interesado.apellido1 || '',
                interesadoApellido2: obj.interesado1.interesado.apellido2 || ''
            };
            
            // Guardar el objeto actualizado en localStorage
            let usuarioJson = JSON.stringify(nuevoUsuario);
            saveState(localStorageName.infoUsuario, usuarioJson, false);
       }).catch(()=>{
            setIsLoading(false);
            handleSetMessage("Ha ocurrido un error al intentar guardar los datos del interesado.", 'error');
        });
        return interesado;
    }

  /**
   * @function getForm Método que obtiene el recurso de formio
   * @param {*} aliasFormulario Recibe el alias del formulario
   */
    const getResource = async (aliasFormulario) => {
        formioOperation(aliasFormulario, {}, true).then(response => {
            setFormData(response.data);
        })
    }

    /**
     * @function handleCustomEvents Función que captura los eventos de los botones customizados
     * @param {*} customEvent 
     */
    const handleCustomEvents = async (customEvent, altaUsuario) => {   
        // Captura cuando se hace click sobre el botón Atrás
        if (customEvent.type === 'btnAtras') {
            if (altaUsuario && localStorage.getItem('urlProcedenciaProcedimiento')) {
                history.push(`/SeleccionConvocatoriaPerfil${localStorage.getItem('urlProcedenciaProcedimiento')}`)
            } else {
                history.goBack();
            }
        }
    }    

    const obtenerObjetoInteresadoPCT3 = async (respuestaInteresado) => {
        //Interesado
        const componentesPersonales = respuestaInteresado.data.componentesPersonales;
        const componentesDireccion = respuestaInteresado.data.componentesDireccion;

        const idInte = componentesPersonales.idInteresado;
        const idDatosInte = componentesDireccion.idDatosInteresado;

        const codTipoVia = componentesDireccion.tipoVia.codigo;
        const descTipoVia = componentesDireccion.tipoVia.descripcion;
        const idTipoVia = componentesDireccion.tipoVia.id;
        const idPais = componentesDireccion.paisSelector.id;
        const codPais = componentesDireccion.paisSelector.codigo;
        let cpostal = componentesDireccion.postal;

        let provinciaAndaluza = true;
        let idProvincia = null;
        let idMunicipio = null;
        let codMunucipio = null;
        let codProvincia = null;
        let descripcionCortaPais = null;
        let nomProvincia = null;
        let descripcionMunicipio = null;
        //Código del país del repre. aquí para ver si hay que obtener los
        //id de la pro. y muni. extranjeros        
        if(codPais !== valoresExtranjero.COD_SPAIN){
            await provMuniExtranjero();
        }
        if(hayRepresentante){
            const codPaisRepre = respuestaInteresado.data.componentesDireccionRepresenante
            .paisSelectorRepresentante.codigo;
            if(codPaisRepre !== valoresExtranjero.COD_SPAIN){
                await provMuniExtranjero();
            }                        
        }
        //Si es España
        if(codPais === valoresExtranjero.COD_SPAIN){
            idProvincia = componentesDireccion.provinciaSelector.id;
            idMunicipio = componentesDireccion.municipioSelector.id;
            codMunucipio = componentesDireccion.municipioSelector.codigo;
            codProvincia = componentesDireccion.provinciaSelector.codigo;
            descripcionCortaPais = componentesDireccion.paisSelector.descripcionCorta;
            nomProvincia = componentesDireccion.provinciaSelector.nombre;
            descripcionMunicipio = componentesDireccion.municipioSelector.descripcion;
        }else{
            idProvincia = idProvExtranjero;
            idMunicipio = idMuniExtranjero;
            provinciaAndaluza = false;
            nomProvincia = valoresExtranjero.CADENA_EXTRANJERO;
            descripcionMunicipio = valoresExtranjero.CADENA_EXTRANJERO;
            codProvincia = valoresExtranjero.COD_PROVINCIA_EXTRANJERO;
            codMunucipio = valoresExtranjero.CADENA_EXTRANJERO;
            cpostal = valoresExtranjero.CP;
        }
        const sexo = componentesPersonales.sexo;
        const fechaNacimiento = await parsearFecha(componentesPersonales.fechaNacimiento);
        const obsoleto = "N";
        const codTipoIdentificadorInte = await calcularTipoIdentificador(componentesPersonales.dNINIENIF);
        const objInteresado = {
            interesado1: {
                interesado: {
                    id: idInte,
                    nombre: componentesPersonales.nombreRazonSocialDenominacion,
                    apellido1: componentesPersonales.primerApellido,
                    apellido2: componentesPersonales.segundoApellido,
                    tipoIdentificador: codTipoIdentificadorInte,
                    identificador: componentesPersonales.dNINIENIF,
                    sexo: sexo,
                    fechaNacimiento: fechaNacimiento,
                    datosContactoPorDefecto: {}
                },
                datosContacto: {
                    id: idDatosInte,
                    idDatoExpediente: null,
                    interesado: {},
                    tipoInteresado: null,
                    tipoNotificacion: null,
                    tipoVia: {
                        id: idTipoVia,
                        codigo: codTipoVia,
                        descripcion: descTipoVia,
                        obsoleto: obsoleto
                    },
                    nombreVia: componentesDireccion.nombreDeLaVia,
                    numero: componentesDireccion.numero,
                    letra: componentesDireccion.letra,
                    escalera: componentesDireccion.escalera,
                    piso: componentesDireccion.piso,
                    puerta: componentesDireccion.puerta,
                    postal: cpostal,
                    telefono: componentesPersonales.telefonoFijo,
                    telefonoMovil: componentesPersonales.telefonoMovil,
                    fax: "",
                    email: componentesPersonales.correoElectronico,
                    idPais: idPais,
                    pais: {
                        id: idPais,
                        descripcionCorta: descripcionCortaPais,
                        codigo: codPais,
                    },
                    provincia: {
                        id: idProvincia,
                        codigo: codProvincia,
                        andaluza: provinciaAndaluza,
                        pais: {
                            id: idPais,
                            descripcionCorta: descripcionCortaPais,
                            codigo: codPais,
                        },
                        nombre: nomProvincia
                    },
                    municipio: {
                        id: idMunicipio,
                        codigo: codMunucipio,
                        provincia: {
                            id: idProvincia,
                            codigo: codProvincia,
                            andaluza: provinciaAndaluza,
                            pais: {
                                id: idPais,
                                descripcionCorta: descripcionCortaPais,
                                codigo: codPais,
                            },
                            nombre: nomProvincia
                        },
                        descripcion: descripcionMunicipio
                    },
                    idProvincia: idProvincia,
                    idMunicipio: idMunicipio,
                    region: "",
                    ciudad: "",
                    fechaBaja: "",
                    isNotificacion: ""
                }
            },
            interesado2: null
        };

        if(hayRepresentante){
            //Datos represenante            
            const componentesPersonalesRepre = respuestaInteresado.data.componentesDatosRepresentante;
            const componentesDireccionRepre = respuestaInteresado.data.componentesDireccionRepresenante;
            const idRepre = componentesPersonalesRepre.idRepresentante;
            //Direccion represenante
            const idDatosRepre = componentesDireccionRepre.idDatosRepre;
            const idPaisRepre = componentesDireccionRepre.paisSelectorRepresentante.id;
            const descPaisRepre = componentesDireccionRepre.paisSelectorRepresentante.descripcionCorta;
            //const codPaisRepre = componentesDireccionRepre.paisSelectorRepresentante.codigo;
            let cPostal = componentesDireccionRepre.postalRepresentante;

            let idProvRepre = null;
            let codProvRepre = null;
            let nomProvRepre = null;

            let idMuniRepre = null;
            let codMuniRepre = null;
            let nomMuniRepre = null;
            
            const codPaisRepre = respuestaInteresado.data.componentesDireccionRepresenante
            .paisSelectorRepresentante.codigo;
            //Si es España
            if(codPaisRepre === valoresExtranjero.COD_SPAIN){
                idProvRepre = componentesDireccionRepre.provinciaSelectorRepresentante.id;
                codProvRepre = componentesDireccionRepre.provinciaSelectorRepresentante.codigo;
                nomProvRepre = componentesDireccionRepre.provinciaSelectorRepresentante.nombre;
                idMuniRepre = componentesDireccionRepre.municipioSelectorRepresentante.id;
                codMuniRepre = componentesDireccionRepre.municipioSelectorRepresentante.codigo;
                nomMuniRepre = componentesDireccionRepre.municipioSelectorRepresentante.descripcion;                
            }else{
                idProvRepre = idProvExtranjero;
                idMuniRepre = idMuniExtranjero;
                codProvRepre = valoresExtranjero.COD_PROVINCIA_EXTRANJERO;
                nomProvRepre = valoresExtranjero.CADENA_EXTRANJERO;
                codMuniRepre = valoresExtranjero.COD_MUNI_EXTRANJERO;
                nomMuniRepre = valoresExtranjero.CADENA_EXTRANJERO;
                cPostal = valoresExtranjero.CP;
            }

            const codTipoIdentificadorRepre = "CIF";
            const representante = {
                interesado: {
                    id: idRepre,
                    nombre: componentesPersonalesRepre.nombreRazonSocialDenominacionRepresentante,
                    apellido1: null,
                    apellido2: null,
                    tipoIdentificador: codTipoIdentificadorRepre,
                    identificador: componentesPersonalesRepre.dNINIENIFRepresentante,
                    sexo: null,
                    fechaNacimiento: null,
                    datosContactoPorDefecto: {}
                },
                datosContacto: {
                    id: idDatosRepre,
                    idDatoExpediente: null,
                    interesado: {},
                    tipoInteresado: null,
                    tipoNotificacion: null,
                    tipoVia: {
                        id: componentesDireccionRepre.tipoViaRepresentante.id,
                        codigo: componentesDireccionRepre.tipoViaRepresentante.codigo,
                        descripcion: componentesDireccionRepre.tipoViaRepresentante.descripcion,
                        obsoleto: obsoleto
                    },
                    nombreVia: componentesDireccionRepre.nombreDeLaViaRepresentante,
                    numero: componentesDireccionRepre.numeroRepresentante,
                    letra: componentesDireccionRepre.letraRepresentante,
                    escalera: componentesDireccionRepre.escaleraRepresentante,
                    piso: componentesDireccionRepre.pisoRepresentante,
                    puerta: componentesDireccionRepre.puertaRepresentante,
                    postal: cPostal,
                    telefono: componentesPersonalesRepre.telefonoFijoRepresentante,
                    telefonoMovil: componentesPersonalesRepre.telefonoMovilRepresentante,
                    fax: "",
                    email: componentesPersonalesRepre.correoElectronicoRepresentante,
                    idPais: idPaisRepre,
                    pais: {
                        id: idPaisRepre,
                        descripcionCorta: descPaisRepre,
                        codigo: codPaisRepre,
                    },
                    provincia: {
                        id: idProvRepre,
                        codigo: codProvRepre,
                        andaluza: provinciaAndaluza,
                        pais: {
                            id: idPaisRepre,
                            descripcionCorta: descPaisRepre,
                            codigo: codPaisRepre,
                        },
                        descripcionCorta: nomProvRepre
                    },
                    municipio: {
                        id: idMuniRepre,
                        codigo: codMuniRepre,
                        provincia: {
                            id:idProvRepre,
                            nombre: nomProvRepre,
                            codigo: codProvRepre,
                            andaluza: provinciaAndaluza,
                            pais: {
                                id: idPaisRepre,
                                descripcionCorta: descPaisRepre,
                                codigo: codPaisRepre,
                            }
                        },
                        descripcionCorta: nomMuniRepre
                    },
                    idProvincia: idProvRepre,
                    idMunicipio: idMuniRepre,
                    region: "",
                    ciudad: "",
                    fechaBaja: "",
                    isNotificacion: ""
                }
            };
            objInteresado.interesado2 = representante;
        }
        return objInteresado;
    }

    const provMuniExtranjero = async () =>{
        await getProvinciasPCT3();
        await getMunicipiosPCT3();
    }

    /**
     * @function getProvinciasPCT3 Obteiene un listado de provincias
     */
    const getProvinciasPCT3 = async () => {
        //Paso 1. Obtención del listado de provincias
        const urlProv = 'findAll';
        await crudOperation(crudOperationsType.CUSTOM, propertiesPCT3.resource.provincias,
                                    { url: urlProv, method: 'get' }).then(response => {
            const listadoProvincias = response.data;
            //Paso 1. Obtención de la provincia EXTRANJERO
            for (const provincia of listadoProvincias) {
                if (provincia.nombre === valoresExtranjero.CADENA_EXTRANJERO) {
                    provExtranjero = provincia;
                    idProvExtranjero = provincia.id;
                }
            }
        }).catch(function () {
            handleSetMessage("Ha ocurrido un error al intentar recuperar los datos de provincias.", 'error');
        });
    }

    /**
     * @function getMunicipiosPCT3 Obteiene un listado de los municipios
     */
    const getMunicipiosPCT3 = async () => {
        // Obtenemos el municipio "EXTRANJERO"
        await crudOperation(crudOperationsType.CUSTOM, propertiesPCT3.resource.municipios,
            { url: propertiesPCT3.url.provincia.concat(provExtranjero.codigo) + propertiesPCT3.pageable.municipios, method: 'get' })
            .then(response => {
                idMuniExtranjero = response.data[0].id;
        }).catch(function () {
            handleSetMessage("Ha ocurrido un error al intentar recuperar los datos de municipios.", 'error');
        });  
    }

    const parsearFecha = async (fecha) => {
        let fechaFormatoCorrecto;
        if(fecha){
            fechaFormatoCorrecto = fecha.substring(0, 10);
        }
        return fechaFormatoCorrecto;
    }

    /**
     * @function calcularTipoIdentificador Función que calcula el tipo de identificador, a partir de los datos
     * del certificado digital con el que se autentíque el interesado
     * @returns tipoIdentificador
     */
    const calcularTipoIdentificador = async (identificador) => {
        const NIE = ['X', 'Y', 'Z'];
        let tipoIdentificador = null;
        if(identificador !== undefined && identificador !== null && identificador !== ""){
            if (NIE.includes((identificador).substring(0,1))) {
                tipoIdentificador = 'NIE';//Persona física extranjera
            } else {
                tipoIdentificador = 'NIF';//Persona física española
            }
        }
        return tipoIdentificador;   
    }
    
    return {
        handleOnSubmit,
        handleCustomEvents,
        isLoading,
        alerts,
        message,
        setMessage,
        getResource,
        formData,
        setAlerts,
        setHayRepresentante,
      };        

}
