import { getContextFromUrl } from 'config/Config';

export const texts = {
    TEXT_BTN_ANT: 'Anterior',
    TEXT_BTN_SIG: 'Siguiente',
    PAGINA: 'Página ',
    DE: ' de ',
    TEXTO_AUMENTAR_ZOOM: 'Aumentar Zoom',
    TEXTO_DISMINUIR_ZOOM: 'Disminuir Zoom',
    TEXTO_DESCARGAR: 'Descargar',
    TEXTO_SIGUIENTE_PAGINA: 'Siguiente página',
    TEXTO_ANTERIOR_PAGINA: 'Página anterior',
}

export const properties = {
    idBtnAnt: 'idBtnAnt',
    idBtnSig: 'idBtnSig',
    idDivPdf: 'idPdf',
    imgDescarga: `${getContextFromUrl()}` + 'download.png',
    ELEMENTO_DESCARGA: 'a',
    imgZoomMas: `${getContextFromUrl()}` + 'mas.png',
    imgZoomMenos: `${getContextFromUrl()}` + 'menos.png',
    imgArrowLeft: `${getContextFromUrl()}` + 'arrowLeft.png',
    imgArrowRigth: `${getContextFromUrl()}` + 'arrowRigth.png',
}