import { getContextFromUrl } from 'config/Config';

export const texts = {
    searchBarPlaceholder: 'Buscar...',
    searchBarLabel: 'Buscar...',
    searchIconAlt : 'Buscar',
    cleanText: "Borrar",
    idButton: "buscador"
}

export const paths = {
    searchIconPath : `${getContextFromUrl()}` +  'searchBar/lupa.svg'
}

export const properties = {
    minLettersForAutocomplete : 3
}