import { rutas, caminos, properties, nombresMigas } from './BreadcrumbsData';
import { useState } from "react";
import { loadState } from 'providers/localStorage';
import { localStorageName } from 'providers/localStorageData';
import { getUrl, getContextFromUrl } from 'config/Config';

const useOperations = () => {
    //Hook que almacena el camino
    const contexto = getUrl() + '#/';
    const contextoUrl = getContextFromUrl();
    const [camino, setCamino] = useState(properties.pantallaPrincipal);
    let urlProcedimiento = loadState(localStorageName.urlProcedenciaProcedimiento, false);

    //Array que contiene el nombre de la ruta que se desee extraer de una ruta compuesta. Ej.: creacionFormularios/form
    let rutasCompuestas = ['creacionFormularios', 'seleccionConvocatoriaPerfil'];  

    /**
     * @function mostrarMiga Función auxiliar para verificar si ha de mostrarse la miga de pan.
     * @param {*} path Ruta completa
     * @returns Devuelve true si ha de mostrarse la miga.
     */
    const mostrarMiga = (rutaActual) => {
        // Verificar que la ruta actual no es '#/' o contiene las posibles rutas de inicio definidas.
        return (rutaActual !== '#/' 
                && !rutaActual.includes('#/retorno.do') 
                && !rutaActual.includes('#/cerrarSesion.do'));
      }

    /**
     * @function extraerRutas Función auxiliar para extrar una ruta de una ruta compuesta.
     * @param {*} ruta Ruta completa
     * @param {*} rutaExtraer Cadena a extraer de la ruta
     * @returns Devuelve la ruta interesada
     */
    const extraerRutas = (ruta, rutaExtraer) => {
        return ruta.substr(ruta.indexOf(rutaExtraer), rutaExtraer.length);
    }

    /**
     * @function obtenerCamino Función que obtiene el camino de la pantalla actual
     * @param rutaActual Se recibe la ruta de la pantalla actual
     */
    const obtenerCamino = (rutaActual) => {
        //Paso 1. Se realiza el tratamiento sobre la cadena obtenida, obteniendo solo el texto de la ruta
        //Paso 1.1. Se extrae los dos primeros caracteres (#/)
        rutaActual = (rutaActual.substr(2, rutaActual.length));
        //Paso 1.2. Se establece a minúsucla el primer caracter
        rutaActual = (rutaActual.substr(0, 1).toLowerCase() + rutaActual.substr(1, rutaActual.length));
        //Paso 1.3. En caso de que contenga al final el caracter (/) se extrae
        let ultimaPosicion = (rutaActual.substr(rutaActual.length - 1, rutaActual.length));
        rutaActual = (ultimaPosicion === '/') ? rutaActual.substr(0, rutaActual.length - 1) : rutaActual;
        //Paso 1.4. Casuísticas expecíficas de rutas compuestas
        //Paso 1.4.1. Se comprueba si la cadena contiene el caracter (/)
        if (rutaActual.includes('/')) {
            //Paso 1.4.2. Se recorre con un bucle forof el array que contiene las rutasCompuestas
            for (const rutaExtraer of rutasCompuestas) {
                //Se comprueba si la ruta contiene la ruta a extrar, si es así, se ejecuta la función de lo contrario continua el bucle
                if (rutaActual.includes(rutaExtraer)) {
                    rutaActual = extraerRutas(rutaActual, rutaExtraer);
                    break;
                }
            }
        }
        //Paso 2. Se evalúa con una estructura de control si la rutaActual no está vacía
        if (rutaActual !== '') {
            //Paso 3. Se recorre con un bucle forof el array de objetos y se inserta en la HOOK caminos el camino de la rutaActual
            for (const caminoObtenido of caminos) {
                //Se comprueba que el camino existe
                if (caminoObtenido[rutaActual] !== undefined) {     
                    let caminoAinsertar = (rutaActual.toLowerCase() === (rutas.procedimientos.seleccionConvocatoriaPerfil.toLowerCase())) 
                        ? caminoObtenido[rutaActual] + urlProcedimiento
                        : caminoObtenido[rutaActual];
                    setCamino(caminoAinsertar);
                    break;
                }
            }
        } else {
            //Paso 2.1. En caso de que no se cumpla la condición anterior, se insertará en la HOOK de caminos la pantalla principal
            setCamino(properties.pantallaPrincipal);
        }
    };

    /**
     * @function obtenerNombreMiga Función que obtiene el nombre de la miga de pan, a través del parametro texto se obtiene en el switch cual es su nombre
     * @param {*} texto Recibe el nombre de la página actual
     * @returns Devuelve el nombre para la miga de pan
     */
    const obtenerNombreMiga = (texto) => {
        //Rutas
        const procedimientos = rutas.procedimientos;
        const moduloAdministracion = rutas.moduloAdministracion;
        const altaUsuario = rutas.altaUsuario;
        const descargarDocumentos = rutas.descargarDocumentos;
        const contacto = rutas.pantallaContacto;
        const compatibilidad = rutas.matrizCompatibilidad;
        //Nombres
        const nombresMigasProc = nombresMigas.procedimientos;
        const nombresMigasModAdm = nombresMigas.moduloAdministracion;
        const nombresAltaUsuario = nombresMigas.altaUsuario;
        const nombresContacto = nombresMigas.pantallaContacto;
        const nombresCompatibilidad = nombresMigas.matrizCompatibilidad;
        const nombreDescargaDocumentos = nombresMigas.descargarDocumentos

        let textoObtenido = '';

        //Obtención del procedimiento actual
        let procedimientoActual = loadState(localStorageName.procedimientoActual, true);
        //Se evita fallo en caso de no haber seleccionado un procedimiento
        let procedimientoObtenido = procedimientoActual === undefined ? '' : ((procedimientoActual.descripcion === undefined) ? procedimientoActual.nombre : procedimientoActual.descripcion);

        // Muestra SeleccionVersion en lugar de SeleccionConvocatoriaPerfil sin afectar a la url
        texto = texto === 'SeleccionConvocatoriaPerfilundefined' ? 'SeleccionVersion' : texto;

        switch (texto) {
            case procedimientos.seleccionConvocatoriaPerfil + urlProcedimiento: textoObtenido = nombresMigasProc.seleccionConvocatoriaPerfil + procedimientoObtenido;
                break;
            case procedimientos.bandejaSolicitudes: textoObtenido = nombresMigasProc.bandejaSolicitudes + procedimientoObtenido;
                break;
            case procedimientos.nuevaSolicitud: textoObtenido = nombresMigasProc.nuevaSolicitud + procedimientoObtenido;
                break;
            case procedimientos.aporteDocumentacion: textoObtenido = nombresMigasProc.aporteDocumentacion + procedimientoObtenido;
                break;
            case procedimientos.firmaSolicitud: textoObtenido = nombresMigasProc.firmaSolicitud + procedimientoObtenido;
                break;
            case procedimientos.solicitudPresentadaCorrectamente: textoObtenido = nombresMigasProc.solicitudPresentadaCorrectamente + procedimientoObtenido;
                break;
            case procedimientos.historicoDocumentos: textoObtenido = nombresMigasProc.historicoDocumentos;
                break;
            case procedimientos.aporteMejoraDocumentacion: textoObtenido = nombresMigasProc.aporteMejoraDocumentacion;
                break;
            case procedimientos.desistimiento: textoObtenido = nombresMigasProc.desistimiento;
                break;
            case procedimientos.altaAnexo: textoObtenido = nombresMigasProc.altaAnexo;
                break;
            case moduloAdministracion.loginAdministrador: textoObtenido = nombresMigasModAdm.loginAdministrador;
                break;
            case moduloAdministracion.menuNavegacion: textoObtenido = nombresMigasModAdm.menuNavegacion;
                break;
            case altaUsuario: textoObtenido = nombresAltaUsuario;
                break;
            case contacto: textoObtenido = nombresContacto;
                break;
            case compatibilidad: textoObtenido = nombresCompatibilidad;
                break;
            case procedimientos.entregas: textoObtenido = nombresMigasProc.entregas;
                break;
            case descargarDocumentos: textoObtenido = nombreDescargaDocumentos;
                break;
            default: textoObtenido = texto; //En caso de que no exista un nombre definido para la miga de pan, se utilizará el de su ruta
                break;
        }
        return textoObtenido;
    }

    /**
   * @function crearTextoBreadcrumbs Genera el texto de las migas de pan, si el texo es <= al parametro recibido (texto) entonces es el valor recibido,
   * de lo contrario, se recuce al caracter indicado en la propiedad textoTamanoMaxmiga caracteres
   * @param {*} texto Texto recibido que será el texto que formará la miga de pan
   * @returns Devuelve la miga de pan o null en caso de que no se reciba nada por el parametro texto
   */
    const crearTextoBreadcrumbs = (texto) => {
        texto = obtenerNombreMiga(texto);
        if (texto) {
            return (texto.length <= properties.tamanoMaxMiga) ? (texto) : (texto.substring(0, properties.tamanoMaxMiga) + properties.textoTamanoMaxMiga);
        } else {
            return null;
        }
    };

    return {
        obtenerCamino, obtenerNombreMiga, crearTextoBreadcrumbs, camino, contexto, contextoUrl, mostrarMiga
    };
}

export default useOperations;