import React, {useState, useEffect} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import useStyles from './ButtonVisualPdfStyle';
import { Grid, MuiThemeProvider } from '@material-ui/core';
import {properties} from './ButtonVisualPdfData';
import VisualizadorPdf from 'components/commons/visualizadorPdf/VisualizadorPdf'
import Confirmacion from 'components/commons/Confirmacion/Confirmacion';
import {properties as appProperties, compProperties } from './../../../AppData';
import useFunctions from './ButtonVisualPdfFunction'

export default function ButtonVisualPdf(props) {

    // IMPORTS cerrarPopUpPdf={cerrarPopUpPdf} mostrarPDF={mostrarPDF}
    const {mostrarPDF, cerrarPopUpPdf, mostrarPopUpPdf, cortarTexto} = useFunctions();
    const [isChecked, setIsChecked] = useState(false);

    const {style, theme} = useStyles(isChecked, props.isPhoneVersion);
    const customStyle = style();
    return (
        <MuiThemeProvider theme={theme}>
            <Grid className={customStyle.classWithColorRadio}>
                {
                    props.value !== undefined &&
                <FormControl component="fieldset" >
                    <RadioGroup
                        aria-label="PDF"
                        name="PDF"
                        value={props.value}
                        onChange={props.onChange}
                    >
                        {
                            props.listDoc && props.listDoc.map((element, index) => (

                                <Grid className={customStyle.gridRadioPdf}>
                                    <FormControlLabel value={element.pdf} className={customStyle.classMarginButton} control={<Radio id={element.id}/>} />
                                    <img src={properties.PDF_ICON}/>
                                    <p title={element.texto} className={customStyle.classTxtPdf}>{cortarTexto(element.texto)}</p>
                                </Grid>
                            ))
                        }
                    </RadioGroup>
                </FormControl>
                }
                {props.isPhoneVersion ? ( <Grid>
                        <Grid className={customStyle.gridBoton}>
                            <button onClick={mostrarPopUpPdf} className={customStyle.botonListo}>{properties.TEXT_BOTON}</button>
                        </Grid>
                        <Confirmacion 
                            accionConfirmacion={cerrarPopUpPdf}
                            mostrar={mostrarPDF} 
                            isPhoneVersion={props.isPhoneVersion} 
                            mensaje={<VisualizadorPdf pdf={props.pdf} isPhoneVersion={props.isPhoneVersion}/>}
                            botonera={compProperties.SOLO_CERRAR}
                            quitarImg={true}
                            tamMarginTop={appProperties.COMFIRMAR_MARGINTOP_0}
                            isPopUpPdf={true}
                            textBotonConfirmarPersonalizado={false}
                            isImagenPersonalizada={false}
                            centrarContenido={true}
                            isTextoEnriquecido={false}
                        />
                    </Grid> ) : (
                        <Grid className={customStyle.classGridPdfWeb}>
                            <VisualizadorPdf pdf={props.pdf} isPhoneVersion={props.isPhoneVersion} pdfNombre={props.pdfNombre}/>
                        </Grid> 
                    )}
                
            </Grid>
        </MuiThemeProvider>
    )
}
