import {makeStyles} from '@material-ui/core/styles'
import {createMuiTheme} from '@material-ui/core';
import {colores, estilosTextosPrincipales, tipografia} from '../../../AppStyle'

export const customStyle = makeStyles ({
    gridMenu:{
        width: 'fit-content',
        marginRight: '5%'
    },
    gridAcordeon:{
        position: 'absolute',
        zIndex: '1',
        left: 0,
        right: 0,
        top: '80px',
        height: 'fit-content',
        backgroundColor: colores.escalaGrises.cero,
    },
    gridContacto:{
        backgroundColor: colores.escalaGrises.diez
    },
    gridTelefono:{
        paddingBottom: '35px'
    },
    gridDesplegable:{
        width: '100%'
    },
    acordeon:{
        width: '100%'
    },
    iconoAcordeon:{
        height: '20px',
        marginRight: '10px'
    },
    tituloAcordeon:{
        fontFamily: tipografia.regular,
        fontSize: '12px',
        color: colores.principales.principal
    },
    listaDesordenada:{
        listStyleType: 'none',
        width: '100%'
    },
    elementoLista:{
        marginBottom: '10px',
        marginTop: '10px',
        marginLeft: '16px',
    },
    detallesAcordeon:{
        padding: 0
    },
    lineaMenu:{
        borderBottomColor: colores.escalaGrises.veinte,
        borderTopColor: 'transparent'
    },
    botonMiCuenta:{
        height: '48px',
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: '25px',
        marginBottom: '10px',
        border: '1px solid',
        borderRadius: '4px',
        color: colores.escalaGrises.cero,
        fontFamily: tipografia.bold,
        fontSize: '12px',
        lineHeight: '16px',
        backgroundColor: colores.principales.principal        
    },
    ultimaConexion:{
        textAlign: 'center',
        fontFamily: tipografia.regular,
        fontSize: '12px',
        lineHeight: '17px',
        color: colores.escalaGrises.ochenta,
        width: '90%',
        marginTop: '2%',
        marginLeft: '5%',
        marginRight: '5%'
    },
    msgContacto:{
        ...estilosTextosPrincipales.body1,
        textAlign: 'center',
        color: colores.escalaGrises.cien,
        width: '90%',
        marginBottom: '15px',
        marginLeft: '5%',
        marginRight: '5%',
    },
    iconoTelefono:{
        height: '18px',
        marginRight: '10px'
    },
    tlfn:{
        ...estilosTextosPrincipales.body1,
        color: colores.principales.principal,
        width: 'fit-content'
    },
    iconoDesplegable:{
        fill: colores.principales.principal
    },
    link:{
        textDecoration: 'none',
        fontFamily: tipografia.medium,
        fontSize: '12px',
        color: colores.escalaGrises.cien,
    },
    popUpBotones: {
        textAlign: 'center',
    }
})

export const overrideStyle = createMuiTheme({
    overrides: {
        MuiAccordion:{
            root:{
                "&$expanded": {
                    margin: '0px 0'
                  }
            },
        }
    }
})