import React, { useEffect } from 'react';
//Componentes de material importados
import { Container, Grid, IconButton, Menu, MenuItem, MenuList, ThemeProvider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Box from "@material-ui/core/Box";
import ListItemIcon from '@material-ui/core/ListItemIcon';
//Icono de acceso al menú importado ("tres puntos en vertical")
import MoreVertIcon from '@material-ui/icons/MoreVert';
//Importación del objeto que contiene los atributos y el ***mock***
import { objAtributes, objText, mock, properties } from './menuUsuarioData';
//Importación del estilo a aplicar al componente
import Style, { overMenu } from './menuUsuarioStyle';
//Importación de funciones
import { loadState } from './../../../providers/localStorage';
import { localStorageName } from './../../../providers/localStorageData';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { saveState } from './../../../providers/localStorage';
import AuthProvider from 'providers/authProvider';
import { properties as botoneraVerticalData } from './../../commons/botonera/BotoneraVerticalData';

/****************************************************************
TEMP: Iconos temporales hasta la obtención de los definitivos***/
import { ReactComponent as Tramites } from './images/tramites.svg';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import SettingsIcon from '@material-ui/icons/Settings';
import RestProvider from 'providers/RestProvider';
/***************************************************************/

/**
 * @function MenuUsuario Función que devolverá el componente menú usuario.
 * El componente esta compuesto por un botón (<IconButton/>) de acceso al menú de usuario (<Menu/>) y este esta compuesto
 * de el nombre del usuario logado, un listado de las opciones del menu (<MenuList/>), las opciones del menú (<MenuItem/>)
 * y un botón para cerrar la sesión del usuario.
 * @returns Devuelve el componente en sí.
 */
export default function MenuUsuario(props) {
    //Variables y cambios de estado de los componentes del Menú de usuario
    //const { anchorEl, open, handleClick, handleClose, closeSession, redirectAltaUsuario } = useMenuUsuario();
    //Se capturan los valores del mock para que sean más legibles
    const opcionesMenu = mock.opcMenu;
    const usuarioClave = loadState(localStorageName.infoUsuario, true);
    const inicialesUsuario = (usuarioClave.interesadoNombre).substr(0, 1).concat((usuarioClave.interesadoApellido1).substr(0, 1));//Se obtiene la 1ª letra del nombre y del apellido
    const nombreUsuario = usuarioClave.interesadoApellido2 !== null ?
        usuarioClave.interesadoNombre + ' ' + usuarioClave.interesadoApellido1 + ' ' + usuarioClave.interesadoApellido2
        : usuarioClave.interesadoNombre + ' ' + usuarioClave.interesadoApellido1;

    const [anchorEl, setAnchorEl] = useState(null);
    const [ultConexion, setUltConexion] = useState('');
    const [keycloakAuth, setKeycloakAuth] = useState(null);
    const history = useHistory(); //Para navegar entre paginas de la propia web
    const { getPermissions } = AuthProvider();
    const { obtenerProperties } = RestProvider();

    const claseButton = Style().classButton;
    const claseLabelUltConexion = Style().classLabelUltConexion;



    const open = Boolean(anchorEl);

    const addProperties = async () => {    
        const propertiesList = [
            'keycloak.auth.administradores'
        ];
    
        try {
            let properties = await obtenerProperties(propertiesList);    
            // Actualiza el estado
            setKeycloakAuth(properties['keycloak.auth.administradores']);
        } catch (error) {
            console.error('error obteniendo las properties', error);
        }
    };

    useEffect(() => {
        addProperties();
    }, [])

    const crearObjetoCredencial = () => {

        var infoUsuario = loadState(localStorageName.infoUsuario, true)

        let datosSaltoBandeja = {
            tipoAutenticacion: infoUsuario.tipoAutenticacion,
            nifUsuario: infoUsuario.interesadoNif,
            nombre: infoUsuario.interesadoNombre,
            apellido1: infoUsuario.interesadoApellido1,
            apellido2: infoUsuario.interesadoApellido2,
            datosCertificado: infoUsuario.datosCertificado
        }

        return datosSaltoBandeja;
    }

    const handleClick = (event) => {
        let element = document.getElementById(properties.ID_BUTTON);
        if (element) {
            element.setAttribute(properties.ARIA_EXPANDED, true);
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        let element = document.getElementById(properties.ID_BUTTON);
        if (element) {
            element.setAttribute(properties.ARIA_EXPANDED, false);
        }
        setAnchorEl(null);
    };

    //Funcion para Redirigir a altaUsuario
    const redirectAltaUsuario = () => {
        saveState(localStorageName.redirect, 'loginCabecera', false);
        setAnchorEl(null);
        history.push('/AltaUsuario');
    };

    //Funcion para Redirigir a la descarga de documentos by HCV
    const redirectDescargarDocumento = () => {
        saveState(localStorageName.redirect, 'menuNavegacion', false);
        setAnchorEl(null);
        history.push('/DescargarDocumentos');
    }

    //Funcion para Redirigir a moduloAdministrador
    const redirectModuloAdministrador = () => {
        if (getPermissions().split(',').includes('administrador_ov3')) {
            saveState(localStorageName.redirect, 'menuNavegacion', false);
            setAnchorEl(null);
            history.push('/MenuNavegacion');
        } else {
            saveState(localStorageName.redirect, 'loginAdministrador', false);
            setAnchorEl(null);
            history.push('/LoginAdministrador');
        }
    };


    //Funcion para Redirigir a listado de procedimentos
    const redirectListadoProc = () => {
        saveState(localStorageName.redirect, 'loginCabecera', false);
        setAnchorEl(null);
        let btnMisTramites = document.querySelector(`#${botoneraVerticalData.idMistramites}`);
        /*Estructura de control que comprueba si la ubicación desde la que se va a acceder a "Mis trámites" es desde la página principal o desde cualquier otra
        de la aplicación*/
        if (window.location.hash === '#/') {
            //Se pulsa el botón del lista de procedimientos del usuario logado
            btnMisTramites.click();
        } else {
            //Se almacena un valor booleano para recuperarlo desde listadoProcedimientos.js para mostrar los procedimientos del usuario logado
            saveState(localStorageName.misTramites, true, false);
            //Reidrección al Home de la aplicación
            history.push('/');
        }
    };

    //Funcion para cerrar sesion
    const cerrarSesion = () => {
        props.handleChange();
    }

    return (
        <ThemeProvider theme={overMenu}/*///////////////////APLICACIÓN DE SOBREESCRITURA DE ESTILO DE MATERIAL*/>
            <Container {...objAtributes.container} className={Style().classContainer}>
                <Grid {...objAtributes.grid}>
                    {/*///////////////////INICIALES USUARIO Y ACCESO AL MENU DE USUARIO*/}
                    <Grid {...objAtributes.gridMenuInicial} className={Style().classGrid} item>
                        <IconButton id={properties.ID_BUTTON} className={Style().classButtonUsuario} {...objAtributes.iconButton} onClick={handleClick} ariaExpanded={false}>
                            <span className={Style().classIniciales}>{inicialesUsuario}</span>{/*///////////////////INICIALES DEL USUARIO*/}
                            <MoreVertIcon className={Style().classTresPuntos} />{/*Icono de acceso al menú importado ("tres puntos en vertical")*/}
                        </IconButton>
                    </Grid>
                    <Paper>
                        {/*///////////////////MENU DE USUARIO*/}
                        <Menu
                            {...objAtributes.menu}
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >{/*///////////////////NOMBRE DE USUARIO*/}
                            <p {...objAtributes.menuItem.nombreUsuario} className={Style().classLabelUserName}>{nombreUsuario}</p>
                            {/*///////////////////OPCIONES DEL MENU*/}
                            <MenuList>{/*///////////////////LISTADO DE OPCIONES DEL MENU*/}
                                <MenuItem onClick={handleClose}>{/*///////////////////OPCIÓN DEL MENU*/}
                                    <ListItemIcon>
                                        <Tramites className={Style().classIcon} />{/*ICONO*/}
                                    </ListItemIcon>
                                    <p onClick={redirectListadoProc} className={Style().classMenuItem} {...objAtributes.opcMenu.opc1}>{opcionesMenu[0]}</p>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>{/*///////////////////OPCIÓN DEL MENU*/}
                                    <ListItemIcon>
                                        <AccountCircleIcon className={Style().classIcon} onClick={redirectAltaUsuario} />{/*ICONO*/}
                                    </ListItemIcon>
                                    <p onClick={redirectAltaUsuario} className={Style().classMenuItem} {...objAtributes.opcMenu.opc2}>{opcionesMenu[1]}</p>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>{/*///////////////////OPCIÓN DEL MENU*/}
                                    <ListItemIcon>
                                        <DownloadIcon className={Style().classIcon} onClick={redirectDescargarDocumento} />{/*ICONO*/}
                                    </ListItemIcon>
                                    <p onClick={redirectDescargarDocumento} className={Style().classMenuItem} {...objAtributes.opcMenu.opc4}>{opcionesMenu[2]}</p>
                                </MenuItem>
                                <Box display={keycloakAuth && keycloakAuth.split(',').includes(usuarioClave.interesadoNif) ? "block" : "none"}>
                                    <MenuItem onClick={handleClose} >{/*///////////////////OPCIÓN DEL MENU*/}
                                        <ListItemIcon>
                                            <SettingsIcon className={Style().classIcon} onClick={redirectModuloAdministrador} />{/*ICONO*/}
                                        </ListItemIcon>
                                        <p onClick={redirectModuloAdministrador} className={Style().classMenuItem} {...objAtributes.opcMenu.opc3}>{opcionesMenu[3]}</p>
                                    </MenuItem>
                                </Box>
                            </MenuList>
                            {/*///////////////////BOTON CERRAR SESION*/}
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button onClick={cerrarSesion} {...objAtributes.button} className={claseButton}>{(objText.boton).toUpperCase()}</button>
                            </div>{/*///////////////////Últ. conexión*/}
                            <p className={claseLabelUltConexion} >{ultConexion}</p>
                        </Menu>
                    </Paper>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}