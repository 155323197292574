import { loadState } from '../../../providers/localStorage';
import { localStorageName } from '../../../providers/localStorageData';

const usuariLogado = loadState(localStorageName.infoUsuario, true);

export const properties = {
    RESOURCE_BACK: 'formularios',
    RESOURCE_SOLICITUD: 'solicitudes',
    URL_SAVE_DATA: 'guardarFormulario',
    URL_GET_DATA: 'recuperarFormulario',
    RESOURCE_ADMINISTRADOR: 'administrador',
    RESOURCE_EXPEDIENTE: 'gestion-expediente',
    URL_EXPEDIENTE: '/getExpedienteByNumeroExpediente/',
    URL_VALORES_FORM: '/getValoresProcedimiento',
    F2: 'F2',
    SUB: 'SUBV',
    codigoAplicacionF2: 'PRESENTA_FASE2_AS',
    codigoAplicacionSUB: 'PRESENTA_SUB_AS',
    FASE_PENDIENTE_DE_SUBSANAR: 'PENDIENTE DE SUBSANAR',
    FASE_PDT_ALEGACIONES: 'Presentación de alegaciones',
    FASE_INICIO: 'INICIO',
    FASE_PENDIENTE_JUSTIFICACION: 'PENDIENTE DE JUSTIFICAR',
    URL_GET_SOLICITUD_POR_EXP: '/obtenerSolicitudPorExpediente',
    RESOURCE_FORMULARIO_EXPEDIENTE: 'formulario-expediente',
    RESOURCE_FORMULARIO_FASE_CONVOCATORIA: 'formulario-fase-convocatoria',
    URL_GUARDAR_FORMULARIO_PCT: '/guardarFormulario',
    URL_OBTENER_CAMPO_FORMULARIO: '/getValorCampo',
    RESOURCE_TIPO_PDF: '/tipoPdfAgenerar'
}

export const btnsGuardar = {
    guardarPaso: 'btnGuardar',//Debe ser igual que el nombre de la API del botón en FORMIO
    guardarForm: 'btnGuardarFormulario'
}

export const btnsCancelar = {
    cancelarPaso: 'btnCancelar',
}

export const msg = {
    msg: {
        pasoActual: {
            exitoPasoActual: 'Se ha realizado correctamente el guardado del estado actual del formulario. Recuerde que debe seguir los siguientes pasos para realizar la presentación',        
            errorPasoActual: 'Se ha producido un error al guardar el apartado actual'
        },
        formulario: {
            exitoFormulario: 'Se ha realizado correctamente el guardado del formulario',
            errorFormulario: 'Se ha producido un error al guardar el formulario'
        },
        pdf: {
            exitoPdf: 'Se ha generado correctamente el PDF',
            errorPdf: 'Se ha producido un error al generar el formulario'
        }
    },
    severity: {
        exito: 'success',
        error: 'error'
    }
}

export const varOverriders =  {
    i18n : {
        en: {
            complete: 'Formulario cumplimentado correctamente',
            error: 'Hubo un problema',
            next: 'Siguiente',
            previous: 'Atrás',
            cancel: 'Cancelar',
            submit: 'FINALIZAR Y ADJUNTAR DOCUMENTACIÓN',
            required: 'Es obligatorio rellenar este campo',
            confirmCancel: '¿Estás seguro que quieres cancelar?',
            submitError: 'Por favor revise el formulario y corrija todos los errores.',
            'Type to search': 'Escriba para buscar',
            month: 'Mes',
            day: 'Dia',
            year: 'Año',
            january: 'Enero',
            february: 'Febrero',
            march: 'Marzo',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Diciembre'
    
        },
        sp: {
            searchplaceholdervalue : "buscar",
            month: 'Mes',
            day: 'Dia',
            year: 'Año',
            january: 'Enero',
            february: 'Febrero',
            march: 'Marzo',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Diciembre'

        }
    },
    /* Ejemplo de validacion desde JS
    hooks: {
        beforeSubmit: (submission, next) => {
            let msg = '';
            let campoLetras = submission.data.letras;
            console.log()
            if (campoLetras === '' || campoLetras === undefined) {
                msg = 'El campo Letra esta vacio';
                next(msg);
            }
            else {
                next();
            }
        }
    }*/
}

export const mockData = {
    data: {
        containerFecha: {
            lugar: "Sevilla",
        },
        containerFirma: {
            firma: usuariLogado ? `${usuariLogado.interesadoNombre} ${usuariLogado.interesadoApellido1} ${usuariLogado.interesadoApellido2}` : ''
        }
    }
}

export const formatosFecha = {
    salida: 'salida',
    entrada: 'entrada'    
}