import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
//Estilo genérico importado
import { estilosTextosPrincipales, colores, tipografia } from '../../../AppStyle';

export default makeStyles({
    ///////////////////////////////TITULOS
    classEncabezadoH2Responsive: {
        ...estilosTextosPrincipales.encabezadoH2,
        color: colores.escalaGrises.cien,
        letterSpacing: '0',
        fontWeight: 'bold'
    },
    classEncabezadoH2: {
        ...estilosTextosPrincipales.titulosPagina,
    },
    classEncabezadoH3: {
        ...estilosTextosPrincipales.encabezadoH3,
        color: colores.escalaGrises.cien,
        fontWeight: 'bold',

    },
    separador: {
        borderBottom: `1px solid ${colores.escalaGrises.diez}`,
    },    
    classParrafoResponsive: {
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.noventa,
        fontWeight: 'normal'
    },
    classParrafo: {
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.noventa,
        fontWeight: 'normal'
    },
    classMsgBienvenida: {
        color: colores.escalaGrises.noventa,
        fontFamily:  tipografia.regular,
        fontSize: '14px',
        lineHeight: '24px',
        marginBottom: '20px',
    },
    ///////////////////////////////CONTAINERS   
    classContaFormSeleccionConvocatoriaPerfilDataResponsive: {
        width: '375px',
        height: 'auto',
    }, 
    classContaFormSeleccionConvocatoriaPerfilData: {
        width: '1170px',
        height: 'auto',
    },
    classContaComponentesResponsive: {
        height: 'auto',
        width: 'auto',
        marginTop: '22px',
        marginBottom: '32px',
        paddingTop: '22px',
        paddingRight: '23px',
        paddingLeft: '16px',
        paddingBottom: '20px',
        backgroundColor: colores.escalaGrises.blanco,
        boxShadow: `0 0 6px 0 ${colores.sombras.sombraGris1}, 0 0 30px 0 ${colores.sombras.sombraGris2}`,
    },
    classContaComponentes: {
        height: 'auto',
        width: 'auto',
        marginTop: '14px',
        marginBottom: '32px',
        paddingTop: '20px',
        paddingRight: '69px',
        paddingLeft: '53px',
        paddingBottom: '20px',
        backgroundColor: colores.escalaGrises.blanco,
        boxShadow: `0 0 6px 0 ${colores.sombras.sombraGris1}, 0 0 30px 0 ${colores.sombras.sombraGris2}`,
    },
    classContaURL: {
        height: '500px',
        width: '100%',
        marginTop: '14px',
        marginBottom: '32px',
        paddingTop: '20px',
        paddingRight: '69px',
        paddingLeft: '53px',
        paddingBottom: '20px',
        backgroundColor: colores.escalaGrises.blanco,
        boxShadow: `0 0 6px 0 ${colores.sombras.sombraGris1}, 0 0 30px 0 ${colores.sombras.sombraGris2}`,
    },
    /////////////////////////////// GRIDS
    classGridEncabezadoResponsive: {
        width: 'auto',
        marginTop: '20px',
        marginBottom: '14px',
    },
    classGridEncabezado: {
        width: 'auto',
        marginTop: '20px',
        marginBottom: '14px',
    },
    classGridSeccionesResponsive: {
        width: 'auto',
        marginTop: '22.81px',
    },
    classGridSecciones: {
        width: 'auto',
    },
    classSeparacionSecciones: {
        marginTop: '15px',
        borderBottom: `1px solid ${colores.escalaGrises.diez}`,
    },
    classSeparacionMetodoAcceso: {
        marginTop: '15px',
    },
    classGrid: {
        textAlign: 'left',
        marginTop: '15px',
        marginBottom: '19px',
        marginRight: '32px',
        width: 'auto',
        display: 'block',
    },
    ///////////////////////////////LINEAS SEPARATORIAS
    classLine: {
        boxSizing: 'border-box',
        height: '1px',
        width: 'auto',
        border: `1px solid ${colores.escalaGrises.diez}`,
        marginTop: '13px',
        marginBottom: '23px',
    },
    ///////////////////////////////BACKDROP
    classBackdrop: {
        zIndex: 1,
        color: colores.principales.blanco,
    },

    claseIconMensajeBienvenida:{
        color: colores.principales.principal
    },

    acordeonSummary: {
        backgroundColor: colores.sombras.sombraNegra,
        borderBottom: `0px solid ${colores.sombras.sombraNegra}`,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
          minHeight: 56,
        },
    },
});

export const overrideStyle = createTheme({
    overrides: {
        MuiFormGroup: {
            root: {
                width: "100%",
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)',
            }
        },
        MuiAccordion: {
            root:{
                borderLeft: '200px',
                position: 'none'
            },
        },
        MuiAccordionSummary:{
            root: {
                minHeight: '55px',
                marginLeft: '-15px',
                marginTop: '-5px'
            }
        },
        MuiAccordionDetails:{
            root: {
                display: 'flex',
                padding: '8px 16px 16px',
                marginLeft: '-14px'
            }
        }
    },
});