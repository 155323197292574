import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import {buttonPrimaryBig, colores, estilosTextosPrincipales, tipografia} from '../../../AppStyle';

export const customStyle = makeStyles({
    /*Toda la lista*/
    ListingPagerlist: {
        listStyleType: 'none', /*Para que no muestre un punto por cada elemento*/
    },

    nav:{
        float: 'right',
        marginBlock: '1%'
    },

    li: {
        float: 'left',
        padding: '5px',
        fontFamily: tipografia.regular,
        fontSize: '13px',
        margin: '5px',
    },

    /*Todos los elementos li*/
    ClassListingPagerlistLi: {
        float: 'left',
        padding: '10px',
        fontFamily: tipografia.regular,
        fontSize: '13px',
        margin: '5px',
    },

    ClassListingPagerlistItem: {
        backgroundColor: 'transparente',
        color: colores.principales.principal,
        border: `solid 1px ${colores.principales.principal}`,
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        borderRadius: '4px',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        textAlign: 'center',
        float: 'left',
        padding: '10px',
        width: '40px',
        height: '40px',
        margin: '5px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },'&:hover': {
            border: `solid 1px ${colores.principales.hover}`,
            color: colores.principales.hover,
        }
    },
    //Item activo
    ClassListingPagerlistItemActive: {
        backgroundColor: colores.principales.hover,
        color: colores.escalaGrises.cero,
        borderRadius: '4px',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        textAlign: 'center',
        float: 'left',
        padding: '10px',
        width: '40px',
        height: '40px',
        margin: '5px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    ClassListingPagerlistItemHover: {
        borderStyle: 'solid',
        border: `2px solid ${colores.principales.verde}`,
        borderRadius: '4px',
        color: colores.principales.principal,
    },

    /**************************************************/

    ClassListingPagerListButton: {
        color: colores.escalaGrises.cero,
        backgroundColor: colores.principales.principal,
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        borderRadius: '4px',        
        textAlign: 'center',
        float: 'left',
        padding: '10px',
        margin: '5px',
        height: '40px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: colores.principales.hover,
        }
    },
    ClassListingPagerListButtonDisable: {
        color: colores.escalaGrises.cero,
        backgroundColor: colores.principales.apoyo,
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        borderRadius: '4px',        
        textAlign: 'center',
        float: 'left',
        padding: '10px',
        margin: '5px',
        height: '40px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    ClassListingPagerListDots: {
        color: colores.principales.principal,
        float: 'left',
        padding: '10px',
        margin: '5px',
    },
    classIcons: {
        height: '10px'
    },
    classButton: {
        color: `${colores.principales.blanco} !important`,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    classButtonDissable: {
        color: `${colores.escalaGrises.cero1} !important`,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    classItem: {
        color: `${colores.principales.principal} !important`,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    classItemSelected: {
        color: `${colores.escalaGrises.cero} !important`,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    classText:{
        marginLeft: '10px',
        textDecoration: 'none !important',
        color: `${colores.escalaGrises.cien} !important`,
        fontFamily: tipografia.regular,
        fontSize: '17px',
    },
});

export const overInputs = createMuiTheme({
    overrides: {
        MuiInput: {
            root: {
                border: `solid 1px ${colores.principales.principal}`,
                height: '40px',
                margin: '5px',
                borderRadius: '4px',
                padding: '10px',
                '&:focused': {
                    border: `2px solid ${colores.principales.principal}`
                },
                '&.MuiInput-underline:before': {
                    borderBottom: '0',
                },
            },
            underline:{
                '&.Mui-focused:after': {
                    transform: 'scalex(0)',
                },
                
                '&:hover': {
                    '&:not(.Mui-disabled):before': {
                        borderBottom: '0',
                    },
                },
            }
        },
        MuiPopover:{
            root:{
                marginTop: '2.5% !important',
            }
        },
        MuiSelect:{
            select:{  
                fontFamily: tipografia.regular,
                fontSize: '17px',
                '&.MuiSelect-select': {
                    textAlign: 'center',
                },
                '&:focus': {
                    backgroundColor: 'transparente',
                },
            }
        },
        MuiListItem: {
            root: {
                color: colores.escalaGrises.ochenta,
                '&:hover': {
                    backgroundColor: `${colores.principales.selectHover} !important`,
                },
                '&$selected': {
                    backgroundColor: colores.principales.apoyo
                },
                
            }
        },
    },
});
