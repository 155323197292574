import {createMuiTheme, makeStyles} from '@material-ui/core/styles'
import {colores, estilosTextosPrincipales, tipografia} from '../../../AppStyle'

export const customStyle = makeStyles ({
 
    /***********COMPUTER VERSION***********/
    flexContainer:{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'left',
    },

    /***********PHONE VERSION***********/
    selectorMargen:{
        margin: '0px 32px',
    },

    listaBotones:{
        listStyleType: 'none',
    },

    titulos: {
        height: 'auto',
    }
});

export const overrideStyle = createMuiTheme({
    overrides: {

        MuiToggleButton: {
            root: {
                background: colores.escalaGrises.cero,
                border: `1px solid ${colores.escalaGrises.veinte}`,
                borderRadius: '1px',
                height: '42px',
                width: '142px',
                left: '0px',
                top: '0px',
                boxSizing: 'border-box',
                margin: '7px',
                color: colores.escalaGrises.cien,
                fontFamily: tipografia.regular,
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '16px',
                textAlign: 'center',
                textTransform: 'none',
                fontStyle: 'normal',
                '&$selected':{
                    boxSizing: 'border-box',
                    outline: `${colores.principales.principal} auto 5px`, 
                    outlineColor: colores.principales.principal, 
                    backgroundColor: colores.principales.fondoEspecifico, 
                    color: colores.principales.principal,
                    border: '1px',
                    borderStyle: 'solid',
                    borderColor: colores.principales.principal,
                    '&:hover':{
                        backgroundColor: colores.principales.fondoEspecifico,
                    }
                },

                '&:hover':{
                    backgroundColor: colores.escalaGrises.cero,
                    outline: `${colores.principales.principal} auto 5px`, 
                    outlineColor: colores.principales.principal, 
                },
                '&.Mui-focusVisible': {
                    backgroundColor: colores.escalaGrises.cero,
                    outline: `${colores.principales.principal} auto 5px`,
                    outlineColor: colores.principales.principal, 
                },
            }
        },
       
        MuiToggleButtonGroup:{

            groupedHorizontal:{

                '&:nth-child(n)':{
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px',
                    marginLeft: '7px',
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px',
                },
                '&:not(:first-child)': {
                    borderLeft: 'rgbs'
                }
            } 
        }
    }


})