import { getContextFromUrl } from 'config/Config';

const useData = () => {
    //Objecto que contiene las opciones del documento PDF
    const options = {
        orientation: 'p', // (l) Horizontal, (p) Vertical
        unit: 'px',  //Unidad de medida
        format: 'a4', //Fomrato del formulario
        floatPrecision: 16,
        hotfixes: ["px_scaling"], //Escala en pixeles
        compress: true //Compresión del formulario
    }

    const optionsHtml2Canvas = {
        scale: 1 //Escala de las capturas, a mayor escala mayor calidad de imagen y más pesadas
    }

    const images = {
        cabeceraConsejeria: {
            url: `${getContextFromUrl()}app/logoAyto.png`,
            alto: 67
        }
    }

    const formatoForm = {
        altoForm: 1123, //297mm
        anchoForm: 794, //201mm
        coorW: 10,
        coorY: 10,
        margen: 10
    }

    const properties = {
        formularioConVolcadoHtml: 'formularioConVolcadoHtml',
        pdfVolcadoHTML: 'pdfConVolcadoHTML',
        resource: 'pdfNormalizado',
        url: '/obtenerPdfNormalizado',
        resourcePCT3: 'pdf-normalizado'
    }

    return { options, optionsHtml2Canvas, images, formatoForm, formatoForm, properties }
}
export default useData;
