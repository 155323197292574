import { useEffect, useState } from 'react';
import { properties as appProperties } from '../../../AppData';
import RestProvider from 'providers/RestProvider';

const useStyleResponsive = () => {
  const {obtenerProperties} = RestProvider();

  const [isPhoneVersion, setIsPhoneVersion] = useState(false);
  const [nombreConsejeria, setNombreConsejeria] = useState();

  const loadInfoCloud = async () => {      
      const propertiesList = [
          'consejeria.nombre'
      ];
  
      try {
          let properties = await obtenerProperties(propertiesList);    
          // Actualiza el estado
          setNombreConsejeria(properties['consejeria.nombre']);
      } catch (error) {
          console.error('error obteniendo las properties', error);
      }
  };
  

  useEffect(() => {
    loadInfoCloud();
  }, []);


  const updateFormat = () => {
    if (window.matchMedia(`(max-width: ${appProperties.NUM_PIX_MOVIL})`).matches) {
      setIsPhoneVersion(true);
    } else {
      setIsPhoneVersion(false);
    }
  };

  return { isPhoneVersion, updateFormat, loadInfoCloud, nombreConsejeria };
}

export default useStyleResponsive;