import './App.css';

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from './components/commons/Footer/FooterV2';
import ListadoProcedimientos from './components/pages/listadoProcedimientos/ListadoProcedimientos';
import DescargarDocumentos from 'components/pages/descargarDocumentos/DescargarDocumentos';
import AltaUsuario from './components/pages/pageAltaUsuario/PageAltaUsuario';
import seleccionConvocatoriaPerfil from './components/pages/seleccionConvocatoriaPerfil/SeleccionConvocatoriaPerfil';
import ListadoFormularios from './components/pages/listadoFormularios/ListadoFormularios';
import CreacionFormularios from './components/pages/creacionFormularios/CreacionFormularios';
import RespuestaClave from './components/pages/respuestaClave/RespuestaClave';
import Header from './components/commons/header/Header'
import ServiciosAtencionCiudadania from './components/pages/serviciosAtencionCiudadania/serviciosAtencionCiudadania';
import ScrollToTop from './components/commons/scrollToTop/scrollToTop';
import { properties } from './AppData'
import AppContext from 'AppContext';
import BandejaSolicitudes from './components/pages/bandejaSolicitudes/BandejaSolicitudes';
import NuevaSolicitud from 'components/pages/nuevaSolicitud/NuevaSolicitud';
import MenuNavegacion from 'components/pages/menuNavegacion/MenuNavegacion';
import ComponenteFormio from 'components/pages/componenteFormio/ComponenteFormio';
import AporteDocumentacion from 'components/pages/aporteDocumentacion/AporteDocumentacion';
import FirmaSolicitud from 'components/pages/firmaSolicitud/FirmaSolicitud';
import SolicitudPresentadaCorrectamente from 'components/pages/solicitudPresentadaCorrectamente/SolicitudPresentadaCorrectamente';
import HistoricoDocumentos from 'components/pages/historicoDocumentos/HistoricoDocumentos';
import AporteMejoraDocumentacion from 'components/pages/aporteMejoraDocumentacion/AporteMejoraDocumentacion';
import { localStorageName } from 'providers/localStorageData';
import MatrizCompatibilidad from 'components/pages/matrizCompatibilidad/MatrizCompatibilidad';
import Desistimiento from 'components/pages/desistimiento/Desistimiento';
import SelloElectronicoSistemasFirma from 'components/pages/selloElectronicoSistemaFirma/SelloElectronicoSistemasFirma';
import useProtectedRoutes from 'providers/useProtectedRoutes';
import PageNotFound from 'components/pages/pageNotFound/PageNotFound';
import AltaAnexo from 'components/pages/pageAltaAnexo/PageAltaAnexo';
import AltaEntidadColaboradora from 'components/pages/formAltaEntidadColaboradora/altaEntidadColaboradora';
import Entregas from 'components/pages/entregas/Entregas';
import { RandomVarProvider } from 'providers/RandomVarProvider';
import HeaderLinks from 'utils/HeaderLinksUtils';
import CordobaUtils from 'utils/CordobaUtils';

function App() {

  const { ProtectedRoute } = useProtectedRoutes();

  const [title, setTitle] = useState();
  const [sesion, setSesion] = useState();
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  const contextPack = {
    titlePage: title,
    updateTitlePage: (newTitle) => setTitle(newTitle),
    sesion: sesion,
    updateSesion: (estado) => setSesion(estado)
  };

  const handleScriptsLoaded = () => {
    setScriptsLoaded(true);
  };


  return (

    <AppContext.Provider value={contextPack}>
      <RandomVarProvider>
        <HeaderLinks type="sede" onScriptsLoaded={handleScriptsLoaded} />
        {scriptsLoaded && <CordobaUtils />}
        <Helmet htmlAttributes={{ lang: 'es' }}>
          <title>{properties.TITULO_PAGINA + title}</title>
        </Helmet>

        <Router>
          <ScrollToTop />
          <Header />

          <Switch>
            <Route path={["/", "/retorno.do", "/cerrarSesion.do"]} exact component={ListadoProcedimientos} />
            <Route path='/seleccionConvocatoriaPerfil/:idProcedimiento' exact component={seleccionConvocatoriaPerfil} />
            <Route path='/AltaUsuario' exact component={AltaUsuario} />
            <Route path='/RespuestaClave/:datosUsarioClave' exact component={RespuestaClave} />
            <Route path='/PantallaContacto' exact component={ServiciosAtencionCiudadania} />               
            <Route path={'/ComponenteFormio'} exact component={ComponenteFormio} />
            <Route path={'/MatrizCompatibilidad'} exact component={MatrizCompatibilidad}/> 
            <ProtectedRoute path='/DescargarDocumentos' component={DescargarDocumentos} />
            <ProtectedRoute path= '/MenuNavegacion' component={MenuNavegacion}/>
            <ProtectedRoute path= '/ListadoFormularios' component={ListadoFormularios} />
            <ProtectedRoute path= '/BandejaSolicitudes' component={BandejaSolicitudes} />
            <ProtectedRoute path= '/NuevaSolicitud' component={NuevaSolicitud} />
            <ProtectedRoute path= '/AporteDocumentacion' component={AporteDocumentacion} />
            <ProtectedRoute path= '/AporteMejoraDocumentacion' component={AporteMejoraDocumentacion} />
            <ProtectedRoute path= '/FirmaSolicitud' component={FirmaSolicitud} />
            <ProtectedRoute path= '/SolicitudPresentadaCorrectamente' component={SolicitudPresentadaCorrectamente} />
            <ProtectedRoute path= '/HistoricoDocumentos' component={HistoricoDocumentos} />
            <ProtectedRoute path= '/Desistimiento' component={Desistimiento}/>
            <ProtectedRoute path= '/AltaAnexo' component={AltaAnexo}/>
            <ProtectedRoute path= '/AltaEntidadColaboradora' component={AltaEntidadColaboradora}/>
            <ProtectedRoute path= '/Entregas' component={Entregas}/>
            <Route path="*" component={PageNotFound}/>
          </Switch>
          <Footer/>
        </Router>
      </RandomVarProvider>
    </AppContext.Provider>
  );
}

export default App;
