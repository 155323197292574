const useData = () => {
    const properties = {
        RESOURCE_ADMINISTRADOR: 'administrador',
        URL_VALORES_FORM: '/getValoresProcedimiento',
        URL_PROVINCIAS_PROCEDIMIENTO: '/getProvinciasProcedimiento',
        MS_MODULO_ADMINISTRACION: 'modadmin',
        RESOURCE_MANAGER: 'manager',
        URL_PROVINCIAS: '/lista-provincias',
        MS_TREWA: 'trewa' 

        
    }
    /** Nombre de la API de los componentes a precargar */
    const valoresPrecarga = {
        informacionBoja: {
            apiName: 'informacionBoja',
            valoresAbuscar: ["identificadorOrdenDecretoLey", "fechaOrdenDecretoLey", "fechaBoja", "numeroBoja", "convocatoriaEjercicio"]
        },
        destinatario: {
            apiName: 'realizacionGestion',
            provincializada: {
                apiName: 'provincializada'
            },
            centralizada: {
                apiName: 'centralizada'
            }
        },
        direccion:{
            apiName: 'direccion',
            provincia: {
                apiName:'provincia'
            }  
        }
    }

    return { properties, valoresPrecarga };
}
export default useData;