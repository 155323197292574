import useData from './GeneradorPdfData';
import { loadState, saveState } from 'providers/localStorage'
import { localStorageName } from 'providers/localStorageData';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import RestProvider from 'providers/RestProvider';


const useOperations = () => {

    const { options, optionsHtml2Canvas, images, formatoForm, properties } = useData();
    const { crudOperation, crudOperationsType } = RestProvider();

    /**
     * @function generarPDF Función que funciona a modo de switch, a través del parámetro tipoPdf, identifica que tipo de documento PDF
     * debe generar.
     * @param {*} tipoPdf 
     * @param {*} solicitud 
     * @param {*} dataSource 
     * @param {*} idProcedimiento 
     * @param {*} fase
     * @param {*} procedenciaConvocatoria
     * @returns 
     */
    const generarPDF = async (tipoPdf, solicitud, dataSource, idProcedimiento, fase, procedenciaConvocatoria) => {
        let pdfGenerado = null

        if (tipoPdf === properties.pdfVolcadoHTML || tipoPdf === properties.formularioConVolcadoHtml) {
            pdfGenerado = await generarPDFVolcadoHTML(solicitud)
        } else {
            pdfGenerado = await generarPDFnormalizado(solicitud, dataSource, idProcedimiento, fase, procedenciaConvocatoria);
        }
        return pdfGenerado
    }

    /**
     * @function generarPDFnormalizado Genera un documento PDF normalizado a partir del documento PDF que se haya configurado en el módulo de administración
     * @param {*} solicitud 
     * @param {*} dataSource 
     * @param {*} idProcedimiento 
     * @param {*} fase
     * @param {*} procedenciaConvocatoria
     */
    const generarPDFnormalizado = async (solicitud, dataSource, idProcedimiento, fase, procedenciaConvocatoria) => {
        let pdfSolicitud = null;

        //Se obtiene el PDF generado en el back y se almacena en el localStorage para recuperarlo posteriormente
        if (procedenciaConvocatoria === 'PCT2') {
            let input = {
                dataSource: dataSource,
                idSolicitud: solicitud,
                idProcedimiento: idProcedimiento,
                fase: fase
            };

            await crudOperation(crudOperationsType.CUSTOM, properties.resource, { url: properties.url, method: 'post', data: input }).then(response => {
                pdfSolicitud = response.data;
            });
        } else {
            let faseEstadoExpediente = loadState(localStorageName.faseEstadoExpediente, true);
            let convocatoriaSeleccionada = loadState(localStorageName.convocatoriaSeleccionada, true);
            let solicitudActual = loadState(localStorageName.solicitudActual, true);

            let input = {
                idConvocatoria: convocatoriaSeleccionada.id,
                idFase: faseEstadoExpediente.idFase,
                idEstado: faseEstadoExpediente.idEstado,
                idExpediente: solicitudActual.id
            };

            await crudOperation(crudOperationsType.CUSTOM, properties.resourcePCT3, { url: properties.url, method: 'post', data: input}).then(response => {
                pdfSolicitud = response.data;
            });
        }
        saveState(localStorageName.pdfSolicitud, pdfSolicitud, true);
    }
        

    /**
     * @function generarPDF Genera un documento PDF con volcado HTML a partir de elementos del DOM del formulario de solicitud
     * @param solicitud Recibe el ID de la solicitud si se trata de una convocatoria de PCT2, si es de PCT3 el número de expediente
     */
    const generarPDFVolcadoHTML = async (solicitud) => {
        //Capturas de elementos del DOM
        let elementoDOM = document.querySelector('.wizard-page');
        let listaForm = document.querySelector('ul.pagination');
        let paginasObtenidas = listaForm.children;
        let bntGuardar = 'button[id*="btnGuardar"]';
        let btnCancelar = 'button[id*="btnCancelar"]';
        let btnAnadir = 'button[ref="datagrid-dataGrid-addRow"]';

        //Array que contiene los elementos del DOM que se deseen eliminar antes de generar el documento PDF
        let elementosDOMaElinminar = [bntGuardar, btnAnadir, btnCancelar];
        const propiedadesDOMaEliminar = ['placeholder']

        /**** Formatos ***/
        //Formato formulario
        let anchoForm = formatoForm.anchoForm;
        let altoForm = formatoForm.altoForm;
        let coorW = formatoForm.coorW;
        let coorY = formatoForm.coorY;

        //Inicialización de variables
        let paginaActual = null;
        let ancho = null;
        let alto = null;
        let dimensionesCaptura = null;
        let img = null;
        let imagenes = [];
        let pdfGenerado = null;

        //Paso 1. Se declara el formulario
        let form = new jsPDF(options);

        //Paso 2. Se realizan capturas de cada página del formulario. 
        for (let i = 0; i < paginasObtenidas.length; i++) {
            paginaActual = i;
            //Paso 2.1. Se hace click en la página del formulario a través del menú de navegación de este
            await clickPaginaForm(listaForm, paginaActual + 1);
            eliminarElementosDOM(elementosDOMaElinminar);
            eliminarPropiedadesDOM(propiedadesDOMaEliminar);
            elementoDOM = document.querySelector('.wizard-page');
            /*Paso 2.2. Se realiza la captura de los elementos del DOM de la página y se almacenan en localStorage. Se obtiene la imagen capturada en base64.
            * Recibe por parámetros el elemento del dom sobre el que se realizarán las capturas y las opciones que recibirá.
            */
            await html2canvas(elementoDOM, { ...optionsHtml2Canvas }).then(canvas => {
                img = {
                    imagen: canvas.toDataURL('image/png'),
                    formatAncho: elementoDOM.offsetWidth,
                    formatAlto: elementoDOM.offsetHeight
                };
                saveState(localStorageName.pdfSolicitud, img, true);
            }).catch(function (error) {
                console.log(error);
            });
            //Paso 2.3 Se recupera la imagen y se inserta en el array imagenes
            imagenes.push(loadState(localStorageName.pdfSolicitud, true));
        }

        //Paso 3. Se inserta la imagen de la cabecera de la consejería
        form.addImage(images.cabeceraConsejeria.url, 'PNG', coorW, coorY, 130, 46);

        //Paso 4. Se recorre el array que contiene las imagenes capturadas y se van insertando las imagenes en el formulario
        imagenes.map(async (img, i) => {
            //Paso 4.1. Se comprueba que imagen se ha insertado para insertarle la posición en la que aparecerá en el formulario
            if (imagenes[i] !== imagenes[0]) {
                coorY += alto + formatoForm.margen;                
            } else {
                coorY += images.cabeceraConsejeria.alto + 10;
            }

            //4.3. Cálculo del formato de imagen
            dimensionesCaptura = redimensionarCaptura(img.formatAncho, img.formatAlto, anchoForm);
            ancho = dimensionesCaptura.ancho;
            alto = dimensionesCaptura.alto;

            //Paso 4.4. Se comprueba si la imagen excede la página, para insertar una nueva página
            if ((coorY + alto) >= altoForm) {
                coorY = formatoForm.coorY;
                form.addPage();
            }

            //Paso 4.5. Se inserta la imagen
            form.addImage(img.imagen, 'PNG', coorW, coorY, ancho, alto);
        });
        //Paso 5. Se genera el formulario en formato PDF (base64) y se almacena en el localStorage para recuperarlo posteriormente
        pdfGenerado = form.output('datauristring', `${solicitud}.pdf`);
        saveState(localStorageName.pdfSolicitud, pdfGenerado, true);
    }

    /**
     * @function clickPaginaForm Función auxiliar que se encarga de acceder al número de la página del formulario recibida por parámetros
     * @param {*} paginas Listado de páginas del formulario
     * @param {*} numeroPagina Número de la página a la que se va a acceder
     */
    const clickPaginaForm = async (paginas, numeroPagina) => {
        let pagina = `ul.${paginas['attributes']['class']['nodeValue']} > li:nth-child(${numeroPagina}) > span`;
        await document.querySelector(pagina).click();
    }

    /**
     * @function redimensionarCaptura Función auxiliar que se encarga de redimensionar la captura del elemento del DOM obtenida.
     * Se va reduciendo un 1% hasta que el tamaño sea inferior al tamaño del formulario PDF.
     * @param {*} anchoCaptura Ancho del elemento del DOM obtenido
     * @param {*} altoCaptura Alto del elemento del DOM obtenido
     * @param {*} anchoForm Ancho del formulario obtenido
     * @returns Objeto que contiene el ancho y el alto calculado
     */
    const redimensionarCaptura = (anchoCaptura, altoCaptura, anchoForm) => {
        let porcentaje = 0.01;
        let anchoCorrecto = false;
        anchoForm -= 20; //Se reduce 10 px de margen por cada lado

        //Paso 1. Se irá calculado la anchura correcta de la imagen hasta que esta sea correcta
        while (anchoCorrecto === false) {
            //Paso 2. Cálculo de las dimensiones aplicándo el porcentaje
            anchoCaptura -= anchoCaptura * porcentaje;
            altoCaptura -= altoCaptura * porcentaje;
            
            //Paso 3. Se comprueba si se ha obtenido el ancho correcto, de lo contrario se aumentará el % aplicado a su reducción de tamaño
            if (anchoForm > anchoCaptura) {
                anchoCorrecto = true;
            } else {
                porcentaje += 0.01;
            }
        }
        return {
            ancho: anchoCaptura,
            alto: altoCaptura
        };
    }

    /**
     * @function eliminearElementosDOM Función auxiliar que elimina elmentos del DOM que no deseemos que aparezcan en la imagen capturada
     * @param {*} elementosDOM Array que contiene los elementos a eliminar
     */
    const eliminarElementosDOM = (elementosDOM) => {
        for (const elemento of elementosDOM) {
            document.querySelector(elemento) &&
                document.querySelector(elemento).remove();
        }
    }

     /**
     * @function eliminarPropiedadesDOM Función auxiliar que elimina propiedades de los elementos
     * del DOM que no deseemos que aparezcan en la imagen capturada
     * @param {*} propiedadesDOM Array que contiene las propiedades a eliminar
     */
    const eliminarPropiedadesDOM = (propiedadesDOM) => {
        document.querySelectorAll('input, textarea, select').forEach(elemento => {
            if (!elemento.value.trim() || elemento.value === '+034 ') { 
                // Elimina el contenido si es un campo telefónico
                elemento.value = '';
                    propiedadesDOM.forEach(propiedad => {
                    if (elemento.hasAttribute(propiedad)) {
                        elemento.removeAttribute(propiedad);
                    }
                });
            }
        });
    }

    return { generarPDF };
}
export default useOperations;
