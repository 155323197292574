import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { backgroundRectangle , estilosTextosPrincipales, colores, buttonPrimarySmall, buttonSecundaryBig, tipografia } from '../../../AppStyle';

const useStyles = (isPhoneVersion) => {
    
    const style = makeStyles({
        mLeft: {
           marginLeft: '5%', 
        },
        backgroundRectangle: {
            ...backgroundRectangle,
            marginBottom: '0px',
            paddingLeft: '2%',
            margin: 0,
            width: '95%',
            ...(isPhoneVersion) && { paddingRight: '2%', },
            paddingBottom: '1px'
        },
        hclassText: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            
        },
        hclassText2: {
            ...estilosTextosPrincipales.encabezadoH3,
            color: colores.escalaGrises.cien,
            fontWeight: 'bold',
            fontSize: '15px'
        },
        gridBoton: {
            width:'100%', 
            paddingRight: '5%',
            ...(!isPhoneVersion) && { paddingLeft: '5%', },
            margin: 0 ,
        },
        botonVolver:{
            ...buttonPrimarySmall,
            margin: '1% 0.5% 1% 1%',
            ...(isPhoneVersion) && { width: '100%', },
        },
        gridSubirArchivo: {
            paddingBottom: '15%'
        },
        botonFirma: {
            ...buttonSecundaryBig,
            margin: '1% 0.5% 1% 1%',
            ...(isPhoneVersion) && { width: '100%', },
        },
        classBackdrop: {
            zIndex: 1,
            color: colores.principales.blanco,
        },
        gridTitulo:{
            display: 'flex', 
            flexDirection: 'row',
            height:'inherit',
            paddingTop: '1%',
        },
        idExpediente:{
            marginLeft:'25px',
            color: colores.escalaGrises.sesenta,
            fontFamily: tipografia.bold,
            fontSize: '13px',
            height: 'inherit',
            display:'flex',
            alignItems: 'center'
        },
        iconoInfo:{
            marginRight: '10px',
            height: '17px',
            color: colores.principales.principal
        },
        link:{
            color: colores.principales.principal,
            fontWeight: 'bold',
        }
    });

    return {style}
}

export default useStyles;