import React from 'react';
import { months } from 'AppData';
import RestProvider from 'providers/RestProvider';
import useData from './PrecargasData';
import { localStorageName } from 'providers/localStorageData';
import { loadState } from './../../../providers/localStorage';
import { getUrl } from './../../../config/Config';

const usePrecargas = () => {
    const { properties, valoresPrecarga } = useData();
    const { crudOperation, crudOperationsType } = RestProvider();
    let infoUsuario = loadState(localStorageName.infoUsuario, true);
    const solAct = loadState(localStorageName.solicitudActual, true);
    let faseEstadoExpediente = loadState(localStorageName.faseEstadoExpediente, true);
    let convAct = loadState(localStorageName.convocatoriaSeleccionada, true);
    let rutaFormulario = loadState(localStorageName.resourceFormNuevaSolicitud, false);
    let perfilSeleccionado = loadState(localStorageName.perfilSeleccionado, true);
    let solicitudColaborativa = loadState(localStorageName.solicitudColaborativa, false);
    //Obtención del token
    let token = loadState(localStorageName.token, false);
    const parametrosPrecarga = [];

    /** IMPORTANTE: La función evalúa los nombre de la API de los componentes que se hayan insertado en ela rray parametrosPrecarga
     * @function comprobarExistencia Función que comprueba si los componentes a precargar existen en el formulario actual
     * @param {*} form Formulario actual
     * @returns Devuelve un array con el nombre de los componentes existentes.
     */
    const comprobarExistencia = (form) => {
        let parametrosExistentes = [];
        let paginasForm = form['data']['components'];

        for (const parametro of parametrosPrecarga) {
            for (const pagina of paginasForm) {
                let componentesPagina = pagina['components'];
                for (const componente of componentesPagina) {
                    if (parametro === componente['key']) {
                        parametrosExistentes.push(parametro);

                    }
                    if (componente['components'] != null) {

                        for (const comp of componente['components']) {

                            if (parametro === comp['key']) {
                                parametrosExistentes.push(parametro);
                            }
                        }
                    }
                }
            }
        }
        return parametrosExistentes;
    }

    /**
     * @function obtenerValores Función auxiliar utilizada para obtener los valores de un procedimiento
     * @param {*} input Parámetros necesarios para realizar la llamada
     * @returns Valores obtenidos
     */
    const obtenerValores = async (input) => {
        let respuesta = null;
        await crudOperation(crudOperationsType.CUSTOM, properties.RESOURCE_ADMINISTRADOR, { url: properties.URL_VALORES_FORM, method: 'post', data: input }).then(response => {
            respuesta = response.data;
        });
        return respuesta;
    }

    /**
     * @function obtenerUrlEndPoint Obtiene la url del endPoint
     * @param {*} resource Recurso del MS
     * @param {*} ms Nombre del MS con el que aparece en mappedService
     * @param {*} urlEndPoint Url del endPoint
     * @param {*} param Parámetros que reciba el endpoint
     * @returns La url del endPoint al completo
     */
    const obtenerUrlEndPoint = (resource, ms, urlEndPoint, param) => {
        let url = null, host = getUrl();

        //Se prevee que puedan aparecer como undefined, si es así no se insertarán
        let parametro = param === undefined ? '' : `${param}`;
        let urlEP = urlEndPoint === undefined ? '' : `${urlEndPoint}`;

        url = `${host}${ms}/${resource}${urlEP}${parametro}`;

        return url;

    }

    /**
     * @function obtenerDestinatariosExpedientes obtiene los valores para realizar la precarga de los destinatarios de los expedientes referentes a un procedimiento
     * @param {*} id del procedimiento
     * @returns el objeto que con los valores obtenidos
     */
    const obtenerDestinatariosExpedientes = async (id, form) => {
        let valores = {}, destinatario = null, tipoDestinatario = null, urlEndPoint = null;
        let input = {
            idProcedimiento: id,
            valoresAbuscar: [valoresPrecarga.destinatario.apiName]
        };
        //Paso 1. Se obtiene el tipo de destinatario y se inserta en el array de parametrosPrecarga
        destinatario = await obtenerValores(input);
        tipoDestinatario = destinatario[valoresPrecarga.destinatario.apiName];
        parametrosPrecarga.push(tipoDestinatario);

        //Paso 2. Se evalúa si el componente a precargar existe en el formulario
        if (comprobarExistencia(form).includes(tipoDestinatario)) {

            /* Paso 3. Se evalúa de que tipo de destinatario es el componente a precargar (centralizada o provincializada) y se genera el objeto
            para la usePrecargas. */
            if (tipoDestinatario === valoresPrecarga.destinatario.centralizada.apiName) {
                input.valoresAbuscar = [destinatario[valoresPrecarga.destinatario.apiName]];
                valores = await obtenerValores(input);
            }
        }
        return valores;
    }

    /**
     * @function obtenerInformacionBoja obtiene los valores para realizar la precarga de la información BOJA referente a un procedimiento
     * @param {*} id del procedimiento
     * @returns el objeto que con los valores obtenidos
     */
    const obtenerInformacionBoja = async (id, form) => {
        let valores = {};
        //Paso 1. Se inserta el nombre de la API del componente a precargar en el array parametrosPrecarga
        parametrosPrecarga.push(valoresPrecarga.informacionBoja.apiName);

        //Paso 2. Se comprueba si el componente existe en el formulario
        if (comprobarExistencia(form).includes(valoresPrecarga.informacionBoja.apiName)) {
            let valoresObtenidos = null, fecha = null;
            let input = {
                idProcedimiento: id,
                valoresAbuscar: valoresPrecarga.informacionBoja.valoresAbuscar
            };

            //Paso 3. Se genera el objeto para la precarga
            valoresObtenidos = await obtenerValores(input);
            fecha = new Date(valoresObtenidos[valoresPrecarga.informacionBoja.valoresAbuscar[1]]);
            valores = {
                informacionBoja: {
                    ...valoresObtenidos,
                    diaOrdenDecretoLey: fecha.getDate(),
                    mesOrdenDecretoLey: `${months[fecha.getMonth()]}`,
                    anyoOrdenDecretoLey: `${fecha.getFullYear()}`,
                    anyoConvocatoriaEjercicio: valoresObtenidos[valoresPrecarga.informacionBoja.valoresAbuscar[4]]
                }
            };
        }
        return valores;
    }


    /*######################################## PRECARGA PARA FORMULARIOS DE NUEVA SOLICITUD ########################################*/

    const obtenerProvincias = async (id, form) => {
        let valores = {};
        parametrosPrecarga.push(valoresPrecarga.direccion.provincia.apiName);

        let tipoDestinatario = valoresPrecarga.direccion.provincia.apiName;

        if (comprobarExistencia(form).includes(tipoDestinatario)) {
            //parametrosPrecarga.push(valoresPrecarga.destinatario.provincia.provincias.apiName);
            //if(comprobarExistencia(form).includes(valoresPrecarga.destinatario.provincia.provincias.apiName)){
            let urlEndPoint = obtenerUrlEndPoint(properties.RESOURCE_MANAGER, properties.MS_TREWA, properties.URL_PROVINCIAS);
            token = loadState(localStorageName.token, false);

            valores = {
                provincia: {
                    token: `Bearer ${token}`,
                    url: urlEndPoint
                }

            }

            //}
        }
        return valores;
    }

    const getInteresadoPorDefectoPCT3 = async (infoUsuario) => {
        if (!infoUsuario) return null;

        let interesadoBuscado = {
            identificador: infoUsuario.interesadoNif,
            identificadorEntidad: infoUsuario.entidadCif
        };
        try {
            const response = await crudOperation(crudOperationsType.CUSTOM, 'interesado', {
                url: '/obtenerDatosInteresadoPorDefecto',
                method: 'post',
                data: interesadoBuscado
            });
            if (response.data) {
                let idMunicipio = response.data.interesado1.datosContacto.idMunicipio;
                if (idMunicipio) {
                    const municipio = await crudOperation(
                        crudOperationsType.CUSTOM,
                        'municipio',
                        { url: `/${idMunicipio}`, method: 'get' }
                    );
                    response.data.interesado1.datosContacto = {
                        ...response.data.interesado1.datosContacto,
                        municipio: municipio.data,
                        provincia: municipio.data.provincia || null,
                        pais: municipio.data.provincia.pais || null,
                    };
                }
            }
            return response.data
        } catch (error) {
            return null;
        }
    };

    const nombreApellidosInicioSesion = async (nifUsuario) => {
        if (!infoUsuario) return null;
        let usuarioLogueado = {
            identificador: infoUsuario.interesadoNif,
            identificadorEntidad: null
        };
        let nombreApellidos = null
        try {
            const response = await crudOperation(crudOperationsType.CUSTOM, 'interesado', {
                url: '/obtenerDatosInteresadoPorDefecto',
                method: 'post',
                data: usuarioLogueado
            });
            if (response.data) {
                nombreApellidos = {
                    nombre: response.data.interesado1?.interesado?.nombre,
                    apellido1: response.data.interesado1?.interesado?.apellido1,
                    apellido2: response.data.interesado1?.interesado?.apellido2,
                }
            }
            return nombreApellidos
        } catch (error) {
            return null;
        }
    };

    const obtenerDatosUsuarioFormulario = async (datosARellenar) => {
        const datosCompletosUsuario = await getInteresadoPorDefectoPCT3(infoUsuario);
        const interesado = datosCompletosUsuario?.interesado1?.interesado || {};
        const datosContacto = datosCompletosUsuario?.interesado1?.datosContacto || {};

        const direccionBase = {
            puerta: datosContacto.puerta || '',
            tipoDeViaSelector: datosContacto.tipoVia || {},
            numero: datosContacto.numero || '',
            paisSelector: datosContacto.pais || {},
            escalera: datosContacto.escalera || '',
            planta: datosContacto.planta || '',
            letra: datosContacto.letra || '',
            nombreDeLaVia: datosContacto.nombreVia || '',
            municipioSelector: datosContacto.municipio || {},
            tipoDeVia: datosContacto.tipoVia?.codigo || '',
            provinciaSelector: datosContacto.provincia || {},
            postal: datosContacto.postal || '',
        };

        const contactoBase = {
            telefonoMovil: datosContacto.telefonoMovil || '',
            telefonoFijo: datosContacto.telefono || '',
            correoElectronico: datosContacto.email || '',
        };

        // Cuando viene desde Formio:
        if (!datosARellenar) {
            let datos;
            if (solAct.edicion || solAct.subsanar || solAct.alegar || solAct.revisarPresentacion || solAct.corregirSolicitud
                || solAct.revisarSubsanacion || solAct.corregirSubsanacion) {

                datos = {
                    idConvocatoria: convAct.id,
                    idExpediente: solAct.id,
                    idFase: faseEstadoExpediente.idFase,
                    rutaFormulario: rutaFormulario,
                    perfilSeleccionado: perfilSeleccionado.descripcion,
                    solicitudColaborativa: solicitudColaborativa
                }
            } else {
                datos = {
                    idConvocatoria: convAct.id,
                    idExpediente: solAct.id,
                    idFase: faseEstadoExpediente.idFase,
                }
            }
            let formularioGuardado = await crudOperation(
                crudOperationsType.CUSTOM,
                'formulario-expediente',
                { url: '/getValorCampo', method: 'post', data: datos })
            return {
                componentesPersonales: {
                    nombreRazonSocialDenominacion: interesado.nombre || '',
                    primerApellido: interesado.apellido1 || '',
                    segundoApellido: interesado.apellido2 || '',
                    sexo: interesado.sexo || '',
                    identificador: interesado.identificador,
                    ...contactoBase,
                },
                direccion: direccionBase,
                formularioGuardado: formularioGuardado
            };
        }

        return {
            ...datosARellenar,
            data: {
                ...datosARellenar.data,
                datos: {
                    ...datosARellenar.data.datos,
                    direccion: { ...datosARellenar.data.datos?.direccion, ...direccionBase },
                },
                containerNotificacion: {
                    ...datosARellenar.data.containerNotificacion,
                    containerNotificacionElectronica: {
                        ...datosARellenar.data.containerNotificacion?.containerNotificacionElectronica,
                        correoElectronico1: datosContacto.email || '',
                        telefonoMovilNoti2: datosContacto.telefonoMovil || null,
                    },
                },
            },
        };
    };



    return { obtenerInformacionBoja, obtenerDestinatariosExpedientes, obtenerUrlEndPoint, obtenerProvincias, obtenerDatosUsuarioFormulario, nombreApellidosInicioSesion };
}
export default usePrecargas;
