import { createMuiTheme, makeStyles } from '@material-ui/core';
import {colores} from 'AppStyle';

export const customStyle = makeStyles({
    slide:{
        height: '100%',
    },
    iconoAyuda: {
        color: colores.principales.hover,
        fill: colores.principales.hover,
        height: '20px',
        width: '20px',
        '&:hover': {
            color: colores.escalaGrises.quince
        }
    },
    iconButton: {
        '&:hover': {
            backgroundColor: colores.escalaGrises.quince,
        },
    },
})

export const overPopover = createMuiTheme({
    overrides: {
        MuiPopover: {
            root: {
                left: '8px',
                right: '0px',
                top: '250px',
                bottom: '100px !important',
            },
        },
        MuiPaper:{
            root:{
                height: 'fit-content',
                maxHeight: '200px',
                width: '100%',
                left: '8px',
                right: '0px',
                top: '250px',
            },
            elevation8:{
                top: '100px !important',
                boxShadow: `0px 0px 20px 5px ${colores.escalaGrises.cincuenta}`
            }
        }, MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: colores.escalaGrises.quince,
                },
            },
        }, MuiIconLabel: {
            display: 'flex'
        }
    }
});