import _ from 'lodash';
import React, { createContext, useState, useContext } from 'react';

const RandomVarContext = createContext();
const generateRandomKey = (length) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return _.sampleSize(chars, length).join('');
};

export const RandomVarProvider = ({ children }) => {
  const [randomVar, setRandomVar] = useState(generateRandomKey(10));

  const refreshRandomVar = () => {
    setRandomVar(generateRandomKey(10));
  };

  return (
    <RandomVarContext.Provider value={{ randomVar, refreshRandomVar }}>
      {children}
    </RandomVarContext.Provider>
  );
};

export const useRandomVar = () => useContext(RandomVarContext);