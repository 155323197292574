import { createMuiTheme, makeStyles } from '@material-ui/core';
import { colores, estilosTextosPrincipales } from '../../../AppStyle';

const useStyles = (isPhoneVersion) => {

    const style = makeStyles({
        ///////////////////////////////GRID
        classGrid: {
            ...(isPhoneVersion) ? { display: 'inline-flex', marginLeft: '5%', width: '95%' } : { width: '304px', display: 'inline-block', marginRight: '10%', marginBottom: '19px' }
        },
        classImg: {
            ...(isPhoneVersion) ? { width: '50px', height: '50px', marginTop: '5%' } : { display: 'block', margin: 'auto' }
        },
        classLineaProgreso: {
            ...(!isPhoneVersion) && { marginTop: '3%' },
            height: '15px'
        },
        classGridEstado: {
            ...(isPhoneVersion) && { margin: '5%', width: '100%' }
        },
        classText: {
            ...estilosTextosPrincipales.body1,
            color: colores.escalaGrises.cien,
            ...(isPhoneVersion) && { margin: '7px auto 0px auto', width: '100%' }
        },
        classH4: {
            ...estilosTextosPrincipales.encabezadoH4,
            color: colores.escalaGrises.cien,
            ...(isPhoneVersion) && { margin: '7px auto 0px auto' }
        }
    })

    const overrideStyle = createMuiTheme({
        overrides: {
            MuiLinearProgress: {
                colorPrimary: {
                    backgroundColor: colores.principales.apoyo,
                },
                barColorPrimary: {
                    backgroundColor: colores.principales.principal,
                },
            },
        },
    });
    return { style, overrideStyle };
}

export default useStyles;