import { compProperties } from "AppData";

export const buttons = [
    { id: 'btnGeneracionFormularios', name: 'btnGeneracionFormularios', textButton: 'Generación Formularios' },
    { id: 'btnAdministracionProcedimientos', name: 'btnAdministracionProcedimientos', textButton: 'Adm. Procedimientos' }
];

export const objAttrib = {
    gridContainer: {
        id: 'container',
        name: 'container'
    },
    gridEncabezado: {
        id: 'encabezado',
        name: 'encabezado'
    },
    gridRectangulo: {
        id: 'rectangulo',
        name: 'rectangulo'
    },
    gridComp: {
        id: 'componentes',
        name: 'componentes'
    },
    gridComponentes: {
        id: 'gridComponentes',
        name: 'gridComponentes'
    },
    gridBotonera: {
        gridId: 'botonera',
        gridName: 'botonera'
    },
    gridButtonGuardar: {
        id: 'gridButtonGuardar',
        name: 'gridButtonGuardar'
    },
    buttons: {
        idButton: 'id',
        nameButton: 'name',
        textButton: 'textButton'
    } 
}

export const objText = {
    pageTitle: 'Módulo de administración',
    popup: {
        titulo: 'AVISO',
        mensaje: 'No se han guardado los datos introducidos en el formulario. Los datos no guardados se perderán, ¿desa continuar?.',
        botonera: compProperties.CONFIR_CANCELAR_ACEPTAR,
        accionDenegacion: compProperties.CONFIR_SIN_ACCION
    }
}

export const objComponentFormio = {
    administracionProcedimientos: {
        aliasFormulario: 'ov3-administracionProcedimientos',
        tituloComponente: 'Administración de Procedimientos'
    }
}

export const objSelectorProcedimiento = {
    nombre: 'procedimiento',
}

export const properties = {
    MEDIO_SEGUNDO: 500,
    //Procedimiento
    RESOURCE_PROCEDIMIENTO: 'procedimientos',
    MS_MOAD: 'moad',
    //Fases
    RESOURCE_CATALOGO_VALOR: 'valor',
    MS_CATALOGO: 'catalogo',
    URL_GET_CODIGO_CATALOGO: '/findBy-codigoCatalogo',
    PARAM_CODIGO_CATALOGO_FASES: '/COM_FASESPROCED',
    //Acciones
    RESOURCE_ACCIONES: 'verAcciones',
    MS_Acciones: 'modadmin',
    URL_GET_ACCIONES: '/getAcciones',
    //Formularios
    RESOURCE_FORMULARIOS: 'form',
    URL_GET_FORMULARIOS: '?type=form&limit=500',
    MS_MODULO_AD: 'formio'
}

export const paramAcciones = {
    RESOURCE_LISTADO_ACCIONES: 'verAcciones',
    URL_GET_ACCIONES: '/getAcciones'
}

