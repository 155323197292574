import {makeStyles} from '@material-ui/core/styles'
import { tipoAviso } from 'components/commons/avisosCriticos/AvisosCriticosData';
import {estilosTextosPrincipales, colores, backgroundRectangle} from '../../../AppStyle'

export const customStyle = makeStyles ({
    backgroundRectangle:{
        ...backgroundRectangle
    },
    gridPageTitle:{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2%',
        width: '90%',
        ...estilosTextosPrincipales.body1,
        color: colores.escalaGrises.cien,
    },
    classTitleH2: {
        ...estilosTextosPrincipales.encabezadoH2,
        color: colores.escalaGrises.cien
    },
    tableTitle:{
        fontWeight: 'bold',
        textDecoration: 'underline',
        margin: 'auto',
        width: 'fit-content'
    },
    divTabla:{
        padding: '1%'
    },
    tablaSoftware:{
        width: '80%',
        margin: 'auto',
        paddingBottom: '2%',
        paddingTop: '1%',
        ... estilosTextosPrincipales.body1,
        "& tr": {  
            backgroundColor: colores.escalaGrises.cero,
            '&:nth-of-type(odd)': {
                backgroundColor:colores.principales.apoyo,
            },
        }
    },

    tablaSoftwareLv2:{
        width: '100%',
        "& tr": {          
            backgroundColor:colores.escalaGrises.diez,
            '&:nth-of-type(odd)': {
                backgroundColor: colores.escalaGrises.cero,
            },
        },
    },

    tdFondoBlanco:{
        backgroundColor: colores.escalaGrises.cero,
    },

    tablaSoftwareLv3:{
        width: '100%',
    }

});
