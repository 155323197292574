import React, { useEffect, useState} from 'react';
import { ThemeProvider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as ArrowBefore } from './Img/ic-chevron-left.svg';
import { ReactComponent as ArrowNext } from './Img/ic-chevron-right.svg';
import { ReactComponent as Exit } from './Img/exit_ic.svg';
import RestProvider from 'providers/RestProvider';
import { properties} from './AvisosData';
import { styleAlert, overAvisos } from './AvisosStyle.js';
import { loadState } from './../../../providers/localStorage';
import { localStorageName } from './../../../providers/localStorageData';

const Avisos = () => {
    
    const classes = styleAlert(); //Aplica estilo al componente alert
    const [notVisible, setNotVisible] = useState(true); //Estado visible de los avisos
    const [avisosAuto, setAvisosAuto] = useState(true); //Estado dinámico de la transición de avisos
    let [aviso, setAviso] = useState(0); //Transición de avisos
    const [avisosData, setAvisosData] = useState([]);
    const {crudOperation, crudOperationsType} = RestProvider();

    useEffect(() => {
        cambioPantallaAvisos();
    }, []) ;

    const getDataAvisos = async () => {
        let avisos =[];
        let avisosPCT3 = await getDataAvisosPCT3();

        if (avisosPCT3 !== undefined && avisosPCT3.length > 0 && avisosPCT3[0].length>0){
            avisosPCT3[0].forEach(element => {
                avisos.push({idAvisoNoticia: element.id, nombreAvisoNoticia : element.nombre, descripcion : element.descripcion});
            });
        }
    
        if(avisos.length > 0){
            setAvisosData(avisos);        
            setNotVisible(false);         
    
        }
    }

    /***Llamada al microservicio de motor-tramitacion para recuperar el listado de avisos informativos PCT3 */
    const getDataAvisosPCT3 = async () => {
        let avisos = [];
        await crudOperation(crudOperationsType.CUSTOM, 'avisosov3', {url: properties.URL_AVISOSOV3_INFORMATIVOS , method: 'get'}).then(response => {
            const avisosNoCriticos = response.data;
            if (avisosNoCriticos.length>0){
                avisos.push(avisosNoCriticos);
            }
        }).catch();

        return avisos;
    }

    const getDataAvisosProcedimientoPCT3 = async () => {
        var contexto = loadState(localStorageName.procedimientoActual, true);
        crudOperation(crudOperationsType.CUSTOM, properties.URL_AVISOSOV3, {url:properties.URL_AVISOSOV3_INFORMATIVO_PROC + '/' + contexto.id, method: 'get'}).then(response => {            
            const avisosNoCriticosPorProc = response.data;
            let avisos =[];
    
            if (avisosNoCriticosPorProc !== undefined && avisosNoCriticosPorProc.length > 0){
                avisosNoCriticosPorProc.forEach(element => {
                    avisos.push({idAvisoNoticia: element.id, nombreAvisoNoticia : element.nombre, descripcion : element.descripcion});
                });
            }

            setAvisosData(avisos);
            if (avisos.length>0){
                setNotVisible(false);                 
            }
        }).catch();
    }    

    const AVISO_ANTERIOR = () => (
        aviso > 0 && setAviso(aviso -= 1),
        setAvisosAuto(false)
    );

    const AVISO_SIGUIENTE = () => {
        if(aviso < avisosData.length - 1){
            setAviso(aviso += 1)
        }else{
            setAvisosAuto(false)
        }
    };

    function obtenerAvisos(id) {
        let contAvisos = [];
        avisosData.map(avisos => (
            contAvisos.push(avisos.descripcion)
        ))
        return { __html: contAvisos[id] };
    }

    function descripcionEnriquecida(id) {
        return <p dangerouslySetInnerHTML={obtenerAvisos(id)} />;
    }

    if (notVisible) {
        return null;
    }

    function cambioPantallaAvisos() {
        const path = window.location.href.split('/').pop();
        const pathAux = path.split('_');
        if(pathAux[0] === properties.V2){
            setAviso(0);
            avisosData.indexOf(aviso);
            setAvisosAuto(true);
         } else if(pathAux[0] === properties.V3){
            getDataAvisosProcedimientoPCT3();
            setAviso(0);
            avisosData.indexOf(aviso);
            setAvisosAuto(true);
         } else if(!pathAux[0]){
            getDataAvisos();
            setAviso(0);
            avisosData.indexOf(aviso);
            setAvisosAuto(true);
        }
    }

    return (
        <ThemeProvider theme={overAvisos}>           
            <div className={classes.root} onload={
                avisosAuto &&
                setTimeout(AVISO_SIGUIENTE, 5000)
            }>
                <div className={classes.btnNav}>
                    {avisosData.length > 1 && (
                        <>
                            <IconButton className={classes.iconBtn} disableFocusRipple color='default' aria-label="Aviso anterior" size='small' onClick={AVISO_ANTERIOR}>
                                <ArrowBefore title={properties.TITLE_ANT}/>
                            </IconButton>
                            <IconButton className={classes.iconBtn} disableFocusRipple color='default' aria-label="Aviso siguiente" size='small' onClick={AVISO_SIGUIENTE}>
                                <ArrowNext title={properties.TITLE_SIG}/>
                            </IconButton>
                        </>
                    )}
                </div>
                {
                    avisosData.map(avisos => (
                        avisos.idAvisoNoticia === avisosData[aviso].idAvisoNoticia &&
                        <Alert severity='info' color='none' icon={false} className={classes.descripcionAviso}
                            id={avisos.idAvisoNoticia}
                            key={avisos.idAvisoNoticia}
                            value={avisos.idAvisoNoticia}>
                            <AlertTitle className={classes.tituloAviso}
                                id={avisos.idAvisoNoticia}
                                key={avisos.idAvisoNoticia}
                                value={avisos.idAvisoNoticia}>
                                {<h5 dangerouslySetInnerHTML={{ __html:avisos.nombreAvisoNoticia}}/>}
                            </AlertTitle>
                            {descripcionEnriquecida(aviso)}
                        </Alert>
                    ))
                }
                <div className={classes.btnClose}>
                    <IconButton className={classes.iconClose} disableFocusRipple color='default' aria-label="Cerrar avisos" onClick={() => setNotVisible(true)}>
                        <Exit title={properties.TITLE_CERRAR}/>
                    </IconButton>
                </div>
            </div >
        </ThemeProvider>
    );
}

export default Avisos;