import { getContextFromUrl } from 'config/Config';

export const properties = {
    RESOURCE :'documento',
    RESOURCE_HELP: 'documento-hcv',
    URL_GET_DOCUMENTO : '/docCodigoHCV',
    URL_GET_DOCUMENTO_PDF : '/fileCodigoHCV',
    INIT_PAGE : 1,
    RECORDS_PER_PAGE : 5   
}

export const texts = {
    PAGE_TITLE: 'Descarga de documentos',
    TEXT_TITULO_PAGINA: 'Descarga de documentos',
    TEXT_BOTON: 'VOLVER A LA BANDEJA DE ENTRADA',
    TEXT_NO_DATOS_DOCUMENTOS: 'No hay documentos.',
    TEXT_DOC: 'DOCUMENTO',
    MSG_ERROR: 'Se ha producido un error al recuperar los datos',
    SEVERITY: 'error',
    searchBarPlaceholder: 'Buscar...',
    searchBarLabel: 'Buscar...',
    searchIconAlt : 'Buscar',
    cleanText: "Borrar",
    idButton: "buscador",
    DOCUMENTO_HELP_ENCONTRADO: "Documento descargado correctamente.",
}


export const tableHeader = [
    {
        titulo: 'NOMBRE DOCUMENTO',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'nombreDoc',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'FECHA PRESENTACIÓN',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'fechaAlta',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'ESTADO',
        esBotonera: false,
        funcionOrdenacion: null,
        id: 'estado',
        estaOrdenado: null,
        ordenadoAsc: null
    },
    {
        titulo: 'ACCIONES',
        esBotonera: true,
        funcionOrdenacion: null,
        id: 'columnAcciones',
        estaOrdenado: null,
        ordenadoAsc: null
    }
];


export const keysTablaGenerica = {
    urlImg: 'img',
    keyTitulo: 'titulo',
    onClickFunction: 'funcion'
}

export const paths = {
    searchIconPath : `${getContextFromUrl()}` +  'searchBar/lupa.svg'
}
