export const properties = {
    RESOURCE :'configuraciones',
    URL_GET_ATENCION_CIUDADANIA : 'Pantalla_Contacto',
    INIT_PAGE : 1,
    RECORDS_PER_PAGE : 5   
}

export const texts  = {
    PAGE_TITLE : 'Servicios de atención a la ciudadanía'
}
